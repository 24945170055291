import { getDistance } from "ol/sphere";

import SimpleLatLng from "../../../da/map/models/simple-lat-lng";
import { METERS_TO_INCHES } from "../../../da/map/ol-geometry";

// we have to nudge the panels a tiny bit off the bounding box or the first column is
// considered out of bounds and (sometimes) we get errors where panels are thought
// to be inside the roof section when they are not.
// Must stay in sync with app/services/bx/results/non_rectangular/cell_positioner.rb
const NUDGE_OFFSET = 0.1; // inches

export default class CellPositioner {
  constructor(project, roofSection) {
    this.project = project;
    this.roofSection = roofSection;

    this.saveKeyVertices();
    this.calculateAnchorPoint();
    this.calculateWidthAndHeight();
    this.calculateRowsAndColumns();
    this.calculatorVectors();
    this.calculateZeroZeroPosition();
    this.calculatePaddedSizes();
  }

  cellAt(row, column) {
    const topLeft = this.positionOf(row, column);
    const topRight = topLeft.plus(this.panelWidthVector);
    const bottomRight = topRight.plus(this.panelHeightVector);
    const bottomLeft = topLeft.plus(this.panelHeightVector);

    return [topLeft, topRight, bottomRight, bottomLeft, topLeft];
  }

  // This is the panel plus the north and south tray extensions
  extendedCellAt(row, column) {
    const cellTopLeft = this.positionOf(row, column);

    const topLeft = cellTopLeft.minus(this.northExtensionVector);
    const topRight = topLeft.plus(this.panelWidthVector);
    const bottomLeft = cellTopLeft.plus(this.panelHeightVector).plus(this.southExtensionVector);
    const bottomRight = bottomLeft.plus(this.panelWidthVector);

    return [topLeft, topRight, bottomRight, bottomLeft, topLeft];
  }

  saveKeyVertices() {
    this.firstVertex = SimpleLatLng.fromLatLngModel(this.roofSection.boundingBox[0]);
    this.secondVertex = SimpleLatLng.fromLatLngModel(this.roofSection.boundingBox[1]);
    this.thirdVertex = SimpleLatLng.fromLatLngModel(this.roofSection.boundingBox[2]);

    this.firstVertexLonLat = this.firstVertex.toLonLat;
    this.secondVertexLonLat = this.secondVertex.toLonLat;
    this.thirdVertexLonLat = this.thirdVertex.toLonLat;
  }

  calculateAnchorPoint() {
    this.anchor = this.firstVertex.plus(this.roofSection.offset);
  }

  calculateWidthAndHeight() {
    this.width = getDistance(this.firstVertexLonLat, this.secondVertexLonLat) * METERS_TO_INCHES;
    this.height = getDistance(this.secondVertexLonLat, this.thirdVertexLonLat) * METERS_TO_INCHES;
  }

  calculateRowsAndColumns() {
    const usableHeight = this.height - this.project.northExtension - this.project.southExtension - NUDGE_OFFSET;
    this.rows = Math.floor(
      (usableHeight + this.project.interRowSpacing) / (this.project.panelWidth + this.project.interRowSpacing),
    );

    const usableWidth = this.width - NUDGE_OFFSET;
    this.columns = Math.floor(
      (usableWidth + this.project.interColumnSpacing) / (this.project.panelLength + this.project.interColumnSpacing),
    );
  }

  // Because bounding box is based on azimuth, creating vectors between the BB vertices gives
  // you orientation parallel to / perpendicular to the azimuth
  calculatorVectors() {
    this.calculatorLatLngVectors();
    this.calculatorCartesianVectors();
  }

  calculatorLatLngVectors() {
    const widthVector = this.secondVertex.minus(this.firstVertex);
    const widthDistance = getDistance(this.firstVertexLonLat, this.secondVertexLonLat) * METERS_TO_INCHES;
    this.unitWidthVector = widthVector.dividedBy(widthDistance);
    this.panelWidthVector = this.unitWidthVector.times(this.project.panelLength);

    const heightVector = this.thirdVertex.minus(this.secondVertex);
    const heightDistance = getDistance(this.secondVertexLonLat, this.thirdVertexLonLat) * METERS_TO_INCHES;
    this.unitHeightVector = heightVector.dividedBy(heightDistance);
    this.panelHeightVector = this.unitHeightVector.times(this.project.panelWidth);

    this.northExtensionVector = this.unitHeightVector.times(this.project.northExtension);
    this.southExtensionVector = this.unitHeightVector.times(this.project.southExtension);
  }

  calculatorCartesianVectors() {
    const firstVertexCartesian = this.roofSection.boundingBoxCartesianPoints[0];
    const secondVertexCartesian = this.roofSection.boundingBoxCartesianPoints[1];
    const thirdVertexCartesian = this.roofSection.boundingBoxCartesianPoints[2];

    const widthVector = secondVertexCartesian.minus(firstVertexCartesian);
    const widthDistance = firstVertexCartesian.distanceTo(secondVertexCartesian);
    this.unitWidthVectorCartesian = widthVector.dividedBy(widthDistance);

    const heightVector = thirdVertexCartesian.minus(secondVertexCartesian);
    const heightDistance = secondVertexCartesian.distanceTo(thirdVertexCartesian);
    this.unitHeightVectorCartesian = heightVector.dividedBy(heightDistance);
  }

  // This is the top left corner of the north most / west most panel
  calculateZeroZeroPosition() {
    const nudgeEwVector = this.unitWidthVector.times(NUDGE_OFFSET);
    const nudgeNsVector = this.unitHeightVector.times(NUDGE_OFFSET);
    this.zeroZero = this.anchor.plus(this.northExtensionVector).plus(nudgeEwVector).plus(nudgeNsVector);
  }

  calculatePaddedSizes() {
    this.paddedColumnWidth = this.project.panelLength + this.project.interColumnSpacing;
    this.paddedRowHeight = this.project.panelWidth + this.project.interRowSpacing;
  }

  positionOf(row, column) {
    const columnsWidth = (column - 1) * this.paddedColumnWidth;
    const rowsHeight = (row - 1) * this.paddedRowHeight;

    return this.zeroZero.plus(this.unitWidthVector.times(columnsWidth)).plus(this.unitHeightVector.times(rowsHeight));
  }
}
