import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["contractorLicenseNumberFieldContainer", "contractorLicenseNumberField"];

  toggleContractorLicenseNumber(e) {
    if (e.target.value === "true") {
      this.contractorLicenseNumberFieldContainerTarget.classList.remove("d-none");
    } else {
      this.contractorLicenseNumberFieldContainerTarget.classList.add("d-none");
      this.contractorLicenseNumberFieldTarget.value = "";
    }
  }
}
