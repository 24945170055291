import React from "react";
import PropTypes from "prop-types";
import { isContourEast, isContourSouth, isContourWest } from "../../../helpers/contour";

const railStyles = {
  backgroundColor: "#1D5380",
  position: "absolute",
};

export default function GridRails({
  height,
  width,
  isRailOrientationNs,
  thirdRail,
  contourGridSetting,
  showRailOverhangLeft,
  showRailOverhangRight,
  showRailOverhangTop,
  showRailOverhangBottom,
}) {
  const scaledRailWidth = (actual) => Math.min(5, parseInt(actual / 5));

  const contourWest = isContourWest(contourGridSetting);
  const contourEast = isContourEast(contourGridSetting);
  const contourSouth = isContourSouth(contourGridSetting);

  const railStylesEw = {
    height: `${scaledRailWidth(height)}px`,
    ...railStyles,
  };

  if (showRailOverhangLeft && showRailOverhangRight) {
    railStylesEw.width = contourEast || contourWest ? "calc(100% - 2px)" : "calc(100% + 1px)";
    railStylesEw.left = contourEast || contourWest ? "0" : "-1px";
  } else if (!showRailOverhangLeft && !showRailOverhangRight) {
    railStylesEw.width = "calc(100% - 2px)";
    railStylesEw.left = "0";
  } else if (showRailOverhangLeft) {
    railStylesEw.width = "calc(100% + 1px)";
    railStylesEw.left = "-2px";
  } else if (showRailOverhangRight) {
    railStylesEw.width = "calc(100%)";
    railStylesEw.left = "0";
  }

  const topRailStyle = {
    ...railStylesEw,
    top: `${0.2 * height}px`,
  };

  const middleEwRailStyle = {
    ...railStylesEw,
    top: `${0.45 * height}px`,
  };

  const bottomRailStyle = {
    ...railStylesEw,
    bottom: `${0.2 * height}px`,
  };

  const railStylesNs = {
    width: `${scaledRailWidth(width)}px`,
    ...railStyles,
  };

  if (showRailOverhangTop && showRailOverhangBottom) {
    railStylesNs.height = contourSouth ? "calc(100% - 2px)" : "calc(100% + 1px)";
    railStylesNs.top = contourSouth ? "0" : "-1px";
  } else if (!showRailOverhangTop && !showRailOverhangBottom) {
    railStylesNs.height = "calc(100% - 2px)";
    railStylesNs.top = "0";
  } else if (showRailOverhangTop) {
    railStylesNs.height = "calc(100% + 1px)";
    railStylesNs.top = "-2px";
  } else if (showRailOverhangBottom) {
    railStylesNs.height = "calc(100%)";
    railStylesNs.top = "0";
  }

  const leftRailStyle = {
    ...railStylesNs,
    left: `${0.2 * width}px`,
  };

  const middleNsRailStyle = {
    ...railStylesNs,
    left: `${0.45 * width}px`,
  };

  const rightRailStyle = {
    ...railStylesNs,
    right: `${0.2 * width}px`,
  };

  return isRailOrientationNs ? (
    <>
      <div style={leftRailStyle} />
      {thirdRail && <div style={middleNsRailStyle} />}
      <div style={rightRailStyle} />
    </>
  ) : (
    <>
      <div style={topRailStyle} />
      {thirdRail && <div style={middleEwRailStyle} />}
      <div style={bottomRailStyle} />
    </>
  );
}

GridRails.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  isRailOrientationNs: PropTypes.bool.isRequired,
  thirdRail: PropTypes.bool,
  showRailOverhangLeft: PropTypes.bool,
  showRailOverhangRight: PropTypes.bool,
  showRailOverhangTop: PropTypes.bool,
  showRailOverhangBottom: PropTypes.bool,
};
