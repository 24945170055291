import InDialogController from "../../controller_in_dialog";

import LocationFromValues from "./location_from_values";
import LocationFromGooglePlace from "./location_from_google_place";
import GoogleMapsFacade from "./google_maps_facade";
import * as animate from "../../../helpers/animate";
import { showFlashMessage } from "../../../helpers/app";
import { alertDialog } from "../../components/ir_dialog/helper";

const REVERSE_GEOCODE_DRAG_DISTANCE_THRESHOLD_IN_FT = 20;

// Handles behavior around property search.  Uses a combination of Google
// location services and maps APIs to accept and auto-complete addresses
// and then display a related map of the location.
export default class extends InDialogController {
  static targets = [
    "addressDraggedToDisplay",
    "addressSearchedForDisplay",
    "changeProjectNameToAddressDraggedToCheckbox",
    "changeProjectNameToAddressDraggedToCheckboxContainer",
    "changeProjectNameToAddressDraggedToName",
    "changeProjectNameToAddressSearchedForCheckbox",
    "changeProjectNameToAddressSearchedForCheckboxContainer",
    "changeProjectNameToAddressSearchedForName",
    "chooseAddressContainer",
    "chooseAddressDraggedToBtn",
    "chooseAddressSearchAgainBtn",
    "chooseAddressSearchedForBtn",
    "cityField",
    "countryField",
    "displayLat",
    "displayLatLngContainer",
    "displayLng",
    "latField",
    "lngField",
    "locationSearchField",
    "locationSearchFieldContainer",
    "map",
    "nameField",
    "nonUsaPostalCodeField",
    "searchBtn",
    "showMapLink",
    "stateField",
    "streetField",
    "useThisLocationForm",
    "useThisLocationFormContainer",
    "useThisLocationFormSubmitBtn",
    "useThisLocationFormSubmitBtnContainer",
    "zipField",
    "zoomField",
  ];

  connect() {
    if (!this.isOkayToConnect) return;

    this.element.propertySearchController = this;
    this.connectingLocationSearchString = this.locationSearchFieldTarget.value;

    this.initGoogleMap();
  }

  get isInDialog() {
    return this.element.closest(".ir-dialog__container") === null;
  }

  initGoogleMap = () => {
    if (!this.isGoogleMapsLoaded) {
      setTimeout(this.initGoogleMap, 50);
      return;
    }

    this.map = new GoogleMapsFacade({
      connectingLat: Number.parseFloat(this.latFieldTarget.value),
      connectingLng: Number.parseFloat(this.lngFieldTarget.value),
      connectingZoom: Number.parseInt(this.zoomFieldTarget.value),
      mapContainerElement: this.mapTarget,
      locationSearchField: this.locationSearchFieldTarget,
      mapLocationSearchResultCallback: this.mapLocationSearchResultCallback,
      mapReverseGeolocationResultCallback: this.mapReverseGeolocationResultCallback,
      mapCenterChangedCallback: this.mapCenterChangedCallback,
      mapShowErrorCallback: this.mapShowErrorCallback,
    });
    this.initializeLocations();
    this.setupExternalLatLngUpdateMethod();
  };

  get isGoogleMapsLoaded() {
    return window.google !== undefined && window.google.maps !== undefined && window.google.maps.places !== undefined;
  }

  mapLocationSearchResultCallback = (googlePlace) => {
    this.locationSearchedFor = new LocationFromGooglePlace(googlePlace);
    this.useThisLocationFormContainerTarget.classList.remove("da-property-search__form--hidden");
    this.locationSearchFieldTarget.value = googlePlace.formatted_address;
  };

  mapCenterChangedCallback = (mapLat, mapLng) => {
    if (this.isMapVisible) this.displayLatLngContainerTarget.classList.remove("d-none");
    this.useThisLocationFormContainerTarget.classList.remove("da-property-search__form--hidden");
    this.displayLatTarget.innerHTML = mapLat;
    this.displayLngTarget.innerHTML = mapLng;
  };

  mapReverseGeolocationResultCallback = ({ googlePlace }) => {
    if (!googlePlace) this.useThisLocationFormTarget.submit();

    this.locationDraggedTo = new LocationFromGooglePlace(googlePlace);

    if (this.locationDraggedTo.equals(this.locationSearchedFor)) {
      this.useThisLocationFormTarget.submit();
      return;
    }

    this.showAddressChoiceSelectionUI();
  };

  showAddressChoiceSelectionUI() {
    this.showProjectNameOverrideSelection();

    this.addressSearchedForDisplayTarget.innerHTML = this.locationSearchedFor.displayString;
    this.addressDraggedToDisplayTarget.innerHTML = this.locationDraggedTo.displayString;

    animate.show(this.chooseAddressContainerTarget);
    animate.hide(this.locationSearchFieldContainerTarget, { fadeOut: false });
    animate.hide(this.useThisLocationFormSubmitBtnContainerTarget, { fadeOut: false });
  }

  showProjectNameOverrideSelection() {
    this.resetProjectNameOverrides();

    if (this.projectNameField === null) return; // we're in the create form and this doesn't apply

    if (this.projectNameField.value !== this.locationSearchedFor.street) {
      this.changeProjectNameToAddressSearchedForCheckboxTarget.checked = !this.hasProjectNameBeenEdited;
      this.changeProjectNameToAddressSearchedForNameTarget.innerHTML = this.locationSearchedFor.street;
      this.changeProjectNameToAddressSearchedForCheckboxContainerTarget.classList.remove("d-none");
    }

    if (this.projectNameField.value !== this.locationDraggedTo.street) {
      this.changeProjectNameToAddressDraggedToCheckboxTarget.checked = !this.hasProjectNameBeenEdited;
      this.changeProjectNameToAddressDraggedToNameTarget.innerHTML = this.locationDraggedTo.street;
      this.changeProjectNameToAddressDraggedToCheckboxContainerTarget.classList.remove("d-none");
    }
  }

  resetProjectNameOverrides() {
    this.changeProjectNameToAddressSearchedForCheckboxContainerTarget.classList.add("d-none");
    this.changeProjectNameToAddressDraggedToCheckboxContainerTarget.classList.add("d-none");
    this.changeProjectNameToAddressSearchedForCheckboxTarget.checked = false;
    this.changeProjectNameToAddressDraggedToCheckboxTarget.checked = false;
  }

  get projectNameField() {
    return document.querySelector('[data-identifier="projectNameField"]');
  }

  get hasProjectNameBeenEdited() {
    return this.projectNameField.value !== this.connectedProjectLocationStreet;
  }

  mapShowErrorCallback = (msg) => {
    showFlashMessage({ message: msg, flashType: "error" });
  };

  initializeLocations() {
    if (this.isProjectPersisted) {
      this.locationSearchedFor = new LocationFromValues({
        street: this.streetFieldTarget.value,
        city: this.cityFieldTarget.value,
        state: this.stateFieldTarget.value,
        zip: this.zipFieldTarget.value,
        lat: this.map.lat,
        lng: this.map.lng,
      });
      this.connectedProjectLocationStreet = this.streetFieldTarget.value;
    } else {
      this.locationSearchedFor = null;
    }
    this.locationDraggedTo = null;
  }

  // Used by FX after updating the project location in the roof sections editor
  setupExternalLatLngUpdateMethod() {
    if (window.IR_PROPERTY_SEARCH === undefined) window.IR_PROPERTY_SEARCH = {};
    window.IR_PROPERTY_SEARCH.updateLatLng = ({ lat, lng }) => {
      this.lngFieldTarget.value = lng;
      this.latFieldTarget.value = lat;
      this.map.setCenter({ lat, lng });
    };
  }

  locationSearchFieldKeyupHandler = (event) => {
    // enter key
    if (event.keyCode === 13) {
      event.preventDefault();
      if (this.map) {
        this.map.locationSearch(this.locationSearchFieldTarget.value);
      } else {
        this.#alertMapNotLoaded();
      }
    } else {
      this.useThisLocationFormContainerTarget.classList.add("da-property-search__form--hidden");
    }
  };

  locationSearchButtonClickHandler = (_event) => {
    if (this.map) {
      this.map.locationSearch(this.locationSearchFieldTarget.value);
    } else {
      this.#alertMapNotLoaded();
    }
  };

  get isMapVisible() {
    return window.getComputedStyle(this.mapTarget).display !== "none";
  }

  useLocationFormSubmitHandler = (event) => {
    event.preventDefault();

    if (!this.map.currentPlace && !this.isProjectPersisted) return;

    if (this.map.currentPlace) {
      const location = new LocationFromGooglePlace(this.map.currentPlace);
      this.locationSearchedFor = location;
      this.setLocationFieldValuesFromLocation(location);
    }
    this.setLatLngZoomFieldsFromMap();

    this.map.disableInteraction();

    if (this.hasDraggedMapExceededReverseGeocodeThreshold) {
      this.map.geocodeCurrentPosition();
    } else {
      this.useThisLocationFormTarget.submit();
    }
  };

  get isProjectPersisted() {
    return this.element.dataset.isProjectPersisted !== undefined;
  }

  setLocationFieldValuesFromLocation(location) {
    if (!this.isProjectPersisted) this.nameFieldTarget.value = location.street;
    this.streetFieldTarget.value = location.street;
    this.cityFieldTarget.value = location.city;
    this.stateFieldTarget.value = location.state;
    this.countryFieldTarget.value = location.country;
    if (location.country === "US") {
      this.zipFieldTarget.value = location.zip;
    } else {
      this.nonUsaPostalCodeFieldTarget.value = location.zip;
    }
  }

  setLatLngZoomFieldsFromMap() {
    this.latFieldTarget.value = this.map.lat;
    this.lngFieldTarget.value = this.map.lng;
    this.zoomFieldTarget.value = this.map.zoom;
  }

  get hasDraggedMapExceededReverseGeocodeThreshold() {
    if (!this.map.hasBeenDragged) return false;

    const distanceInFeet = this.map.currentPositionDistanceFromLatLngInFeet(
      this.locationSearchedFor.lat,
      this.locationSearchedFor.lng,
    );

    return distanceInFeet >= REVERSE_GEOCODE_DRAG_DISTANCE_THRESHOLD_IN_FT;
  }

  chooseAddressHandler = (event) => {
    event.preventDefault();
    const { addressType } = event.currentTarget.dataset;

    if (addressType === "searchedFor") {
      this.chooseAddressSearchedFor();
    } else if (addressType === "draggedTo") {
      this.chooseAddressDraggedTo();
    } else if (addressType === "searchAgain") {
      this.searchAgainInsteadOfChooseAddress();
    }
  };

  chooseAddressSearchedFor() {
    this.chooseAddressDraggedToBtnTarget.disabled = true;
    this.chooseAddressSearchedForBtnTarget.classList.add("ir-btn--spinner-shown");
    this.chooseAddressSearchAgainBtnTarget.disabled = true;
    if (this.changeProjectNameToAddressSearchedForCheckboxTarget.checked) {
      this.nameFieldTarget.value = this.locationSearchedFor.street;
    }
    this.submitWithChosenAddress(this.locationSearchedFor);
  }

  chooseAddressDraggedTo() {
    this.chooseAddressDraggedToBtnTarget.classList.add("ir-btn--spinner-shown");
    this.chooseAddressSearchedForBtnTarget.disabled = true;
    this.chooseAddressSearchAgainBtnTarget.disabled = true;
    if (this.changeProjectNameToAddressDraggedToCheckboxTarget.checked) {
      this.nameFieldTarget.value = this.locationDraggedTo.street;
    }
    this.submitWithChosenAddress(this.locationDraggedTo);
  }

  submitWithChosenAddress(location) {
    this.setLocationFieldValuesFromLocation(location);
    this.useThisLocationFormTarget.submit();
  }

  searchAgainInsteadOfChooseAddress() {
    animate.hide(this.chooseAddressContainerTarget, { fadeOut: false });
    animate.show(this.locationSearchFieldContainerTarget);
    this.useThisLocationFormSubmitBtnTarget.classList.remove("ir-btn--spinner-shown");
    animate.show(this.useThisLocationFormSubmitBtnContainerTarget);
    this.locationSearchFieldTarget.focus();
    this.map.enableInteraction();
  }

  toggleMapHandler = (event) => {
    event.preventDefault();

    const showMapIcon = this.showMapLinkTarget.querySelector("i");
    if (this.isMapVisible) {
      animate.hide(this.mapTarget);
      showMapIcon.classList.add("fa-angle-down");
      showMapIcon.classList.remove("fa-angle-up");
    } else {
      animate.show(this.mapTarget);
      showMapIcon.classList.add("fa-angle-up");
      showMapIcon.classList.remove("fa-angle-down");
    }
  };

  resetLocationSearchToPersistedValues() {
    this.locationSearchFieldTarget.value = this.connectingLocationSearchString;
    this.map.resetToConnectingValues();
  }

  #alertMapNotLoaded() {
    alertDialog(
      "The map has not finished loading yet. If you continue to see this message, you may want to try reloading the web page.",
      () => {},
      {
        title: "Map not loaded",
        headerColor: "red",
        confirmBtnColor: "red",
      },
    );
  }
}
