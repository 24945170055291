import React, { Component } from "react";
import PropTypes from "prop-types";

import ManufacturerSelect from "./ManufacturerSelect";
import IronRidgePanelSelect from "./PanelSelect/IronRidgePanelSelect";
import Filter from "./Filter";

import { panelPropTypeShape, manufacturerPropTypeShape, endClampPropTypeShape } from "./helper";
import * as store from "../../helpers/local-store";

import TabNav, { TAB_IR, TAB_CP } from "./TabNav";
import CustomPanelsSelect from "./PanelSelect/CustomPanelsSelect";

class PanelPicker extends Component {
  static propTypes = {
    panels: PropTypes.arrayOf(panelPropTypeShape),
    selectedPanelId: PropTypes.number,
    projectType: PropTypes.string.isRequired,
    manufacturers: PropTypes.arrayOf(manufacturerPropTypeShape),
    selectedManufacturerId: PropTypes.number,
    customPanelsManufacturerId: PropTypes.number,
    retrievePanelsForManufacturer: PropTypes.func.isRequired,
    panelSelected: PropTypes.func.isRequired,
    endClamp: endClampPropTypeShape,
    hidePanelPickerDialog: PropTypes.func.isRequired,
    railPlatform: PropTypes.string.isRequired,
    efoAvailable: PropTypes.bool.isRequired,
    stopperAvailable: PropTypes.bool.isRequired,
    createdBefore42mmStopperSleeveUfoCutoffDate: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      panels: props.panels,
      customPanels: props.customPanels,
      selectedPanelId: props.selectedPanelId,
      manufacturers: props.manufacturers,
      selectedManufacturerId: props.selectedManufacturerId,
      endClamp: props.endClamp,
      loadingPanels: false,
      onlyShowUlListedAndCompatible: store.get("panel-picker-only-show-ul-listed", true),
      onlyShowBxCompatible: store.get("panel-picker-only-show-bx-compatible", true),
      activeTab: props.selectedManufacturerId === props.customPanelsManufacturerId ? TAB_CP : TAB_IR,
      railPlatform: props.railPlatform,
    };
  }

  updateOnlyShowUlListedPanels = (event) => {
    const onlyShowUlListedAndCompatible = event.target.checked;
    store.set("panel-picker-only-show-ul-listed", onlyShowUlListedAndCompatible);
    store.set("panel-picker-only-show-bx-compatible", onlyShowUlListedAndCompatible);
    this.setState({ onlyShowBxCompatible: onlyShowUlListedAndCompatible, onlyShowUlListedAndCompatible });
  };

  updateOnlyShowBxCompatiblePanels = (event) => {
    const onlyShowBxCompatible = event.target.checked;
    store.set("panel-picker-only-show-bx-compatible", onlyShowBxCompatible);
    store.set("panel-picker-only-show-ul-listed", onlyShowBxCompatible);
    this.setState({ onlyShowBxCompatible, onlyShowUlListedAndCompatible: onlyShowBxCompatible });
  };

  selectManufacturer = (selectedManufacturerId) => {
    const { retrievePanelsForManufacturer } = this.props;

    this.setState({
      selectedManufacturerId,
      panels: [],
      selectedPanelId: undefined,
    });

    if (!selectedManufacturerId) return;
    retrievePanelsForManufacturer(selectedManufacturerId);
  };

  selectPanel = (panel) => {
    let { selectedManufacturerId } = this.state;
    const { panelSelected } = this.props;

    this.setState({ selectedPanelId: panel.id });

    if (panel.user_id) {
      selectedManufacturerId = null;
      this.setState({ selectedManufacturerId });
    }

    panelSelected(selectedManufacturerId, panel);
  };

  renderManufacturerUi() {
    const { selectedManufacturerId, manufacturers, onlyShowUlListedAndCompatible, onlyShowBxCompatible } = this.state;
    const { projectType } = this.props;

    return (
      <ManufacturerSelect
        projectType={projectType}
        manufacturers={manufacturers}
        selectManufacturer={this.selectManufacturer}
        selectedManufacturerId={selectedManufacturerId}
        onlyShowUlListedAndCompatible={onlyShowUlListedAndCompatible}
        onlyShowBxCompatible={onlyShowBxCompatible}
      />
    );
  }

  renderPanelUi() {
    const {
      panels,
      selectedPanelId,
      selectedManufacturerId,
      endClamp,
      loadingPanels,
      onlyShowUlListedAndCompatible,
      onlyShowBxCompatible,
      railPlatform,
    } = this.state;
    const {
      projectType,
      persistedSelectedPanel,
      hidePanelPickerDialog,
      currentUser,
      projectPanelUser,
      enableAllPanels,
      createdBefore42mmStopperSleeveUfoCutoffDate,
      efoAvailable,
      stopperAvailable,
    } = this.props;

    if (!selectedManufacturerId) return "";

    return (
      <IronRidgePanelSelect
        projectId={this.props.projectId}
        projectType={projectType}
        panels={panels}
        endClamp={endClamp}
        selectPanel={this.selectPanel}
        selectedPanelId={selectedPanelId}
        persistedSelectedPanel={persistedSelectedPanel}
        loadingPanels={loadingPanels}
        onlyShowUlListedAndCompatible={onlyShowUlListedAndCompatible}
        onlyShowBxCompatible={onlyShowBxCompatible}
        hidePanelPickerDialog={hidePanelPickerDialog}
        currentUser={currentUser}
        projectPanelUser={projectPanelUser}
        enableAllPanels={enableAllPanels}
        railPlatform={railPlatform}
        efoAvailable={efoAvailable}
        stopperAvailable={stopperAvailable}
        createdBefore42mmStopperSleeveUfoCutoffDate={createdBefore42mmStopperSleeveUfoCutoffDate}
      />
    );
  }

  renderCustomPanelsUi() {
    const { customPanels, selectedPanelId, endClamp, loadingPanels, railPlatform, onlyShowUlListedAndCompatible } =
      this.state;
    const {
      persistedSelectedPanel,
      hidePanelPickerDialog,
      currentUser,
      projectPanelUser,
      projectType,
      createdBefore42mmStopperSleeveUfoCutoffDate,
      efoAvailable,
      stopperAvailable,
    } = this.props;

    return (
      <CustomPanelsSelect
        projectId={this.props.projectId}
        projectType={projectType}
        panels={customPanels}
        endClamp={endClamp}
        selectPanel={this.selectPanel}
        selectedPanelId={selectedPanelId}
        persistedSelectedPanel={persistedSelectedPanel}
        loadingPanels={loadingPanels}
        hidePanelPickerDialog={hidePanelPickerDialog}
        currentUser={currentUser}
        projectPanelUser={projectPanelUser}
        railPlatform={railPlatform}
        efoAvailable={efoAvailable}
        stopperAvailable={stopperAvailable}
        onlyShowUlListedAndCompatible={onlyShowUlListedAndCompatible}
        createdBefore42mmStopperSleeveUfoCutoffDate={createdBefore42mmStopperSleeveUfoCutoffDate}
      />
    );
  }

  renderFilter() {
    const { projectType } = this.props;
    const { onlyShowBxCompatible, onlyShowUlListedAndCompatible } = this.state;

    if (projectType === "BX") {
      return (
        <Filter
          checked={onlyShowBxCompatible}
          onChange={this.updateOnlyShowBxCompatiblePanels}
          message="Only show BX compatible and IronRidge UL listed"
        />
      );
    }

    return (
      <Filter
        checked={onlyShowUlListedAndCompatible}
        onChange={this.updateOnlyShowUlListedPanels}
        message="Only show usable and IronRidge UL listed"
      />
    );
  }

  onTabClick = (activeTab) => {
    const { selectedManufacturerId } = this.state;
    const { customPanelsManufacturerId } = this.props;
    if (selectedManufacturerId === customPanelsManufacturerId) {
      this.setState({ selectedManufacturerId: null });
    }
    this.setState({ activeTab });
  };

  get hasCustomPanels() {
    const { customPanels } = this.state;
    return customPanels.length > 0;
  }

  renderCreateCustomPanelNoticeLink() {
    return (
      <div className="pt-4">
        Don't see your panel?{" "}
        <a href="/my_custom_panels/new">
          Add a custom panel here <i className="fas fa-arrow-right" />
        </a>
      </div>
    );
  }

  renderManufacturerRequirementsResponsibility() {
    return (
      <div className="pt-2 px-1">
        <i>User to verify module manufacturer's clamping location and pressure limits.</i>
      </div>
    );
  }

  render() {
    const { activeTab } = this.state;

    return (
      <>
        {this.hasCustomPanels && <TabNav activeTab={activeTab} onTabClick={this.onTabClick} />}
        <div className="ir-content-box">
          {activeTab === TAB_IR && this.renderFilter()}
          {activeTab === TAB_IR && this.renderManufacturerUi()}
          {activeTab === TAB_IR && this.renderPanelUi()}
          {activeTab === TAB_CP && this.renderCustomPanelsUi()}
          {this.renderManufacturerRequirementsResponsibility()}
          {this.renderCreateCustomPanelNoticeLink()}
        </div>
      </>
    );
  }
}

export default PanelPicker;
