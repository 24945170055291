import React from "react";
import PropTypes from "prop-types";

function Outline({ type, x, y, height, width }) {
  const classNames = ["graphical-tool__grid-setback-outline", `graphical-tool__grid-setback-outline--${type}`];
  const styles = {
    height: `${height}px`,
    width: `${width}px`,
    left: `${x}px`,
    top: `${y}px`,
  };
  return <div className={classNames.join(" ")} style={styles} data-testid={type}></div>;
}

Outline.propTypes = {
  type: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export default React.memo(Outline);
