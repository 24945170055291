import { EAVE_SELECTION_LINE_STRING_DATA_TYPE } from "../../../da/map/data-types";
import BaseMapModelSynchronizer from "./base";
import { buildLineStringSegmentFeaturesFromPolygonCoordinates } from "../../../da/map/ol-helpers";

export default class EaveSelectionMapModelSynchronizer extends BaseMapModelSynchronizer {
  featuresForRoofPlaneLineStrings(roofPlaneModel) {
    const roofPlaneFeature = this.getFeatureForRoofPlane(roofPlaneModel);
    const polygon = roofPlaneFeature.getGeometry();
    const polygonCoordinates = polygon.getLinearRing(0).getCoordinates();

    const features = buildLineStringSegmentFeaturesFromPolygonCoordinates(polygonCoordinates);
    features.forEach((feature) => {
      feature.set("dataType", EAVE_SELECTION_LINE_STRING_DATA_TYPE);
      feature.set("roofPlaneUuid", roofPlaneModel.uuid);
    });
    return features;
  }
}
