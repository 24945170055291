import VectorSource from "ol/source/Vector";
import { defaults as interactionDefaults } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";

import BaseMapManager from "./base";
import { attachmentsStyle } from "../styles/attachments";
import { panelsStyle } from "../styles/zone-panels";
import { segmentsStyle } from "../styles/segments";
import * as events from "../../../da/map/events";
import { logger } from "../../../helpers/app";

export default class AttachmentsMapManager extends BaseMapManager {
  add() {
    this.buildMeasuresVectorSource();
    this.buildRoofPlanesVectorSource();
    this.buildRoofSectionsVectorSource();
    this.buildSetbacksVectorSource();
    this.buildPanelsVectorSource();
    this.buildSegmentsVectorSource();
    this.buildRailsVectorSource();
    this.buildThermalExpansionsVectorSource();
    this.buildAdjoinmentsVectorSource();
    this.buildContourLegalityAdjoinmentsVectorSource();
    this.buildContoursVectorSource();

    this.map = this.buildMap({
      layers: [
        this.measuresVectorLayer,
        this.visualMarkersVectorLayer,
        this.tileLayer,
        this.roofPlanesVectorLayer,
        this.segmentsVectorLayer,
        this.panelsVectorLayer,
        this.railsVectorLayer,
        this.roofSectionsVectorLayer,
        this.setbacksVectorLayer,
        this.thermalExpansionsVectorLayer,
        this.contoursVectorLayer,
        this.contourLegalityAdjoinmentsVectorLayer,
        this.adjoinmentsVectorLayer,
      ],
      interactions: interactionDefaults({ doubleClickZoom: false, mouseWheelZoom: false }),
    });

    // We need to have the map in place first
    this.buildRafterVectorSourcesLayersAndFeatures();
    this.renderContourFeatures();

    // The rafters need to be in place first before we can place attachments
    this.buildAttachmentsVectorSource();
    this.map.addLayer(this.attachmentsVectorLayer);

    this.addMouseWheelZoomWhenCtrlKeyPressedInteraction();

    this.panelsVectorLayer.setZIndex(60);

    this.map.on("moveend", this.onMoveEnd);
    this.map.on(events.AFTER_MAP_FEATURES_RENDERING_EVENT, (event) => {
      logger(`Dispatch ${events.AFTER_MAP_FEATURES_RENDERING_EVENT} called from ${event.calledFrom}`);

      this.checkSegmentsAttachmentStatusLegality();
    });

    this.dispatchAfterMapFeaturesRendering({ calledFrom: "AttachmentsMapManager#add" });
  }

  onMoveEndHandler(event) {
    const continueProcessing = super.onMoveEndHandler(event);
    if (!continueProcessing) return;

    this.controller.mapMoveUpdateOtherMaps();
  }

  buildPanelsVectorSource() {
    const features = this.controller.pageRoofPlanes.flatMap((rp) => {
      return this.mapModelSynchronizer.featuresForRoofPlanePanels(rp);
    });
    this.panelsVectorSource = new VectorSource({ features });
  }

  panelsStyle = (feature) => {
    return panelsStyle(feature, this.map, this.controller.project.is716Or722);
  };

  segmentsStyle = (feature) => {
    return segmentsStyle(feature);
  };

  buildAttachmentsVectorSource() {
    this.attachmentsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.attachmentFeatures,
    });
  }

  get attachmentsVectorLayer() {
    if (!this.memoAttachmentsVectorLayer) {
      this.memoAttachmentsVectorLayer = new VectorLayer({
        source: this.attachmentsVectorSource,
        style: (feature) => attachmentsStyle(feature, this.map),
        zIndex: 70,
      });
    }
    return this.memoAttachmentsVectorLayer;
  }

  checkSegmentsAttachmentStatusLegality() {
    const panelFeatures = this.panelsVectorSource.getFeatures();
    this.segmentsVectorSource.getFeatures().forEach((segmentFeature) => {
      const segment = segmentFeature.get("model");
      if (segment.isAttachmentPlanStatusLegal) return;

      segmentFeature.set("illegalShape", true);
      const segmentPanelFeatures = panelFeatures.filter((pf) => pf.get("segmentUuid") === segment.uuid);
      segmentPanelFeatures.forEach((panelFeature) => {
        panelFeature.set("illegalShape", true);
      });
    });
  }

  reRenderMap() {
    this.dispatchBeforeMapFeaturesRendering({ calledFrom: "AttachmentsMapManager#reRenderMap" });

    this.mapModelSynchronizer.clearSegmentAndRailFeatures();
    this.#clearMap();
    this.mapModelSynchronizer.reRenderRoofPlanes();
    this.mapModelSynchronizer.reRenderRoofSections();
    this.mapModelSynchronizer.reRenderSetbacks();
    this.buildRafterVectorSourcesLayersAndFeatures();
    this.mapModelSynchronizer.reRenderPanels();
    this.mapModelSynchronizer.reRenderThermalExpansions();
    if (this.controller.renderAttachments) {
      this.mapModelSynchronizer.reRenderAttachments();
    }

    this.mapModelSynchronizer.reRenderAdjoinmentsAndContourForPageRoofPlanes();

    this.dispatchAfterMapFeaturesRendering({ calledFrom: "AttachmentsMapManager#reRenderMap" });
  }

  #clearMap() {
    this.roofPlanesVectorSource.clear();
    this.roofSectionsVectorSource.clear();
    this.setbacksVectorSource.clear();
    this.segmentsVectorSource.clear();
    this.panelsVectorSource.clear();
    this.railsVectorSource.clear();
    this.thermalExpansionsVectorSource.clear();
    Object.values(this.rafterVectorSources).forEach((rafterVectorSource) => {
      rafterVectorSource.clear();
    });
    this.attachmentsVectorSource.clear();
  }
}
