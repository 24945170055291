import DaBase from "../../../../da/map/interaction-managers/draw/base";
import { ROOF_PLANE_DATA_TYPE } from "../../../../da/map/data-types";
import { createRoofPlane } from "../../modification-helpers/roof-plane";

export default class RoofPlanes extends DaBase {
  get targetDrawingSource() {
    return this.mapManager.roofPlanesVectorSource;
  }

  get featureDataType() {
    return ROOF_PLANE_DATA_TYPE;
  }

  createModelFromFeature(feature) {
    createRoofPlane(feature, this.project, this.mapModelSynchronizer, this.mapManager);
  }

  onDrawStart = (event) => {
    const { feature } = event;

    this.controller.rulerInteractionManager.add(feature);
  };

  featureSpecificDrawEndProcessing(_event) {
    this.controller.rulerInteractionManager.remove();
  }
}
