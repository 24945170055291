import DaBase from "../../../../da/map/interaction-managers/select/base";
import { MEASURE_DATA_TYPE, ROOF_SECTION_DATA_TYPE, SETBACK_DATA_TYPE } from "../../../../da/map/data-types";
import { modifyRoofSection, deleteRoofSection } from "../../modification-helpers/roof-section";

export default class RoofSections extends DaBase {
  filterSelects = (_feature, layer) => {
    const layerDataType = layer && layer.get("dataType");
    const selectedBubbleAtVertex = layer === null;

    // Current.rollout?(:bx_editable_setbacks)
    if (!this.controller.canEditSetbacks && layerDataType === SETBACK_DATA_TYPE) return false;

    return (
      selectedBubbleAtVertex ||
      layerDataType === ROOF_SECTION_DATA_TYPE ||
      layerDataType === MEASURE_DATA_TYPE ||
      layerDataType === SETBACK_DATA_TYPE
    );
  };

  removeSelectedVertices() {
    // if feature is default roof section
    // make a copy of selected roof section & set that to default / inactive
    // change the selected roof to custom
    this.removeSelectedVerticesFromRoofSection();
    this.removeSelectedVertexFromSelectInteractionFeatures();
  }

  removeSelectedVerticesFromRoofSection() {
    this.selectionCollection.forEach((rsFeature) => {
      const selectedVertexCoordinates = rsFeature.get("selectedVertexCoordinates");

      const coordinates = this.getCoordinatesIfVertexDeletionAllowed(rsFeature);
      if (!coordinates) return;

      const index = this.indexOfMatchingCoordinate(selectedVertexCoordinates, coordinates);
      if (index === -1) return;

      const newCoordinates = this.coordinatesWithoutVertexAtIndex(coordinates, index);
      this.updateFeatureToNewCoordinates(rsFeature, newCoordinates);
      rsFeature.unset("selectedVertexCoordinates");

      modifyRoofSection(rsFeature, this.project, this.mapModelSynchronizer, this.mapManager, this.controller);
    });

    this.controller.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: "RoofSectionsSelectInteractionManager#removeSelectedVerticesFromRoofSection",
    });
  }

  removeSelectedShapes = () => {
    this.removeSelectedRoofSections();
    this.baseRemoveSelectedShapes();
  };

  removeSelectedRoofSections() {
    this.selectionCollection.forEach((feature) => {
      if (feature.get("dataType") === ROOF_SECTION_DATA_TYPE) {
        deleteRoofSection(feature, this.project, this.mapModelSynchronizer, this.mapManager, this.controller);
      }
    });

    this.controller.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: "RoofSectionsSelectInteractionManager#removeSelectedRoofSections",
    });
  }

  selectClickResetInteractionManagers() {
    // The translate interaction has to be before the modify, or modify doesn't work
    this.controller.translateInteractionManager.add();
    this.controller.modifyInteractionManager.add();
  }
}
