import PropTypes from "prop-types";

export const panelPropTypeShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  dimensions: PropTypes.string.isRequired,
  ul_listed_status: PropTypes.string.isRequired,
  ufo_compatible: PropTypes.bool.isRequired,
  ufo_stopper_compatible: PropTypes.bool.isRequired,
  efo_thickness_compatibility: PropTypes.string.isRequired,
  camo_flange_compatibility_long_side: PropTypes.string.isRequired,
  camo_flange_compatibility_short_side: PropTypes.string.isRequired,
  aire_lock_mid_compatible: PropTypes.bool.isRequired,
  aire_lock_end_compatible: PropTypes.bool.isRequired,
  aire_lock_stealth_flange_compatibility_long_side: PropTypes.string.isRequired,
  aire_lock_stealth_flange_compatibility_short_side: PropTypes.string.isRequired,
  contour_flange_compatibility_long_side: PropTypes.string.isRequired,
  contour_flange_compatibility_short_side: PropTypes.string.isRequired,
  bx_thickness_compatible: PropTypes.bool.isRequired,
  bx_width_compatible: PropTypes.bool.isRequired,
  pr_length_compatible: PropTypes.bool.isRequired,
  fr_length_compatible: PropTypes.bool.isRequired,
  gb_length_compatible: PropTypes.bool.isRequired,
});

export const endClampPropTypeShape = PropTypes.oneOf(["stopper", "efo", "camo", "lock_end", "lock_stealth"]);

export const customPanelPropTypeShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export const manufacturerPropTypeShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export function detailsLinkParams(panel, projectType, projectId, railPlatform, efoAvailable, stopperAvailable) {
  return {
    "data-action": "click->components--ir-dialog--turbo-frame-details#clickToOpen",
    "data-components--ir-dialog--turbo-frame-details-dialog-identifier-param": `panel-detail-${panel.id}-dialog`,
    "data-components--ir-dialog--turbo-frame-details-dialog-header-param": `${panel.manufacturer_name}: ${panel.name}`,
    "data-components--ir-dialog--turbo-frame-details-dialog-width-param": detailsDialogWidth(
      projectType,
      efoAvailable,
      stopperAvailable,
    ),
    "data-components--ir-dialog--turbo-frame-details-dialog-body-min-height-param": 450,
    "data-components--ir-dialog--turbo-frame-details-turbo-frame-id-param": `panel_details_${panel.id}`,
    "data-components--ir-dialog--turbo-frame-details-turbo-frame-src-param": `/panel_picker_panels/${panel.id}?rail_platform=${railPlatform}&project_id=${projectId}&project_type=${projectType}`,
  };
}

function detailsDialogWidth(projectType, efoAvailable, stopperAvailable) {
  const efoSpace = efoAvailable ? 140 : 0;
  const stopperSpace = stopperAvailable ? 120 : 0;

  switch (projectType) {
    case "BX":
      return 800;
    case "FR4":
      return 830 + efoSpace + stopperSpace;
    case "FR":
      return 830 + efoSpace + stopperSpace;
    case "GB4":
      return 830 + efoSpace + stopperSpace;
    case "GB":
      return 830 + efoSpace + stopperSpace;
    default:
      return 980 + efoSpace + stopperSpace;
  }
}
