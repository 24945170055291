import LineString from "ol/geom/LineString";
import { fromLonLat } from "ol/proj";
import { Fill, Stroke, Style } from "ol/style";

const REGULAR_THERMAL_EXPANSION_BORDER_COLOR = "rgba(155, 18, 112, 1)";
const REGULAR_THERMAL_EXPANSION_FILL_COLOR = "rgba(217, 22, 156, 0.6)";

const SELECTED_THERMAL_EXPANSION_BORDER_COLOR = "rgba(255, 255, 255, 1)";
const SELECTED_THERMAL_EXPANSION_FILL_COLOR = "rgba(217, 130, 190, 0.8)";

const THERMAL_EXPANSION_BORDER_WEIGHT = 2;

const REGULAR_THERMAL_EXPANSION_MIDLINE_COLOR = "rgba(255, 255, 255, 0.8)";
const SELECTED_THERMAL_EXPANSION_MIDLINE_COLOR = "rgba(255, 255, 255, 1)";

const REGULAR_THERMAL_EXPANSION_STYLE = new Style({
  stroke: new Stroke({ color: REGULAR_THERMAL_EXPANSION_BORDER_COLOR, width: THERMAL_EXPANSION_BORDER_WEIGHT }),
  fill: new Fill({ color: REGULAR_THERMAL_EXPANSION_FILL_COLOR }),
});

const SELECTED_THERMAL_EXPANSION_STYLE = new Style({
  stroke: new Stroke({ color: SELECTED_THERMAL_EXPANSION_BORDER_COLOR, width: THERMAL_EXPANSION_BORDER_WEIGHT }),
  fill: new Fill({ color: SELECTED_THERMAL_EXPANSION_FILL_COLOR }),
});

export const thermalExpansionsStyle = (thermalExpansionFeature) => {
  const styles = [REGULAR_THERMAL_EXPANSION_STYLE];
  const lineStyle = thermalExpansionLineGraphicStyle(thermalExpansionFeature, REGULAR_THERMAL_EXPANSION_MIDLINE_COLOR);
  styles.push(lineStyle);
  return styles;
};

function thermalExpansionLineGraphicStyle(thermalExpansionFeature, midLineColor) {
  const thermalExpansion = thermalExpansionFeature.get("model");
  const { unitColumnVectorLatLng, unitRowVectorLatLng } = thermalExpansion.segment;
  const segmentHeight = thermalExpansion.segment.height;
  const startLatLng = thermalExpansion.startLatLng;
  const lineStringInset = 0;
  const lineStringHeight = segmentHeight - lineStringInset * 2;
  const p1 = startLatLng
    .plus(unitColumnVectorLatLng.times(thermalExpansion.thermalExpansionLength / 2))
    .plus(unitRowVectorLatLng.times(lineStringInset));

  const halfHeightOfExpansionSymbol = 3;
  const p2 = p1.plus(unitRowVectorLatLng.times(lineStringHeight / 2 - halfHeightOfExpansionSymbol));

  const p3 = p2.minus(unitColumnVectorLatLng.times(3)).plus(unitRowVectorLatLng.times(1.5));
  const p4 = p3.plus(unitColumnVectorLatLng.times(6)).plus(unitRowVectorLatLng.times(3));
  const p5 = p4.minus(unitColumnVectorLatLng.times(3)).plus(unitRowVectorLatLng.times(1.5));

  const p6 = p1.plus(unitRowVectorLatLng.times(lineStringHeight));
  const coordinates = [p1, p2, p3, p4, p5, p6].map((latLng) => fromLonLat(latLng.toLonLat));

  const style = new Style({
    geometry: new LineString(coordinates),
    stroke: new Stroke({ color: midLineColor, width: 1 }),
    zIndex: 80,
  });
  return style;
}

export const selectThermalExpansionStyle = (thermalExpansionFeature) => {
  const styles = [SELECTED_THERMAL_EXPANSION_STYLE];
  const lineStyle = thermalExpansionLineGraphicStyle(thermalExpansionFeature, SELECTED_THERMAL_EXPANSION_MIDLINE_COLOR);
  styles.push(lineStyle);
  return styles;
};
