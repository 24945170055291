import moment from "moment";

export const diff = (start, end) => {
  start = moment(start);
  end = moment(end);
  const duration = moment.duration(end.diff(start));
  return {
    years: duration.get("years"),
    months: duration.get("months"),
    days: duration.get("days"),
    hours: duration.get("hours"),
    minutes: duration.get("minutes"),
    seconds: duration.get("seconds"),
    milliseconds: duration.get("milliseconds"),
  };
};

export const until = (end) => {
  if (moment().isAfter(end)) {
    return {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    };
  }
  return diff(new Date(), end);
};
