import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";
import * as store from "../../helpers/local-store";

export default class extends Controller {
  static targets = ["tabLink", "tabContentContainer", "collapsableTabContent"];

  connect() {
    this.projectId = this.element.dataset.projectId;
    this.localStoreSelectedKey = `gb-sub-array-tab-selected-${this.projectId}`;
    this.localStoreExpandedKey = `gb-sub-array-tabs-expanded-${this.projectId}`;
    this.localStoreCollapsedIdsKey = `gb-sub-array-collapsed-ids-${this.projectId}`;

    if (this.hasMoreThanOneTab) this.showSelectedTab(this.selectedSubArrayId);

    const tabsAreExpanded = this.storedTabsExpanded;
    this.setTabsExpanded(tabsAreExpanded);

    if (tabsAreExpanded) this.hideCollapsedDetailsSections();
  }

  get hasMoreThanOneTab() {
    return this.tabContentContainerTargets.length > 0;
  }

  showSelectedTab(showSubArrayId) {
    this.toggleTabLinks(showSubArrayId);
    this.toggleTabContentAreas(showSubArrayId);
  }

  toggleTabLinks(showSubArrayId) {
    this.tabLinkTargets.forEach((tl) => {
      if (tl.dataset.subArrayId === showSubArrayId) {
        tl.closest("li").classList.add("active");
      } else {
        tl.closest("li").classList.remove("active");
      }
    });
  }

  toggleTabContentAreas(showSubArrayId) {
    this.tabContentContainerTargets.forEach((sdc) => {
      if (sdc.dataset.subArrayId === showSubArrayId) {
        animate.show(sdc);
      } else {
        sdc.style.display = "none";
      }
    });
  }

  setTabsExpanded(expanded) {
    this.element.classList[expanded ? "add" : "remove"]("gb-sub-array-details--tabs-expanded");
    store.set(this.localStoreExpandedKey, expanded);

    if (!expanded) this.collapsableTabContentTargets.forEach(animate.show);
  }

  get storedTabsExpanded() {
    return store.get(this.localStoreExpandedKey, false);
  }

  get selectedSubArrayId() {
    const selectedId = store.get(this.localStoreSelectedKey);
    if (selectedId !== null && this.subArrayIdPresent(selectedId)) {
      return selectedId;
    } else {
      return this.firstSubArrayId;
    }
  }

  subArrayIdPresent(subArrayId) {
    const subArrayIds = this.tabContentContainerTargets.map((t) => t.dataset.subArrayId);
    return subArrayIds.includes(subArrayId);
  }

  get firstSubArrayId() {
    return this.tabContentContainerTargets[0].dataset.subArrayId;
  }

  showTab(event) {
    event.preventDefault();
    const tabLink = event.currentTarget;
    const showSubArrayId = tabLink.dataset.subArrayId;
    this.saveSelectedSubArrayId(showSubArrayId);
    this.showSelectedTab(showSubArrayId);
  }

  saveSelectedSubArrayId(newId) {
    store.set(this.localStoreSelectedKey, newId);
  }

  toggleTabsExpandedDisplay(event) {
    event.preventDefault();
    const tabsAreExpanded = !this.tabsExpandedClassExists;
    this.setTabsExpanded(tabsAreExpanded);
    if (tabsAreExpanded) this.hideCollapsedDetailsSections();
  }

  get tabsExpandedClassExists() {
    return this.element.classList.contains("gb-sub-array-details--tabs-expanded");
  }

  hideCollapsedDetailsSections() {
    const storedSubArrayIds = this.storedCollapsedSubArrays;
    this.collapsableTabContentTargets.forEach((ctc) => {
      const subArrayId = ctc.dataset.subArrayId;
      if (storedSubArrayIds.includes(subArrayId)) ctc.style.display = "none";
    });
  }

  storeCollapsed(event) {
    const { subArrayId } = event.currentTarget.dataset;
    this.toggleAndStoreCollapsedSubArray(subArrayId);
  }

  toggleAndStoreCollapsedSubArray(subArrayId) {
    const subArrayIds = this.storedCollapsedSubArrays;

    let index = subArrayIds.indexOf(subArrayId);
    if (index < 0) {
      subArrayIds.push(subArrayId);
    } else {
      subArrayIds.splice(index, 1);
    }

    store.set(this.localStoreCollapsedIdsKey, subArrayIds);
  }

  get storedCollapsedSubArrays() {
    return store.get(this.localStoreCollapsedIdsKey, []);
  }
}
