import { featureEdgesIntersect, markIllegalIfFeatureHasPointsInsideFeature } from "./polygon-helpers";

export default class SimplePolygonLegalityChecker {
  constructor(existingFeatures, mapModelSynchronizer) {
    this.existingFeatures = existingFeatures;
    this.mapModelSynchronizer = mapModelSynchronizer;
  }

  markIllegalPolygons(justCompletedFeature = undefined) {
    let features = this.existingFeatures;
    if (justCompletedFeature) {
      features = [...features, justCompletedFeature];
    }

    this.cleanIllegalShapeFlags();

    for (let i = 0; i < features.length; i++) {
      const feature = features[i];

      // If feature edges intersect other edges of same feature, it should be illegal
      if (featureEdgesIntersect(feature, feature)) {
        feature.set("illegalShape", true);
      }

      for (let j = 0; j < features.length; j++) {
        const otherFeature = features[j];

        if (feature.get("uuid") === otherFeature.get("uuid")) continue;

        if (featureEdgesIntersect(feature, otherFeature)) {
          feature.set("illegalShape", true);
          otherFeature.set("illegalShape", true);
        } else {
          markIllegalIfFeatureHasPointsInsideFeature(feature, otherFeature);
        }
      }
    }

    this.saveIllegalityIntoModels(justCompletedFeature);
  }

  cleanIllegalShapeFlags() {
    this.existingFeatures.forEach((feature) => feature.set("illegalShape", false));
  }

  saveIllegalityIntoModels(justCompletedFeature) {
    const extraFeatures = justCompletedFeature ? [justCompletedFeature] : undefined;
    this.updateAllUnderlyingModelsIllegalityFromFeature(extraFeatures);
  }
}
