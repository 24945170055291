import { Controller } from "@hotwired/stimulus";
import anime from "animejs";
import axios from "axios";

import * as api from "../../helpers/api";
import { isInStore, setInStore } from "./helpers";

export default class IntroductionDismissalController extends Controller {
  static values = {
    introductionName: String,
    hasCurrentUser: Boolean,
  };

  connect() {
    if (isInStore(this.introductionNameValue)) {
      this.element.remove();
      return;
    }

    this.element.classList.remove("d-none");
  }

  dismiss(event) {
    event.preventDefault();

    setInStore(this.introductionNameValue);

    if (this.hasCurrentUserValue) {
      axios({
        method: "POST",
        headers: api.defaultHeaders(),
        url: "/introduction_dismissals",
        data: { introduction_name: this.introductionNameValue },
      }).catch(api.errorHandler);
    }

    if (event.params && event.params.url) {
      location.href = event.params.url;
    } else {
      anime({
        targets: this.element,
        opacity: 0,
        easing: "easeInOutQuad",
        duration: 300,
        complete: () => {
          this.element.style.display = "none";
        },
      });
    }
  }
}
