import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

// Used to show an element (usually a save button) when fields it saves
// have changed.
// your form should start out with an item wrapped in
//   {style: 'display: none', data: { components__ir_dirty_tracker_target: 'enableable' }}
// each field that should cause that to be changed to display normally should have
//   data: { action: 'change->components--ir-dirty-tracker#dirtied' }
// with text fields or text areas use the input event
//   data: { action: 'input->components--ir-dirty-tracker#dirtied' }

export default class extends Controller {
  static targets = ["enableable", "hideable"];

  dirtied() {
    animate.show(this.enableableTarget);

    this.hideableTargets.forEach((h) => animate.hide(h));
  }
}
