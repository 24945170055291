import React, { Component } from "react";
import PropTypes from "prop-types";
import InfoPopupLink from "../InfoPopupLink";

class TextField extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    typeLabel: PropTypes.string,
    infoIconDialogIdentifier: PropTypes.string,
    gridCols: PropTypes.number,
    onScrollButtonClick: PropTypes.func,
    onFieldChange: PropTypes.func.isRequired,
    onFieldPressEnter: PropTypes.func,
    onFieldBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyDown: PropTypes.func,
    autoFocus: PropTypes.bool,
  };

  static defaultProps = {
    onScrollButtonClick: undefined,
    autoFocus: false,
    onFieldChange: () => {},
    onFieldPressEnter: () => {},
    onFieldBlur: () => {},
    onKeyDown: () => {},
  };

  onTextFieldChange = (event) => {
    const value = event.target.value;
    this.props.onTextFieldChange(value);
  };

  scrollClick = (event, amount) => {
    if (event.shiftKey) {
      amount = amount * 10;
    }
    this.props.onScrollButtonClick(amount);
  };

  renderScrollButtons() {
    if (this.props.onScrollButtonClick === undefined) {
      return "";
    }
    return (
      <div className="input-group-append ir-number-scroll__container">
        <button onClick={(event) => this.scrollClick(event, 1)} className="ir-number-scroll__up" type="button">
          <i className="fas fa-caret-up" />
        </button>
        <button onClick={(event) => this.scrollClick(event, -1)} className="ir-number-scroll__down" type="button">
          <i className="fas fa-caret-down" />
        </button>
      </div>
    );
  }

  renderInfoPopup() {
    const { infoIconDialogIdentifier } = this.props;
    if (!infoIconDialogIdentifier) return "";
    return (
      <>
        {" "}
        <InfoPopupLink dialogIdentifier={infoIconDialogIdentifier} />
      </>
    );
  }

  handleKeyPress = (event) => {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(event);
    } else {
      if (event.key === "Enter") {
        const value = event.target.value;
        this.props.onFieldPressEnter(value);
      }
    }
  };

  handleFieldBlur = (event) => {
    const value = event.target.value;
    this.props.onFieldBlur(value);
  };

  handleFieldChange = (event) => {
    const value = event.target.value;
    this.props.onFieldChange(value);
  };

  get idFromLabel() {
    return this.props.label.replace(/\s/, "_").toLowerCase();
  }

  renderInput() {
    const { id, value, autoFocus, onKeyDown } = this.props;

    let inputId;
    if (id !== null) inputId = id || this.idFromLabel;

    return (
      <>
        <input
          type="text"
          className="form-control"
          id={inputId}
          value={value}
          onChange={this.handleFieldChange}
          onKeyPress={this.handleKeyPress}
          onBlur={this.handleFieldBlur}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
        />
        {this.renderScrollButtons()}
        {this.renderTypeLabel()}
      </>
    );
  }

  get renderWithInputGroup() {
    const { onScrollButtonClick, typeLabel } = this.props;
    return onScrollButtonClick !== undefined || typeLabel !== undefined;
  }

  renderTypeLabel() {
    const { typeLabel } = this.props;
    if (!typeLabel) return "";
    return (
      <div className="input-group-append">
        <div className="input-group-text">{typeLabel}</div>
      </div>
    );
  }

  renderInputInInputGroup() {
    return <div className="input-group">{this.renderInput()}</div>;
  }

  render() {
    const { id, label, gridCols } = this.props;

    const formGroupClasses = ["form-group"];
    if (gridCols) {
      formGroupClasses.push(`col-${gridCols}`);
    }

    return (
      <div className={formGroupClasses.join(" ")}>
        {label && <label htmlFor={id}>{label}</label>}
        {this.renderInfoPopup()}
        {this.renderWithInputGroup && this.renderInputInInputGroup()}
        {!this.renderWithInputGroup && this.renderInput()}
      </div>
    );
  }
}

export default TextField;
