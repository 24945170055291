import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

// Connects to data-controller="custom-payment-fields"
export default class extends Controller {
  static targets = ["form"];

  async update() {
    const response = await patch(this.formTarget.action, {
      body: JSON.stringify(Object.fromEntries(new FormData(this.formTarget))),
      responseKind: "turbo-stream",
    });

    return response.ok;
  }
}
