import BaseMapModelSynchronizer from "./base";

export default class SetbacksMapModelSynchronizer extends BaseMapModelSynchronizer {
  loadSetbacks() {
    return [this.buildSetbackForRoofPlane(this.controller.focusedRoofPlane)];
  }

  setRoofPlaneRoofSectionsLegality(roofPlaneUuid, illegalShape) {
    const roofPlane = this.project.getRoofPlane(roofPlaneUuid);
    roofPlane.roofSections.forEach((rs) => rs.setIllegalShape(illegalShape));
  }

  buildSetbackForRoofPlane(roofPlane) {
    const setbackFeature = super.buildSetbackForRoofPlane(roofPlane);
    if (roofPlane.hasIllegalRoofSections) setbackFeature.set("illegalShape", true);

    return setbackFeature;
  }

  get setbackFeature() {
    const roofPlaneUuid = this.controller.focusedRoofPlaneUuid;
    return this.controller.mapManager.setbacksVectorSource
      .getFeatures()
      .find((f) => f.get("roofPlaneUuid") === roofPlaneUuid);
  }

  loadGuideLines() {
    const guideLines = this.project.displayableRoofPlanes.flatMap((roofPlane) => {
      return this.buildRoofPlaneGuideLineStringFeatures(roofPlane, { guideLinesKey: "setbackGuides" });
    });
    return guideLines;
  }

  toggleGuideLineVisibility(segmentIndex) {
    this.guideLineForSegmentIndex(segmentIndex).toggleVisibility();
    this.controller.mapManager.reloadGuideLineFeatures();
  }

  setGuideLineDistance(segmentIndex, distance) {
    this.guideLineForSegmentIndex(segmentIndex).setDistance(distance);
    this.controller.mapManager.reloadGuideLineFeatures();
  }

  guideLineForSegmentIndex(segmentIndex) {
    return this.controller.focusedRoofPlane.guideLineForSegmentIndex(segmentIndex);
  }

  showAllGuideLines() {
    this.setAllGuideLinesVisibility(true);
  }

  hideAllGuideLines() {
    this.setAllGuideLinesVisibility(false);
  }

  setAllGuideLinesVisibility(visible) {
    this.controller.focusedRoofPlane.setbackGuides.forEach((sg) => sg.setVisibility(visible));
    this.controller.mapManager.reloadGuideLineFeatures();
  }
}
