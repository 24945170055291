import Base from "./base";
import { ROOF_PLANE_DATA_TYPE } from "../../data-types";

export default class DaRoofPlanes extends Base {
  filterSelects = (_feature, _layer) => {
    return true;
  };

  removeSelectedVertices() {
    this.removeSelectedVerticesFromRoofPlane();
    this.removeSelectedVertexFromSelectInteractionFeatures();
  }

  removeSelectedVerticesFromRoofPlane() {
    this.selectionCollection.forEach((rpFeature) => {
      const selectedVertexCoordinates = rpFeature.get("selectedVertexCoordinates");

      const coordinates = this.getCoordinatesIfVertexDeletionAllowed(rpFeature);
      if (!coordinates) return;

      const index = this.indexOfMatchingCoordinate(selectedVertexCoordinates, coordinates);
      if (index === -1) return;

      const newCoordinates = this.coordinatesWithoutVertexAtIndex(coordinates, index);
      this.updateFeatureToNewCoordinates(rpFeature, newCoordinates);
      rpFeature.unset("selectedVertexCoordinates");

      this.modifyRoofPlane(rpFeature, this.mapModelSynchronizer, this.mapManager);
    });
  }

  removeSelectedShapes = () => {
    this.removeSelectedRoofPlanes();
    this.baseRemoveSelectedShapes();
  };

  removeSelectedRoofPlanes = () => {
    this.selectionCollection.forEach((feature) => {
      if (feature.get("dataType") === ROOF_PLANE_DATA_TYPE) {
        this.deleteRoofPlane(feature, this.project, this.mapModelSynchronizer, this.mapManager);
      }
    });
  };

  selectClickResetInteractionManagers() {
    // The translate interaction has to be before the modify, or modify doesn't work
    this.controller.translateInteractionManager.add();
    this.controller.modifyInteractionManager.add();
  }
}
