import { Controller } from "@hotwired/stimulus";

export default class EndClampController extends Controller {
  changeEndClamp(event) {
    const panelPickerElement = document.querySelector("[data-controller='da--panel-picker']");
    if (!panelPickerElement) return;

    const panelPickerController = panelPickerElement.controller;

    panelPickerController.updateEndClamp(event.target.value);
  }
}
