import React from "react";
import PropTypes from "prop-types";

import GridNumber from "./GridNumber";

function GridNumbers({ numbers }) {
  return numbers.map((numbersArray) => {
    return numbersArray.map((n) => (
      <GridNumber key={`${n.x}${n.y}`} number={n.number} height={n.height} width={n.width} x={n.x} y={n.y} />
    ));
  });
}

GridNumbers.propTypes = {
  numbers: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        number: PropTypes.number.isRequired,
      }),
    ),
  ),
};

export default React.memo(GridNumbers);
