import { Feature } from "ol";
import Polygon from "ol/geom/Polygon";
import { fromLonLat } from "ol/proj";
import { getDistance } from "ol/sphere";

import { THERMAL_EXPANSION_DATA_TYPE } from "../../../../da/map/data-types";
import { METERS_TO_INCHES } from "../../../../da/map/ol-geometry";

export const DISTANCE_THRESHOLD = 0.01; // inches

export function createThermalExpansionFeature(thermalExpansion) {
  const coordinatesLatLng = thermalExpansion.latLngPoints;
  const coordinates = coordinatesLatLng.map((latLng) => fromLonLat(latLng.toLonLat));

  const geometry = new Polygon([coordinates]);
  const feature = new Feature({ geometry });

  feature.set("model", thermalExpansion);
  feature.set("uuid", thermalExpansion.uuid);
  feature.set("segmentUuid", thermalExpansion.segment.uuid);
  feature.set("dataType", THERMAL_EXPANSION_DATA_TYPE);

  return feature;
}

export function thermalExpansionFeaturesSortedByDistanceToRailedParentStart(
  thermalExpansionFeatures,
  ascDesc = "desc",
) {
  return thermalExpansionFeatures.sort((feature1, feature2) => {
    const distance1 = thermalExpansionDistanceToRailedParentStart(feature1.get("model"));
    const distance2 = thermalExpansionDistanceToRailedParentStart(feature2.get("model"));
    if (ascDesc === "desc") {
      return distance2 - distance1;
    } else {
      return distance1 - distance2;
    }
  });
}

export function segmentsFlushAroundThermalExpansion(controller, thermalExpansion) {
  const segment = thermalExpansion.segment;
  const railGroup = segment.railedGroup;

  const thermalExpansionDistance = controller.mapModelSynchronizer.getDistanceInInches(
    segment.startLatLng,
    thermalExpansion.startLatLng,
  );

  let segmentBeforeIndex;
  const segmentBefore = railGroup.find((seg, i) => {
    const distance = Math.abs(seg.endDistanceFromRailedParentStart - thermalExpansionDistance);
    const isSegment = distance <= DISTANCE_THRESHOLD;
    if (isSegment) segmentBeforeIndex = i;
    return isSegment;
  });

  const segmentAfter = railGroup[segmentBeforeIndex + 1];

  return [segmentBefore, segmentAfter];
}

export function thermalExpansionsSortedByDistanceToRailedParentStart(thermalExpansions, ascDesc = "desc") {
  return thermalExpansions.sort((thermalExpansion1, thermalExpansion2) => {
    const distance1 = thermalExpansionDistanceToRailedParentStart(thermalExpansion1);
    const distance2 = thermalExpansionDistanceToRailedParentStart(thermalExpansion2);
    if (ascDesc === "desc") {
      return distance2 - distance1;
    } else {
      return distance1 - distance2;
    }
  });
}

export function thermalExpansionDistanceToRailedParentStart(thermalExpansion) {
  const segmentStartLonLat = thermalExpansion.segment.startLatLng.toLonLat;
  const thermalExpansionStartLonLat = thermalExpansion.startLatLng.toLonLat;
  return getDistance(segmentStartLonLat, thermalExpansionStartLonLat) * METERS_TO_INCHES;
}
