import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import InDialogController from "../controller_in_dialog";
import ChosenPanelDisplay from "../../components/PanelPicker/ChosenPanelDisplay";
import PanelPicker from "../../components/PanelPicker";
import SentryErrorHandler from "../../components/SentryErrorHandler";

import { showDialog, hideDialog } from "../components/ir_dialog/helper";
import * as api from "../../helpers/api";
import { TAB_IR, TAB_CP } from "../../components/PanelPicker/TabNav";

export default class extends InDialogController {
  static targets = ["manufacturerIdField", "panelIdField", "selectedPanelContainer"];

  connect() {
    if (!this.isOkayToConnect) return;

    this.projectId = this.data.get("projectId");
    this.projectType = this.data.get("projectType");
    this.manufacturers = JSON.parse(this.data.get("manufacturers"));
    this.customPanelsManufacturerId = Number.parseInt(this.data.get("customPanelsManufacturerId"));
    this.panels = JSON.parse(this.data.get("panels"));
    this.customPanels = JSON.parse(this.data.get("customPanels"));
    this.selectedManufacturerId = this.manufacturerIdFromField;
    this.selectedPanelId = this.panelIdFromField;
    this.persistedSelectedPanelId = this.panelIdFromField;
    this.persistedSelectedPanel = this.panels.concat(this.customPanels).find((p) => p.id === this.panelIdFromField);
    this.manufacturerPanelsCache = {};
    this.currentUser = JSON.parse(this.data.get("currentUser"));
    this.projectPanelUser = JSON.parse(this.data.get("projectPanelUser"));
    this.disabled = this.data.get("disabled") === "true";
    this.enableAllPanels = this.data.get("enable-all-panels") === "true";
    this.idSuffix = this.data.get("id-suffix");
    this.railPlatform = this.data.get("railPlatform");
    this.endClamp = this.data.get("endClamp");
    this.efoAvailable = this.data.get("efoAvailable") === "true";
    this.stopperAvailable = this.data.get("stopperAvailable") === "true";
    this.createdBefore42mmStopperSleeveUfoCutoffDate =
      this.data.get("createdBefore-42mmStopperSleeveUfoCutoffDate") === "true";

    this.addPanelsToManufacturerPanelsCache(this.selectedManufacturerId, this.panels);
    this.instantiateChosenPanelDisplay();
    this.instantiatePanelPicker();

    this.element.controller = this;
  }

  disconnect() {
    this.panelPickerComponent = undefined;
    this.displayComponent = undefined;
  }

  updateRailPlatform = (railPlatform) => {
    this.railPlatform = railPlatform;
    this.data.set("railPlatform", railPlatform);
    this.panelPickerComponent.setState({ railPlatform });
    this.displayComponent.setState({ railPlatform });

    if (Number.isNaN(this.selectedPanelId)) return;

    const detailsTabs = document.querySelector(`[data-identifier='panel${this.selectedPanelId}DetailsTabs']`);
    if (detailsTabs) detailsTabs.tabsController.setTabWithValueToActive(railPlatform);
  };

  updateEndClamp = (endClamp) => {
    this.endClamp = endClamp;
    this.data.set("endClamp", endClamp);
    this.panelPickerComponent.setState({ endClamp });
  };

  addPanelsToManufacturerPanelsCache(manufacturerId, panels) {
    if (!manufacturerId || !panels) return;

    this.manufacturerPanelsCache[manufacturerId] = panels;
  }

  get manufacturerIdFromField() {
    return Number.parseInt(this.manufacturerIdFieldTarget.value);
  }

  get panelIdFromField() {
    return Number.parseInt(this.panelIdFieldTarget.value);
  }

  instantiateChosenPanelDisplay() {
    const ref = React.createRef();

    ReactDOM.render(
      <SentryErrorHandler>
        <ChosenPanelDisplay
          ref={ref}
          openPicker={this.openPicker}
          projectType={this.projectType}
          manufacturers={this.manufacturers}
          selectedManufacturerId={this.selectedManufacturerId}
          panels={this.panels}
          customPanels={this.customPanels}
          selectedPanelId={this.selectedPanelId}
          currentUser={this.currentUser}
          projectPanelUser={this.projectPanelUser}
          disabled={this.disabled}
          projectId={this.projectId}
          railPlatform={this.railPlatform}
          efoAvailable={this.efoAvailable}
          stopperAvailable={this.stopperAvailable}
        />
      </SentryErrorHandler>,
      this.selectedPanelContainerTarget,
    );

    this.displayComponent = ref.current;
  }

  openPicker = (event) => {
    event.preventDefault();
    if (this.disabled) return;

    this.refreshPanelPickerData();
    const clickPosition = { x: event.pageX, y: event.pageY };
    showDialog(
      this.dialog,
      () => {
        this.focusFirstFormElement(this.dialog);
      },
      { showNearClickPosition: true, clickPosition },
    );
  };

  focusFirstFormElement(dialog) {
    const input = dialog.querySelector("input[type='text']");
    if (!input) return;

    input.focus();
  }

  updatePanelAndManufacturerSelection = (manufacturerId, panel) => {
    const panelId = panel.id;
    const changeEvent = new CustomEvent("change", { bubbles: true });

    const isCustomPanel = !manufacturerId && panel.user_id !== null;
    this.selectedManufacturerId = isCustomPanel ? this.customPanelsManufacturerId : manufacturerId;

    this.manufacturerIdFieldTarget.value = this.selectedManufacturerId;
    this.manufacturerIdFieldTarget.dispatchEvent(changeEvent);

    this.selectedPanelId = panelId;
    this.panelIdFieldTarget.value = panelId;
    this.panelIdFieldTarget.dispatchEvent(changeEvent);

    this.panels = this.manufacturerPanelsCache[this.selectedManufacturerId];

    hideDialog(this.dialog);

    this.displayComponent.setState({
      panels: this.panels,
      selectedPanelId: panelId,
      manufacturers: this.manufacturers,
      selectedManufacturerId: manufacturerId,
    });

    document.querySelector(".pp__chosen-panel").focus();
  };

  retrievePanelsForManufacturer = (selectedManufacturerId) => {
    const panelsFromCache = this.manufacturerPanelsCache[selectedManufacturerId];
    if (panelsFromCache !== undefined) {
      this.usePanels(panelsFromCache);
      return;
    }
    this.panelPickerComponent.setState({ loadingPanels: true });

    axios({
      method: "GET",
      url: `/manufacturers/${selectedManufacturerId}/panels`,
      headers: api.defaultHeaders(),
    }).then((response) => {
      this.panelPickerComponent.setState({ loadingPanels: false });
      const { panels } = response.data;
      this.usePanels(panels);
      this.addPanelsToManufacturerPanelsCache(selectedManufacturerId, panels);
    });
  };

  usePanels = (panels) => {
    this.panels = panels;
    this.panelPickerComponent.setState({ panels });
  };

  refreshPanelPickerData() {
    let selectedManufacturerId = this.manufacturerIdFromField;
    let activeTab = TAB_IR;
    if (selectedManufacturerId === this.customPanelsManufacturerId) {
      selectedManufacturerId = null;
      activeTab = TAB_CP;
    }

    this.panelPickerComponent.setState({
      panels: this.manufacturerPanelsCache[this.selectedManufacturerId],
      selectedPanelId: this.panelIdFromField,
      manufacturers: this.manufacturers,
      selectedManufacturerId,
      activeTab,
      endClamp: this.endClamp,
    });
  }

  get dialog() {
    let dialogId = "panel-picker-dialog";
    if (this.idSuffix) {
      dialogId += `-${this.idSuffix}`;
    }
    return document.querySelector(`[data-dialog-identifier="${dialogId}"]`);
  }

  instantiatePanelPicker() {
    const ref = React.createRef();

    ReactDOM.render(
      <SentryErrorHandler>
        <PanelPicker
          ref={ref}
          projectId={this.projectId}
          projectType={this.projectType}
          manufacturers={this.manufacturers}
          selectedManufacturerId={this.selectedManufacturerId}
          customPanelsManufacturerId={this.customPanelsManufacturerId}
          panels={this.panels}
          selectedPanelId={this.selectedPanelId}
          persistedSelectedPanel={this.persistedSelectedPanel}
          endClamp={this.endClamp}
          panelSelected={this.updatePanelAndManufacturerSelection.bind(this)}
          hidePanelPickerDialog={() => hideDialog(this.dialog)}
          retrievePanelsForManufacturer={this.retrievePanelsForManufacturer}
          customPanels={this.customPanels}
          currentUser={this.currentUser}
          projectPanelUser={this.projectPanelUser}
          enableAllPanels={this.enableAllPanels}
          railPlatform={this.railPlatform}
          efoAvailable={this.efoAvailable}
          stopperAvailable={this.stopperAvailable}
          createdBefore42mmStopperSleeveUfoCutoffDate={this.createdBefore42mmStopperSleeveUfoCutoffDate}
        />
      </SentryErrorHandler>,
      document.querySelector("[data-identifier='panelPickerContainer']"),
    );

    this.panelPickerComponent = ref.current;
  }
}
