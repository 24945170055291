import RoofInformationController from "../da/roof_information_controller";
import { addOptionsToSelect } from "../../helpers/form";
import * as animate from "../../helpers/animate";
import { hideDialog, showDialog } from "../components/ir_dialog/helper";
import { elementInfo } from "../../helpers/app";

export default class extends RoofInformationController {
  static projectType = "pr";

  static targets = [
    ...RoofInformationController.targets,
    "railPlatformSelect",
    "conduitMountsContainer",
    "defaultRafterSpacingLabel",
    "fvGripCapPlusContainer",
    "fvGripCapPlusCount",
    "metalShingleInfoContainer",
    "qmConduitMountPenetrationFlashingsForCompShingleContainer",
    "qmConduitMountPenetrationFlashingsForTileContainer",
    "qmConduitMountsForTileContainer",
    "qmTileReplacementFlashingForTileHooksContainer",
    "qmTileReplacementFlashingForTileHooksRadioNo",
    "qmTileDeckFlashingContainer",
    "qmTileDeckFlashingRadioNo",
    "roofManufacturerContainer",
    "roofManufacturerSelect",
    "roofMaterialFamilyInfoPopup",
    "roofMaterialInfoPopup",
    "roofSlopes",
    "shingleAndFlashingContainer",
    "shingleAndFlashingTypeSelect",
    "deckThicknessContainer",
    "qmHugScrewsSubText",
    "lFootSubText",
    "specificGravitySelect",
  ];

  static values = {
    ...RoofInformationController.values,
    roofAttachmentToSpecificGravities: Object,
    flpa3RoofAttachmentToSpecificGravities: Object,
    flpa3Project: Boolean,
  };

  connect() {
    this.railPlatform = this.data.get("rail-platform");
    this.materialToAttachmentXr = JSON.parse(this.data.get("material-to-attachment-xr"));
    this.materialToAttachmentAire = JSON.parse(this.data.get("material-to-attachment-aire"));
    this.materialFamilyToRoofMaterials = JSON.parse(this.data.get("material-family-to-roof-materials"));
    this.roofSlopes = JSON.parse(this.data.get("roof-slopes"));
    this.minimumRoofSlopes = JSON.parse(this.data.get("minimum-roof-slopes"));
    this.shingleAndFlashingTypesToAttachments = JSON.parse(this.data.get("shingleAndFlashingTypesToAttachments"));
    this.fallbackMinimumRoofSlope = JSON.parse(this.data.get("fallback-minimum-roof-slope"));

    this.previousRoofMaterial = this.roofMaterial;

    this.toggleRoofMaterialFamilyInfoPopup();
    this.toggleRoofMaterialInfoPopup();

    this.changeRoofAttachment();
  }

  // NOTE: This action will only fire on the preset page
  changeRailPlatform() {
    this.railPlatform = this.railPlatformSelectTarget.value;

    this.#syncAttachmentListWithRailPlatform();
    this.updateDependenciesOnAttachmentList();
  }

  changeRoofAttachment(event) {
    this.toggleRoofAttachmentInfoPopup();
    this.#toggleRoofAttachmentsInInfoPopup();
    this.#updateRoofSlopes();
    this.changeShingleAndFlashingType();
    this.toggleMetalShingleInfoBox();
    this.toggleTileReplacementFlashingForTileHooks();
    this.toggleTileDeckFlashing();
    this.toggleFvGripCapPlus();
    this.toggleDeckThickness();
    this.#toggleSubText();
    this.#disableRoofAttachmentsIfNeeded();
    this.#updateSpecificGravity();
    if (event) {
      this.#openQmHugScrewsIntro(event);
    }
  }

  changeShingleAndFlashingType(event) {
    animate.toggle(this.shingleAndFlashingContainerTarget, this.isMetalShingle);

    this.#disableRoofAttachmentsIfNeeded();

    if (!this.isMetalShingle) {
      this.shingleAndFlashingTypeSelectTarget.value = "";
      return;
    }

    const attachments = this.isShingleAndFlashingTypeEmpty
      ? []
      : this.shingleAndFlashingTypesToAttachments[this.shingleAndFlashingType];
    addOptionsToSelect(attachments, this.roofAttachmentSelectTarget);

    // this is being called from the shingle and flashing type select list change handler
    // so we need to toggle the attachment info popup
    if (event) {
      this.toggleRoofAttachmentInfoPopup();
      this.#toggleRoofAttachmentsInInfoPopup();
      if (this.roofAttachment) this.#updateRoofSlopes();
    }
  }

  showHideRoofMaterialSpecificFormElements() {
    const isCompShingle = this.roofMaterial === "cs";
    const isTile = this.roofMaterial.startsWith("tile_");
    this.selectPenetrationFlashing(isTile, isCompShingle);
    const showHide = (elementTarget, show) => {
      animate.toggle(this[elementTarget], show, { fadeOut: false });
    };
    showHide("conduitMountsContainerTarget", isCompShingle);
    showHide("qmConduitMountsForTileContainerTarget", isTile);
    showHide("qmConduitMountPenetrationFlashingsForCompShingleContainerTarget", isCompShingle);
    showHide("qmConduitMountPenetrationFlashingsForTileContainerTarget", isTile);
  }

  toggleMetalShingleInfoBox() {
    animate.toggle(this.metalShingleInfoContainerTarget, this.isMetalShingle);
  }

  toggleTileReplacementFlashingForTileHooks() {
    // Show if All tile hook or QuickHook
    const show = ["ath", "ath_f", "qm_qh_lh", "qm_qh_lh_f", "qm_qh_sh", "qm_qh_sh_f"].includes(this.roofAttachment);
    if (this.hasQmTileReplacementFlashingForTileHooksRadioNoTarget && !show) {
      this.qmTileReplacementFlashingForTileHooksRadioNoTarget.checked = true;
    }
    if (this.hasQmTileReplacementFlashingForTileHooksContainerTarget) {
      animate.toggle(this.qmTileReplacementFlashingForTileHooksContainerTarget, show);
    }
  }

  toggleTileDeckFlashing() {
    // Show unless qm_tile_replacement or qbase_tile
    const isTile = this.roofMaterial.startsWith("tile_");
    const show = isTile && !["qm_tr", "qm_qb_utm"].includes(this.roofAttachment);
    if (this.hasQmTileDeckFlashingRadioNoTarget && !show) {
      this.qmTileDeckFlashingRadioNoTarget.checked = true;
    }
    if (this.hasQmTileDeckFlashingContainerTarget) {
      animate.toggle(this.qmTileDeckFlashingContainerTarget, show);
    }
    if (isTile) {
      this.#toggleTileDeckFlashingInfoPopup();
    }
  }

  toggleFvGripCapPlus() {
    const show = this.roofAttachment === "fv";
    if (this.hasFvGripCapPlusCountTarget && !show) {
      this.fvGripCapPlusCountTarget.value = 0;
    }
    if (this.hasFvGripCapPlusContainerTarget) {
      animate.toggle(this.fvGripCapPlusContainerTarget, show);
    }
  }

  toggleDeckThickness() {
    if (this.hasDeckThicknessContainerTarget) {
      animate.toggle(this.deckThicknessContainerTarget, this.roofAttachment === "qm_hug_d");
    }
  }

  selectPenetrationFlashing(isTile, isCompShingle) {
    if (!isTile && !isCompShingle) return;

    const penFlashRadio = (material, trueFalse) => {
      return this[`qmConduitMountPenetrationFlashingsFor${material}ContainerTarget`].querySelector(
        `input[value='${trueFalse}']`,
      );
    };

    const penFlashForCompShingleRadioTrue = penFlashRadio("CompShingle", "true");
    const penFlashForCompShingleRadioFalse = penFlashRadio("CompShingle", "false");
    const penFlashForTileRadioTrue = penFlashRadio("Tile", "true");
    const penFlashForTileRadioFalse = penFlashRadio("Tile", "false");

    if (isTile) {
      if (penFlashForCompShingleRadioTrue.checked) {
        penFlashForTileRadioTrue.checked = true;
      } else {
        penFlashForTileRadioFalse.checked = true;
      }
    }

    if (isCompShingle) {
      if (penFlashForTileRadioTrue.checked) {
        penFlashForCompShingleRadioTrue.checked = true;
      } else {
        penFlashForCompShingleRadioFalse.checked = true;
      }
    }
  }

  get isMetalShingle() {
    return this.roofMaterial === "metal_shingle";
  }

  get shingleAndFlashingType() {
    return this.shingleAndFlashingTypeSelectTarget.value;
  }

  get isShingleAndFlashingTypeEmpty() {
    return this.shingleAndFlashingType === "";
  }

  updateAttachmentList() {
    if (this.#stayedWithTile(this.roofMaterial)) {
      this.previousRoofMaterial = this.roofMaterial;
      return;
    }

    const possibleAttachments = this.roofMaterial === "" ? [] : this.#materialToAttachment(this.roofMaterial);

    const prompt = possibleAttachments.length > 1 ? [["- choose one -", ""]] : [];
    const attachmentOptions = prompt.concat(possibleAttachments.map((a) => [a[0], a[1], false, false]));
    addOptionsToSelect(attachmentOptions, this.roofAttachmentSelectTarget, false);
    this.previousRoofMaterial = this.roofMaterial;
  }

  #syncAttachmentListWithRailPlatform() {
    const currentAttachment = this.roofAttachmentSelectTarget.value;
    const possibleAttachments = this.roofMaterial === "" ? [] : this.#materialToAttachment(this.roofMaterial);

    const prompt = possibleAttachments.length > 1 ? [["- choose one -", ""]] : [];
    // keep the current attachment selected if it is present in the list of possibilities
    const attachmentOptions = prompt.concat(
      possibleAttachments.map((a) => [a[0], a[1], false, a[1] === currentAttachment]),
    );
    addOptionsToSelect(attachmentOptions, this.roofAttachmentSelectTarget, false);
  }

  #materialToAttachment(roofMaterial) {
    const attachments = this.railPlatform === "aire" ? this.materialToAttachmentAire : this.materialToAttachmentXr;

    return attachments[roofMaterial];
  }

  updateDependenciesOnAttachmentList() {
    this.changeRoofAttachment();
    this.showHideRoofMaterialSpecificFormElements();
    this.toggleRoofMaterialInfoPopup();
  }

  #stayedWithTile(selectedMaterial) {
    return (
      selectedMaterial.startsWith("tile_") && this.previousRoofMaterial && this.previousRoofMaterial.startsWith("tile_")
    );
  }

  #updateRoofSlopes() {
    if (!this.hasRoofSlopesTarget) return;

    const currentRoofSlope = Number.parseInt(this.roofSlopesTarget.value);
    const minimumSlopeForSelectedAttachment = this.minimumRoofSlopes[this.roofAttachment];
    const minimumRoofSlope = minimumSlopeForSelectedAttachment === undefined ? 0 : minimumSlopeForSelectedAttachment;

    let selectedSlope = "";
    if (
      this.roofSlopesTarget.value !== "" &&
      this.roofAttachment !== "" &&
      currentRoofSlope >= minimumSlopeForSelectedAttachment
    ) {
      selectedSlope = currentRoofSlope;
    }

    const opts = this.roofSlopes
      .filter((opt) => opt[1] >= minimumRoofSlope)
      .map((opt) => [opt[0], opt[1], false, opt[1] === selectedSlope]);

    addOptionsToSelect(opts, this.roofSlopesTarget, true, "- set slope -");
  }

  #toggleRoofAttachmentsInInfoPopup(_event) {
    const dialog = document.querySelector("[data-dialog-identifier=info-popup-pr-roof-attachment]");
    const sections = dialog.querySelectorAll("[data-roof-attachment]");
    if (this.isRoofAttachmentEmpty) return;

    let showSelector = `[data-roof-attachment=${this.roofAttachment}]`;

    if (this.roofAttachment === "ath") {
      if (this.railPlatform === "aire") {
        showSelector += "[data-rail-platform=aire]";
      } else {
        showSelector += "[data-rail-platform=xr]";
      }
    }

    for (let section of sections) {
      section.style.display = section.matches(showSelector) ? "block" : "none";
    }
  }

  #toggleTileDeckFlashingInfoPopup(_event) {
    const dialog = document.querySelector("[data-dialog-identifier=info-popup-pr-tile-deck-flashing]");

    if (this.roofAttachment === "ko_f") {
      dialog.querySelector("[data-tile-hook-deck-flashing=false]").style.display = "block";
      dialog.querySelector("[data-tile-hook-deck-flashing=true]").style.display = "none";
    } else {
      dialog.querySelector("[data-tile-hook-deck-flashing=false]").style.display = "none";
      dialog.querySelector("[data-tile-hook-deck-flashing=true]").style.display = "block";
    }
  }

  #disableRoofAttachmentsIfNeeded() {
    this.roofAttachmentSelectTarget.disabled =
      (this.isMetalShingle && this.isShingleAndFlashingTypeEmpty) || this.roofMaterial === "";
  }

  #toggleSubText() {
    if (this.hasQmHugScrewsSubTextTarget) {
      animate.toggle(
        this.qmHugScrewsSubTextTarget,
        this.roofAttachment === "qm_hug_r" || this.roofAttachment === "qm_hug_d",
      );
    }

    if (this.hasLFootSubTextTarget) {
      animate.toggle(this.lFootSubTextTarget, this.roofAttachment === "lfoot");
    }
  }

  toggleRoofMaterialInfoPopup() {
    if (this.roofMaterial === "") {
      this.roofMaterialInfoPopupTarget.classList.add("d-none");
      return;
    }

    const dialog = document.querySelector("[data-dialog-identifier=info-popup-pr-roof-material]");
    const sections = dialog.querySelectorAll("[data-roof-material]");

    let showSelector = `[data-roof-material=${this.roofMaterial}]`;
    let sectionMatchExists = false;

    for (let section of sections) {
      if (section.matches(showSelector)) {
        section.style.display = "block";
        sectionMatchExists = true;
      } else {
        section.style.display = "none";
      }
    }

    if (sectionMatchExists) {
      this.roofMaterialInfoPopupTarget.classList.remove("d-none");
    } else {
      hideDialog(dialog);
      this.roofMaterialInfoPopupTarget.classList.add("d-none");
    }
  }

  #openQmHugScrewsIntro(event) {
    const isQmHug = this.roofAttachment === "qm_hug_r" || this.roofAttachment === "qm_hug_d";
    if (!isQmHug) return;

    // If the introduction is not active the dialog will not be present on the page
    const dialog = document.querySelector("[data-dialog-identifier='info-popup-pr-qmhug-screws']");
    if (!dialog || !dialog.querySelector("[data-identifier='content']")) return;

    const elemInfo = elementInfo(event.currentTarget);
    showDialog("info-popup-pr-qmhug-screws", () => {}, {
      showNearClickPosition: true,
      clickPosition: elemInfo.center,
    });
  }

  #updateSpecificGravity() {
    if (!this.hasSpecificGravitySelectTarget) return;
    if (!this.roofAttachmentToSpecificGravitiesValue) return;

    const roofAttachmentToSGs = this.flpa3ProjectValue
      ? this.flpa3RoofAttachmentToSpecificGravitiesValue
      : this.roofAttachmentToSpecificGravitiesValue;
    const specificGravities = roofAttachmentToSGs[this.roofAttachment];
    const currentSG = Number.parseFloat(this.specificGravitySelectTarget.value);
    if (!specificGravities) {
      animate.hide(this.specificGravitySelectTarget.parentElement);
    } else {
      animate.show(this.specificGravitySelectTarget.parentElement);
      const minSpecificGravity = Math.min(...specificGravities);
      const sgOptions = specificGravities.map((sg) => [sg, sg, false, sg === currentSG]);
      addOptionsToSelect(sgOptions, this.specificGravitySelectTarget, false);

      if (!currentSG || !specificGravities.includes(currentSG)) {
        this.specificGravitySelectTarget.value = minSpecificGravity;
      }
    }
  }
}
