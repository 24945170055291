import { segmentsFlushAroundThermalExpansion } from "../modification-helpers/thermal-expansions/helpers";

export const MAX_THERMAL_EXPANSION_GAP_FOR_BONDING_JUMPERS = 7; // inches

export default class ThermalExpansionGaps {
  constructor({ controller, segment }) {
    this.controller = controller;
    this.mapManager = controller.mapManager;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;

    this.segment = segment;
    this.thermalExpansionGaps = this.#getThermalExpansionGaps();
  }

  get hasGapThatExceedsBondingJumperCompatibility() {
    return this.thermalExpansionGaps.some((g) => g > MAX_THERMAL_EXPANSION_GAP_FOR_BONDING_JUMPERS);
  }

  #getThermalExpansionGaps() {
    if (this.segment.thermalExpansions.length === 0 || this.segment.deleted) return [];

    const result = [];
    this.segment.thermalExpansions.forEach((thermalExpansion) => {
      const [segmentBefore, segmentAfter] = segmentsFlushAroundThermalExpansion(this.controller, thermalExpansion);
      if (!segmentBefore || !segmentAfter) return;

      const distance = this.mapModelSynchronizer.getDistanceInInches(
        segmentBefore.latLngPoints[1],
        segmentAfter.startLatLng,
      );
      result.push(distance);
    });

    return result;
  }
}
