import { types } from "mobx-state-tree";
import { v4 as uuid } from "uuid";

import RoofSectionModel, { roofSectionPersistenceData } from "./roof-section-model";
import LatLngModel from "../../da/models/lat-lng-model";
import DaRoofPlaneModel from "../../da/models/roof-plane-model";
import { pointsForSave } from "../../da/models/points-utilities";
import GuideLineModel, { guideLinePersistenceData } from "../../da/map/models/guide-line-model";
import WithCartesianPoints from "../../da/models/with-cartesian-points";
import CartesianModel from "../../da/models/cartesian-model";

import { logger } from "../../helpers/app";

const RoofPlaneBase = types
  .model("RoofPlaneModel", {
    roofSections: types.array(RoofSectionModel),
    guideLines: types.array(GuideLineModel),
    globalCartesianOffset: types.maybe(CartesianModel),
  })
  .views((self) => ({
    get customRoofSections() {
      return self.roofSections.filter((roofSection) => roofSection.custom && !roofSection.deleted);
    },
    get hasCustomRoofSections() {
      return self.customRoofSections.length > 0;
    },
    get isRoofSectionRequired() {
      return !self.hasActiveRoofSections && self.hasDefaultRoofSections;
    },
    get hasInactiveDefaultRoofSections() {
      return self.defaultRoofSections.some((rs) => !rs.active);
    },
    get panelsPresent() {
      if (self.deleted) return 0;

      return self.roofSections.reduce((count, roofSection) => {
        return count + roofSection.panelsPresent;
      }, 0);
    },
    get panelWattage() {
      return self.project.panelWattage;
    },
    get wattage() {
      // don't need to worry about checking deleted since panelsPresent will be 0 if it is
      return self.panelsPresent * self.panelWattage;
    },
    get interRowSpacing() {
      return self.project.interRowSpacing;
    },
    get interColumnSpacing() {
      return self.project.interColumnSpacing;
    },
    guideLineForSegmentIndex(segmentIndex) {
      return self.guideLines.find((sg) => sg.segmentIndex === segmentIndex);
    },
    get needsSave() {
      const anyGuideLinesNeedSave = self.guideLines.some((sg) => sg.needsSave);
      return self.baseNeedsSave || anyGuideLinesNeedSave;
    },
  }))
  .actions((self) => ({
    addRoofSection(params) {
      const defaultParams = {
        uuid: uuid(),
        azimuth: self.project.azimuth,
        custom: true,
        active: true,
        identifier: self.getNextSequentialRoofSectionIdentifier(),
        offset: LatLngModel.create({ lat: 0, lng: 0 }),
        offsetCartesianPoint: CartesianModel.create({ x: 0, y: 0 }),
        fresh: true,
      };
      const mergedParams = { ...defaultParams, ...params };
      const roofSection = RoofSectionModel.create(mergedParams);
      self.roofSections.push(roofSection);

      return roofSection;
    },
    addNewCustomRoofSectionBasedOnADefaultBeingDeactivated(sourceRoofSection, copyLayout) {
      const roofSection = RoofSectionModel.create({
        uuid: uuid(),
        azimuth: sourceRoofSection.azimuth,
        identifier: sourceRoofSection.identifier,
        custom: true,
        active: true,
        illegalShape: sourceRoofSection.illegalShape,
        offset: LatLngModel.create({ lat: 0, lng: 0 }),
        offsetCartesianPoint: CartesianModel.create({ x: 0, y: 0 }),
        fresh: sourceRoofSection.fresh,
      });
      if (copyLayout) {
        roofSection.copyLayoutFrom(sourceRoofSection);
      }
      self.roofSections.push(roofSection);

      sourceRoofSection.deactivate();

      return roofSection;
    },
    applyUpdatesReturnedBySave(json) {
      const roofSectionsJson = json?.["roofSections"];

      self.roofSections.forEach((roofSection) => {
        const roofSectionJson = roofSectionsJson?.find((rsJson) => rsJson["uuid"] === roofSection.uuid);
        roofSection.applyUpdatesReturnedBySave(roofSectionJson);
      });
    },
    flagDeleted() {
      if (!self.deleted) {
        self.markDirty();
        self.deleted = true;
        self.project.snapshotRoofPlaneDeletion(self);
      }
      self.roofSections.forEach((rs) => rs.flagDeleted());
    },
    clearGuideLines() {
      if (self.guideLines.length > 0) self.markDirty();
      self.guideLines = [];
    },
    setGuideLines(newGuideLines) {
      self.markDirty();
      self.guideLines = newGuideLines;
    },
    setGlobalCartesianOffset(delta) {
      if (
        self.globalCartesianOffset &&
        self.globalCartesianOffset.x === delta.x &&
        self.globalCartesianOffset.y === delta.y
      )
        return;

      self.markDirty();
      self.globalCartesianOffset = delta;
    },
  }));

export function roofPlanePersistenceData(roofPlane) {
  logger(`Persisting Roof Plane ${roofPlane.displayIdentifier}`);

  if (!roofPlane.needsSave) {
    logger("--!needsSave. Skipping");
    return undefined;
  }

  const latLngPoints = pointsForSave(roofPlane, "latLngPoints");
  const cartesianPoints = pointsForSave(roofPlane, "cartesianPoints");
  const globalCartesianOffset = roofPlane.globalCartesianOffset?.toArray || [0, 0];

  const data = {
    uuid: roofPlane.uuid,
    id: roofPlane.id,
    identifier: roofPlane.identifier,
    lat_lng_points: latLngPoints,
    cartesian_points: cartesianPoints,
    illegal_shape: roofPlane.illegalShape,
    global_cartesian_offset: globalCartesianOffset,
    _destroy: roofPlane.deleted,
    roof_sections_attributes: roofPlane.roofSections.map((roofSection) => roofSectionPersistenceData(roofSection)),
  };

  if (roofPlane.guideLines.length > 0) {
    data.guide_lines = roofPlane.guideLines.map(guideLinePersistenceData);
  }
  return data;
}

const RoofPlaneModel = types.compose(DaRoofPlaneModel, WithCartesianPoints, RoofPlaneBase);
export default RoofPlaneModel;
