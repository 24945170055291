import { toLonLat, fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import { getDistance } from "ol/sphere";
import { Point } from "ol/geom";

import {
  calculateUnitPerpendicularEdgeVectorLatLng,
  cartesianPointRelativeTo,
  findTopLeftLonLat,
  lineIntersectionInCoordinates,
  METERS_TO_INCHES,
} from "./ol-geometry";
import {
  buildLineStringSegmentFeaturesFromPolygonCoordinates,
  isPolygon,
  relativePosition,
  segmentCoordinatesFromPolygon,
} from "./ol-helpers";

import {
  GUIDE_LINES_INTERSECTION_CIRCLE_DATA_TYPE,
  GUIDE_LINE_DATA_TYPE,
  OBSTRUCTION_DATA_TYPE,
  ROOF_PLANE_DATA_TYPE,
  ROOF_SECTION_DATA_TYPE,
  SETBACK_DATA_TYPE,
} from "./data-types";
import SimpleLatLng from "./models/simple-lat-lng";
import { buildLengthCorrectedEdgeList } from "./edge-list";
import { degreesToRadians } from "../../helpers/geometry";

export default class MapModelSynchronizer {
  constructor(controller) {
    this.controller = controller;

    this.project = controller.project;
  }

  get saveBtnTarget() {
    return controller.saveBtnTarget;
  }

  // Translate lon/lat coordinates to projection coordinates and create polygon features
  featuresForDisplayableRoofPlanes() {
    return this.project.displayableRoofPlanes.map((roofPlane) => {
      const feature = this.createFeatureForModel(roofPlane);

      // TODO: PRIB: Remove once we've got legacy projects updated to have rotation angles (just projects on staging)
      // This is to catch existing projects that were saved before we persisted rotation angle
      if (roofPlane.eaveRotationAngleRadians === -1 && roofPlane.eaveEdgeIndex !== null) {
        const edgeList = buildLengthCorrectedEdgeList(feature, this.controller);
        const eaveRotationAngleRadians = Math.PI - edgeList[0].rotationAngle;
        roofPlane.setEaveRotationAngleRadians(eaveRotationAngleRadians);
      }

      return feature;
    });
  }

  featuresForDisplayableRoofSections() {
    return this.project.displayableRoofPlanes.flatMap((roofPlane) => {
      return roofPlane.displayableRoofSections.map((roofSection) => this.createFeatureForModel(roofSection));
    });
  }

  updateRoofPlaneLatLngFromFeature(feature) {
    const roofPlane = this.getRoofPlaneForFeature(feature, "DaMapModelSynchronizer#updateRoofPlaneLatLngFromFeature");
    if (!roofPlane) return;

    const latLngPoints = this.latLngPointsFromFeature(feature);
    roofPlane.setLatLngPoints(latLngPoints);

    if (this.project.isBX) {
      this.setGlobalCartesianOffset(latLngPoints, roofPlane);
    }
  }

  updateObstructionLatLngFromFeature(feature) {
    const obstruction = this.getObstructionForFeature(
      feature,
      "DaMapModelSynchronizer#updateObstructionLatLngFromFeature",
    );
    if (!obstruction) return;

    const latLngPoints = this.latLngPointsFromFeature(feature);
    obstruction.setLatLngPoints(latLngPoints);

    this.setGlobalCartesianOffset(latLngPoints, obstruction);
  }

  setGlobalCartesianOffset(latLngPoints, model) {
    if (!this.project.isBX) return;

    const topLeftLonLat = findTopLeftLonLat(latLngPoints);
    const globalOriginLonLat = this.project.globalOrigin.toLonLat;
    const delta = cartesianPointRelativeTo(topLeftLonLat, globalOriginLonLat);
    model.setGlobalCartesianOffset(delta);
  }

  getRoofSectionForFeature(feature) {
    if (!isPolygon(feature)) return;
    if (!feature.get("dataType") === ROOF_SECTION_DATA_TYPE) return;

    const roofSection = this.project.getRoofSection(feature.get("uuid"));

    if (!roofSection) {
      debugger;
      throw new Error(`No matching roof section for ${feature.get("uuid")}`);
    }

    return roofSection;
  }

  getFeatureForRoofSection(roofSection, requireMatch = true) {
    const mapManager = this.controller.mapManager;
    const roofSectionsVectorSource = mapManager.roofSectionsVectorSource;

    return this.matchFeatureByUuid(roofSectionsVectorSource, roofSection.uuid, requireMatch);
  }

  updateAllRoofSectionsIllegalityFromFeature(inFlightFeatures = []) {
    const features = inFlightFeatures.concat(this.controller.mapManager.roofSectionsFeatures);

    features.forEach((feature) => {
      const roofSection = this.getRoofSectionForFeature(feature);
      if (!roofSection) return;

      roofSection.setIllegalShape(feature.get("illegalShape"));
      roofSection.setEncroaching(feature.get("encroaching"));
    });
  }

  updateRoofSectionLatLngFromFeature(feature) {
    const roofSection = this.getRoofSectionForFeature(feature);
    if (!roofSection) return;

    const latLngPoints = this.latLngPointsFromFeature(feature);
    roofSection.setLatLngPoints(latLngPoints);
  }

  latLngPointsFromFeature(feature) {
    return feature
      .getGeometry()
      .getCoordinates()
      .map((featureCoordinates) => {
        return featureCoordinates.map((c) => {
          const lonLat = toLonLat(c);
          return { lat: lonLat[1], lng: lonLat[0] };
        });
      })[0];
  }

  getRoofPlaneForFeature(feature, calledFrom) {
    if (!isPolygon(feature)) return;
    if (!feature.get("dataType") === ROOF_PLANE_DATA_TYPE) return;

    const roofPlane = this.project.getRoofPlane(feature.get("uuid"));
    if (!roofPlane) {
      debugger;
      throw new Error(`No matching roof plane for ${feature.get("uuid")} (called from ${calledFrom})`);
    }

    return roofPlane;
  }

  getFeatureForRoofPlane(roofPlane) {
    if (roofPlane.deleted) return;

    const mapManager = this.controller.mapManager;
    const roofPlanesVectorSource = mapManager.roofPlanesVectorSource;

    return this.matchFeatureByUuid(roofPlanesVectorSource, roofPlane.uuid, true);
  }

  getRoofPlaneFeatureByUuid(uuid) {
    const mapManager = this.controller.mapManager;
    const roofPlanesVectorSource = mapManager.roofPlanesVectorSource;

    return this.matchFeatureByUuid(roofPlanesVectorSource, uuid, true);
  }

  getObstructionForFeature(feature, calledFrom) {
    if (!isPolygon(feature)) return;
    if (!feature.get("dataType") === OBSTRUCTION_DATA_TYPE) return;

    const obstruction = this.project.getObstruction(feature.get("uuid"));
    if (!obstruction) {
      debugger;
      throw new Error(`No matching obstruction for ${feature.get("uuid")} (called from ${calledFrom})`);
    }

    return obstruction;
  }

  getFeatureForObstruction(obstruction) {
    if (obstruction.deleted) return;

    const mapManager = this.controller.mapManager;
    const obstructionsVectorSource = mapManager.obstructionsVectorSource;

    return this.matchFeatureByUuid(obstructionsVectorSource, obstruction.uuid, true);
  }

  matchFeatureByUuid(source, uuid, requireMatch = false) {
    const features = source.getFeatures();
    const matchingFeature = features.find((feature) => feature.get("uuid") === uuid);

    if (!matchingFeature && requireMatch) {
      debugger;
    }

    return matchingFeature;
  }

  updateAllRoofPlanesIllegalityFromFeature(inFlightFeatures = []) {
    const features = inFlightFeatures.concat(this.controller.mapManager.roofPlanesFeatures);

    features.forEach((feature) => {
      const roofPlane = this.getRoofPlaneForFeature(
        feature,
        "DaMapModelSynchronizer#updateAllRoofPlanesIllegalityFromFeature",
      );
      if (!roofPlane) return;

      roofPlane.setIllegalShape(feature.get("illegalShape"));
    });
  }

  updateAllObstructionsIllegalityFromFeature(inFlightFeatures = []) {
    const features = inFlightFeatures.concat(this.controller.mapManager.obstructionsFeatures);

    features.forEach((feature) => {
      const obstruction = this.getObstructionForFeature(
        feature,
        "DaMapModelSynchronizer#updateAllObstructionsIllegalityFromFeature",
      );
      if (!obstruction) return;

      obstruction.setIllegalShape(feature.get("illegalShape"));
    });
  }

  saveEditorLocation = (_event) => {
    const { map } = this.controller.mapManager;
    const view = map.getView();

    const center = toLonLat(view.getCenter());
    const zoom = view.getZoom();
    const rotation = view.getRotation();

    const site = this.project.projectSite;
    if (this.controller.isViewerPage) {
      site.setViewerSettings({ lat: center[1], lng: center[0], zoom, rotation });
    } else if (this.controller.isUnpersistedZoomRotationPositionPage) {
      site.setUnpersistedSettings({ lat: center[1], lng: center[0], zoom, rotation });
    } else if (this.shouldSetEditorSettings) {
      site.setEditorSettings({ lat: center[1], lng: center[0], zoom, rotation });
    }
  };

  shouldSetEditorSettings() {
    return true;
  }

  syncIdentifiersOnFeatures() {
    const mapManager = this.controller.mapManager;

    mapManager.roofPlanesFeatures.forEach((feature) => {
      const roofPlane = this.getRoofPlaneForFeature(feature, "DaMapModelSynchronizer#syncIdentifiersOnFeatures");
      feature.set("identifier", roofPlane.displayIdentifier);
    });
  }

  loadSetbacks() {
    return this.project.roofPlanesWithDefaultRoofSections.map((roofPlane) => this.buildSetbackForRoofPlane(roofPlane));
  }

  buildSetbackForRoofPlane(roofPlane) {
    const outerCoordinates = roofPlane.latLngPoints.map((p) => fromLonLat(p.toLonLat));
    const polygonCoordinates = [outerCoordinates];

    roofPlane.defaultRoofSections.forEach((roofSection) => {
      const innerCoordinates = roofSection.latLngPoints.map((p) => fromLonLat(p.toLonLat));
      polygonCoordinates.push(innerCoordinates);
    });

    const setbackPolygon = new Polygon(polygonCoordinates);
    const setbackFeature = new Feature({ geometry: setbackPolygon });
    setbackFeature.set("roofPlaneUuid", roofPlane.uuid);
    setbackFeature.set("dataType", SETBACK_DATA_TYPE);

    return setbackFeature;
  }

  /*
  The following methods are wrappers around OpenLayers methods we use quite a bit in our system.  OpenLayers
  doesn't play well with Jest, and the imports for these methods end up throwing an error when running
  a test on a file that uses them: `SyntaxError: Cannot use import statement outside a module`.  Using these
  methods makes it possible to easily mock out the mapModelSynchronizer when writing unit tests, which
  sidesteps the import error that pops up.
  */
  getDistanceInInches(latLng1, latLng2) {
    return getDistance(latLng1.toLonLat, latLng2.toLonLat) * METERS_TO_INCHES;
  }

  toLonLat(olCoordinate) {
    return toLonLat(olCoordinate);
  }

  fromLonLat(lonLat) {
    return fromLonLat(lonLat);
  }

  simpleLatLngFromCoordinate(coordinate) {
    return SimpleLatLng.fromLonLat(toLonLat(coordinate));
  }

  buildRoofPlaneGuideLineStringFeatures(roofPlane, options) {
    const roofPlaneCoordinates = roofPlane.latLngPoints.map((p) => fromLonLat(p.toLonLat));
    const features = buildLineStringSegmentFeaturesFromPolygonCoordinates(roofPlaneCoordinates);

    let eaveLonLats;
    const eave = roofPlane.eaveLatLngPoints; // [ [lat, lng], [lat, lng]]
    if (eave) {
      eaveLonLats = eave.map((point) => point.toLonLat);
    }
    const roofSlopeRadians = degreesToRadians(roofPlane.roofSlope);

    features.forEach((feature, i) => {
      feature.set("dataType", GUIDE_LINE_DATA_TYPE);
      feature.set("roofPlaneUuid", roofPlane.uuid);
      feature.set("lineNumber", i + 1);
      feature.set("segmentIndex", i);
      const guideLine = roofPlane[options.guideLinesKey][i];
      this.moveGuideLineInsideRoofPlaneAndExtend(feature, guideLine, eaveLonLats, roofSlopeRadians);
    });

    return features.filter((_feature, i) => roofPlane[options.guideLinesKey][i].visible);
  }

  moveGuideLineInsideRoofPlaneAndExtend(lineStringFeature, guideLine, eaveLonLats, roofSlopeRadians) {
    const lineStringGeometry = lineStringFeature.getGeometry();
    const lineStringCoordinates = lineStringGeometry.getCoordinates();

    const [startPointCoordinates, endPointCoordinates] = lineStringCoordinates;

    const startPointLonLat = toLonLat(startPointCoordinates);
    const endPointLonLat = toLonLat(endPointCoordinates);

    const startPointLatLng = SimpleLatLng.fromLonLat(startPointLonLat);
    const endPointLatLng = SimpleLatLng.fromLonLat(endPointLonLat);

    const [_midPointLatLng, unitPerpendicularEdgeVectorLatLng, unitEdgeVectorLatLng] =
      calculateUnitPerpendicularEdgeVectorLatLng(startPointCoordinates, endPointCoordinates); // [coordinate, inches]

    const rawDistance = -1 * guideLine.distance; // inches inset from edge
    let distance = rawDistance;
    if (eaveLonLats !== undefined) {
      distance = this.scaleDistance(rawDistance, unitPerpendicularEdgeVectorLatLng, eaveLonLats, roofSlopeRadians);
    }
    const offsetVectorLatLng = unitPerpendicularEdgeVectorLatLng.times(distance);

    // new end points in SimpleLatLng format
    const insetStartPointLatLng = startPointLatLng.plus(offsetVectorLatLng);
    const insetEndPointLatLng = endPointLatLng.plus(offsetVectorLatLng);

    const extensionDistance = 30 * 12; // 30 feet in inches
    const fullExtensionVector = unitEdgeVectorLatLng.times(extensionDistance);

    const fullyExtendedStartPointLatLng = insetStartPointLatLng.minus(fullExtensionVector);
    const fullyExtendedEndPointLatLng = insetEndPointLatLng.plus(fullExtensionVector);

    // new end points in OL LngLat
    const insetStartPointLonLat = fullyExtendedStartPointLatLng.toLonLat;
    const insetEndPointLonLat = fullyExtendedEndPointLatLng.toLonLat;

    // new end points in OL coordinates
    const insetStartPointCoordinates = fromLonLat(insetStartPointLonLat);
    const insetEndPointCoordinates = fromLonLat(insetEndPointLonLat);

    lineStringGeometry.setCoordinates([insetStartPointCoordinates, insetEndPointCoordinates]);
  }

  scaleDistance(distance, unitPerpendicularEdgeVectorLatLng, eaveLonLats, roofSlopeRadians) {
    const angleToPerpendicularRadians = this.angleBetweenPerpendicularAndEave(
      unitPerpendicularEdgeVectorLatLng,
      eaveLonLats,
    );
    const scaling = this.distanceScalingFactor(angleToPerpendicularRadians, roofSlopeRadians);

    return distance / scaling;
  }

  angleBetweenPerpendicularAndEave(unitPerpendicularEdgeVectorLatLng, eaveLonLats) {
    const [eaveStartLonLat, eaveEndLonLat] = eaveLonLats;
    const eaveCornerLonLat = [eaveEndLonLat[0], eaveStartLonLat[1]];

    const [eaveSignLng, eaveSignLat] = relativePosition(eaveEndLonLat, eaveStartLonLat);

    const eaveX = eaveSignLng * getDistance(eaveStartLonLat, eaveCornerLonLat);
    const eaveY = eaveSignLat * getDistance(eaveCornerLonLat, eaveEndLonLat);

    const perpendicularEndLonLat = [
      eaveStartLonLat[0] + unitPerpendicularEdgeVectorLatLng.lng,
      eaveStartLonLat[1] + unitPerpendicularEdgeVectorLatLng.lat,
    ];
    const perpendicularCornerLonLat = [eaveStartLonLat[0] + unitPerpendicularEdgeVectorLatLng.lng, eaveStartLonLat[1]];

    const [perpendicularSignLng, perpendicularSignLat] = relativePosition(perpendicularEndLonLat, eaveStartLonLat);

    const perpendicularX = perpendicularSignLng * getDistance(eaveStartLonLat, perpendicularCornerLonLat);
    const perpendicularY = perpendicularSignLat * getDistance(perpendicularCornerLonLat, perpendicularEndLonLat);

    const angle = Math.atan2(perpendicularY, perpendicularX) - Math.atan2(eaveY, eaveX);

    return angle;
  }

  distanceScalingFactor(angleToPerpendicularRadians, roofSlopeRadians) {
    const firstTerm = Math.cos(angleToPerpendicularRadians);
    const secondTerm = Math.sin(angleToPerpendicularRadians) / Math.cos(roofSlopeRadians);
    const scalingFactor = Math.sqrt(firstTerm ** 2 + secondTerm ** 2);

    return scalingFactor;
  }

  loadGuideLinesIntersectionCircles(guideLineFeatures, roofPlaneFeatures) {
    const intersectionPoints = [];

    const guideLineLines = guideLineFeatures.map((sgf) => sgf.getGeometry().getCoordinates());
    const roofPlaneLines = roofPlaneFeatures.flatMap((rpf) => segmentCoordinatesFromPolygon(rpf));

    const addPointFromIntersection = (line, otherLine) => {
      const intersection = lineIntersectionInCoordinates(line, otherLine);
      if (!intersection) return;

      if (!this.intersectionPointOnSegment(intersection, otherLine)) return;

      const point = new Point(intersection);
      const feature = new Feature({ geometry: point });
      feature.set("dataType", GUIDE_LINES_INTERSECTION_CIRCLE_DATA_TYPE);
      intersectionPoints.push(feature);
    };

    guideLineLines.forEach((line) => {
      guideLineLines.forEach((otherLine) => {
        addPointFromIntersection(line, otherLine);
      });

      roofPlaneLines.forEach((otherLine) => {
        addPointFromIntersection(line, otherLine);
      });
    });

    return intersectionPoints;
  }

  // Since the intersection is on the infinite line, it will
  // be on the small line segment if it's X is between the
  // X's of the line's start and end and it's Y is between the
  // line's Y.
  // This is a simple, special case since we know the intersection
  // is actually on the infinite version of the line.
  intersectionPointOnSegment(intersection, line) {
    const [lineStart, lineEnd] = line;
    const [lineStartX, lineStartY] = lineStart;
    const [lineEndX, lineEndY] = lineEnd;

    const [intersectionX, intersectionY] = intersection;

    const lineXMin = Math.min(lineStartX, lineEndX);
    const lineXMax = Math.max(lineStartX, lineEndX);

    const lineYMin = Math.min(lineStartY, lineEndY);
    const lineYMax = Math.max(lineStartY, lineEndY);

    return (
      lineXMin <= intersectionX && intersectionX <= lineXMax && lineYMin <= intersectionY && intersectionY <= lineYMax
    );
  }
}
