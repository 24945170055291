import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = ["railCuttingContainer", "railCuttingCheckbox"];

  availabilityUpdated(e) {
    const lengthCheckbox = e.target;
    const length = lengthCheckbox.dataset.length;

    if (length === "168" || length === "170") {
      // we have 2 sets of these on the PR preset form, so we have to forEach
      if (lengthCheckbox.checked) {
        this.railCuttingCheckboxTargets.forEach((target) => (target.checked = true));
        this.railCuttingContainerTargets.forEach((target) => animate.show(target));
      } else {
        this.railCuttingCheckboxTargets.forEach((target) => (target.checked = false));
        this.railCuttingContainerTargets.forEach((target) => animate.hide(target));
      }
    }
  }
}
