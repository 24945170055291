import React from "react";

function CompressedIcon() {
  return (
    <svg
      width="9px"
      height="12px"
      viewBox="0 0 9 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
        <g transform="translate(1.000000, 0.000000)" stroke="#ccc">
          <polyline points="0 0 2 2.58823529 0 5.17647059 2 8.2718601 0 11"></polyline>
          <polyline points="5 0 7 2.58823529 5 5.17647059 7 8.2718601 5 11"></polyline>
        </g>
      </g>
    </svg>
  );
}

export default CompressedIcon;
