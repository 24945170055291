import { Controller } from "@hotwired/stimulus";

import * as animate from "../../helpers/animate";

export default class IntroductionsFormController extends Controller {
  static targets = ["introductionTypeSelect", "standardIntroductionOnlyFieldsContainer"];

  introductionTypeChange(event) {
    const isNewFeatureBadge = event.currentTarget.value === "true";
    this.standardIntroductionOnlyFieldsContainerTargets.forEach((container) => {
      animate.toggle(container, !isNewFeatureBadge, { display: "flex" });
    });
  }
}
