import { types, getParent } from "mobx-state-tree";

import * as Api from "../../helpers/api-mobx";
import { logger } from "../../helpers/app";
import WithDirtyTracking from "./with-dirty-tracking";

const ProjectSiteBase = types
  .model("ProjectSiteModel", {
    id: types.integer,
    lat: types.number,
    lng: types.number,
    zoom: types.number,
    viewerLat: types.maybeNull(types.number),
    viewerLng: types.maybeNull(types.number),
    viewerZoom: types.maybeNull(types.number),
    viewerRotation: types.maybeNull(types.number),
    editorLat: types.maybeNull(types.number),
    editorLng: types.maybeNull(types.number),
    editorZoom: types.maybeNull(types.number),
    editorRotation: types.maybeNull(types.number),
    // Keeping track of location and zoom values that only show on the map and do not get persisted
    unpersistedLat: types.maybeNull(types.number),
    unpersistedLng: types.maybeNull(types.number),
    unpersistedZoom: types.maybeNull(types.number),
    unpersistedRotation: types.maybeNull(types.number),
  })
  .views((self) => ({
    get project() {
      return getParent(self, 1);
    },
    get effectiveViewerLat() {
      return self.viewerLat || self.lat;
    },
    get effectiveViewerLng() {
      return self.viewerLng || self.lng;
    },
    get effectiveViewerZoom() {
      return self.viewerZoom || self.zoom;
    },
    get effectiveViewerRotation() {
      return self.viewerRotation || 0.0;
    },
    get effectiveEditorLat() {
      return self.editorLat || self.lat;
    },
    get effectiveEditorLng() {
      return self.editorLng || self.lng;
    },
    get effectiveEditorZoom() {
      return self.editorZoom || self.zoom;
    },
    get effectiveEditorRotation() {
      return self.editorRotation || 0.0;
    },
    get needsSave() {
      return self.dirty;
    },
  }))
  .actions((self) => ({
    setViewerSettings({ lat, lng, zoom, rotation }) {
      self.markDirty();

      self.viewerLat = lat;
      self.viewerLng = lng;
      self.viewerZoom = zoom;
      self.viewerRotation = rotation;
    },
    setEditorSettings({ lat, lng, zoom, rotation }) {
      self.markDirty();

      self.editorLat = lat;
      self.editorLng = lng;
      self.editorZoom = zoom;
      self.editorRotation = rotation;
    },
    setUnpersistedSettings({ lat, lng, zoom, rotation }) {
      self.unpersistedLat = lat;
      self.unpersistedLng = lng;
      self.unpersistedZoom = zoom;
      self.unpersistedRotation = rotation;
    },
    save({ path, onSuccess, onValidationError = () => {}, onServerError = () => {} }) {
      logger(`Direct save of Project Site #${self.id}`);

      if (!self.needsSave) {
        logger("--!needsSave. Skipping");
        onSuccess(undefined);
        return undefined;
      }

      const payload = persistenceData(self);
      return Api.put({ model: self, path, body: payload, onSuccess, onValidationError, onServerError });
    },
  }));

function persistenceData(projectSite) {
  return {
    project_site: projectSitePersistenceData(projectSite),
  };
}

export function projectSitePersistenceData(projectSite) {
  logger(`Persisting Project Site #${projectSite.id}`);

  if (!projectSite.needsSave) {
    logger("--!needsSave. Skipping");
    return undefined;
  }

  return {
    id: projectSite.id,
    viewer_lat: projectSite.effectiveViewerLat,
    viewer_lng: projectSite.effectiveViewerLng,
    viewer_zoom: projectSite.effectiveViewerZoom,
    viewer_rotation: projectSite.effectiveViewerRotation,
    editor_lat: projectSite.effectiveEditorLat,
    editor_lng: projectSite.effectiveEditorLng,
    editor_zoom: projectSite.effectiveEditorZoom,
    editor_rotation: projectSite.effectiveEditorRotation,
  };
}

const ProjectSiteModel = types.compose(ProjectSiteBase, WithDirtyTracking);
export default ProjectSiteModel;
