import { Controller } from "@hotwired/stimulus";
import { confirmDialog } from "../components/ir_dialog/helper";

export default class PaymentRequestConfirmation extends Controller {
  static targets = ["form", "customerEmail", "productSelect", "projectId"];

  open(event) {
    event.preventDefault();

    const callback = (confirmed) => {
      if (!confirmed) return;

      this.formTarget.submit();
    };

    const clickPosition = { x: event.pageX, y: event.pageY };
    const message = `
      Please confirm the following payment request details.
      <br />
      <br />
      <strong>Project id:</strong> ${this.projectIdTarget.value}
      <br />
      <strong>Product:</strong> ${this.productSelectTarget.options[this.productSelectTarget.selectedIndex].text}
      <br />
      <strong>Customer email:</strong> ${this.customerEmailTarget.value} (customer will be emailed on confirmation)
    `;
    confirmDialog(message, callback, {
      title: "Confirm payment request",
      confirmBtnColor: "green",
      headerColor: "orange",
      showNearClickPosition: true,
      clickPosition,
    });
  }
}
