import {
  cartesianPointRelativeTo,
  findTopLeftLonLat,
  setCartesianPointsForModelRelativeToOrigin,
} from "../../../da/map/ol-geometry";
import { setCartesianCoordinatesForRoofPlaneAndChildren, setRoofPlaneEaveCartesianPoints } from "./roof-plane";

export function updateProjectOriginAndCartesianCoordinatesForChildren(project) {
  setProjectOrigin(project);
  setCartesianCoordinatesForProjectChildren(project);
}

function setProjectOrigin(project) {
  if (project.notDeletedRoofPlanes.length === 0) {
    project.detail.setOriginLatLng(null);
    return;
  }

  const roofPlaneLatLngs = project.notDeletedRoofPlanes.flatMap((roofPlane) => {
    return roofPlane.latLngPoints;
  });

  const [lng, lat] = findTopLeftLonLat(roofPlaneLatLngs);
  project.detail.setOriginLatLng({ lat, lng });

  return project.detail.originLatLng;
}

function setCartesianCoordinatesForProjectChildren(project) {
  if (project.detail.originLatLng === null) return;

  const originLonLat = project.detail.originLatLng.toLonLat;
  project.notDeletedRoofPlanes.forEach((roofPlane) => {
    setCartesianCoordinatesForRoofPlaneAndChildren(roofPlane, originLonLat);
  });
}

export function setCartesianPointsForProjectIfMissing(project) {
  const projectOriginLatLng = project.detail.originLatLng || setProjectOrigin(project);
  if (!projectOriginLatLng) return;

  const originLonLat = (project.detail.originLatLng || setProjectOrigin(project)).toLonLat;
  project.notDeletedRoofPlanes.forEach((roofPlane) => {
    if (roofPlane.cartesianPoints.length === 0) {
      setCartesianPointsForModelRelativeToOrigin(originLonLat, roofPlane);
    }
    if (roofPlane.eaveMidpointCartesianPoint === null && roofPlane.eaveEdgeIndex !== null) {
      setRoofPlaneEaveCartesianPoints(roofPlane, originLonLat);
    }

    roofPlane.roofSections.forEach((roofSection) => {
      if (roofSection.cartesianPoints.length === 0) {
        setCartesianPointsForModelRelativeToOrigin(originLonLat, roofSection);
      }

      roofSection.segments.forEach((segment) => {
        if (segment.startCartesianPoint === null) {
          const startCartesianPoint = cartesianPointRelativeTo(segment.startLatLng.toLonLat, originLonLat);
          segment.setStartCartesianPoint(startCartesianPoint);
          segment.thermalExpansions.forEach((te) => {
            if (te.startCartesianPoint === null) {
              const teStartCartesianPoint = cartesianPointRelativeTo(te.startLatLng.toLonLat, originLonLat);
              te.setStartCartesianPoint(teStartCartesianPoint);
            }
          });
          if (segment.adjoinments.length > 0) {
            segment.adjoinments.forEach((adjoinment) => {
              if (adjoinment.cartesianPoints.length === 0) {
                const cartesianPoints = adjoinment.latLngPoints.map((ll) => {
                  return cartesianPointRelativeTo(ll.toLonLat, originLonLat);
                });
                adjoinment.setCartesianPoints(cartesianPoints);
              }
            });
          }
          if (segment.contourLegalityAdjoinments.length > 0) {
            segment.contourLegalityAdjoinments.forEach((adjoinment) => {
              if (adjoinment.cartesianPoints.length === 0) {
                const cartesianPoints = adjoinment.latLngPoints.map((ll) => {
                  return cartesianPointRelativeTo(ll.toLonLat, originLonLat);
                });
                adjoinment.setCartesianPoints(cartesianPoints);
              }
            });
          }
          if (segment.contours.length > 0) {
            segment.contours.forEach((contour) => {
              if (contour.cartesianPoints.length === 0) {
                const cartesianPoints = contour.latLngPoints.map((ll) => {
                  return cartesianPointRelativeTo(ll.toLonLat, originLonLat);
                });
                contour.setCartesianPoints(cartesianPoints);
              }
            });
          }
        }
      });
    });
  });
}
