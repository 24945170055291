import { Controller } from "@hotwired/stimulus";
import { Form } from "../../helpers/form";

export default class extends Controller {
  change(event) {
    const select = event.currentTarget;
    const url = select.value;
    Form.patch(url, {}, { remote: true });
  }
}
