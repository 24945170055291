import { Controller } from "@hotwired/stimulus";
import tippy, { followCursor } from "tippy.js";

export default class LockedTooltipController extends Controller {
  static values = {
    disable: { type: Boolean, default: false },
    content: { type: String, default: "This area is locked from<br />modification" },
  };

  connect() {
    this.element.lockedTooltipController = this;

    const tooltip = `
      <div class="d-flex">
        <div class="pe-3">
          <i class="fas fa-lock"></i>
        </div>
        <div>
          ${this.contentValue}
          <div class="small pt-3">&larr; Unlock in the sidebar</div>
        </div>
      </div>`;

    this.tippy = tippy(this.element, {
      content: tooltip,
      theme: "locked",
      animation: "fade",
      followCursor: true,
      placement: "bottom",
      allowHTML: true,
      plugins: [followCursor],
    });

    if (this.disableValue) this.disable();
  }

  disable() {
    this.tippy.disable();
  }

  enable() {
    this.tippy.enable();
  }
}
