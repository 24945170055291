import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";
import { addOptionsToSelect } from "../../helpers/form";

export default class extends Controller {
  static values = {
    membranesAvailableFor: Object,
    materialToManufacturers: Object,
  };

  static targets = [
    "roofInfoContainer",
    "roofManufacturerSelect",
    "roofManufacturerContainer",
    "thicknessSelect",
    "thicknessContainer",
    "colorSelect",
    "colorContainer",
    "roofAttachmentSelect",
    "roofMaterialSelect",
  ];

  get roofMaterial() {
    return this.roofMaterialSelectTarget.value;
  }

  get isMembraneAvailable() {
    return this.membranesAvailableForValue[this.roofMaterial];
  }

  get roofAttachment() {
    return this.roofAttachmentSelectTarget.value;
  }

  toggleRoofingInformation(event) {
    const val = event.currentTarget.value;
    const meth = val === "true" ? "show" : "hide";
    animate[meth](this.roofInfoContainerTarget);
    if (val !== "true") {
      this.roofMaterialSelectTarget.value = "";
    }
  }

  changeRoofMaterialFamily() {
    this.#toggleMembraneFields(false);
  }

  changeRoofMaterial(event) {
    const select = event.currentTarget;
    const manufacturerMaterials = this.roofMaterial === "" ? [] : this.materialToManufacturersValue[this.roofMaterial];

    // addOptionsToSelect wants triples where the third argument is disabled
    if (manufacturerMaterials) {
      const manufacturerMaterialsOptions = manufacturerMaterials.map((mm) => [mm[0], mm[1], false, false]);

      addOptionsToSelect(manufacturerMaterialsOptions, this.roofManufacturerSelectTarget, true);
    }

    if (this.hasRoofAttachmentSelectTarget) {
      this.changeRoofAttachment();
    } else {
      this.#toggleMembraneFields(this.isMembraneAvailable);
    }
  }

  changeRoofAttachment() {
    const isFra = this.roofAttachment === "fra";

    this.#toggleMembraneFields(isFra && this.isMembraneAvailable);
  }

  #toggleMembraneFields(toggle) {
    animate.toggle(this.colorContainerTarget, toggle);
    animate.toggle(this.thicknessContainerTarget, toggle);
    animate.toggle(this.roofManufacturerContainerTarget, toggle);

    if (!toggle) {
      this.colorSelectTarget.value = "";
      this.thicknessSelectTarget.value = "";
      this.roofManufacturerSelectTarget.value = "";
    }
  }
}
