import { Controller } from "@hotwired/stimulus";
import React from "react";
import ReactDOM from "react-dom";
import CountdownTimer from "../../components/CountdownTimer";

export default class extends Controller {
  connect() {
    ReactDOM.render(<CountdownTimer untilDateString={this.element.dataset.untilDateString} />, this.element);
  }
}
