import DaBase from "../../../../da/map/interaction-managers/snap/base";

export default class Obstructions extends DaBase {
  get mainTargetSource() {
    return this.mapManager.roofPlanesVectorSource;
  }

  get secondarySource() {
    return this.mapManager.obstructionsVectorSource;
  }

  get quaternarySource() {
    return this.mapManager.rulersVectorSource;
  }
}
