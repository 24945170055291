import { getDistance } from "ol/sphere";
import { METERS_TO_INCHES } from "../../../../../da/map/ol-geometry";
import {
  distanceBetweenPixelCoordinatesInInches,
  pixelsFromLatLng,
  truncatePixelCoordinateDecimal,
} from "../../../../../da/map/ol-helpers";
import { degreesToRadians } from "../../../../../helpers/geometry";

const SEGMENT_LENGTH_CUTOFF = 12; // inches
const CONNECTED_LENGTH_CUTOFF = 2; // inches

// Figures out if sections of Contour that are less than the cutoff amount should be removed or if
// they are connected to other adjoining Contour segments, making them longer than the cutoff.
export default class SegmentsContourShortRunsRemover {
  constructor({ controller, allRunFeatures }) {
    this.controller = controller;
    this.map = controller.mapManager.map;
    this.allRunFeatures = allRunFeatures;

    this.legalFeatures = [];
    this.contoursToRemove = [];
    this.contoursWithDistances = this.#getContoursWithDistances;
  }

  remove() {
    this.contoursWithDistances.forEach((contourWithDistance) => {
      const { contour, feature, distance } = contourWithDistance;
      const roundedDistance = Number.parseFloat(distance).toFixed(2);
      const tooShortOnOwn = roundedDistance < SEGMENT_LENGTH_CUTOFF;
      if (tooShortOnOwn) {
        if (this.#isPartOfConnectedRunLongerThanCutoff(contourWithDistance)) {
          this.legalFeatures.push(feature);
        } else {
          this.contoursToRemove.push(contour);
        }
      } else {
        this.legalFeatures.push(feature);
      }
    });

    this.contoursToRemove.forEach((contour) => {
      contour.segment.removeContour(contour);
    });
  }

  get #getContoursWithDistances() {
    return this.allRunFeatures.map((feature) => {
      const contour = feature.get("model");
      const point1 = contour.latLngPoints[0].toLonLat;
      const point2 = contour.latLngPoints[1].toLonLat;
      let distance = getDistance(point1, point2) * METERS_TO_INCHES;
      if (contour.side !== "bottom") {
        distance = this.#slopeAdjustDistance(distance);
      }
      return { contour, feature, distance };
    });
  }

  #isPartOfConnectedRunLongerThanCutoff(contourWithDistance) {
    for (let i = 0; i < this.contoursWithDistances.length; i++) {
      const otherContourWithDistance = this.contoursWithDistances[i];
      const { isConnected, connectionDistance } = this.#contourAreConnected(
        contourWithDistance,
        otherContourWithDistance,
      );
      if (isConnected) {
        const combinedDistance = contourWithDistance.distance + connectionDistance + otherContourWithDistance.distance;
        if (combinedDistance >= SEGMENT_LENGTH_CUTOFF) return true;
      }
    }

    return false;
  }

  #contourAreConnected(contourWithDistance, otherContourWithDistance) {
    const noConnection = { isConnected: false, connectionDistance: 0 };

    const { contour } = contourWithDistance;
    const { contour: otherContour } = otherContourWithDistance;
    if (contour === otherContour || contour.side !== otherContour.side) return noConnection;

    const contourPx = contour.latLngPoints.map((ll) => pixelsFromLatLng(this.map, ll.toLatLng));
    const otherContourPx = otherContour.latLngPoints.map((ll) => pixelsFromLatLng(this.map, ll.toLatLng));

    const contourYs = contourPx.map((c) => c[1]).sort((a, b) => a - b);
    const contourXs = contourPx.map((c) => c[0]).sort((a, b) => a - b);
    const [cY1, cY2] = contourYs;
    const [cX1, cX2] = contourXs;

    const cY1Compare = truncatePixelCoordinateDecimal(cY1);
    const cX1Compare = truncatePixelCoordinateDecimal(cX1);

    const otherContourYs = otherContourPx.map((c) => c[1]).sort((a, b) => a - b);
    const otherContourXs = otherContourPx.map((c) => c[0]).sort((a, b) => a - b);
    const [oCY1, oCY2] = otherContourYs;
    const [oCX1, oCX2] = otherContourXs;

    const oCY1Compare = truncatePixelCoordinateDecimal(oCY1);
    const oCX1Compare = truncatePixelCoordinateDecimal(oCX1);

    let point1;
    let point2;
    if (contour.side === "bottom") {
      if (cY1Compare !== oCY1Compare) return noConnection;

      const contourIsLeftOfOtherContour = cX1 < oCX1;
      let pointX1 = contourIsLeftOfOtherContour ? cX2 : oCX2;
      let pointX2 = contourIsLeftOfOtherContour ? oCX1 : cX1;
      point1 = [pointX1, cY1];
      point2 = [pointX2, cY1];
    } else {
      if (cX1Compare !== oCX1Compare) return noConnection;

      const contourIsOnTopOfOtherContour = cY1 < oCY1;
      let pointY1 = contourIsOnTopOfOtherContour ? cY2 : oCY2;
      let pointY2 = contourIsOnTopOfOtherContour ? oCY1 : cY1;
      point1 = [cX1, pointY1];
      point2 = [cX1, pointY2];
    }

    let distanceBetween = distanceBetweenPixelCoordinatesInInches(this.map, point1, point2);
    if (contour.side !== "bottom") {
      distanceBetween = this.#slopeAdjustDistance(distanceBetween);
    }
    const isConnected = truncatePixelCoordinateDecimal(distanceBetween) <= CONNECTED_LENGTH_CUTOFF;

    if (isConnected) {
      return { isConnected: true, connectionDistance: distanceBetween };
    } else {
      return noConnection;
    }
  }

  #slopeAdjustDistance(distance) {
    const roofSlopeRadians = degreesToRadians(this.controller.focusedRoofPlane.roofSlope);
    return distance / Math.cos(roofSlopeRadians);
  }
}
