import { inchesToFeetAndInches } from "../../../helpers/app";

import { GRID_SETBACK_OUTLINE_INNER_PADDING } from "./grid-setback-outlines";

export const GRID_DIMENSION_MARKERS_SPACING = 20;

export default class GridDimensionMarkers {
  dimensionMarkerOffset = 15;

  static calculate(displayGrid) {
    return new this(displayGrid).calculate();
  }

  constructor(displayGrid) {
    this.displayGrid = displayGrid;
    this.realGrid = displayGrid.realGrid;
  }

  calculate() {
    const { setbackWidth, setbackHeight } = this.displayGrid;
    const markers = [this.arrayHardwareWidth, this.arrayHardwareHeight];
    if (setbackWidth) markers.push(this.widthWithSetbacks);
    if (setbackHeight) markers.push(this.heightWithSetbacks);
    return markers;
  }

  get arrayHardwareWidth() {
    const { extensionLeft } = this.displayGrid;

    return {
      label: "width without setbacks",
      orientation: "horizontal",
      dimension: inchesToFeetAndInches(this.realGrid.arrayHardwareWidth),
      x: this.firstRowFirstCell.x - extensionLeft,
      y: this.horizontalYStartingPos,
      width: this.hardwareWidth,
      height: undefined,
      compressedBeginning: false,
      compressedEnd: false,
    };
  }

  get setbackOutlineInnerPadding() {
    const { setback } = this.displayGrid;
    if (setback === 0) return 0;
    return GRID_SETBACK_OUTLINE_INNER_PADDING;
  }

  get firstRowFirstCell() {
    const { cellPositions } = this.displayGrid;
    return cellPositions[0][0];
  }

  get horizontalYStartingPos() {
    const { cellHeight, extensionBottom, setbackWidth, spaceAroundCellsBottom } = this.displayGrid;
    return (
      this.lastRowLastCell.y +
      cellHeight +
      setbackWidth +
      spaceAroundCellsBottom +
      this.dimensionMarkerOffset +
      extensionBottom
    );
  }

  get lastRowLastCell() {
    const { cellPositions, rows, columns } = this.displayGrid;
    return cellPositions[rows - 1][columns - 1];
  }

  get hardwareWidth() {
    const { cellWidth, extensionLeft, extensionRight } = this.displayGrid;

    return this.lastRowLastCell.x - this.firstRowFirstCell.x + cellWidth + extensionLeft + extensionRight - 1;
  }

  get arrayHardwareHeight() {
    const { extensionTop } = this.displayGrid;

    return {
      label: "height without setbacks",
      orientation: "vertical",
      dimension: inchesToFeetAndInches(this.realGrid.arrayHardwareHeight),
      x: this.verticalXStartingPos,
      y: this.firstRowFirstCell.y - extensionTop,
      width: undefined,
      height: this.hardwareHeight,
      compressedBeginning: false,
      compressedEnd: false,
    };
  }

  get verticalXStartingPos() {
    const { cellWidth, extensionRight, setbackWidth, spaceAroundCellsRight } = this.displayGrid;

    return (
      this.lastRowLastCell.x +
      cellWidth +
      setbackWidth +
      this.dimensionMarkerOffset +
      spaceAroundCellsRight +
      extensionRight
    );
  }

  get hardwareHeight() {
    const { cellHeight, extensionBottom, extensionTop } = this.displayGrid;

    return this.lastRowLastCell.y - this.firstRowFirstCell.y + cellHeight + extensionTop + extensionBottom - 1;
  }

  get widthWithSetbacks() {
    const {
      uncompressedWidth,
      spaceAroundCellsLeftIsCompressed: compressedOnLeft,
      spaceAroundCellsRightIsCompressed: compressedOnRight,
    } = this.realGrid;
    const { extensionLeft, setbackWidth, spaceAroundCellsLeft, spaceAroundCellsHorizontal } = this.displayGrid;

    const width = this.hardwareWidth + setbackWidth * 2 + spaceAroundCellsHorizontal;

    return {
      label: "width with setbacks",
      orientation: "horizontal",
      dimension: inchesToFeetAndInches(uncompressedWidth),
      x: this.firstRowFirstCell.x - setbackWidth - spaceAroundCellsLeft - extensionLeft,
      y: this.horizontalYStartingPos + GRID_DIMENSION_MARKERS_SPACING,
      width,
      height: undefined,
      compressedBeginning: compressedOnLeft,
      compressedEnd: compressedOnRight,
    };
  }

  get heightWithSetbacks() {
    const {
      uncompressedHeight,
      spaceAroundCellsTopIsCompressed: compressedOnTop,
      spaceAroundCellsBottomIsCompressed: compressedOnBottom,
    } = this.realGrid;
    const { extensionTop, setbackHeight, spaceAroundCellsTop, spaceAroundCellsVertical } = this.displayGrid;

    const height = this.hardwareHeight + setbackHeight * 2 + spaceAroundCellsVertical;

    return {
      label: "height with setbacks",
      orientation: "vertical",
      dimension: inchesToFeetAndInches(uncompressedHeight),
      x: this.verticalXStartingPos + GRID_DIMENSION_MARKERS_SPACING,
      y: this.firstRowFirstCell.y - setbackHeight - spaceAroundCellsTop - extensionTop,
      width: undefined,
      height,
      compressedBeginning: compressedOnTop,
      compressedEnd: compressedOnBottom,
    };
  }
}
