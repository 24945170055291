import * as Sentry from "@sentry/browser";

const sentryDsn = () => {
  const metaTag = document.head.querySelector("meta[name=sentry-dsn]");
  return metaTag && metaTag.content;
};

export function sentryException(error, extras = false, eventIdCallback = () => {}) {
  const documentBody = document.querySelector("body");

  function setSentryUserOnScope(scope) {
    if (documentBody === null) return;

    const { userId: id, userEmail: email } = documentBody.dataset;
    if (id !== undefined && email !== undefined) scope.setUser({ id, email });
  }

  function setProjectOnScope(scope) {
    if (documentBody === null) return;

    const { projectId, projectType } = documentBody.dataset;
    if (projectId !== undefined && projectType !== undefined) {
      scope.setTag("project ID", projectId);
      scope.setTag("project type", projectType.toUpperCase());
    }
  }

  const errorPayload = error || "No error available";

  Sentry.withScope(function (scope) {
    if (extras) scope.setExtras(extras);
    setSentryUserOnScope(scope);
    setProjectOnScope(scope);
    const captureMethod = errorPayload instanceof Error ? "captureException" : "captureMessage";
    const eventId = Sentry[captureMethod](errorPayload);
    eventIdCallback(eventId);
  });
}

export default Sentry;

Sentry.init({ dsn: sentryDsn() });
