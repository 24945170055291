import React from "react";
import PropTypes from "prop-types";
import CountMarker from "./CountMarker";
import BaseSelectionMarquee from "../../SelectionMarquee";

function SelectionMarquee({ selectionMarquee, rows, columns, selectedToolbarItem }) {
  const { x, y, width, height } = selectionMarquee;

  let color = "blue";
  if (selectedToolbarItem === "add") {
    color = "green";
  } else if (selectedToolbarItem === "remove") {
    color = "red";
  }

  const showCountMarker = rows > 1 || columns > 1;

  return (
    <>
      {showCountMarker && <CountMarker x={x} y={y} rows={rows} columns={columns} />}
      <BaseSelectionMarquee x={x} y={y} width={width} height={height} color={color} />
    </>
  );
}

SelectionMarquee.propTypes = {
  selectionMarquee: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  rows: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
  selectedToolbarItem: PropTypes.string.isRequired,
};

SelectionMarquee.defaultProps = {
  selectionMarquee: {
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  },
};

export default React.memo(SelectionMarquee);
