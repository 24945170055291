import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import throttle from "lodash/throttle";

import { defaultEaveSelectionLineStringStyle } from "../styles/eave-selection";
import BaseMapManager from "./base";
import { EAVE_SELECTION_LINE_STRING_DATA_TYPE } from "../../../da/map/data-types";

export default class EaveSelectionMapManager extends BaseMapManager {
  add() {
    this.buildRoofPlanesVectorSource();
    this.buildRoofPlaneLineStringsVectorSource();

    this.map = this.buildMap({
      layers: [this.tileLayer, this.roofPlanesVectorLayer, this.roofPlaneLineStringsVectorLayer],
    });

    this.setProjectSiteValuesOnMoveEnd = false;

    this.map.on("moveend", this.onMoveEnd);

    if (!this.controller.isLocked) {
      this.throttledOnPointerMove = throttle(this.onPointerMove, 50, { leading: true });
      this.map.on("pointermove", this.throttledOnPointerMove);
    }
  }

  onPointerMove = (event) => {
    this.roofPlaneLineStringsVectorSource.forEachFeature((feature) => {
      feature.set("mouseover", false);
    });

    this.map.forEachFeatureAtPixel(event.pixel, (feature) => {
      if (feature.get("dataType") === EAVE_SELECTION_LINE_STRING_DATA_TYPE) {
        feature.set("mouseover", true);
      }
      return true;
    });
  };

  buildRoofPlaneLineStringsVectorSource() {
    this.roofPlaneLineStringsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.featuresForRoofPlaneLineStrings(this.controller.focusedRoofPlane),
    });
  }

  get roofPlaneLineStringsVectorLayer() {
    if (!this.memoRoofPlaneLineStringVectorLayer) {
      this.memoRoofPlaneLineStringVectorLayer = new VectorLayer({
        source: this.roofPlaneLineStringsVectorSource,
        style: (feature) => defaultEaveSelectionLineStringStyle(feature, this.map),
        zIndex: 10,
      });
      this.memoRoofPlaneLineStringVectorLayer.set("dataType", EAVE_SELECTION_LINE_STRING_DATA_TYPE);
    }
    return this.memoRoofPlaneLineStringVectorLayer;
  }
}
