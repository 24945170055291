export function getCritterGuardDirections(gridArray, row, column) {
  //                   0    1    2    3
  //                   N    E    S    W
  const directions = ["0", "0", "0", "0"];

  const currentCell = gridArray[row][column];
  if (currentCell === "0") return directions;

  if (row === 0) {
    // First row
    directions[0] = "1";
  } else if (row === gridArray.length - 1) {
    // Last row
    directions[2] = "1";
  }

  if (gridArray[row - 1] && gridArray[row - 1][column] === "0") {
    // Previous row's cell is off
    directions[0] = "1";
  }

  if (gridArray[row + 1] && gridArray[row + 1][column] === "0") {
    // Next row's cell is off
    directions[2] = "1";
  }

  if (column === 0) {
    // First column
    directions[3] = "1";
  } else if (column === gridArray[row].length - 1) {
    // Last column
    directions[1] = "1";
  }

  if (gridArray[row][column - 1] && gridArray[row][column - 1] === "0") {
    // Previous column's cell is off
    directions[3] = "1";
  }

  if (gridArray[row][column + 1] && gridArray[row][column + 1] === "0") {
    // Next column's cell is off
    directions[1] = "1";
  }

  return directions;
}
