import anime from "animejs";
import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import Rails from "@rails/ujs";

import { Form } from "../../helpers/form";
import * as api from "../../helpers/api";
import { track } from "../../helpers/app";
import { lookupDialog, showDialog } from "../components/ir_dialog/helper";

export default class extends Controller {
  static targets = [
    "selectAllCheckbox",
    "projectFolderCheckboxes",
    "selectedProjectsActionLinks",
    "discountedPriceTds",
    "detailsFrame",
  ];

  submitting = false;

  connect() {
    if (lookupDialog("my-projects-login-dialog")) {
      showDialog("my-projects-login-dialog", () => {}, {
        showNearClickPosition: true,
        clickPosition: this.getLoginDialogPosition(),
      });
    }
  }

  deleteSelectedLinkClicked(event) {
    this.selectedLinkHandler(event, "delete");
    track({ category: "Bulk Action", action: "delete", label: "Projects" });
  }

  emailSelectedLinkClicked(e) {
    const link = e.currentTarget;
    if (link.classList.contains("disabled")) return;
    const hiddenFieldIdentifier = "bulk-send-project-folder-ids-hidden-field";
    [...document.querySelectorAll(`[data-identifier="${hiddenFieldIdentifier}"]`)].forEach((field) => {
      field.remove();
    });
    const form = document.querySelector(`[data-identifier="project-report-bulk-shares-form"]`);
    this.selectedProjectIds.forEach((projectFolderId) => {
      const field = this.projectFolderIdHiddenField(projectFolderId, hiddenFieldIdentifier, "bulk_report_share");
      form.insertAdjacentHTML("afterbegin", field);
    });
  }

  projectIdHiddenField(projectId, hiddenFieldIdentifier, formObjectName) {
    return `<input data-identifier="${hiddenFieldIdentifier}" type="hidden" name="${formObjectName}[project_ids][]" value="${projectId}" />`;
  }

  projectFolderIdHiddenField(projectFolderId, hiddenFieldIdentifier, formObjectName) {
    return `<input data-identifier="${hiddenFieldIdentifier}" type="hidden" name="${formObjectName}[project_folder_ids][]" value="${projectFolderId}" />`;
  }

  consolidateBOMLinkClicked(e) {
    const link = e.currentTarget;
    if (link.classList.contains("disabled")) return;
    const hiddenFieldIdentifier = "consolidated-bom-project-folder-ids-hidden-field";
    [...document.querySelectorAll(`[data-identifier="${hiddenFieldIdentifier}"]`)].forEach((field) => {
      field.remove();
    });
    const form = document.querySelector('[data-identifier="download-consolidated-bom-form"]');
    this.selectedProjectIds.forEach((projectFolderId) => {
      const field = this.projectFolderIdHiddenField(projectFolderId, hiddenFieldIdentifier, "consolidated_bom");
      form.insertAdjacentHTML("afterbegin", field);
    });
    this.validateBomConsolidation();
  }

  validateBomConsolidation() {
    const projectIdParams = this.selectedProjectIds
      .map((id) => `consolidated_bom[project_folder_ids][]=${id}`)
      .join("&");
    Rails.ajax({ type: "GET", url: `/consolidated_boms/new?${projectIdParams}` });
  }

  addPersonLinkClicked(e) {
    const link = e.currentTarget;
    if (link.classList.contains("disabled")) return;
    const hiddenFieldIdentifier = "project-folders-ownership-project-folder-ids-hidden-field";
    [...document.querySelectorAll(`[data-identifier="${hiddenFieldIdentifier}"]`)].forEach((field) => {
      field.remove();
    });
    const form = document.querySelector('[data-identifier="share-project-folders-form"]');
    this.selectedProjectIds.forEach((projectFolderId) => {
      const field = this.projectFolderIdHiddenField(
        projectFolderId,
        hiddenFieldIdentifier,
        "project_folders_ownership",
      );
      form.insertAdjacentHTML("afterbegin", field);
    });
  }

  selectedLinkHandler(event, type) {
    event.preventDefault();
    const link = event.currentTarget;
    if (link.classList.contains("disabled")) return;
    const projectIds = this.selectedProjectIds;
    if (projectIds.length === 0) return;

    const msg = link.dataset.msg || `Are you sure you want to ${type} the selected projects?`;
    if (window.confirm(msg)) {
      this.disableSelectedProjectsLinks();
      this.postSelectedOperation(projectIds, type);
    }
  }

  deleteProjectFolderLinkClicked(event) {
    event.preventDefault();
    const link = event.currentTarget;
    const msg = link.dataset.msg || "Are you sure you want to delete this project?";
    if (window.confirm(msg)) {
      const projectFolderId = link.dataset.id;
      this.postSelectedOperation([projectFolderId], "delete");
    }
  }

  individualProjectLinkHandler(event, type, msgOverride = "") {
    event.preventDefault();
    const link = event.currentTarget;
    const defaultMsg = `Are you sure you want to ${type} this project?`;
    const msg = msgOverride === "" ? defaultMsg : msgOverride;
    if (window.confirm(msg)) {
      const projectId = link.dataset.id;
      this.postSelectedOperation([projectId], type);
    }
  }

  postSelectedOperation(projectFolderIds, type) {
    Form.post(`/project_set/${type}`, {
      project_set: {
        selected: projectFolderIds.join(","),
        project_criteria: window.location.search,
      },
    });
  }

  disableSelectedProjectsLinks() {
    this.selectedProjectsActionLinksTargets.forEach((link) => {
      link.classList.add("disabled");
    });
  }

  get anyCheckboxesSelected() {
    return this.projectFolderCheckboxesTargets.some((pc) => pc.checked);
  }

  get allCheckboxesSelected() {
    return this.projectFolderCheckboxesTargets.every((pc) => pc.checked);
  }

  get selectedProjectIds() {
    const projectIds = [];
    this.projectFolderCheckboxesTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        projectIds.push(checkbox.value);
      }
    });
    return projectIds;
  }

  get anyPermitPacksSelected() {
    return this.anyCheckboxHasTrueValue(this.projectFolderCheckboxesTargets, "permitPack");
  }

  get anyProposalsSelected() {
    return this.anyCheckboxHasTrueValue(this.projectFolderCheckboxesTargets, "proposal");
  }

  get anyMultiProjectFoldersSelected() {
    return this.anyCheckboxHasTrueValue(this.projectFolderCheckboxesTargets, "multipleProjects");
  }

  anyCheckboxHasTrueValue(checkboxes, dataAttribute) {
    return checkboxes.some((checkbox) => {
      if (checkbox.checked) {
        return checkbox.dataset[dataAttribute] === "true";
      } else {
        return false;
      }
    });
  }

  toggleSelectAll(event) {
    const checkbox = event.currentTarget;
    if (checkbox.checked) {
      track({ category: "Bulk Action", action: "select all", label: "Projects" });
    }
    const projectFolderCheckboxes = this.projectFolderCheckboxesTargets;
    projectFolderCheckboxes.forEach((projectFolderCheckbox) => {
      projectFolderCheckbox.checked = checkbox.checked;
      const projectTr = projectFolderCheckbox.closest("tr");
      this.toggleProjectTrStyle(checkbox, projectTr);
    });
    this.updateSelectedActionLinkStatuses();
  }

  toggleProjectTrStyle(checkbox, tr) {
    const meth = checkbox.checked ? "add" : "remove";
    tr.classList[meth]("selected");
  }

  updateSelectedActionLinkStatuses() {
    const meth = this.anyCheckboxesSelected ? "remove" : "add";
    this.selectedProjectsActionLinksTargets.forEach((link) => {
      if (
        link.dataset.rackingOnly === "true" &&
        (this.anyPermitPacksSelected || this.anyProposalsSelected || this.anyMultiProjectFoldersSelected)
      ) {
        link.classList.add("disabled");
        let tooltip;
        if (this.anyMultiProjectFoldersSelected) {
          tooltip = "Folders with multiple projects are not eligible for this action";
        } else if (this.anyPermitPacksSelected) {
          tooltip = "Permit Pack projects are not eligible for this action";
        } else {
          tooltip = "Proposal projects are not eligible for this action";
        }
        link.setAttribute("data-controller", "components--ir-tooltip");
        link.setAttribute("data-components--ir-tooltip-show-message-if-disabled-value", true);
        link.setAttribute("data-components--ir-tooltip-content-value", tooltip);
      } else {
        link.classList[meth]("disabled");
        link.removeAttribute("data-controller");
        link.removeAttribute("data-components--ir-tooltip-show-message-if-disabled-value");
        link.removeAttribute("data-components--ir-tooltip-content-value");
      }
    });
  }

  toggleSelect(event) {
    const checkbox = event.currentTarget;
    const projectTr = checkbox.closest("tr");
    this.toggleProjectTrStyle(checkbox, projectTr);
    this.updateSelectedActionLinkStatuses();
    this.selectAllCheckboxTarget.checked = this.allCheckboxesSelected;
  }

  toggleDetails(event) {
    event.preventDefault();
    const link = event.currentTarget;
    const detailsTr = link.closest("tr").nextElementSibling;
    const hidden = detailsTr.style.display === "none";

    if (hidden) {
      this.showDetails(detailsTr);
      this.toggleDetailsIcon(link, "up");
    } else {
      this.hideDetails(detailsTr);
      this.toggleDetailsIcon(link, "down");
    }

    track({ category: "Project", action: hidden ? "expand" : "collapse", label: link.dataset.type });
  }

  toggleDetailsIcon(link, direction) {
    const i = link.querySelector("i");
    let meth = direction === "up" ? "add" : "remove";
    i.classList[meth]("rotated-up");
  }

  showDetails(detailsTr) {
    detailsTr.style.display = "table-row";
    detailsTr.style.opacity = 0;
    anime({
      targets: detailsTr,
      opacity: 1,
      easing: "easeOutQuad",
      duration: 500,
    });
  }

  hideDetails(detailsTr) {
    anime({
      targets: detailsTr,
      opacity: 0,
      easing: "easeInQuad",
      duration: 250,
      complete: () => {
        detailsTr.style.display = "none";
      },
    });
  }

  trackClearSearch() {
    track({ category: "Bulk Action", action: "clear search", label: "Projects" });
  }

  trackSearch() {
    track({ category: "Bulk Action", action: "search", label: "Projects" });
  }

  trackTypeFilterChange() {
    track({ category: "Bulk Action", action: "filter type", label: "Projects" });
  }

  trackScopeFilterChange() {
    track({ category: "Bulk Action", action: "filter scope", label: "Projects" });
  }

  trackNewProjectLinkClick(event) {
    const link = event.currentTarget;
    const projectType = link.dataset.type;
    track({ category: "Project", action: "create", label: projectType });
  }

  trackProjectActionClick(event) {
    const link = event.currentTarget;
    const projectType = link.dataset.type;
    const category = link.dataset.trackCategory;
    const action = link.dataset.trackAction;
    track({ category, action, label: projectType });
  }

  getLoginDialogPosition() {
    // Get the viewport dimensions
    const width = window.innerWidth;
    const height = window.innerHeight;

    const y = Math.max(0.35 * height, 200);

    return { x: width / 2, y };
  }
}
