import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["actionBtn"];

  actionBtnClick(event) {
    this.actionBtnTargets.forEach((btn) => {
      if (btn === event.target) return;

      btn.classList.add("ir-btn--disabled");
      btn.disabled = true;
    });
  }
}
