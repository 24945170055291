import { degreesToRadians } from "../../../../helpers/geometry";
import SegmentsRailGroup from "../../map-model-synchronizers/segments-rail-group";
import RailGroupConsolidator from "../rail-group-consolidator";
import ThermalExpansionsHorizontalNudger from "../thermal-expansions/horizontal-nudger";
import { reRenderSegment } from "./helpers";

export default class SegmentsNudger {
  constructor({ controller, selectionCollection, nudgeDirection, nudgeDistance, slopeAdjusted = true }) {
    this.controller = controller;
    this.selectionCollection = selectionCollection;
    this.nudgeDirection = nudgeDirection;
    this.nudgeDistance = nudgeDistance;

    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.roofPlane = controller.focusedRoofPlane;
    this.mapManager = controller.mapManager;
    this.project = controller.project;
    this.slopeAdjusted = slopeAdjusted;

    this.railGroups = SegmentsRailGroup.uniqueGroupsFromSegmentFeatures({
      controller: this.controller,
      features: this.selectionCollection.getArray(),
    });

    this.nudgeVector = this.#getNudgeVector();
  }

  nudge() {
    if (this.nudgeDirection === "up" || this.nudgeDirection === "down") this.#verticalNudge();
    if (this.nudgeDirection === "right" || this.nudgeDirection === "left") this.#horizontalNudge();

    this.railGroups.forEach((railGroup) => {
      railGroup.selectRailFeatures();
    });
  }

  #verticalNudge() {
    this.railGroups.forEach((railGroup) => this.#verticalNudgeForRailGroup(railGroup));
  }

  #verticalNudgeForRailGroup(railGroup) {
    railGroup.segments.forEach((segment, i) => {
      const nudgedStartLatLng = segment.startLatLng.plus(this.nudgeVector);
      this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(segment, nudgedStartLatLng);

      const segmentFeature = railGroup.segmentFeatures[i];

      if (segment.railed && segment.hasThermalExpansions) {
        segment.thermalExpansions.forEach((thermalExpansion) => {
          const nudgedStartLatLng = thermalExpansion.startLatLng.plus(this.nudgeVector);
          this.mapModelSynchronizer.setThermalExpansionStartLatLngAndCartesianPoint(
            thermalExpansion,
            nudgedStartLatLng,
          );
        });
      }

      const newSegmentFeature = reRenderSegment(this.controller, segment);
      if (this.#isSelected(segmentFeature)) {
        this.selectionCollection.push(newSegmentFeature);
        this.selectionCollection.remove(segmentFeature);
      }

      railGroup.segmentFeatures[i] = newSegmentFeature;
    });
  }

  #horizontalNudge() {
    this.railGroups.forEach((railGroup) => this.#horizontalNudgeForRailGroup(railGroup));
  }

  #isSelected(segmentFeature) {
    return this.controller.selectInteractionManager.isSegmentSelected(segmentFeature);
  }

  #horizontalNudgeForRailGroup(railGroup) {
    railGroup.segments.forEach((segment, i) => {
      const segmentFeature = railGroup.segmentFeatures[i];
      if (!this.#isSelected(segmentFeature)) return;

      const nudgedStartLatLng = segment.startLatLng.plus(this.nudgeVector);
      this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(segment, nudgedStartLatLng);
    });

    railGroup.updateDistancesFromRailedParentStart();
    this.#thermalExpansionNudging(railGroup);
    this.#consolidateOverlappingRailGroupSegments(railGroup);
    railGroup.reRenderSegmentsWithSegmentSelections(this.selectionCollection);
  }

  #thermalExpansionNudging(railGroup) {
    this.thermalExpansionNudgeCalls++;
    if (this.thermalExpansionNudgeCalls > 25) {
      console.error("Called the thermal expansion nudger more than 25 times");
      return;
    }

    const { controller, nudgeDirection } = this;

    const nudgesOccurred = new ThermalExpansionsHorizontalNudger({
      controller,
      railGroup,
      nudgeDirection,
    }).nudge();

    if (nudgesOccurred) this.#thermalExpansionNudging(railGroup);
  }

  #consolidateOverlappingRailGroupSegments(railGroup) {
    const { controller, nudgeDirection } = this;

    const consolidationsOccurred = new RailGroupConsolidator({
      controller,
      railGroup,
      nudgeDirection,
    }).consolidate();

    if (consolidationsOccurred) this.#consolidateOverlappingRailGroupSegments(railGroup);
  }

  #getNudgeVector() {
    const { eavePerpendicularEdgeVectorLatLng, eaveEdgeVectorLatLng } = this.roofPlane;
    const distance = this.nudgeDistance;
    const roofSlopeRadians = degreesToRadians(this.roofPlane.roofSlope);
    const slopeAdjustedDistance = distance * Math.cos(roofSlopeRadians);

    if (this.nudgeDirection === "up") {
      return eavePerpendicularEdgeVectorLatLng.times(-1 * (this.slopeAdjusted ? slopeAdjustedDistance : distance));
    } else if (this.nudgeDirection === "down") {
      return eavePerpendicularEdgeVectorLatLng.times(1 * (this.slopeAdjusted ? slopeAdjustedDistance : distance));
    } else if (this.nudgeDirection === "right") {
      return eaveEdgeVectorLatLng.times(-1 * distance);
    } else if (this.nudgeDirection === "left") {
      return eaveEdgeVectorLatLng.times(1 * distance);
    }
  }
}
