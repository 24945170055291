import BaseRenderer from "./base-renderer";
import SegmentsProximityAlignmentsIdentifier from "./identifier";

import { CONTOUR_LEGALITY_ADJOINMENT_DATA_TYPE } from "../../../../../da/map/data-types";
import {
  CONTOUR_LEGALITY_ADJOINMENT_HORIZONTAL_THRESHOLD,
  CONTOUR_LEGALITY_ADJOINMENT_VERTICAL_THRESHOLD,
} from "../../../../models/adjoinment-model";

export default class SegmentsContourLegalityAdjoinmentsRenderer extends BaseRenderer {
  static render(controller) {
    const identifier = new SegmentsProximityAlignmentsIdentifier({
      controller,
      horizontalRange: CONTOUR_LEGALITY_ADJOINMENT_HORIZONTAL_THRESHOLD,
      verticalRange: CONTOUR_LEGALITY_ADJOINMENT_VERTICAL_THRESHOLD,
    });
    identifier.identify();
    new SegmentsContourLegalityAdjoinmentsRenderer(controller, identifier).render();
  }

  clearAdjoinmentsOnSegments() {
    Object.values(this.segmentsByUuid).forEach((segment) => {
      segment.clearContourLegalityAdjoinments();
    });
  }

  addAdjoinmentFeature(feature) {
    feature.set("dataType", CONTOUR_LEGALITY_ADJOINMENT_DATA_TYPE);
    this.mapManager.contourLegalityAdjoinmentsVectorSource.addFeature(feature);
  }

  addAdjoinment(segment, adjoinmentData) {
    segment.addContourLegalityAdjoinment(adjoinmentData);
  }
}
