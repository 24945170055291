import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

import BaseMapManager from "./base";
import { silhouettePanelsStyle } from "../styles/zone-panels";
import { rowsColumnsMarkerStyle } from "../styles/segments";
import * as events from "../../../da/map/events";
import { logger } from "../../../helpers/app";

import {
  EDITOR_MODE_DRAW,
  EDITOR_MODE_SELECT,
  SELECT_TYPE_MEASURES,
  SELECT_TYPE_PANELS,
  SELECT_TYPE_SEGMENTS,
  SELECT_TYPE_THERMAL_EXPANSIONS,
} from "../../../da/layout-editor/helpers/toolbar-constants";
import {
  MEASURE_DATA_TYPE,
  PANEL_DATA_TYPE,
  SEGMENT_DATA_TYPE,
  THERMAL_EXPANSION_DATA_TYPE,
} from "../../../da/map/data-types";
import ThermalExpansionGaps from "../legality-checkers/thermal-expansion-gaps";
import { reRenderSegment } from "../modification-helpers/segments/helpers";

export default class ArraysMapManager extends BaseMapManager {
  add() {
    this.buildRoofPlanesVectorSource();
    this.buildRoofSectionsVectorSource();
    this.buildSetbacksVectorSource();
    this.buildMeasuresVectorSource();
    this.buildPanelsVectorSource();
    this.buildSilhouettePanelsVectorSource();
    this.buildRowsColumnsMarkerVectorSource();
    this.buildSegmentsVectorSource();
    this.buildRailsVectorSource();
    this.buildThermalExpansionsVectorSource();
    this.buildAdjoinmentsVectorSource();
    this.buildContourLegalityAdjoinmentsVectorSource();
    this.buildContoursVectorSource();

    this.map = this.buildMap({
      layers: [
        this.tileLayer,
        this.visualMarkersVectorLayer,
        this.roofPlanesVectorLayer,
        this.measuresVectorLayer,
        this.panelsVectorLayer,
        this.roofSectionsVectorLayer,
        this.setbacksVectorLayer,
        this.silhouettePanelsVectorLayer,
        this.rowsColumnsMarkerVectorLayer,
        this.segmentsVectorLayer,
        this.railsVectorLayer,
        this.thermalExpansionsVectorLayer,
        this.contourLegalityAdjoinmentsVectorLayer,
        this.adjoinmentsVectorLayer,
        this.contoursVectorLayer,
      ],
    });

    // We need to have the map in place first
    this.buildRafterVectorSourcesLayersAndFeatures();
    this.renderContourFeatures();

    this.setProjectSiteValuesOnMoveEnd = false;

    this.map.on("moveend", this.onMoveEnd);
    this.map.on("click", this.onMapClick);
    this.map.on("dblclick", this.onMapDblClick);

    this.map.on(events.BEFORE_MAP_FEATURES_RENDERING_EVENT, (event) => {
      logger(`Dispatch ${events.BEFORE_MAP_FEATURES_RENDERING_EVENT} called from ${event.calledFrom}`);

      this.clearAdjoinmentFeatures();
      this.clearContourLegalityAdjoinmentFeatures();
      this.clearContourFeatures();
    });

    this.map.on(events.AFTER_MAP_FEATURES_RENDERING_EVENT, (event) => {
      logger(`Dispatch ${events.AFTER_MAP_FEATURES_RENDERING_EVENT} called from ${event.calledFrom}`);

      this.checkLegality();
      this.controller.enableDisableThermalExpansionButton();

      this.clearAdjoinmentFeatures();
      this.clearContourLegalityAdjoinmentFeatures();

      this.renderAdjoinAlignments();
      this.renderContourLegalityAdjoinAlignments();
      this.mapModelSynchronizer.reRenderContourFeatures();

      this.#checkThermalExpansionGaps();

      this.controller.focusedRoofPlane.roofSections.forEach((rs) => {
        rs.segments.forEach((s) => {
          if (s.hasContourCodesAndDetailsMismatch) {
            console.log("Warning: Contour Codes Mismatch");
            debugger;
          }
        });
      });

      this.controller.addSnapshotToUndoQueue();
    });
  }

  clearFeaturesBeforeSnapshotUpdate() {
    this.clearAdjoinmentFeatures();
    this.clearContourLegalityAdjoinmentFeatures();
    this.clearContourFeatures();
    this.clearRafterFeatures();
    this.clearPanelFeatures();
    this.clearSegmentFeatures();
    this.clearRailFeatures();
  }

  renderFeaturesAfterSnapshotUpdate() {
    this.controller.focusedRoofPlane.roofSections.forEach((roofSection) => {
      roofSection.segments.forEach((segment) => {
        reRenderSegment(this.controller, segment);
      });
    });

    this.renderAdjoinAlignments();
    this.renderContourLegalityAdjoinAlignments();
    this.renderContourFeatures();
    this.renderRafterFeatures();
  }

  checkLegality() {
    const legalityChecker = super.checkLegality();
    this.controller.encroaching = legalityChecker.encroaching;
    this.controller.needsThermalExpansion = legalityChecker.needsThermalExpansion;
    this.controller.showEncroachingWarningMessage(legalityChecker.encroaching);
    this.controller.showNeedsThermalExpansionWarningMessage(legalityChecker.needsThermalExpansion);
  }

  onMapClick = (event) => {
    const { coordinate } = event;
    const pixelCoordinate = this.map.getPixelFromCoordinate(coordinate);
    const featuresAtPixel = this.map.getFeaturesAtPixel(pixelCoordinate);

    if (this.controller.editorMode === EDITOR_MODE_DRAW) {
      this.onMapClickInDrawMode(event, featuresAtPixel);
    } else if (this.controller.editorMode === EDITOR_MODE_SELECT) {
      this.onMapClickInSelectMode(event, featuresAtPixel);
    }
  };

  onMapClickInDrawMode(event, featuresAtPixel) {
    const shiftKey = event.originalEvent.shiftKey;

    let featuresToSelect = [];

    if (shiftKey) {
      const segmentFeatures = featuresAtPixel.filter((feature) => feature.get("dataType") === SEGMENT_DATA_TYPE);

      if (segmentFeatures.length === 0) return;

      this.controller.setSelectToSegment();
      featuresToSelect = segmentFeatures;
    } else {
      const panelFeatures = featuresAtPixel.filter((feature) => feature.get("dataType") === PANEL_DATA_TYPE);

      if (panelFeatures.length === 0) return;

      this.controller.setSelectToPanel();
      featuresToSelect = panelFeatures;
    }

    setTimeout(() => {
      this.controller.selectInteractionManager.addToSelection(featuresToSelect);
    }, 500);
  }

  onMapClickInSelectMode(_event, featuresAtPixel) {
    const measureFeaturesAtPixel = featuresAtPixel.filter((feature) => feature.get("dataType") === MEASURE_DATA_TYPE);
    const segmentFeaturesAtPixel = featuresAtPixel.filter((feature) => feature.get("dataType") === SEGMENT_DATA_TYPE);
    const thermalExpansionFeatureAtPixel = featuresAtPixel.filter(
      (feature) => feature.get("dataType") === THERMAL_EXPANSION_DATA_TYPE,
    );

    const featuresToCheckFor = [measureFeaturesAtPixel, segmentFeaturesAtPixel, thermalExpansionFeatureAtPixel];
    if (featuresToCheckFor.every((arr) => arr.length === 0)) return;

    const hasPreexistingSelection = (dataTypes) => {
      const preexistingSelectionsOfType = this.controller.selectInteractionManager.selectedFeatures.filter(
        (feature) => {
          return dataTypes.includes(feature.get("dataType"));
        },
      );
      return preexistingSelectionsOfType.length > 0;
    };

    const selectType = this.controller.selectType;

    if (thermalExpansionFeatureAtPixel.length > 0) {
      if (!hasPreexistingSelection([SEGMENT_DATA_TYPE, PANEL_DATA_TYPE, MEASURE_DATA_TYPE])) {
        if (selectType !== SELECT_TYPE_THERMAL_EXPANSIONS) {
          this.controller.setSelectToThermalExpansion();
        }
      }
    } else if (measureFeaturesAtPixel.length > 0) {
      if (!hasPreexistingSelection([SEGMENT_DATA_TYPE, PANEL_DATA_TYPE, THERMAL_EXPANSION_DATA_TYPE])) {
        if (selectType !== SELECT_TYPE_MEASURES) {
          this.controller.setSelectToMeasure();
        }
      }
    } else if (segmentFeaturesAtPixel.length > 0) {
      if (!hasPreexistingSelection([SEGMENT_DATA_TYPE, THERMAL_EXPANSION_DATA_TYPE, MEASURE_DATA_TYPE])) {
        if (selectType === SELECT_TYPE_SEGMENTS) {
          return;
        } else if (selectType !== SELECT_TYPE_PANELS) {
          this.controller.setSelectToPanel();
        }
      }
    }
  }

  onMapDblClick = (event) => {
    if (this.controller.editorMode !== EDITOR_MODE_SELECT) return;

    const { coordinate, originalEvent } = event;

    const pixelCoordinate = this.map.getPixelFromCoordinate(coordinate);
    const featuresAtPixel = this.map.getFeaturesAtPixel(pixelCoordinate);

    if (originalEvent.shiftKey) return;

    const segmentFeatures = featuresAtPixel.filter((feature) => feature.get("dataType") === SEGMENT_DATA_TYPE);
    const panelFeatures = featuresAtPixel.filter((feature) => feature.get("dataType") === PANEL_DATA_TYPE);

    if (segmentFeatures.length === 0 || panelFeatures.length === 0) return;

    if (this.controller.selectType === SELECT_TYPE_SEGMENTS) {
      this.controller.setSelectToPanel();
      this.controller.selectInteractionManager.addToSelection(panelFeatures);
    } else if (this.controller.selectType === SELECT_TYPE_PANELS) {
      this.controller.setSelectToSegment();
      this.controller.selectInteractionManager.addToSelection(segmentFeatures);
    }
  };

  get silhouettePanelsVectorLayer() {
    if (!this.memoSilhouettePanelsVectorLayer) {
      this.memoSilhouettePanelsVectorLayer = new VectorLayer({
        source: this.silhouettePanelsVectorSource,
        style: (feature) => silhouettePanelsStyle(feature),
        zIndex: 70,
      });
    }
    return this.memoSilhouettePanelsVectorLayer;
  }

  buildSilhouettePanelsVectorSource() {
    this.silhouettePanelsVectorSource = new VectorSource();
  }

  buildRowsColumnsMarkerVectorSource() {
    this.rowsColumnsMarkerVectorSource = new VectorSource();
  }

  get rowsColumnsMarkerVectorLayer() {
    if (!this.memoRowsColumnsMarkerVectorLayer) {
      this.memoRowsColumnsMarkerVectorLayer = new VectorLayer({
        source: this.rowsColumnsMarkerVectorSource,
        style: (feature) => rowsColumnsMarkerStyle(feature),
        zIndex: 70,
      });
    }
    return this.memoRowsColumnsMarkerVectorLayer;
  }

  adjustPanelSegmentZIndexesForSelectType(selectType) {
    if (selectType === SELECT_TYPE_SEGMENTS) {
      this.segmentsVectorLayer.setZIndex(60);
      this.panelsVectorLayer.setZIndex(40);
    } else {
      this.panelsVectorLayer.setZIndex(60);
      this.segmentsVectorLayer.setZIndex(40);
    }
  }

  #checkThermalExpansionGaps() {
    this.controller.focusedRoofPlane.roofSections.forEach((roofSection) => {
      roofSection.railedParentSegments.forEach((segment) => {
        const thermalExpansionGaps = new ThermalExpansionGaps({ controller: this.controller, segment });
        const hasIllegalGaps = thermalExpansionGaps.hasGapThatExceedsBondingJumperCompatibility;
        segment.setThermalExpansionGapExceedingBondingJumperLimit(hasIllegalGaps);
      });
    });
  }
}
