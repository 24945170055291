import React from "react";

function ArrowLineEnd() {
  return (
    <svg
      width="6px"
      height="12px"
      viewBox="0 0 6 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
        <polyline stroke="#ddd" points="6 0 1 6 6 12"></polyline>
      </g>
    </svg>
  );
}

export default ArrowLineEnd;
