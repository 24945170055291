import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  connect() {
    this.mapIdentifier = this.element.dataset.mapIdentifier;
    this.element[this.identifier] = this;
  }

  linkClick(event) {
    event.preventDefault();

    const url = event.currentTarget.href;
    this.requestToNavigate(url);
  }

  requestToNavigate(url) {
    if (this.isDirty) {
      this.mapController.saveAndThenRedirect(url);
    } else {
      Turbo.visit(url);
    }
  }

  get mapDomElement() {
    // ~= matches the controller name even if it is not the only controller applied to the element
    return document.querySelector(`[data-controller~='${this.mapIdentifier}']`);
  }

  get mapController() {
    return this.mapDomElement[this.mapIdentifier];
  }

  get isDirty() {
    return this.mapDomElement && this.mapController.dirty;
  }
}
