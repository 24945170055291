import React from "react";

function CompressionGraphic() {
  return (
    <div>
      <svg
        width="49px"
        height="12px"
        viewBox="0 0 49 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
          <g
            id="Group-2"
            transform="translate(0.000000, 1.000000)"
            className="graphical-tool__compressed-space-marker__graphic-line"
          >
            <g id="Group">
              <path d="M0.335616438,5.8 L6.37671233,0.2" id="Line"></path>
              <path
                d="M6.37671233,5.8 L12.4178082,0.2"
                id="Line"
                transform="translate(9.397260, 3.000000) scale(1, -1) translate(-9.397260, -3.000000) "
              ></path>
              <path d="M12.4178082,5.8 L18.4589041,0.2" id="Line"></path>
              <path
                d="M18.4589041,5.8 L24.5,0.2"
                id="Line"
                transform="translate(21.479452, 3.000000) scale(1, -1) translate(-21.479452, -3.000000) "
              ></path>
              <path d="M24.5,5.8 L30.5410959,0.2" id="Line"></path>
              <path
                d="M30.5410959,5.8 L36.5821918,0.2"
                id="Line"
                transform="translate(33.561644, 3.000000) scale(1, -1) translate(-33.561644, -3.000000) "
              ></path>
              <path d="M36.5821918,5.8 L42.6232877,0.2" id="Line"></path>
              <path
                d="M42.6232877,5.8 L48.6643836,0.2"
                id="Line"
                transform="translate(45.643836, 3.000000) scale(1, -1) translate(-45.643836, -3.000000) "
              ></path>
            </g>
            <g id="Group" transform="translate(0.000000, 4.000000)" fill="#FFFFFF">
              <path d="M0.335616438,5.8 L6.37671233,0.2" id="Line"></path>
              <path
                d="M6.37671233,5.8 L12.4178082,0.2"
                id="Line"
                transform="translate(9.397260, 3.000000) scale(1, -1) translate(-9.397260, -3.000000) "
              ></path>
              <path d="M12.4178082,5.8 L18.4589041,0.2" id="Line"></path>
              <path
                d="M18.4589041,5.8 L24.5,0.2"
                id="Line"
                transform="translate(21.479452, 3.000000) scale(1, -1) translate(-21.479452, -3.000000) "
              ></path>
              <path d="M24.5,5.8 L30.5410959,0.2" id="Line"></path>
              <path
                d="M30.5410959,5.8 L36.5821918,0.2"
                id="Line"
                transform="translate(33.561644, 3.000000) scale(1, -1) translate(-33.561644, -3.000000) "
              ></path>
              <path d="M36.5821918,5.8 L42.6232877,0.2" id="Line"></path>
              <path
                d="M42.6232877,5.8 L48.6643836,0.2"
                id="Line"
                transform="translate(45.643836, 3.000000) scale(1, -1) translate(-45.643836, -3.000000) "
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default React.memo(CompressionGraphic);
