import { types } from "mobx-state-tree";
import { v4 as uuid } from "uuid";
import sumBy from "lodash/sumBy";

import * as Api from "../../helpers/api-mobx";
import { projectSitePersistenceData } from "../../da/models/project-site-model";
import DaProjectModel from "../../da/models/project-model";
import RoofPlaneModel, { roofPlanePersistenceData } from "./roof-plane-model";
import DetailModel, { detailPersistenceData } from "./detail-model";
import { logger } from "../../helpers/app";

export const ROOF_SHAPE_HIP = "hip";
export const ROOF_SHAPE_GABLE = "gable";

const ProjectModelBase = types
  .model("ProjectModel", {
    roofPlanes: types.array(RoofPlaneModel),
    deletedRoofPlanes: types.array(RoofPlaneModel),
    detail: DetailModel,
    asce: types.string,
    hiddenEndClamp: types.boolean,
    thermalExpansionLength: types.integer,
    maxLengthBeforeThermalExpansion: types.integer,
  })
  .views((self) => ({
    get shouldLengthCorrectEdges() {
      return true;
    },
    get is716() {
      return self.asce === "7.16";
    },
    get is722() {
      return self.asce === "7.22";
    },
    get is716Or722() {
      return self.is716 || self.is722;
    },
    get segments() {
      return self.roofPlanes.flatMap((rp) => {
        return rp.roofSections.flatMap((rs) => {
          return rs.segments;
        });
      });
    },
    get panelsCount() {
      return sumBy(self.notDeletedRoofPlanes, "panelsCount");
    },
    roofPlaneForIdentifier(identifier) {
      return self.roofPlanes.find((rp) => rp.identifier === identifier);
    },
  }))
  .actions((self) => ({
    addRoofPlane(params) {
      const defaultParams = {
        uuid: uuid(),
        identifier: self.getNextSequentialRoofPlaneIdentifier(),
        eaveRotationAngleRadians: 0,
        boundingBoxCartesianPoints: [],
      };
      const mergedParams = { ...defaultParams, ...params };
      const roofPlane = RoofPlaneModel.create(mergedParams);
      self.roofPlanes.push(roofPlane);

      return roofPlane;
    },
    save({
      path,
      onSuccess = () => {},
      onValidationError = () => {},
      onServerError = () => {},
      includeProjectSite = true,
      includeZonesAndModulePositions = false,
    }) {
      if (self.saving) {
        logger("Already saving. Ignoring request.");
        return;
      }
      self.setSaving();

      const payload = persistenceData(self, includeProjectSite, includeZonesAndModulePositions);
      return Api.put({ model: self, path, body: payload, onSuccess, onValidationError, onServerError });
    },
  }));

function persistenceData(project, includeProjectSite, includeZonesAndModulePositions) {
  const projectSiteAttributes = includeProjectSite ? projectSitePersistenceData(project.projectSite) : undefined;

  // TODO: pass include zones and positions value down the persistence data chain to segments
  return {
    pr_project: {
      project_site_attributes: projectSiteAttributes,
      roof_planes_attributes: project.roofPlanes.map((roofPlane) =>
        roofPlanePersistenceData(roofPlane, includeZonesAndModulePositions),
      ),
      detail_attributes: detailPersistenceData(project.detail),
    },
  };
}

const ProjectModel = types.compose(DaProjectModel, ProjectModelBase);
export default ProjectModel;
