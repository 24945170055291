import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static values = {
    files: Array,
  };

  static targets = ["fileLinkContainer", "searchTextField", "productFamilyCard", "noResultsFoundMsg"];

  initialize() {
    this.search = debounce(this.search, 300);
  }

  search(_event) {
    this.#processSearch();
  }

  clearSearch(event) {
    event.preventDefault();
    this.searchTextFieldTarget.value = "";
    this.#processSearch();
  }

  #processSearch() {
    this.#showSearchResults();
    this.#hideEmptyCards();
    this.#showHideNoResultsFoundMsg();
  }

  #showSearchResults() {
    const searchResultFileIds = this.#searchResults.map((file) => `${file.id}`);
    this.fileLinkContainerTargets.forEach((fileLinkContainer) => {
      const id = fileLinkContainer.dataset.fileId;
      const isPartOfResult = searchResultFileIds.includes(id);
      fileLinkContainer.dataset.visible = isPartOfResult;

      if (isPartOfResult) {
        animate.show(fileLinkContainer, { duration: 250 });
      } else {
        animate.hide(fileLinkContainer, { duration: 250 });
      }
    });
  }

  get #searchResults() {
    const searchTerm = this.searchTextFieldTarget.value.toLowerCase();
    if (searchTerm === "") return this.filesValue;

    return this.filesValue.filter((file) => {
      return (
        file.nameWithType.toLowerCase().includes(searchTerm) ||
        file.productFamilyName.toLowerCase().includes(searchTerm)
      );
    });
  }

  #hideEmptyCards() {
    this.hasVisibleCards = false;
    this.productFamilyCardTargets.forEach((card) => {
      const visibleResults = Array.from(card.querySelectorAll("li")).filter((li) => li.dataset.visible === "true");
      const hasVisibleResults = visibleResults.length > 0;
      card.dataset.visible = !hasVisibleResults;

      if (hasVisibleResults) {
        animate.show(card, { duration: 250 });
        this.hasVisibleCards = true;
      } else {
        animate.hide(card, { duration: 250 });
      }
    });
  }

  #showHideNoResultsFoundMsg() {
    if (this.hasVisibleCards) {
      animate.hide(this.noResultsFoundMsgTarget, { duration: 250, fadeOut: false });
    } else {
      animate.show(this.noResultsFoundMsgTarget, { duration: 250 });
    }
  }
}
