import { getDistance } from "ol/sphere";
import { toLonLat } from "ol/proj";
import { distanceInInchesBetweenCoordinates } from "./styles/distance-markers";
import { isARoofPlane } from "./ol-helpers";
import { degreesToRadians } from "../../helpers/geometry";

export function buildSimpleEdgeList(coordinates) {
  const edgeList = convertCoordinatesToEdges(coordinates);
  edgeList.forEach((edge) => {
    const [startPoint, endPoint] = edge.segment;
    // distance is the OL calculated distance between adjacent
    // vertices. This is accurate for an 0 degree sloped roof.
    edge.distance = distanceInInchesBetweenCoordinates(startPoint, endPoint);
  });

  return edgeList;
}

// builds an edge list, but the distance of the edge takes into
// account the actual size of things by adjusting for roof slope
export function buildLengthCorrectedEdgeList(feature, controller) {
  const geometry = feature.getGeometry().getLinearRing(0);
  const coordinates = geometry.getCoordinates();

  const roofPlane = getRoofPlaneForFeature(feature, controller);
  const anchorPoint = findAnchorPoint(coordinates, roofPlane);
  if (!anchorPoint) return buildSimpleEdgeList(coordinates);

  // each element is:
  //   original: a point in OL coordinates
  //   distanceCoordinates: same point in distance from anchor point
  //   rotatedDistanceCoordinates: rotated so eave is aligned to X axis
  //   adjustedDistanceCoordinates: rotated point with Y value scaled by 1/cos(roofSlope)
  const coordinateVariations = coordinates.map((c) => ({ original: c }));
  addDistanceCoordinates(coordinateVariations, anchorPoint);

  const farEndOfEaveInDistanceCoordinates = coordinateVariations[roofPlane.eaveEdgeIndex + 1].distanceCoordinates;
  const rotationAngle = rotateFeature(farEndOfEaveInDistanceCoordinates, coordinateVariations, roofPlane);
  scaleYAxis(coordinateVariations, roofPlane);

  const edgeList = convertCoordinatesToEdges(coordinates);
  addAdjustedDistanceToEdgeList(edgeList, coordinateVariations);
  // stick the rotationAngle into the first element of the edgeList so
  // we can get to it
  edgeList[0].rotationAngle = rotationAngle;

  return edgeList;
}

export function buildLengthCorrectedMeasure(rpFeature, controller, measureFeature) {
  const geometry = rpFeature.getGeometry().getLinearRing(0);
  const coordinates = geometry.getCoordinates();
  const measureCoordinates = measureFeature.getGeometry().getCoordinates();

  const roofPlane = getRoofPlaneForFeature(rpFeature, controller);
  const anchorPoint = findAnchorPoint(coordinates, roofPlane);
  if (!anchorPoint) return undefined;

  // Need to get eave of roofPlane in distance coordinates
  const coordinateVariations = coordinates.map((c) => ({ original: c }));
  addDistanceCoordinates(coordinateVariations, anchorPoint);
  const farEndOfEaveInDistanceCoordinates = coordinateVariations[roofPlane.eaveEdgeIndex + 1].distanceCoordinates;

  // build an edge list (of 1 element) based on the measure and containing its length
  // corrected for the roof slope of the roof plane
  const measureCoordinateVariations = measureCoordinates.map((c) => ({ original: c }));
  addDistanceCoordinates(measureCoordinateVariations, anchorPoint);
  rotateFeature(farEndOfEaveInDistanceCoordinates, measureCoordinateVariations, roofPlane);
  scaleYAxis(measureCoordinateVariations, roofPlane);
  const edgeList = convertCoordinatesToEdges(measureCoordinates);
  addAdjustedDistanceToEdgeList(edgeList, measureCoordinateVariations);

  return edgeList;
}

function convertCoordinatesToEdges(coordinates) {
  const edgeList = [];

  for (let i = 0; i < coordinates.length - 1; i++) {
    const startPoint = coordinates[i];
    const endPoint = coordinates[i + 1];

    const edge = {
      segment: [startPoint, endPoint],
    };
    edgeList.push(edge);
  }

  return edgeList;
}

function addAdjustedDistanceToEdgeList(edgeList, coordinateVariations) {
  for (let i = 0; i < edgeList.length; i++) {
    const edge = edgeList[i];

    const adjustedStart = coordinateVariations[i].adjustedDistanceCoordinates;
    const adjustedEnd = coordinateVariations[i + 1].adjustedDistanceCoordinates;

    const dx = adjustedEnd[0] - adjustedStart[0];
    const dy = adjustedEnd[1] - adjustedStart[1];

    const distanceInFeet = Math.sqrt(dx * dx + dy * dy);

    // this distance is the value between two adjacent points
    // in adjustedDistanceCoordinates.
    edge.distance = distanceInFeet * 12.0;
  }
}

function getRoofPlaneForFeature(feature, controller) {
  let uuid;
  if (isARoofPlane(feature)) {
    uuid = feature.get("uuid");
  } else {
    uuid = feature.get("roofPlaneUuid");
  }
  if (!uuid) debugger;

  return controller.project.getRoofPlane(uuid);
}

function findAnchorPoint(coordinates, roofPlane) {
  if (roofPlane.eaveEdgeIndex !== null) {
    return coordinates[roofPlane.eaveEdgeIndex];
  } else {
    return undefined;
  }
}

function addDistanceCoordinates(coordinateVariations, anchorPoint) {
  const anchorPointLonLat = toLonLat(anchorPoint);

  coordinateVariations.forEach((coordinate) => {
    const vertex = coordinate.original;

    const vertexLonLat = toLonLat(vertex);
    const cornerLonLat = [vertexLonLat[0], anchorPointLonLat[1]];

    const distanceLng = getDistance(anchorPointLonLat, cornerLonLat) * 3.28084;
    const distanceLat = getDistance(cornerLonLat, vertexLonLat) * 3.28084;

    // As lng gets more negative, x gets smaller
    const signLng = vertexLonLat[0] < anchorPointLonLat[0] ? -1 : 1;
    // as lat gets bigger, y gets bigger
    const signLat = vertexLonLat[1] > anchorPointLonLat[1] ? 1 : -1;

    coordinate.distanceCoordinates = [signLng * distanceLng, signLat * distanceLat];
  });
}

function rotateFeature(farEndOfEaveInDistanceCoordinates, coordinateVariations, roofPlane) {
  const angle = Math.atan2(farEndOfEaveInDistanceCoordinates[1], farEndOfEaveInDistanceCoordinates[0]);
  const rotation = -angle;

  coordinateVariations.forEach((variation) => {
    const coordinates = variation.distanceCoordinates;

    const x = coordinates[0];
    const y = coordinates[1];

    const xPrime = x * Math.cos(rotation) - y * Math.sin(rotation);
    const yPrime = x * Math.sin(rotation) + y * Math.cos(rotation);

    variation.rotatedDistanceCoordinates = [xPrime, yPrime];
  });

  return rotation;
}

function scaleYAxis(coordinateVariations, roofPlane) {
  const roofSlopeRadians = degreesToRadians(roofPlane.roofSlope);
  const scaling = 1.0 / Math.cos(roofSlopeRadians);

  coordinateVariations.forEach((variation) => {
    const coordinates = variation.rotatedDistanceCoordinates;

    const x = coordinates[0];
    const y = coordinates[1];

    variation.adjustedDistanceCoordinates = [x, y * scaling];
  });
}
