import { Stroke, Style } from "ol/style";
import { GeometryType } from "../ol-helpers";

const RULER_STYLE = new Style({
  stroke: new Stroke({ color: `rgba(255, 0, 0)`, width: 1 }),
  zIndex: 10,
});

const MARKER_STYLE = new Style({
  stroke: new Stroke({ color: `rgba(255, 0, 0)`, width: 1 }),
  zIndex: 20,
});

export function rulersStyle(feature) {
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();

  if (geometryType === GeometryType.LINE_STRING) {
    return RULER_STYLE;
  } else {
    return MARKER_STYLE;
  }
}
