import RailGroupConsolidator from "../../rail-group-consolidator";
import ThermalExpansionsHorizontalNudger from "../../thermal-expansions/horizontal-nudger";
import { shiftSimplifiedRailGroupSelection } from "../helpers";
import PanelsNudgerBase from "./base";

export default class PanelsNudgerHorizontal extends PanelsNudgerBase {
  getNudgeVector() {
    const directionMultiplier = this.nudgeDirection === "right" ? -1 : 1;
    return this.roofPlane.eaveEdgeVectorLatLng.times(directionMultiplier * this.nudgeDistance);
  }

  nudge(options = {}) {
    this.shiftSplits = options.shiftSplits;
    this.railGroups = this.generateNewRailGroupsFromNudgeSplits();
    this.#nudgeRailGroupSegmentsWithSelections();
    this.thermalExpansionNudgeCalls = 0;

    this.nudgeThermalExpansionAndHandleConsolidations();
  }

  nudgeThermalExpansionAndHandleConsolidations() {
    this.railGroups.forEach((railGroup) => {
      this.#thermalExpansionNudging(railGroup);

      this.#consolidateOverlappingRailGroupSegments(railGroup);

      railGroup.reRenderSegmentsWithPanelSelections(this.selectionCollection);
    });
  }

  simplifiedRailGroupGridsAfterNudgeSplit(railGroup) {
    let simplifiedGrid = railGroup.simplifiedGrid;
    if (this.shiftSplits) {
      simplifiedGrid = shiftSimplifiedRailGroupSelection(simplifiedGrid, this.shiftSplits);
    }
    const consolidatedPanelsGridStartLatLngs = railGroup.consolidatedPanelsGridStartLatLngs;
    const consolidatedZonesAndModulePositionsGrid = railGroup.consolidatedSegmentZonesAndModulePositionsGrid;
    const consolidatedContourCodesGrid = railGroup.consolidatedContourCodesGrid;

    const controlColumnIndex = (segmentRow) => {
      if (!segmentRow.some((panelStatus) => panelStatus === "X")) return undefined;

      const selectionIndexes = [];
      segmentRow.forEach((panelStatus, i) => {
        if (panelStatus === "O") return;

        selectionIndexes.push(i);
      });

      const minMax = this.nudgeDirection === "left" ? "max" : "min";
      const result = Math[minMax](...selectionIndexes);
      return result;
    };

    const segmentComparisonString = (segmentRow, controlColumnIndex) => {
      return segmentRow.map((_panelStatus, i) => (i === controlColumnIndex ? "X" : "O")).join("");
    };

    const segmentRowNudgeSplits = (segmentRow, controlColumnIndex) => {
      if (controlColumnIndex === undefined) return [segmentRow];

      const sliceIndex = this.nudgeDirection === "left" ? controlColumnIndex + 1 : controlColumnIndex;
      const result = [segmentRow.slice(0, sliceIndex), segmentRow.slice(sliceIndex, segmentRow.length)];
      return result.filter((result) => result.length > 0);
    };

    let newRailGroupSimplifiedGrids = [];
    let currentRailGroupGrid;

    simplifiedGrid.forEach((simplifiedGridRow, i) => {
      const controlColumnIndexes = simplifiedGridRow.map((segmentRow) => controlColumnIndex(segmentRow));
      const comparisonString = simplifiedGridRow
        .map((segmentRow, i) => segmentComparisonString(segmentRow, controlColumnIndexes[i]))
        .join("");
      const simplifiedGridRowWithNudgeSplits = simplifiedGridRow.flatMap((segmentRow, i) =>
        segmentRowNudgeSplits(segmentRow, controlColumnIndexes[i]),
      );

      const setCurrentRailGroupGrid = () => {
        currentRailGroupGrid = {
          comparisonString,
          gridRows: [simplifiedGridRowWithNudgeSplits],
          consolidatedPanelStartLatLngRows: [consolidatedPanelsGridStartLatLngs[i]],
          consolidatedZonesAndModulePositionsGrid: [consolidatedZonesAndModulePositionsGrid[i]],
          consolidatedContourCodesGrid: [consolidatedContourCodesGrid[i]],
        };
      };

      if (i === 0) {
        setCurrentRailGroupGrid();
      } else {
        if (comparisonString === currentRailGroupGrid.comparisonString) {
          currentRailGroupGrid.gridRows.push(simplifiedGridRowWithNudgeSplits);
          currentRailGroupGrid.consolidatedPanelStartLatLngRows.push(consolidatedPanelsGridStartLatLngs[i]);
          currentRailGroupGrid.consolidatedZonesAndModulePositionsGrid.push(consolidatedZonesAndModulePositionsGrid[i]);
          currentRailGroupGrid.consolidatedContourCodesGrid.push(consolidatedContourCodesGrid[i]);
        } else {
          newRailGroupSimplifiedGrids.push(currentRailGroupGrid);
          setCurrentRailGroupGrid();
        }
      }
    });
    newRailGroupSimplifiedGrids.push(currentRailGroupGrid);

    return newRailGroupSimplifiedGrids;
  }

  #nudgeRailGroupSegmentsWithSelections() {
    this.railGroups.forEach((railGroup) => {
      if (!railGroup.hasAnyPanelSelections) return;
      let simplifiedGrid = railGroup.simplifiedGrid;
      railGroup.segments.forEach((segment) => {
        if (!railGroup.segmentHasPanelSelections(segment)) return;

        const nudgedStartLatLng = segment.startLatLng.plus(this.nudgeVector);
        this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(segment, nudgedStartLatLng);
      });
      railGroup.updateDistancesFromRailedParentStart();
      railGroup.clearPanelSelections();
      railGroup.reRenderSegments();

      if (this.shiftSplits) {
        simplifiedGrid = shiftSimplifiedRailGroupSelection(simplifiedGrid, this.shiftSplits * -1);
      }
      railGroup.selectPanelsFromSimplifiedGrid(simplifiedGrid, this.selectionCollection);
    });
  }

  #thermalExpansionNudging(railGroup) {
    this.thermalExpansionNudgeCalls++;
    if (this.thermalExpansionNudgeCalls > 25) {
      console.error("Called the thermal expansion nudger more than 25 times");
      return;
    }

    const { controller, nudgeDirection } = this;

    const nudgesOccurred = new ThermalExpansionsHorizontalNudger({
      controller,
      railGroup,
      nudgeDirection,
    }).nudge();

    if (nudgesOccurred) this.#thermalExpansionNudging(railGroup);
  }

  #consolidateOverlappingRailGroupSegments(railGroup) {
    const { controller, nudgeDirection } = this;

    const consolidationsOccurred = new RailGroupConsolidator({
      controller,
      railGroup,
      nudgeDirection,
    }).consolidate();

    this.#thermalExpansionNudging(railGroup, 1);

    if (consolidationsOccurred) this.#consolidateOverlappingRailGroupSegments(railGroup);
  }
}
