import { fromLonLat } from "ol/proj";

import EaveSelectionSelectInteractionManager from "../../../../pr/map/interaction-managers/select/eave-selection";

import EaveSelectionMapModelSynchronizer from "../../../../pr/map/map-model-synchronizers/eave-selection";
import EaveSelectionMapManager from "../../../../pr/map/map-managers/eave-selection";

import MapBaseController from "./map_base_controller";

import { findLineStringFeatureWithMatchingCoordinates } from "../../../../da/map/ol-helpers";

export default class MapRoofPlanesController extends MapBaseController {
  static targets = ["map", "compass", "saveBtn", "statusItemZoom", "statusItemRotation"];

  connect() {
    super.connect();

    this.focusedRoofPlaneUuid = this.element.dataset.focusedRoofPlaneUuid;
    this.focusedRoofPlane = this.project.getRoofPlane(this.focusedRoofPlaneUuid);
    this.isLocked = this.focusedRoofPlane.modifyingEaveOrPropertiesLocked;

    this.mapModelSynchronizer = new EaveSelectionMapModelSynchronizer(this);

    this.mapManager = new EaveSelectionMapManager(this);
    this.mapManager.add();

    // Add interactions
    this.selectInteractionManager = new EaveSelectionSelectInteractionManager(this);
    if (!this.isLocked) {
      this.selectInteractionManager.add();
      this.selectPersistedEaveEdge();
    } else {
      this.highlightSelectedEaveEdge();
    }
    this.populateStatusbar();

    setTimeout(() => {
      this.alignViewToFeature(this.mapModelSynchronizer.getFeatureForRoofPlane(this.focusedRoofPlane));
    }, 200);
  }

  selectPersistedEaveEdge() {
    if (this.focusedRoofPlane.eaveEdgeIndex === null) return;

    this.selectInteractionManager.currentSelectInteraction.getFeatures().push(this.selectedEaveEdgeFeature);
  }

  highlightSelectedEaveEdge() {
    this.selectedEaveEdgeFeature.set("lockedSelected", true);
  }

  get selectedEaveEdgeFeature() {
    const roofPlaneCoordinates = this.focusedRoofPlane.latLngPoints.map((p) => fromLonLat(p.toLonLat));
    const segments = [];
    for (let i = 0; i < roofPlaneCoordinates.length - 1; i++) {
      segments.push([roofPlaneCoordinates[i], roofPlaneCoordinates[i + 1]]);
    }
    const eaveEdgeCoordinates = segments[this.focusedRoofPlane.eaveEdgeIndex];

    const features = this.mapManager.roofPlaneLineStringsVectorSource.getFeatures();
    return findLineStringFeatureWithMatchingCoordinates(features, eaveEdgeCoordinates);
  }

  // Needed to override parent class method
  startSelectMode() {}

  // Called by both Stimulus event handler and saveAndThenRedirect triggered by auto save controller
  save(_event, onSuccess = () => {}) {
    this.modelSave({ model: this.project, onSuccess, includeProjectSite: false });
  }
}
