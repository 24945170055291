import { Fill, Stroke, Style, Text } from "ol/style";
import { sentryException } from "../../../config/sentry";
import { numberVertices } from "./helpers";

export function roofPlaneStyle(feature, controller, project) {
  const styles = [];

  const opacity = controller.isRoofPlanesPage ? 0.3 : 0.1;
  const uuid = feature.get("uuid");
  const roofPlane = project.getRoofPlane(uuid);

  if (!roofPlane) {
    // const deletedRoofPlane = project.deletedRoofPlane(uuid);
    // if (deletedRoofPlane === undefined) {
    //   const extras = {
    //     uuid: uuid,
    //     project: project.id,
    //     feature: feature.getProperties(),
    //   };
    //   sentryException(
    //     `[Handled Error] Trying to render a roof plane that is not in the array of deleted roof planes for project.`,
    //     extras,
    //   );
    // } else {
    //   const extras = {
    //     saveStatus: deletedRoofPlane.id === undefined ? "unpersisted" : "persisted",
    //     uuid: uuid,
    //     project: project.id,
    //     deletedAt: deletedRoofPlane.deletedAt,
    //   };
    //   sentryException(`[Handled Error] Trying to render a deleted roof plane for project.`, extras);
    // }

    return [];
  }

  let label = roofPlane.identifier;
  if (!controller.isRoofPlanesPage && roofPlane.hasActiveRoofSections) {
    label = "";
  }

  const warningStrokeColor = controller.isRoofSectionsPage ? "rgba(255, 0, 0, 1.0)" : "rgba(77, 151, 246, 1.0)";

  if (roofPlane.illegalShape) {
    styles.push(illegalRoofPlaneStyle(opacity, label));
  } else if (!controller.isRoofSectionsPage && roofPlane.hasIllegalRoofSections) {
    styles.push(legalRoofPlaneWithIllegalRoofSections(label, opacity));
  } else if (roofPlane.isRoofSectionRequired) {
    styles.push(roofPlaneWithoutRoofSectionsStyle(label, opacity, warningStrokeColor));
  } else if (controller.isRoofPlanesPage && roofPlane.modifyingTraceLocked) {
    styles.push(lockedRoofPlaneStyle(label));
  } else {
    styles.push(legalRoofPlaneStyle(opacity, label));
  }

  if (controller.debugMode === "YES") numberVertices(feature, styles);

  return styles;
}

function legalRoofPlaneStyle(opacity, label) {
  return new Style({
    stroke: new Stroke({ color: "rgba(77, 151, 246, 1.0)", width: 3 }),
    fill: new Fill({ color: `rgba(77, 151, 246, ${opacity})` }),
    text: new Text({ text: label, scale: 2, fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }) }),
  });
}

function illegalRoofPlaneStyle(opacity, label) {
  return new Style({
    stroke: new Stroke({ color: "rgba(255, 0, 0, 1.0)", width: 3 }),
    fill: new Fill({ color: `rgba(255, 0, 0, ${opacity})` }),
    text: new Text({ text: label, scale: 2, fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }) }),
    zIndex: 3,
  });
}

function legalRoofPlaneWithIllegalRoofSections(label, opacity) {
  return new Style({
    stroke: new Stroke({ color: "rgba(77, 151, 246, 1.0)", width: 3 }),
    fill: new Fill({ color: `rgba(255, 0, 0, ${opacity})` }),
    text: new Text({ text: label, scale: 2, fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }) }),
    zIndex: 3,
  });
}

function roofPlaneWithoutRoofSectionsStyle(label, opacity, warningStrokeColor) {
  return new Style({
    stroke: new Stroke({ color: warningStrokeColor, width: 3 }),
    fill: new Fill({ color: `rgba(255, 0, 0, ${opacity})` }),
    text: new Text({ text: label, scale: 2, fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }) }),
    zIndex: 2,
  });
}

function lockedRoofPlaneStyle(label) {
  return new Style({
    stroke: new Stroke({ color: "rgba(246, 240, 56, 1.0)", width: 3 }),
    fill: new Fill({ color: `rgba(246, 240, 56, 0.3)` }),
    text: new Text({ text: label, scale: 2, fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }) }),
  });
}

export function selectRoofPlanePolygonStrokeAndFillColors(feature, controller) {
  const roofPlane = controller.project.getRoofPlane(feature.get("uuid"));

  if (roofPlane.hasIllegalRoofSections) {
    return ["rgba(77, 151, 246, 1.0)", `rgba(255, 0, 0, 0.4)`];
  } else if (roofPlane.isRoofSectionRequired) {
    return ["rgba(77, 151, 246, 1.0)", `rgba(255, 0, 0, 0.4)`];
  } else {
    return ["rgba(77, 151, 246, 1.0)", "rgba(77, 151, 246, 0.4)"];
  }
}
