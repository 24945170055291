import ObstructionLegalityChecker from "../../../da/map/legality-checkers/obstructions";
import ObstructionsBufferBuilder from "../obstructions/buffer-builder";
import ObstructionsProximityDetector from "../obstructions/proximity-detector";
import { linkFeatureToModel } from "../../../da/map/modification-helpers/base";
import { findTopLeftLonLat, setCartesianPointsForModelRelativeToOrigin } from "../../../da/map/ol-geometry";
import { removeFeaturesForObstruction } from "../ol-helpers";
import { removeFeaturesMatchingUuid } from "../../../da/map/ol-helpers";
import LayoutBuilder from "../panel-grid/layout-builder";

export function createObstruction(oFeature, project, mapModelSynchronizer, mapManager) {
  const obstruction = project.addObstruction({});
  linkFeatureToModel(oFeature, obstruction);

  mapModelSynchronizer.updateObstructionLatLngFromFeature(oFeature);
  syncObstructionCartesianPoints(obstruction);
  new ObstructionLegalityChecker(mapManager.obstructionsFeatures, mapModelSynchronizer).markIllegalPolygons(oFeature);

  reprocessRoofSectionsDueToObstructionChange(project);
  // NOTE: no point in doing obstruction proximity detection here because we don't
  // have a height for the obstruction yet, so we don't have a buffer. Hence we won't
  // detect any proximity since that's based on the buffer

  return obstruction;
}

export function modifyObstruction(oFeature, mapModelSynchronizer, mapManager) {
  repositionObstruction(oFeature, mapModelSynchronizer, mapManager);
  mapManager.forceRedrawObstructions();
}

export function repositionObstruction(oFeature, mapModelSynchronizer, mapManager) {
  const obstruction = mapModelSynchronizer.getObstructionForFeature(
    oFeature,
    "BxObstructionModificationHelper.repositionObstruction",
  );

  mapModelSynchronizer.updateObstructionLatLngFromFeature(oFeature);
  syncObstructionCartesianPoints(obstruction);
  new ObstructionLegalityChecker(mapManager.obstructionsFeatures, mapModelSynchronizer).markIllegalPolygons();

  rebuildObstructionBuffers(obstruction, mapManager, mapModelSynchronizer);
}

export function rebuildObstructionBuffers(obstruction, mapManager, mapModelSynchronizer) {
  new ObstructionsBufferBuilder(
    mapManager.controller,
    mapManager.map,
    mapManager.obstructionsFeatures,
    mapManager.obstructionBuffersFeatures,
  );

  reprocessRoofSectionsDueToObstructionChange(mapModelSynchronizer.project);
  new ObstructionsProximityDetector(mapManager.project).findNearbyRoofPlanesAndSectionsForObstruction(obstruction);
}

export function deleteObstruction(rpFeature, project, mapModelSynchronizer, mapManager) {
  const obstruction = mapModelSynchronizer.getObstructionForFeature(
    rpFeature,
    "BxObstructionModificationHelper.deleteObstruction",
  );

  if (!obstruction) {
    console.log("Deleted feature not a polygon. Skipping.");
    return;
  }

  removeFeaturesForObstruction(obstruction, mapManager);

  project.destroyObstruction(obstruction);

  // This is less efficient if multiple roof planes are being deleted, but it's cleaner
  // because we can be confident that each call to deleteObstruction will leave things in
  // a valid final state.
  new ObstructionLegalityChecker(mapManager.obstructionsFeatures, mapModelSynchronizer).markIllegalPolygons();
  project.resequenceObstructionsIdentifiers();
  mapModelSynchronizer.syncIdentifiersOnFeatures();

  new ObstructionsBufferBuilder(
    mapManager.controller,
    mapManager.map,
    mapManager.obstructionsFeatures,
    mapManager.obstructionBuffersFeatures,
  );

  reprocessRoofSectionsDueToObstructionChange(project);
}

export function syncObstructionCartesianPoints(obstructionOrBuffer) {
  const topLeftLonLat = findTopLeftLonLat(obstructionOrBuffer.latLngPoints);

  setCartesianPointsForModelRelativeToOrigin(topLeftLonLat, obstructionOrBuffer);
}

export function removeObstructionBuffer(oFeature, mapModelSynchronizer, mapManager) {
  const obstruction = mapModelSynchronizer.getObstructionForFeature(oFeature, "removeObstructionBuffer");
  removeFeaturesMatchingUuid(mapManager.obstructionBuffersVectorSource, obstruction.uuid);
  obstruction.removeBuffer();
}

export function reprocessRoofSectionsDueToObstructionChange(project) {
  project.notDeletedRoofPlanes.forEach((roofPlane) => {
    roofPlane.activeRoofSections.forEach((roofSection) => {
      new LayoutBuilder(project, roofSection).reprocessExistingLayout();
    });
  });
}
