import "core-js/stable";
import "regenerator-runtime/runtime";

import "./childnode-after";
import "./childnode-append";
import "./childnode-before";
import "./childnode-remove";
import "./childnode-replace-with";
import "./custom-event";
import "./element-class-list";
import "./element-closest";
import "./element-matches";
import "./element-toggle-attribute";
import "./nodelist-for-each";
import "./parent-node-prepend";

import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();
