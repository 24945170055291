import { Controller } from "@hotwired/stimulus";

export default class IrAutoGrowTextAreaController extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTarget.style.resize = "none";
    if (this.inputTarget.value !== "") this.inputTarget.style.height = `${this.inputTarget.scrollHeight}px`;
    this.inputTarget.style.overflow = "hidden";
  }

  resize(event) {
    event.target.style.height = `${event.target.scrollHeight}px`;
  }
}
