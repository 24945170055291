import React from "react";
import PropTypes from "prop-types";

import { showDialogWithIdentifier } from "../../controllers/components/ir_dialog/helper";

function InfoPopupLink({ dialogIdentifier }) {
  const onClick = (e) => {
    e.preventDefault();
    const clickPosition = { x: e.pageX, y: e.pageY };
    showDialogWithIdentifier(dialogIdentifier, () => {}, { showNearClickPosition: true, clickPosition });
  };

  return (
    <a href="#" className="info-popup__link" onClick={onClick} data-testid={dialogIdentifier}>
      <i className="fas fa-info-circle" />
    </a>
  );
}

InfoPopupLink.propTypes = {
  dialogIdentifier: PropTypes.string.isRequired,
};

export default React.memo(InfoPopupLink);
