export const CONTOUR_CODE_NONE = "0";
export const CONTOUR_CODE_SOUTH = "1";
export const CONTOUR_CODE_WEST = "2";
export const CONTOUR_CODE_SOUTH_AND_WEST = "3";
export const CONTOUR_CODE_EAST = "4";
export const CONTOUR_CODE_SOUTH_AND_EAST = "5";
export const CONTOUR_CODE_WEST_AND_EAST = "6";
export const CONTOUR_CODE_SOUTH_AND_WEST_AND_EAST = "7";

export const CONTOUR_DIRECTION_SOUTH = "south";
export const CONTOUR_DIRECTION_WEST = "west";
export const CONTOUR_DIRECTION_EAST = "east";

export function contourCodeGenerator(isWest, isEast, isSouth) {
  return `${(isSouth ? 1 : 0) + (isWest ? 2 : 0) + (isEast ? 4 : 0)}`;
}

export const isContourWest = (code) =>
  [
    CONTOUR_CODE_WEST,
    CONTOUR_CODE_SOUTH_AND_WEST,
    CONTOUR_CODE_WEST_AND_EAST,
    CONTOUR_CODE_SOUTH_AND_WEST_AND_EAST,
  ].includes(code);

export const isContourEast = (code) =>
  [
    CONTOUR_CODE_EAST,
    CONTOUR_CODE_SOUTH_AND_EAST,
    CONTOUR_CODE_WEST_AND_EAST,
    CONTOUR_CODE_SOUTH_AND_WEST_AND_EAST,
  ].includes(code);

export const isContourSouth = (code) =>
  [
    CONTOUR_CODE_SOUTH,
    CONTOUR_CODE_SOUTH_AND_WEST,
    CONTOUR_CODE_SOUTH_AND_EAST,
    CONTOUR_CODE_SOUTH_AND_WEST_AND_EAST,
  ].includes(code);

export const contourCodeToDirections = (code) => {
  const contourPaintDirections = [];
  if (isContourSouth(code)) contourPaintDirections.push(CONTOUR_DIRECTION_SOUTH);
  if (isContourWest(code)) contourPaintDirections.push(CONTOUR_DIRECTION_WEST);
  if (isContourEast(code)) contourPaintDirections.push(CONTOUR_DIRECTION_EAST);
  return contourPaintDirections;
};

export const contourDirectionsToCode = (directions) => {
  const isWest = directions.includes(CONTOUR_DIRECTION_WEST);
  const isEast = directions.includes(CONTOUR_DIRECTION_EAST);
  const isSouth = directions.includes(CONTOUR_DIRECTION_SOUTH);
  return contourCodeGenerator(isWest, isEast, isSouth);
};

export const contourDirectionToggle = (existingCode, directionToggle) => {
  let isWest = isContourWest(existingCode);
  let isEast = isContourEast(existingCode);
  let isSouth = isContourSouth(existingCode);

  isWest = directionToggle === CONTOUR_DIRECTION_WEST ? !isWest : isWest;
  isEast = directionToggle === CONTOUR_DIRECTION_EAST ? !isEast : isEast;
  isSouth = directionToggle === CONTOUR_DIRECTION_SOUTH ? !isSouth : isSouth;

  if (!isWest && !isEast && !isSouth) return existingCode;

  return contourCodeGenerator(isWest, isEast, isSouth);
};

export const contourCodeHumanized = (code) => {
  switch (code) {
    case CONTOUR_CODE_NONE:
      return "no contour";
    case CONTOUR_CODE_SOUTH:
      return CONTOUR_DIRECTION_SOUTH;
    case CONTOUR_CODE_WEST:
      return CONTOUR_DIRECTION_WEST;
    case CONTOUR_CODE_SOUTH_AND_WEST:
      return `${CONTOUR_DIRECTION_SOUTH} + ${CONTOUR_DIRECTION_WEST}`;
    case CONTOUR_CODE_EAST:
      return CONTOUR_DIRECTION_EAST;
    case CONTOUR_CODE_SOUTH_AND_EAST:
      return `${CONTOUR_DIRECTION_SOUTH} + ${CONTOUR_DIRECTION_EAST}`;
    case CONTOUR_CODE_WEST_AND_EAST:
      return `${CONTOUR_DIRECTION_WEST} + ${CONTOUR_DIRECTION_EAST}`;
    case CONTOUR_CODE_SOUTH_AND_WEST_AND_EAST:
      return `${CONTOUR_DIRECTION_SOUTH} + ${CONTOUR_DIRECTION_WEST} + ${CONTOUR_DIRECTION_EAST}`;
  }
};
