import { PANEL_DATA_TYPE } from "../../../da/map/data-types";

/*
Takes in segments and panels with selections like this:

    1        1   2        1   2
  +---+    +---+---+    +---+---+
1 |   |====|   | ■ |====|   |   |
  +---+    +---+---+    +---+---+
2 |   |====|   |   |====|   |   |
  +---+    +---+---+    +---+---+

and produces a simplified data structure representation that's easier to work with:

simplifiedRailGroups = [
  [
    [["O"], ["O", "X"], ["O", "O"]],
    [["O"], ["O", "O"], ["O", "O"]],
  ]
]
*/
export function simplifiedRailGroup({ segmentFeatures, allPanelsBySegmentUuid, selectedPanelsBySegmentUuid }) {
  const segmentsRows = segmentFeatures[0].get("model").rows;

  const segmentRows = Array.from({ length: segmentsRows }).map((_, i) => {
    const segmentRow = i + 1;
    return segmentFeatures.map((sf) => {
      const segmentUuid = sf.get("uuid");
      const panelFeatures = allPanelsBySegmentUuid[segmentUuid];
      const selectedPanelFeatures = selectedPanelsBySegmentUuid[segmentUuid] || [];
      const selectedPanelColumnsInRow = selectedPanelFeatures
        .filter((pf) => pf.get("model").row === segmentRow)
        .map((pf) => pf.get("model").column);

      const sortedRowPanels = panelFeatures
        .filter((pf) => pf.get("model").row === segmentRow)
        .sort((pfa, pfb) => pfa.get("model").column - pfb.get("model").column);
      const panelSelectionStatuses = sortedRowPanels.map((pf) => {
        return selectedPanelColumnsInRow.includes(pf.get("model").column) ? "X" : "O";
      });
      return panelSelectionStatuses;
    });
  });

  return segmentRows;
}

/*
When we go to create new segments after splitting because of a nudge, we need to get the
segments' first panel's start lat,lng for rendering.  We use a simplified data structure
to do the segment splitting, but because the number of panels and relative panel positions
don't change we can figure out the consolidated position of the simplified data structure's
first panel for each segment.  We can use these indexes to do a lookup.

This function creates a similar consolidated grid for actual panel features that are a part
of the source/before-nudge segments, allowing us to do the look up and get the appropriate
panel and its start lat,lng.

Visually, from the source segment features like this:
    1   2        1   2        1   2
  +---+---+    +---+---+    +---+---+
1 |   |   |====|   |   |====|   |   |
  +---+---+    +---+---+    +---+---+
2 |   |   |====|   |   |====|   |   |
  +---+---+    +---+---+    +---+---+

We're creating a consolidated grid of panels like this:
    1   2   3   4   5   6
  +---+---+---+---+---+---+
1 |   |   |   |   |   |   |
  +---+---+---+---+---+---+
2 |   |   |   |   |   |   |
  +---+---+---+---+---+---+
*/
export function railGroupConsolidatedGrid(segmentFeatures, panelsBySegmentUuid) {
  const gridRows = segmentFeatures[0].get("model").rows;
  const gridColumns = segmentFeatures.reduce((sum, sf) => sum + sf.get("model").columns, 0);
  const grid = Array.from({ length: gridRows }).map((_) => Array.from({ length: gridColumns }));

  let columnOffset = 0;
  segmentFeatures.forEach((segmentFeature, i) => {
    const panelFeatures = panelsBySegmentUuid[segmentFeature.get("uuid")];

    panelFeatures.forEach((panelFeature) => {
      const gridRowIndex = panelFeature.get("model").row - 1;
      const gridColumnIndex = panelFeature.get("model").column + columnOffset - 1;
      grid[gridRowIndex][gridColumnIndex] = panelFeature;
    });

    columnOffset += segmentFeature.get("model").columns;
  });
  return grid;
}

export function allPanelsBySegmentUuid(mapManager) {
  const { segmentsVectorSource, panelsVectorSource } = mapManager;
  const allSegmentFeatures = segmentsVectorSource.getFeatures();
  const allPanelFeatures = panelsVectorSource.getFeatures();
  const segmentUuids = allSegmentFeatures.map((sf) => sf.get("uuid"));
  return segmentUuids.reduce((obj, segmentUuid) => {
    obj[segmentUuid] = allPanelFeatures.filter((pf) => pf.get("segmentUuid") === segmentUuid);
    return obj;
  }, {});
}

export function selectedPanelsBySegmentUuid(selectInteractionManager) {
  const selectedPanelFeatures = selectInteractionManager.selectedFeatures;
  const result = {};
  if (selectedPanelFeatures.length === 0) return result;

  if (selectedPanelFeatures[0].get("dataType") !== PANEL_DATA_TYPE) {
    throw "The select interaction does not contain panels.";
  }

  selectedPanelFeatures.forEach((pf) => {
    const segmentUuid = pf.get("segmentUuid");
    if (!result[segmentUuid]) result[segmentUuid] = [];

    result[segmentUuid].push(pf);
  });

  return result;
}

export function removeSegmentPanelsFromSelectInteraction(controller, segmentUuid) {
  const selectInteractionManager = controller.selectInteractionManager;
  const { selectionCollection } = selectInteractionManager;

  const selectRemovals = [];
  selectionCollection.forEach((pf) => {
    if (pf.get("segmentUuid") === segmentUuid) selectRemovals.push(pf);
  });
  selectRemovals.forEach((pf) => selectionCollection.remove(pf));
}
