import { Draw } from "ol/interaction";

import { drawStyle } from "../../styles/draw";
import { ensureFeatureIsClockwise } from "../../ol-helpers";

export default class Base {
  constructor(controller) {
    this.controller = controller;

    this.project = controller.project;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;

    this.project = controller.project;
    this.drawBtnTarget = controller.drawBtnTarget;

    this.currentDrawInteraction = undefined;
  }

  // Add polygon drawing interaction to the vector source
  add() {
    this.clearCurrentInteraction();

    this.currentDrawInteraction = new Draw({
      source: this.targetDrawingSource,
      type: "Polygon",
      style: (feature) => drawStyle(feature, this.map),
    });

    this.currentDrawInteraction.on("drawstart", this.onDrawStart);
    this.currentDrawInteraction.on("drawend", this.onDrawEnd);
    this.map.addInteraction(this.currentDrawInteraction);
    this.controller.snapInteractionManager.refresh();
  }

  finish() {
    if (!this.currentDrawInteraction) return;

    this.currentDrawInteraction.finishDrawing();

    this.controller.project.clearDrawingCoordinates();

    // If we end up with only a line, remove it
    const features = this.targetDrawingSource.getFeatures();
    const lastFeature = features[features.length - 1];
    if (lastFeature && lastFeature.getGeometry().getArea() === 0) {
      this.targetDrawingSource.removeFeature(lastFeature);
    }
  }

  remove() {
    this.controller.project.clearDrawingCoordinates();
    this.mapManager.rulersVectorSource.clear();
    this.clearCurrentInteraction();
  }

  clearCurrentInteraction() {
    if (this.currentDrawInteraction) {
      this.currentDrawInteraction.un("drawstart", this.onDrawStart);
      this.currentDrawInteraction.un("drawend", this.onDrawEnd);
      this.map.removeInteraction(this.currentDrawInteraction);
      delete this.currentDrawInteraction;
      this.currentDrawInteraction = undefined;
    }
  }

  reset() {
    this.remove();
    this.add();
  }

  get featureDataType() {
    // override in sub class
  }

  onDrawStart = (_event) => {};

  onDrawEnd = (event) => {
    const { feature } = event;

    this.controller.project.clearDrawingCoordinates();
    this.featureSpecificDrawEndProcessing(event);

    if (feature.getGeometry().getArea() === 0) {
      feature.set("flaggedForAutoDeleteByVectorSource", true);
      feature.set("dataType", this.featureDataType);

      return;
    }

    // TODO: How can we get a feature with an id?
    if (feature.getId()) {
      console.error("New feature already has an id");
      debugger;
    }

    if (feature.get("uuid")) {
      console.error("New feature already has a UUID");
      debugger;
    }

    ensureFeatureIsClockwise(feature);
    this.createModelFromFeature(feature);

    this.controller.markDirty();
  };

  initializeDrawingFromProjectDrawingCoordinates() {
    const coordinates = this.controller.project.drawingCoordinates;
    if (coordinates.length === 0) return;

    const olCoordinates = coordinates.map((c) => c.toCoordinate);

    this.reset();

    this.currentDrawInteraction.appendCoordinates(olCoordinates);
  }

  createModelFromFeature(_feature) {
    // override in sub class
  }

  featureSpecificDrawEndProcessing(_event) {}
}
