import { MEASURE_DATA_TYPE } from "../../data-types";

export default class MeasureSelectManager {
  constructor(map, measuresVectorSource, selectInteractionManager) {
    this.map = map;
    this.measuresVectorSource = measuresVectorSource;
    this.selectInteractionManager = selectInteractionManager;
  }

  removeSelectedMeasures() {
    this.currentSelectInteraction.getFeatures().forEach((feature) => {
      if (feature.get("dataType") === MEASURE_DATA_TYPE) {
        this.measuresVectorSource.removeFeature(feature);
      }
    });
  }

  get currentSelectInteraction() {
    return this.selectInteractionManager.currentSelectInteraction;
  }
}
