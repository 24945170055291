import Rails from "@rails/ujs";
export class Form {
  static post(url, data = {}, options = { remote: false }) {
    new Form(url, "POST", data, options).request();
  }

  static patch(url, data = {}, options = { remote: false }) {
    new Form(url, "PATCH", data, options).request();
  }

  static get(url, data = {}, options = { remote: false }) {
    new Form(url, "GET", data, options).request();
  }

  static delete(url, options = { remote: false }) {
    new Form(url, "DELETE", options).request();
  }

  constructor(url, method = "POST", data = {}, options = { remote: false }) {
    this.method = method;
    this.data = data;
    this.remote = options.remote;

    this.form = document.createElement("form");
    this.form.method = method == "GET" ? "GET" : "POST";
    this.form.action = url;
    if (this.remote) this.form.dataset.remote = "true";
  }

  request() {
    this.createInput("authenticity_token", this.csrfToken);
    this.createInput("utf8", "✓");
    if (this.method !== "GET") this.createInput("_method", this.method.toLowerCase());
    if (this.data != {}) {
      this.addInputsForData(this.data);
    }

    if (this.remote) {
      document.body.appendChild(this.form);
      Rails.fire(this.form, "submit");
    } else {
      this.submit();
    }
  }

  get csrfToken() {
    const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
    return csrfMetaTag ? csrfMetaTag.getAttribute("content") : "no-token";
  }

  createInput(name, value) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    this.form.appendChild(input);
  }

  addInputsForData(data, nestedNamePrefix = "") {
    for (let [name, value] of Object.entries(data)) {
      if (typeof value === "object") {
        this.addInputsForData(value, name);
      } else {
        if (nestedNamePrefix !== "") {
          name = `${nestedNamePrefix}[${name}]`;
        }
        this.createInput(name, value);
      }
    }
  }

  submit() {
    document.body.appendChild(this.form);
    this.form.submit();
  }
}

// optsList = [ [name, value, disabled, selected] ]
export const addOptionsToSelect = (optsList, selectMenu, includeBlank = false, blankLabel = "- choose one -") => {
  const optionTmpl = (value, text, disabled, selected) => {
    const d = disabled ? "disabled" : "";
    const s = selected ? 'selected = "selected"' : "";

    return `<option value="${value}" ${d} ${s}>${text}</option>`;
  };

  const options = [];
  if (includeBlank) {
    options.push(optionTmpl("", blankLabel));
  }
  optsList.forEach((option) => {
    options.push(optionTmpl(option[1], option[0], option[2], option[3]));
  });
  selectMenu.innerHTML = options.join("\n");
};
