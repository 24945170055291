import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import Title from "./Title";
import CompressedSpaceMarkers from "./CompressedSpaceMarkers";
import DragAndDrop from "./DragAndDrop";
import GridCells from "./GridCells";
import GridNumbers from "./GridNumbers";
import Toolbar from "./Toolbar";
import DimensionMarkers from "./DimensionMarkers";
import SetbackOutlines from "./SetbackOutlines";
import ExtensionsOutline from "./ExtensionsOutline/ExtensionsOutline";
import SelectionMarquee from "./SelectionMarquee";
import DimensionCrosshairs from "./DimensionCrosshairs";
import RoofOutline from "./RoofOutline";

import GridReal from "./services/grid-real";
import GridDisplay from "./services/grid-display";
import GridModifier from "./services/grid-modifier";
import GridDragSelection from "./services/grid-drag-selection";
import * as serializer from "./helpers/grid-serializer";
import { calculateSelectionMarqueeSizeAndPosition } from "./helpers/marquee-size-and-position-calculator";
import {
  updateGridArraySelectionFromDragAndDrop,
  updateGridCellValue,
  cropGrid,
  clearGrid,
  shiftPanelsOnGrid,
  gridIsCropable,
  calculateCrop,
  updateContourGridCellValue,
  GRID_CELL_INACTIVE,
  validContourModification,
  clearIllegalContourInAdjacentCells,
  invalidContourDesign,
} from "./helpers/grid-helper";
import { contourCodeToDirections, contourDirectionToggle, contourCodeGenerator } from "../../helpers/contour";

import * as store from "../../helpers/local-store";
import {
  GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR,
  GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT,
} from "../../helpers/graphical-tool-helpers";
import { logger } from "../../helpers/app";

function GraphicalLayoutTool({
  cellHeight,
  cellWidth,
  columns: propsColumns,
  extensionTop,
  extensionRight,
  extensionBottom,
  extensionLeft,
  gridString: propsGridString,
  identifier,
  interColumnSpacing,
  interRowSpacing,
  maxEditorHeight,
  offerCropOnSave,
  persistenceCallback,
  projectType,
  rows: propsRows,
  setback,
  spaceAroundCellsTop,
  spaceAroundCellsRight,
  spaceAroundCellsBottom,
  spaceAroundCellsLeft,
  isRailOrientationNs,
  showRails,
  railPlatform,
  thirdRailUsed,
  clampHardwareDimensions,
  maxHardwareHeight,
  maxHardwareWidth,
  expandable,
  editorMode: propsEditorMode,
  graphicalToolControllerElement,
  contourGridString: propsContourGridString,
  hiddenEndClamp,
  editableGrid,
  arrayEditingAllowed,
  contourEditingAllowed,
  critterGuardDisplayAllowed,
  useCritterGuard,
  isOpenSolar,
}) {
  const containerRef = useRef(null);
  const outlineRef = useRef(null);

  const [askToSaveWithCropOrNot, setAskToSaveWithCropOrNot] = useState(false);
  const [cellHovers, setCellHovers] = useState([[]]);
  const [compressedSpace, setCompressedSpace] = useState({ top: 0, right: 0, bottom: 0, left: 0 });
  const [compressedSpaceThresholds, setCompressedSpaceThresholds] = useState({
    topY: 0,
    rightX: 0,
    bottomY: 0,
    leftX: 0,
  });
  const [columns, setColumns] = useState(propsColumns);
  const [compressedSpaceMarkers, setCompressedSpaceMarkers] = useState([]);
  const [containerWidth, setContainerWidth] = useState(0);
  const [dimensionMarkers, setDimensionMarkers] = useState([]);
  const [displayCellHeight, setDisplayCellHeight] = useState(0);
  const [displayCellWidth, setDisplayCellWidth] = useState(0);
  const [displayCellPositions, setDisplayCellPositions] = useState([[]]);
  const [displayHeight, setDisplayHeight] = useState(0);
  const [extensionsOutline, setExtensionsOutline] = useState(undefined);
  const [gridArray, setGridArray] = useState([[]]);
  const [gridNumbers, setGridNumbers] = useState([]);
  const [marqueeSelectionColumns, setMarqueeSelectionColumns] = useState(0);
  const [marqueeSelectionRows, setMarqueeSelectionRows] = useState(0);
  const [needsSaving, setNeedsSaving] = useState(false);
  const [persistedGridString, setPersistedGridString] = useState(propsGridString);
  const [persistedContourGridString, setPersistedContourGridString] = useState(propsContourGridString);
  const [positionSnaps, setPositionSnaps] = useState({ xAxis: [], yAxis: [] });
  const [realHeight, setRealHeight] = useState(0);
  const [realWidth, setRealWidth] = useState(0);
  const [roofOutline, setRoofOutline] = useState(null);
  const [rows, setRows] = useState(propsRows);
  const [saving, setSaving] = useState(false);
  const [selectedToolbarItem, setSelectedToolbarItem] = useState("add");
  const [selectionMarquee, setSelectionMarquee] = useState({});
  const [showSelectionMarquee, setShowSelectionMarquee] = useState(false);
  const [showDimensionCrosshairs, setShowDimensionCrosshairs] = useState(false);
  const [setbackOutlines, setSetbackOutlines] = useState(undefined);
  const [snapDimensionCrosshairs, setSnapDimensionCrosshairs] = useState(true);

  const expandKey = `${projectType}-graphical-tool-${identifier}-expand`;
  const expandPreference = store.get(expandKey, false);
  const [isExpanded, setIsExpanded] = useState(expandPreference);
  const [editorMode, setEditorMode] = useState(propsEditorMode);
  const firstLegalContourDirection = isRailOrientationNs && !hiddenEndClamp ? "2" : "1"; // 2 = west, 1 = south
  const [contourPaintMode, setContourPaintMode] = useState(firstLegalContourDirection);
  const [contourGridArray, setContourGridArray] = useState([[]]);

  const persist = useCallback(
    (rows, columns, gridArray, contourGridArray) => {
      const dumper = projectType === "GB4" ? "gbDump" : "dump";
      const gridString = serializer[dumper](gridArray);
      const contourGridString = serializer[dumper](contourGridArray);
      setSaving(true);

      const saveCompleted = () => {
        setNeedsSaving(false);
        setSaving(false);
        setPersistedGridString(serializer.dump(gridArray));
        setPersistedContourGridString(serializer.dump(contourGridArray));
      };

      persistenceCallback({
        rows,
        columns,
        gridString,
        contourGridString,
        completed: saveCompleted,
      });
    },
    [persistenceCallback],
  );

  const recalculateGrid = useCallback(
    (newGridArray, newContourGridArray, options = {}, fromMessage) => {
      const containerWidth = containerRef.current.offsetWidth;
      const rows = newGridArray.length;
      const columns = newGridArray[0].length;

      const realGrid = new GridReal({
        cellWidth,
        cellHeight,
        columns,
        extensionTop,
        extensionRight,
        extensionBottom,
        extensionLeft,
        rows,
        rowSpacing: interRowSpacing,
        columnSpacing: interColumnSpacing,
        setback,
        spaceAroundCellsTop,
        spaceAroundCellsRight,
        spaceAroundCellsBottom,
        spaceAroundCellsLeft,
      });

      const displayGrid = new GridDisplay({
        realGrid,
        maxWidth: containerWidth,
        maxHeight: isExpanded ? maxEditorHeight * 2 : maxEditorHeight,
      });

      setCompressedSpace({
        top: realGrid.spaceAroundCellsTopCompressedSpace,
        right: realGrid.spaceAroundCellsRightCompressedSpace,
        bottom: realGrid.spaceAroundCellsBottomCompressedSpace,
        left: realGrid.spaceAroundCellsLeftCompressedSpace,
      });
      setCompressedSpaceThresholds(displayGrid.compressedSpaceThresholds);
      setColumns(columns);
      setCompressedSpaceMarkers(displayGrid.compressedSpaceMarkers);
      setContainerWidth(containerWidth);
      setDimensionMarkers(displayGrid.dimensionMarkers);
      setDisplayCellHeight(displayGrid.cellHeight);
      setDisplayCellPositions(displayGrid.cellPositions);
      setDisplayCellWidth(displayGrid.cellWidth);
      setDisplayHeight(displayGrid.height);
      setExtensionsOutline(displayGrid.extensionsOutline);
      setContourGridArray(newContourGridArray);
      setGridArray(newGridArray);
      checkForInvalidContourGridDesign(`${fromMessage} -> recalculateGrid`, newGridArray, newContourGridArray);
      setGridNumbers(displayGrid.numbers);
      setPositionSnaps(displayGrid.positionSnaps);
      setRealHeight(realGrid.height);
      setRealWidth(realGrid.width);
      setRoofOutline(displayGrid.roofOutline);
      setRows(rows);
      setSetbackOutlines(displayGrid.setbackOutlines);

      if (options.withPersistence) persist(rows, columns, newGridArray, newContourGridArray);
    },
    [
      cellHeight,
      cellWidth,
      extensionBottom,
      extensionLeft,
      extensionRight,
      extensionTop,
      interColumnSpacing,
      interRowSpacing,
      maxEditorHeight,
      persist,
      setback,
      spaceAroundCellsBottom,
      spaceAroundCellsLeft,
      spaceAroundCellsRight,
      spaceAroundCellsTop,
      isExpanded,
    ],
  );

  const checkForInvalidContourGridDesign = useCallback(
    (calledFrom, newGridArray, newContourGridArray) => {
      if (projectType === "GB4") return;

      if (invalidContourDesign(newGridArray, newContourGridArray)) {
        logger("invalid contour design when set in", calledFrom);
        logger("invalid contour design when set in", calledFrom);
        logger("oldGridArray", gridArray);
        logger("oldContourGridArray", contourGridArray);
        logger("newGridArray", newGridArray);
        logger("newContourGridArray", newContourGridArray);
        debugger;
      }
    },
    [gridArray, contourGridArray],
  );

  useEffect(() => {
    const loader = projectType === "GB4" ? "gbLoad" : "load";
    const newGridArray = serializer[loader](propsGridString, propsRows, propsColumns);
    const newContourGridArray = serializer[loader](propsContourGridString, propsRows, propsColumns);
    recalculateGrid(newGridArray, newContourGridArray, {}, "useEffect (grid props loading)");
  }, [propsColumns, propsGridString, propsContourGridString, propsRows, recalculateGrid]);

  useEffect(() => {
    if (gridArray[0].length !== 0)
      recalculateGrid(gridArray, contourGridArray, {}, "useEffect (gridArray or contourGrid changed)");
    if (expandable) store.set(expandKey, isExpanded);
  }, [isExpanded, gridArray, contourGridArray, recalculateGrid]);

  useEffect(() => {
    graphicalToolControllerElement.addEventListener(
      "changeModeToLayout",
      () => {
        setEditorMode(GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT);
      },
      false,
    );
  }, []);

  useEffect(() => {
    graphicalToolControllerElement.addEventListener(
      "changeModeToContour",
      () => {
        setEditorMode(GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR);
      },
      false,
    );
  }, []);

  const handleSave = useCallback(() => {
    if (editableGrid && offerCropOnSave && gridIsCropable(gridArray)) {
      setAskToSaveWithCropOrNot(true);
    } else {
      persist(rows, columns, gridArray, contourGridArray);
    }
  }, [offerCropOnSave, gridArray, contourGridArray, persist, rows, columns]);

  const saveWithOption = useCallback(
    (option = null) => {
      setAskToSaveWithCropOrNot(false);
      if (option === "crop") {
        const cropRowsAndColumns = calculateCrop(gridArray);
        const croppedGridArray = cropGrid(gridArray, cropRowsAndColumns);
        const croppedContourGridArray = cropGrid(contourGridArray, cropRowsAndColumns);

        recalculateGrid(croppedGridArray, croppedContourGridArray, { withPersistence: true }, "saveWithOption (crop)");
      } else {
        persist(rows, columns, gridArray, contourGridArray);
      }
    },
    [recalculateGrid, gridArray, contourGridArray, persist, rows, columns],
  );

  const gridNeedsSaving = useCallback(
    (gridArray, contourGridArray) => {
      const serializedArray = serializer.dump(gridArray);
      if (!serializedArray.includes("1")) return false;

      // TODO: check if the array is valid

      const serializedContourArray = serializer.dump(contourGridArray);
      return serializedArray !== persistedGridString || serializedContourArray !== persistedContourGridString;
    },
    [persistedGridString, persistedContourGridString],
  );

  const handleGridCellClicked = useCallback(
    (row, column) => {
      const cga = contourGridArray.map((row) => [...row]);

      if (editorMode === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR) {
        if (gridArray[row][column] === GRID_CELL_INACTIVE) return;

        const contourPaintDirections = contourCodeToDirections(contourPaintMode);
        let contourUpdated = false;
        contourPaintDirections.forEach((paintDirection) => {
          if (validContourModification(gridArray, cga, row, column, selectedToolbarItem, paintDirection)) {
            cga[row][column] = updateContourGridCellValue(cga[row][column], selectedToolbarItem, paintDirection);
            setNeedsSaving(gridNeedsSaving(gridArray, cga));
            contourUpdated = true;
          }
        });
        if (contourUpdated) {
          recalculateGrid(gridArray, cga, {}, "handleGridCellClicked (contour mode)");
        }
      } else {
        const newGridCellValue = updateGridCellValue(gridArray[row][column], selectedToolbarItem);

        if (gridArray[row][column] != newGridCellValue) {
          const ga = gridArray.map((row) => [...row]);
          ga[row][column] = newGridCellValue;
          if (newGridCellValue === GRID_CELL_INACTIVE) {
            cga[row][column] = contourCodeGenerator(false, false, false);
          } else {
            clearIllegalContourInAdjacentCells(cga, row, column);
          }
          setNeedsSaving(gridNeedsSaving(ga, cga));
          recalculateGrid(ga, cga, {}, "handleGridCellClicked (layout mode)");
        }
      }
    },
    [gridArray, contourGridArray, selectedToolbarItem, contourPaintMode, gridNeedsSaving, recalculateGrid, editorMode],
  );

  const realGridParams = () => ({
    cellWidth,
    cellHeight,
    columns,
    extensionTop,
    extensionRight,
    extensionBottom,
    extensionLeft,
    rows,
    rowSpacing: interRowSpacing,
    columnSpacing: interColumnSpacing,
    setback,
    spaceAroundCellsTop,
    spaceAroundCellsRight,
    spaceAroundCellsBottom,
    spaceAroundCellsLeft,
  });

  const handleGridRowsChange = useCallback(
    (newRowCount) => {
      const { arrayHardwareHeight } = new GridReal({ ...realGridParams(), rows: newRowCount });
      const newGridArray = new GridModifier(
        gridArray,
        clampHardwareDimensions,
        maxHardwareHeight,
        maxHardwareWidth,
      ).changeRowNumber(newRowCount, arrayHardwareHeight);

      const newContourGridArray = new GridModifier(
        contourGridArray,
        clampHardwareDimensions,
        maxHardwareHeight,
        maxHardwareWidth,
      ).changeRowNumber(newRowCount, arrayHardwareHeight);

      setNeedsSaving(gridNeedsSaving(newGridArray, newContourGridArray));
      recalculateGrid(newGridArray, newContourGridArray, {}, "handleGridRowsChange");
    },
    [gridArray, contourGridArray, gridNeedsSaving, recalculateGrid],
  );

  const handleGridColumnsChange = useCallback(
    (newColumnCount) => {
      const { arrayHardwareWidth } = new GridReal({
        ...realGridParams(),
        columns: newColumnCount,
      });

      const newGridArray = new GridModifier(
        gridArray,
        clampHardwareDimensions,
        maxHardwareHeight,
        maxHardwareWidth,
      ).changeColumnNumber(newColumnCount, arrayHardwareWidth);

      const newContourGridArray = new GridModifier(
        contourGridArray,
        clampHardwareDimensions,
        maxHardwareHeight,
        maxHardwareWidth,
      ).changeColumnNumber(newColumnCount, arrayHardwareWidth);

      setNeedsSaving(gridNeedsSaving(newGridArray, newContourGridArray));
      recalculateGrid(newGridArray, newContourGridArray, {}, "handleGridColumnsChange");
    },
    [gridArray, contourGridArray, gridNeedsSaving, recalculateGrid],
  );

  const handleKeyDown = (event) => {
    const arrowShift = (direction) => {
      event.preventDefault();
      handleShiftPanelsOnGrid(direction);
    };

    switch (event.key) {
      case "ArrowRight":
        if (editorMode === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR) {
          event.preventDefault();
          setContourPaintMode(contourDirectionToggle(contourPaintMode, "east"));
        } else {
          arrowShift("right");
        }
        return;
      case "ArrowLeft":
        if (editorMode === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR) {
          event.preventDefault();
          setContourPaintMode(contourDirectionToggle(contourPaintMode, "west"));
        } else {
          arrowShift("left");
        }
        return;
      case "ArrowUp":
        if (editorMode !== GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR) {
          arrowShift("up");
        }
        return;
      case "ArrowDown":
        if (editorMode === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR) {
          event.preventDefault();
          setContourPaintMode(contourDirectionToggle(contourPaintMode, "south"));
        } else {
          arrowShift("down");
        }
        return;
      case "a":
        setSelectedToolbarItem("add");
        return;
      case "r":
        setSelectedToolbarItem("remove");
        return;
      case "t":
        setSelectedToolbarItem("toggle");
        return;
      case "d":
        setShowDimensionCrosshairs(!showDimensionCrosshairs);
    }
  };

  const handleContourPaintModeChange = (paintDirection) => {
    setContourPaintMode(contourDirectionToggle(contourPaintMode, paintDirection));
  };

  const handleDragMove = useCallback(
    (dragAndDrop) => {
      if (
        (!arrayEditingAllowed && editorMode === GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT) ||
        (!contourEditingAllowed && editorMode === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR)
      )
        return;

      const selectionMarquee = calculateSelectionMarqueeSizeAndPosition(dragAndDrop, window);
      const { cellHovers, marqueeColumns, marqueeRows } = new GridDragSelection({
        cellHeight: displayCellHeight,
        cellWidth: displayCellWidth,
        cellPositions: displayCellPositions,
        selectionMarquee,
      });

      setSelectionMarquee(selectionMarquee);
      setCellHovers(cellHovers);
      setMarqueeSelectionColumns(marqueeColumns);
      setMarqueeSelectionRows(marqueeRows);
      setShowSelectionMarquee(true);
    },
    [displayCellHeight, displayCellWidth, displayCellPositions],
  );

  const handleDragEnd = useCallback(
    (dragAndDrop) => {
      if (
        (!arrayEditingAllowed && editorMode === GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT) ||
        (!contourEditingAllowed && editorMode === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR)
      )
        return;

      const [newGridArray, newContourGridArray] = updateGridArraySelectionFromDragAndDrop(
        dragAndDrop,
        displayCellPositions,
        gridArray,
        contourGridArray,
        displayCellWidth,
        displayCellHeight,
        selectedToolbarItem,
        editorMode,
        contourPaintMode,
      );

      setSelectionMarquee({ x: 0, y: 0, height: 0, width: 0 });
      setShowSelectionMarquee(false);
      setContourGridArray(newContourGridArray);
      setGridArray(newGridArray);
      checkForInvalidContourGridDesign("handleDragEnd", newGridArray, newContourGridArray);
      setNeedsSaving(gridNeedsSaving(newGridArray, newContourGridArray));
      recalculateGrid(newGridArray, newContourGridArray, {}, "handleDragEnd");
      setCellHovers([[]]);
    },
    [
      displayCellPositions,
      gridArray,
      contourGridArray,
      displayCellWidth,
      displayCellHeight,
      selectedToolbarItem,
      editorMode,
      contourPaintMode,
      gridNeedsSaving,
    ],
  );

  const afterModifyingGrid = useCallback(
    (newGridArray, newContourGridArray, calledFrom) => {
      setNeedsSaving(gridNeedsSaving(newGridArray, newContourGridArray));
      setContourGridArray(newContourGridArray);
      setGridArray(newGridArray);
      checkForInvalidContourGridDesign(`${calledFrom} -> afterModifyingGrid`, newGridArray, newContourGridArray);
      recalculateGrid(newGridArray, newContourGridArray, {}, "afterModifyingGrid");
    },
    [gridNeedsSaving, recalculateGrid],
  );

  const handleShiftPanelsOnGrid = useCallback(
    (direction) => {
      afterModifyingGrid(
        shiftPanelsOnGrid(gridArray, direction),
        shiftPanelsOnGrid(contourGridArray, direction),
        "handleShiftPanelsOnGrid",
      );
    },
    [afterModifyingGrid, gridArray, contourGridArray],
  );

  const closeSaveOptionsPopup = useCallback((event) => {
    event.preventDefault();
    setAskToSaveWithCropOrNot(false);
  }, []);

  const handleCropGrid = useCallback(() => {
    const cropRowsAndColumns = calculateCrop(gridArray);
    const croppedGridArray = cropGrid(gridArray, cropRowsAndColumns);
    const croppedContourGridArray = cropGrid(contourGridArray, cropRowsAndColumns);
    afterModifyingGrid(croppedGridArray, croppedContourGridArray, "handleCropGrid");
  }, [afterModifyingGrid, gridArray, contourGridArray]);

  const handleClearGrid = useCallback(() => {
    if (editorMode === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR) {
      afterModifyingGrid(gridArray, clearGrid(contourGridArray), "handleClearGrid (contour mode)");
    } else {
      afterModifyingGrid(clearGrid(gridArray), clearGrid(contourGridArray), "handleClearGrid (layout mode)");
    }
  }, [afterModifyingGrid, gridArray, editorMode, contourGridArray]);

  const handleToggleExpand = () => setIsExpanded(!isExpanded);

  const classNames = ["graphical-tool"];
  if (extensionsOutline) classNames.push("graphical-tool--with-extensions");

  const containerStyles = { height: `${displayHeight}px` };
  if (containerWidth > 0) containerStyles.width = `${containerWidth}px`;

  const getArrayHardwareHeight = useCallback(
    () => new GridReal({ ...realGridParams(), rows }).arrayHardwareHeight,
    [rows],
  );
  const showExpansionToggle = expandable && getArrayHardwareHeight() > 1350;

  return (
    <>
      <Title projectType={projectType} />
      <div className={classNames.join(" ")} onKeyDown={handleKeyDown} tabIndex="0" /* allows onKeyDown */>
        <Toolbar
          askToSaveWithCropOrNot={askToSaveWithCropOrNot}
          columns={String(columns)}
          closeSaveOptionsPopup={closeSaveOptionsPopup}
          needsSaving={needsSaving}
          onChangeActiveToolbarItem={setSelectedToolbarItem}
          onClearGrid={handleClearGrid}
          onColumnsChange={handleGridColumnsChange}
          onColumnsFieldValueChange={setColumns}
          onCropGrid={handleCropGrid}
          onRowsChange={handleGridRowsChange}
          onRowsFieldValueChange={setRows}
          onSave={handleSave}
          onShiftPanelsOnGrid={handleShiftPanelsOnGrid}
          onToggleDimensionCrosshairs={setShowDimensionCrosshairs}
          onToggleSnapDimensionCrosshairs={setSnapDimensionCrosshairs}
          editableGrid={editableGrid}
          rows={String(rows)}
          saveWithOption={saveWithOption}
          saving={saving}
          selectedToolbarItem={selectedToolbarItem}
          showDimensionCrosshairs={showDimensionCrosshairs}
          snapDimensionCrosshairs={snapDimensionCrosshairs}
          expandable={showExpansionToggle}
          onToggleExpand={handleToggleExpand}
          isExpanded={isExpanded}
          gridArray={gridArray}
          editorMode={editorMode}
          contourPaintMode={contourPaintMode}
          handleContourPaintModeChange={handleContourPaintModeChange}
          isRailOrientationNs={isRailOrientationNs}
          railPlatform={railPlatform}
          hiddenEndClamp={hiddenEndClamp}
          arrayEditingAllowed={arrayEditingAllowed}
          contourEditingAllowed={contourEditingAllowed}
          critterGuardDisplayAllowed={critterGuardDisplayAllowed}
          projectType={projectType}
          isOpenSolar={isOpenSolar}
        />
        <DragAndDrop onDragMove={handleDragMove} onDragEnd={handleDragEnd}>
          {showSelectionMarquee && (
            <SelectionMarquee
              selectionMarquee={selectionMarquee}
              selectedToolbarItem={selectedToolbarItem}
              columns={marqueeSelectionColumns}
              rows={marqueeSelectionRows}
            />
          )}
          <div className="graphical-tool__grid-container" ref={containerRef} style={containerStyles}>
            {setbackOutlines && setback !== 0 && <SetbackOutlines {...setbackOutlines} />}
            {extensionsOutline && <ExtensionsOutline {...extensionsOutline} />}
            <CompressedSpaceMarkers markers={compressedSpaceMarkers} rows={Number(rows)} columns={Number(columns)} />
            <GridNumbers numbers={gridNumbers} />
            <GridCells
              cellPositions={displayCellPositions}
              cellHeight={displayCellHeight}
              cellWidth={displayCellWidth}
              cellHovers={cellHovers}
              handleClick={handleGridCellClicked}
              gridArray={gridArray}
              contourGridArray={contourGridArray}
              showSelectionMarquee={showSelectionMarquee}
              isRailOrientationNs={isRailOrientationNs}
              showRails={showRails}
              thirdRailUsed={thirdRailUsed}
              arrayEditingAllowed={arrayEditingAllowed}
              contourEditingAllowed={contourEditingAllowed}
              useCritterGuard={useCritterGuard}
              editorMode={editorMode}
              hiddenEndClamp={hiddenEndClamp}
              projectType={projectType}
            />
            {roofOutline && <RoofOutline {...roofOutline} outlineRef={outlineRef} />}
            {roofOutline && showDimensionCrosshairs && (
              <DimensionCrosshairs
                {...roofOutline}
                realHeight={realHeight}
                realWidth={realWidth}
                outlineRef={outlineRef}
                containerRef={containerRef}
                positionSnaps={positionSnaps}
                snap={snapDimensionCrosshairs}
                compressedSpace={compressedSpace}
                compressedSpaceThresholds={compressedSpaceThresholds}
              />
            )}
          </div>
          <DimensionMarkers markers={dimensionMarkers} />
        </DragAndDrop>
      </div>
    </>
  );
}

GraphicalLayoutTool.propTypes = {
  cellHeight: PropTypes.number.isRequired,
  cellWidth: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
  editableGrid: PropTypes.bool.isRequired,
  extensionTop: PropTypes.number,
  extensionRight: PropTypes.number,
  extensionBottom: PropTypes.number,
  extensionLeft: PropTypes.number,
  gridString: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  interColumnSpacing: PropTypes.number.isRequired,
  interRowSpacing: PropTypes.number.isRequired,
  maxEditorHeight: PropTypes.number.isRequired,
  offerCropOnSave: PropTypes.bool.isRequired,
  persistenceCallback: PropTypes.func.isRequired,
  projectType: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  setback: PropTypes.number,
  spaceAroundCellsTop: PropTypes.number,
  spaceAroundCellsRight: PropTypes.number,
  spaceAroundCellsBottom: PropTypes.number,
  spaceAroundCellsLeft: PropTypes.number,
  isRailOrientationNs: PropTypes.bool.isRequired,
  showRails: PropTypes.bool,
  railPlatform: PropTypes.string,
  thirdRailUsed: PropTypes.bool,
  clampHardwareDimensions: PropTypes.bool,
  maxHardwareHeight: PropTypes.number,
  maxHardwareWidth: PropTypes.number,
  expandable: PropTypes.bool,
  arrayEditingAllowed: PropTypes.bool,
  contourEditingAllowed: PropTypes.bool,
};

GraphicalLayoutTool.defaultProps = {
  extensionTop: 0,
  extensionRight: 0,
  extensionBottom: 0,
  extensionLeft: 0,
  setback: 0,
  spaceAroundCellsTop: 0,
  spaceAroundCellsRight: 0,
  spaceAroundCellsBottom: 0,
  spaceAroundCellsLeft: 0,
  isRailOrientationNs: false,
  showRails: false,
  thirdRailUsed: false,
  clampHardwareDimensions: false,
  expandable: false,
  arrayEditingAllowed: false,
  contourEditingAllowed: false,
};

export default GraphicalLayoutTool;
