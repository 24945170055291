import DragBox, { DragBoxEvent } from "ol/interaction/DragBox";

export const DRAG_BOX_CLASSNAME = "ol-map__drag-box";

// This adds behavior that makes it possible to press the spacebar key and drag the
// selection marquee around the map to get it positioned more precisely.
export default class extends DragBox {
  constructor(opt_options) {
    super(opt_options);

    this.movingBox = false;
    this.startMovingPixel = undefined;
    this.currentMovingPixel = undefined;
  }

  handleDownEvent(mapBrowserEvent) {
    document.addEventListener("keydown", this.handleKeydown);
    document.addEventListener("keyup", this.handleKeyup);

    return super.handleDownEvent(mapBrowserEvent);
  }

  handleKeydown = (mapBrowserEvent) => {
    if (mapBrowserEvent.key === " ") {
      this.startMovingBox(mapBrowserEvent);
    }
  };

  startMovingBox(_mapBrowserEvent) {
    this.movingBox = true;
  }

  handleKeyup = (mapBrowserEvent) => {
    if (mapBrowserEvent.key === " ") {
      this.stopMovingBox(mapBrowserEvent);
    }
  };

  stopMovingBox(mapBrowserEvent) {
    setTimeout(() => {
      this.movingBox = false;
      this.startMovingPixel = undefined;
    }, 50);
  }

  handleDragEvent(mapBrowserEvent) {
    if (this.movingBox) {
      // Space bar is down, so we want to reposition the box rather than change its size
      if (!this.currentMovingPixel) this.currentMovingPixel = mapBrowserEvent.pixel;

      const deltaX = this.currentMovingPixel[0] - mapBrowserEvent.pixel[0];
      const deltaY = this.currentMovingPixel[1] - mapBrowserEvent.pixel[1];

      // In order to reposition the drag box, we need to update internal state in multiple
      // places. One is this.startPixel_ of the DragBox and the other is the box_.
      // (this.box_.startPixel_ and this.box_.endPixel_). OL doesn't expose any methods
      // to do this, so we are just jumping in and modifying the private state.
      const startPixel = [this.box_.startPixel_[0] - deltaX, this.box_.startPixel_[1] - deltaY];
      this.startPixel_ = startPixel;
      const endPixel = [this.box_.endPixel_[0] - deltaX, this.box_.endPixel_[1] - deltaY];
      this.box_.setPixels(startPixel, endPixel);

      this.currentMovingPixel = mapBrowserEvent.pixel;

      mapBrowserEvent.startPixel = startPixel;
      mapBrowserEvent.endPixel = endPixel;

      // This is a custom event we've created to so we can have an event handler that
      // repositions the array
      this.dispatchEvent(new DragBoxEvent("boxmove", mapBrowserEvent.coordinate, mapBrowserEvent));
    } else {
      // Use the normal behavior and have dragging change the box size
      super.handleDragEvent(mapBrowserEvent);
    }
  }

  handleUpEvent(mapBrowserEvent) {
    document.removeEventListener("keydown", this.handleKeydown);
    document.removeEventListener("keyup", this.handleKeyup);

    return super.handleUpEvent(mapBrowserEvent);
  }
}
