import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import { fromLonLat, toLonLat } from "ol/proj";

import LatLngModel from "../../../da/models/lat-lng-model";

import { degreesToRadians } from "../../../helpers/geometry";
import { linkFeatureToModel } from "../../../da/map/modification-helpers/base";
import { findTopLeftLonLat, cartesianPointsRelativeToOrigin } from "../../../da/map/ol-geometry";

import { BOUNDING_BOX_DATA_TYPE } from "../../../da/map/data-types";

export default class BoundingBoxBuilder {
  constructor(project, roofSection, roofSectionFeature) {
    this.project = project;
    this.roofSection = roofSection;
    this.roofPlane = roofSection.roofPlane;
    this.roofSectionFeature = roofSectionFeature;
  }

  build() {
    console.log(`Updating BB for ${this.roofSection.displayIdentifier}`);
    this.calculateBoundingBox();
    this.roofSection.setBoundingBox(this.boundingBox);
    this.roofSection.setBoundingBoxCartesianPoints(this.boundingBoxCartesianPoints);
  }

  calculateBoundingBox() {
    const combinedRotationInRadians = this.calculateBoundingBoxRotation();
    const center = fromLonLat([this.project.projectSite.editorLng, this.project.projectSite.editorLat]);
    const rotatedRoofSection = this.buildRotatedRoofSection(combinedRotationInRadians, center);
    const rotatedRoofSectionLatLngs = this.convertToLatLngs(rotatedRoofSection);
    const [minLat, minLng, maxLat, maxLng] = this.findMinAndMax(rotatedRoofSectionLatLngs);
    const rotatedBoundingBoxInLatLngs = this.buildRectangle(minLat, minLng, maxLat, maxLng);
    const rotatedBoundingBoxCoordinates = rotatedBoundingBoxInLatLngs.map((latLng) =>
      fromLonLat([latLng.lng, latLng.lat]),
    );
    const rotatedBoundingBoxPolygon = new Polygon([rotatedBoundingBoxCoordinates]);
    const antiRotatedBoundingBoxPolygon = this.buildRotatedGeometry(
      rotatedBoundingBoxPolygon,
      -combinedRotationInRadians,
      center,
    );
    this.boundingBoxFeature = buildBoundingBoxFeatureFromGeometry(this.roofSection, antiRotatedBoundingBoxPolygon);
    this.boundingBox = this.convertToLatLngs(antiRotatedBoundingBoxPolygon);
    this.syncBoundingBoxCartesianPoints();
  }

  syncBoundingBoxCartesianPoints() {
    const topLeftLonLat = findTopLeftLonLat(this.roofPlane.latLngPoints);
    this.boundingBoxCartesianPoints = cartesianPointsRelativeToOrigin(topLeftLonLat, this.boundingBox);
  }

  buildRotatedRoofSection(combinedRotationInRadians, center) {
    const geometry = this.roofSectionFeature.getGeometry();
    return this.buildRotatedGeometry(geometry, combinedRotationInRadians, center);
  }

  buildRotatedGeometry(original, rotation, center) {
    const geometry = original.clone();
    geometry.rotate(rotation, center);

    return geometry;
  }

  convertToLatLngs(rotatedRoofSection) {
    const outerRing = rotatedRoofSection.getLinearRing(0).getCoordinates();
    return outerRing.map((c) => {
      const lonLat = toLonLat(c);
      return LatLngModel.create({ lat: lonLat[1], lng: lonLat[0] });
    });
  }

  calculateBoundingBoxRotation() {
    const azimuth = this.roofSection.azimuth;
    const azimuthRotationInDegrees = azimuth - 180;
    const azimuthRotationInRadians = degreesToRadians(azimuthRotationInDegrees);

    return azimuthRotationInRadians;
  }

  findMinAndMax(latLngs) {
    let minLat;
    let maxLat;
    let minLng;
    let maxLng;

    latLngs.forEach((latLng) => {
      if (minLat === undefined || minLat > latLng.lat) minLat = latLng.lat;
      if (maxLat === undefined || maxLat < latLng.lat) maxLat = latLng.lat;

      if (minLng === undefined || minLng > latLng.lng) minLng = latLng.lng;
      if (maxLng === undefined || maxLng < latLng.lng) maxLng = latLng.lng;
    });

    return [minLat, minLng, maxLat, maxLng];
  }

  buildRectangle(minLat, minLng, maxLat, maxLng) {
    return [
      LatLngModel.create({ lat: maxLat, lng: minLng }),
      LatLngModel.create({ lat: maxLat, lng: maxLng }),
      LatLngModel.create({ lat: minLat, lng: maxLng }),
      LatLngModel.create({ lat: minLat, lng: minLng }),
      LatLngModel.create({ lat: maxLat, lng: minLng }),
    ];
  }
}

export function buildBoundingBoxFeatureFromGeometry(roofSection, geometry) {
  const feature = new Feature({ geometry });
  linkFeatureToModel(feature, roofSection);
  feature.set("roofSectionUuid", roofSection.uuid);
  feature.set("dataType", BOUNDING_BOX_DATA_TYPE);

  return feature;
}
