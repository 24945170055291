import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = ["allowedMessage", "notAllowedMessage", "button"];
  static values = {
    showAllowedMessage: Boolean,
  };

  connect() {
    this.element.controller = this;
    this.displayMessage(this.showAllowedMessageValue);
  }

  displayMessage(showAllowed) {
    const shown = showAllowed ? this.allowedMessageTarget : this.notAllowedMessageTarget;
    const hidden = showAllowed ? this.notAllowedMessageTarget : this.allowedMessageTarget;

    animate.show(shown);
    animate.hide(hidden, { fadeOut: false });

    this.buttonTargets.forEach((button) => {
      button.classList.remove("ir-btn--spinner-shown");
      button.disabled = false;
    });
  }
}
