import { v4 as uuid } from "uuid";

import { cartesianPointRelativeTo } from "../../../../da/map/ol-geometry";
import SegmentModel from "../../../models/segment-model";
import SegmentsRailGroup from "../../map-model-synchronizers/segments-rail-group";
import SegmentRenderer from "../../modification-helpers/segments/renderer";

export function reRenderSegment(controller, segment) {
  const roofPlane = controller.focusedRoofPlane;
  const { panelsVectorSource, segmentsVectorSource, railsVectorSource, thermalExpansionsVectorSource } =
    controller.mapManager;

  const sr = new SegmentRenderer({
    roofPlane,
    segment,
    controller,
    panelsVectorSource,
    segmentsVectorSource,
    railsVectorSource,
    thermalExpansionsVectorSource,
  });

  sr.reRender();

  return sr.segmentFeature;
}

export function createNewSegment(controller, roofSection, attributes = {}) {
  const defaultAttributes = {
    uuid: uuid(),
    rows: 1,
    columns: 1,
    rowDirection: 1,
    columnDirection: -1,
    illegalShape: false,
  };

  if (attributes.zonesAndModulePositionsGrid) {
    attributes.zonesAndModulePositions = SegmentsRailGroup.stringifyZonesAndModulePositionsFromGrid(
      attributes.zonesAndModulePositionsGrid,
    );
    delete attributes.zonesAndModulePositionsGrid;
  }

  if (attributes.contourCodesGrid) {
    attributes.contourCodes = SegmentsRailGroup.stringifyContourCodesFromGrid(attributes.contourCodesGrid);
    delete attributes.contourCodesGrid;
  }

  const attrs = { ...defaultAttributes, ...attributes };

  const originLonLat = controller.project.detail.originLatLng.toLonLat;
  const startLonLat = [attrs.startLatLng.lng, attrs.startLatLng.lat];
  attrs.startCartesianPoint = cartesianPointRelativeTo(startLonLat, originLonLat);

  const newSegment = SegmentModel.create(attrs);
  roofSection.addSegment(newSegment);
  return newSegment;
}

export function renderNewSegment(controller, newSegment) {
  const sr = createSegmentRenderer(controller, newSegment);
  sr.render();
  return sr.segmentFeature;
}

function createSegmentRenderer(controller, segment) {
  const { focusedRoofPlane: roofPlane, mapManager } = controller;
  const { panelsVectorSource, segmentsVectorSource, railsVectorSource, thermalExpansionsVectorSource } = mapManager;

  const sr = new SegmentRenderer({
    roofPlane,
    segment,
    controller,
    panelsVectorSource,
    segmentsVectorSource,
    railsVectorSource,
    thermalExpansionsVectorSource,
  });

  return sr;
}

export function clearSegmentFeatures(controller, segment) {
  const sr = createSegmentRenderer(controller, segment);
  sr.clearSegmentFeatures();
}

export function getRailedGroupIncludingSegmentWithUuid({ controller, uuid }) {
  let segmentForUuid;
  controller.pageRoofPlanes.forEach((roofPlane) => {
    roofPlane.roofSections.forEach((roofSection) => {
      if (segmentForUuid !== undefined) return;

      segmentForUuid = roofSection.segments.find((s) => s.uuid === uuid);
    });
  });
  if (segmentForUuid === undefined) throw `[Rail Group Error] Couldn't find segment with UUID: ${uuid}`;
  const segments = segmentForUuid.railedGroup;
  return segments;
}

export function getSegmentFeaturesForSegments({ controller, segments }) {
  const allSegmentFeatures = controller.mapManager.segmentsVectorSource.getFeatures();
  const segmentFeatures = segments.map((s) => {
    const segmentFeature = allSegmentFeatures.find((sf) => s.uuid === sf.get("uuid"));
    if (segmentFeature === undefined)
      throw `[Rail Group Error] Couldn't find segment feature for segment UUID: ${s.uuid}`;
    return segmentFeature;
  });
  return segmentFeatures;
}

export function removeSegmentFromSelectInteraction(controller, segmentUuid) {
  const selectInteractionManager = controller.selectInteractionManager;
  const { selectionCollection } = selectInteractionManager;

  const selectRemovals = [];
  selectionCollection.forEach((sf) => {
    if (sf.get("uuid") === segmentUuid) selectRemovals.push(sf);
  });
  selectRemovals.forEach((sf) => selectionCollection.remove(sf));
}
