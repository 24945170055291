import { toLonLat } from "ol/proj";
import { getDistance } from "ol/sphere";

import SimpleLatLng from "./models/simple-lat-lng";
import CartesianPoint from "../models/cartesian-model";

import { relativePosition } from "./ol-helpers";

export const METERS_TO_INCHES = 39.3700787;

export function calculateUnitPerpendicularEdgeVectorLatLng(startPointCoordinates, endPointCoordinates) {
  const startPointLonLat = toLonLat(startPointCoordinates);
  const endPointLonLat = toLonLat(endPointCoordinates);

  const startPointLatLng = SimpleLatLng.fromLonLat(startPointLonLat);
  const endPointLatLng = SimpleLatLng.fromLonLat(endPointLonLat);

  const edgeVectorLatLng = endPointLatLng.minus(startPointLatLng);
  const edgeVectorLength = getDistance(startPointLonLat, endPointLonLat) * METERS_TO_INCHES;
  const unitEdgeVectorLatLng = edgeVectorLatLng.dividedBy(edgeVectorLength);
  const midPointLatLng = startPointLatLng.plus(edgeVectorLatLng.times(0.5));

  const rightAngleCornerLatLng = new SimpleLatLng(startPointLatLng.lat, endPointLatLng.lng);

  const xEdgeDistance = getDistance(startPointLatLng.toLonLat, rightAngleCornerLatLng.toLonLat); // meters
  let xEdgeVectorLatLng;
  let xEdgeUnitVectorLatLng;
  if (xEdgeDistance === 0) {
    // the points are vertical, i.e. at the same longitude
    xEdgeVectorLatLng = new SimpleLatLng(0, 1);
    const oneDegreePosition = startPointLatLng.plus(xEdgeVectorLatLng);
    const oneDegreeDistance = getDistance(startPointLatLng.toLonLat, oneDegreePosition.toLonLat);
    xEdgeUnitVectorLatLng = xEdgeVectorLatLng.dividedBy(oneDegreeDistance);
  } else {
    xEdgeVectorLatLng = rightAngleCornerLatLng.minus(startPointLatLng);
    xEdgeUnitVectorLatLng = xEdgeVectorLatLng.dividedBy(xEdgeDistance);
  }

  const yEdgeDistance = getDistance(endPointLatLng.toLonLat, rightAngleCornerLatLng.toLonLat);
  let yEdgeVectorLatLng;
  let yEdgeUnitVectorLatLng;
  if (yEdgeDistance === 0) {
    // the points are horizontal, i.e. at the same latitude
    yEdgeVectorLatLng = new SimpleLatLng(1, 0);
    const oneDegreePosition = startPointLatLng.plus(yEdgeVectorLatLng);
    const oneDegreeDistance = getDistance(startPointLatLng.toLonLat, oneDegreePosition.toLonLat);
    yEdgeUnitVectorLatLng = yEdgeVectorLatLng.dividedBy(oneDegreeDistance);
  } else {
    yEdgeVectorLatLng = endPointLatLng.minus(rightAngleCornerLatLng);
    yEdgeUnitVectorLatLng = yEdgeVectorLatLng.dividedBy(yEdgeDistance);
  }

  const scaledXEdgeVectorLatLng = xEdgeUnitVectorLatLng.times(yEdgeDistance);
  const scaledYEdgeVectorLatLng = yEdgeUnitVectorLatLng.times(xEdgeDistance);
  const perpendicularEdgeVectorLatLng = scaledXEdgeVectorLatLng
    .minus(scaledYEdgeVectorLatLng)
    .times(directionalAdjustment(startPointLatLng, endPointLatLng));

  const pointOnPerpendicularLatLon = midPointLatLng.plus(perpendicularEdgeVectorLatLng);
  const perpendicularEdgeLength =
    getDistance(pointOnPerpendicularLatLon.toLonLat, midPointLatLng.toLonLat) * METERS_TO_INCHES;

  const unitPerpendicularEdgeVectorLatLng = perpendicularEdgeVectorLatLng.dividedBy(perpendicularEdgeLength);

  // [coordinate, degrees/inch, degrees/inch]
  return [midPointLatLng, unitPerpendicularEdgeVectorLatLng, unitEdgeVectorLatLng];
}

function directionalAdjustment(startPointLatLng, endPointLatLng) {
  const latDelta = endPointLatLng.lat - startPointLatLng.lat;
  const lngDelta = endPointLatLng.lng - startPointLatLng.lng;

  if (
    (latDelta > 0 && lngDelta > 0) ||
    (latDelta < 0 && lngDelta < 0) ||
    (latDelta === 0 && lngDelta > 0) ||
    (latDelta > 0 && lngDelta === 0)
  ) {
    return -1;
  } else {
    return 1;
  }
}

// Lines are [ [x1, y1], [x2, y2]] with x's and y's in OpenLayers coordinates
// returns undefined if lines are coincident or parallel
// otherwise returns a point in OL coordinates [x, y] where the lines intersect
export function lineIntersectionInCoordinates(firstLine, secondLine) {
  const x1 = firstLine[0][0];
  const y1 = firstLine[0][1];
  const x2 = firstLine[1][0];
  const y2 = firstLine[1][1];

  const x3 = secondLine[0][0];
  const y3 = secondLine[0][1];
  const x4 = secondLine[1][0];
  const y4 = secondLine[1][1];

  const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
  // if denominator is 0 then the lines are are coincident or parallel
  if (Math.abs(denominator) < 0.000001) return undefined;

  const pointX = ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / denominator;
  const pointY = ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / denominator;

  return [pointX, pointY];
}

export function findTopLeftLonLat(latLngPoints) {
  let topLat;
  let leftmostLng;

  latLngPoints.forEach((p) => {
    if (topLat === undefined || p.lat > topLat) topLat = p.lat;
    if (leftmostLng === undefined || p.lng < leftmostLng) leftmostLng = p.lng;
  });

  // returning these in LonLat order because OL wants that format for distance calcs
  return [leftmostLng, topLat];
}

export function setCartesianPointsForModelRelativeToOrigin(origin, model) {
  const newPoints = cartesianPointsRelativeToOrigin(origin, model.latLngPoints);
  model.setCartesianPoints(newPoints);
}

export function cartesianPointsRelativeToOrigin(origin, latLngPoints) {
  return latLngPoints.map((latLng) => {
    return cartesianPointRelativeTo(latLng.toLonLat, origin);
  });
}

export function cartesianPointRelativeTo(pointLonLat, originLonLat) {
  const originLat = originLonLat[1];
  const pointLon = pointLonLat[0];

  const cornerLonLat = [pointLon, originLat];

  const [pointSignLng, pointSignLat] = relativePosition(pointLonLat, originLonLat);
  const pointX = pointSignLng * getDistance(originLonLat, cornerLonLat) * METERS_TO_INCHES;
  // flip sign on Y because we want to match BXR which has the NW corner as (0,0) and has Y increase
  // as you go down
  const pointY = -1 * (pointSignLat * getDistance(cornerLonLat, pointLonLat) * METERS_TO_INCHES);

  return CartesianPoint.create({ x: pointX, y: pointY });
}
