import { Controller } from "@hotwired/stimulus";
import { showDialog } from "../../components/ir_dialog/helper";

export default class extends Controller {
  dialogIdentifier = "edit-project-location-dialog";

  open(event) {
    event.preventDefault();
    this.propertySearchController.resetLocationSearchToPersistedValues();
    const clickPosition = { x: event.pageX, y: event.pageY };
    showDialog(this.dialogIdentifier, () => {}, { showNearClickPosition: true, clickPosition });
  }

  get propertySearchController() {
    return document.querySelector("[data-controller='da--property-search--base']").propertySearchController;
  }
}
