import "core-js/stable";
import "regenerator-runtime/runtime";

import "./polyfills";
import "./config/sentry";
import "./helpers/da-globals";
import { onDevelopment } from "./helpers/app";

try {
  // try/catch is meant to deal with a recurring error: https://github.com/basecamp/trix/issues/247
  require("trix");
} catch (e) {}

import "@rails/actiontext";

// Rails UJS
import RailsUjs from "@rails/ujs";
if (!window._rails_loaded) RailsUjs.start();

// Turbo
import { Turbo } from "@hotwired/turbo-rails";
Turbo.start();
window.Turbo = Turbo;
import "./support/turbo-stream-actions";

// Stimulus
import "./controllers";
import { flashlight } from "./helpers/animate";

// Delayed scroll
document.addEventListener("turbo:load", () => {
  if (!window.location.hash.startsWith("#ds--")) return;

  const anchorName = window.location.hash.replace("#ds--", "");
  const anchor = document.querySelector(`a#${anchorName}`);
  setTimeout(() => anchor.scrollIntoView({ block: "center" }), 250);
});

// Page load flashlight
document.addEventListener("turbo:load", () => {
  const params = new URL(window.location).searchParams;

  const flashlightElemId = params.get("flashlight");
  if (flashlightElemId) pageLoadFlashlight(flashlightElemId, "flashlight");

  const flashlightContentBoxElemId = params.get("flashlight_ir_content_box");
  if (flashlightContentBoxElemId) flashlight(`#${flashlightContentBoxElemId}`, "flashlight-ir-content-box");
});

function pageLoadFlashlight(flashlightElemId, flashlightClass) {
  const flashlightElem = document.querySelector(`#${flashlightElemId}`);

  if (!flashlightElem && onDevelopment()) throw `Flashlight element not found: ${flashlight}`;
  if (!flashlightElem) return;

  flashlightElem.classList.add(flashlightClass);
}
