import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

import { showDialog } from "./components/ir_dialog/helper";
import { logger } from "../helpers/app";

export default class ProjectUpdatedNoticeController extends Controller {
  static values = {
    projectId: Number,
    updatingCsrfToken: String,
  };

  static show(_projectId, updatingCsrfToken, currentCsrfToken) {
    // logger("current csrf token", currentCsrfToken);
    // logger("updating csrf token", updatingCsrfToken);

    if (updatingCsrfToken === currentCsrfToken) return;

    showDialog("project-updated-notice-dialog");
  }

  static debouncedShow = debounce(ProjectUpdatedNoticeController.show, 1000);

  connect() {
    ProjectUpdatedNoticeController.debouncedShow(
      this.projectIdValue,
      this.updatingCsrfTokenValue,
      this.currentCsrfToken,
    );
  }

  get currentCsrfToken() {
    const metaTag = document.head.querySelector("meta[name=csrf-token]");
    if (!metaTag) return "";

    return metaTag.getAttribute("content");
  }
}
