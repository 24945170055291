import { toLonLat } from "ol/proj";

import { ROOF_SECTION_DATA_TYPE, SETBACK_DATA_TYPE } from "../../../../da/map/data-types";
import DaBase from "../../../../da/map/interaction-managers/modify/base";
import RoofSectionLegalityChecker from "../../../../da/map/legality-checkers/roof-section";
import SimpleLatLng from "../../../../da/map/models/simple-lat-lng";
import { findTopLeftLonLat, setCartesianPointsForModelRelativeToOrigin } from "../../../../da/map/ol-geometry";
import { ensureSetbackOuterRingRemainsUnmodified } from "../../../../pr/map/modification-helpers/roof-section";
import {
  adjustArrayAfterNudge,
  modifyRoofSection,
  updateBoundingBoxAndReAddLayoutForRoofSection,
} from "../../modification-helpers/roof-section";

export default class RoofSections extends DaBase {
  modifyEndForFeature(feature) {
    let dataType = feature.get("dataType");

    if (dataType === ROOF_SECTION_DATA_TYPE) {
      modifyRoofSection(feature, this.project, this.mapModelSynchronizer, this.mapManager, this.controller);
    } else if (dataType === SETBACK_DATA_TYPE) {
      ensureSetbackOuterRingRemainsUnmodified(this.mapManager, feature);
      this.#updateDefaultRoofSectionFromModifiedSetback(feature);
    }

    this.controller.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: "RoofSectionsModifyInteractionManager#modifyEndForFeature",
    });
  }

  get polygonModifyInteractionParams() {
    return {
      features: this.selectedPolygonFeatures,
      insertVertexCondition: (_mapBrowserEvent) => {
        const selectedFeatures = this.selectedPolygonFeatures.getArray();
        if (selectedFeatures.some((f) => f.get("dataType") === SETBACK_DATA_TYPE)) {
          return false; // disallow adding vertexes
        } else {
          return true;
        }
      },
    };
  }

  #updateDefaultRoofSectionFromModifiedSetback(setbackFeature) {
    const roofPlane = this.project.getRoofPlane(setbackFeature.get("roofPlaneUuid"));
    const defaultRoofSections = roofPlane.defaultRoofSections;

    setbackFeature
      .getGeometry()
      .getCoordinates()
      .forEach((linearRing, i) => {
        if (i === 0) return; // This is the outer setback ring

        const roofSection = defaultRoofSections[i - 1];
        const latLngPoints = linearRing.map((c) => SimpleLatLng.fromLonLat(toLonLat(c)).toSimpleObject);
        roofSection.setLatLngPoints(latLngPoints);

        const topLeftLonLat = findTopLeftLonLat(roofPlane.latLngPoints);
        setCartesianPointsForModelRelativeToOrigin(topLeftLonLat, roofSection);
      });

    this.mapManager.clearRoofSectionFeatures();
    this.mapManager.renderRoofSectionFeatures();

    roofPlane.notDeletedRoofSections.forEach((roofSection) => {
      let roofSectionFeature = this.mapManager.roofSectionsVectorSource
        .getFeatures()
        .find((rsf) => rsf.get("uuid") === roofSection.uuid);

      if (!roofSectionFeature) return;

      const roofPlaneFeature = this.mapModelSynchronizer.getFeatureForRoofPlane(roofPlane);

      new RoofSectionLegalityChecker(
        roofPlaneFeature,
        this.mapManager.roofSectionsFeatures,
        this.mapModelSynchronizer,
      ).markIllegalPolygons(roofSectionFeature);

      updateBoundingBoxAndReAddLayoutForRoofSection(roofSectionFeature, roofSection, this.project, this.mapManager);
      adjustArrayAfterNudge(roofSection, this.project, this.mapManager);
    });
  }
}
