export const load = (gridString, rows, columns) => {
  const ret = [];
  for (let i = 0; i < rows; i++) {
    const startPos = i * columns;
    ret[i] = gridString.slice(startPos, startPos + columns).split("");
  }
  return ret;
};

export const gbLoad = (gridString, rows, columns) => {
  let gridArray = gridString.split("");
  let result = Array.from({ length: rows }, () => Array(columns).fill(0));

  for (let i = 0; i < gridArray.length; i++) {
    let row = i % rows;
    let column = Math.floor(i / rows);
    result[row][column] = gridArray[i];
  }

  return result;
};

export const dump = (gridArray) => {
  return gridArray.map((row) => row.join("")).join("");
};

export const gbDump = (gridArray) => {
  return dump(transposeGridArray(gridArray));
};

export const transposeGridArray = (gridArray) => {
  return gridArray[0].map((_, colIndex) => gridArray.map((row) => row[colIndex]));
};
