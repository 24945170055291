import React from "react";
import PropTypes from "prop-types";

import InfoPopupLink from "../InfoPopupLink";

function Title({ projectType }) {
  return (
    projectType === "BX" && (
      <div className="graphical-tool__title">
        <h2>Roof Array Editor</h2>
        <div className="graphical-tool__title__what-is-this">
          What is this? <InfoPopupLink dialogIdentifier="bx-graphical-editor-what-is-this" />
        </div>
      </div>
    )
  );
}

Title.propTypes = {
  projectType: PropTypes.string.isRequired,
};

export default React.memo(Title);
