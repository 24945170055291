import { Collection } from "ol";
import { fromLonLat } from "ol/proj";
import { getSnapshot } from "mobx-state-tree";

import SegmentsRowsAndColumnsAdder from "./rows-and-columns-adder";
import SegmentsRailGroup from "../../map-model-synchronizers/segments-rail-group";
import SegmentsAligner from "./aligner";
import { sortBy } from "lodash";

export default class SegmentsMerger {
  constructor({ controller, selectionCollection, direction }) {
    this.controller = controller;
    this.project = controller.project;
    this.selectionCollection = selectionCollection;

    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;

    const [anchorSegmentFeature, moverSegmentFeature] = selectionCollection.getArray();

    this.anchorSegmentFeature = anchorSegmentFeature;
    this.anchorSegment = anchorSegmentFeature.get("model");
    this.anchorRailedParent = this.anchorSegment.railedParent || this.anchorSegment;
    this.anchorRailGroup = this.anchorSegment.railedGroup;
    this.anchorThermalExpansions = this.anchorRailedParent.thermalExpansions;

    this.moverSegmentFeature = moverSegmentFeature;
    this.moverSegment = moverSegmentFeature.get("model");
    this.moverRailedParent = this.moverSegment.railedParent || this.moverSegment;
    this.moverRailGroup = this.moverSegment.railedGroup;
    this.moverThermalExpansions = this.moverRailedParent.thermalExpansions;

    this.direction = direction;
    this.isHorizontal = direction === "horizontal";
  }

  merge() {
    if (this.isHorizontal) {
      return this.#mergeHorizontal();
    } else {
      return this.#mergeVertical();
    }
  }

  get areSegmentsMergeable() {
    if (this.anchorSegment.orientation !== this.moverSegment.orientation) {
      this.notMergeableMessage = "You can not merge segments that have different orientations.";
      return false;
    }

    if (this.isHorizontal) {
      return this.#areSegmentsHorizontalMergeable();
    } else {
      return this.#areSegmentsVerticalMergeable();
    }
  }

  #areSegmentsHorizontalMergeable() {
    if (this.anchorSegment.rows !== this.moverSegment.rows) {
      this.notMergeableMessage = "You can only horizontally merge segments that have the same number of rows.";
      return false;
    }

    if (this.#segmentsAreInSameRailGroup) {
      if (!this.#segmentsAreNextToEachOther) {
        this.notMergeableMessage =
          "If you are merging segments in the same rail group, they need to be next to each other.";
        return false;
      }

      if (this.#segmentsHaveAThermalExpansionBetweenThem) {
        this.notMergeableMessage = "You can't horizontally merge segments that have a thermal expansion between them.";
        return false;
      }
    } else {
      if (!this.anchorSegment.isOnTheOutsideOfRailGroup || !this.moverSegment.isOnTheOutsideOfRailGroup) {
        this.notMergeableMessage = "You can only horizontally merge segments that are on the outside of railed groups.";
        return false;
      }

      if (this.#mergeSegmentsBelongToMultiSegmentRailGroups) {
        if (this.#mergeSegmentsAreOnOpposingSidesOfRailGroups) {
          this.notMergeableMessage = "You can only horizontally merge segments on opposite ends of their rail groups.";
          return false;
        }

        if (this.#mergeSegmentsHaveSegmentsInBetween) {
          this.notMergeableMessage = "You can not merge segments with segments in between.";
          return false;
        }
      }
    }

    return true;
  }

  get #mergeSegmentsAreOnOpposingSidesOfRailGroups() {
    const bothAtStartOfRailedGroup = this.anchorSegment.isAtStartOfRailGroup && this.moverSegment.isAtStartOfRailGroup;
    const bothAtEndOfRailedGroup = this.anchorSegment.isAtEndOfRailGroup && this.moverSegment.isAtEndOfRailGroup;
    return bothAtStartOfRailedGroup || bothAtEndOfRailedGroup;
  }

  get #mergeSegmentsBelongToMultiSegmentRailGroups() {
    return this.anchorRailGroup.length > 1 && this.moverRailGroup.length > 1;
  }

  get #segmentsHaveAThermalExpansionBetweenThem() {
    if (this.moverThermalExpansions.length === 0) return false;

    const moverSegmentPixels = this.#pixelCoordinates(this.moverSegmentFeature);
    const anchorSegmentPixels = this.#pixelCoordinates(this.anchorSegmentFeature);

    const isMoverOnLeft = moverSegmentPixels[0][0] < anchorSegmentPixels[0][0];
    const leftSegmentPixels = isMoverOnLeft ? moverSegmentPixels : anchorSegmentPixels;
    const rightSegmentPixels = isMoverOnLeft ? anchorSegmentPixels : moverSegmentPixels;

    const leftSegmentRightSideX = leftSegmentPixels[1][0];
    const rightSegmentLeftSideX = rightSegmentPixels[0][0];

    for (let i = 0; i < this.moverThermalExpansions.length; i++) {
      const teStartX = this.#pixelFromLatLng(this.moverThermalExpansions[i].startLatLng.toLatLng)[0];
      if (teStartX >= leftSegmentRightSideX && teStartX <= rightSegmentLeftSideX) {
        return true;
      }
    }
    return false;
  }

  get #mergeSegmentsHaveSegmentsInBetween() {
    const moverSegmentPixels = this.#pixelCoordinates(this.moverSegmentFeature);
    const anchorSegmentPixels = this.#pixelCoordinates(this.anchorSegmentFeature);
    const isMoverOnLeft = moverSegmentPixels[0][0] < anchorSegmentPixels[0][0];
    const leftSegment = isMoverOnLeft ? this.moverSegment : this.anchorSegment;
    return leftSegment.railGroupIndex === 0;
  }

  get #segmentsAreInSameRailGroup() {
    return this.anchorRailGroup[0] === this.moverRailGroup[0];
  }

  get #segmentsAreNextToEachOther() {
    return Math.abs(this.anchorSegment.railGroupIndex - this.moverSegment.railGroupIndex) === 1;
  }

  #areSegmentsVerticalMergeable() {
    if (this.#segmentsAreInSameRailGroup) {
      this.notMergeableMessage = "You can not vertically merge segments in the same rail group.";
      return false;
    }

    if (this.anchorSegment.columns !== this.moverSegment.columns) {
      this.notMergeableMessage = "You can only vertically merge segments that have the same number of columns.";
      return false;
    }

    if (this.anchorRailGroup.length !== this.moverRailGroup.length) {
      this.notMergeableMessage =
        "You can only vertically merge segments the are a part of a rail group with the same number of segments.";
      return false;
    } else {
      for (let i = 0; i < this.anchorRailGroup.length; i++) {
        if (this.anchorRailGroup[i].columns !== this.moverRailGroup[i].columns) {
          this.notMergeableMessage =
            "You can only vertically merge segments that are a part of rail groups that have segments with the same number of columns.";
          return false;
        }
      }
    }

    if (this.anchorThermalExpansions.length !== this.moverThermalExpansions.length) {
      this.notMergeableMessage = "You can not vertically merge segments with different numbers of thermal expansions.";
      return false;
    } else if (this.#mergeSegmentsHaveDifferentThermalExpansionSegmentPatterns) {
      this.notMergeableMessage =
        "You can not vertically merge segments with thermal expansions in different positions.";
      return false;
    }

    return true;
  }

  get #mergeSegmentsHaveDifferentThermalExpansionSegmentPatterns() {
    return (
      this.#segmentsAndThermalExpansionsOrderedPattern(this.anchorRailedParent) !==
      this.#segmentsAndThermalExpansionsOrderedPattern(this.moverRailedParent)
    );
  }

  #segmentsAndThermalExpansionsOrderedPattern(railedParent) {
    const result = railedParent.railedGroup.map((s) => ({
      distance: s.endDistanceFromRailedParentStart,
      type: "s",
    }));

    railedParent.thermalExpansions.forEach((te) => {
      const thermalExpansionDistance = this.controller.mapModelSynchronizer.getDistanceInInches(
        railedParent.startLatLng,
        te.startLatLng,
      );
      result.push({
        distance: thermalExpansionDistance,
        type: "t",
      });
    });

    return sortBy(result, "distance")
      .map((r) => r.type)
      .join("");
  }

  #mergeHorizontal() {
    const moverColumns = this.moverSegment.columns;
    const anchorColumns = this.anchorSegment.columns;

    const side = this.#isAnchorOnLeft ? "right" : "left";

    const moverRailGroup = SegmentsRailGroup.fromSegmentUuid({
      controller: this.controller,
      uuid: this.moverSegment.uuid,
    });

    const anchorRailGroup = SegmentsRailGroup.fromSegmentUuid({
      controller: this.controller,
      uuid: this.anchorSegment.uuid,
    });

    if (!this.#segmentsAreInSameRailGroup && moverRailGroup.segments.length > 1) {
      this.#alignMoverSegmentsToAnchorTop(moverRailGroup);

      if (this.moverSegment.railed) {
        this.#addMoverSegmentsAndThermalExpansionsToAnchor(moverRailGroup);
      }

      if (this.anchorSegment.railed) {
        this.#addAnchorSegmentsAndThermalExpansionsToMover(anchorRailGroup);
      }
    }

    const moverZonesAndPositions = this.moverSegment.zonesAndModulePositionsGrid;
    const moverContour = this.moverSegment.contourCodesGrid;

    moverRailGroup.deleteSegment(this.moverSegment);

    this.#addColumnsFromMoverToAnchor(side, moverColumns);

    this.#horizontalMergeAddPanelPropertiesToNewPanels(
      side,
      anchorColumns,
      moverColumns,
      moverZonesAndPositions,
      moverContour,
    );
  }

  #alignMoverSegmentsToAnchorTop(moverRailGroup) {
    const alignCollection = new Collection([this.anchorSegmentFeature, ...moverRailGroup.segmentFeatures]);
    const aligner = new SegmentsAligner({
      controller: this.controller,
      selectionCollection: alignCollection,
      alignTo: "top",
    });
    aligner.align();
  }

  #addMoverSegmentsAndThermalExpansionsToAnchor(moverRailGroup) {
    this.moverRailedParent.thermalExpansions.forEach((te) => {
      const teSnapshot = getSnapshot(te);
      this.moverRailedParent.deleteThermalExpansion(te);
      this.anchorRailedParent.addThermalExpansion(teSnapshot);
    });

    moverRailGroup.segments.forEach((segment) => {
      if (segment === this.moverSegment) return;

      segment.setRailedParentUuid(this.anchorRailedParent.uuid);
    });
  }

  #addAnchorSegmentsAndThermalExpansionsToMover(anchorRailGroup) {
    this.anchorRailedParent.thermalExpansions.forEach((te) => {
      const teSnapshot = getSnapshot(te);
      this.anchorRailedParent.deleteThermalExpansion(te);
      this.moverRailedParent.addThermalExpansion(teSnapshot);
    });

    anchorRailGroup.segments.forEach((segment) => {
      if (segment === this.anchorSegment) {
        segment.setRailed(false);
      }
      segment.setRailedParentUuid(this.moverRailedParent.uuid);
    });
  }

  #addColumnsFromMoverToAnchor(side, moverColumns) {
    const selectionCollection = new Collection([this.anchorSegmentFeature]);
    const adder = new SegmentsRowsAndColumnsAdder({
      controller: this.controller,
      selectionCollection,
      side,
    });
    for (let i = 0; i < moverColumns; i++) {
      adder.add();
    }
  }

  #horizontalMergeAddPanelPropertiesToNewPanels(
    side,
    anchorColumns,
    moverColumns,
    moverZonesAndPositions,
    moverContour,
  ) {
    const anchorPanelsGrid = this.anchorSegment.panelsGrid;
    anchorPanelsGrid.forEach((panelsRow, rowIndex) => {
      const newColumnsStartIndex = side === "left" ? 0 : anchorColumns;
      const newPanels = panelsRow.slice(newColumnsStartIndex, newColumnsStartIndex + moverColumns);
      newPanels.forEach((panel, columnIndex) => {
        const [zone, position] = moverZonesAndPositions[rowIndex][columnIndex].split(":");
        const contour = moverContour[rowIndex][columnIndex];

        panel.setZone(zone);
        panel.setModulePosition(position);
        panel.setContourCode(contour);
      });
    });
  }

  get #isAnchorOnLeft() {
    const anchorPixels = this.#pixelCoordinates(this.anchorSegmentFeature);
    const anchorMidPoint = (anchorPixels[1][0] - anchorPixels[0][0]) / 2 + anchorPixels[0][0];
    const moverPixels = this.#pixelCoordinates(this.moverSegmentFeature);
    const moverMidPoint = (moverPixels[1][0] - moverPixels[0][0]) / 2 + moverPixels[0][0];
    return anchorMidPoint < moverMidPoint;
  }

  #pixelFromLatLng(latLng) {
    const lngLat = [latLng[1], latLng[0]];
    const coordinate = fromLonLat(lngLat);
    return this.map.getPixelFromCoordinate(coordinate);
  }

  #pixelCoordinates(feature) {
    return feature
      .getGeometry()
      .getLinearRing(0)
      .getCoordinates()
      .map((coordinate) => this.map.getPixelFromCoordinate(coordinate));
  }

  #mergeVertical() {
    const moverRows = this.moverSegment.rows;
    const anchorRows = this.anchorSegment.rows;

    const side = this.#isMoverOnTop ? "top" : "bottom";

    this.#addMoverRowsToAnchor(side, moverRows);

    this.#verticalMergeAddMoverPanelPropertiesToNewPanels(side, anchorRows, moverRows);
  }

  get #isMoverOnTop() {
    const moverSegmentPixels = this.#pixelCoordinates(this.moverSegmentFeature);
    const anchorSegmentPixels = this.#pixelCoordinates(this.anchorSegmentFeature);

    return moverSegmentPixels[0][1] < anchorSegmentPixels[0][1];
  }

  #addMoverRowsToAnchor(side, moverRows) {
    const selectionCollection = new Collection([this.anchorSegmentFeature]);
    const adder = new SegmentsRowsAndColumnsAdder({
      controller: this.controller,
      selectionCollection,
      side,
    });
    for (let i = 0; i < moverRows; i++) {
      adder.add();
    }
  }

  #verticalMergeAddMoverPanelPropertiesToNewPanels(side, anchorRows, moverRows) {
    const moverRailGroup = SegmentsRailGroup.fromSegmentUuid({
      controller: this.controller,
      uuid: this.moverSegment.uuid,
    });

    const anchorRailGroup = SegmentsRailGroup.fromSegmentUuid({
      controller: this.controller,
      uuid: this.anchorSegment.uuid,
    });

    const moverZonesAndPositions = moverRailGroup.consolidatedSegmentZonesAndModulePositionsGrid;
    const moverContour = moverRailGroup.consolidatedContourCodesGrid;

    const anchorConsolidatedGrid = anchorRailGroup.consolidatedPanelsGrid.map((row) =>
      row.map((feature) => feature.get("model")),
    );

    const newRowsStartRowIndex = side === "top" ? 0 : anchorRows;
    const newPanelsGrid = anchorConsolidatedGrid.slice(newRowsStartRowIndex, newRowsStartRowIndex + moverRows);

    newPanelsGrid.forEach((panelsRow, rowIndex) => {
      panelsRow.forEach((panel, columnIndex) => {
        const [zone, position] = moverZonesAndPositions[rowIndex][columnIndex].split(":");
        const contour = moverContour[rowIndex][columnIndex];

        panel.setZone(zone);
        panel.setModulePosition(position);
        panel.setContourCode(contour);
      });
    });

    moverRailGroup.deleteSegments();
  }
}
