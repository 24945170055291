import { Controller } from "@hotwired/stimulus";
import anime from "animejs";

// Behavior for showing and hiding the ir-flash message element
export default class extends Controller {
  static targets = ["flash"];

  delayBeforeShowTiming = 600;
  showMessageTiming = 3000;
  autoHideTimeout = undefined;

  connect() {
    setTimeout(() => {
      this.animateIn();
      this.autoHideAfterDelay();
    }, this.delayBeforeShowTiming);
  }

  autoHideAfterDelay() {
    this.autoHideTimeout = setTimeout(() => {
      this.animateOut(400);
    }, this.showMessageTiming);
  }

  close(event) {
    event.preventDefault();
    this.animateOut(200);
  }

  mouseOver(_event) {
    clearTimeout(this.autoHideTimeout);
  }

  mouseOut(_event) {
    this.autoHideAfterDelay();
  }

  animateIn() {
    this.flashTarget.style.display = "block";
    anime({
      targets: this.flashTarget,
      opacity: 1,
      top: ["9%", "7%"],
      easing: "easeOutQuad",
      duration: 500,
    });
  }

  animateOut(timing) {
    anime({
      targets: this.flashTarget,
      opacity: 0,
      top: ["7%", "4%"],
      easing: "easeInQuad",
      duration: timing,
      complete: () => {
        this.flashTarget.style.display = "none";
      },
    });
  }
}
