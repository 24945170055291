import { Controller } from "@hotwired/stimulus";
import * as store from "../../helpers/local-store";

export default class extends Controller {
  static values = {
    userUuid: String,
  };

  static targets = ["termsOfServiceAcceptedAtField"];

  connect() {
    const delayMilliseconds = 500;
    const termsOfServiceAcceptedAt = store.get(`${this.userUuidValue}-termsOfServiceAcceptedAt`);

    if (termsOfServiceAcceptedAt) {
      this.termsOfServiceAcceptedAtFieldTarget.value = termsOfServiceAcceptedAt;
    }

    setTimeout(() => {
      this.element.requestSubmit();
    }, delayMilliseconds);
  }
}
