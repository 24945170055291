import React from "react";
import PropTypes from "prop-types";

import Button from "../../Button";

function SaveWithCropPopup({ closeSaveOptionsPopup, saveWithOption }) {
  return (
    <div className="graphical-tool__toolbar__save-options">
      <a href="#" onClick={closeSaveOptionsPopup} className="graphical-tool__toolbar__save-options__close-link">
        &times;
      </a>
      <h4>Save</h4>
      <Button
        onButtonClick={() => {
          saveWithOption("crop");
        }}
        text="Crop first"
        actionStyle="green"
        icon="crop"
      />
      <Button onButtonClick={saveWithOption} text="As is" icon="check" />
    </div>
  );
}

SaveWithCropPopup.propTypes = {
  closeSaveOptionsPopup: PropTypes.func.isRequired,
  saveWithOption: PropTypes.func.isRequired,
};

export default React.memo(SaveWithCropPopup);
