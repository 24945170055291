import Point from "ol/geom/Point";
import Feature from "ol/Feature";

import BaseMapModelSynchronizer from "./base";
import { lineIntersectionInCoordinates } from "../../../da/map/ol-geometry";
import RaftersBuilder from "../modification-helpers/rafters-builder";

export default class AttachmentsRoofPlaneMapModelSynchronizer extends BaseMapModelSynchronizer {
  get attachmentFeatures() {
    const results = [];

    this.controller.pageRoofPlanes.forEach((roofPlane) => {
      const allRailFeatures = this.controller.mapManager.railsVectorSource.getFeatures();

      const roofPlaneSegments = roofPlane.roofSections.flatMap((rs) => rs.segments);
      roofPlaneSegments.forEach((segment) => {
        const segmentRailFeatures = allRailFeatures.filter((rf) => rf.get("segmentUuid") === segment.uuid);
        const allRafterFeatures = this.getAllRafterFeatures(roofPlane);

        segment.attachmentPositions.forEach((row, rowNum) => {
          row.forEach((rail, railNum) => {
            // Rafter numbers start at 1
            const railFeature = segmentRailFeatures.find(
              (rf) => rf.get("row") === rowNum + 1 && rf.get("rail") === railNum + 1,
            );

            if (railFeature) {
              const railFeatureCoordinates = railFeature.get("midLineCoordinates");

              rail.forEach((rafterNum) => {
                const rafterFeature = allRafterFeatures[rafterNum - 1];
                const rafterFeatureCoordinates = rafterFeature.getGeometry().getCoordinates();
                const intersection = lineIntersectionInCoordinates(railFeatureCoordinates, rafterFeatureCoordinates);
                const point = new Point(intersection);
                const attachmentFeature = new Feature({ geometry: point });
                if (!segment.isAttachmentPlanStatusLegal) {
                  attachmentFeature.set("illegalShape", true);
                }

                results.push(attachmentFeature);
              });
            }
          });
        });
      });
    });

    return results;
  }

  getAllRafterFeatures(roofPlane) {
    if (this.project.detail.isDeckAttached) {
      const raftersBuilder = new RaftersBuilder({ controller: this.controller, roofPlane });
      raftersBuilder.buildFraRafters();
      return raftersBuilder.features;
    } else {
      const roofPlaneRafterFeatures =
        this.controller.mapManager.rafterVectorSources[roofPlane.identifier].getFeatures();
      return roofPlaneRafterFeatures.sort((f1, f2) => f1.get("rafterNumber") - f2.get("rafterNumber"));
    }
  }

  loadSetbacks() {
    return this.controller.pageRoofPlanes.map((rp) => this.buildSetbackForRoofPlane(rp));
  }

  reRenderRoofPlanes() {
    const pageRoofPlaneUuids = this.controller.pageRoofPlanes.map((rp) => rp.uuid);
    const features = this.featuresForDisplayableRoofPlanes().filter((rpf) => {
      return pageRoofPlaneUuids.includes(rpf.get("uuid"));
    });
    features.forEach((feature) => this.controller.mapManager.roofPlanesVectorSource.addFeature(feature));
  }

  reRenderRoofSections() {
    const pageRoofSectionUuids = this.controller.pageRoofPlanes.flatMap((rp) => rp.roofSections.map((rs) => rs.uuid));
    const features = this.featuresForDisplayableRoofSections().filter((rpf) => {
      return pageRoofSectionUuids.includes(rpf.get("uuid"));
    });
    features.forEach((feature) => this.controller.mapManager.roofSectionsVectorSource.addFeature(feature));
  }

  reRenderSetbacks() {
    const features = this.loadSetbacks();
    features.forEach((feature) => this.controller.mapManager.setbacksVectorSource.addFeature(feature));
  }

  reRenderPanels() {
    const pageRoofPlanes = this.controller.pageRoofPlanes;
    const panelFeatures = pageRoofPlanes.flatMap((roofPlane) => {
      return this.featuresForRoofPlanePanels(roofPlane);
    });
    panelFeatures.forEach((pf) => this.controller.mapManager.panelsVectorSource.addFeature(pf));
    const segmentUuids = pageRoofPlanes.flatMap((rp) =>
      rp.roofSections.flatMap((rs) => rs.segments.map((s) => s.uuid)),
    );
    this.segmentFeatures.forEach((sf) => {
      if (segmentUuids.includes(sf.get("uuid"))) {
        this.controller.mapManager.segmentsVectorSource.addFeature(sf);
      }
    });
    this.railFeatures.forEach((rf) => {
      if (segmentUuids.includes(rf.get("segmentUuid"))) {
        this.controller.mapManager.railsVectorSource.addFeature(rf);
      }
    });
  }

  reRenderAttachments() {
    const features = this.attachmentFeatures;
    features.forEach((feature) => {
      this.controller.mapManager.attachmentsVectorSource.addFeature(feature);
    });
  }

  clearAttachmentFeatures() {
    this.controller.mapManager.attachmentsVectorSource.clear();
  }
}
