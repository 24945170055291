import { fromLonLat } from "ol/proj";

import { linkFeatureToModel } from "./base";
import RoofPlaneLegalityChecker from "../../../da/map/legality-checkers/roof-plane";
import { removeFeaturesForRoofPlane } from "../ol-helpers";
import {
  calculateUnitPerpendicularEdgeVectorLatLng,
  cartesianPointRelativeTo,
  setCartesianPointsForModelRelativeToOrigin,
  cartesianPointsRelativeToOrigin,
} from "../../../da/map/ol-geometry";
import CartesianModel from "../../../da/models/cartesian-model";

export function createRoofPlane(rpFeature, project, mapModelSynchronizer, mapManager) {
  const roofPlane = project.addRoofPlane({});
  linkFeatureToModel(rpFeature, roofPlane);

  mapModelSynchronizer.updateRoofPlaneLatLngFromFeature(rpFeature);
  new RoofPlaneLegalityChecker(mapManager.roofPlanesFeatures, mapModelSynchronizer).markIllegalPolygons(rpFeature);
}

export function modifyRoofPlane(rpFeature, mapModelSynchronizer, mapManager) {
  repositionRoofPlane(rpFeature, mapModelSynchronizer, mapManager);
  mapManager.forceRedrawRoofPlanes();
}

export function repositionRoofPlane(rpFeature, mapModelSynchronizer, mapManager) {
  mapModelSynchronizer.updateRoofPlaneLatLngFromFeature(rpFeature);
  new RoofPlaneLegalityChecker(mapManager.roofPlanesFeatures, mapModelSynchronizer).markIllegalPolygons();
}

export function deleteRoofPlane(rpFeature, project, mapModelSynchronizer, mapManager) {
  const roofPlane = mapModelSynchronizer.getRoofPlaneForFeature(
    rpFeature,
    "PrRoofPlaneModificationHelper.deleteRoofPlane",
  );

  if (!roofPlane) {
    console.log("Deleted feature not a polygon. Skipping.");
    return;
  }

  removeFeaturesForRoofPlane(roofPlane, mapManager);

  if (roofPlane.id) {
    // this also flags its roof sections for deletion
    roofPlane.flagDeleted();
  } else {
    // currently an unsaved roof plane can't have any roof sections
    // but this method will remove them automatically if future code
    // allows them to be created (e.g. with a default setback)
    project.destroyRoofPlane(roofPlane);
  }

  // This is less efficient if multiple roof planes are being deleted, but it's cleaner
  // because we can be confident that each call to deleteRoofPlane will leave things in
  // a valid final state.
  new RoofPlaneLegalityChecker(mapManager.roofPlanesFeatures, mapModelSynchronizer).markIllegalPolygons();
  project.resequenceRoofPlanesIdentifiers();
  mapModelSynchronizer.syncIdentifiersOnFeatures();
}

export function setRoofPlaneEaveValues(roofPlane, eaveEdgeIndex) {
  roofPlane.setEaveEdgeIndex(eaveEdgeIndex);
  setRoofPlaneEaveLatLngValues(roofPlane);
  const originLonLat = roofPlane.project.detail.originLatLng.toLonLat;
  setCartesianCoordinatesForRoofPlaneAndChildren(roofPlane, originLonLat);
}

export function setRoofPlaneEaveLatLngValues(roofPlane) {
  const eaveCoordinates = roofPlane.eaveLatLngPoints.map((p) => fromLonLat(p.toLonLat));
  const [midpointLatLng, unitPerpendicularEdgeVectorLatLng, unitEdgeVectorLatLng] =
    calculateUnitPerpendicularEdgeVectorLatLng(eaveCoordinates[0], eaveCoordinates[1]);

  roofPlane.setEaveMidpointLatLng({ lat: midpointLatLng.lat, lng: midpointLatLng.lng });
  roofPlane.setEaveEdgeVectorLatLng({ lat: unitEdgeVectorLatLng.lat, lng: unitEdgeVectorLatLng.lng });
  roofPlane.setEavePerpendicularEdgeVectorLatLng({
    lat: unitPerpendicularEdgeVectorLatLng.lat,
    lng: unitPerpendicularEdgeVectorLatLng.lng,
  });
}

export function setCartesianCoordinatesForRoofPlaneAndChildren(roofPlane, originLonLat) {
  setCartesianPointsForModelRelativeToOrigin(originLonLat, roofPlane);
  if (roofPlane.eaveEdgeIndex === null) return;
  setRoofPlaneEaveCartesianPoints(roofPlane, originLonLat);

  const repositionedBoundingBoxCartesianPoints = cartesianPointsRelativeToOrigin(originLonLat, roofPlane.boundingBox);
  roofPlane.setBoundingBoxCartesianPoints(repositionedBoundingBoxCartesianPoints);

  roofPlane.roofSections.forEach((roofSection) => {
    setCartesianPointsForModelRelativeToOrigin(originLonLat, roofSection);

    roofSection.segments.forEach((segment) => {
      const startCartesianPoint = cartesianPointRelativeTo(segment.startLatLng.toLonLat, originLonLat);
      segment.setStartCartesianPoint(startCartesianPoint);
      segment.thermalExpansions.forEach((te) => {
        const teStartCartesianPoint = cartesianPointRelativeTo(te.startLatLng.toLonLat, originLonLat);
        te.setStartCartesianPoint(teStartCartesianPoint);
      });
      segment.adjoinments.forEach((adjoinment) => {
        adjoinment.clearCartesianPoints();
        const cartesianPoints = adjoinment.latLngPoints.map((ll) => {
          return cartesianPointRelativeTo(ll.toLonLat, originLonLat);
        });
        adjoinment.setCartesianPoints(cartesianPoints);
      });
      segment.contourLegalityAdjoinments.forEach((adjoinment) => {
        adjoinment.clearCartesianPoints();
        const cartesianPoints = adjoinment.latLngPoints.map((ll) => {
          return cartesianPointRelativeTo(ll.toLonLat, originLonLat);
        });
        adjoinment.setCartesianPoints(cartesianPoints);
      });
      segment.contours.forEach((contour) => {
        contour.clearCartesianPoints();
        const cartesianPoints = contour.latLngPoints.map((ll) => {
          return cartesianPointRelativeTo(ll.toLonLat, originLonLat);
        });
        contour.setCartesianPoints(cartesianPoints);
      });
    });
  });
}

export function setRoofPlaneEaveCartesianPoints(roofPlane, originLonLat) {
  const eaveMidpointCartesianPoint = cartesianPointRelativeTo(roofPlane.eaveMidpointLatLng.toLonLat, originLonLat);
  roofPlane.setEaveMidpointCartesianPoint(eaveMidpointCartesianPoint);
  const eaveCartesianPoints = roofPlane.eaveCartesianPoints;
  const eaveEdgeVectorMagnitude = eaveCartesianPoints[1].distanceTo(eaveCartesianPoints[0]);
  const eaveEdgeVectorCartesianPoint = eaveCartesianPoints[1]
    .minus(eaveCartesianPoints[0])
    .dividedBy(eaveEdgeVectorMagnitude);
  roofPlane.setEaveEdgeVectorCartesianPoint(eaveEdgeVectorCartesianPoint);
  const eavePerpendicularEdgeVectorCartesianPoint = CartesianModel.create({
    x: eaveEdgeVectorCartesianPoint.y * -1,
    y: eaveEdgeVectorCartesianPoint.x,
  });
  roofPlane.setEavePerpendicularEdgeVectorCartesianPoint(eavePerpendicularEdgeVectorCartesianPoint);
}
