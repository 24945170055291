import { Controller } from "@hotwired/stimulus";

import { track } from "../../helpers/app";

export default class CadDlController extends Controller {
  static targets = ["attachmentSelect"];
  static values = { baseUrl: String, projectType: String, trackingCategory: String };

  download(event) {
    if (!this.#isValid) {
      alert("Please select a roof attachment");
      return;
    }

    const { fileType } = event.params;
    const fileName = `${this.attachmentSelectTarget.value}-${
      this.projectTypeValue
    }-AutoCAD-${fileType.toUpperCase()}.${fileType}`;

    this.#downloadFile(fileName);
  }

  get #isValid() {
    return this.attachmentSelectTarget.value !== "";
  }

  #downloadFile(fileName) {
    const fileUrl = this.baseUrlValue + fileName;
    track({ action: "Document Download", category: this.trackingCategoryValue, label: fileUrl });

    // give tracking code time to execute
    setTimeout(() => {
      window.open(fileUrl, "_blank");
    }, 250);
  }
}
