import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";
import { addOptionsToSelect } from "../../helpers/form";

export default class extends Controller {
  static targets = [
    "roofMaterialFamilySelect",
    "roofMaterialFamilyInfoPopup",
    "roofMaterialContainer",
    "roofMaterialSelect",
    "roofAttachmentSelect",
    "roofAttachmentInfoPopup",
    "hugBadgeContainer",
  ];

  static values = {
    roofAttachmentToRoofMaterials: Object,
    roofAttachmentToRoofMaterialFamilies: Object,
    roofMaterialFamilyToRoofMaterials: Object,
    materialToAttachmentXr: Object,
  };

  get roofMaterialFamily() {
    return this.roofMaterialFamilySelectTarget.value;
  }

  get roofMaterial() {
    return this.roofMaterialSelectTarget.value;
  }

  get roofAttachment() {
    return this.roofAttachmentSelectTarget.value;
  }

  get isRoofAttachmentEmpty() {
    return this.roofAttachment === "";
  }

  changeRoofMaterialFamily() {
    this.#updateRoofMaterialList();
    this.changeRoofMaterial();
    this.toggleRoofMaterialFamilyInfoPopup();
    this.toggleRoofMaterialInfoPopup();
    this.toggleHugBadge();
  }

  changeRoofMaterial() {
    this.updateAttachmentList();
    this.updateDependenciesOnAttachmentList();
  }

  updateDependenciesOnAttachmentList() {}

  toggleRoofMaterialFamilyInfoPopup() {
    if (this.roofMaterialFamily === "other") {
      this.roofMaterialFamilyInfoPopupTarget.classList.add("d-none");
    } else {
      this.roofMaterialFamilyInfoPopupTarget.classList.remove("d-none");

      const dialog = document.querySelector(
        `[data-dialog-identifier=info-popup-${this.constructor.projectType}-roof-material-family]`,
      );
      const sections = dialog.querySelectorAll("[data-roof-material-family]");

      let showSelector = `[data-roof-material-family=${this.roofMaterialFamily}]`;

      for (let section of sections) {
        section.style.display = section.matches(showSelector) ? "block" : "none";
      }
    }
  }

  toggleRoofMaterialInfoPopup() {}

  toggleRoofAttachmentInfoPopup() {
    if (this.isRoofAttachmentEmpty) {
      this.roofAttachmentInfoPopupTarget.classList.add("d-none");
    } else {
      this.roofAttachmentInfoPopupTarget.classList.remove("d-none");
    }
  }

  toggleHugBadge() {
    if (!this.hasHugBadgeContainerTarget) return;

    if (this.roofMaterialFamily === "cs") {
      this.hugBadgeContainerTarget.classList.remove("d-none");
    } else {
      this.hugBadgeContainerTarget.classList.add("d-none");
    }
  }

  #updateRoofMaterialList() {
    const roofMaterials = this.roofMaterialFamilyToRoofMaterialsValue[this.roofMaterialFamily];

    const roofMaterialValues = roofMaterials.map((rm) => rm[1]);
    const shouldHideRoofMaterial = roofMaterialValues.length === 1 && roofMaterialValues[0] === this.roofMaterialFamily;
    animate.toggle(this.roofMaterialContainerTarget, !shouldHideRoofMaterial);

    const prompt = roofMaterials.length > 1 ? [["- choose one -", ""]] : [];
    const roofMaterialOptions = prompt.concat(roofMaterials.map((a) => [a[0], a[1], false, false]));
    addOptionsToSelect(roofMaterialOptions, this.roofMaterialSelectTarget, false);
  }
}
