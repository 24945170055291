export default class {
  get displayString() {
    const { street, city, state, zip } = this;
    const hasStreet = street && street !== "";
    const hasCity = city && city !== "";
    const hasState = state && state !== "";
    const hasZip = zip && zip !== "";

    let result = "";
    if (hasStreet) {
      result += street;
      if (hasCity || hasState || hasZip) result += "\n<br />\n";
    }

    if (hasCity) {
      result += city;
      if (hasState || hasZip) result += ", ";
    }
    if (hasState) {
      result += state;
      if (hasZip) result += " ";
    }
    if (hasZip) result += zip;

    return result;
  }

  equals(location) {
    return this.compareString === location.compareString;
  }

  get compareString() {
    return `${this.street} ${this.city} ${this.state} ${this.zip}`;
  }
}
