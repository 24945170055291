import DaBase from "../../../../da/map/interaction-managers/modify/base";
import { modifyRoofPlane } from "../../modification-helpers/roof-plane";
import { updateProjectOriginAndCartesianCoordinatesForChildren } from "../../modification-helpers/project";

export default class RoofPlanes extends DaBase {
  modifyEndForFeature(rpFeature) {
    modifyRoofPlane(rpFeature, this.mapModelSynchronizer, this.mapManager);
    const roofPlane = this.mapModelSynchronizer.getRoofPlaneForFeature(
      rpFeature,
      "PrRoofPlaneModifyInteractionManager#modifyEndForFeature",
    );
    roofPlane.clearSetbackGuides();
    updateProjectOriginAndCartesianCoordinatesForChildren(this.controller.project);
  }
}
