import { types } from "mobx-state-tree";

const CoordinateModel = types
  .model("CoordinateModel", {
    y: types.number,
    x: types.number,
  })
  .views((self) => ({
    get toCoordinate() {
      return [self.y, self.x];
    },
  }));

CoordinateModel.createFromCoordinate = (coordinate) => {
  return CoordinateModel.create({ x: coordinate[1], y: coordinate[0] });
};

export default CoordinateModel;
