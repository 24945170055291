import { cartesianPointRelativeTo } from "../../../../da/map/ol-geometry";
import PanelsNudgerHorizontal from "../panels/nudger/horizontal";
import { createThermalExpansionFeature } from "./helpers";

export const THERMAL_EXPANSION_DIRECTION_LEFT = "left";
export const THERMAL_EXPANSION_DIRECTION_RIGHT = "right";

export default class ThermalExpansionAdder {
  constructor({ controller, selectionCollection, direction, sourcePanelFeature }) {
    this.controller = controller;
    this.mapManager = controller.mapManager;
    this.project = controller.project;
    this.selectionCollection = selectionCollection;
    this.direction = direction;
    this.panel = this.#panelFromSourcePanelFeature(sourcePanelFeature);
    this.panelStartLatLng = this.panel.startLatLng.clone;
    this.segment = this.panel.segment.railed ? this.panel.segment : this.panel.segment.railedParent;
    this.unitColumnVectorLatLng = this.segment.unitColumnVectorLatLng;
    this.panelLengthAlongEave = this.segment.panelLengthAlongEave;
    this.originLonLat = this.segment.roofPlane.project.detail.originLatLng.toLonLat;
    this.nudgeDistance = this.project.thermalExpansionLength - this.project.interColumnSpacing;
  }

  add() {
    this.#autoSelectColumn();
    this.#addThermalExpansion(this.segment);
    if (this.#requiresNudge) this.#nudgePanels();
  }

  #panelFromSourcePanelFeature(sourcePanelFeature) {
    const selectedPanel = sourcePanelFeature.get("model");
    return selectedPanel.segment.panels.find((p) => p.column === selectedPanel.column && p.row === 1);
  }

  // Ensure that all of the panels in the segment column are selected.  This allows the user to
  // only have to select a single panel in the column to add a thermal expansion.
  #autoSelectColumn() {
    const segmentUuid = this.panel.segment.uuid;
    const column = this.panel.column;
    const selectedFeatures = this.selectionCollection.getArray();
    this.mapManager.panelsVectorSource.getFeatures().forEach((feature) => {
      const panel = feature.get("model");
      if (panel.segment.uuid === segmentUuid && panel.column === column) {
        if (selectedFeatures.find((sf) => sf === feature)) return;
        this.selectionCollection.push(feature);
      }
    });
  }

  #addThermalExpansion(segment) {
    const thermalExpansion = this.#createThermalExpansionModel(segment);
    const feature = createThermalExpansionFeature(thermalExpansion);
    this.mapManager.thermalExpansionsVectorSource.addFeature(feature);
  }

  #createThermalExpansionModel(segment) {
    const startLatLng = this.#startLatLng;
    const startCartesianPoint = cartesianPointRelativeTo(startLatLng.toLonLat, this.originLonLat);
    const thermalExpansion = segment.addThermalExpansion({ startLatLng, startCartesianPoint });

    return thermalExpansion;
  }

  get #startLatLng() {
    let latLng = this.panelStartLatLng;
    if (this.#isLeft) {
      latLng = latLng.minus(this.unitColumnVectorLatLng.times(this.project.thermalExpansionLength));
    } else {
      latLng = latLng.plus(this.unitColumnVectorLatLng.times(this.panelLengthAlongEave));
    }
    return latLng;
  }

  get #isLeft() {
    return this.direction === THERMAL_EXPANSION_DIRECTION_LEFT;
  }

  get #isRight() {
    return this.direction === THERMAL_EXPANSION_DIRECTION_RIGHT;
  }

  get #requiresNudge() {
    if (this.#isLeft) {
      if (this.panel.column !== 1) return true;

      const s1 = this.panel.segment.previousRailGroupSegment;
      const s2 = this.panel.segment;
      return this.#segmentsAreCloserThanNudgeDistance(s1, s2);
    }
    if (this.#isRight) {
      if (this.panel.column !== this.panel.segment.columns) return true;

      const s1 = this.panel.segment;
      const s2 = this.panel.segment.nextRailGroupSegment;
      return this.#segmentsAreCloserThanNudgeDistance(s1, s2);
    }
    return;
  }

  #segmentsAreCloserThanNudgeDistance(segment1, segment2) {
    const distanceBetween =
      segment2.distanceFromRailedParentStart - (segment1.distanceFromRailedParentStart + segment1.width);
    return distanceBetween < this.nudgeDistance;
  }

  #nudgePanels() {
    const nudger = new PanelsNudgerHorizontal({
      controller: this.controller,
      selectionCollection: this.selectionCollection,
      nudgeDirection: this.direction,
      nudgeDistance: this.nudgeDistance,
    });
    const options = {
      shiftSplits: this.#isLeft ? -1 : 1,
    };
    nudger.nudge(options);
  }
}
