import React from "react";
import PropTypes from "prop-types";
import GridRails from "./GridRails";
import { isContourEast, isContourSouth, isContourWest } from "../../../helpers/contour";

const CELL_SPACING = 1;

function GridCell({
  height,
  width,
  x,
  y,
  row,
  column,
  marqueeHover,
  selected,
  handleClick,
  isRailOrientationNs,
  showRails,
  thirdRailUsed,
  contourGridSetting,
  arrayEditingAllowed,
  contourEditingAllowed,
  showRailOverhangLeft,
  showRailOverhangRight,
  showRailOverhangTop,
  showRailOverhangBottom,
  projectType,
  useCritterGuardNorth,
  useCritterGuardEast,
  useCritterGuardSouth,
  useCritterGuardWest,
}) {
  const onClick = (event) => {
    event.preventDefault();

    if (!(arrayEditingAllowed || contourEditingAllowed)) return;

    handleClick(row, column, !selected);
  };

  const classNames = ["graphical-tool__grid-cell"];
  if (selected) {
    classNames.push("graphical-tool__grid-cell--selected");
  }
  if (marqueeHover) {
    classNames.push("graphical-tool__grid-cell--marquee-hover");
  }
  if (!arrayEditingAllowed) {
    classNames.push("graphical-tool__grid-cell--view-only");
  }

  const styles = {
    height: `${height}px`,
    width: `${width}px`,
    left: `${x}px`,
    top: `${y}px`,
  };

  const panelOverlayStyles = {
    width: `${width - CELL_SPACING}px`,
    height: `${height - CELL_SPACING}px`,
    top: 0,
    left: 0,
    position: "absolute",
    background: "rgba(54, 141, 214, 0.5)",
    border: "2px solid #2573b4",
  };

  const doPanelsLookLikeLandscape = width > height;
  const thicknessMultiplier = doPanelsLookLikeLandscape ? 0.1 : 0.2;
  const contourCritterGuardThickness = (width - CELL_SPACING) * thicknessMultiplier;

  const contourCritterGuardWestAndEastStyles = {
    width: `${contourCritterGuardThickness}px`,
    height: `${height - CELL_SPACING}px`,
  };

  const contourCritterGuardNorthSouthStyles = {
    width: `${width - CELL_SPACING}px`,
    height: `${contourCritterGuardThickness}px`,
  };

  return (
    <div className="graphical-tool__grid-cell__container" style={styles}>
      <div className={classNames.join(" ")} onClick={onClick} draggable="false" data-testid="grid-cell">
        {((showRails && selected) || projectType === "GB4") && (
          <>
            <GridRails
              height={height}
              width={width}
              isRailOrientationNs={isRailOrientationNs}
              thirdRail={thirdRailUsed}
              contourGridSetting={contourGridSetting}
              showRailOverhangLeft={showRailOverhangLeft}
              showRailOverhangRight={showRailOverhangRight}
              showRailOverhangTop={showRailOverhangTop}
              showRailOverhangBottom={showRailOverhangBottom}
            />
            {projectType !== "GB4" && <div style={panelOverlayStyles} onClick={onClick} draggable="false" />}

            {isContourWest(contourGridSetting) && (
              <div className="graphical-tool__grid-cell__contour--west" style={contourCritterGuardWestAndEastStyles} />
            )}
            {isContourEast(contourGridSetting) && (
              <div className="graphical-tool__grid-cell__contour--east" style={contourCritterGuardWestAndEastStyles} />
            )}
            {isContourSouth(contourGridSetting) && (
              <div className="graphical-tool__grid-cell__contour--south" style={contourCritterGuardNorthSouthStyles} />
            )}

            {useCritterGuardNorth && (
              <div
                className="graphical-tool__grid-cell__critter-guard--north"
                style={contourCritterGuardNorthSouthStyles}
              />
            )}
            {useCritterGuardEast && (
              <div
                className="graphical-tool__grid-cell__critter-guard--east"
                style={contourCritterGuardWestAndEastStyles}
              />
            )}
            {useCritterGuardSouth && (
              <div
                className="graphical-tool__grid-cell__critter-guard--south"
                style={contourCritterGuardNorthSouthStyles}
              />
            )}
            {useCritterGuardWest && (
              <div
                className="graphical-tool__grid-cell__critter-guard--west"
                style={contourCritterGuardWestAndEastStyles}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

GridCell.propTypes = {
  selected: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  row: PropTypes.number.isRequired,
  column: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  marqueeHover: PropTypes.bool,
  isRailOrientationNs: PropTypes.bool,
  showRails: PropTypes.bool,
  thirdRailUsed: PropTypes.bool,
  arrayEditingAllowed: PropTypes.bool,
  showRailOverhangLeft: PropTypes.bool,
  showRailOverhangRight: PropTypes.bool,
  showRailOverhangTop: PropTypes.bool,
  showRailOverhangBottom: PropTypes.bool,
  projectType: PropTypes.string,
};

GridCell.defaultProps = {
  marqueeHover: false,
  isRailOrientationNs: false,
  showRails: false,
  thirdRailUsed: false,
  arrayEditingAllowed: false,
};

export default React.memo(GridCell);
