import { Controller } from "@hotwired/stimulus";

import { GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT, storeEditorMode } from "../../helpers/graphical-tool-helpers";
import { critterGuardRemovesContourAlert } from "./helper";

export default class extends Controller {
  static values = {
    roofSectionId: Number,
  };

  static targets = ["useCritterGuardYes", "useCritterGuardNo"];

  changeUseCritterGuard(_event) {
    const yesRadio = this.useCritterGuardYesTarget;

    if (!yesRadio.checked) return;

    critterGuardRemovesContourAlert();

    storeEditorMode({
      roofSectionId: this.roofSectionIdValue,
      editorMode: GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT,
    });
  }
}
