import { Controller } from "@hotwired/stimulus";
import * as animate from "../../../../helpers/animate";

export default class PrSpansEditorSpanLimitsController extends Controller {
  static targets = [
    "useMaxAllowedSpanChoiceYes",
    "useMaxAllowedSpanChoiceNo",
    "maxAllowedSpanFieldContainer",
    "spanLimitsField",
    "smallerThanRafterSpacingMessage",
    "smallestRafterSpacingMessage",
  ];

  static values = {
    isDeckAttached: Boolean,
    smallestRafterSpacingFt: Number,
  };

  useMaxAllowedSpanChoiceChange(_event) {
    const choiceIsYes = this.useMaxAllowedSpanChoiceYesTarget.checked;
    const showHide = choiceIsYes ? "show" : "hide";
    animate[showHide](this.maxAllowedSpanFieldContainerTarget);

    if (choiceIsYes) this.maxAllowedSpanFieldContainerTarget.querySelector("input[type='text']").focus();
  }

  checkIfValueIsLessThanRafterSpacing(event) {
    if (this.isDeckAttachedValue) return;

    const maxSpanLimitValue = event.currentTarget.value;
    if (maxSpanLimitValue < this.smallestRafterSpacingFtValue) {
      animate.show(this.smallerThanRafterSpacingMessageTarget);
      animate.hide(this.smallestRafterSpacingMessageTarget, { fadeOut: false });
    } else {
      animate.show(this.smallestRafterSpacingMessageTarget);
      animate.hide(this.smallerThanRafterSpacingMessageTarget, { fadeOut: false });
    }
  }
}
