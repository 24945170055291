import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Sometimes the submission of a form causes page animations to stop.  Having a delay allows the animations to
    // at least get a chance to show briefly so the user gets the "loading" experience.
    //
    // Note that this does not work as a Stimulus value.  It looks like the value does not load before the connect()
    // method is called.
    const delayMilliseconds = this.element.dataset.delayMilliseconds
      ? parseInt(this.element.dataset.delayMilliseconds)
      : 500;

    setTimeout(() => {
      this.element.requestSubmit();
    }, delayMilliseconds);
  }
}
