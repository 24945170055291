import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";

import {
  CELL_DRAW_STYLE_EMPTY,
  CELL_DRAW_STYLE_OBSTRUCTED_OUT_OF_BOUNDS,
  CELL_DRAW_STYLE_OUT_OF_BOUNDS,
} from "../../models/layout-model";

import CellPositioner from "./cell-positioner";

import { benchmark, logger } from "../../../helpers/app";
import { ARRAY_CELL_DATA_TYPE } from "../../../da/map/data-types";

export default class ArrayBuilder {
  constructor(project, roofSection) {
    this.project = project;
    this.roofSection = roofSection;

    this.cellPositioner = new CellPositioner(this.project, this.roofSection);
    this.features = [];
  }

  build() {
    const rows = this.cellPositioner.rows;
    const columns = this.cellPositioner.columns;
    const layout = this.roofSection.layout;
    if (!layout) {
      // This typically happens if the roof section is so small that rows or columns < 1
      return;
    }

    const roofSectionUuid = this.roofSection.uuid;
    const layoutFresh = layout.fresh;

    const maxRow = layout.rows;
    const maxColumn = layout.columns;

    benchmark("array build", () => {
      let resizeMessage = "";
      if (rows !== layout.rows || columns !== layout.columns) {
        resizeMessage = ` (old layout ${layout.rows}x${layout.columns})`;
      }
      logger(`Building Array for ${this.roofSection.displayIdentifier}: ${rows}x${columns}${resizeMessage}`);

      for (let row = 1; row <= rows; row++) {
        for (let column = 1; column <= columns; column++) {
          let cellDrawStyle;
          if (row > maxRow || column > maxColumn) {
            cellDrawStyle = CELL_DRAW_STYLE_EMPTY;
          } else {
            cellDrawStyle = layout.cellDrawStyle(row, column);
          }

          if (
            cellDrawStyle === CELL_DRAW_STYLE_OUT_OF_BOUNDS ||
            cellDrawStyle === CELL_DRAW_STYLE_OBSTRUCTED_OUT_OF_BOUNDS
          )
            continue;

          const cell = this.cellPositioner.cellAt(row, column);
          const rectangleCoordinatesInOpenLayersCoordinates = cell.map((vertex) => fromLonLat(vertex.toLonLat));

          const rectangle = new Polygon([rectangleCoordinatesInOpenLayersCoordinates]);
          const rectangleFeature = new Feature({ geometry: rectangle });
          rectangleFeature.set("cellDrawStyle", cellDrawStyle);
          rectangleFeature.set("roofSectionUuid", roofSectionUuid);
          rectangleFeature.set("row", row);
          rectangleFeature.set("column", column);
          rectangleFeature.set("dataType", ARRAY_CELL_DATA_TYPE);
          rectangleFeature.set("fresh", layoutFresh);

          this.features.push(rectangleFeature);
        }
      }
    });
  }
}
