import {
  simplifiedRailGroup,
  allPanelsBySegmentUuid,
  removeSegmentPanelsFromSelectInteraction,
  selectedPanelsBySegmentUuid,
  railGroupConsolidatedGrid,
} from "./helpers";

import {
  clearSegmentFeatures,
  getSegmentFeaturesForSegments,
  getRailedGroupIncludingSegmentWithUuid,
  removeSegmentFromSelectInteraction,
  reRenderSegment,
} from "../modification-helpers/segments/helpers";
import { ZONES_MODULE_POSITIONS } from "../../helpers/zones-and-module-positions";
import { clearOutAnyIllegalThermalExpansions } from "../modification-helpers/panels/helpers";
import {
  thermalExpansionDistanceToRailedParentStart,
  thermalExpansionsSortedByDistanceToRailedParentStart,
} from "../modification-helpers/thermal-expansions/helpers";

import { sentryException } from "../../../config/sentry";

export const RAIL_OVERHANG_LENGTH = 1;

export default class SegmentsRailGroup {
  static uniqueGroupsFromSegmentFeatures({ controller, features }) {
    const uniqueParentUuids = [
      ...new Set(
        features.map((segmentFeature) => {
          const segment = segmentFeature.get("model");
          return segment.railed ? segment.uuid : segment.railedParentUuid;
        }),
      ),
    ];

    const railGroups = uniqueParentUuids.map((uuid) => {
      return SegmentsRailGroup.fromSegmentUuid({ controller: controller, uuid });
    });

    return railGroups;
  }

  static uniqueGroupsFromPanelFeatures({ controller, features }) {
    const uniqueSegmentUuids = [...new Set(features.map((pf) => pf.get("segmentUuid")))];
    const segmentFeatures = controller.mapManager.segmentsVectorSource
      .getFeatures()
      .filter((sf) => uniqueSegmentUuids.includes(sf.get("uuid")));
    return SegmentsRailGroup.uniqueGroupsFromSegmentFeatures({ controller, features: segmentFeatures });
  }

  static fromSegmentUuid({ controller, uuid }) {
    const segments = getRailedGroupIncludingSegmentWithUuid({ controller, uuid });
    const segmentFeatures = getSegmentFeaturesForSegments({ controller, segments });

    return new SegmentsRailGroup({ controller, segments, segmentFeatures });
  }

  static stringifiedZonesAndModulePositionsFromConsolidatedGrid(consolidatedGrid, columns, columnOffset) {
    const results = [];
    consolidatedGrid.forEach((row) => {
      results.push(row.slice(columnOffset, columnOffset + columns));
    });

    return SegmentsRailGroup.stringifyZonesAndModulePositionsFromGrid(results);
  }

  static stringifyZonesAndModulePositionsFromGrid(grid) {
    const stringResults = grid
      .map((row) =>
        row
          .map((zp) => {
            return ZONES_MODULE_POSITIONS.findIndex((n) => n === zp);
          })
          .join(","),
      )
      .join(",");

    return stringResults;
  }

  static stringifiedContourCodesFromConsolidatedGrid(consolidatedGrid, columns, columnOffset) {
    const results = [];
    consolidatedGrid.forEach((row) => {
      results.push(row.slice(columnOffset, columnOffset + columns));
    });

    return SegmentsRailGroup.stringifyContourCodesFromGrid(results);
  }

  static stringifyContourCodesFromGrid(grid) {
    return grid.flatMap((row) => row).join("");
  }

  constructor({ controller, segments, segmentFeatures }) {
    this.controller = controller;
    this.project = controller.project;
    this.segments = segments;
    this.segmentFeatures = segmentFeatures;

    this.selectInteractionManager = controller.selectInteractionManager;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.roofSection = segments[0].roofSection;
    this.panelOrientation = segments[0].orientation;

    this.consolidatedSegmentZonesAndModulePositionsGrid = this.createConsolidatedZonesAndModulePositionsGrid();
    this.consolidatedContourCodesGrid = this.createConsolidatedContourCodesGrid();
  }

  createConsolidatedZonesAndModulePositionsGrid() {
    const grid = Array(this.segments[0].rows).fill([]);
    this.segments.forEach((segment) => {
      segment.zonesAndModulePositionsGrid.forEach((row, i) => {
        grid[i] = [...grid[i], ...row];
      });
    });
    return grid;
  }

  createConsolidatedContourCodesGrid() {
    const grid = Array(this.segments[0].rows).fill([]);
    this.segments.forEach((segment) => {
      segment.contourCodesGrid.forEach((row, i) => {
        try {
          grid[i] = [...grid[i], ...row];
        } catch (e) {
          const message = "Issue creating consolidated Contour codes grid";
          const extras = {
            error: e,
            gridRow: `grid[${i}]: ${JSON.stringify(grid[i])}`,
            row: JSON.stringify(row),
            contourCodes: segment.contourCodes,
          };
          sentryException(message, extras);
          throw e;
        }
      });
    });
    return grid;
  }

  removeIllegalPanelContour() {
    this.segments.forEach((segment) => {
      segment.removeIllegalPanelContour();
    });
  }

  reRenderSegments() {
    this.segments.forEach((segment, i) => {
      const newSegmentFeature = reRenderSegment(this.controller, segment);
      this.segmentFeatures[i] = newSegmentFeature;
    });
  }

  reRenderSegmentsWithSegmentSelections(selectedSegmentsCollection) {
    this.segments.forEach((segment, i) => {
      const currentSegmentFeature = this.segmentFeatures[i];
      const newSegmentFeature = reRenderSegment(this.controller, segment);
      this.segmentFeatures[i] = newSegmentFeature;

      const isSelected = selectedSegmentsCollection
        .getArray()
        .find((sf) => sf.get("uuid") === currentSegmentFeature.get("uuid"));
      if (!isSelected) return;

      selectedSegmentsCollection.push(newSegmentFeature);
      selectedSegmentsCollection.remove(currentSegmentFeature);
    });
  }

  reRenderSegmentsWithPanelSelections(selectedPanelsCollection) {
    const simplifiedConsolidatedGrid = this.consolidatedSimplifiedGrid;

    const segmentUuids = this.segmentUuids;
    const removals = [];
    selectedPanelsCollection.getArray().forEach((pf) => {
      if (segmentUuids.includes(pf.get("segmentUuid"))) {
        removals.push(pf);
      }
    });
    removals.forEach((pf) => selectedPanelsCollection.remove(pf));

    this.segments.forEach((segment) => {
      reRenderSegment(this.controller, segment);
    });

    const consolidatedGrid = this.consolidatedPanelsGrid;

    simplifiedConsolidatedGrid.forEach((row, i) => {
      row.forEach((panelSelectionStatus, j) => {
        if (panelSelectionStatus !== "X") return;

        const panelFeature = consolidatedGrid[i][j];
        selectedPanelsCollection.push(panelFeature);
      });
    });
  }

  deleteSegments() {
    this.segments.forEach((segment) => this.deleteSegment(segment));
  }

  deleteSegment(segment, { clearIllegalThermalExpansions } = { clearIllegalThermalExpansions: true }) {
    clearSegmentFeatures(this.controller, segment);
    removeSegmentPanelsFromSelectInteraction(this.controller, segment.uuid);
    removeSegmentFromSelectInteraction(this.controller, segment.uuid);
    const notDeletedSegment = this.segments.find((s) => s.uuid !== segment.uuid);

    this.roofSection.deleteSegment(segment);

    if (notDeletedSegment) {
      this.refreshFromSegmentUuid(notDeletedSegment.uuid);
      this.updateDistancesFromRailedParentStart();
      if (clearIllegalThermalExpansions) {
        clearOutAnyIllegalThermalExpansions(this.railedParentSegment, this.mapModelSynchronizer);
      }
    } else {
      this.clearSegments();
    }
  }

  clearSegments() {
    this.segments = [];
  }

  addSegment(segment) {
    this.segments.push(segment);
  }

  refreshFromSegmentUuid(uuid) {
    const { controller } = this;

    this.segments = getRailedGroupIncludingSegmentWithUuid({ controller, uuid });
    this.segmentFeatures = getSegmentFeaturesForSegments({ controller, segments: this.segments });
  }

  // This produces a simplified multidimensional array or grid of the rail group segments and panels where
  // the panels are represented by "X" for selected, or "0" for not selected.  So if you had a rail group
  // that looked like this (2 railed segments with 2 rows and the first column of the first segment selected):
  //
  // "X" = Selected
  // "0" = Not selected
  // "| 0 |"" = Panel
  // "=" = Rail
  //
  // | X | 0 | = | 0 | 0 |
  // | X | 0 | = | 0 | 0 |
  //
  // Then this would output:
  // [
  //   [
  //     ["X", "0"],
  //     ["0", "0"],
  //   ],
  //   [
  //     ["X", "0"],
  //     ["0", "0"],
  //   ],
  // ]
  get simplifiedGrid() {
    const rows = this.segments[0].rows;
    const selectedPanelUuids = this.selectedPanelUuids;

    return Array.from({ length: rows }).map((_, i) => {
      return this.segmentFeatures.map((segmentFeature) => {
        const segment = segmentFeature.get("model");
        return Array.from({ length: segment.columns }).map((_, j) => {
          const panelFeature = this.getPanelFeatureForSegment({ segmentFeature, row: i + 1, column: j + 1 });
          return selectedPanelUuids.includes(panelFeature.get("uuid")) ? "X" : "O";
        });
      });
    });
  }

  getPanelFeatureForSegment({ segmentFeature, row, column }) {
    return this.segmentPanelFeatures(segmentFeature).find((pf) => {
      const panel = pf.get("model");
      return panel.row === row && panel.column === column;
    });
  }

  updateDistancesFromRailedParentStart() {
    this.segments.forEach((segment) => {
      if (segment.railed || segment.deleted) return;

      const distanceFromRailedParentStart = this.mapModelSynchronizer.getDistanceInInches(
        segment.railedParent.startLatLng,
        segment.startLatLng,
      );
      segment.setDistanceFromRailedParentStart(distanceFromRailedParentStart);
    });
  }

  get consolidatedSimplifiedGrid() {
    const railGroup = simplifiedRailGroup({
      segmentFeatures: this.segmentFeatures,
      allPanelsBySegmentUuid: allPanelsBySegmentUuid(this.controller.mapManager),
      selectedPanelsBySegmentUuid: selectedPanelsBySegmentUuid(this.selectInteractionManager),
    });
    return railGroup.map((railGroupRow) => railGroupRow.flatMap((segment) => segment));
  }

  clearPanelSelections() {
    const selectInteractionManager = this.selectInteractionManager;
    const selectedPanels = selectInteractionManager.selectedFeatures;
    const removals = [];
    const segmentUuids = this.segments.map((s) => s.uuid);
    selectedPanels.forEach((panelFeature) => {
      if (segmentUuids.includes(panelFeature.get("segmentUuid"))) removals.push(panelFeature);
    });
    removals.forEach((pf) => {
      selectInteractionManager.selectionCollection.remove(pf);
    });
  }

  get consolidatedPanelsGrid() {
    const panelsGrid = railGroupConsolidatedGrid(
      this.segmentFeatures,
      allPanelsBySegmentUuid(this.controller.mapManager),
    );
    return panelsGrid;
  }

  get consolidatedPanelsGridStartLatLngs() {
    return this.consolidatedPanelsGrid.map((consolidatedPanelsGridRow) => {
      return consolidatedPanelsGridRow.map((pf) => {
        const panel = pf.get("model");
        const { lat, lng } = panel.startLatLng;
        return { lat, lng };
      });
    });
  }

  hasFeature(segmentFeature) {
    return this.segmentFeatures.find((sf) => sf.get("uuid") === segmentFeature.get("uuid"));
  }

  get selectedSegmentFeatures() {
    return this.segmentFeatures.filter((sf) => this.selectedSegmentUuidsForAllRailGroups.includes(sf.get("uuid")));
  }

  get selectedSegments() {
    return this.selectedSegmentFeatures.map((sf) => sf.get("model"));
  }

  get selectedSegmentUuidsForAllRailGroups() {
    return this.selectInteractionManager.selectedFeatures.map((f) => f.get("uuid"));
  }

  get minSelectedSegmentFeatureIndex() {
    return this.#maxMinSelectedSegmentFeatureIndex("min");
  }

  get minSelectedSegmentFeature() {
    return this.segmentFeatures[this.minSelectedSegmentFeatureIndex];
  }

  get maxSelectedSegmentFeature() {
    return this.segmentFeatures[this.maxSelectedSegmentFeatureIndex];
  }

  get maxSelectedSegmentFeatureIndex() {
    return this.#maxMinSelectedSegmentFeatureIndex("max");
  }

  segmentFeaturesUpToAndIncluding(index) {
    return this.segmentFeatures.slice(0, index + 1);
  }

  segmentFeaturesAfterAndIncluding(index) {
    return this.segmentFeatures.slice(index, this.segmentFeatures.length);
  }

  thermalExpansionsWithDistanceToRailedParent(comparisonSegment, { distanceType }) {
    const { thermalExpansions, startLatLng } = this.railedParentSegment;

    if (thermalExpansions.length === 0) return [];

    const comparisonDistance = comparisonSegment.distanceFromRailedParentStart;

    return thermalExpansions.filter((thermalExpansion) => {
      const distance = this.mapModelSynchronizer.getDistanceInInches(startLatLng, thermalExpansion.startLatLng);
      if (distanceType === "closer") {
        return distance < comparisonDistance;
      } else {
        return distance > comparisonDistance;
      }
    });
  }

  get segmentUuids() {
    return this.segments.map((s) => s.uuid);
  }

  get selectedPanelFeatures() {
    return this.selectInteractionManager.selectedFeatures.filter((pf) =>
      this.segmentUuids.includes(pf.get("segmentUuid")),
    );
  }

  get selectedPanelUuids() {
    return this.selectedPanelFeatures.map((pf) => pf.get("uuid"));
  }

  get hasPanelsSelectedInMultipleSegments() {
    return [...new Set(this.selectedPanelFeatures.map((pf) => pf.get("segmentUuid")))].length > 1;
  }

  get panelFeatures() {
    const segmentUuids = this.segmentUuids;
    return this.allPanelFeaturesOnMap.filter((pf) => segmentUuids.includes(pf.get("segmentUuid")));
  }

  get allPanelFeaturesOnMap() {
    return this.controller.mapManager.panelsVectorSource.getFeatures();
  }

  get railFeatures() {
    const segmentUuid = this.railedParentSegment.uuid;
    return this.controller.mapManager.railsVectorSource
      .getFeatures()
      .filter((f) => f.get("segmentUuid") === segmentUuid);
  }

  selectRailFeatures() {
    this.railFeatures.forEach((f) => {
      f.set("selected", true);
    });
  }

  get railedParentSegment() {
    return this.segments.find((s) => s.railed);
  }

  get lastSegment() {
    return this.segments[this.segments.length - 1];
  }

  get thermalExpansions() {
    return this.railedParentSegment.thermalExpansions;
  }

  segmentFeatureForPanel(panelFeature) {
    const segmentUuid = panelFeature.get("segmentUuid");
    return this.segmentFeatures.find((sf) => sf.get("uuid") === segmentUuid);
  }

  segmentPanelFeatures(segmentFeature) {
    return this.allPanelFeaturesOnMap.filter((pf) => pf.get("segmentUuid") === segmentFeature.get("uuid"));
  }

  segmentPanelFeaturesForUuid(uuid) {
    return this.allPanelFeaturesOnMap.filter((pf) => pf.get("segmentUuid") === uuid);
  }

  selectedSegmentPanelFeatures(segmentFeature) {
    const segmentPanelFeatures = this.segmentPanelFeatures(segmentFeature);
    const selectedPanelUuids = this.selectedPanelFeatures.map((pf) => pf.get("uuid"));
    const features = segmentPanelFeatures.filter((pf) => selectedPanelUuids.includes(pf.get("uuid")));
    return features;
  }

  selectPanelsFromSimplifiedGrid(simplifiedGrid, selectedPanelsCollection) {
    simplifiedGrid.forEach((simplifiedGridRow, i) => {
      simplifiedGridRow.forEach((segmentRow, j) => {
        const segmentUuid = this.segments[j].uuid;
        // TODO: Revisit and see if this is a performance problem
        const segmentPanelFeatures = this.segmentPanelFeaturesForUuid(segmentUuid);

        segmentRow.forEach((panelSelectionStatus, k) => {
          if (panelSelectionStatus === "O") return;

          const row = i + 1;
          const column = k + 1;
          const panelFeature = segmentPanelFeatures.find((pf) => {
            const panel = pf.get("model");
            return panel.row === row && panel.column === column;
          });

          selectedPanelsCollection.push(panelFeature);
        });
      });
    });
  }

  get hasAnyPanelSelections() {
    return this.consolidatedSimplifiedGrid.some((row) => row.includes("X"));
  }

  segmentHasPanelSelections(segment) {
    return this.selectInteractionManager.selectedFeatures.some((pf) => segment.uuid === pf.get("segmentUuid"));
  }

  get contiguousRailLengths() {
    const { railedParentSegment, lastSegment } = this;

    if (!railedParentSegment.hasThermalExpansions) {
      return [lastSegment.endDistanceFromRailedParentStart + 2 * RAIL_OVERHANG_LENGTH];
    }

    const thermalExpansions = thermalExpansionsSortedByDistanceToRailedParentStart(
      railedParentSegment.thermalExpansions.slice(),
      "asc",
    );

    const numberOfRailSpans = thermalExpansions.length + 1;

    const railSpans = [];
    const endClampLength = 1;
    const thermalExpansionGap = 1;
    for (let i = 0; i < numberOfRailSpans; i++) {
      let railSpan;
      // First rail span
      if (i === 0) {
        railSpan =
          RAIL_OVERHANG_LENGTH + thermalExpansionDistanceToRailedParentStart(thermalExpansions[i]) + endClampLength;
        railSpans.push(railSpan);
        continue;
      }

      // Last rail span
      if (i === numberOfRailSpans - 1) {
        railSpan =
          lastSegment.endDistanceFromRailedParentStart -
          (thermalExpansionDistanceToRailedParentStart(thermalExpansions[i - 1]) +
            endClampLength +
            thermalExpansionGap) +
          RAIL_OVERHANG_LENGTH;
        railSpans.push(railSpan);
        continue;
      }

      // Rail spans in between two thermal expansions
      railSpan =
        RAIL_OVERHANG_LENGTH +
        thermalExpansionDistanceToRailedParentStart(thermalExpansions[i]) +
        endClampLength -
        (thermalExpansionDistanceToRailedParentStart(thermalExpansions[i - 1]) + endClampLength + thermalExpansionGap);
      railSpans.push(railSpan);
    }

    return railSpans;
  }

  get needsThermalExpansions() {
    return this.contiguousRailLengths.some((railLength) => railLength >= this.project.maxLengthBeforeThermalExpansion);
  }

  #maxMinSelectedSegmentFeatureIndex(maxMin) {
    if (!["max", "min"].includes(maxMin)) throw "Must call with `max` or `min`";

    const selectedIndexes = [];
    this.segmentFeatures.forEach((sf, i) => {
      if (this.selectInteractionManager.isSegmentSelected(sf)) selectedIndexes.push(i);
    });

    return Math[maxMin](...selectedIndexes);
  }
}
