import { v4 as uuid } from "uuid";
import { types, getParent } from "mobx-state-tree";

import CartesianModel from "../../da/models/cartesian-model";
import LatLngModel from "../../da/models/lat-lng-model";
import WithDirtyTracking from "../../da/models/with-dirty-tracking";
import PanelModel, {
  DEFAULT_ZONE_MODULE_POSITION,
  MODULE_POSITION_N,
  ZONE_1,
  ZONES_MODULE_POSITIONS,
} from "./panel-model";
import ThermalExpansionModel from "./thermal-expansion-model";
import AdjoinmentModel from "./adjoinment-model";
import { contourCodeGenerator, CONTOUR_CODE_NONE } from "../../helpers/contour";
import ContourModel from "./contour-model";

export const ATTACHMENT_PLAN_STATUS_LEGAL = "legal";
export const ATTACHMENT_PLAN_STATUS_CANTILEVER_PROBLEM = "cantilever";
export const ATTACHMENT_PLAN_STATUS_SPAN_PROBLEM = "span";

const SegmentModelBase = types
  .model("SegmentModel", {
    id: types.maybe(types.integer),
    uuid: types.identifier,
    rows: types.integer,
    columns: types.integer,
    railed: types.boolean,
    startLatLng: LatLngModel,
    startCartesianPoint: types.maybeNull(CartesianModel),
    orientation: types.string,
    rowDirection: types.integer,
    columnDirection: types.integer,
    illegalShape: types.boolean,
    deleted: types.optional(types.boolean, false),
    temporary: types.optional(types.boolean, false),
    railedParentUuid: types.maybeNull(types.string),
    distanceFromRailedParentStart: types.maybeNull(types.number),
    display: types.optional(types.boolean, true),
    panels: types.array(PanelModel),
    zonesAndModulePositions: types.optional(types.string, ""),
    zonesAndModulePositionsPersisted: types.optional(types.boolean, false),
    // this is either an empty array [] for an unrailed segment, or
    // a nested structure like:
    // [
    //    [ // row 1
    //      [1, 3, 5] // north rail of row 1
    //      [2, 4, 6] // south rail of row 1
    //    ],
    //    [ ... ] // row 2, etc
    // ]
    attachmentPositions: types.array(types.array(types.array(types.integer))),
    attachmentPlanStatus: types.optional(types.string, ATTACHMENT_PLAN_STATUS_LEGAL),
    thermalExpansions: types.array(ThermalExpansionModel),
    thermalExpansionsStartLatLngs: types.array(LatLngModel),
    thermalExpansionsStartCartesianPoints: types.array(CartesianModel),
    adjoinments: types.array(AdjoinmentModel),
    contourLegalityAdjoinments: types.array(AdjoinmentModel),
    contourCodes: types.optional(types.string, ""),
    contours: types.array(ContourModel),
    thermalExpansionGapExceedingBondingJumperLimit: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get roofSection() {
      try {
        return getParent(self, 2);
      } catch (e) {
        if (e.message.includes("Failed to find the parent of AnonymousModel")) {
          return undefined;
        } else {
          throw e;
        }
      }
    },
    get panelsGrid() {
      const result = [];
      for (let i = 0; i < self.rows; i++) {
        const row = [];
        for (let j = 0; j < self.columns; j++) {
          const panel = self.panels.find((p) => p.row === i + 1 && p.column === j + 1);
          row.push(panel);
        }
        result.push(row);
      }
      return result;
    },
    get panelsOnSideTop() {
      return this.panelsGrid[0];
    },
    get panelsOnSideRight() {
      return this.panelsGrid.map((row) => row[row.length - 1]);
    },
    get panelsOnSideBottom() {
      return this.panelsGrid[this.panelsGrid.length - 1];
    },
    get panelsOnSideLeft() {
      return this.panelsGrid.map((row) => row[0]);
    },
    get needsSave() {
      return (
        self.dirty ||
        !self.id ||
        self.panels.some((p) => p.dirty) ||
        self.thermalExpansions.some((te) => te.dirty) ||
        self.adjoinments.some((a) => a.dirty) ||
        self.contourLegalityAdjoinments.some((a) => a.dirty)
      );
    },
    get isEmpty() {
      return self.rows === 0 || self.columns === 0;
    },
    get railedParent() {
      if (self.railed) return undefined;

      return self.roofSection.segments.find((s) => s.uuid === self.railedParentUuid);
    },
    get unrailedChildren() {
      if (!self.railed) return [];
      if (self.roofSection === undefined) return [];

      return self.roofSection.segments
        .filter((s) => !s.railed && s.railedParentUuid === self.uuid && !s.deleted)
        .sort((a, b) => {
          return a.distanceFromRailedParentStart - b.distanceFromRailedParentStart;
        });
    },
    get hasUnrailedChildren() {
      if (!self.railed) return false;

      return self.unrailedChildren.length > 0;
    },
    get displayable() {
      return !self.deleted;
    },
    get railedGroup() {
      if (self.railed) {
        return [self, ...self.unrailedChildren];
      } else {
        return self.railedParent.railedGroup;
      }
    },
    get railGroupIndex() {
      return self.railedGroup.indexOf(self);
    },
    get railGroupSegmentUuids() {
      return self.railedGroup.map((s) => s.uuid);
    },
    get previousRailGroupSegment() {
      const railedGroup = self.railedGroup;
      const groupIndex = railedGroup.indexOf(self);
      if (groupIndex === 0) return undefined;

      return railedGroup[groupIndex - 1];
    },
    get nextRailGroupSegment() {
      const railedGroup = self.railedGroup;
      const groupIndex = railedGroup.indexOf(self);
      if (groupIndex === railedGroup.length - 1) return undefined;

      return railedGroup[groupIndex + 1];
    },
    get isLastInRailGroup() {
      const railedGroup = self.railedGroup;
      return railedGroup.indexOf(self) === railedGroup.length - 1;
    },
    get isOnTheOutsideOfRailGroup() {
      return self.railed || self.isLastInRailGroup;
    },
    get isAtStartOfRailGroup() {
      return self.railGroupIndex === 0;
    },
    get isAtEndOfRailGroup() {
      return self.railGroupIndex === self.railedGroup.length - 1;
    },
    get width() {
      if (self.spacedPanelSizeInColumnDirection === undefined) return undefined;

      return self.columns * self.spacedPanelSizeInColumnDirection - self.project.interColumnSpacing;
    },
    get height() {
      return self.rows * self.slopeAdjustedSpacedPanelSizeInRowDirection - self.roofPlane.slopeAdjustedInterRowSpacing;
    },
    get roofPlane() {
      return self.roofSection.roofPlane;
    },
    get project() {
      return self.roofPlane.project;
    },
    get panelLengthAlongEave() {
      return self.roofPlane.panelLengthAlongEave(self.orientation);
    },
    get panelLengthPerpendicularToEave() {
      return self.roofPlane.panelLengthPerpendicularToEave(self.orientation);
    },
    get unitRowVectorLatLng() {
      return self.roofPlane.eavePerpendicularEdgeVectorLatLng.times(self.rowDirection);
    },
    get unitColumnVectorLatLng() {
      return self.roofPlane.eaveEdgeVectorLatLng.times(self.columnDirection);
    },
    get slopeAdjustedSpacedPanelSizeInRowDirection() {
      return self.roofPlane.slopeAdjustedSpacedPanelSizeInRowDirection(self.orientation);
    },
    get spacedPanelSizeInColumnDirection() {
      return self.roofPlane.spacedPanelSizeInColumnDirection(self.orientation);
    },
    get latLngPoints() {
      const rowOffset = self.unitRowVectorLatLng.times(
        self.rows * self.slopeAdjustedSpacedPanelSizeInRowDirection - self.roofPlane.slopeAdjustedInterRowSpacing,
      );
      const columnOffset = self.unitColumnVectorLatLng.times(
        self.columns * self.spacedPanelSizeInColumnDirection - self.project.interColumnSpacing,
      );

      const firstPoint = self.startLatLng;
      const secondPoint = firstPoint.plus(columnOffset);
      const thirdPoint = secondPoint.plus(rowOffset);
      const fourthPoint = firstPoint.plus(rowOffset);

      const latLngPoints = [firstPoint, secondPoint, thirdPoint, fourthPoint, firstPoint];
      return latLngPoints;
    },
    panelModulePosition(row, column) {
      const rowOffset = self.unitRowVectorLatLng.times((row - 1) * self.slopeAdjustedSpacedPanelSizeInRowDirection);
      const columnOffset = self.unitColumnVectorLatLng.times((column - 1) * self.spacedPanelSizeInColumnDirection);
      const position = self.startLatLng.plus(rowOffset).plus(columnOffset);
      return position;
    },
    get panelDrawingPerpendicularToEave() {
      return self.unitRowVectorLatLng.times(self.panelLengthPerpendicularToEave);
    },
    get panelDrawingParallelToEave() {
      return self.unitColumnVectorLatLng.times(self.panelLengthAlongEave);
    },
    panelLatLngPoints(row, column) {
      const startLatLng = self.panelModulePosition(row, column);
      const p2 = startLatLng.plus(self.panelDrawingParallelToEave);
      const p3 = p2.plus(self.panelDrawingPerpendicularToEave);
      const p4 = startLatLng.plus(self.panelDrawingPerpendicularToEave);
      const latLngPoints = [startLatLng, p2, p3, p4, startLatLng];
      return latLngPoints;
    },
    get buildZonesAndModulePositions() {
      return this.panels.map((panel) => panel.zoneModulePosition).join(",");
    },
    get zonesAndModulePositionsGrid() {
      if (self.zonesAndModulePositions === "") return self.defaultZonesAndModulePositionsGrid;

      const result = [];
      let currentRow = [];
      self.zonesAndModulePositions.split(",").forEach((zoneModulePositionIndexString, i) => {
        if (i > 0 && i % self.columns === 0) {
          result.push(currentRow);
          currentRow = [];
        }
        currentRow.push(ZONES_MODULE_POSITIONS[Number.parseInt(zoneModulePositionIndexString)]);
      });
      result.push(currentRow);

      return result;
    },
    get defaultZonesAndModulePositionsGrid() {
      const singleRow = Array(self.columns).fill(DEFAULT_ZONE_MODULE_POSITION);
      return Array(self.rows).fill([...singleRow]);
    },
    get notDeleted() {
      return !self.deleted;
    },
    get panelsCount() {
      return self.rows * self.columns;
    },
    get isAttachmentPlanStatusLegal() {
      return self.attachmentPlanStatus === ATTACHMENT_PLAN_STATUS_LEGAL;
    },
    get thermalExpansionsStartLatLngsString() {
      return `[${self.thermalExpansions
        .map((thermalExpansion) => thermalExpansion.startLatLng.toLatLngString)
        .join(",")}]`;
    },
    get thermalExpansionsStartCartesianPointsString() {
      return `[${self.thermalExpansions
        .map((thermalExpansion) => thermalExpansion.startCartesianPoint.toString)
        .join(",")}]`;
    },
    get hasThermalExpansions() {
      return this.thermalExpansions.length > 0;
    },
    get adjoinmentsLatLngsString() {
      return `[${self.adjoinments.flatMap((a) => a.latLngPoints.map((ll) => ll.toLatLngString)).join(",")}]`;
    },
    get adjoinmentsCartesianPointsString() {
      return `[${self.adjoinments.flatMap((a) => a.cartesianPoints.map((cp) => cp.toString)).join(",")}]`;
    },
    get adjoinmentsDetailsString() {
      return `[${self.adjoinments.map((a) => JSON.stringify([a.adjoinmentSide, a.adjoiningSegmentUuid])).join(",")}]`;
    },
    adjoinmentsOn(side) {
      return self.adjoinments.filter((a) => a.adjoinmentSide === side);
    },
    get contourLegalityAdjoinmentsLatLngsString() {
      return `[${self.contourLegalityAdjoinments
        .flatMap((a) => a.latLngPoints.map((ll) => ll.toLatLngString))
        .join(",")}]`;
    },
    get contourLegalityAdjoinmentsCartesianPointsString() {
      return `[${self.contourLegalityAdjoinments
        .flatMap((a) => a.cartesianPoints.map((cp) => cp.toString))
        .join(",")}]`;
    },
    get contourLegalityAdjoinmentsDetailsString() {
      return `[${self.contourLegalityAdjoinments
        .map((a) => JSON.stringify([a.adjoinmentSide, a.adjoiningSegmentUuid]))
        .join(",")}]`;
    },
    contourLegalityAdjoinmentsOn(side) {
      return self.contourLegalityAdjoinments.filter((a) => a.adjoinmentSide === side);
    },
    get endDistanceFromRailedParentStart() {
      return this.distanceFromRailedParentStart + this.width;
    },
    get contourCodesString() {
      if (self.panels.length === 0 && self.contours.length > 0) {
        return self.contourCodes;
      } else {
        return self.panels.map((panel) => panel.contourCode).join("");
      }
    },
    get contourCodesGrid() {
      if (self.contourCodes === "") return self.defaultContourCodesGrid;

      const result = [];
      let currentRow = [];
      self.contourCodes.split("").forEach((panelContourCode, i) => {
        if (i > 0 && i % self.columns === 0) {
          result.push(currentRow);
          currentRow = [];
        }
        currentRow.push(panelContourCode);
      });
      result.push(currentRow);

      return result;
    },
    get defaultContourCodesGrid() {
      const singleRow = Array(self.columns).fill(CONTOUR_CODE_NONE);
      return Array(self.rows).fill([...singleRow]);
    },
    get contoursLatLngsString() {
      return `[${self.contours.flatMap((c) => c.latLngPoints.map((ll) => ll.toLatLngString)).join(",")}]`;
    },
    get contoursCartesianPointsString() {
      return `[${self.contours.flatMap((c) => c.cartesianPoints.map((cp) => cp.toString)).join(",")}]`;
    },
    get contourDetailsString() {
      return `[${self.contours.map((c) => JSON.stringify([c.side, c.segmentUuid])).join(",")}]`;
    },
    get hasContourCodesAndDetailsMismatch() {
      return self.contoursCodeString === "" && self.contourDetailsString !== "";
    },
  }))
  .actions((self) => ({
    setIllegalShape(isIllegal) {
      if (self.illegalShape !== isIllegal) {
        self.markDirty();
      }
      self.illegalShape = isIllegal;
    },
    flagDeleted() {
      if (!self.deleted) {
        self.markDirty();
      }
      self.deleted = true;
    },
    setStartLatLng(newStartLatLng) {
      if (self.startLatLng.lat === newStartLatLng.lat && self.startLatLng.lng === newStartLatLng.lng) {
        return;
      }
      self.startLatLng = newStartLatLng;
      self.markDirty();
    },
    setStartCartesianPoint(newStartCartesianPoint) {
      if (
        self.startCartesianPoint &&
        self.startCartesianPoint.x === newStartCartesianPoint.x &&
        self.startCartesianPoint.y === newStartCartesianPoint.y
      ) {
        return;
      }
      self.startCartesianPoint = newStartCartesianPoint;
      self.markDirty();
    },
    addRow() {
      self.rows++;
      self.markDirty();
    },
    addColumn() {
      self.columns++;
      self.markDirty();
    },
    addColumns(num) {
      self.columns += num;
      self.markDirty();
    },
    setRows(newRows) {
      if (self.rows !== newRows) self.markDirty();
      self.rows = newRows;
    },
    setColumns(newColumns) {
      if (self.columns !== newColumns) self.markDirty();
      self.columns = newColumns;
    },
    setRailed(isRailed) {
      if (self.railed !== isRailed) self.markDirty();
      self.railed = isRailed;
    },
    setRailedParentUuid(railedParentUuid) {
      if (self.railedParentUuid !== railedParentUuid) self.markDirty();
      self.railedParentUuid = railedParentUuid;
    },
    setDistanceFromRailedParentStart(distanceFromRailedParentStart) {
      if (self.distanceFromRailedParentStart !== distanceFromRailedParentStart) self.markDirty();
      self.distanceFromRailedParentStart = distanceFromRailedParentStart;
    },
    setFullColumnSize(spacedPanelSizeInColumnDirection) {
      if (self.spacedPanelSizeInColumnDirection !== spacedPanelSizeInColumnDirection) self.markDirty();
      self.spacedPanelSizeInColumnDirection = spacedPanelSizeInColumnDirection;
    },
    setAdjustedFullRowSize(slopeAdjustedSpacedPanelSizeInRowDirection) {
      if (self.slopeAdjustedSpacedPanelSizeInRowDirection !== slopeAdjustedSpacedPanelSizeInRowDirection)
        self.markDirty();
      self.slopeAdjustedSpacedPanelSizeInRowDirection = slopeAdjustedSpacedPanelSizeInRowDirection;
    },
    addPanel(params) {
      const defaultParams = { uuid: uuid(), zone: ZONE_1, modulePosition: MODULE_POSITION_N };

      const mergedParams = { ...defaultParams, ...params };
      const panel = PanelModel.create(mergedParams);
      self.panels.push(panel);

      const latLngPoints = self.panelLatLngPoints(params.row, params.column);
      panel.setLatLngPoints(
        latLngPoints.map((llp) => {
          return { lat: llp.lat, lng: llp.lng };
        }),
      );
      panel.dirty = false;

      return panel;
    },
    addThermalExpansion(params) {
      const defaultParams = { uuid: uuid() };

      const mergedParams = { ...defaultParams, ...params };
      const thermalExpansion = ThermalExpansionModel.create(mergedParams);
      self.thermalExpansions.push(thermalExpansion);

      if (!params.latLngPoints) {
        thermalExpansion.generateAndSetLatLngPoints(params.startLatLng);
      }

      return thermalExpansion;
    },
    deleteThermalExpansion(thermalExpansion) {
      const index = self.thermalExpansions.indexOf(thermalExpansion);

      self.thermalExpansions.splice(index, 1);
      self.thermalExpansionsStartLatLngs.splice(index, 1);
      self.thermalExpansionsStartCartesianPoints.splice(index, 1);

      self.markDirty();
    },
    clearPanels() {
      self.panels = [];
    },
    setTemporary(temporary) {
      self.temporary = temporary;
    },
    setDefaultZonesAndModulePositionsIfNoneExist() {
      if (self.zonesAndModulePositions !== "") return;

      self.markDirty();
      self.zonesAndModulePositions = self.buildZonesAndModulePositions;
    },
    refreshZonesAndModulePositions() {
      self.zonesAndModulePositions = self.buildZonesAndModulePositions;
    },
    setZonesAndModulePositionsString(newString) {
      if (newString !== self.zonesAndModulePositions) self.markDirty();
      self.zonesAndModulePositions = newString;
    },
    clearId() {
      self.id = undefined;
    },
    clearAdjoinments() {
      if (self.adjoinments.length > 0) self.markDirty();

      self.adjoinments = [];
    },
    addAdjoinment(adjoinmentData) {
      self.markDirty();
      const adjoinment = AdjoinmentModel.create(adjoinmentData);
      self.adjoinments.push(adjoinment);
    },
    refreshContourCodes() {
      self.markDirty();
      self.contourCodes = self.panels.map((p) => p.contourCode).join("");
    },
    clearContourLegalityAdjoinments() {
      if (self.contourLegalityAdjoinments.length > 0) self.markDirty();

      self.contourLegalityAdjoinments = [];
    },
    addContourLegalityAdjoinment(adjoinmentData) {
      self.markDirty();
      const adjoinment = AdjoinmentModel.create(adjoinmentData);
      self.contourLegalityAdjoinments.push(adjoinment);
    },
    setContourCodes(newContourCodes) {
      self.markDirty();
      self.contourCodes = newContourCodes;
    },
    setThermalExpansionGapExceedingBondingJumperLimit(newValue) {
      if (self.thermalExpansionGapExceedingBondingJumperLimit !== newValue) self.markDirty();

      self.thermalExpansionGapExceedingBondingJumperLimit = newValue;
    },
    clearContours() {
      if (self.contours.length > 0) self.markDirty();

      self.contours = [];
    },
    addContour(contourData) {
      self.markDirty();
      const contour = ContourModel.create(contourData);
      self.contours.push(contour);
      return contour;
    },
    removeContour(contour) {
      self.markDirty();
      self.contours = self.contours.filter((c) => c !== contour);
    },
    removeIllegalPanelContour() {
      self.panels.forEach((panel) => {
        if (!panel.hasContour) return;

        let isWest = panel.isContourWest;
        let isEast = panel.isContourEast;
        let isSouth = panel.isContourSouth;

        if (isWest && !panel.isFirstColumn) isWest = false;
        if (isEast && !panel.isLastColumn) isEast = false;
        if (isSouth && !panel.isLastRow) isSouth = false;

        const newCode = contourCodeGenerator(isWest, isEast, isSouth);
        panel.setContourCode(newCode);
      });
    },
  }));

export function segmentPersistenceData(segment, includeZonesAndModulePositions) {
  if (segment.hasContourCodesAndDetailsMismatch) {
    console.log("Warning: Contour Codes Mismatch");
    // Contour codes do not match other Contour related data
    debugger;
  }

  const data = {
    id: segment.id,
    uuid: segment.uuid,
    rows: segment.rows,
    columns: segment.columns,
    railed: segment.railed,
    start_lat_lng: segment.startLatLng.toLatLngString,
    start_cartesian_point: segment.startCartesianPoint.toString,
    rails_for_ew_coverage: "[]",
    portrait: segment.orientation === "portrait",
    row_direction: segment.rowDirection,
    column_direction: segment.columnDirection,
    illegal_shape: segment.illegalShape,
    _destroy: segment.deleted,
    railed_parent_uuid: segment.railedParentUuid,
    distance_from_railed_parent_start: segment.distanceFromRailedParentStart,
    thermal_expansions_start_lat_lngs: segment.thermalExpansionsStartLatLngsString,
    thermal_expansions_start_cartesian_points: segment.thermalExpansionsStartCartesianPointsString,
    adjoinments_lat_lngs: segment.adjoinmentsLatLngsString,
    adjoinments_cartesian_points: segment.adjoinmentsCartesianPointsString,
    adjoinments_details: segment.adjoinmentsDetailsString,
    contour_codes: segment.contourCodesString,
    contour_legality_adjoinments_lat_lngs: segment.contourLegalityAdjoinmentsLatLngsString,
    contour_legality_adjoinments_cartesian_points: segment.contourLegalityAdjoinmentsCartesianPointsString,
    contour_legality_adjoinments_details: segment.contourLegalityAdjoinmentsDetailsString,
    thermal_expansion_gap_exceeding_bonding_jumper_limit: segment.thermalExpansionGapExceedingBondingJumperLimit,
    contours_lat_lngs: segment.contoursLatLngsString,
    contours_cartesian_points: segment.contoursCartesianPointsString,
    contours_details: segment.contourDetailsString,
  };

  if (includeZonesAndModulePositions) {
    data.zones_and_module_positions = segment.buildZonesAndModulePositions;
  }

  return data;
}

const SegmentModel = types.compose(SegmentModelBase, WithDirtyTracking);
export default SegmentModel;
