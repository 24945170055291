import { types } from "mobx-state-tree";
import { v4 as uuid } from "uuid";
import sumBy from "lodash/sumBy";

import DaRoofPlaneModel from "../../da/models/roof-plane-model";
import { pointsForSave } from "../../da/models/points-utilities";
import RoofSectionModel, { roofSectionPersistenceData } from "./roof-section-model";
import GuideLineModel, { guideLinePersistenceData } from "../../da/map/models/guide-line-model";
import LatLngModel from "../../da/models/lat-lng-model";
import { logger } from "../../helpers/app";
import WithCartesianPoints from "../../da/models/with-cartesian-points";
import CartesianModel from "../../da/models/cartesian-model";
import { degreesToRadians } from "../../helpers/geometry";

const RoofPlaneBase = types
  .model("RoofPlaneModel", {
    roofSlope: types.maybeNull(types.number),
    modifyingTraceLocked: types.optional(types.boolean, false),
    modifyingEaveOrPropertiesLocked: types.optional(types.boolean, false),
    roofSections: types.array(RoofSectionModel),
    setbackGuides: types.array(GuideLineModel),
    eaveEdgeIndex: types.maybeNull(types.integer),
    eaveMidpointLatLng: types.maybeNull(LatLngModel),
    eavePerpendicularEdgeVectorLatLng: types.maybeNull(LatLngModel),
    eaveEdgeVectorLatLng: types.maybeNull(LatLngModel),
    eaveMidpointCartesianPoint: types.maybeNull(CartesianModel),
    eavePerpendicularEdgeVectorCartesianPoint: types.maybeNull(CartesianModel),
    eaveEdgeVectorCartesianPoint: types.maybeNull(CartesianModel),
    eaveRotationAngleRadians: types.number,
    rafterSpacing: types.maybeNull(types.integer),
    rafterOffset: types.maybeNull(types.integer),
    boundingBox: types.array(LatLngModel),
    boundingBoxCartesianPoints: types.array(CartesianModel),
    stale: types.optional(types.boolean, false),
    useCritterGuard: types.optional(types.boolean, false),
    height: types.maybeNull(types.integer),
  })
  .views((self) => ({
    guideLineForSegmentIndex(segmentIndex) {
      return self.setbackGuides.find((sg) => sg.segmentIndex === segmentIndex);
    },
    get eaveLatLngPoints() {
      return [self.latLngPoints[self.eaveEdgeIndex], self.latLngPoints[self.eaveEdgeIndex + 1]];
    },
    get eaveCartesianPoints() {
      return [self.cartesianPoints[self.eaveEdgeIndex], self.cartesianPoints[self.eaveEdgeIndex + 1]];
    },
    get needsSave() {
      const anySetbackGuidesNeedSave = self.setbackGuides.some((sg) => sg.needsSave);
      return self.baseNeedsSave || anySetbackGuidesNeedSave;
    },
    panelLengthAlongEave(orientation) {
      return orientation === "portrait" ? self.project.panelWidth : self.project.panelLength;
    },
    panelLengthPerpendicularToEave(orientation) {
      return (
        (orientation === "portrait" ? self.project.panelLength : self.project.panelWidth) *
        Math.cos(self.roofSlopeRadians)
      );
    },
    get activeRailedParentSegments() {
      return self.roofSections.flatMap((roofSection) => roofSection.activeRailedParentSegments);
    },
    get temporarySegments() {
      return self.roofSections.flatMap((roofSection) => roofSection.temporarySegments);
    },
    slopeAdjustedSpacedPanelSizeInRowDirection(orientation) {
      return self.slopeAdjustedInterRowSpacing + self.panelLengthPerpendicularToEave(orientation);
    },
    spacedPanelSizeInColumnDirection(orientation) {
      return self.project.interColumnSpacing + self.panelLengthAlongEave(orientation);
    },
    get roofSlopeRadians() {
      return degreesToRadians(self.roofSlope);
    },
    get slopeAdjustedInterRowSpacing() {
      return self.project.interRowSpacing * Math.cos(self.roofSlopeRadians);
    },
    get boundingBoxToArray() {
      if (!self.boundingBox) return [];

      return self.boundingBox.map((point) => point.toLatLng);
    },
    get boundingBoxToString() {
      if (!self.boundingBox) return "[]";

      return JSON.stringify(self.boundingBoxToArray);
    },
    get boundingBoxCartesianPointsToArray() {
      if (!self.boundingBoxCartesianPoints) return [];

      return self.boundingBoxCartesianPoints.map((point) => point.toArray);
    },
    get boundingBoxCartesianPointsToString() {
      if (!self.boundingBoxCartesianPoints) return "[]";

      return JSON.stringify(self.boundingBoxCartesianPointsToArray);
    },
    get notDeletedRoofSections() {
      return self.roofSections.filter((rs) => !rs.deleted);
    },
    get panelsCount() {
      return sumBy(self.notDeletedRoofSections, "panelsCount");
    },
    get isAttachmentPlanStatusLegal() {
      return self.roofSections.every((rs) => rs.isAttachmentPlanStatusLegal);
    },
  }))
  .actions((self) => ({
    flagDeleted() {
      if (!self.deleted) {
        self.markDirty();
        self.deleted = true;
        self.project.snapshotRoofPlaneDeletion(self);
      }
    },
    setEaveEdgeIndex(newIndex) {
      if (self.eaveEdgeIndex !== newIndex) self.markDirty();
      self.eaveEdgeIndex = newIndex;
    },
    addRoofSection(params) {
      const defaultParams = {
        uuid: uuid(),
        custom: true,
        active: true,
        identifier: self.getNextSequentialRoofSectionIdentifier(),
      };
      const mergedParams = { ...defaultParams, ...params };
      const roofSection = RoofSectionModel.create(mergedParams);
      self.roofSections.push(roofSection);

      return roofSection;
    },
    setEaveMidpointLatLng(latLng) {
      if (
        self.eaveMidpointLatLng === null ||
        self.eaveMidpointLatLng.lat !== latLng.lat ||
        self.eaveMidpointLatLng.lng !== latLng.lng
      ) {
        self.markDirty();
      }
      self.eaveMidpointLatLng = latLng;
    },
    setEaveMidpointCartesianPoint(cartesianPoint) {
      if (
        self.eaveMidpointCartesianPoint === null ||
        self.eaveMidpointCartesianPoint.x !== cartesianPoint.x ||
        self.eaveMidpointCartesianPoint.y !== cartesianPoint.y
      ) {
        self.markDirty();
      }
      self.eaveMidpointCartesianPoint = cartesianPoint;
    },
    setEavePerpendicularEdgeVectorLatLng(latLng) {
      if (
        self.eavePerpendicularEdgeVectorLatLng === null ||
        self.eavePerpendicularEdgeVectorLatLng.lat !== latLng.lat ||
        self.eavePerpendicularEdgeVectorLatLng.lng !== latLng.lng
      ) {
        self.markDirty();
      }
      self.eavePerpendicularEdgeVectorLatLng = latLng;
    },
    setEavePerpendicularEdgeVectorCartesianPoint(cartesianPoint) {
      if (
        self.eavePerpendicularEdgeVectorCartesianPoint === null ||
        self.eavePerpendicularEdgeVectorCartesianPoint.x !== cartesianPoint.x ||
        self.eavePerpendicularEdgeVectorCartesianPoint.y !== cartesianPoint.y
      ) {
        self.markDirty();
      }
      self.eavePerpendicularEdgeVectorCartesianPoint = cartesianPoint;
    },
    setEaveEdgeVectorLatLng(latLng) {
      if (
        self.eaveEdgeVectorLatLng === null ||
        self.eaveEdgeVectorLatLng.lat !== latLng.lat ||
        self.eaveEdgeVectorLatLng.lng !== latLng.lng
      ) {
        self.markDirty();
      }
      self.eaveEdgeVectorLatLng = latLng;
    },
    setEaveEdgeVectorCartesianPoint(cartesianPoint) {
      if (
        self.eaveEdgeVectorCartesianPoint === null ||
        self.eaveEdgeVectorCartesianPoint.x !== cartesianPoint.x ||
        self.eaveEdgeVectorCartesianPoint.y !== cartesianPoint.y
      ) {
        self.markDirty();
      }
      self.eaveEdgeVectorCartesianPoint = cartesianPoint;
    },
    setEaveRotationAngleRadians(angle) {
      if (self.eaveRotationAngleRadians === angle) return;

      self.markDirty();
      self.eaveRotationAngleRadians = angle;
    },
    setRafterOffset(newRafterOffset) {
      if (self.rafterOffset !== newRafterOffset) self.markDirty();
      self.rafterOffset = newRafterOffset;
    },
    clearSetbackGuides() {
      if (self.setbackGuides.length > 0) self.markDirty();
      self.setbackGuides = [];
    },
    deleteTemporarySegments() {
      self.roofSections.forEach((roofSection) => roofSection.deleteTemporarySegments());
    },
    setBoundingBox(newBoundingBox) {
      const oldBoundingBoxString = JSON.stringify(self.boundingBox);
      const newBoundingBoxString = JSON.stringify(newBoundingBox);
      if (oldBoundingBoxString === newBoundingBoxString) return;

      self.markDirty();
      self.boundingBox = newBoundingBox;
    },
    setBoundingBoxCartesianPoints(newBoundingBoxCartesianPoints) {
      const oldBoundingBoxCartesianPointsString = JSON.stringify(self.boundingBoxCartesianPoints);
      const newBoundingBoxCartesianPointsString = JSON.stringify(newBoundingBoxCartesianPoints);
      if (oldBoundingBoxCartesianPointsString === newBoundingBoxCartesianPointsString) return;

      self.markDirty();
      self.boundingBoxCartesianPoints = newBoundingBoxCartesianPoints;
    },
    clearRoofSections() {
      self.roofSections.forEach((rs) => {
        if (rs.id === undefined) {
          self.destroyRoofSection(rs);
        } else {
          rs.flagDeleted();
        }
      });
      self.markDirty();
    },
    setStale(stale) {
      if (self.stale !== stale) self.markDirty();
      self.stale = stale;
    },
  }));

export function roofPlanePersistenceData(roofPlane, includeZonesAndModulePositions) {
  logger(`Persisting Roof Plane ${roofPlane.displayIdentifier}`);

  if (!roofPlane.needsSave) {
    logger("--!needsSave. Skipping");
    return undefined;
  }

  const latLngPoints = pointsForSave(roofPlane, "latLngPoints");
  const cartesianPoints = pointsForSave(roofPlane, "cartesianPoints");
  const boundingBox = pointsForSave(roofPlane, "boundingBox");
  const boundingBoxCartesianPoints = pointsForSave(roofPlane, "boundingBoxCartesianPoints");

  const result = {
    uuid: roofPlane.uuid,
    id: roofPlane.id,
    identifier: roofPlane.identifier,
    lat_lng_points: latLngPoints,
    cartesian_points: cartesianPoints,
    illegal_shape: roofPlane.illegalShape,
    eave_edge_index: roofPlane.eaveEdgeIndex,
    eave_rotation_angle_radians: roofPlane.eaveRotationAngleRadians,
    // Don't need to persist roofSlope because it is never changed in a JS model
    _destroy: roofPlane.deleted,
    roof_sections_attributes: roofPlane.roofSections.map((roofSection) =>
      roofSectionPersistenceData(roofSection, includeZonesAndModulePositions),
    ),
    setback_guides: roofPlane.setbackGuides.map(guideLinePersistenceData),
    rafter_offset: roofPlane.rafterOffset || 0,
    bounding_box: boundingBox,
    bounding_box_cartesian_points: boundingBoxCartesianPoints,
    stale: roofPlane.stale,
  };

  if (roofPlane.eaveEdgeIndex !== null) {
    result.eave_midpoint_lat_lng = roofPlane.eaveMidpointLatLng.toLatLngString;
    result.eave_perpendicular_edge_vector_lat_lng = roofPlane.eavePerpendicularEdgeVectorLatLng.toLatLngString;
    result.eave_edge_vector_lat_lng = roofPlane.eaveEdgeVectorLatLng.toLatLngString;

    result.eave_midpoint_cartesian_point = roofPlane.eaveMidpointCartesianPoint.toString;
    result.eave_perpendicular_edge_vector_cartesian_point =
      roofPlane.eavePerpendicularEdgeVectorCartesianPoint.toString;
    result.eave_edge_vector_cartesian_point = roofPlane.eaveEdgeVectorCartesianPoint.toString;
  }

  return result;
}

const RoofPlaneModel = types.compose(DaRoofPlaneModel, RoofPlaneBase, WithCartesianPoints);
export default RoofPlaneModel;
