import SegmentsRailGroup from "../../map-model-synchronizers/segments-rail-group";
import {
  DISTANCE_THRESHOLD,
  segmentsFlushAroundThermalExpansion,
  thermalExpansionFeaturesSortedByDistanceToRailedParentStart,
} from "./helpers";

export default class ThermalExpansionsDeleter {
  constructor({ controller }) {
    this.controller = controller;
    this.project = controller.project;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.mapManager = controller.mapManager;
    this.selectInteractionManager = controller.selectInteractionManager;
    this.selectedFeatures = this.selectInteractionManager.selectedFeatures;
    this.selectionCollection = this.selectInteractionManager.selectionCollection;
  }

  delete() {
    const removalsBySegmentUuid = this.#selectedFeaturesGroupedByRailedParentUuid;
    Object.keys(removalsBySegmentUuid).forEach((segmentUuid) => {
      const removals = thermalExpansionFeaturesSortedByDistanceToRailedParentStart(removalsBySegmentUuid[segmentUuid]);

      removals.forEach((thermalExpansionFeature) => {
        const thermalExpansion = thermalExpansionFeature.get("model");
        const segment = thermalExpansion.segment;
        const railGroup = SegmentsRailGroup.fromSegmentUuid({ controller: this.controller, uuid: segment.uuid });

        this.#removeThermalExpansion(thermalExpansion, thermalExpansionFeature, railGroup);

        railGroup.reRenderSegments();
      });
    });
  }

  get #selectedFeaturesGroupedByRailedParentUuid() {
    const result = {};
    this.selectedFeatures.forEach((feature) => {
      const segmentUuid = feature.get("segmentUuid");
      if (!result[segmentUuid]) result[segmentUuid] = [];

      result[segmentUuid].push(feature);
    });
    return result;
  }

  #removeThermalExpansion(thermalExpansion, thermalExpansionFeature, railGroup) {
    this.#mergeSegmentsIfThermalExpansionIsFlush(thermalExpansion, railGroup);
    this.selectionCollection.remove(thermalExpansionFeature);
    railGroup.railedParentSegment.deleteThermalExpansion(thermalExpansion);
  }

  #mergeSegmentsIfThermalExpansionIsFlush(thermalExpansion, railGroup) {
    const [segmentBeforeThermalExpansion, segmentAfterThermalExpansion] = segmentsFlushAroundThermalExpansion(
      this.controller,
      thermalExpansion,
    );

    if (!segmentBeforeThermalExpansion || !segmentAfterThermalExpansion) return;

    const distanceBetweenSegments =
      segmentAfterThermalExpansion.distanceFromRailedParentStart -
      segmentBeforeThermalExpansion.endDistanceFromRailedParentStart;
    if (Math.abs(distanceBetweenSegments - this.project.thermalExpansionLength) <= DISTANCE_THRESHOLD) {
      this.#nudgeSegmentsAndThermalExpansionFartherAwayFromRailParentStart(
        railGroup,
        segmentAfterThermalExpansion.distanceFromRailedParentStart,
      );
      this.#mergeSegments(railGroup, segmentBeforeThermalExpansion, segmentAfterThermalExpansion);
    }
  }

  #mergeSegments(railGroup, segment1, segment2) {
    const segment1ZonesAndModulesPositions = segment1.zonesAndModulePositionsGrid;
    const segment2ZonesAndModulesPositions = segment2.zonesAndModulePositionsGrid;

    const mergedSegmentZonesAndModulesPositions = segment1ZonesAndModulesPositions.map((row, i) => {
      return [...row, ...segment2ZonesAndModulesPositions[i]];
    });
    const newZonesAndModulesPositionsString = SegmentsRailGroup.stringifyZonesAndModulePositionsFromGrid(
      mergedSegmentZonesAndModulesPositions,
    );

    segment1.setColumns(segment1.columns + segment2.columns);
    segment1.setZonesAndModulePositionsString(newZonesAndModulesPositionsString);

    railGroup.deleteSegment(segment2, { clearIllegalThermalExpansions: false });
  }

  #nudgeSegmentsAndThermalExpansionFartherAwayFromRailParentStart(railGroup, distanceCutoff) {
    const { railedParentSegment } = railGroup;
    const { eaveEdgeVectorLatLng } = railedParentSegment.roofPlane;
    const nudgeVector = eaveEdgeVectorLatLng.times(
      this.project.thermalExpansionLength - this.project.interColumnSpacing,
    );

    railGroup.segments.forEach((segment) => {
      if (segment.distanceFromRailedParentStart < distanceCutoff) return;

      const nudgeStartLatLng = segment.startLatLng.plus(nudgeVector);
      this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(segment, nudgeStartLatLng);
    });

    railedParentSegment.thermalExpansions.forEach((thermalExpansion) => {
      const thermalExpansionDistance = this.mapModelSynchronizer.getDistanceInInches(
        railedParentSegment.startLatLng,
        thermalExpansion.startLatLng,
      );
      if (thermalExpansionDistance < distanceCutoff) return;

      const nudgeStartLatLng = thermalExpansion.startLatLng.plus(nudgeVector);
      this.mapModelSynchronizer.setThermalExpansionStartLatLngAndCartesianPoint(thermalExpansion, nudgeStartLatLng);
    });
  }
}
