import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = ["segmentForm", "submitBtn", "submitBtnContainer", "segmentsPayload"];

  connect() {
    this.element.controller = this;
  }

  saveAll() {
    this.collectSavableForms();
    this.buildPayload();
    this.savePayloadIntoForm();
  }

  collectSavableForms() {
    this.savableForms = this.segmentFormTargets.filter((form) => {
      const submitBtnContainer = form.querySelector(".submit-btn-container");
      return submitBtnContainer.style.display === "block";
    });
  }

  buildPayload() {
    this.payload = this.savableForms.map((form) => {
      const id = form.querySelector("[data-identifier='pr-segment-id']").dataset.value;
      const rows = form.querySelector("[data-identifier='pr-segment-rows']").value;
      const columns = form.querySelector("[data-identifier='pr-segment-columns']").value;
      const number_of_arrays = form.querySelector("[data-identifier='pr-segment-number-of-arrays']").value;

      return { id, rows, columns, number_of_arrays };
    });
  }

  savePayloadIntoForm() {
    this.segmentsPayloadTarget.value = JSON.stringify(this.payload);
  }

  dirtied() {
    const btnContainer = this.submitBtnContainerTarget;
    if (btnContainer.style.display === "none") {
      animate.show(btnContainer);
    }
  }

  resetSaveAllBtn() {
    this.collectSavableForms();
    if (!this.savableForms.length) {
      animate.hide(this.submitBtnContainerTarget);
    }
  }
}
