import { clone, destroy } from "mobx-state-tree";
import { fromLonLat } from "ol/proj";

import MapRoofPlanesAndSectionsController from "./map_roof_planes_and_sections_controller";
import RoofSectionLegalityChecker from "../../../da/map/legality-checkers/roof-section";
import DrawInteractionManager from "../../../bx/map/interaction-managers/draw/roof-sections";
import SelectInteractionManager from "../../../bx/map/interaction-managers/select/roof-sections";
import SnapInteractionManager from "../../../bx/map/interaction-managers/snap/roof-sections";
import TranslateInteractionManager from "../../../bx/map/interaction-managers/translate/roof-sections";
import ModifyInteractionManager from "../../../bx/map/interaction-managers/modify/roof-sections";
import MeasureInteractionManager from "../../../da/map/interaction-managers/measure/base";
import RulerInteractionManager from "../../../da/map/interaction-managers/ruler/base";
import {
  deleteRoofSectionAndRemoveFromVectorSource,
  reactivateRoofSection,
  addLayoutAndArrayForRoofSection,
} from "../../../bx/map/modification-helpers/roof-section";
import { deleteAndRebuildDefaultRoofSectionsForRoofPlane } from "../../../bx/map/modification-helpers/roof-plane";
import { logger, humanizeWattage } from "../../../helpers/app";
import { EDITOR_MODE_SELECT } from "../../../da/layout-editor/helpers/toolbar-constants";
import * as toolbarSelectGroup from "../../../da/layout-editor/helpers/toolbar-select-group";

export default class MapRoofSectionsController extends MapRoofPlanesAndSectionsController {
  useGuideLines = true;

  connect() {
    super.connect();

    // Current.rollout?(:bx_editable_setbacks)
    this.canEditSetbacks = this.element.dataset.canEditSetbacks === "true";

    this.element.controller = this;

    setTimeout(() => {
      this.addSnapshotToUndoQueue(false);
    }, 1000);
  }

  setupMapInteractionManagers() {
    this.drawInteractionManager = new DrawInteractionManager(this);

    this.selectInteractionManager = new SelectInteractionManager(this);

    this.translateInteractionManager = new TranslateInteractionManager(this);

    this.modifyInteractionManager = new ModifyInteractionManager(this);

    this.measureInteractionManager = new MeasureInteractionManager(this);

    this.rulerInteractionManager = new RulerInteractionManager(this);

    this.snapInteractionManager = new SnapInteractionManager(this);
    this.snapInteractionManager.add();
  }

  mapSpecificConnect() {
    this.mapManager.buildNewDefaultRoofSectionsAndSetbacks();
    this.statusItemPanelsContainerTarget.classList.remove("d-none");
    this.statusItemWattageContainerTarget.classList.remove("d-none");
    this.#autoFixProject();
    this.mapManager.addRoofSectionsAndSetbacksVectorLayers();
    this.mapManager.addArraysVectorLayer();
    this.editorMode = toolbarSelectGroup.getState("bxRoofSectionEditorMode", EDITOR_MODE_SELECT);

    // Passing the editorMode forward so that when the BtnSelectGroup is
    // instantiated, is can enable the correct btn for the editorMode
    this.editModeBtnGroupTarget.dataset["da-LayoutEditor-BtnSelectGroupDefaultOptionValue"] = this.editorMode;
  }

  undo(event) {
    super.undo(event);
    this.afterUndoRedo();
  }

  redo(event) {
    super.redo(event);
    this.afterUndoRedo();
  }

  afterUndoRedo() {
    this.updateAzimuthFormWithValueFromProjectDetail();
    this.setRotationZoomAndPositionFromProjectSite();
    this.resetDrawingModeAndRenderDrawPoints();
    this.updateRestoreDefaultRoofSectionsUI();
    this.reRenderGuideLinesAndUpdateGuideLinesForm();
    this.snapInteractionManager.refresh();
  }

  updateAzimuthFormWithValueFromProjectDetail() {
    const azimuthForm = document.querySelector("[data-controller='bx--layout-editor--azimuth-management']");
    const azimuthFormController = azimuthForm.controller;
    azimuthFormController.setAzimuthValue(this.project.azimuth);
  }

  setRotationZoomAndPositionFromProjectSite() {
    this.mapManager.rotateCompassTo(this.project.projectSite.editorRotation);
    this.mapManager.map.getView().setRotation(this.project.projectSite.editorRotation);

    this.mapManager.map.getView().setZoom(this.project.projectSite.editorZoom);

    const coordinate = fromLonLat([this.project.projectSite.editorLng, this.project.projectSite.editorLat]);
    this.mapManager.setCenter(coordinate);
  }

  resetDrawingModeAndRenderDrawPoints() {
    if (!this.drawInteractionManager.currentDrawInteraction && this.project.drawingCoordinates.length > 0) {
      this.startDrawMode();
      this.editModeBtnGroupTarget.selectGroupController.selectOption("draw");
    }

    this.drawInteractionManager.initializeDrawingFromProjectDrawingCoordinates();
  }

  reRenderGuideLinesAndUpdateGuideLinesForm() {
    this.mapManager.reloadGuideLineFeatures();

    const form = document.querySelector("[data-controller='bx--layout-editor--guide-lines-form']");
    const formController = form.controller;
    formController.updateDistanceValues(this.project);
  }

  setMapSpecificStatusBarItems() {
    this.statusItemPanelsTarget.innerText = this.project.panelsPresent;
    this.statusItemWattageTarget.innerText = humanizeWattage(this.project.wattage);
  }

  restoreRoofPlaneDefaultRoofSectionGivenIdentifier(rpIdentifier) {
    let roofPlanes;
    if (rpIdentifier === "all") {
      roofPlanes = this.project.roofPlanes;
    } else {
      const roofPlane = this.project.roofPlanes.find((rp) => rp.identifier === rpIdentifier);
      if (!roofPlane) {
        console.error(`Unable to find roof plane for identifier`, rpIdentifier);
        return;
      }
      roofPlanes = [roofPlane];
    }

    const updates = roofPlanes.map((rp) => this.restoreRoofPlaneDefaultRoofSections(rp));
    if (updates.some((u) => u)) this.markDirty();

    this.mapManager.forceRedrawRoofPlanes();
    this.updateRestoreDefaultRoofSectionsUI();
  }

  restoreRoofPlaneDefaultRoofSections(roofPlane) {
    let didUpdateOccur = false;

    const activeCustomRoofSections = roofPlane.customRoofSections.filter((rp) => rp.active);
    activeCustomRoofSections.forEach((roofSection) => {
      deleteRoofSectionAndRemoveFromVectorSource(roofSection, this.mapModelSynchronizer, this.mapManager);
      didUpdateOccur = true;
    });

    roofPlane.defaultRoofSections.forEach((defaultRoofSection) => {
      if (defaultRoofSection.active) return;

      reactivateRoofSection(defaultRoofSection, this.project, this.mapModelSynchronizer, this.mapManager);
      didUpdateOccur = true;
    });

    roofPlane.resequenceRoofSectionsIdentifiers();

    const roofPlaneFeature = this.mapModelSynchronizer.getFeatureForRoofPlane(roofPlane);
    new RoofSectionLegalityChecker(
      roofPlaneFeature,
      this.mapManager.roofSectionsFeatures,
      this.mapModelSynchronizer,
    ).markIllegalPolygons();

    if (didUpdateOccur) {
      this.mapManager.dispatchAfterMapFeaturesRendering({
        calledFrom: "MapRoofSectionsController#restoreRoofPlaneDefaultRoofSections",
      });
    }

    return didUpdateOccur;
  }

  updateRestoreDefaultRoofSectionsUI() {
    if (!this.restoreDefaultRoofSectionsController) return;

    this.restoreDefaultRoofSectionsController.updateUI(this.project.roofPlanes);
  }

  get restoreDefaultRoofSectionsController() {
    return document.querySelector("[data-controller='bx--layout-editor--restore-roof-section-defaults']")[
      "bx--layout-editor--restore-roof-section-defaults"
    ];
  }

  azimuthUpdated(currentRotationDegrees, _allRsInRp, _allRs, _newProjectDefault, dragging) {
    this.project.setAzimuth(currentRotationDegrees);

    this.project.displayableRoofPlanes.forEach((roofPlane) => {
      roofPlane.displayableRoofSections.forEach((roofSection) => {
        if (currentRotationDegrees === roofSection.azimuth) return;

        logger(`setting azimuth to ${currentRotationDegrees} for ${roofSection.displayIdentifier}`);
        const rsFeature = this.mapModelSynchronizer.getFeatureForRoofSection(roofSection);
        roofSection.setAzimuth(currentRotationDegrees);
        const currentLayout = roofSection.layout && clone(roofSection.layout);
        addLayoutAndArrayForRoofSection(rsFeature, roofSection, this.project, this.mapManager, currentLayout);
        if (currentLayout) destroy(currentLayout);
      });
    });

    this.markDirty();

    if (!dragging) {
      this.mapManager.dispatchAfterMapFeaturesRendering({
        calledFrom: "MapRoofSectionsController#azimuthUpdated",
      });
    }
  }

  rotateCounterClockwise(event) {
    super.rotateCounterClockwise(event);

    this.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: "MapRoofSectionsController#rotateCounterClockwise",
    });
  }

  rotateClockwise(event) {
    super.rotateClockwise(event);

    this.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: "MapRoofSectionsController#rotateClockwise",
    });
  }

  #autoFixProject() {
    logger("AutoFix Project");
    this.project.roofPlanes.forEach((roofPlane) => {
      this.#autoFixRooSections(roofPlane);

      roofPlane.roofSections.forEach((roofSection) => {
        this.#autoFixLayout(roofPlane, roofSection);
      });
    });
  }

  #autoFixRooSections(roofPlane) {
    const hasStaleDefaultRoofSections = roofPlane.defaultRoofSections.some((rs) => rs.isStale);
    if (hasStaleDefaultRoofSections) {
      logger(`Roof Plane ${roofPlane.displayIdentifier} -- rebuilding default roof section`);
      deleteAndRebuildDefaultRoofSectionsForRoofPlane(roofPlane, this.mapModelSynchronizer, this.mapManager);
    }

    roofPlane.customRoofSections.forEach((roofSection) => {
      if (roofSection.fresh) return;

      const rsFeature = this.mapModelSynchronizer.getFeatureForRoofSection(roofSection);
      roofSection.freshen();
      rsFeature.set("fresh", true);
      this.markDirty();
    });

    const rpFeature = this.mapModelSynchronizer.getFeatureForRoofPlane(roofPlane);
    new RoofSectionLegalityChecker(
      rpFeature,
      this.mapManager.roofSectionsFeatures,
      this.mapModelSynchronizer,
    ).markIllegalPolygons();
    this.mapManager.forceRedrawRoofPlanes();
  }

  #autoFixLayout(roofPlane, roofSection) {
    const layout = roofSection.layout;
    if (!layout || layout.fresh) return;

    logger(`Roof Section ${roofSection.displayIdentifier} -- rebuilding layout`);

    const rsFeature = this.mapModelSynchronizer.getFeatureForRoofSection(roofSection);
    const currentLayout = roofSection.layout && clone(roofSection.layout);
    addLayoutAndArrayForRoofSection(rsFeature, roofSection, this.project, this.mapManager, currentLayout);
    if (currentLayout) destroy(currentLayout);
    this.markDirty();
  }
}
