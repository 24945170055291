import { Controller } from "@hotwired/stimulus";
import { sentryException } from "../../config/sentry";
import * as animate from "../../helpers/animate";
import { defaultHeaders } from "../../helpers/api";

export default class extends Controller {
  static values = {
    clientSecret: String,
    returnUrl: String,
    stripePublishableKey: String,
    projectId: String,
    discountAllowed: Boolean,
    updateReceiptEmailUrl: String,
  };

  static targets = [
    "paymentElement",
    "errorContainer",
    "errorStatus",
    "haveCouponLinkContainer",
    "couponCodeEntryBox",
    "couponCodeTextField",
    "newCardLink",
    "existingCardRadioBtn",
    "submitBtn",
    "paymentFormTitle",
    "receiptEmailTextField",
  ];

  static outlets = ["custom-payment-fields"];

  // Stripe custom elements styling:
  // https://stripe.com/docs/js/appendix/style
  appearance = {
    theme: "none",
    variables: {
      fontFamily: "Open Sans",
      fontSizeBase: "10px",
      spacingGridColumn: "20px",
      spacingGridRow: "15px",
    },
    rules: {
      ".Input": {
        fontSize: "1.6rem",
        border: "1px solid #ddd",
        padding: "0 0.75rem",
        lineHeight: "3.5rem",
        borderRadius: "2px",
        color: "#444",
      },
      ".Input::placeholder": {
        color: "#bbb",
      },
      ".Input:focus": {
        boxShadow: "0 0 0 0.2rem #b9d8f9",
        outline: 0,
      },
      ".Input--invalid": {
        color: "#d56262",
        backgroundColor: "#fde7e7",
        border: "1px solid #f3c9c9",
      },
      ".Input--invalid::placeholder": {
        color: "#E7A4A4",
      },
      ".Label": {
        color: "#888",
        fontSize: "1.3rem",
        marginBottom: "0.5rem",
        paddingLeft: "0.2rem",
      },
      ".Label--invalid": {
        color: "#d56262",
      },
      ".Error": {
        color: "#d56262",
        padding: "2px 0 0 2px",
        fontSize: "1.2rem",
      },
      ".Text--terms": {
        fontSize: "10px",
      },
    },
  };

  connect() {
    // `Stripe` comes from https://js.stripe.com/v3/. This should be included as a script tag
    // wherever this controller is used.
    if (this.hasPaymentElementTarget) {
      this.stripe = Stripe(this.stripePublishableKeyValue);

      const fonts = [
        {
          family: "Open Sans",
          cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans:400",
          weight: 400,
        },
      ];

      this.elements = this.stripe.elements({
        appearance: this.appearance,
        fonts,
        clientSecret: this.clientSecretValue,
      });
      const paymentElement = this.elements.create("payment");
      paymentElement.mount(this.paymentElementTarget);
    }
  }

  async submitPayment(e) {
    e.preventDefault();
    const submitBtn = e.currentTarget;

    if (submitBtn.classList.contains("ir-btn--spinner-shown")) return;

    submitBtn.classList.add("ir-btn--spinner-shown");

    const customPaymentFieldsUpdated = await this.updateCustomPaymentFields();
    if (!customPaymentFieldsUpdated) {
      submitBtn.classList.remove("ir-btn--spinner-shown");
      return;
    }

    const discountApplied = this.discountAllowedValue === false || (await this.applyDiscount());
    const receiptEmailUpdated = await this.updateReceiptEmail();
    if (discountApplied && receiptEmailUpdated) {
      if (this.existingCardId) {
        this.confirmPaymentWithExistingCard();
      } else {
        this.confirmPayment();
      }
    } else {
      submitBtn.classList.remove("ir-btn--spinner-shown");
    }
  }

  get existingCardId() {
    const existingCardRadioBtnTarget = this.existingCardRadioBtnTargets.find((radioBtn) => radioBtn.checked);
    return existingCardRadioBtnTarget && existingCardRadioBtnTarget.value;
  }

  async confirmPaymentWithExistingCard() {
    const result = await this.stripe.confirmCardPayment(this.clientSecretValue, {
      payment_method: this.existingCardId,
      return_url: this.returnUrlValue,
    });

    if (result.error) {
      this.submitBtnTarget.classList.remove("ir-btn--spinner-shown");
      this.errorContainerTarget.classList.remove("d-none");
      this.errorStatusTarget.textContent = result.error.message;
      if (result.error.type !== "card_error" && result.error.type !== "validation_error") {
        sentryException(
          `[Handled Error] Error confirming payment with existing card. Error: ${JSON.stringify(
            result.error,
          )}. Used card ID: ${this.existingCardId}`,
        );
      }
    } else {
      window.location = this.returnUrlValue;
    }
  }

  async confirmPayment() {
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: this.returnUrlValue,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    setTimeout(() => {
      if (error) {
        this.submitBtnTarget.classList.remove("ir-btn--spinner-shown");
        this.errorContainerTarget.classList.remove("d-none");
        this.errorStatusTarget.textContent = error.message;
        if (error.type !== "card_error" && error.type !== "validation_error") {
          sentryException(`[Handled Error] Error confirming payment. Error: ${JSON.stringify(error)}`);
        }
      }
    }, 300);
  }

  selectExistingCard(_event) {
    this.paymentElementTarget.classList.add("d-none");
    this.paymentFormTitleTarget.classList.add("d-none");
    this.newCardLinkTarget.classList.remove("d-none");
  }

  clickNewCardLink(_event) {
    this.paymentElementTarget.classList.remove("d-none");
    this.paymentFormTitleTarget.classList.remove("d-none");
    this.newCardLinkTarget.classList.add("d-none");
    this.existingCardRadioBtnTargets.forEach((radioBtn) => {
      radioBtn.checked = false;
    });
  }

  async processZeroDollarTransaction(e) {
    e.preventDefault();
    const submitBtn = e.currentTarget;

    if (submitBtn.classList.contains("ir-btn--spinner-shown")) return;

    submitBtn.classList.add("ir-btn--spinner-shown");

    if (await this.applyDiscount()) {
      window.location = this.returnUrlValue;
    }
  }

  async applyDiscount() {
    const applyDiscountUrl = `/permit_packs/${this.projectIdValue}/payment/apply_discount`;
    const applyDiscountResponse = await fetch(applyDiscountUrl, {
      headers: defaultHeaders(),
      method: "PUT",
      body: JSON.stringify({
        payment: { client_secret: this.clientSecretValue },
      }),
    });
    if (applyDiscountResponse.status === 204) {
      return true;
    } else {
      // render coupon validation error (from app/views/payments/apply_discount.js.erb)
      window.eval(await applyDiscountResponse.text());
      return false;
    }
  }

  async updateReceiptEmail() {
    const updateReceiptEmailResponse = await fetch(this.updateReceiptEmailUrlValue, {
      headers: defaultHeaders(),
      method: "PUT",
      body: JSON.stringify({
        payment: { receipt_email: this.receiptEmailTextFieldTarget.value, client_secret: this.clientSecretValue },
      }),
    });
    if (updateReceiptEmailResponse.status === 204) {
      return true;
    } else {
      window.eval(await updateReceiptEmailResponse.text());
      return false;
    }
  }

  async updateCustomPaymentFields() {
    if (this.hasCustomPaymentFieldsOutlet) {
      return this.customPaymentFieldsOutlet.update();
    } else {
      return true;
    }
  }

  haveCouponLinkClick(event) {
    event.preventDefault();
    animate.hide(this.haveCouponLinkContainerTarget);
    animate.show(this.couponCodeEntryBoxTarget);
    this.couponCodeTextFieldTarget.focus();
  }
}
