import { Controller } from "@hotwired/stimulus";
import { addOptionsToSelect } from "../../helpers/form";

export default class PrController extends Controller {
  static values = {
    railPlatformRailTypeValues: Object,
    railPlatformChangeRailTypeMapping: Object,
  };

  static targets = ["railTypeSelect"];

  changeRailPlatform(event) {
    const railPlatform = event.currentTarget.value;
    const railTypeValues = this.railPlatformRailTypeValuesValue[railPlatform];
    const currentRailType = this.railTypeSelectTarget.value;
    const newRailType = this.railPlatformChangeRailTypeMappingValue[currentRailType];
    const opts = railTypeValues.map((rt) => [rt, rt, false, newRailType === rt]);
    addOptionsToSelect(opts, this.railTypeSelectTarget);
  }
}
