import { Controller } from "@hotwired/stimulus";
import * as store from "../../../helpers/local-store";

const SHOW_MENU_CLASS = "ol-map__btn__flyout__menu--active";
const PINNED_MENU_CLASS = "ol-map__btn__flyout__menu--pinned";
const ACTIVE_PIN_BTN_CLASS = "ol-map__btn__flyout__menu__actions__btn--active";

export default class BtnFlyoutMenuController extends Controller {
  static targets = ["menu", "pinBtn", "flyoutMenuBtn"];
  static values = { localStorageKey: String };

  hideMenuTimeout = undefined;

  connect() {
    this.element.flyoutMenuController = this;

    this.pinBtnPinTooltip = this.pinBtnTarget.dataset.tooltip;
    this.pinBtnUnpinTooltip = this.pinBtnTarget.dataset.tooltipPinned;

    if (this.hasLocalStorageKeyValue && store.has(this.localStorageKey)) {
      this.showMenu();
      // Give Tippy some time to initialize
      setTimeout(() => {
        this.pinMenu();
      }, 50);
    }
  }

  get isFlyoutMenuBtnDisabled() {
    const btn = this.flyoutMenuBtnTarget;
    return btn.disabled || btn.className.match(/--disabled/);
  }

  get localStorageKey() {
    return `le--pinnedMenu--${this.localStorageKeyValue}`;
  }

  showMenu(_event) {
    if (this.isFlyoutMenuBtnDisabled) return;

    if (this.hideMenuTimeout) window.clearTimeout(this.hideMenuTimeout);

    this.menuTarget.classList.add(SHOW_MENU_CLASS);
  }

  hideMenu(_event) {
    if (this.isMenuPinned) return;

    this.hideMenuTimeout = window.setTimeout(() => {
      this.menuTarget.classList.remove(SHOW_MENU_CLASS);
    }, 100);
  }

  get isMenuPinned() {
    return this.menuTarget.classList.contains(PINNED_MENU_CLASS);
  }

  toggleMenuPin(_event) {
    if (this.isMenuPinned) {
      this.unpinMenu();
      if (this.hasLocalStorageKeyValue) store.del(this.localStorageKey);
    } else {
      this.pinMenu();
      if (this.hasLocalStorageKeyValue) store.set(this.localStorageKey, true);
    }
  }

  pinMenu() {
    this.pinBtnTarget.classList.add(ACTIVE_PIN_BTN_CLASS);
    this.menuTarget.classList.add(PINNED_MENU_CLASS);
    this.pinBtnTarget._tippy.setContent(this.pinBtnUnpinTooltip);
  }

  unpinMenu() {
    this.pinBtnTarget.classList.remove(ACTIVE_PIN_BTN_CLASS);
    this.menuTarget.classList.remove(PINNED_MENU_CLASS);
    this.menuTarget.classList.remove(SHOW_MENU_CLASS);
    this.pinBtnTarget._tippy.setContent(this.pinBtnPinTooltip);
  }

  showEnabledAndHideDisabledPinnedMenu() {
    if (this.isFlyoutMenuBtnDisabled) {
      this.menuTarget.classList.remove(SHOW_MENU_CLASS);
    } else if (this.isMenuPinned) {
      this.menuTarget.classList.add(SHOW_MENU_CLASS);
    }
  }
}
