export default class GridCellPositions {
  static calculate(displayGrid) {
    return new this(displayGrid).calculate();
  }

  constructor(displayGrid) {
    this.displayGrid = displayGrid;
  }

  calculate() {
    const { rows, columns } = this.displayGrid;

    const positions = [];
    for (let i = 0; i < rows; i++) {
      positions[i] = [];
      for (let j = 0; j < columns; j++) {
        positions[i][j] = this.position(i, j);
      }
    }
    return positions;
  }

  position(row, column) {
    return {
      x: this.xPosition(column),
      y: this.yPosition(row),
    };
  }

  xPosition(column) {
    const {
      cellWidth,
      columnSpacing,
      containerPadding,
      extensionLeft,
      horizontalCenteringOffset,
      setbackWidth,
      spaceAroundCellsLeft,
    } = this.displayGrid;

    const columnSpacingsUpToRow = column * columnSpacing;
    const cellWidthsUpToRow = column * cellWidth;

    return (
      setbackWidth +
      spaceAroundCellsLeft +
      extensionLeft +
      columnSpacingsUpToRow +
      cellWidthsUpToRow +
      containerPadding.left +
      horizontalCenteringOffset
    );
  }

  yPosition(row) {
    const { cellHeight, containerPadding, extensionTop, rowSpacing, setbackHeight, spaceAroundCellsTop } =
      this.displayGrid;

    const rowSpacingsUpToColumn = row * rowSpacing;
    const cellHeightsUpToColumn = row * cellHeight;

    return (
      setbackHeight +
      spaceAroundCellsTop +
      extensionTop +
      rowSpacingsUpToColumn +
      cellHeightsUpToColumn +
      containerPadding.top
    );
  }
}
