import { types, detach, getSnapshot } from "mobx-state-tree";

import { logger } from "../../helpers/app";

import DaRoofSectionModel from "../../da/models/roof-section-model";
import SegmentModel, { ATTACHMENT_PLAN_STATUS_LEGAL, segmentPersistenceData } from "./segment-model";
import WithCartesianPoints from "../../da/models/with-cartesian-points";
import sumBy from "lodash/sumBy";
import LatLngModel from "../../da/models/lat-lng-model";
import { pointsForSave } from "../../da/models/points-utilities";

const RoofSectionBase = types
  .model("RoofSectionModel", {
    custom: types.boolean,
    active: types.boolean,
    segments: types.array(SegmentModel),
    defaultLatLngPoints: types.array(LatLngModel),
  })
  .views((self) => ({
    get railedParentSegments() {
      return self.segments.filter((s) => s.railed);
    },
    get displayIdentifier() {
      let prefix = "";
      if (!self.active) prefix = "Inactive-";
      if (self.deleted) prefix = "Deleted-";

      return `${prefix}${self.roofPlane.identifier}${self.identifier}`;
    },
    get displayable() {
      return !self.deleted && self.active && self.display;
    },
    get needsSave() {
      const anySegmentsNeedsSave = self.segments.some((rs) => rs.needsSave);
      return self.dirty || !self.id || anySegmentsNeedsSave;
    },
    get activeRailedParentSegments() {
      return self.segments.filter((s) => s.railed && !s.deleted);
    },
    get temporarySegments() {
      return self.segments.filter((s) => s.temporary);
    },
    get notDeletedSegments() {
      return self.segments.filter((s) => !s.deleted);
    },
    get panelsCount() {
      return sumBy(self.notDeletedSegments, "panelsCount");
    },
    get defaultLatLngPointsToArray() {
      return self.defaultLatLngPoints.map((point) => point.toLatLng);
    },
    get defaultLatLngPointsToString() {
      return JSON.stringify(self.defaultLatLngPointsToArray);
    },
    get isAttachmentPlanStatusLegal() {
      return self.segments.every((s) => s.isAttachmentPlanStatusLegal);
    },
  }))
  .actions((self) => ({
    flagDeleted() {
      if (!self.deleted) {
        self.markDirty();
        self.deleted = true;
      }
    },
    addSegment(segment) {
      self.segments.push(segment);
    },
    addExistingSegment(segment) {
      const newSegment = detach(segment);
      newSegment.clearId();
      self.addSegment(newSegment);
      self.markDirty();
    },
    deleteSegment(segment) {
      if (segment.hasUnrailedChildren) {
        const unrailedChildren = segment.unrailedChildren;
        const previousFirstChild = unrailedChildren[0];
        const previousFirstChildDistanceFromRailedParentStart = previousFirstChild.distanceFromRailedParentStart;

        // Make the previous first child the new parent
        previousFirstChild.setRailed(true);
        previousFirstChild.setRailedParentUuid(null);
        previousFirstChild.setDistanceFromRailedParentStart(null);
        segment.thermalExpansions.forEach((thermalExpansion) => {
          const thermalExpansionSnapShot = getSnapshot(thermalExpansion);
          previousFirstChild.addThermalExpansion(thermalExpansionSnapShot);
        });

        unrailedChildren.forEach((childSegment, i) => {
          if (i === 0) return;

          // Assign any other children to the new parent
          childSegment.setRailedParentUuid(previousFirstChild.uuid);
          childSegment.setDistanceFromRailedParentStart(
            childSegment.distanceFromRailedParentStart - previousFirstChildDistanceFromRailedParentStart,
          );
        });
      }

      if (segment.id) {
        segment.flagDeleted();
      } else {
        self.segments = self.segments.filter((s) => s.uuid !== segment.uuid);
      }
    },
    reassignSegments(otherRoofSection) {
      self.segments.forEach((segment) => {
        otherRoofSection.addExistingSegment(segment);
      });
      self.segments = [];
      self.markDirty();
      otherRoofSection.markDirty();
    },
    detachSegments() {
      self.segments.forEach((segment) => {
        detach(segment);
        segment.clearId();
      });
      self.segments = [];
    },
    deleteTemporarySegments() {
      self.temporarySegments.forEach((segment) => {
        self.deleteSegment(segment);
      });
    },
    setDefaultLatLngPoints(newPoints) {
      const oldDefaultLatLngPointsString = JSON.stringify(self.defaultLatLngPoints);
      const newDefaultLatLngPointsString = JSON.stringify(newPoints);
      if (oldDefaultLatLngPointsString === newDefaultLatLngPointsString) return;

      self.markDirty();
      self.defaultLatLngPoints = newPoints;
    },
  }));

export function roofSectionPersistenceData(roofSection, includeZonesAndModulePositions) {
  logger(`Persisting Roof Section ${roofSection.displayIdentifier}`);

  if (!roofSection.needsSave) {
    logger("--!needsSave. Skipping.");
    return undefined;
  }

  const latLngPoints = pointsForSave(roofSection, "latLngPoints");

  const data = {
    pr_roof_plane_id: roofSection.roofPlane.id,
    // TODO: [PRIB] remove this when you update the system to use the new hierarchy of roof sections belonging to roof planes
    project_id: roofSection.roofPlane.project.id,
    uuid: roofSection.uuid,
    id: roofSection.id,
    identifier: roofSection.identifier,
    custom: roofSection.custom,
    active: roofSection.active,
    illegal_shape: roofSection.illegalShape,
    lat_lng_points: latLngPoints,
    cartesian_points: roofSection.cartesianPointsToString,
    encroaching: roofSection.encroaching,
    _destroy: roofSection.deleted,
    segments_attributes: roofSection.segments.map((segment) =>
      segmentPersistenceData(segment, includeZonesAndModulePositions),
    ),
  };

  if (!roofSection.id) {
    data.default_lat_lng_points = roofSection.defaultLatLngPointsToString;
  }

  return data;
}

const RoofSectionModel = types.compose(DaRoofSectionModel, RoofSectionBase, WithCartesianPoints);
export default RoofSectionModel;
