import React, { useMemo } from "react";
import PropTypes from "prop-types";

import Vertical from "./Vertical";
import Horizontal from "./Horizontal";

function Crosshairs({
  mousePos,
  outlinePos,
  height,
  width,
  realHeight,
  realWidth,
  cursorOffset,
  lineOffset,
  compressedSpace,
  compressedSpaceXQuadrant,
  compressedSpaceYQuadrant,
}) {
  const showLines = useMemo(() => {
    const offset = cursorOffset / 2;
    return (
      outlinePos &&
      mousePos &&
      mousePos.x >= outlinePos.left - offset &&
      mousePos.x <= outlinePos.right - offset &&
      mousePos.y >= outlinePos.top - offset &&
      mousePos.y <= outlinePos.bottom - offset
    );
  }, [cursorOffset, mousePos, outlinePos]);

  return (
    <>
      {showLines && (
        <Vertical
          x={mousePos.x}
          y={outlinePos.top}
          intersectionY={mousePos.y}
          height={height}
          realHeight={realHeight}
          cursorOffset={cursorOffset}
          lineOffset={lineOffset}
          compressedTop={compressedSpace.top}
          compressedBottom={compressedSpace.bottom}
          compressedSpaceYQuadrant={compressedSpaceYQuadrant}
        />
      )}
      {showLines && (
        <Horizontal
          x={outlinePos.left}
          y={mousePos.y}
          intersectionX={mousePos.x}
          width={width}
          realWidth={realWidth}
          cursorOffset={cursorOffset}
          lineOffset={lineOffset}
          compressedLeft={compressedSpace.left}
          compressedRight={compressedSpace.right}
          compressedSpaceXQuadrant={compressedSpaceXQuadrant}
        />
      )}
    </>
  );
}

Crosshairs.propTypes = {
  mousePos: PropTypes.object,
  outlinePos: PropTypes.object,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  realHeight: PropTypes.number.isRequired,
  realWidth: PropTypes.number.isRequired,
  cursorOffset: PropTypes.number.isRequired,
  lineOffset: PropTypes.number.isRequired,
  compressedSpace: PropTypes.shape({
    top: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
  compressedSpaceXQuadrant: PropTypes.oneOf(["split", "bothOnRight", "bothOnLeft"]).isRequired,
  compressedSpaceYQuadrant: PropTypes.oneOf(["split", "bothOnTop", "bothOnBottom"]).isRequired,
};

Crosshairs.defaultProps = {
  mousePos: undefined,
  outlinePos: undefined,
};

export default React.memo(Crosshairs);
