import { Controller } from "@hotwired/stimulus";
import * as store from "../helpers/local-store";

export default class extends Controller {
  static values = {
    userUuid: String,
    isAuroraProject: Boolean,
  };

  submit(event) {
    event.preventDefault();

    if (this.isAuroraProjectValue) {
      const timestampInSeconds = Math.floor(new Date().getTime() / 1000);
      store.set(`${this.userUuidValue}-termsOfServiceAcceptedAt`, timestampInSeconds);
    }

    this.element.requestSubmit();
  }
}
