import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectAllCheckbox", "checkbox", "submitButton"];

  toggleSelectAll(_e) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.selectAllCheckboxTarget.checked;
    });

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = !this.selectAllCheckboxTarget.checked;
    }
  }

  toggleSubmit(_e) {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = this.checkboxTargets.every((checkbox) => !checkbox.checked);
    }
  }
}
