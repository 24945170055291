import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";
import axios from "axios";
import * as api from "../../helpers/api";

export default class extends Controller {
  static values = {
    projectPrefix: String,
  };

  static targets = [
    "orientationRailsSpansAndForcesField",
    "landscapeViewContainer",
    "setPortraitButton",
    "portraitViewContainer",
    "setLandscapeButton",
    "setOrientationUrl",
    "railTypeSelect",
  ];

  setCurrentView(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const currentView = button.dataset.viewName;

    this.orientationRailsSpansAndForcesFieldTarget.value = currentView;

    this.updateOrientation(currentView);

    this.toggleButtonActive(button, true);

    if (currentView === "landscape") {
      if (this.hasPortraitViewContainerTarget) {
        this.portraitViewContainerTarget.style.display = "none";
      }

      animate.show(this.landscapeViewContainerTarget);

      if (this.hasSetPortraitButtonTarget) {
        this.toggleButtonActive(this.setPortraitButtonTarget, false);
      }
    } else {
      if (this.hasLandscapeViewContainerTarget) {
        this.landscapeViewContainerTarget.style.display = "none";
      }

      animate.show(this.portraitViewContainerTarget);

      if (this.hasSetLandscapeButtonTarget) {
        this.toggleButtonActive(this.setLandscapeButtonTarget, false);
      }
    }
  }

  updateOrientation(orientation) {
    axios({
      method: "PATCH",
      url: this.setOrientationUrlTarget.dataset.value,
      data: { orientation },
      headers: api.defaultHeaders(),
    });
  }

  toggleButtonActive(button, bool) {
    button.closest("li").classList[bool ? "add" : "remove"]("active");
  }

  fetchForcesMaxSpan(event) {
    event.preventDefault();
    const link = event.currentTarget;
    const projectId = link.dataset.id;
    const roofPlaneId = link.dataset.roofPlaneId;
    const orientation = link.dataset.orientation;
    const zone = link.dataset.zone;
    const modulePosition = link.dataset.position || "";
    const queryParams = `orientation=${orientation}&zone=${zone}&module_position=${modulePosition}`;
    const url =
      this.projectPrefixValue === "fr"
        ? this.frFetchForcesMaxSpanUrl(projectId)
        : this.prFetchForcesMaxSpanUrl(projectId, roofPlaneId);
    const urlWithQueryParams = `${url}?${queryParams}`;
    axios
      .get(urlWithQueryParams, { headers: api.defaultHeaders() })
      .then((response) => {
        document.querySelector("#max-span-under-forces-result").innerHTML = response.data.max_span;
      })
      .catch(api.errorHandler);
  }

  prFetchForcesMaxSpanUrl(projectId, roofPlaneId) {
    return `/pr/projects/${projectId}/roof_planes/${roofPlaneId}/spans_and_forces/max_span_under_forces`;
  }

  frFetchForcesMaxSpanUrl(projectId) {
    return `/fr/projects/${projectId}/span_and_forces/max_span_under_forces`;
  }
}
