import { Fill, Stroke, Style, Text } from "ol/style";

// Obstructions

function legalObstructionStyle(label) {
  return new Style({
    stroke: new Stroke({ color: "rgba(255, 148, 3, 1.0)", width: 1 }),
    fill: new Fill({ color: "rgba(255, 148, 3, 0.4)" }),
    text: new Text({ text: label, scale: 2, fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }) }),
  });
}

function illegalObstructionStyle(label) {
  return new Style({
    stroke: new Stroke({ color: "rgba(255, 0, 0, 1.0)", width: 1 }),
    fill: new Fill({ color: `rgba(255, 0, 0, 0.4)` }),
    text: new Text({ text: label, scale: 2, fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }) }),
  });
}

export function obstructionStyle(feature, project) {
  const uuid = feature.get("uuid");
  const obstruction = project.getObstruction(uuid);
  let label = obstruction.identifier;

  if (feature.get("illegalShape")) {
    return illegalObstructionStyle(label);
  } else {
    return legalObstructionStyle(label);
  }
}

export function selectObstructionPolygonStrokeAndFillColors(_feature, _controller) {
  return ["rgba(255, 148, 3, 1.0)", "rgba(255, 148, 3, 0.6)"];
}

// Obstruction Buffers

const OBSTRUCTION_BUFFER_STYLE = new Style({
  stroke: new Stroke({ color: "rgba(255, 228, 196, 1.0)", width: 1 }),
  fill: new Fill({ color: `rgba(255, 228, 196, 0.4)` }),
});

export function obstructionBufferStyle(feature, project) {
  return OBSTRUCTION_BUFFER_STYLE;
}
