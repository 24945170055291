import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    turboFrame: String,
    basePath: String,
  };

  change(event) {
    const select = event.currentTarget;
    const turboFrame = document.querySelector(`#${this.turboFrameValue}`);
    turboFrame.src = `${this.basePathValue}?items_per_page=${select.value}`;
  }
}
