import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

import { showDialog, getDialogElem } from "./components/ir_dialog/helper";

export default class CompanySwitchedNoticeController extends Controller {
  static values = {
    updatingCsrfToken: String,
    newCompanyName: String,
    oldCompanyName: String,
  };

  static show(updatingCsrfToken, currentCsrfToken, newCompanyName, oldCompanyName) {
    if (updatingCsrfToken === currentCsrfToken) return;

    const dialog = getDialogElem("company-switched-notice-dialog");
    dialog.querySelector("[data-identifier='old-company-name']").textContent = `"${oldCompanyName}"`;
    dialog.querySelector("[data-identifier='new-company-name']").textContent = `"${newCompanyName}"`;

    showDialog("company-switched-notice-dialog");
  }

  static debouncedShow = debounce(CompanySwitchedNoticeController.show, 1000);

  connect() {
    CompanySwitchedNoticeController.debouncedShow(
      this.updatingCsrfTokenValue,
      this.currentCsrfToken,
      this.newCompanyNameValue,
      this.oldCompanyNameValue,
    );
  }

  get currentCsrfToken() {
    const metaTag = document.head.querySelector("meta[name=csrf-token]");
    if (!metaTag) return "";

    return metaTag.getAttribute("content");
  }
}
