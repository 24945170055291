import RoofInformationController from "../da/roof_information_controller";
import { addOptionsToSelect } from "../../helpers/form";
import * as animate from "../../helpers/animate";

export default class extends RoofInformationController {
  static projectType = "fr";

  static targets = [
    ...RoofInformationController.targets,
    "roofMaterialDisplayText",
    "roofAttachmentDisplayText",
    "roofDeckPulloutStrengthContainer",
    "roofDeckPulloutStrengthTextField",
    "lFootSubText",
  ];

  static values = {
    ...RoofInformationController.values,
    northSouthRoofSectionsPresent: Boolean,
  };

  connect() {
    this.toggleRoofMaterialFamilyInfoPopup();
    this.changeRoofAttachment();
  }

  changeRoofAttachment(_event) {
    this.toggleRoofAttachmentInfoPopup();
    this.#toggleRoofAttachmentsInInfoPopup();
    this.roofAttachmentSelectTarget.disabled = this.roofMaterial === "";
    this.#toggleRoofMaterialAndAttachmentDisplayText();
    this.#toggleRoofDeckPulloutStrengthField();
    this.#toggleSubText();
  }

  // Used by parent method RoofInformationController#changeRoofMaterial
  updateAttachmentList() {
    const possibleAttachments = this.roofMaterial === "" ? [] : this.#materialToAttachment(this.roofMaterial);

    const prompt = possibleAttachments.length > 1 ? [["- choose one -", ""]] : [];
    const attachmentOptions = prompt.concat(
      possibleAttachments.map((a) => {
        let text = a[0];
        let disabled = false;

        if (this.northSouthRoofSectionsPresentValue && a[1] === "none") {
          text += " [E-W Only]";
          disabled = true;
        }

        return [text, a[1], disabled, false];
      }),
    );
    addOptionsToSelect(attachmentOptions, this.roofAttachmentSelectTarget, false);
  }

  updateDependenciesOnAttachmentList() {
    this.changeRoofAttachment();
  }

  #materialToAttachment(roofMaterial) {
    return this.materialToAttachmentXrValue[roofMaterial];
  }

  #toggleRoofAttachmentsInInfoPopup(_event) {
    const dialog = document.querySelector("[data-dialog-identifier=info-popup-fr-roof-attachment]");
    const sections = dialog.querySelectorAll("[data-roof-attachment]");
    if (this.isRoofAttachmentEmpty) return;

    let showSelector = `[data-roof-attachment=${this.roofAttachment}]`;

    for (let section of sections) {
      section.style.display = section.matches(showSelector) ? "block" : "none";
    }
  }

  #toggleRoofMaterialAndAttachmentDisplayText() {
    const hideAttachmentSelect = this.roofMaterial === "metal_standing_seam";
    animate.toggle(this.roofAttachmentSelectTarget, !hideAttachmentSelect, { fadeOut: false });
    animate.toggle(this.roofAttachmentDisplayTextTarget, hideAttachmentSelect, { fadeOut: false });
  }

  #toggleRoofDeckPulloutStrengthField() {
    const metalRoof = this.roofMaterialFamily === "metal";
    animate.toggle(this.roofDeckPulloutStrengthContainerTarget, !metalRoof);
    if (metalRoof) {
      this.roofDeckPulloutStrengthTextFieldTarget.value = "";
    }
  }

  #toggleSubText() {
    if (this.hasLFootSubTextTarget) {
      animate.toggle(this.lFootSubTextTarget, this.roofAttachment === "lfoot");
    }
  }
}
