import { Controller } from "@hotwired/stimulus";
import { hideDialog } from "./helper";
import * as animate from "../../../helpers/animate";

export default class extends Controller {
  static targets = ["overlay"];

  hideModalDialogs() {
    const dialogs = document.querySelectorAll(".ir-dialog--modal");

    const dialogsArr = Array.from(dialogs).filter((d) => {
      return d.dataset.dialogModal === "true" && d.style.display === "block";
    });
    const anyNonClosable = dialogsArr.some((dialog) => dialog.dataset.closable === "false");
    if (anyNonClosable) return;

    dialogsArr.forEach((dialog) => hideDialog(dialog));
  }
}
