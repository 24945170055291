import React from "react";
import PropTypes from "prop-types";

import Marker from "./Marker";
import Line from "./Line";
import { inchesToFeetAndInches } from "../../../helpers/app";

function Vertical({
  x,
  y,
  height,
  intersectionY,
  realHeight,
  cursorOffset,
  lineOffset,
  compressedTop,
  compressedBottom,
  compressedSpaceYQuadrant,
}) {
  const offset = cursorOffset / 2;
  const middleX = x + offset;
  const middleY = intersectionY + offset;

  let topCompressedAmount = 0;
  let bottomCompressedAmount = 0;
  if (compressedSpaceYQuadrant === "bothOnTop") {
    topCompressedAmount += compressedTop + compressedBottom;
  } else if (compressedSpaceYQuadrant === "bothOnBottom") {
    bottomCompressedAmount += compressedTop + compressedBottom;
  } else {
    topCompressedAmount += compressedTop;
    bottomCompressedAmount += compressedBottom;
  }

  const topHeight = middleY - y;
  const topY = y + (middleY - y) / 2;
  const topRealHeightInches = (topHeight / height) * realHeight + topCompressedAmount;

  const bottomHeight = height - topHeight;
  const bottomY = middleY + (y + height - middleY) / 2;
  const bottomRealHeightInches = (bottomHeight / height) * realHeight + bottomCompressedAmount;

  const topMarker = {
    x: middleX,
    y: topY,
    label: inchesToFeetAndInches(topRealHeightInches),
    includesCompressedSpace: topCompressedAmount > 0,
  };

  const bottomMarker = {
    x: middleX,
    y: bottomY,
    label: inchesToFeetAndInches(bottomRealHeightInches),
    includesCompressedSpace: bottomCompressedAmount > 0,
  };

  return (
    <div data-testid="vertical">
      <Line x={x + lineOffset} y={y} length={height} type="vertical" />
      <Marker {...topMarker} />
      <Marker {...bottomMarker} />
    </div>
  );
}

Vertical.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  intersectionY: PropTypes.number.isRequired,
  realHeight: PropTypes.number.isRequired,
  cursorOffset: PropTypes.number.isRequired,
  lineOffset: PropTypes.number.isRequired,
  compressedTop: PropTypes.number.isRequired,
  compressedBottom: PropTypes.number.isRequired,
  compressedSpaceYQuadrant: PropTypes.oneOf(["split", "bothOnTop", "bothOnBottom"]).isRequired,
};

export default React.memo(Vertical);
