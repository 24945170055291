import React from "react";
import PropTypes from "prop-types";

const DisplayField = ({ label, value }) => (
  <div className="form-group">
    <label>{label}</label>
    <div className="form-display-text">{value}</div>
  </div>
);

DisplayField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default DisplayField;
