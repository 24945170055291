// 1" = 1px and we scale from there
export default class GridDisplayScaleFactor {
  cellLongSideMaxLength = 100;

  static calculate(displayGrid) {
    return new this(displayGrid).calculate();
  }

  constructor(displayGrid) {
    this.displayGrid = displayGrid;
    this.realGrid = displayGrid.realGrid;
  }

  calculate() {
    const heightScaleFactor = this.containerMaxHeight / this.realGrid.height;
    const widthScaleFactor = this.containerWidth / this.realGrid.width;
    const scaleFactor = Math.min(heightScaleFactor, widthScaleFactor);
    return this.augmentScaleFactorForCellMaxSize(scaleFactor);
  }

  get containerWidth() {
    const { maxWidth, containerPadding } = this.displayGrid;
    return maxWidth - containerPadding.horizontal;
  }

  get containerMaxHeight() {
    const { maxHeight, containerPadding } = this.displayGrid;
    return maxHeight - containerPadding.vertical;
  }

  augmentScaleFactorForCellMaxSize(scaleFactor) {
    const scaledCell = this.scaledCell(scaleFactor);
    if (this.cellSidesLessThanMax(scaledCell)) return scaleFactor;
    return this.cellMaxLengthScaleFactor;
  }

  scaledCell(scaleFactor) {
    const { cellWidth, cellHeight } = this.realGrid;
    return {
      width: cellWidth * scaleFactor,
      height: cellHeight * scaleFactor,
    };
  }

  cellSidesLessThanMax(cell) {
    return cell.width <= this.cellLongSideMaxLength && cell.height <= this.cellLongSideMaxLength;
  }

  get cellMaxLengthScaleFactor() {
    const { cellWidth, cellHeight } = this.realGrid;
    const widthScaleFactor = this.cellLongSideMaxLength / cellWidth;
    const heightScaleFactor = this.cellLongSideMaxLength / cellHeight;
    return Math.min(widthScaleFactor, heightScaleFactor);
  }
}
