import Select from "ol/interaction/Select";
import { click } from "ol/events/condition";

import {
  isPolygon,
  isARoofPlane,
  isAnObstruction,
  isARoofSection,
  isPoint,
  pointAtFeatureVertex,
  ensureFeatureIsClockwise,
  isAMeasure,
} from "../../ol-helpers";

import { selectStyle } from "../../styles/select";
import MeasureSelectManager from "./measure-select-manager";

export default class Base {
  constructor(controller) {
    this.controller = controller;

    if (controller.hasSelectBtnTarget) this.selectBtnTarget = controller.selectBtnTarget;

    this.project = controller.project;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;

    this.measureSelectManager = new MeasureSelectManager(
      this.map,
      this.controller.mapManager.measuresVectorSource,
      this,
    );

    this.currentSelectInteraction = undefined;
  }

  add() {
    this.clearCurrentInteraction();

    this.currentSelectInteraction = this.buildSelectInteraction;
    this.map.addInteraction(this.currentSelectInteraction);
    this.currentSelectInteraction.on("select", this.selectClick);
  }

  get buildSelectInteraction() {
    return new Select({
      style: this.selectStyle,
      hitTolerance: 3,
      multi: false,
      filter: this.filterSelects,
      // this improves responsiveness because select doesn't need to wait to
      // determine if the user single vs double clicked
      condition: this.selectCondition,
    });
  }

  selectStyle = (feature) => {
    return selectStyle(feature, this.controller, this.map);
  };

  filterSelects = (_feature, _layer) => {
    // override in sub class
  };

  selectCondition = click;

  remove() {
    this.clearCurrentInteraction();
  }

  get features() {
    console.warn("[Deprecation warning] Use selectionCollection instead of features on the select interaction manager");
    // should we check if there is no current select interaction?
    return this.currentSelectInteraction.getFeatures();
  }

  get selectedFeatures() {
    return this.selectionCollection.getArray();
  }

  get selectionCollection() {
    return this.currentSelectInteraction.getFeatures();
  }

  removeSelectedFeatures(_event) {
    if (!this.currentSelectInteraction || this.selectionCollection.getLength() === 0) return;

    if (this.anyFeaturesWithSelectedVertices()) {
      this.removeSelectedVertices();
    } else {
      this.removeSelectedShapes();
    }

    this.controller.markDirty();
  }

  anyFeaturesWithSelectedVertices() {
    if (this.selectedFeatures.find((feature) => feature.get("selectedVertexCoordinates"))) return true;
    return false;
  }

  removeSelectedVertices() {
    // override in sub class
  }

  getCoordinatesIfVertexDeletionAllowed(feature) {
    const selectedVertexCoordinates = feature.get("selectedVertexCoordinates");
    const geometry = feature.getGeometry();
    if (!selectedVertexCoordinates || !isPolygon(feature)) return;

    // get coordinates of outer ring
    const coordinates = geometry.getLinearRing(0).getCoordinates();
    // a triangle will have 4 coordinates and it is the smallest legal polygon
    if (coordinates.length <= 4) {
      alert("Vertices can only be deleted from features with at least four corners.");
      return;
    }

    return coordinates;
  }

  coordinatesWithoutVertexAtIndex(coordinates, index) {
    const newCoordinates = [...coordinates];
    const isFirstOrLastIndex = index === 0 || index === coordinates.length - 1;

    if (isFirstOrLastIndex) {
      newCoordinates.splice(0, 1); // remove first element
      newCoordinates.pop(); // take off last element
      newCoordinates.push(newCoordinates[0]); // add the first one again at the end to close the ring
    } else {
      newCoordinates.splice(index, 1);
    }

    return newCoordinates;
  }

  updateFeatureToNewCoordinates(feature, newCoordinates) {
    const geometry = feature.getGeometry();
    geometry.setCoordinates([newCoordinates]);

    ensureFeatureIsClockwise(feature);
  }

  indexOfMatchingCoordinate(selectedVertexCoordinates, coordinates) {
    return coordinates.findIndex((coordinate) => {
      return coordinate[0] === selectedVertexCoordinates[0] && coordinate[1] === selectedVertexCoordinates[1];
    });
  }

  baseRemoveSelectedShapes = () => {
    this.measureSelectManager.removeSelectedMeasures();
    this.deselectSelected();
    this.controller.modifyInteractionManager.refresh();
  };

  clearCurrentInteraction() {
    if (this.currentSelectInteraction) {
      this.currentSelectInteraction.un("select", this.selectClick);
      this.map.removeInteraction(this.currentSelectInteraction);
      delete this.currentSelectInteraction;
      this.currentSelectInteraction = undefined;
    }
  }

  // Event fired when clicking a polygon
  selectClick = (event) => {
    event.deselected.forEach((feature) => this.clearDeselectFeature(feature));

    if (event.selected && event.selected.length > 0) {
      event.selected.forEach((feature) => {
        // If it's none of these things, it's assumed to be point feature over the top of a roof plane or roof section vertex
        if (!isAMeasure(feature) && !isARoofPlane(feature) && !isAnObstruction(feature) && !isARoofSection(feature)) {
          // it's a point
          const coordinates = feature.getGeometry().getCoordinates();

          let roofPlaneFeatures = [];
          if (this.controller.mapManager.roofPlanesVectorSource) {
            roofPlaneFeatures = this.controller.mapManager.roofPlanesVectorSource.getFeatures();
          }

          let obstructionFeatures = [];
          if (this.controller.mapManager.obstructionsVectorSource) {
            obstructionFeatures = this.controller.mapManager.obstructionsVectorSource.getFeatures();
          }

          let roofSectionFeatures = [];
          if (this.controller.mapManager.roofSectionsVectorSource) {
            roofSectionFeatures = this.controller.mapManager.roofSectionsVectorSource.getFeatures();
          }

          const allFeatures = [...roofPlaneFeatures, ...obstructionFeatures, ...roofSectionFeatures];

          allFeatures.forEach((allFeature) => {
            if (pointAtFeatureVertex(allFeature, coordinates)) {
              allFeature.set("selectedVertexCoordinates", coordinates);
              this.addToSelection(allFeature);
            }
          });
        }
      });
      // because modify and translate only work on the selected polygon, we
      // need to refresh them after the selections change
      this.selectClickResetInteractionManagers();
    }
  };

  clearDeselectFeature(feature) {
    feature.unset("selectedVertexCoordinates");
  }

  selectClickResetInteractionManagers() {
    // override in sub class
  }

  deselectSelected() {
    if (!this.currentSelectInteraction) return;

    this.selectionCollection.clear();
  }

  removeSelectedVertexFromSelectInteractionFeatures() {
    const selectedVertexPoints = [];
    this.selectionCollection.forEach((feature) => {
      if (isPoint(feature)) selectedVertexPoints.push(feature);
    });

    selectedVertexPoints.forEach((feature) => this.selectionCollection.remove(feature));
  }

  addToSelection(features) {
    if (!this.currentSelectInteraction) return;

    const add = (feature) => {
      const alreadySelected = this.selectionCollection.getArray().find((f) => f === feature);
      if (!alreadySelected) this.selectionCollection.push(feature);
    };

    if (features instanceof Array) {
      features.forEach((feature) => add(feature));
    } else {
      add(features);
    }
  }

  removeFromSelection(features) {
    if (!this.currentSelectInteraction) return;

    if (features instanceof Array) {
      features.forEach((feature) => this.selectionCollection.remove(feature));
    } else {
      this.selectionCollection.remove(features);
    }
  }
}
