import { removeSegmentFromSelectInteraction } from "./segments/helpers";
import { removeSegmentPanelsFromSelectInteraction } from "../map-model-synchronizers/helpers";
import SegmentsRailGroup from "../map-model-synchronizers/segments-rail-group";

/*
When you are nudging horizontally, you can nudge one segment in a rail group into another.
This class looks for overlaps between rail group segments and when it finds them it consolidates
the segments into a single segment.  This does a single pass through looking for possible
consolidations, however it's possible to have a chain reaction of consolidation so this class
needs to be called recursively until it identifies that no consolidations have occurred.
*/
export default class RailGroupConsolidator {
  constructor({ controller, railGroup, nudgeDirection }) {
    this.controller = controller;
    this.railGroup = railGroup;
    this.nudgeDirection = nudgeDirection;
    this.mapModelSynchronizer = this.controller.mapModelSynchronizer;

    this.selectInteractionManager = controller.selectInteractionManager;
    this.roofPlane = controller.focusedRoofPlane;
    this.project = controller.project;
    this.consolidationsOccurred = false;
  }

  consolidate() {
    if (this.railGroup.segments.length < 2) return;

    if (this.selectInteractionManager.isPanelSelectType) {
      this.consolidatedSimplifiedGridBeforeSegmentConsolidation = this.railGroup.consolidatedSimplifiedGrid;
    } else {
      this.segmentUuidsToSelectAfterConsolidation = this.selectInteractionManager.selectedFeatures.map((sf) =>
        sf.get("uuid"),
      );
    }

    this.#consolidateSegmentsInRailGroup();

    if (this.consolidationsOccurred) {
      this.railGroup.updateDistancesFromRailedParentStart();
      this.railGroup.reRenderSegments();

      if (this.selectInteractionManager.isPanelSelectType) {
        this.#reSelectPanels();
      } else {
        this.#reSelectSegments();
      }
    }

    return this.consolidationsOccurred;
  }

  #consolidateSegmentsInRailGroup() {
    this.railGroup.segments.forEach((segment2, i) => {
      if (i === 0 || this.consolidationsOccurred) return;

      const segment1 = this.railGroup.segments[i - 1];

      const overlapAmount = this.#segmentsOverlap(segment1, segment2);
      if (overlapAmount === 0) return;

      this.#consolidateSegments(segment1, segment2, overlapAmount);
    });
  }

  /*
  Since we're horizontally nudging with segments that are a part of a rail group and thus
  already perfectly aligned vertically with each other, and since our nudge is along the
  roof plane's eave edge vector, we can use the segment models to figure out if there's an
  overlap and by how much.
  */
  #segmentsOverlap(segment1, segment2) {
    const s1Distance = segment1.distanceFromRailedParentStart || 0;
    const s1Width = segment1.width;
    const s1End = s1Distance + s1Width;
    const s2Distance = segment2.distanceFromRailedParentStart || 0;
    const s2Start = s2Distance;
    const overlapAmount = Math.abs(s2Start - s1End);

    return s1End > s2Start ? overlapAmount + this.project.interColumnSpacing : 0;
  }

  #consolidateSegments(segment1, segment2, overlapAmount) {
    const segment1ZonesAndModulesPositions = segment1.zonesAndModulePositionsGrid;
    const segment2ZonesAndModulesPositions = segment2.zonesAndModulePositionsGrid;

    const consolidatedSegmentZonesAndModulesPositions = segment1ZonesAndModulesPositions.map((row, i) => {
      return [...row, ...segment2ZonesAndModulesPositions[i]];
    });
    const newZonesAndModulesPositionsString = SegmentsRailGroup.stringifyZonesAndModulePositionsFromGrid(
      consolidatedSegmentZonesAndModulesPositions,
    );

    const segment1ContourCodes = segment1.contourCodesGrid;
    const segment2ContourCodes = segment2.contourCodesGrid;

    const consolidatedSegmentContourCodes = segment1ContourCodes.map((row, i) => {
      return [...row, ...segment2ContourCodes[i]];
    });
    const newContourCodesString = SegmentsRailGroup.stringifyContourCodesFromGrid(consolidatedSegmentContourCodes);

    /*
    The segments will be in order of railed parent followed by unrailed children sorted by
    distance from the parent.  Since we are iterating through pairs from start to finish
    this means we will always keep the current parent segment when doing a consolidation.
    */
    const consolidatedSegment = segment1;
    const deletedSegment = segment2;
    consolidatedSegment.setColumns(consolidatedSegment.columns + deletedSegment.columns);
    consolidatedSegment.setZonesAndModulePositionsString(newZonesAndModulesPositionsString);
    consolidatedSegment.setContourCodes(newContourCodesString);

    /*
    Figure out if we need to move the startLatLng of the new segment right or left
    if nudge direction is left need to update start lat lng.  If we're nudging right,
    the segment is already positioned correctly.
    */
    if (this.nudgeDirection === "left") {
      const nudgeVector = this.roofPlane.eaveEdgeVectorLatLng.times(1 * overlapAmount);
      const nudgedStartLatLng = consolidatedSegment.startLatLng.plus(nudgeVector);
      this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(consolidatedSegment, nudgedStartLatLng);
    }

    const deletedSegmentUuid = deletedSegment.uuid;
    this.railGroup.deleteSegment(deletedSegment);

    removeSegmentPanelsFromSelectInteraction(this.controller, consolidatedSegment.uuid);
    removeSegmentFromSelectInteraction(this.controller, consolidatedSegment.uuid);

    if (!this.selectInteractionManager.isPanelSelectType) {
      this.segmentUuidsToSelectAfterConsolidation = this.segmentUuidsToSelectAfterConsolidation.filter(
        (uuid) => uuid !== deletedSegmentUuid,
      );
      this.segmentUuidsToSelectAfterConsolidation.push(consolidatedSegment.uuid);
    }

    this.consolidationsOccurred = true;
  }

  /*
  While the organization/number of segments may change when nudging, the relative
  placement and number of panels will not.  So, to select previously selected panels
  we compare a snapshot of a simplified consolidated grid of panels for the rail
  group before a segment consolidation has been carried out to a consolidated grid of
  rail group panels after the segment consolidation, using the first as a lookup for
  panel features in the second to add to the select interaction's collection.
  */
  #reSelectPanels() {
    const panelsConsolidatedGrid = this.railGroup.consolidatedPanelsGrid;
    const selectionCollection = this.selectInteractionManager.selectionCollection;

    selectionCollection.clear();
    this.consolidatedSimplifiedGridBeforeSegmentConsolidation.forEach((row, i) => {
      row.forEach((panelSelectionStatus, j) => {
        if (panelSelectionStatus !== "X") return;

        const panelFeature = panelsConsolidatedGrid[i][j];
        selectionCollection.push(panelFeature);
      });
    });
  }

  #reSelectSegments() {
    const { selectionCollection } = this.selectInteractionManager;

    selectionCollection.clear();
    this.segmentUuidsToSelectAfterConsolidation.forEach((uuid) => {
      const segmentFeature = this.railGroup.segmentFeatures.find((sf) => sf.get("uuid") === uuid);
      if (!segmentFeature) return;

      selectionCollection.push(segmentFeature);
    });
  }
}
