import {
  checkIfFeatureHasPointsInsideFeature,
  featureEdgesIntersect,
  verticesFromFeatureToPoints,
  checkPointSetAgainstPolygonVertices,
  verticesPlusNearbyPointsFromFeature,
  checkIfFeatureHasPointsInsideFeatureIncludingInteriorPoint,
  markIllegalIfFeatureHasPointsInsideFeatureIncludingInteriorPoint,
} from "../../../da/map/legality-checkers/polygon-helpers";
import SegmentsRailGroup from "../map-model-synchronizers/segments-rail-group";

export default class SegmentsLegalityChecker {
  constructor(controller) {
    this.controller = controller;

    this.mapManager = controller.mapManager;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;

    this.panelFeatures = controller.mapManager.panelsVectorSource.getFeatures();
    this.segmentFeatures = controller.mapManager.segmentsVectorSource.getFeatures();
    this.railFeatures = controller.mapManager.railsVectorSource.getFeatures();
    this.segmentFeaturesMap = {};
    this.segmentPanelFeaturesMap = {};
    this.segmentRailFeaturesMap = {};

    this.segmentFeatures.forEach((segmentFeature) => {
      const segmentUuid = segmentFeature.get("uuid");
      this.segmentFeaturesMap[segmentUuid] = segmentFeature;
      this.segmentPanelFeaturesMap[segmentUuid] = this.panelFeatures.filter(
        (pf) => pf.get("segmentUuid") === segmentUuid,
      );
      this.segmentRailFeaturesMap[segmentUuid] = this.railFeatures.filter(
        (rf) => rf.get("segmentUuid") === segmentUuid,
      );
    });
    this.roofSectionFeatures = controller.mapManager.roofSectionsVectorSource.getFeatures();
  }

  check() {
    this.encroaching = false;
    this.needsThermalExpansion = false;

    this.#clearIllegal();
    this.#checkSegmentPanelFeatures();
    this.#checkPanelsInsideAndNotOverlappingARoofSection();
    this.#checkRailFeatures();
    this.#checkThermalExpansions();
  }

  #clearIllegal() {
    this.panelFeatures.forEach((panelFeature) => panelFeature.set("illegalShape", false));
    this.segmentFeatures.forEach((segmentFeature) => this.#setSegmentIllegalShapeFlag(segmentFeature, false));
  }

  #setSegmentIllegalShapeFlag(segmentFeature, isIllegal) {
    if (segmentFeature.get("illegalShape") === isIllegal) return;

    segmentFeature.set("illegalShape", isIllegal);
    const segment = segmentFeature.get("model");
    segment.setIllegalShape(isIllegal);
  }

  #checkSegmentPanelFeatures() {
    this.segmentFeatures.forEach((segmentFeature) => {
      this.segmentFeatures.forEach((otherSegmentFeature) => {
        if (segmentFeature.get("uuid") === otherSegmentFeature.get("uuid")) return;

        const segmentHasPointInsideOtherSegment = checkIfFeatureHasPointsInsideFeatureIncludingInteriorPoint(
          segmentFeature,
          otherSegmentFeature,
        );
        if (!segmentHasPointInsideOtherSegment) return;

        this.#checkPanelsDoNotOverlap(
          this.segmentPanelFeaturesMap[segmentFeature.get("uuid")],
          otherSegmentFeature,
          this.segmentPanelFeaturesMap[otherSegmentFeature.get("uuid")],
        );
      });
    });
  }

  #checkPanelsDoNotOverlap(segmentOnePanelFeatures, segmentTwoFeature, segmentTwoPanelFeatures) {
    segmentOnePanelFeatures.forEach((panelFeature) => {
      if (panelFeature.get("illegalShape")) {
        this.#markSegmentFeatureForPanelIllegal(panelFeature);
        return;
      }

      if (
        !checkIfFeatureHasPointsInsideFeature(panelFeature, segmentTwoFeature) &&
        !featureEdgesIntersect(panelFeature, segmentTwoFeature)
      ) {
        return;
      }

      segmentTwoPanelFeatures.forEach((otherPanelFeature) => {
        const isIllegal = markIllegalIfFeatureHasPointsInsideFeatureIncludingInteriorPoint(
          panelFeature,
          otherPanelFeature,
        );

        if (isIllegal) this.#markSegmentFeatureForPanelIllegal(panelFeature);
      });
    });
  }

  #markSegmentFeatureForPanelIllegal(panelFeature) {
    const segmentFeature = this.segmentFeaturesMap[panelFeature.get("segmentUuid")];
    this.#setSegmentIllegalShapeFlag(segmentFeature, true);
  }

  #checkPanelsInsideAndNotOverlappingARoofSection() {
    this.panelFeatures.forEach((panelFeature) => {
      let isLegal = this.roofSectionFeatures.some((roofSectionFeature) => {
        const encroaching =
          featureEdgesIntersect(panelFeature, roofSectionFeature) ||
          this.#roofSectionHasPointInsidePanel(panelFeature, roofSectionFeature);
        if (encroaching) this.encroaching = true;

        return checkIfFeatureHasPointsInsideFeature(panelFeature, roofSectionFeature) && !encroaching;
      });

      if (!isLegal) {
        panelFeature.set("illegalShape", true);

        const segmentFeature = this.segmentFeaturesMap[panelFeature.get("segmentUuid")];
        this.#setSegmentIllegalShapeFlag(segmentFeature, true);
      }
    });
  }

  #roofSectionHasPointInsidePanel(panelFeature, roofSectionFeature) {
    const roofSectionFeatureVertices = verticesPlusNearbyPointsFromFeature(roofSectionFeature);
    const panelFeatureVertices = verticesFromFeatureToPoints(panelFeature);
    const roofSectionHasPointInsidePanel = checkPointSetAgainstPolygonVertices(
      roofSectionFeature,
      roofSectionFeatureVertices,
      panelFeatureVertices,
      false,
    );
    return roofSectionHasPointInsidePanel;
  }

  #checkRailFeatures() {
    this.segmentFeatures.forEach((segmentFeature) => {
      const segment = segmentFeature.get("model");
      if (!segment.railed) return;

      const segmentRailFeatures = this.segmentRailFeaturesMap[segmentFeature.get("uuid")];

      segmentRailFeatures.forEach((segmentRailFeature) => {
        this.railFeatures.forEach((otherRailFeature) => {
          if (segmentRailFeature.get("segmentUuid") === otherRailFeature.get("segmentUuid")) return;

          const isIllegal =
            checkIfFeatureHasPointsInsideFeature(segmentRailFeature, otherRailFeature) ||
            featureEdgesIntersect(segmentRailFeature, otherRailFeature);

          if (isIllegal) this.#setRailGroupFeaturesIllegal(segmentRailFeature.get("segmentUuid"));
        });

        const isLegal = this.roofSectionFeatures.some((roofSectionFeature) => {
          return (
            checkIfFeatureHasPointsInsideFeature(segmentRailFeature, roofSectionFeature) &&
            !featureEdgesIntersect(segmentRailFeature, roofSectionFeature)
          );
        });
        if (!isLegal) {
          this.encroaching = true;
          this.#setRailGroupFeaturesIllegal(segmentRailFeature.get("segmentUuid"));
        }
      });
    });
  }

  #setRailGroupFeaturesIllegal(parentSegmentUuid) {
    this.#setSegmentAndPanelFeaturesIllegal(parentSegmentUuid);
    const segmentFeature = this.segmentFeaturesMap[parentSegmentUuid];

    const segment = segmentFeature.get("model");
    segment.unrailedChildren.forEach((childSegment) => {
      this.#setSegmentAndPanelFeaturesIllegal(childSegment.uuid);
    });
  }

  #setSegmentAndPanelFeaturesIllegal(segmentUuid) {
    const segmentFeature = this.segmentFeaturesMap[segmentUuid];
    this.#setSegmentIllegalShapeFlag(segmentFeature, true);
    this.segmentPanelFeaturesMap[segmentUuid].forEach((panelFeature) => {
      panelFeature.set("illegalShape", true);
    });
  }

  #checkThermalExpansions() {
    this.segmentFeatures.forEach((segmentFeature) => {
      const segment = segmentFeature.get("model");
      if (!segment.railed) return;

      const railGroup = SegmentsRailGroup.fromSegmentUuid({ controller: this.controller, uuid: segment.uuid });
      if (!railGroup.needsThermalExpansions) return;

      this.#setRailGroupFeaturesIllegal(segment.uuid);
      this.needsThermalExpansion = true;
    });
  }
}
