import React from "react";
import PropTypes from "prop-types";

import { showDialogWithIdentifier } from "../../../controllers/components/ir_dialog/helper";
import CompressionGraphic from "./CompressionGraphic";

function Marker({ orientation, x, y, rows, columns }) {
  const classNames = [
    "graphical-tool__compressed-space-marker",
    `graphical-tool__compressed-space-marker--${orientation}`,
  ];

  const styles = {
    left: `${x}px`,
    top: `${y}px`,
  };

  const onClick = (event) => {
    const clickPosition = { x: event.pageX, y: event.pageY };
    showDialogWithIdentifier("graphical-tool-extra-space-compressed", () => {}, {
      showNearClickPosition: true,
      clickPosition,
    });
  };

  const shortenedDisplay = (orientation === "vertical" && rows < 3) || (orientation === "horizontal" && columns < 3);

  return (
    <div
      className={classNames.join(" ")}
      style={styles}
      onClick={onClick}
      data-testid={`marker-${x}-${y}-${orientation}`}
    >
      <CompressionGraphic />
      {!shortenedDisplay && (
        <>
          <div className="graphical-tool__compressed-space-marker__text">roof area truncated</div>
          <CompressionGraphic />
        </>
      )}
    </div>
  );
}

Marker.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  orientation: PropTypes.oneOf(["vertical", "horizontal"]).isRequired,
  columns: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
};

export default React.memo(Marker);
