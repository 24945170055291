import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = [
    "ewPierSpanContainer",
    "foundationTypeSelect",
    "ewPierSpanTypeCustomCheckbox",
    "customEwPierSpanFieldContainer",
    "customEwPierSpanLabel",
  ];

  foundationChanged(_event) {
    if (this.foundationTypeSelectTarget.value === "other") {
      animate.hide(this.ewPierSpanContainerTarget, { fadeOut: false });
      animate.show(this.customEwPierSpanFieldContainerTarget);
      this.ewPierSpanTypeCustomCheckboxTarget.checked = true;
      this.customEwPierSpanLabelTarget.innerHTML = "E/W pier span";
    } else {
      animate.show(this.ewPierSpanContainerTarget);
      this.customEwPierSpanLabelTarget.innerHTML = "Custom";
    }
  }
}
