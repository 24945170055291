import MapBaseController from "./layout_editor/map_base_controller";
import BaseMapModelSynchronizer from "../../../pr/map/map-model-synchronizers/base";
import ProjectReportMapManager from "../../../pr/map/map-managers/project-report";

export default class MapProjectReportController extends MapBaseController {
  static targets = ["map", "compass"];

  connect() {
    this.viewOnlyMap = true;
    this.renderSegmentsWithZones = true;

    super.connect();

    this.pageRoofPlanes = this.project.roofPlanes;

    this.mapModelSynchronizer = new BaseMapModelSynchronizer(this);

    this.mapManager = new ProjectReportMapManager(this);
    this.mapManager.add();

    this.element.controller = this;
  }
}
