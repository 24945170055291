import React from "react";
import PropTypes from "prop-types";
import Column from "./Column";
import DisplayField from "../../DisplayField";

const ViewOnly = ({ rows, columns }) => (
  <div data-testid="view-only" className="d-flex align-items-end" style={{ position: "relative", right: "-2rem" }}>
    <Column>
      <DisplayField label="Rows" value={`${rows}`} />
    </Column>
    <Column>
      <DisplayField label="Columns" value={`${columns}`} />
    </Column>
  </div>
);

ViewOnly.propTypes = {
  rows: PropTypes.string.isRequired,
  columns: PropTypes.string.isRequired,
};

export default React.memo(ViewOnly);
