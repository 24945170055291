import { logger } from "../../helpers/app";

export function pointsForSave(model, pointsSelector) {
  if (model.deleted) {
    logger(`--Deleted. Blanking ${pointsSelector}`);
    return "[]";
  } else if (model.dirty) {
    const method = `${pointsSelector}ToString`;
    return model[method];
  } else {
    logger(`--Not dirty. Omitting ${pointsSelector}`);
    return undefined;
  }
}
