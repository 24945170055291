import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Crop from "ol-ext/filter/Crop";

import DaMapManager from "../../../da/map/map-manager";
import SegmentsLegalityChecker from "../legality-checkers/segments";
import SegmentsAdjoinmentsRenderer from "../modification-helpers/segments/proximity-alignments/adjoinments-renderer";
import SegmentsContourLegalityAdjoinmentsRenderer from "../modification-helpers/segments/proximity-alignments/contour-legality-adjoinments-renderer";

import { thermalExpansionsStyle } from "../styles/thermal-expansions";
import { raftersStyle } from "../styles/rafters";
import { railsStyle } from "../styles/segments";
import { panelsStyle } from "../styles/zone-panels";
import { segmentsStyle } from "../styles/segments";
import { adjoinmentsStyle, contourLegalityAdjoinmentsStyle } from "../styles/adjoinments";
import { contoursStyle } from "../styles/contour";

import {
  ADJOINMENT_DATA_TYPE,
  CONTOUR_DATA_TYPE,
  CONTOUR_LEGALITY_ADJOINMENT_DATA_TYPE,
  PANEL_DATA_TYPE,
  RAIL_DATA_TYPE,
  THERMAL_EXPANSION_DATA_TYPE,
} from "../../../da/map/data-types";

export default class BaseMapManager extends DaMapManager {
  get panelsVectorLayer() {
    if (!this.memoPanelsVectorLayer) {
      this.memoPanelsVectorLayer = new VectorLayer({
        source: this.panelsVectorSource,
        style: this.panelsStyle,
        zIndex: 40,
      });
      this.memoPanelsVectorLayer.set("dataType", PANEL_DATA_TYPE);
    }
    return this.memoPanelsVectorLayer;
  }

  buildSegmentsVectorSource() {
    this.segmentsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.segmentFeatures,
    });
  }

  clearSegmentFeatures() {
    this.clearFeaturesFromVectorSource(this.segmentsVectorSource);
  }

  get segmentsVectorLayer() {
    if (!this.memoSegmentsVectorLayer) {
      this.memoSegmentsVectorLayer = new VectorLayer({
        source: this.segmentsVectorSource,
        style: this.segmentsStyle,
        zIndex: 60,
      });
    }
    return this.memoSegmentsVectorLayer;
  }

  panelsStyle = (feature) => {
    return panelsStyle(feature, this.map, this.controller.project.is716Or722);
  };

  segmentsStyle = (feature) => {
    return segmentsStyle(feature);
  };

  buildRailsVectorSource() {
    this.railsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.railFeatures,
    });
  }

  clearRailFeatures() {
    this.clearFeaturesFromVectorSource(this.railsVectorSource);
  }

  get railsVectorLayer() {
    if (!this.memoRailsVectorLayer) {
      this.memoRailsVectorLayer = new VectorLayer({
        source: this.railsVectorSource,
        style: (feature) => railsStyle(feature),
        zIndex: 30,
      });
      this.memoRailsVectorLayer.set("dataType", RAIL_DATA_TYPE);
    }
    return this.memoRailsVectorLayer;
  }

  buildRafterVectorSourcesLayersAndFeatures() {
    const roofPlanes = this.controller.pageRoofPlanes;

    this.rafterVectorSources = {};

    roofPlanes.forEach((roofPlane) => {
      if (roofPlane.roofSections.length === 0) return;

      const vectorSource = new VectorSource();
      this.rafterVectorSources[roofPlane.identifier] = vectorSource;

      vectorSource.addFeatures(this.mapModelSynchronizer.rafterFeatures(roofPlane));

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: (feature) => raftersStyle(feature),
      });
      this.map.addLayer(vectorLayer);

      const roofPlaneFeature = this.mapModelSynchronizer.getFeatureForRoofPlane(roofPlane);
      const cropFilter = new Crop({ feature: roofPlaneFeature, inner: false });
      vectorLayer.addFilter(cropFilter);
    });
  }

  clearRafterFeatures() {
    Object.values(this.rafterVectorSources).forEach((rafterVectorSource) => {
      this.clearFeaturesFromVectorSource(rafterVectorSource);
    });
  }

  renderRafterFeatures() {
    this.controller.pageRoofPlanes.forEach((roofPlane) => {
      if (roofPlane.roofSections.length === 0) return;

      this.rafterVectorSources[roofPlane.identifier].addFeatures(this.mapModelSynchronizer.rafterFeatures(roofPlane));
    });
  }

  clearFeaturesFromVectorSource(vectorSource) {
    const features = vectorSource.getFeatures();
    features.forEach((feature) => vectorSource.removeFeature(feature));
  }

  buildPanelsVectorSource() {
    this.panelsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.featuresForRoofPlanePanels(this.controller.focusedRoofPlane),
    });
  }

  clearPanelFeatures() {
    this.clearFeaturesFromVectorSource(this.panelsVectorSource);
  }

  checkLegality() {
    const legalityChecker = new SegmentsLegalityChecker(this.controller);
    legalityChecker.check();
    return legalityChecker;
  }

  buildThermalExpansionsVectorSource() {
    this.thermalExpansionsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.thermalExpansionFeatures,
    });
  }

  get thermalExpansionsVectorLayer() {
    if (!this.memoThermalExpansionsVectorLayer) {
      this.memoThermalExpansionsVectorLayer = new VectorLayer({
        source: this.thermalExpansionsVectorSource,
        style: (feature) => thermalExpansionsStyle(feature),
        zIndex: 70,
      });
      this.memoThermalExpansionsVectorLayer.set("dataType", THERMAL_EXPANSION_DATA_TYPE);
    }
    return this.memoThermalExpansionsVectorLayer;
  }

  clearThermalExpansionFeatures() {
    this.clearFeaturesFromVectorSource(this.thermalExpansionsVectorSource);
  }

  buildAdjoinmentsVectorSource() {
    this.adjoinmentsVectorSource = new VectorSource({ features: this.mapModelSynchronizer.adjoinmentsFeatures });
  }

  get adjoinmentsVectorLayer() {
    if (!this.memoAdjoinmentsVectorLayer) {
      this.memoAdjoinmentsVectorLayer = new VectorLayer({
        source: this.adjoinmentsVectorSource,
        style: (feature) => adjoinmentsStyle(feature),
        zIndex: 80,
      });
      this.memoAdjoinmentsVectorLayer.set("dataType", ADJOINMENT_DATA_TYPE);
    }
    return this.memoAdjoinmentsVectorLayer;
  }

  renderAdjoinAlignments() {
    SegmentsAdjoinmentsRenderer.render(this.controller);
  }

  clearAdjoinmentFeatures() {
    this.clearFeaturesFromVectorSource(this.adjoinmentsVectorSource);
  }

  buildContourLegalityAdjoinmentsVectorSource() {
    this.contourLegalityAdjoinmentsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.contourLegalityAdjoinmentsFeatures,
    });
  }

  get contourLegalityAdjoinmentsVectorLayer() {
    if (!this.memoContourLegalityAdjoinmentsVectorLayer) {
      this.memoContourLegalityAdjoinmentsVectorLayer = new VectorLayer({
        source: this.contourLegalityAdjoinmentsVectorSource,
        style: (feature) => contourLegalityAdjoinmentsStyle(feature),
        zIndex: 80,
      });
      this.memoContourLegalityAdjoinmentsVectorLayer.set("dataType", CONTOUR_LEGALITY_ADJOINMENT_DATA_TYPE);
    }
    return this.memoContourLegalityAdjoinmentsVectorLayer;
  }

  renderContourLegalityAdjoinAlignments() {
    SegmentsContourLegalityAdjoinmentsRenderer.render(this.controller);
  }

  clearContourLegalityAdjoinmentFeatures() {
    this.clearFeaturesFromVectorSource(this.contourLegalityAdjoinmentsVectorSource);
  }

  buildContoursVectorSource() {
    this.contoursVectorSource = new VectorSource();
  }

  get contoursVectorLayer() {
    if (!this.memoContoursVectorLayer) {
      this.memoContoursVectorLayer = new VectorLayer({
        source: this.contoursVectorSource,
        style: (feature) => contoursStyle(feature, this.map, this.controller),
        zIndex: 80,
      });
      this.memoContoursVectorLayer.set("dataType", CONTOUR_DATA_TYPE);
    }
    return this.memoContoursVectorLayer;
  }

  renderContourFeatures() {
    const features = this.mapModelSynchronizer.contourFeatures;
    this.contoursVectorSource.addFeatures(features);
  }

  clearContourFeatures() {
    this.clearFeaturesFromVectorSource(this.contoursVectorSource);
  }

  showVectorLayer(dataTypes, show = true) {
    let dataTypesArr = Array.isArray(dataTypes) ? dataTypes : [dataTypes];
    this.map
      .getLayers()
      .getArray()
      .filter((l) => dataTypesArr.includes(l.get("dataType")))
      .forEach((l) => l.setVisible(show));
  }
}
