import { Controller } from "@hotwired/stimulus";
import { addOptionsToSelect } from "../../helpers/form";
import * as animate from "../../helpers/animate";

// This is used on PR projects and PR preset.
export default class RailPlatformController extends Controller {
  static targets = [
    "endClampSelect",
    // these targets are only on the preset page
    "railLengthsXr",
    "railLengthsAire",
    "railFinishClear",
    "railFinishMill",
    "railFinishBlack",
    "attachmentHardwareSelect",
    "attachmentHardwareXrInfoPopup",
    "attachmentHardwareAireInfoPopup",
    "endClampInfoPopupSpanXr",
    "endClampInfoPopupSpanAire",
  ];
  static values = {
    clampNames: Object,
    clampConversions: Object,
    // only on preset page
    millOnXr: Boolean,
    attachmentHardwares: Object,
  };

  changeRailPlatform(event) {
    const railPlatform = event.target.value;
    this.#updateEndClamp(railPlatform);
    this.#updatePanelPicker(railPlatform);
    this.#updateRailLengths(railPlatform);
    this.#updateRailFinishes(railPlatform);
    this.#updateAttachmentHardware(railPlatform);
  }

  #updateEndClamp(railPlatform) {
    const clampNames = this.clampNamesValue[railPlatform];

    const currentValue = this.endClampSelectTarget.value;
    const convertTo = this.clampConversionsValue[currentValue];
    const selectOptions = clampNames.map((clamp) => {
      return [clamp[0], clamp[1], false, clamp[1] === convertTo];
    });

    addOptionsToSelect(selectOptions, this.endClampSelectTarget, false);

    if (railPlatform === "aire") {
      animate.hide(this.endClampInfoPopupSpanXrTarget, { fadeOut: false });
      animate.show(this.endClampInfoPopupSpanAireTarget, { fadeIn: false });
    } else {
      animate.show(this.endClampInfoPopupSpanXrTarget, { fadeIn: false });
      animate.hide(this.endClampInfoPopupSpanAireTarget, { fadeOut: false });
    }
  }

  #updatePanelPicker(railPlatform) {
    const panelPickerElement = document.querySelector("[data-controller='da--panel-picker']");
    if (!panelPickerElement) return;

    const panelPickerController = panelPickerElement.controller;
    panelPickerController.updateRailPlatform(railPlatform);
  }

  #updateRailLengths(railPlatform) {
    if (!this.#onPresetPage()) return;

    if (railPlatform === "aire") {
      this.railLengthsXrTarget.classList.add("d-none");
      this.railLengthsXrTarget.setAttribute("disabled", "");
      this.railLengthsAireTarget.removeAttribute("disabled");
      animate.show(this.railLengthsAireTarget);
    } else {
      this.railLengthsAireTarget.classList.add("d-none");
      this.railLengthsAireTarget.setAttribute("disabled", "");
      this.railLengthsXrTarget.removeAttribute("disabled");
      animate.show(this.railLengthsXrTarget);
    }
  }

  #updateRailFinishes(railPlatform) {
    if (!this.#onPresetPage()) return;

    const clearInput = this.railFinishClearTarget.querySelector("input");
    const millInput = this.railFinishMillTarget.querySelector("input");

    if (railPlatform === "aire") {
      if (clearInput.checked) {
        clearInput.checked = false;
        millInput.checked = true;
      }

      this.railFinishClearTarget.classList.add("d-none");
      animate.show(this.railFinishMillTarget);
    } else {
      if (millInput.checked) {
        clearInput.checked = true;
        millInput.checked = false;
      }

      if (!this.millOnXrValue) {
        this.railFinishMillTarget.classList.add("d-none");
      }
      animate.show(this.railFinishClearTarget);
    }
  }

  #updateAttachmentHardware(railPlatform) {
    if (!this.#onPresetPage()) return;

    const attachmentHardwares = this.attachmentHardwaresValue[railPlatform];

    const selectOptions = attachmentHardwares.map((hardware) => {
      return [hardware[0], hardware[1], false, false];
    });

    addOptionsToSelect(selectOptions, this.attachmentHardwareSelectTarget, false);

    if (railPlatform === "aire") {
      this.attachmentHardwareXrInfoPopupTarget.classList.add("d-none");
      animate.show(this.attachmentHardwareAireInfoPopupTarget, { display: "inline" });
    } else {
      animate.show(this.attachmentHardwareXrInfoPopupTarget, { display: "inline" });
      this.attachmentHardwareAireInfoPopupTarget.classList.add("d-none");
    }
  }

  #onPresetPage() {
    // Arbitrarily chose this target, could have been any of them that only appear on that page
    return this.hasRailLengthsXrTarget;
  }
}
