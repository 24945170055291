import Base from "../../../../da/map/interaction-managers/snap/base";

export default class RoofPlanes extends Base {
  get mainTargetSource() {
    return this.mapManager.roofPlanesVectorSource;
  }

  get quaternarySource() {
    return this.mapManager.rulersVectorSource;
  }
}
