import { Controller } from "@hotwired/stimulus";

export default class GoogleMapFullPage extends Controller {
  static values = { lat: Number, lng: Number, zoom: Number };

  connect() {
    const latLng = { lat: this.latValue, lng: this.lngValue };

    this.map = new google.maps.Map(this.element, {
      center: latLng,
      zoom: this.zoomValue,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      disableDefaultUI: true,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    });

    this.map.setTilt(0);

    new google.maps.Marker({
      position: latLng,
      map: this.map,
    });
  }
}
