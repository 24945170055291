import React from "react";
import PropTypes from "prop-types";
import TextField from "../../TextField";
import ToolbarBtn from "../Toolbar/ToolbarBtn";
import Column from "./Column";
import InfoPopupLink from "../../InfoPopupLink";

const Editable = ({
  onCropGrid,
  rows,
  columns,
  onRowsChange,
  onRowsFieldValueChange,
  onColumnsChange,
  onColumnsFieldValueChange,
  expandable,
  onToggleExpand,
  isExpanded,
}) => (
  <>
    <div
      className="ir-form graphical-tool__toolbar__button-group col-auto"
      style={{ paddingBottom: "1rem" }}
      data-testid="editable"
    >
      <div>
        <label>Grid {expandable && <InfoPopupLink dialogIdentifier="info-popup-graphical-tool-height" />}</label>
      </div>
      <ToolbarBtn icon="crop" text="Crop" onClick={onCropGrid} />
      {expandable && (
        <ToolbarBtn
          icon={`long-arrow-alt-${isExpanded ? "up" : "down"}`}
          text={isExpanded ? "Contract" : "Expand"}
          onClick={onToggleExpand}
        />
      )}
    </div>

    <Column>
      <TextField
        label="Rows"
        id={null}
        value={rows}
        onScrollButtonClick={(amount) => {
          onRowsChange(Number.parseInt(rows) + Number.parseInt(amount));
        }}
        onFieldBlur={onRowsChange}
        onFieldPressEnter={onRowsChange}
        onFieldChange={onRowsFieldValueChange}
      />
    </Column>
    <Column>
      <TextField
        label="Columns"
        id={null}
        value={columns}
        onScrollButtonClick={(amount) => {
          onColumnsChange(Number.parseInt(columns) + Number.parseInt(amount));
        }}
        onFieldBlur={onColumnsChange}
        onFieldPressEnter={onColumnsChange}
        onFieldChange={onColumnsFieldValueChange}
      />
    </Column>
  </>
);

Editable.propTypes = {
  rows: PropTypes.string.isRequired,
  columns: PropTypes.string.isRequired,
  onCropGrid: PropTypes.func.isRequired,
  onRowsChange: PropTypes.func.isRequired,
  onRowsFieldValueChange: PropTypes.func.isRequired,
  onColumnsChange: PropTypes.func.isRequired,
  onColumnsFieldValueChange: PropTypes.func.isRequired,
  expandable: PropTypes.bool,
  onToggleExpand: PropTypes.func,
  isExpanded: PropTypes.bool,
};

export default React.memo(Editable);
