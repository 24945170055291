import { types } from "mobx-state-tree";

const LatLngModel = types
  .model("LatLngModel", {
    lat: types.number,
    lng: types.number,
  })
  .views((self) => ({
    get toLatLng() {
      return [self.lat, self.lng];
    },
    get toLonLat() {
      return [self.lng, self.lat];
    },
    get toLatLngString() {
      return JSON.stringify(this.toLatLng);
    },
    get clone() {
      return LatLngModel.create({
        lat: self.lat,
        lng: self.lng,
      });
    },
    get toObjectLiteral() {
      return {
        lat: self.lat,
        lng: self.lng,
      };
    },
    plus(other) {
      return LatLngModel.create({
        lat: self.lat + other.lat,
        lng: self.lng + other.lng,
      });
    },
    minus(other) {
      return LatLngModel.create({
        lat: self.lat - other.lat,
        lng: self.lng - other.lng,
      });
    },
    times(multiplier) {
      return LatLngModel.create({
        lat: self.lat * multiplier,
        lng: self.lng * multiplier,
      });
    },
    dividedBy(divisor) {
      return self.times(1.0 / divisor);
    },
  }));

export default LatLngModel;
