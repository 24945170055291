import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hiddenField", "feetField", "inchesField"];

  connect() {
    this.feet = Number.parseInt(this.feetFieldTarget.value) || 0;
    this.inches = Number.parseInt(this.inchesFieldTarget.value) || 0;
  }

  keyupFeet = (event) => {
    this.setUpdatedValue("feet", event);
  };

  keyupInches = (event) => {
    this.setUpdatedValue("inches", event);
  };

  onlyAllowNumber = (event) => {
    if (!event.key || event.key.length > 1) return;

    if (!/\d/.test(event.key)) event.preventDefault();
  };

  setUpdatedValue(type, event) {
    this[type] = Number.parseInt(event.currentTarget.value) || 0;
    this.setHiddenFieldValue();
  }

  setHiddenFieldValue() {
    let feetWithInches;
    if (this.inches === 2 || this.inches === 5 || this.inches === 8 || this.inches === 11) {
      // subtract 0.0001 to make sure rounding never causes the re-inflated
      // value to exceed what the user typed, e.g. 11 inches -> 10.9992 rather
      // than 11.00399999
      feetWithInches = (this.feet + this.inches / 12 - 0.0001).toFixed(4);
    } else {
      feetWithInches = (this.feet + this.inches / 12).toFixed(4);
    }

    this.hiddenFieldTarget.value = feetWithInches;
    const changeEvent = new CustomEvent("change", { bubbles: true });
    this.hiddenFieldTarget.dispatchEvent(changeEvent);
  }

  fieldBlur = (event) => {
    const field = event.currentTarget;
    const value = field.value;
    const type = field.dataset.type;
    if (value === "") field.value = 0;
    if (type === "inches" && value >= 12) {
      this.updateDisplayFromHiddenField();
    }
  };

  updateDisplayFromHiddenField() {
    const value = Number.parseFloat(this.hiddenFieldTarget.value) || 0.0;
    this.feet = Math.floor(value);
    this.inches = Math.round((value - this.feet) * 12);
    this.feetFieldTarget.value = this.feet;
    this.inchesFieldTarget.value = this.inches;
  }
}
