import { ARRAY_CELL_DATA_TYPE } from "../../da/map/data-types";
import { removeFeaturesDescendedFromRoofPlaneUuid, removeFeaturesMatchingUuid } from "../../da/map/ol-helpers";

export function removeFeaturesForRoofPlane(roofPlane, mapManager) {
  removeFeaturesDescendedFromRoofPlaneUuid(mapManager.setbacksVectorSource, roofPlane.uuid);
  removeFeaturesMatchingUuid(mapManager.roofPlanesVectorSource, roofPlane.uuid);
}

export function removeFeaturesForObstruction(obstruction, mapManager) {
  removeFeaturesMatchingUuid(mapManager.obstructionBuffersVectorSource, obstruction.uuid);
  removeFeaturesMatchingUuid(mapManager.obstructionsVectorSource, obstruction.uuid);
}

export function removeArrayCellFeaturesDescendedFromRoofSectionUuid(vectorSource, roofSectionUuid) {
  const features = vectorSource.getFeatures();

  features.forEach((feature) => {
    if (feature.get("roofSectionUuid") === roofSectionUuid && feature.get("dataType") === ARRAY_CELL_DATA_TYPE) {
      vectorSource.removeFeature(feature);
    }
  });
}

export function logState(mapModelSynchronizer, includeArrays = true) {
  const { project, controller } = mapModelSynchronizer;

  LogRoofPlane(project, controller, mapModelSynchronizer);
  LogObstructions(project, controller, mapModelSynchronizer);
  LogRoofSections(project, controller, mapModelSynchronizer);
  LogLayouts(project);
  logArrays(controller, includeArrays);

  logFeatures(controller.mapManager.roofPlanesVectorSource, "Roof Planes");
  logFeatures(controller.mapManager.obstructionsVectorSource, "Obstructions");
  logFeatures(controller.mapManager.obstructionBuffersVectorSource, "Obstruction Buffers");
  logFeatures(controller.mapManager.roofSectionsVectorSource, "Roof Sections");
  if (includeArrays) {
    logFeatures(controller.mapManager.arraysVectorSource, "Arrays");
  }
  logFeatures(controller.mapManager.setbacksVectorSource, "Setbacks");
}

function logFeatures(source, label) {
  console.log(`All features in: ${label}`);

  source.getFeatures().forEach((f) => {
    console.log(f);
  });
}

function LogItem(model, feature) {
  const fPrint = (attr) => {
    return feature === undefined ? "NA" : feature.get(attr);
  };

  this.mDbId = model.id;
  this.mIdentifier = model.displayIdentifier;
  this.mDirty = model.dirty;

  this.fIdentifier = fPrint("identifier");

  this.mUuid = model.uuid;
  this.fUuid = fPrint("uuid");

  this.mIllegal = model.illegalShape;
  this.fIllegal = fPrint("illegalShape");

  this.fDataType = fPrint("dataType");

  if (model.dataType === "RoofSection") {
    this.mCellsErr = model.cellsWithErrors;
    this.fCellsErr = fPrint("cellsWithErrors");
    this.mRpUuid = model.roofPlane.uuid;
    this.fRpUuid = fPrint("roofPlaneUuid");
    this.mCustom = model.custom;
    this.fCustom = fPrint("custom");
    this.mActive = model.active;
    this.mEncroaching = model.encroaching;
    this.fEncroaching = fPrint("encroaching");
  }

  if (model.dataType === "Obstruction") {
    this.height = model.height;
  }

  this.mDeleted = model.deleted;
}

function LogLayout(model) {
  this.mDbId = model.id;
  this.mDirty = model.dirty;
  this.mRsIdentifier = model.roofSection.displayIdentifier;
  this.mRsUuid = model.roofSection.uuid;
  this.rows = model.rows;
  this.columns = model.columns;
  this.panelsPresent = model.panelsPresent;
  this.mDeleted = model.deleted;
}

function LogRoofPlane(project, controller, mapModelSynchronizer) {
  console.log("ROOF PLANES");

  const roofPlaneLogItems = project.roofPlanes.map((rp) => {
    const feature = mapModelSynchronizer.getFeatureForRoofPlane(rp);
    return new LogItem(rp, feature);
  });
  console.table(roofPlaneLogItems);

  const roofPlaneFeatures = [];
  controller.mapManager.roofPlanesFeatures.forEach((feature) => {
    const uuid = feature.get("uuid");
    const model = project.getRoofPlane(uuid);
    if (model === undefined) {
      roofPlaneFeatures.push(feature);
    }
  });
  if (roofPlaneFeatures.length > 0) {
    console.log("Unmatched roof plane features");
    roofPlaneFeatures.forEach((f) => {
      console.log("Feature", f.get("uuid"), f);
    });
  }
}

function LogObstructions(project, controller, mapModelSynchronizer) {
  if (project.isBX) {
    console.log("OBSTRUCTIONS");

    const obstructionLogItems = project.obstructions.map((obstruction) => {
      const feature = mapModelSynchronizer.getFeatureForObstruction(obstruction);
      return new LogItem(obstruction, feature);
    });
    console.table(obstructionLogItems);

    const obstructionFeatures = [];
    controller.mapManager.obstructionsFeatures.forEach((feature) => {
      const uuid = feature.get("uuid");
      const model = project.getObstruction(uuid);
      if (model === undefined) {
        obstructionFeatures.push(feature);
      }
    });
    if (obstructionFeatures.length > 0) {
      console.log("Unmatched obstruction features");
      obstructionFeatures.forEach((f) => {
        console.log("Feature", f.get("uuid"), f);
      });
    }
  }
}

function LogRoofSections(project, controller, mapModelSynchronizer) {
  console.log("ROOF SECTIONS");

  const roofSectionLogItems = project.roofPlanes.flatMap((rp) => {
    return rp.roofSections.map((rs) => {
      const feature = mapModelSynchronizer.getFeatureForRoofSection(rs, false);
      return new LogItem(rs, feature);
    });
  });
  console.table(roofSectionLogItems);

  const roofSectionFeatures = [];
  controller.mapManager.roofSectionsFeatures.forEach((feature) => {
    const uuid = feature.get("uuid");
    const model = project.getRoofSection(uuid);
    if (model === undefined) {
      roofSectionFeatures.push(feature);
    }
  });
  if (roofSectionFeatures.length > 0) {
    console.log("Unmatched roof section features");
    roofSectionFeatures.forEach((f) => {
      console.log("Feature", f.get("uuid"), f);
    });
  }
}

function LogLayouts(project) {
  console.log("LAYOUTS");

  const LayoutLogItems = project.roofPlanes.flatMap((rp) => {
    return rp.roofSections.map((rs) => {
      return rs.layout && new LogLayout(rs.layout);
    });
  });
  console.table(LayoutLogItems);
}

function logArrays(controller, includeArrays) {
  if (includeArrays) {
    console.log("ROOF SECTIONS ARRAY FEATURES");
    controller.mapManager.arraysVectorSource.getFeatures().map((feature) => {
      console.log(feature.get("uuid"));
    });
  }
}
