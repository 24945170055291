import Collection from "ol/Collection";

import { SETBACK_DATA_TYPE } from "../../../../da/map/data-types";
import DaBase from "../../../../da/map/interaction-managers/translate/base";
import { removeFeaturesDescendedFromRoofSectionUuid } from "../../../../da/map/ol-helpers";
import { translateRoofSection } from "../../modification-helpers/roof-section";

export default class RoofSections extends DaBase {
  translating = (event) => {
    if (this.alreadyRemovedArray) return;

    const { features, startCoordinate, coordinate } = event;

    if (startCoordinate[0] === coordinate[0] && startCoordinate[0] === coordinate[0]) return;

    // for performance reasons, pull the array of panels out of OpenLayers while the user is dragging
    features.forEach((rsFeature) => {
      const uuid = rsFeature.get("uuid");
      removeFeaturesDescendedFromRoofSectionUuid(this.mapManager.arraysVectorSource, uuid);
    });
    this.alreadyRemovedArray = true;
  };

  get translatableFeatures() {
    const features = this.controller.selectInteractionManager.selectionCollection.getArray();
    const filteredFeatures = features.filter((f) => f.get("dataType") !== SETBACK_DATA_TYPE);
    return new Collection(filteredFeatures);
  }

  translateEndForFeature(rsFeature) {
    translateRoofSection(rsFeature, this.project, this.mapModelSynchronizer, this.mapManager, this.controller);

    this.controller.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: "RoofSectionsTranslateInteractionManager#translateEndForFeature",
    });
  }
}
