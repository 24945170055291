import { Fill, Stroke, Style } from "ol/style";

import {
  CELL_DRAW_STYLE_OBSTRUCTED_EMPTY,
  CELL_DRAW_STYLE_OBSTRUCTED_PRESENT,
  CELL_DRAW_STYLE_OBSTRUCTED_PRESENT_WITH_ERROR,
  CELL_DRAW_STYLE_PRESENT,
  CELL_DRAW_STYLE_PRESENT_WITH_ERROR,
} from "../../../bx/models/layout-model";
import { BOUNDING_BOX_DATA_TYPE } from "../data-types";

const BOUNDING_BOX_STYLE = new Style({
  stroke: new Stroke({ color: "rgba(0, 250, 255, 0.6)", width: 3 }),
});

const FRESH_CELL_STROKE = new Stroke({ color: "rgba(0, 141, 249, 1.0)", width: 3 });
const FRESH_CELL_FILL = new Fill({ color: "rgba(0, 141, 249, 0.4)" });
// This should match the encroaching roof sections colors
const STALE_CELL_STROKE = new Stroke({ color: "rgba(255, 109, 0, 0.7)", width: 3 });
const STALE_CELL_FILL = new Fill({ color: "rgba(255, 109, 0, 0.2)" });
const ERROR_CELL_STROKE = new Stroke({ color: "rgba(255,0, 0, 1.0)", width: 3 });
const ERROR_CELL_FILL = new Fill({ color: "rgba(255,0, 0, 0.3)" });

const PRESENT_FRESH_STYLE = new Style({
  stroke: FRESH_CELL_STROKE,
  fill: FRESH_CELL_FILL,
});
const PRESENT_STALE_STYLE = new Style({
  stroke: STALE_CELL_STROKE,
  fill: STALE_CELL_FILL,
});
const PRESENT_ERROR_STYLE = new Style({
  stroke: ERROR_CELL_STROKE,
  fill: ERROR_CELL_FILL,
});

const EMPTY_CELL_STYLE = new Style({
  stroke: new Stroke({ color: `rgba(160, 160, 160, 1.0)`, width: 1 }),
  fill: new Fill({ color: `rgba(0, 0, 0, 0.0)` }),
});

const OBSTRUCTED_PRESENT_FRESH_STYLE = new Style({
  stroke: new Stroke({ color: `rgba(133, 97, 140, 1.0)`, width: 3 }),
  fill: new Fill({ color: `rgba(133, 97, 140, 0.4)` }),
});
const OBSTRUCTED_EMPTY_CELL_STYLE = EMPTY_CELL_STYLE;

export function arraysStyle(feature, { hideEmptyCells = false, isProjectReport = false }) {
  if (feature.get("dataType") === BOUNDING_BOX_DATA_TYPE) {
    return BOUNDING_BOX_STYLE;
  } else {
    const cellDrawStyle = feature.get("cellDrawStyle");
    if (cellDrawStyle >= CELL_DRAW_STYLE_OBSTRUCTED_EMPTY) {
      return arrayStyleObstructed(feature, cellDrawStyle, hideEmptyCells, isProjectReport);
    } else {
      return arrayStyleUnobstructed(feature, cellDrawStyle, hideEmptyCells);
    }
  }
}

function arrayStyleUnobstructed(feature, cellDrawStyle, hideEmptyCells) {
  if (cellDrawStyle === CELL_DRAW_STYLE_PRESENT) {
    return feature.get("fresh") ? PRESENT_FRESH_STYLE : PRESENT_STALE_STYLE;
  } else if (cellDrawStyle === CELL_DRAW_STYLE_PRESENT_WITH_ERROR) {
    return PRESENT_ERROR_STYLE;
  } else if (!hideEmptyCells) {
    return EMPTY_CELL_STYLE;
  }
}

function arrayStyleObstructed(feature, cellDrawStyle, hideEmptyCells, isProjectReport) {
  if (cellDrawStyle === CELL_DRAW_STYLE_OBSTRUCTED_PRESENT) {
    if (feature.get("fresh")) {
      return isProjectReport ? PRESENT_FRESH_STYLE : OBSTRUCTED_PRESENT_FRESH_STYLE;
    } else {
      return PRESENT_STALE_STYLE;
    }
  } else if (cellDrawStyle === CELL_DRAW_STYLE_OBSTRUCTED_PRESENT_WITH_ERROR) {
    return PRESENT_ERROR_STYLE;
  } else if (!hideEmptyCells) {
    return OBSTRUCTED_EMPTY_CELL_STYLE;
  }
}
