import { Controller } from "@hotwired/stimulus";

import * as toolbarBtn from "../../../da/layout-editor/helpers/toolbar-btns";
import * as store from "../../../helpers/local-store";

export default class BtnSelectGroup extends Controller {
  static targets = ["flyoutMenuBtn", "optionBtn"];
  static values = { localStorageKey: String, defaultOption: String };

  connect() {
    this.element.selectGroupController = this;

    let initialOption;
    if (
      this.hasLocalStorageKeyValue &&
      store.has(this.localStorageKey) &&
      this.hasButtonForOption(store.get(this.localStorageKey))
    ) {
      initialOption = store.get(this.localStorageKey);
    } else if (this.hasDefaultOptionValue) {
      initialOption = this.defaultOptionValue;
    } else {
      initialOption = this.optionBtnTargets[0].dataset.option;
    }

    this.selectOption(initialOption);
  }

  hasButtonForOption(option) {
    return this.buttonForOption(option) !== undefined;
  }

  buttonForOption(option) {
    return this.optionBtnTargets.find((btn) => btn.dataset.option === option);
  }

  get localStorageKey() {
    return `le--selectGroup--${this.localStorageKeyValue}`;
  }

  optionBtnClick(event) {
    const button = event.currentTarget;
    if (toolbarBtn.isDisabled(button)) return;

    this.selectOption(button.dataset.option);
  }

  selectOption(option) {
    this.selectedOption = option;

    const button = this.buttonForOption(option);
    if (button === undefined) throw `BtnSelectGroup#selectionOptions: "${option}Btn" can not be found`;

    const buttonIcon = button.querySelector(".fas");

    if (buttonIcon) {
      const iconName = buttonIcon.className.replace("fas fa-", "");
      if (this.hasFlyoutMenuBtnTarget) toolbarBtn.setIcon(this.flyoutMenuBtnTarget, iconName);
    } else {
      const buttonSpanContent = button.querySelector("span").innerHTML;
      if (this.hasFlyoutMenuBtnTarget) {
        toolbarBtn.setSpanContent(this.flyoutMenuBtnTarget, buttonSpanContent);
        const addRemove = option.length > 3 ? "add" : "remove";
        this.flyoutMenuBtnTarget.classList[addRemove]("ol-map__btn--small-font");
      }
    }

    this.optionBtnTargets.forEach((btn) => {
      if (btn.dataset.option === this.selectedOption) {
        toolbarBtn.activate(btn);
      } else {
        toolbarBtn.deactivate(btn);
      }
    });

    if (this.hasLocalStorageKeyValue) store.set(this.localStorageKey, this.selectedOption);
  }
}
