import { getParent, types } from "mobx-state-tree";

import ObstructionBufferModel, { obstructionBufferPersistenceData } from "./obstruction-buffer-model";
import WithLatLngPoints from "../../da/models/with-lat-lng-points";
import WithCartesianPoints from "../../da/models/with-cartesian-points";
import WithDirtyTracking from "../../da/models/with-dirty-tracking";
import CartesianModel from "../../da/models/cartesian-model";

import { OBSTRUCTION_DATA_TYPE } from "../../da/map/data-types";
import { pointsForSave } from "../../da/models/points-utilities";
import { logger } from "../../helpers/app";

const ObstructionModelBase = types
  .model("ObstructionModel", {
    uuid: types.identifier,
    id: types.maybe(types.integer),
    deleted: types.optional(types.boolean, false),
    identifier: types.optional(types.string, ""),
    illegalShape: types.optional(types.boolean, false),
    height: types.maybeNull(types.number),
    globalCartesianOffset: types.maybe(CartesianModel),
    buffer: types.maybeNull(ObstructionBufferModel),
    // store UUIDs to handle both saved and unsaved roof planes/sections
    nearbyRoofPlanes: types.array(types.string),
    nearbyRoofSections: types.array(types.string),
  })
  .views((self) => ({
    get dataType() {
      return OBSTRUCTION_DATA_TYPE;
    },
    get project() {
      return getParent(self, 2);
    },
    get needsSave() {
      return self.dirty || !self.id;
    },
    get hasBuffer() {
      return self.buffer !== null;
    },
    get displayIdentifier() {
      return self.identifier;
    },
    get notDeleted() {
      return !self.deleted;
    },
  }))
  .actions((self) => ({
    setIdentifier(newIdentifier) {
      if (self.identifier === newIdentifier) return;

      self.markDirty();
      self.identifier = newIdentifier;
    },
    setIllegalShape(legality) {
      if (self.illegalShape === legality) return;

      self.markDirty();
      self.illegalShape = legality;
    },
    setHeight(height) {
      const newHeight = Number.parseFloat(height);
      if (self.height === newHeight) return;

      self.markDirty();
      self.height = newHeight;
    },
    flagDeleted() {
      if (!self.deleted) {
        self.markDirty();
        self.deleted = true;
      }
    },
    addBuffer() {
      self.buffer = ObstructionBufferModel.create();
      self.markDirty();

      return self.buffer;
    },
    removeBuffer() {
      if (!self.buffer) return;

      self.markDirty();
      self.buffer = null;
    },
    setGlobalCartesianOffset(delta) {
      if (
        self.globalCartesianOffset &&
        self.globalCartesianOffset.x === delta.x &&
        self.globalCartesianOffset.y === delta.y
      )
        return;

      self.markDirty();
      self.globalCartesianOffset = delta;
    },
    addRoofPlane(roofPlane) {
      if (self.nearbyRoofPlanes.includes(roofPlane.uuid)) return;

      self.markDirty();
      self.nearbyRoofPlanes.push(roofPlane.uuid);
    },
    removeRoofPlane(roofPlane) {
      if (!self.nearbyRoofPlanes.includes(roofPlane.uuid)) return;

      self.markDirty();
      self.nearbyRoofPlanes = self.nearbyRoofPlanes.filter((item) => item !== roofPlane.uuid);
    },
    addRoofSection(roofSection) {
      if (self.nearbyRoofSections.includes(roofSection.uuid)) return;

      self.markDirty();
      self.nearbyRoofSections.push(roofSection.uuid);
    },
    removeRoofSection(roofSection) {
      self.clearRoofSectionUuid(roofSection.uuid);
    },
    clearRoofSectionUuid(roofSectionUuid) {
      if (!self.nearbyRoofSections.includes(roofSectionUuid)) return;

      self.markDirty();
      self.nearbyRoofSections = self.nearbyRoofSections.filter((item) => item !== roofSectionUuid);
    },
  }));

export function obstructionPersistenceData(obstruction) {
  logger(`Persisting Obstruction ${obstruction.identifier}`);

  if (!obstruction.needsSave) {
    logger("--!needsSave. Skipping");
    return undefined;
  }

  const latLngPoints = pointsForSave(obstruction, "latLngPoints");
  const cartesianPoints = pointsForSave(obstruction, "cartesianPoints");
  const globalCartesianOffset = obstruction.globalCartesianOffset?.toArray || [0, 0];

  const bufferData = obstructionBufferPersistenceData(obstruction.buffer);

  const data = {
    uuid: obstruction.uuid,
    id: obstruction.id,
    identifier: obstruction.identifier,
    lat_lng_points: latLngPoints,
    cartesian_points: cartesianPoints,
    illegal_shape: obstruction.illegalShape,
    _destroy: obstruction.deleted,
    height: obstruction.height,
    allow_blank_height: true,
    global_cartesian_offset: globalCartesianOffset,
    buffer_lat_lng_points: bufferData.lat_lng_points,
    buffer_cartesian_points: bufferData.cartesian_points,
    buffer_global_cartesian_offset: bufferData.global_cartesian_offset,
    nearby_roof_planes: obstruction.nearbyRoofPlanes,
    nearby_roof_sections: obstruction.nearbyRoofSections,
  };

  return data;
}

const ObstructionModel = types.compose(ObstructionModelBase, WithLatLngPoints, WithCartesianPoints, WithDirtyTracking);
export default ObstructionModel;
