import React from "react";
import PropTypes from "prop-types";

import ToolbarBtn from "./ToolbarBtn";
import SaveWithCropPopup from "./SaveWithCropPopup";
import InfoPopupLink from "../../InfoPopupLink";
import { isContourEast, isContourSouth, isContourWest } from "../../../helpers/contour";

import {
  GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR,
  GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT,
} from "../../../helpers/graphical-tool-helpers";

function GridTools({
  selectedToolbarItem,
  onChangeActiveToolbarItem,
  onClearGrid,
  saving,
  saveWithOption,
  onSave,
  onShiftPanelsOnGrid,
  needsSaving,
  askToSaveWithCropOrNot,
  closeSaveOptionsPopup,
  editorMode,
  contourPaintMode,
  handleContourPaintModeChange,
  isRailOrientationNs,
  railPlatform,
  hiddenEndClamp,
  editableGrid,
  isValidGrid,
}) {
  const onClickLeft = () => onShiftPanelsOnGrid("left");
  const onClickRight = () => onShiftPanelsOnGrid("right");
  const onClickDown = () => onShiftPanelsOnGrid("down");
  const onClickUp = () => onShiftPanelsOnGrid("up");

  const allowSouthContour = !isRailOrientationNs || hiddenEndClamp;
  const allowEastWestContour = isRailOrientationNs || hiddenEndClamp;

  const isModeSouth = isContourSouth(contourPaintMode);
  const isModeEast = isContourEast(contourPaintMode);
  const isModeWest = isContourWest(contourPaintMode);

  const contourDisabledMessage = `Disabled: requires ${railPlatform === "xr" ? "CAMO" : "AireLock Stealth"}`;

  return (
    <div className="ir-form graphical-tool__toolbar__buttons-container">
      <div className="graphical-tool__toolbar__button-group">
        <div>
          <label>
            Grid tools <InfoPopupLink dialogIdentifier="info-popup-pr-graphical-tool-grid-tools" />
          </label>
        </div>
        <ToolbarBtn
          icon="pencil-alt"
          text="Add"
          selectedToolbarItem={selectedToolbarItem}
          onClick={onChangeActiveToolbarItem}
        />
        <ToolbarBtn
          icon="eraser"
          text="Remove"
          selectedToolbarItem={selectedToolbarItem}
          onClick={onChangeActiveToolbarItem}
        />
        <ToolbarBtn
          icon="toggle-on"
          text="Toggle"
          selectedToolbarItem={selectedToolbarItem}
          onClick={onChangeActiveToolbarItem}
        />
      </div>
      <ToolbarBtn icon="broom" text="Clear all" onClick={onClearGrid} />
      {editorMode === GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT && (
        <div className="graphical-tool__toolbar__button-group">
          <div>
            <label>
              Shift <InfoPopupLink dialogIdentifier="info-popup-pr-graphical-tool-shift" />
            </label>
          </div>
          <ToolbarBtn icon="arrow-left" text="Shift left" iconOnly onClick={onClickLeft} showTooltip />
          <ToolbarBtn icon="arrow-right" text="Shift right" iconOnly onClick={onClickRight} showTooltip />
          <ToolbarBtn icon="arrow-down" text="Shift down" iconOnly onClick={onClickDown} showTooltip />
          <ToolbarBtn icon="arrow-up" text="Shift up" iconOnly onClick={onClickUp} showTooltip />
        </div>
      )}
      {editorMode === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR && (
        <div className="graphical-tool__toolbar__button-group">
          <div>
            <label>
              Contour <InfoPopupLink dialogIdentifier="info-popup-pr-graphical-tool-contour" />
            </label>
          </div>
          <ToolbarBtn
            icon="border-bottom"
            text="South"
            iconOnly
            selectedToolbarItem={isModeSouth ? "south" : ""}
            onClick={() => {
              handleContourPaintModeChange("south");
            }}
            showTooltip
            disabled={!allowSouthContour}
            disabledTooltipMessage={contourDisabledMessage}
          />
          <ToolbarBtn
            icon="border-left"
            text="West"
            iconOnly
            selectedToolbarItem={isModeWest ? "west" : ""}
            onClick={() => {
              handleContourPaintModeChange("west");
            }}
            showTooltip
            disabled={!allowEastWestContour}
            disabledTooltipMessage={contourDisabledMessage}
          />
          <ToolbarBtn
            icon="border-right"
            text="East"
            iconOnly
            selectedToolbarItem={isModeEast ? "east" : ""}
            onClick={() => {
              handleContourPaintModeChange("east");
            }}
            showTooltip
            disabled={!allowEastWestContour}
            disabledTooltipMessage={contourDisabledMessage}
          />
        </div>
      )}
      {needsSaving && isValidGrid && (
        <ToolbarBtn icon="check" text="Save" showSpinner={saving} onClick={onSave} preventDoubleClick />
      )}
      {editableGrid && askToSaveWithCropOrNot && (
        <SaveWithCropPopup closeSaveOptionsPopup={closeSaveOptionsPopup} saveWithOption={saveWithOption} />
      )}
    </div>
  );
}

GridTools.propTypes = {
  selectedToolbarItem: PropTypes.string.isRequired,
  onChangeActiveToolbarItem: PropTypes.func.isRequired,
  onClearGrid: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  saveWithOption: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onShiftPanelsOnGrid: PropTypes.func.isRequired,
  needsSaving: PropTypes.bool.isRequired,
  askToSaveWithCropOrNot: PropTypes.bool.isRequired,
  closeSaveOptionsPopup: PropTypes.func.isRequired,
  editableGrid: PropTypes.bool.isRequired,
  railPlatform: PropTypes.string.isRequired,
  isValidGrid: PropTypes.bool.isRequired,
};

export default React.memo(GridTools);
