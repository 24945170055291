export default class SimpleLatLng {
  static fromLatLngModel(latLngModel) {
    return new SimpleLatLng(latLngModel.lat, latLngModel.lng);
  }

  static fromLonLat(lonLat) {
    return new SimpleLatLng(lonLat[1], lonLat[0]);
  }

  constructor(lat, lng) {
    this.lat = lat;
    this.lng = lng;
  }

  get toLonLat() {
    return [this.lng, this.lat];
  }

  get toSimpleObject() {
    return { lat: this.lat, lng: this.lng };
  }

  plus(other) {
    return new SimpleLatLng(this.lat + other.lat, this.lng + other.lng);
  }

  minus(other) {
    return new SimpleLatLng(this.lat - other.lat, this.lng - other.lng);
  }

  times(multiplier) {
    return new SimpleLatLng(this.lat * multiplier, this.lng * multiplier);
  }

  dividedBy(divisor) {
    return new SimpleLatLng(this.lat / divisor, this.lng / divisor);
  }
}
