import React from "react";
import PropTypes from "prop-types";
import DimensionMarker from "./DimensionMarker";

function DimensionMarkers({ markers }) {
  return markers.map(
    ({ label, dimension, orientation, x, y, width, height, compressedBeginning, compressedEnd }, i) => (
      <DimensionMarker
        key={i}
        label={label}
        dimension={dimension}
        orientation={orientation}
        x={x}
        y={y}
        width={width}
        height={height}
        compressedBeginning={compressedBeginning}
        compressedEnd={compressedEnd}
      />
    ),
  );
}

DimensionMarkers.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      dimension: PropTypes.string.isRequired,
      orientation: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      height: PropTypes.number,
      width: PropTypes.number,
      compressedBeginning: PropTypes.bool.isRequired,
      compressedEnd: PropTypes.bool.isRequired,
    }),
  ),
};

export default React.memo(DimensionMarkers);
