import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = ["usContainer", "internationalContainer", "usSearchContainer", "linkContainer"];

  showUsSearch(event) {
    this.handleTabClick(event);
    this.internationalContainerTarget.style.display = "none";
    this.usContainerTarget.style.display = "none";
    animate.show(this.usSearchContainerTarget);
  }

  showInternational(event) {
    this.handleTabClick(event);
    this.usSearchContainerTarget.style.display = "none";
    this.usContainerTarget.style.display = "none";
    animate.show(this.internationalContainerTarget);
  }

  showAll(event) {
    this.handleTabClick(event);
    this.usSearchContainerTarget.style.display = "none";
    animate.show(this.internationalContainerTarget);
    animate.show(this.usContainerTarget);
  }

  handleTabClick(event) {
    event.preventDefault();
    const link = event.currentTarget;
    this.linkContainerTargets.forEach((l) => l.classList.remove("active"));
    link.closest("li").classList.add("active");
  }
}
