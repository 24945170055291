import SegmentsProximityAlignmentsIdentifier from "./identifier";
import BaseRenderer from "./base-renderer";

import { ADJOINMENT_DATA_TYPE } from "../../../../../da/map/data-types";
import { ADJOINMENT_HORIZONTAL_THRESHOLD, ADJOINMENT_VERTICAL_THRESHOLD } from "../../../../models/adjoinment-model";

export default class SegmentsAdjoinmentsRenderer extends BaseRenderer {
  static render(controller) {
    const identifier = new SegmentsProximityAlignmentsIdentifier({
      controller,
      horizontalRange: ADJOINMENT_HORIZONTAL_THRESHOLD,
      verticalRange: ADJOINMENT_VERTICAL_THRESHOLD,
    });
    identifier.identify();
    new SegmentsAdjoinmentsRenderer(controller, identifier).render();
  }

  clearAdjoinmentsOnSegments() {
    Object.values(this.segmentsByUuid).forEach((segment) => {
      segment.clearAdjoinments();
    });
  }

  addAdjoinmentFeature(feature) {
    feature.set("dataType", ADJOINMENT_DATA_TYPE);
    this.mapManager.adjoinmentsVectorSource.addFeature(feature);
  }

  addAdjoinment(segment, adjoinmentData) {
    segment.addAdjoinment(adjoinmentData);
  }
}
