import { Stroke, Style, Circle, Text, Fill } from "ol/style";
import MultiPoint from "ol/geom/MultiPoint";
import { GeometryType } from "../ol-helpers";

import { buildSimpleEdgeList } from "../edge-list";
import { addDimensionMarkersToSegmentsBetweenCoordinates, lineWithDistancePills } from "./distance-markers";

const MEASURE_COLOR = "241, 0, 200";
const MEASURE_ADJUSTED_COLOR = "12, 187, 170";

export function measureDrawStyle(feature, map) {
  const color = measureColor(feature);
  return lineWithDistancePills(feature, map, color, color, 3, true);
}

function measureColor(feature) {
  return feature.get("slopeAdjustedDistance") ? MEASURE_ADJUSTED_COLOR : MEASURE_COLOR;
}

export function selectMeasureStyle(feature, controller, map) {
  const styles = [];

  const color = measureColor(feature);
  const strokeColor = `rgba(${color}, 1.0)`;
  const fillColor = `rgba(${color}, 0.3)`;

  const lineStringStyle = new Style({
    stroke: new Stroke({ color: strokeColor, width: 3 }),
    fill: new Fill({ color: fillColor }),
    zIndex: 1,
  });
  styles.push(lineStringStyle);

  const vertices = verticesOfSelectedMeasure(feature);

  const vertexOuterCircleStyle = new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({ color: strokeColor }),
      snapToPixel: true,
    }),
    geometry: vertices,
  });
  styles.push(vertexOuterCircleStyle);

  const vertexInnerCircleStyle = new Style({
    image: new Circle({
      radius: 4,
      fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }),
      snapToPixel: true,
    }),
    geometry: vertices,
    zIndex: 2,
  });
  styles.push(vertexInnerCircleStyle);

  addDimensionMarkerToMeasure(feature, styles, map, strokeColor);

  return styles;
}

function addDimensionMarkerToMeasure(feature, styles, map, lineColor) {
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();
  if (geometryType === GeometryType.LINE_STRING) {
    const coordinates = geometry.getCoordinates();

    let edgeList;
    const slopeAdjustedDistance = feature.get("slopeAdjustedDistance");

    if (slopeAdjustedDistance) {
      const lineStringCoordinates = geometry.getCoordinates();
      edgeList = [{ segment: lineStringCoordinates, distance: slopeAdjustedDistance }];
    } else {
      edgeList = buildSimpleEdgeList(coordinates);
    }

    addDimensionMarkersToSegmentsBetweenCoordinates(styles, map, lineColor, edgeList);
  }
}

function verticesOfSelectedMeasure(feature) {
  const geometry = feature.getGeometry();
  const coordinates = geometry.getCoordinates();

  return new MultiPoint(coordinates);
}
