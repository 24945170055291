import { createNewSegment, renderNewSegment } from "../segments/helpers";
import SegmentsRailGroup from "../../map-model-synchronizers/segments-rail-group";
import { getSnapshot } from "mobx-state-tree";
import SimpleLatLng from "../../../../da/map/models/simple-lat-lng";
import { clearOutAnyIllegalThermalExpansions } from "./helpers";
import { cartesianPointRelativeTo } from "../../../../da/map/ol-geometry";

export default class PanelsDeleter {
  constructor({ controller, selectionCollection }) {
    this.controller = controller;
    this.selectionCollection = selectionCollection;

    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.mapManager = controller.mapManager;

    this.railGroups = SegmentsRailGroup.uniqueGroupsFromPanelFeatures({
      controller,
      features: selectionCollection.getArray(),
    });
  }

  delete(deleteType = "unrailed") {
    this.deleteType = deleteType;
    this.railGroups.forEach((railGroup) => {
      this.#deletePanelsAndGenerateNewRailGroups(railGroup);
    });
  }

  #deletePanelsAndGenerateNewRailGroups(railGroup) {
    const railGroupsSets = this.#railGroupDeletionProcessingSets(railGroup);
    const creationData = this.#generateRailGroupsCreationData(railGroupsSets);

    const roofSection = railGroup.roofSection;
    const orientation = railGroup.segments[0].orientation;

    railGroup.deleteSegments();

    this.#createAndRenderNewRailGroups(creationData, roofSection, orientation);
  }

  #createAndRenderNewRailGroups(railGroupsCreationData, roofSection, orientation) {
    railGroupsCreationData.forEach((newRailGroupCreationData) => {
      let parent;
      const segments = newRailGroupCreationData.map((segmentCreationData, i) => {
        segmentCreationData.orientation = orientation;

        if (i === 0) {
          segmentCreationData.railed = true;
        } else {
          segmentCreationData.railedParentUuid = parent.uuid;
          segmentCreationData.railed = false;
          segmentCreationData.distanceFromRailedParentStart = this.mapModelSynchronizer.getDistanceInInches(
            parent.startLatLng,
            new SimpleLatLng(segmentCreationData.startLatLng.lat, segmentCreationData.startLatLng.lng),
          );
        }

        const newSegment = createNewSegment(this.controller, roofSection, segmentCreationData);
        if (i === 0 && segmentCreationData.thermalExpansionStartLatLngs.length > 0) {
          const originLonLat = this.controller.project.detail.originLatLng.toLonLat;
          segmentCreationData.thermalExpansionStartLatLngs.forEach((startLatLng) => {
            const startCartesianPoint = cartesianPointRelativeTo(startLatLng.toLonLat, originLonLat);
            newSegment.addThermalExpansion({ startLatLng, startCartesianPoint });
          });
        }

        if (i === 0) parent = newSegment;

        return newSegment;
      });

      if (segments[0]) {
        clearOutAnyIllegalThermalExpansions(segments[0], this.mapModelSynchronizer);
      }

      segments.forEach((segment) => renderNewSegment(this.controller, segment));
    });
  }

  // Takes rail groups deletion processing sets and returns new rail groups creation data
  #generateRailGroupsCreationData(railGroupsDeletionProcessingSets) {
    const railGroupsCreationData = [];
    railGroupsDeletionProcessingSets.forEach((railGroupSet) => {
      const segmentsCreationData = [];
      const splitSegmentIndexes = [];

      let consolidatedColumnIndexOffset = 0;
      railGroupSet.simplifiedGridPattern.forEach((segmentPattern, rowIndex) => {
        // Break the segment pattern up on deletions
        const setNewSegmentFromPattern = (segmentColumnIndex) => {
          const startLatLng =
            railGroupSet.panelStartLatLngsForConsolidatedFirstRow[consolidatedColumnIndexOffset + segmentColumnIndex];

          const firstColumnOfZoneAndModulePositions = railGroupSet.zonesAndModulePositionsGrid.map((gridRow) => {
            return [gridRow[consolidatedColumnIndexOffset + segmentColumnIndex]];
          });

          const firstColumnOfContourCodes = railGroupSet.contourCodesGrid.map((gridRow) => {
            return [gridRow[consolidatedColumnIndexOffset + segmentColumnIndex]];
          });

          return {
            rows: railGroupSet.rows,
            columns: 1,
            startLatLng,
            zonesAndModulePositionsGrid: firstColumnOfZoneAndModulePositions,
            contourCodesGrid: firstColumnOfContourCodes,
          };
        };

        let currentSegmentFromPattern = undefined;
        segmentPattern.forEach((panelStatus, segmentColumnIndex) => {
          if (panelStatus === "O") {
            if (currentSegmentFromPattern) {
              currentSegmentFromPattern.columns++;
              // Add next column of zones and module positions grid data
              currentSegmentFromPattern.zonesAndModulePositionsGrid.forEach((gridRow, gridRowIndex) => {
                gridRow.push(
                  railGroupSet.zonesAndModulePositionsGrid[gridRowIndex][
                    consolidatedColumnIndexOffset + segmentColumnIndex
                  ],
                );
              });

              currentSegmentFromPattern.contourCodesGrid.forEach((gridRow, gridRowIndex) => {
                gridRow.push(
                  railGroupSet.contourCodesGrid[gridRowIndex][consolidatedColumnIndexOffset + segmentColumnIndex],
                );
              });
            } else {
              currentSegmentFromPattern = setNewSegmentFromPattern(segmentColumnIndex);
            }
          } else if (panelStatus === "X") {
            if (currentSegmentFromPattern) {
              segmentsCreationData.push(currentSegmentFromPattern);
              currentSegmentFromPattern = undefined;
            }
            if (!(segmentColumnIndex === 0 && rowIndex === 0)) {
              const index = segmentsCreationData.length === 0 ? 0 : segmentsCreationData.length - 1;
              splitSegmentIndexes.push(index);
            }
          }
        });

        if (currentSegmentFromPattern) {
          segmentsCreationData.push(currentSegmentFromPattern);
        }

        consolidatedColumnIndexOffset += segmentPattern.length;
      });

      if (segmentsCreationData[0]) {
        segmentsCreationData[0].thermalExpansionStartLatLngs = railGroupSet.thermalExpansionStartLatLngs;
      }

      // If we're doing unrailed deletions, we need to create new rail groups for each segment split.
      // This takes the segmentsCreationData and splits it into separate rail groups.
      if (
        this.deleteType === "unrailed" &&
        splitSegmentIndexes.length > 0 &&
        !(splitSegmentIndexes.length === 1 && splitSegmentIndexes[0] === segmentsCreationData.length - 1)
      ) {
        splitSegmentIndexes.push(segmentsCreationData.length - 1);

        for (let i = 0; i < splitSegmentIndexes.length; i++) {
          const previousSegmentIndexSplit = i === 0 ? 0 : splitSegmentIndexes[i - 1] + 1;
          const splitSegmentIndex = splitSegmentIndexes[i] + 1;
          if (previousSegmentIndexSplit <= splitSegmentIndex) {
            const newRailGroupFromSplit = segmentsCreationData.slice(previousSegmentIndexSplit, splitSegmentIndex);
            if (newRailGroupFromSplit.length > 0) {
              if (!newRailGroupFromSplit[0].thermalExpansionStartLatLngs) {
                newRailGroupFromSplit[0].thermalExpansionStartLatLngs = railGroupSet.thermalExpansionStartLatLngs.map(
                  (ll) => ll.clone,
                );
              }
              railGroupsCreationData.push(newRailGroupFromSplit);
            }
          }
        }
      } else {
        railGroupsCreationData.push(segmentsCreationData);
      }
    });

    return railGroupsCreationData;
  }

  // Data structures for creating new rail groups and segments with selected panels removed
  #railGroupDeletionProcessingSets(railGroup) {
    const selectionsGrid = railGroup.simplifiedGrid;
    const consolidatedPanelsGrid = railGroup.consolidatedPanelsGrid;
    const consolidatedSegmentZonesAndModulePositionsGrid = railGroup.consolidatedSegmentZonesAndModulePositionsGrid;
    const consolidatedContourCodesGrid = railGroup.consolidatedContourCodesGrid;

    const newRailGroupSets = [];
    let currentRailGroupSet;

    selectionsGrid.forEach((railRow, i) => {
      const comparisonString = railRow.map((segmentRow) => segmentRow.join("")).join("");
      const zonesAndModulePositionsGrid = consolidatedSegmentZonesAndModulePositionsGrid[i];
      const contourCodesGrid = consolidatedContourCodesGrid[i];

      let firstRowLatLng;
      const panelStartLatLngsForConsolidatedFirstRow = consolidatedPanelsGrid[i].map((pf, i) => {
        const ll = pf.get("model").startLatLng;
        if (i === 0) firstRowLatLng = ll;
        return getSnapshot(ll);
      });

      const oldStartToNewStartLatLngVect = railGroup.railedParentSegment.startLatLng.minus(firstRowLatLng);

      const thermalExpansionStartLatLngs = railGroup.railedParentSegment.thermalExpansions.map((thermalExpansion) => {
        return thermalExpansion.startLatLng.minus(oldStartToNewStartLatLngVect);
      });

      const setNewRailGroupSet = () => {
        currentRailGroupSet = {
          comparisonString,
          rows: 1,
          simplifiedGridPattern: railRow,
          panelStartLatLngsForConsolidatedFirstRow,
          zonesAndModulePositionsGrid: [zonesAndModulePositionsGrid],
          contourCodesGrid: [contourCodesGrid],
          thermalExpansionStartLatLngs,
        };
      };

      if (i === 0) {
        setNewRailGroupSet();
        return;
      }

      if (comparisonString === currentRailGroupSet.comparisonString) {
        currentRailGroupSet.rows++;
        currentRailGroupSet.zonesAndModulePositionsGrid.push(consolidatedSegmentZonesAndModulePositionsGrid[i]);
        currentRailGroupSet.contourCodesGrid.push(consolidatedContourCodesGrid[i]);
      } else {
        newRailGroupSets.push(currentRailGroupSet);
        setNewRailGroupSet();
      }
    });
    newRailGroupSets.push(currentRailGroupSet);

    return newRailGroupSets;
  }
}
