import { Controller } from "@hotwired/stimulus";
import { critterGuardRemovesContourAlert } from "./helper";

export default class extends Controller {
  static targets = ["useCritterGuardYes", "useCritterGuardNo"];

  changeUseCritterGuard(_event) {
    const yesRadio = this.useCritterGuardYesTarget;

    if (!yesRadio.checked) return;

    critterGuardRemovesContourAlert();
  }
}
