import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["spares", "edit", "count"];

  edit(event) {
    event.preventDefault();
    this.editTarget.classList.remove("d-none");
    this.sparesTarget.classList.add("d-none");
  }

  save(_event) {
    this.sparesTarget.classList.remove("d-none");
    this.editTarget.classList.add("d-none");
  }

  cancel(event) {
    event.preventDefault();

    const countElement = this.countTarget;
    const initialSpares = countElement.dataset.initialSpares;
    countElement.value = initialSpares;

    this.sparesTarget.classList.remove("d-none");
    this.editTarget.classList.add("d-none");
  }

  increment(event) {
    event.preventDefault();

    const countElement = this.countTarget;
    let currentValue = parseInt(countElement.value);
    if (isNaN(currentValue)) {
      currentValue = 0;
    }

    countElement.value = currentValue + 1;
  }

  decrement(event) {
    event.preventDefault();

    const countElement = this.countTarget;
    const configuredCount = countElement.dataset.configuredCount;

    let currentValue = parseInt(countElement.value);
    if (isNaN(currentValue)) {
      currentValue = 0;
    }
    const newValue = -currentValue >= configuredCount ? currentValue : currentValue - 1;

    countElement.value = newValue;
  }
}
