export default class GridExtensionsOutline {
  static calculate(displayGrid) {
    return new this(displayGrid).calculate();
  }

  constructor({
    columns,
    extensionBottom,
    extensionLeft,
    extensionRight,
    extensionTop,
    cellHeight,
    cellPositions,
    cellWidth,
    rows,
  }) {
    this.columns = columns;
    this.extensionBottom = extensionBottom;
    this.extensionLeft = extensionLeft;
    this.extensionRight = extensionRight;
    this.extensionTop = extensionTop;
    this.cellHeight = cellHeight;
    this.cellPositions = cellPositions;
    this.cellWidth = cellWidth;
    this.rows = rows;
  }

  calculate() {
    if (this.hasNoExtensions) return undefined;

    return {
      x: this.firstCell.x - this.extensionLeft,
      y: this.firstCell.y - this.extensionTop,
      height: this.height,
      width: this.width,
    };
  }

  get hasNoExtensions() {
    return [this.extensionTop, this.extensionRight, this.extensionBottom, this.extensionLeft].every((s) => s === 0);
  }

  get firstCell() {
    return this.cellPositions[0][0];
  }

  get lastCell() {
    return this.cellPositions[this.rows - 1][this.columns - 1];
  }

  get height() {
    return this.extensionTop + (this.lastCell.y - this.firstCell.y) + this.cellHeight + this.extensionBottom - 1;
  }

  get width() {
    return this.extensionLeft + (this.lastCell.x - this.firstCell.x) + this.cellWidth + this.extensionRight - 1;
  }
}
