import { Controller } from "@hotwired/stimulus";
import { SVG } from "@svgdotjs/svg.js";
import * as Turbo from "@hotwired/turbo";
import { polygon as turfPolygon } from "@turf/helpers";
import centerOfMass from "@turf/center-of-mass";
import { getDistance } from "ol/sphere";

import { rotatePointAroundCenter } from "../../../helpers/geometry";

const HORIZONTAL_PADDING = 5;
const VERTICAL_PADDING = 10;
const MAX_HEIGHT = 350;
const IDENTIFIER_DIAMETER = 30;

export default class extends Controller {
  static values = {
    displayOnly: Boolean, // Stimulus defaults to false
  };

  connect() {
    this.miniMapItems = JSON.parse(this.element.dataset.miniMapItems).sort((mmi) => {
      // We want the active item to render as the last item so it displays on top
      return mmi.isActive ? 1 : -1;
    });
    this.centerLatLng = JSON.parse(this.element.dataset.centerLatLng);
    this.rotationRadians = Number.parseFloat(this.element.dataset.rotationRadians || 0) * -1;

    if (this.miniMapItems.length === 0) return;

    // sometimes turbo seems to call this twice
    if (this.svgAlreadyRendered) return;

    this.switchPointsToOpenLayersLonLat();
    this.switchPointsToDistancesFromTopLeftCorner();
    this.rotatePoints();
    this.calculateActualSize();
    this.calculateScalingFactor();
    this.scaleDistancePoints();

    this.renderSVG();
  }

  disconnect() {
    this.element.innerHTML = "";
    this.svg = undefined;
  }

  get svgAlreadyRendered() {
    return this.element.innerHTML.includes("<svg");
  }

  switchPointsToOpenLayersLonLat() {
    // OpenLayers wants them in the opposite order
    // Also flip Y axis since SVG uses Y axis increasing down
    this.miniMapItems.forEach((mmi) => {
      mmi.lonLatPoints = mmi.latLngPoints.map((latLng) => [latLng[1], -latLng[0]]);
    });

    this.centerLonLat = [this.centerLatLng[1], -this.centerLatLng[0]];
  }

  switchPointsToDistancesFromTopLeftCorner() {
    const anchor = this.topLeftOfPoints("lonLatPoints");

    this.miniMapItems.forEach((mmi) => {
      mmi.distancePoints = mmi.lonLatPoints.map((lonLat) => this.distanceFromAnchor(anchor, lonLat));
    });

    this.centerDistances = this.distanceFromAnchor(anchor, this.centerLonLat);
  }

  topLeftOfPoints(pointsAccessor) {
    let top;
    let left;

    this.miniMapItems.forEach((mmi) => {
      mmi[pointsAccessor].forEach((p) => {
        if (left === undefined || p[0] < left) left = p[0];
        if (top === undefined || p[1] < top) top = p[1];
      });
    });

    return [left, top];
  }

  // given an anchor (in lonLat) and a lonLat, returns a vector expressed in distances (in meters)
  // from the anchor point to the lonLat
  distanceFromAnchor(anchor, lonLat) {
    const left = anchor[0];
    const top = anchor[1];

    const xDistance = getDistance(anchor, [lonLat[0], top]);
    const yDistance = getDistance(anchor, [left, lonLat[1]]);

    return [xDistance, yDistance];
  }

  calculateActualSize() {
    this.miniMapItems.forEach((mmi) => {
      mmi.distancePoints.forEach((p) => {
        if (this.actualWidth === undefined || p[0] > this.actualWidth) this.actualWidth = p[0];
        if (this.actualHeight === undefined || p[1] > this.actualHeight) this.actualHeight = p[1];
      });
    });
  }

  rotatePoints() {
    if (this.rotationRadians === 0) return;

    this.miniMapItems.forEach((mmi) => {
      mmi.distancePoints = mmi.distancePoints.map((p) =>
        rotatePointAroundCenter(this.centerDistances, p, this.rotationRadians),
      );
    });

    this.normalizeToEliminateNegativePositions();
  }

  normalizeToEliminateNegativePositions() {
    const anchor = this.topLeftOfPoints("distancePoints");

    this.miniMapItems.forEach((mmi) => {
      mmi.distancePoints = mmi.distancePoints.map((p) => {
        const x = p[0];
        const y = p[1];

        return [x - anchor[0], y - anchor[1]];
      });
    });
  }

  calculateScalingFactor() {
    const horizontalScalingFactor = (this.element.offsetWidth - 2 * HORIZONTAL_PADDING) / this.actualWidth;
    const verticalScalingFactor = (MAX_HEIGHT - 2 * VERTICAL_PADDING) / this.actualHeight;
    this.scalingFactor = Math.min(horizontalScalingFactor, verticalScalingFactor);
  }

  scaleDistancePoints() {
    this.miniMapItems.forEach((mmi) => {
      mmi.scaledNormalizedPoints = mmi.distancePoints.map((p) => {
        const x = p[0] * this.scalingFactor + HORIZONTAL_PADDING;
        const y = p[1] * this.scalingFactor + VERTICAL_PADDING;
        return [x, y];
      });
    });
  }

  renderSVG() {
    const width = this.actualWidth * this.scalingFactor + 2 * HORIZONTAL_PADDING;
    const height = this.actualHeight * this.scalingFactor + 2 * VERTICAL_PADDING;

    this.svg = SVG().addTo(this.element);
    this.svg.size(width, height);
    this.svg.viewbox(0, 0, width, height);
    this.svg.fill("#999");
    this.miniMapItems.forEach((mmi) => this.addPolygonToSvg(mmi));
  }

  addPolygonToSvg(miniMapItem) {
    const pointsString = miniMapItem.scaledNormalizedPoints.map((p) => `${p[0]},${p[1]}`).join(" ");
    const polygon = this.svg.polygon(pointsString);
    this.addPolygonClasses(polygon, miniMapItem);
    this.addPolygonIdentifierToSvg(miniMapItem, miniMapItem.scaledNormalizedPoints);

    polygon.click((event) => this.polygonClick(event, miniMapItem));
  }

  addPolygonIdentifierToSvg(miniMapItem) {
    const scaledNormalizedPoints = miniMapItem.scaledNormalizedPoints;
    const polygon = turfPolygon([scaledNormalizedPoints]);
    const [x, y] = centerOfMass(polygon).geometry.coordinates;

    const identSvg = this.svg.nested();
    identSvg.width(IDENTIFIER_DIAMETER);
    identSvg.height(IDENTIFIER_DIAMETER);
    identSvg.x(x - IDENTIFIER_DIAMETER / 2);
    identSvg.y(y - IDENTIFIER_DIAMETER / 2);

    const circle = identSvg.circle(IDENTIFIER_DIAMETER);
    circle.addClass("fs__mini-map__item__identifier");

    const text = identSvg.plain(miniMapItem.identifier);
    text.addClass("fs__mini-map__item__identifier__text");

    // It turns out centering in SVG is hard.  This approximates for a single character
    // TODO: This is sort of a centering hack.  Work on real centering
    text.x(miniMapItem.identifier.length > 1 ? 5 : 9);
    text.y(5);
  }

  addPolygonClasses(polygon, miniMapItem) {
    polygon.addClass("fs__mini-map__item");
    if (this.displayOnlyValue) {
      if (miniMapItem.isActive) {
        polygon.addClass("fs__mini-map__item--no-link--active");
      } else {
        polygon.addClass("fs__mini-map__item--no-link--inactive");
      }

      return;
    }

    if (miniMapItem.isActive) polygon.addClass("fs__mini-map__item--active");
    if (miniMapItem.isCompleted) polygon.addClass("fs__mini-map__item--completed");
    if (miniMapItem.hasError) polygon.addClass("fs__mini-map__item--error");
    if (miniMapItem.hasWarning) polygon.addClass("fs__mini-map__item--warning");
  }

  polygonClick(_event, miniMapItem) {
    if (!miniMapItem.url) return;

    if (this.autoSaveController) {
      this.autoSaveController.requestToNavigate(miniMapItem.url);
    } else {
      Turbo.visit(miniMapItem.url);
    }
  }

  get autoSaveController() {
    return this.element["da--layout-editor--auto-save"];
  }
}
