import { Stroke, Style } from "ol/style";

const ADJOINMENT_STROKE_COLOR = "rgba(0, 199, 225, 1.0)";
const ADJOINMENT_STROKE_WIDTH = 5;

const CONTOUR_LEGALITY_ADJOINMENT_STROKE_COLOR = "rgba(227, 205, 0, 1.0)";
const CONTOUR_LEGALITY_ADJOINMENT_STROKE_WIDTH = 5;

const ADJOINMENT_STYLE = new Style({
  stroke: new Stroke({ color: ADJOINMENT_STROKE_COLOR, width: ADJOINMENT_STROKE_WIDTH }),
  zIndex: 10,
});

const CONTOUR_LEGALITY_ADJOINMENT_STYLE = new Style({
  stroke: new Stroke({
    color: CONTOUR_LEGALITY_ADJOINMENT_STROKE_COLOR,
    width: CONTOUR_LEGALITY_ADJOINMENT_STROKE_WIDTH,
  }),
  zIndex: 11,
});

export const adjoinmentsStyle = (_feature) => {
  const styles = [];
  styles.push(ADJOINMENT_STYLE);
  return styles;
};

export const contourLegalityAdjoinmentsStyle = (_feature) => {
  const styles = [];
  styles.push(CONTOUR_LEGALITY_ADJOINMENT_STYLE);
  return styles;
};
