import { v4 as uuid } from "uuid";

import { toLonLat } from "ol/proj";
import { getDistance } from "ol/sphere";

import { METERS_TO_INCHES, cartesianPointRelativeTo } from "../../../../da/map/ol-geometry";
import SimpleLatLng from "../../../../da/map/models/simple-lat-lng";
import SegmentModel from "../../../models/segment-model";

export default class SegmentBuilder {
  constructor({
    roofPlane,
    orientation,
    boxCoordinates,
    selectionStartCoordinate,
    selectionEndCoordinate,
    selectionStartPixel,
    selectionEndPixel,
  }) {
    this.roofPlane = roofPlane;
    this.orientation = orientation;
    this.boxCoordinates = boxCoordinates;
    this.selectionStartCoordinate = selectionStartCoordinate;
    this.selectionEndCoordinate = selectionEndCoordinate;
    this.selectionStartPixel = selectionStartPixel;
    this.selectionEndPixel = selectionEndPixel;

    this.project = roofPlane.project;

    this.run();
  }

  run() {
    this.determinePanelRelatedSizes();
    this.determineMarqueeDirection();
    this.determinePanelPositionVectors();
    this.determineRowsAndColumns();
    this.createSegment();
  }

  determinePanelRelatedSizes() {
    this.slopeAdjustedSpacedPanelSizeInRowDirection =
      this.roofPlane.slopeAdjustedInterRowSpacing + this.roofPlane.panelLengthPerpendicularToEave(this.orientation);
    this.spacedPanelSizeInColumnDirection =
      this.project.interColumnSpacing + this.roofPlane.panelLengthAlongEave(this.orientation);
  }

  // For a NW marquee, the segment starts at Row 1, Column 1 (if you think about the start point as upper left)
  // for a NE marquee, the segment starts are Row 1, Column M
  // for a SE marquee, the segment starts are Row N, Column M
  // for a SW marquee, the segment starts are Row N, Column 1
  determineMarqueeDirection() {
    if (!this.selectionStartPixel && !this.selectionEndPixel) return;
    const xdelta = this.selectionEndPixel[0] - this.selectionStartPixel[0];
    const ydelta = this.selectionEndPixel[1] - this.selectionStartPixel[1];
    if (xdelta === 0 || ydelta === 0) {
      console.log("No area. Ambiguous");
      this.selectionDirection = undefined;
    } else if (xdelta > 0 && ydelta > 0) {
      this.selectionDirection = "NW";
    } else if (xdelta > 0 && ydelta < 0) {
      this.selectionDirection = "SW";
    } else if (xdelta < 0 && ydelta > 0) {
      this.selectionDirection = "NE";
    } else if (xdelta < 0 && ydelta < 0) {
      this.selectionDirection = "SE";
    }
  }

  determinePanelPositionVectors() {
    if (!this.selectionStartCoordinate && !this.selectionEndCoordinate) return;

    const { eaveEdgeVectorLatLng, eavePerpendicularEdgeVectorLatLng } = this.roofPlane;

    // converting these from OL coordinates to our SimpleLatLng to make vector math easier
    this.startLatLon = SimpleLatLng.fromLonLat(toLonLat(this.selectionStartCoordinate));
    this.endLatLon = SimpleLatLng.fromLonLat(toLonLat(this.selectionEndCoordinate));

    const startToEndDistance = getDistance(this.startLatLon.toLonLat, this.endLatLon.toLonLat);

    const startPlusEavePerpendicularLatLon = this.startLatLon.plus(eavePerpendicularEdgeVectorLatLng);
    if (getDistance(startPlusEavePerpendicularLatLon.toLonLat, this.endLatLon.toLonLat) < startToEndDistance) {
      // adding the eave perpendicular vector moved us from start towards end so
      this.rowDirection = 1;
    } else {
      this.rowDirection = -1;
    }
    this.unitRowVectorLatLng = eavePerpendicularEdgeVectorLatLng.times(this.rowDirection);

    const startPlusEaveParallelLatLon = this.startLatLon.plus(eaveEdgeVectorLatLng);
    if (getDistance(startPlusEaveParallelLatLon.toLonLat, this.endLatLon.toLonLat) < startToEndDistance) {
      // adding the eave parallel vector moved us from start towards end so
      this.columnDirection = 1;
    } else {
      this.columnDirection = -1;
    }
    this.unitColumnVectorLatLng = eaveEdgeVectorLatLng.times(this.columnDirection);
  }

  determineRowsAndColumns() {
    const { slopeAdjustedInterRowSpacing } = this.roofPlane;

    // OpenLayers constructs the marquee so that [0] is the start where you clicked
    // [0] - [1] is perpendicular to the eave, and [0]-[3] is parallel to the eave
    const marqueeStart = this.boxCoordinates[0];
    this.marqueeStart = marqueeStart;
    const marqueeEndPerpendicularToEave = this.boxCoordinates[1];
    const marqueeEndParallelToEave = this.boxCoordinates[3];

    const rowDistance = getDistance(toLonLat(marqueeStart), toLonLat(marqueeEndPerpendicularToEave)) * METERS_TO_INCHES;
    this.rows = Math.floor(
      (rowDistance + slopeAdjustedInterRowSpacing) /
        this.roofPlane.slopeAdjustedSpacedPanelSizeInRowDirection(this.orientation),
    );

    const columnDistance = getDistance(toLonLat(marqueeStart), toLonLat(marqueeEndParallelToEave)) * METERS_TO_INCHES;
    this.columns = Math.floor(
      (columnDistance + this.project.interColumnSpacing) /
        this.roofPlane.spacedPanelSizeInColumnDirection(this.orientation),
    );
  }

  createSegment() {
    const { rows, columns, orientation } = this;

    const topLeftLatLng = this.topLeftLatLng;
    const originLonLat = this.project.detail.originLatLng.toLonLat;
    const startCartesianPoint = cartesianPointRelativeTo(topLeftLatLng.toLonLat, originLonLat);

    this.segment = SegmentModel.create({
      uuid: uuid(),
      rows,
      columns,
      orientation,
      railed: true,
      startLatLng: { lat: topLeftLatLng.lat, lng: topLeftLatLng.lng },
      startCartesianPoint,
      rowDirection: 1,
      columnDirection: -1,
      illegalShape: false,
      temporary: true,
    });

    // Just picking the first roof section for now.  When we finish dragging, we can
    // figure out which roof section this segment belongs to.
    this.roofPlane.roofSections[0].addSegment(this.segment);
  }

  get topLeftLatLng() {
    const { rows, columns } = this;

    const allTheRows = this.unitRowVectorLatLng.times(
      rows * this.roofPlane.slopeAdjustedSpacedPanelSizeInRowDirection(this.orientation),
    );
    const allTheColumns = this.unitColumnVectorLatLng.times(
      columns * this.roofPlane.spacedPanelSizeInColumnDirection(this.orientation),
    );

    let startLatLng;

    if (this.selectionDirection === "NW") {
      startLatLng = this.startLatLon;
    } else if (this.selectionDirection === "NE") {
      startLatLng = this.startLatLon.plus(allTheColumns);
    } else if (this.selectionDirection === "SE") {
      startLatLng = this.startLatLon.plus(allTheColumns).plus(allTheRows);
    } else {
      startLatLng = this.startLatLon.plus(allTheRows);
    }

    return startLatLng;
  }
}
