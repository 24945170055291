import { elementInfo } from "../helpers/app";
import * as store from "../helpers/local-store";
import { hideDialog } from "../controllers/components/ir_dialog/helper";

export const scrollToRoofSection = (roofSectionId, projectType) => {
  const roofSectionContainer = elementInfo(document.querySelector(`#${projectType}_roof_section_${roofSectionId}`));

  const headerScrollOffset = 85;
  const headerHeight = Number.parseInt(document.querySelector(".ir-body-container").style.paddingTop);
  const scrollToY = roofSectionContainer.top - headerHeight - headerScrollOffset;
  window.scrollTo({ top: scrollToY, behavior: "smooth" });
};

window.DA_GLOBAL = {
  hideDialog,
  renumberRoofSections(pairs) {
    pairs.forEach((pair) => {
      const [id, identifier] = pair;
      const identifier_span = `#roof_section_${id}_identifier`;
      const identifierElement = document.querySelector(identifier_span);
      if (identifierElement) identifierElement.innerHTML = identifier;
    });
  },

  scrollToRoofSection: scrollToRoofSection,

  showHideSecondaryRoofSectionMenuStrip() {
    const floatingMenuDismissed = store.get("da-hide-floating-roof-section-menu");
    const roofSectionsArePresent =
      document.querySelector("[data-identifier='da-roof-sections-list']").innerHTML.trim() !== "";
    const showMenu = floatingMenuDismissed && roofSectionsArePresent;
    const secondaryMenu = Array.from(document.querySelectorAll("[data-controller='da--roof-section-menu']")).find(
      (m) => !m.dataset.hasOwnProperty("firstStrip"),
    );
    secondaryMenu.style.display = showMenu ? "block" : "none";
  },
};
