export const get = (key, defaultValue = undefined) => {
  let lsValue;
  try {
    lsValue = JSON.parse(localStorage.getItem(key));
  } catch {
    console.log("Format problem with stored value. Using default if possible.");
    lsValue = null;
  }
  if (lsValue === null && defaultValue !== undefined) return defaultValue;
  return lsValue;
};

export const set = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const has = (key) => {
  return get(key) !== null;
};

export const del = (key) => {
  localStorage.removeItem(key);
};

export const removeMatchingKeys = (filterFn) => {
  Object.keys(localStorage)
    .filter(filterFn)
    .forEach((key) => {
      localStorage.removeItem(key);
    });
};
