import LatLngModel from "../../../../da/models/lat-lng-model";
import SegmentsRailGroup from "../../map-model-synchronizers/segments-rail-group";

// The anchor segment can either be the farthest segment out in the direction of the
// alignment, or it can be the first selected segment.  We're moving towards making the
// anchor being the first selected in order to be consistent with the adjoining aligner
// feature, however, this is contrary to how most design apps handle this kind of
// alignment, so I'm keeping this code in place for now in case we want to revisit
// making this behavior toggleable in some way.
const ANCHOR_IS_FARTHEST_OUT = false;

export default class SegmentsAligner {
  #segmentPositions = {};
  #anchorSegmentFeature;

  constructor({ controller, selectionCollection, alignTo }) {
    this.controller = controller;
    this.selectionCollection = selectionCollection;
    this.alignTo = alignTo;

    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.roofPlane = controller.focusedRoofPlane;

    this.railGroups = SegmentsRailGroup.uniqueGroupsFromSegmentFeatures({
      controller,
      features: selectionCollection.getArray(),
    });

    this.#setSegmentPositions();
    if (ANCHOR_IS_FARTHEST_OUT) {
      this.#findAnchorFeature();
    } else {
      this.#anchorSegmentFeature = selectionCollection.getArray()[0];
    }
  }

  align() {
    this.railGroups.forEach((railGroup) => {
      if (["top", "bottom"].includes(this.alignTo)) this.#verticalAlign(railGroup);
      if (["left", "right"].includes(this.alignTo)) this.#horizontalAlign(railGroup);
    });
  }

  #verticalAlign(railGroup) {
    if (railGroup.hasFeature(this.#anchorSegmentFeature)) return;

    const railedParentStartLatLngBefore = railGroup.railedParentSegment.startLatLng.clone;

    railGroup.segments.forEach((segment, i) => {
      const { lat, lng } = this.#newStartLatLngForSegmentFeature(railGroup.segmentFeatures[i]);
      this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(segment, { lat, lng });

      if (segment.railed && segment.hasThermalExpansions) {
        const newStartLatLng = LatLngModel.create({ lat, lng });
        const moveVector = newStartLatLng.minus(railedParentStartLatLngBefore);

        segment.thermalExpansions.forEach((thermalExpansion) => {
          const newStartLatLng = thermalExpansion.startLatLng.plus(moveVector);
          this.mapModelSynchronizer.setThermalExpansionStartLatLngAndCartesianPoint(thermalExpansion, newStartLatLng);
          thermalExpansion.generateAndSetLatLngPoints(newStartLatLng);
        });
      }
    });

    railGroup.reRenderSegmentsWithSegmentSelections(this.selectionCollection);
  }

  #horizontalAlign(railGroup) {
    if (railGroup.hasFeature(this.#anchorSegmentFeature)) return;

    let aligningSegmentFeature;
    let nudgeSegmentFeatures;
    let nudgeThermalExpansions;
    if (this.alignTo === "left") {
      aligningSegmentFeature = railGroup.minSelectedSegmentFeature;
      nudgeSegmentFeatures = railGroup.segmentFeaturesUpToAndIncluding(railGroup.maxSelectedSegmentFeatureIndex);
      nudgeThermalExpansions = railGroup.thermalExpansionsWithDistanceToRailedParent(
        railGroup.segments[railGroup.maxSelectedSegmentFeatureIndex],
        { distanceType: "closer" },
      );
    } else {
      aligningSegmentFeature = railGroup.maxSelectedSegmentFeature;
      nudgeSegmentFeatures = railGroup.segmentFeaturesAfterAndIncluding(railGroup.minSelectedSegmentFeatureIndex);
      nudgeThermalExpansions = railGroup.thermalExpansionsWithDistanceToRailedParent(
        railGroup.segments[railGroup.minSelectedSegmentFeatureIndex],
        { distanceType: "fartherAway" },
      );
    }

    const newStartLatLng = this.#newStartLatLngForSegmentFeature(aligningSegmentFeature);
    const aligningSegment = aligningSegmentFeature.get("model");
    const distance = this.mapModelSynchronizer.getDistanceInInches(aligningSegment.startLatLng, newStartLatLng);

    nudgeSegmentFeatures.forEach((sf) => {
      const nudgeVectorDirection = this.#horizontalNudgeDirectionForSegmentFeature(sf);
      const nudgeVector = this.roofPlane.eaveEdgeVectorLatLng.times(nudgeVectorDirection * distance);

      const segment = sf.get("model");
      const nudgedStartLatLng = segment.startLatLng.plus(nudgeVector);
      this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(segment, nudgedStartLatLng);
    });

    nudgeThermalExpansions.forEach((te) => {
      const newStartLatLng = te.startLatLng.plus(nudgeVector);
      this.mapModelSynchronizer.setThermalExpansionStartLatLngAndCartesianPoint(te, newStartLatLng);
      te.generateAndSetLatLngPoints(newStartLatLng);
    });

    railGroup.updateDistancesFromRailedParentStart();
    railGroup.reRenderSegmentsWithSegmentSelections(this.selectionCollection);
  }

  #horizontalNudgeDirectionForSegmentFeature(segmentFeature) {
    const anchorPositions = this.#segmentPositions[this.#anchorSegmentFeature.get("uuid")];
    const anchorTopLeftX = anchorPositions.topLeft[0];
    const anchorBottomRightX = anchorPositions.bottomRight[0];

    const segmentPositions = this.#segmentPositions[segmentFeature.get("uuid")];
    const segmentTopLeftX = segmentPositions.topLeft[0];
    const segmentBottomRightX = segmentPositions.bottomRight[0];

    if (this.alignTo === "left" && segmentTopLeftX < anchorTopLeftX) return -1;
    if (this.alignTo === "right" && segmentBottomRightX < anchorBottomRightX) return -1;
    return 1;
  }

  #findAnchorFeature() {
    this.selectionCollection.forEach((segmentFeature) => {
      this.#replaceAnchorSegmentFeatureIfSegmentFeatureIsFartherOut(segmentFeature);
    });
  }

  #setSegmentPositions() {
    this.railGroups.forEach((railGroup) => {
      railGroup.segmentFeatures.forEach((segmentFeature) => {
        const coordinates = segmentFeature.getGeometry().getLinearRing(0).getCoordinates();

        this.#segmentPositions[segmentFeature.get("uuid")] = {
          topLeft: this.map.getPixelFromCoordinate(coordinates[0]),
          bottomRight: this.map.getPixelFromCoordinate(coordinates[2]),
        };
      });
    });
  }

  #replaceAnchorSegmentFeatureIfSegmentFeatureIsFartherOut(segmentFeature) {
    let setAnchorToSegment = false;

    if (!this.#anchorSegmentFeature) {
      setAnchorToSegment = true;
    } else {
      const segmentPositions = this.#segmentPositions[segmentFeature.get("uuid")];
      const anchorSegmentPositions = this.#segmentPositions[this.#anchorSegmentFeature.get("uuid")];

      if (this.alignTo === "top") {
        if (segmentPositions.topLeft[1] < anchorSegmentPositions.topLeft[1]) setAnchorToSegment = true;
      } else if (this.alignTo === "bottom") {
        if (segmentPositions.bottomRight[1] > anchorSegmentPositions.bottomRight[1]) setAnchorToSegment = true;
      } else if (this.alignTo === "left") {
        if (segmentPositions.topLeft[0] < anchorSegmentPositions.topLeft[0]) setAnchorToSegment = true;
      } else if (this.alignTo === "right") {
        if (segmentPositions.bottomRight[0] > anchorSegmentPositions.bottomRight[0]) setAnchorToSegment = true;
      }
    }

    if (setAnchorToSegment) this.#anchorSegmentFeature = segmentFeature;
  }

  #newStartLatLngForSegmentFeature(segmentFeature) {
    const segmentPositions = this.#segmentPositions[segmentFeature.get("uuid")];
    const anchorSegmentPositions = this.#segmentPositions[this.#anchorSegmentFeature.get("uuid")];

    let newSegmentTopLeftPixel;
    if (this.alignTo === "top") {
      newSegmentTopLeftPixel = [segmentPositions.topLeft[0], anchorSegmentPositions.topLeft[1]];
    } else if (this.alignTo === "bottom") {
      newSegmentTopLeftPixel = [
        segmentPositions.topLeft[0],
        segmentPositions.topLeft[1] + (anchorSegmentPositions.bottomRight[1] - segmentPositions.bottomRight[1]),
      ];
    } else if (this.alignTo === "left") {
      newSegmentTopLeftPixel = [anchorSegmentPositions.topLeft[0], segmentPositions.topLeft[1]];
    } else if (this.alignTo === "right") {
      newSegmentTopLeftPixel = [
        segmentPositions.topLeft[0] + (anchorSegmentPositions.bottomRight[0] - segmentPositions.bottomRight[0]),
        segmentPositions.topLeft[1],
      ];
    }

    const coordinate = this.map.getCoordinateFromPixel(newSegmentTopLeftPixel);
    const latLng = this.mapModelSynchronizer.simpleLatLngFromCoordinate(coordinate);
    return latLng;
  }
}
