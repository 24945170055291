const ZONE_1 = "1";
const ZONE_2 = "2";
const ZONE_2E = "2e";
const ZONE_2N = "2n";
const ZONE_2R = "2r";
const ZONE_3 = "3";
const ZONE_3E = "3e";
const ZONE_3R = "3r";

const ZONES = [ZONE_1, ZONE_2, ZONE_2E, ZONE_2N, ZONE_2R, ZONE_3, ZONE_3E, ZONE_3R];

const ZONES_710 = [ZONE_1, ZONE_2, ZONE_3];
const ZONES_716_GABLE = [ZONE_1, ZONE_2E, ZONE_2N, ZONE_2R, ZONE_3E, ZONE_3R];
const ZONES_716_HIP = [ZONE_1, ZONE_2E, ZONE_2R, ZONE_3];

const MODULE_POSITION_N = "N";
const MODULE_POSITION_EXP = "EXP";
const MODULE_POSITION_EDG = "EDG";
const MODULE_POSITION_EDGN = "EDGN";
const MODULE_POSITION_EDGS = "EDGS";
const MODULE_POSITIONS = [
  MODULE_POSITION_N,
  MODULE_POSITION_EXP,
  MODULE_POSITION_EDG,
  MODULE_POSITION_EDGN,
  MODULE_POSITION_EDGS,
];

const DEFAULT_ZONE_MODULE_POSITION = `${ZONE_1}:${MODULE_POSITION_N}`;

const ZONES_MODULE_POSITIONS = [];
ZONES.forEach((zone) => {
  MODULE_POSITIONS.forEach((modulePosition) => {
    ZONES_MODULE_POSITIONS.push(`${zone}:${modulePosition}`);
  });
});

module.exports = {
  ZONE_1,
  ZONE_2,
  ZONE_2E,
  ZONE_2N,
  ZONE_2R,
  ZONE_3,
  ZONE_3E,
  ZONE_3R,
  ZONES,
  ZONES_710,
  ZONES_716_GABLE,
  ZONES_716_HIP,
  MODULE_POSITION_N,
  MODULE_POSITION_EXP,
  MODULE_POSITION_EDG,
  MODULE_POSITION_EDGN,
  MODULE_POSITION_EDGS,
  MODULE_POSITIONS,
  DEFAULT_ZONE_MODULE_POSITION,
  ZONES_MODULE_POSITIONS,
};
