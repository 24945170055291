import { Controller } from "@hotwired/stimulus";
import { showDialog, hideDialog, zIndexForDialog } from "./helper";
import { elementInfo, logger } from "../../../helpers/app";
import { sentryException } from "../../../config/sentry";

const escape = 27;

export default class extends Controller {
  static targets = ["dialog"];
  static values = { autoCloseIfConfirmed: Boolean };

  connect() {
    if (!this.hasDialogTarget) return;

    this.opts = this.dialogTarget.dataset;
    this.setModalWidth();
    this.showDialog();
    this.#moveDialogContainer();
    this.dialogIdentifier = this.dialogTarget.dataset.dialogIdentifier;

    document.addEventListener("keyup", this.keyUpHandler);
  }

  disconnect() {
    this.dragRemoveDocumentEvents();
    document.removeEventListener("keyup", this.keyUpHandler);
  }

  setModalWidth() {
    const width = this.opts.dialogWidth;
    if (width) {
      this.dialogTarget.style.width = `${width}px`;
    }
  }

  isTrue(value) {
    return value && value.toString() === "true";
  }

  showDialog() {
    if (this.isTrue(this.opts.dialogAutoOpen)) {
      showDialog(this.dialogTarget);
    }
  }

  close(event) {
    event.preventDefault();
    this.closeWithEventDefault();
  }

  closeWithEventDefault(_) {
    if (this.hasDialogTarget) {
      hideDialog(this.dialogTarget);
      if (this.dialogTarget.focusOnClose) this.dialogTarget.focusOnClose.focus();
    } else {
      const message = `[Handled Error] Missing dialog in closeWithEventDefault: ${this.dialogIdentifier}.`;
      logger(message);
      sentryException(message);
    }
  }

  keyUpHandler = (event) => {
    if (event.key !== "Escape" || (this.hasDialogTarget && !this.dialogIsVisible)) return;
    if (this.isTrue(this.opts.closable)) this.closeWithEventDefault();
  };

  get dialogIsVisible() {
    return this.dialogTarget.offsetParent !== null;
  }

  dragMouseDown(event) {
    this.dragCurrentX = event.clientX;
    this.dragCurrentY = event.clientY;

    // Override the default positioning based on percentages and remove the default translateX transform
    const { left, top } = elementInfo(this.dialogTarget);
    this.dialogTarget.style.left = `${left}px`;
    this.dialogTarget.style.top = `${top}px`;
    this.dialogTarget.style.transform = "none";

    this.dialogTarget.style.zIndex = zIndexForDialog(this.dialogTarget);

    this.dragAddDocumentEvents();
  }

  clickAndBringToTop(_event) {
    this.dialogTarget.style.zIndex = zIndexForDialog(this.dialogTarget);
  }

  dragAddDocumentEvents() {
    document.addEventListener("mousemove", this.dragMouseMove);
    document.addEventListener("mouseup", this.dragMouseUp);
  }

  dragMouseMove = (event) => {
    const deltaX = event.clientX - this.dragCurrentX;
    const deltaY = event.clientY - this.dragCurrentY;

    const { left, top } = elementInfo(this.dialogTarget);
    this.dialogTarget.style.left = `${left + deltaX}px`;
    this.dialogTarget.style.top = `${top + deltaY}px`;

    this.dragCurrentX = event.clientX;
    this.dragCurrentY = event.clientY;
  };

  dragMouseUp = (_event) => {
    this.dragRemoveDocumentEvents();
  };

  dragRemoveDocumentEvents() {
    document.removeEventListener("mousemove", this.dragMouseMove);
    document.removeEventListener("mouseup", this.dragMouseUp);
  }

  confirmYes(event) {
    const button = event.currentTarget;

    if (!button.classList.contains("ir-btn--spinner-shown")) {
      button.classList.add("ir-btn--spinner-shown");
      button.classList.add("btn--disabled");
      button.disabled = true;
    }

    setTimeout(() => this.callConfirmCallback(true, this.autoCloseIfConfirmedValue), 250);
  }

  confirmNo(_event) {
    this.callConfirmCallback(false, true);
  }

  callConfirmCallback(confirmed, shouldHideDialog) {
    const callback = this.dialogTarget.confirmCallback;
    if (callback === undefined) {
      throw new Error("Expected a confirmCallback to be assigned on this dialog");
    }
    if (shouldHideDialog) {
      hideDialog(this.dialogTarget);
    }
    callback(confirmed);
  }

  get #dialogsContainer() {
    let dialogsContainer = document.querySelector(".dialogs-container");

    if (!dialogsContainer) {
      dialogsContainer = document.createElement("div");
      dialogsContainer.classList.add("dialogs-container");
      document.body.appendChild(dialogsContainer);
    }

    return dialogsContainer;
  }

  #moveDialogContainer() {
    const dialogsContainer = this.#dialogsContainer;
    if (this.element.parentNode == dialogsContainer) return;

    const dialogIdentifier = this.dialogTarget.dataset.dialogIdentifier;
    const oldDialog = dialogsContainer.querySelector(`[data-dialog-identifier="${dialogIdentifier}"]`);

    if (oldDialog) {
      logger(`Removing old dialog for ${dialogIdentifier}`);
      const oldDialogContainer = oldDialog.parentNode;
      dialogsContainer.removeChild(oldDialogContainer);
    }

    this.element.parentNode.removeChild(this.element);
    dialogsContainer.appendChild(this.element);
  }
}
