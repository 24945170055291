import BaseEvent from "ol/events/Event";

export const BEFORE_MAP_FEATURES_RENDERING_EVENT = "beforeMapFeaturesRendering";
export const AFTER_MAP_FEATURES_RENDERING_EVENT = "afterMapFeaturesRendering";

export function dispatchBeforeMapFeaturesRendering(map, eventOptions = {}) {
  const event = new BaseEvent(BEFORE_MAP_FEATURES_RENDERING_EVENT);

  Object.keys(eventOptions).forEach((key) => {
    event[key] = eventOptions[key];
  });

  map.dispatchEvent(event);
}

export function dispatchAfterMapFeaturesRendering(map, eventOptions = {}) {
  const event = new BaseEvent(AFTER_MAP_FEATURES_RENDERING_EVENT);

  Object.keys(eventOptions).forEach((key) => {
    event[key] = eventOptions[key];
  });

  map.dispatchEvent(event);
}
