import { Fill, Style } from "ol/style";
import { SETBACK_DATA_TYPE } from "../data-types";

const SETBACK_STYLE = new Style({
  fill: new Fill({ color: `rgba(231, 201, 6, 0.4)` }),
});

export function setbacksStyle(_feature) {
  return SETBACK_STYLE;
}

export function isSetback(feature) {
  return feature.get("dataType") === SETBACK_DATA_TYPE;
}

export function selectSetbackPolygonStrokeAndFillColors() {
  return ["rgba(246, 240, 56, 1.0)", "rgba(246, 240, 56, 0.3)"];
}
