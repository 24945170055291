import React from "react";
import PropTypes from "prop-types";

function RoofOutline({ x, y, height, width, children, outlineRef }) {
  const styles = {
    left: `${x}px`,
    top: `${y}px`,
    height: `${height}px`,
    width: `${width}px`,
  };

  return (
    <div className="graphical-tool__roof-outline" style={styles} ref={outlineRef}>
      {children}
    </div>
  );
}

RoofOutline.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  children: PropTypes.object,
  outlineRef: PropTypes.object.isRequired,
};

RoofOutline.defaultProps = {
  children: undefined,
};

export default React.memo(RoofOutline);
