import { Controller } from "@hotwired/stimulus";

import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = ["cantileverAttachmentsNumberField", "suppressWarningRadioBtn", "fixWithAttachmentsRadioBtn"];

  toggleAttachments() {
    const showAttachments = this.fixWithAttachmentsRadioBtnTarget.checked;
    animate.toggle(this.cantileverAttachmentsNumberFieldTarget, showAttachments);
  }
}
