import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import throttle from "lodash/throttle";
import sortBy from "lodash/sortBy";
import ReactDOM from "react-dom";

import Crosshairs from "./Crosshairs";
import GridCompressedSpaceThresholds from "../services/grid-compressed-space-thresholds";

// Moving the crosshairs away from the cursor so you can click and select cells
export const CURSOR_OFFSET = 12;

// Offset to account for the fact that the crosshair line is in the middle of a container
export const LINE_OFFSET = 2;

export const SNAP_TOLERANCE = 10;

function DimensionCrosshairs({
  height,
  width,
  realHeight,
  realWidth,
  outlineRef,
  containerRef,
  positionSnaps,
  snap,
  compressedSpace,
  compressedSpaceThresholds,
}) {
  const containerElemRef = React.useRef(document.createElement("div"));

  useEffect(() => {
    const id = "graphical-tool__dimension-crosshairs__container";
    containerElemRef.current.id = id;
    document.body.appendChild(containerElemRef.current);
    return () => containerElemRef.current.remove();
  }, []);

  const [outlinePos, setOutlinePos] = useState(null);
  const [mousePos, setMousePos] = useState(null);
  const [compressedSpaceXQuadrant, setCompressedSpaceXQuadrant] = useState("split");
  const [compressedSpaceYQuadrant, setCompressedSpaceYQuadrant] = useState("split");

  const containerPageOffsets = useMemo(() => {
    if (!containerRef) return { x: 0, y: 0 };
    const container = containerRef.current;
    const pe = container.parentElement;
    return { x: pe.offsetLeft, y: pe.offsetTop };
  }, [containerRef]);

  const adjustXValue = useCallback(
    (value) => value + containerPageOffsets.x - CURSOR_OFFSET / 2,
    [containerPageOffsets.x],
  );
  const adjustYValue = useCallback(
    (value) => value + containerPageOffsets.y - CURSOR_OFFSET / 2,
    [containerPageOffsets.y],
  );

  const adjustedPageXSnaps = useMemo(() => {
    if (!containerRef) return [];
    return positionSnaps.xAxis.map((x) => adjustXValue(x)).sort();
  }, [containerRef, positionSnaps.xAxis, adjustXValue]);

  const adjustedPageYSnaps = useMemo(() => {
    if (!containerRef) return [];
    return positionSnaps.yAxis.map((y) => adjustYValue(y)).sort();
  }, [adjustYValue, containerRef, positionSnaps.yAxis]);

  const adjustedCompressedSpaceThresholds = useMemo(() => {
    if (!containerRef) return compressedSpaceThresholds;
    const { leftX, rightX, topY, bottomY } = compressedSpaceThresholds;
    return {
      leftX: adjustXValue(leftX),
      rightX: adjustXValue(rightX),
      topY: adjustYValue(topY),
      bottomY: adjustYValue(bottomY),
    };
  }, [adjustXValue, adjustYValue, compressedSpaceThresholds, containerRef]);

  useEffect(() => {
    if (outlineRef === null) return;

    const outline = outlineRef.current;
    const outlineRect = outline.getBoundingClientRect();
    const outlineTop = outlineRect.top + window.pageYOffset;
    const outlineLeft = outlineRect.left + window.pageXOffset;
    const outlinePos = {
      top: outlineTop,
      left: outlineLeft,
      right: outlineLeft + width,
      bottom: outlineTop + height,
    };
    setOutlinePos(outlinePos);
  }, [height, outlineRef, width]);

  useEffect(() => {
    const snapPos = (eventPos, snaps) => {
      const adjustedEventPos = eventPos - CURSOR_OFFSET;
      if (!snap) return adjustedEventPos;

      const snapsInRange = [];
      for (let i = 0; i < snaps.length; i++) {
        const inSnapRange =
          adjustedEventPos >= snaps[i] - SNAP_TOLERANCE && adjustedEventPos <= snaps[i] + SNAP_TOLERANCE;
        if (inSnapRange) {
          const closeness = Math.abs(adjustedEventPos - snaps[i]);
          snapsInRange.push({ pos: snaps[i], closeness });
        }
      }

      if (snapsInRange.length === 0) return adjustedEventPos;

      const sortedSnapsInRange = sortBy(snapsInRange, ["closeness"]);
      return sortedSnapsInRange[0].pos;
    };

    const onMouseMove = (event) => {
      const x = snapPos(event.pageX, adjustedPageXSnaps);
      const y = snapPos(event.pageY, adjustedPageYSnaps);
      setCompressedSpaceXQuadrant(GridCompressedSpaceThresholds.xQuadrant(adjustedCompressedSpaceThresholds, x));
      setCompressedSpaceYQuadrant(GridCompressedSpaceThresholds.yQuadrant(adjustedCompressedSpaceThresholds, y));
      setMousePos({ x, y });
    };

    const throttledOnMouseMove = throttle((e) => onMouseMove(e), 50, { leading: true });

    document.addEventListener("mousemove", throttledOnMouseMove);
    return () => document.removeEventListener("mousemove", throttledOnMouseMove);
  }, [
    adjustedCompressedSpaceThresholds,
    adjustedPageXSnaps,
    adjustedPageYSnaps,
    compressedSpaceThresholds,
    height,
    snap,
    width,
  ]);

  return ReactDOM.createPortal(
    <Crosshairs
      mousePos={mousePos}
      outlinePos={outlinePos}
      height={height}
      width={width}
      realHeight={realHeight}
      realWidth={realWidth}
      cursorOffset={CURSOR_OFFSET}
      lineOffset={LINE_OFFSET}
      compressedSpace={compressedSpace}
      compressedSpaceXQuadrant={compressedSpaceXQuadrant}
      compressedSpaceYQuadrant={compressedSpaceYQuadrant}
    />,
    containerElemRef.current,
  );
}

DimensionCrosshairs.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  realHeight: PropTypes.number.isRequired,
  realWidth: PropTypes.number.isRequired,
  outlineRef: PropTypes.object.isRequired,
  positionSnaps: PropTypes.shape({
    xAxis: PropTypes.arrayOf(PropTypes.number).isRequired,
    yAxis: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  snap: PropTypes.bool.isRequired,
  compressedSpace: PropTypes.shape({
    top: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
};

export default React.memo(DimensionCrosshairs);
