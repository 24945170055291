import maxBy from "lodash/maxBy";

export const lineMidPoint = (point1, point2) => {
  const x = (point1.x + point2.x) / 2;
  const y = (point1.y + point2.y) / 2;
  return { x, y };
};

export const radiansToDegrees = (rad) => (rad * 180) / Math.PI;
export const degreesToRadians = (degr) => (degr * Math.PI) / 180;

export const lineDistance = (point1, point2) => {
  let a = point1.x - point2.x;
  let b = point1.y - point2.y;
  return Math.sqrt(a * a + b * b);
};

export const lineAngleRadians = (point1, point2) => {
  return Math.atan2(point1.y - point2.y, point1.x - point2.x);
};

export const lineAngleDegrees = (point1, point2) => {
  return radiansToDegrees(lineAngleRadians(point1, point2));
};

export const pointsDirection = (points) => {
  let sum = 0;
  for (let i = 0; i < points.length; i++) {
    const p1 = points[i];
    const p2 = points[(i + 1) % points.length];
    sum += p1.x * p2.y - p1.y * p2.x;
  }
  return sum > 0 ? "clockwise" : "counter clockwise";
};

// https://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
export const linesAreIntersecting = (p1, p2, p3, p4) => {
  function turn(p1, p2, p3) {
    return (p3.y - p1.y) * (p2.x - p1.x) > (p2.y - p1.y) * (p3.x - p1.x);
  }
  return turn(p1, p3, p4) != turn(p2, p3, p4) && turn(p1, p2, p3) != turn(p1, p2, p4);
};

// https://stackoverflow.com/questions/22521982/js-check-if-point-inside-a-polygon?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
export const pointIsInsidePolygon = (point, polygonPoints) => {
  let inside = false;
  const { x, y } = point;
  for (let i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i++) {
    let xi = polygonPoints[i].x;
    let yi = polygonPoints[i].y;
    let xj = polygonPoints[j].x;
    let yj = polygonPoints[j].y;
    let intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

// Move minimum x and y points to 0 position
export const normalizePoints = (points) => {
  let minX = undefined;
  let minY = undefined;
  points.forEach((p) => {
    if (minX === undefined || p.x < minX) {
      minX = p.x;
    }
    if (minY === undefined || p.y < minY) {
      minY = p.y;
    }
  });
  return points.map((p) => {
    return {
      x: p.x - minX,
      y: p.y - minY,
    };
  });
};

// this also normalizes the position
export const resizePolygonPoints = (points, size = 48) => {
  const normalizedPoints = normalizePoints(points);
  const width = maxBy(normalizedPoints, "x")["x"];
  const height = maxBy(normalizedPoints, "y")["y"];
  let sizeX = size;
  let sizeY = size;
  // if one axis is greater than the other, resize to keep the aspect ratio intact
  if (width > height) {
    sizeY *= height / width;
  } else if (height > width) {
    sizeX *= width / height;
  }
  const percentageChangeX = (width - sizeX) / width;
  const percentageChangeY = (height - sizeY) / height;
  const resizedPoints = [];
  for (let point of normalizedPoints) {
    resizedPoints.push({
      x: Math.round(point.x - point.x * percentageChangeX),
      y: Math.round(point.y - point.y * percentageChangeY),
    });
  }
  return resizedPoints;
};

export const isClockwise = (pathDetails) => {
  let sumEdges = 0;
  pathDetails.forEach((line) => {
    sumEdges += (line.vertices[1].x - line.vertices[0].x) * (line.vertices[1].y + line.vertices[0].y);
  });
  return sumEdges < 0;
};

export const PROXIMITY_CUTOFF = 1.0e-6;

export const isClose = (value1, value2) => {
  if (value1 === Infinity && value2 === Infinity) {
    return true;
  } else {
    return Math.abs(value1 - value2) <= PROXIMITY_CUTOFF;
  }
};

const slope = (start, end) => {
  if (start[0] === end[0]) {
    return Infinity;
  } else {
    return Math.abs((end[1] - start[1]) / (end[0] - start[0]));
  }
};

export const hasSameSlope = (p1, p2, p3, p4) => {
  const m1 = slope(p1, p2);
  const m2 = slope(p3, p4);

  // console.log("m1", m1, "m2", m2);
  return isClose(m1, m2);
};

const isBetween = (value, end1, end2) => {
  const low = Math.min(end1, end2) + PROXIMITY_CUTOFF;
  const high = Math.max(end1, end2) - PROXIMITY_CUTOFF;

  return low < value && value < high;
};

// https://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
export const linesAreIntersectingAndDontOverlap = (p1, p2, p3, p4) => {
  const determinant = (p2[0] - p1[0]) * (p4[1] - p3[1]) - (p4[0] - p3[0]) * (p2[1] - p1[1]);
  if (Math.abs(determinant) < PROXIMITY_CUTOFF) return false;

  const lambda = ((p4[1] - p3[1]) * (p4[0] - p1[0]) + (p3[0] - p4[0]) * (p4[1] - p1[1])) / determinant;
  const gamma = ((p1[1] - p2[1]) * (p4[0] - p1[0]) + (p2[0] - p1[0]) * (p4[1] - p1[1])) / determinant;

  return isBetween(lambda, 0, 1) && isBetween(gamma, 0, 1);
};

export function rotatePointAroundCenter([cx, cy], [x, y], rotationRadians) {
  const cos = Math.cos(rotationRadians);
  const sin = Math.sin(rotationRadians);
  const nx = cos * (x - cx) + sin * (y - cy) + cx;
  const ny = cos * (y - cy) - sin * (x - cx) + cy;

  return [nx, ny];
}
