import { Fill, Stroke, Style } from "ol/style";

const CONTOUR_STROKE_COLOR = "rgba(176, 42, 209, 1.0)";
const CONTOUR_STROKE_WEIGHT = 5;

const CONTOUR_STYLE = new Style({
  stroke: new Stroke({ color: CONTOUR_STROKE_COLOR, width: CONTOUR_STROKE_WEIGHT }),
  zIndex: 1,
});

export function contoursStyle(_feature, _map, _controller) {
  return CONTOUR_STYLE;
}
