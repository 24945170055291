import tippy from "tippy.js";

import DaMapBaseController from "../../da/layout_editor/map_base_controller";

import ProjectModel from "../../../pr/models/project-model";

import AttachmentsMapManager from "../../../pr/map/map-managers/attachments";
import AttachmentsMapModelSynchronizer from "../../../pr/map/map-model-synchronizers/attachments";

import MeasureInteractionManager from "../../../da/map/interaction-managers/measure/base";
import SelectInteractionManager from "../../../pr/map/interaction-managers/select/attachments";
import SnapInteractionManager from "../../../pr/map/interaction-managers/snap/attachments";
import TranslateInteractionManager from "../../../da/map/interaction-managers/translate/base";
import ModifyInteractionManager from "../../../da/map/interaction-managers/modify/base";

import * as toolbarBtn from "../../../da/layout-editor/helpers/toolbar-btns";
import * as toolbarSelectGroup from "../../../da/layout-editor/helpers/toolbar-select-group";
import { EDITOR_MODE_MEASURE, EDITOR_MODE_SELECT } from "../../../da/layout-editor/helpers/toolbar-constants";
import * as animate from "../../../helpers/animate";

import { applyDomUpdatesFromResponse } from "../../../helpers/api-dom-helpers";
import { radiansToDegrees } from "../../../helpers/geometry";
import { fromLonLat } from "ol/proj";

export default class AttachmentsEditorController extends DaMapBaseController {
  static values = {
    activeTab: String,
  };

  static targets = [
    "tabLink",
    "map",
    "compass",
    "saveBtn",
    "statusItemZoom",
    "statusItemRotation",
    "statusItemPanelsCount",
    "yesStaggeredBtn",
    "noStaggeredBtn",
    "rafterOffsetContainer",
    "rafterOffsetTextField",
    "rafterOffsetNumberScrollContainer",
    "maxRafterOffsetContainer",
  ];

  connect() {
    this.activeTab = this.activeTabValue;

    super.connect();

    this.project = ProjectModel.create(this.projectData);
    this.pageRoofPlanes = this.project.roofPlanes;
    this.focusedRoofPlane = undefined;
    this.slopeAdjustedMeasures = true;
    this.renderAttachments = true;

    // This is okay when on a roof plane tab because rotation, panning, and zooming is only
    // saved when on the overview tab, so we can always refer to this page as "viewer".
    this.page = "viewer";

    window.attachmentsMapController = this;

    this.#setActiveTabStyles();

    this.mapModelSynchronizer = new AttachmentsMapModelSynchronizer(this);

    this.mapManager = new AttachmentsMapManager(this);
    this.mapManager.add();
    this.mapManager.setProjectSiteValuesOnMoveEnd = this.#onOverviewTab;
    this.mapManager.checkSegmentsAttachmentStatusLegality();

    this.#setupInteractionManagers();

    this.editorMode = toolbarSelectGroup.getState("prAttachmentsEditorMode", EDITOR_MODE_MEASURE);
    this.#initializeEditorMode();

    this.#populateStatusbar({ timeout: 500 });

    this.element.controller = this;

    document.addEventListener("keydown", this.handleKeydown);
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (event) => {
    if (event.key === "Escape") {
      this.measureInteractionManager.finish();
    }
  };

  staggeringOptionClick(event) {
    this.renderAttachments = false;
    this.#showSaveToViewUpdatedAttachmentPlanTooltip();
    const staggered = event.params.value === "yes";
    this.project.detail.setStaggeredAttachments(staggered);
    this.markDirty();
    this.mapModelSynchronizer.clearAttachmentFeatures();

    const activeClass = "ol-map__sidebar-forms__yes-no-btns__btn--active";
    if (staggered) {
      this.yesStaggeredBtnTarget.classList.add(activeClass);
      this.noStaggeredBtnTarget.classList.remove(activeClass);
    } else {
      this.yesStaggeredBtnTarget.classList.remove(activeClass);
      this.noStaggeredBtnTarget.classList.add(activeClass);
    }
  }

  tabClick(event) {
    event.preventDefault();

    this.activeTab = event.params.value;

    this.#setRoofPlanesForActiveTab();
    this.#populateStatusbar({ timeout: 0 });
    this.mapManager.reRenderMap();
    this.#rotateZoomMap();
    this.#setActiveTabStyles();
    this.#showHideRafterOffsetContainer();
  }

  rafterOffsetValueChange(event) {
    this.renderAttachments = false;
    this.#showSaveToViewUpdatedAttachmentPlanTooltip();

    let value = this.rafterOffsetTextFieldTarget.value;
    value = value === "" ? 0 : Number.parseInt(value);

    this.focusedRoofPlane.setRafterOffset(value);
    this.markDirty();
    this.mapModelSynchronizer.reRenderRafters();
    this.mapModelSynchronizer.clearAttachmentFeatures();
  }

  save(_event, onSuccess = () => {}) {
    this.renderAttachments = true;
    this.#hideSaveToViewUpdatedAttachmentPlanTooltip();

    toolbarBtn.showSpinner(this.saveBtnTarget);
    this.project.save({
      path: this.savePath,
      onSuccess: (json) => {
        if (json) applyDomUpdatesFromResponse(json);
        this.markClean();
        onSuccess();
        setTimeout(() => {
          toolbarBtn.hideSpinner(this.saveBtnTarget);
        }, 500);
        this.mapManager.reRenderMap();
        this.#resetTabLegalityStyles();
      },
      onValidationError: this.onValidationError,
      onServerError: this.onServerError,
      includeProjectSite: true,
    });
  }

  startSelectMode(_event) {
    this.measureInteractionManager.remove();
    this.selectInteractionManager.add();
    this.translateInteractionManager.remove();
    this.modifyInteractionManager.remove();
  }

  startMeasureMode(_event) {
    this.modifyInteractionManager.remove();
    this.selectInteractionManager.remove();
    this.translateInteractionManager.remove();
    this.measureInteractionManager.add();
  }

  #setupInteractionManagers() {
    this.selectInteractionManager = new SelectInteractionManager(this);

    this.snapInteractionManager = new SnapInteractionManager(this);
    this.snapInteractionManager.add();

    this.modifyInteractionManager = new ModifyInteractionManager(this);
    this.translateInteractionManager = new TranslateInteractionManager(this);

    this.measureInteractionManager = new MeasureInteractionManager(this);
  }

  #initializeEditorMode() {
    switch (this.editorMode) {
      case EDITOR_MODE_MEASURE:
        this.startMeasureMode();
        break;
      case EDITOR_MODE_SELECT:
        this.startSelectMode();
        break;
      default:
        this.startMeasureMode();
    }
  }

  #showHideRafterOffsetContainer() {
    const showHide = this.#onOverviewTab ? "hide" : "show";
    animate[showHide](this.rafterOffsetContainerTarget);

    if (!this.#onOverviewTab) {
      this.maxRafterOffsetContainerTarget.innerText = this.focusedRoofPlane.rafterSpacing;
      this.rafterOffsetTextFieldTarget.value = this.focusedRoofPlane.rafterOffset;
      this.rafterOffsetNumberScrollContainerTarget.dataset["components-IrNumberScrollMaxValue"] =
        this.focusedRoofPlane.rafterSpacing;
    }
  }

  #setRoofPlanesForActiveTab() {
    if (this.#onOverviewTab) {
      this.pageRoofPlanes = this.project.roofPlanes;
      this.focusedRoofPlane = undefined;
    } else {
      const roofPlane = this.#roofPlaneForIdentifier(this.activeTab);
      this.focusedRoofPlane = roofPlane;
      this.pageRoofPlanes = [roofPlane];
    }
  }

  #rotateZoomMap() {
    setTimeout(() => {
      if (this.#onOverviewTab) {
        this.mapManager.setProjectSiteValuesOnMoveEnd = true;
        const projectSite = this.project.projectSite;
        this.mapManager.setCenter(fromLonLat([projectSite.effectiveViewerLng, projectSite.effectiveViewerLat]));
        this.mapManager.zoomTo(projectSite.effectiveViewerZoom);
        this.mapManager.rotateTo(projectSite.effectiveViewerRotation);
      } else {
        this.mapManager.setProjectSiteValuesOnMoveEnd = false;
        this.alignViewToFeature(this.mapModelSynchronizer.getFeatureForRoofPlane(this.focusedRoofPlane));
        this.mapManager.rotateTo(this.focusedRoofPlane.eaveRotationAngleRadians);
      }
    }, 200);
  }

  get #onOverviewTab() {
    return this.activeTab === "overview";
  }

  #roofPlaneForIdentifier(identifier) {
    return this.project.roofPlanes.find((rp) => rp.identifier === identifier);
  }

  #populateStatusbar({ timeout }) {
    // When a value like rotation updates, we need to wait for OL to finish doing its thing
    setTimeout(() => {
      this.statusItemZoomTarget.innerText = this.mapManager.zoom.toFixed(3);
      this.statusItemRotationTarget.innerText = `${radiansToDegrees(this.mapManager.rotation).toFixed(1)}°`;
      if (this.#onOverviewTab) {
        this.statusItemPanelsCountTarget.innerText = this.project.panelsCount;
      } else {
        this.statusItemPanelsCountTarget.innerText = `${this.focusedRoofPlane.panelsCount}/${this.project.panelsCount}`;
      }
    }, timeout);
  }

  #setActiveTabStyles() {
    this.tabLinkTargets.forEach((tabLink) => {
      const tabValue = tabLink.dataset["pr-ImageBased-AttachmentsEditorValueParam"];
      const addRemove = tabValue === this.activeTab ? "add" : "remove";
      tabLink.classList[addRemove]("ir-content-tabs__link--active");
    });
  }

  #resetTabLegalityStyles() {
    this.tabLinkTargets.forEach((tabLink) => {
      const roofPlaneIdentifier = tabLink.dataset["pr-ImageBased-AttachmentsEditorValueParam"];
      if (roofPlaneIdentifier === "overview") return;

      const roofPlane = this.project.roofPlaneForIdentifier(roofPlaneIdentifier);
      const addRemove = roofPlane.isAttachmentPlanStatusLegal ? "remove" : "add";
      const errorClass = "ir-content-tabs__link--error";
      tabLink.classList[addRemove](errorClass);
    });
  }

  #showSaveToViewUpdatedAttachmentPlanTooltip() {
    if (this.saveTippy) return;

    this.saveTippy = tippy(this.saveBtnTarget, {
      content: "Save to view updated attachment plan",
      theme: "info",
      animation: "fade",
      placement: "right",
      allowHTML: true,
      showOnCreate: true,
      trigger: "manual",
      hideOnClick: false,
    });
  }

  #hideSaveToViewUpdatedAttachmentPlanTooltip() {
    if (!this.saveTippy) return;

    this.saveTippy.destroy();
    this.saveTippy = undefined;
  }

  zoomOut(event) {
    super.zoomOut(event);
    this.updateProjectSiteValuesForOtherMaps(this.viewOnlyMapController);
  }

  zoomIn(event) {
    super.zoomIn(event);
    this.updateProjectSiteValuesForOtherMaps(this.viewOnlyMapController);
  }

  rotateCounterClockwise(event) {
    super.rotateCounterClockwise(event);
    this.updateProjectSiteValuesForOtherMaps(this.viewOnlyMapController);
  }

  mapMoveUpdateOtherMaps() {
    this.updateProjectSiteValuesForOtherMaps(this.viewOnlyMapController);
  }

  get viewOnlyMapController() {
    return document.querySelector("[data-controller='pr--image-based--layout-editor--map-view-only']").controller;
  }
}
