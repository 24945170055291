import { Stroke, Style } from "ol/style";

const RAFTER_BORDER_COLOR = "rgba(255, 255, 255, 0.5)";
const RAFTER_BORDER_WEIGHT = 1;

const RAFTER_STYLE = new Style({
  stroke: new Stroke({ color: RAFTER_BORDER_COLOR, width: RAFTER_BORDER_WEIGHT, lineDash: [4, 4] }),
});

export const raftersStyle = () => RAFTER_STYLE;
