export const GRID_SETBACK_OUTLINE_INNER_PADDING = 2;
export const GRID_SETBACK_OUTLINE_BORDER = 1;

export default class GridSetbackOutlines {
  innerPadding = 2;

  static calculate(displayGrid) {
    return new this(displayGrid).calculate();
  }

  constructor(displayGrid) {
    this.displayGrid = displayGrid;
  }

  calculate() {
    return {
      outer: this.outer,
      inner: this.inner,
    };
  }

  get gridTopLeft() {
    const { cellPositions, extensionLeft, extensionTop, spaceAroundCellsLeft, spaceAroundCellsTop } = this.displayGrid;
    const firstCell = cellPositions[0][0];

    return {
      x: firstCell.x - spaceAroundCellsLeft - extensionLeft,
      y: firstCell.y - spaceAroundCellsTop - extensionTop,
    };
  }

  get gridBottomRight() {
    const {
      cellPositions,
      columns,
      cellHeight,
      cellWidth,
      extensionBottom,
      extensionRight,
      rows,
      spaceAroundCellsBottom,
      spaceAroundCellsRight,
    } = this.displayGrid;
    const bottomRightCell = cellPositions[rows - 1][columns - 1];

    return {
      x: bottomRightCell.x + cellWidth + 2 * GRID_SETBACK_OUTLINE_BORDER + spaceAroundCellsRight + extensionRight,
      y: bottomRightCell.y + cellHeight + 2 * GRID_SETBACK_OUTLINE_BORDER + spaceAroundCellsBottom + extensionBottom,
    };
  }

  get outer() {
    const { setbackWidth, setbackHeight } = this.displayGrid;
    const x = this.gridTopLeft.x - setbackWidth;
    const y = this.gridTopLeft.y - setbackHeight;
    const gridCellPaddingOffsetWidth = 5;
    const width =
      this.gridBottomRight.x + setbackWidth - x + 2 * GRID_SETBACK_OUTLINE_BORDER - gridCellPaddingOffsetWidth;
    const gridCellPaddingOffsetHeight = 4;
    const height =
      this.gridBottomRight.y + setbackHeight - y + GRID_SETBACK_OUTLINE_BORDER - gridCellPaddingOffsetHeight;
    return { x, y, height, width };
  }

  get inner() {
    const x = this.gridTopLeft.x - GRID_SETBACK_OUTLINE_INNER_PADDING;
    const y = this.gridTopLeft.y - GRID_SETBACK_OUTLINE_INNER_PADDING;
    const gridCellPaddingOffset = 1;
    const width = this.gridBottomRight.x - x - gridCellPaddingOffset;
    const height = this.gridBottomRight.y - y - gridCellPaddingOffset;
    return { x, y, height, width };
  }
}
