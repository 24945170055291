import DaBase from "../../../../da/map/interaction-managers/translate/base";
import { repositionRoofPlane } from "../../modification-helpers/roof-plane";
import { updateProjectOriginAndCartesianCoordinatesForChildren } from "../../modification-helpers/project";

export default class RoofPlanes extends DaBase {
  translateEndForFeature(rpFeature) {
    repositionRoofPlane(rpFeature, this.mapModelSynchronizer, this.mapManager);
    updateProjectOriginAndCartesianCoordinatesForChildren(this.controller.project);
  }
}
