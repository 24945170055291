import { Controller } from "@hotwired/stimulus";

import { track } from "../../helpers/app";

export default class CadDlController extends Controller {
  static targets = ["attachmentSelect"];
  static values = { files: Array, trackingCategory: String };
  /*
  The array of files are generated with: app/views/s3_files/_s3_file.json.jbuilder
  Each file is a hash of S3 data from the backend.  The keys used here are:
  ["url", "name", "isPdf", "isVisible"]
  */

  connect() {
    this.#buildFileDataStructures();
    this.#addFileOptionsToSelect();
  }

  download(event) {
    if (!this.#isValid) {
      alert("Please select a roof attachment");
      return;
    }
    const filename = this.attachmentSelectTarget.value;
    const { fileType } = event.params;
    this.#downloadFile(this.filesByNameAndType[filename][fileType]);
  }

  #buildFileDataStructures() {
    this.files = this.filesValue.filter((f) => f.isVisible);

    this.filesByNameAndType = {};
    this.files.forEach((file) => {
      const baseFilename = file.name.replace(/ CAD blocks (PDF|DWG)/, "");
      if (!this.filesByNameAndType[baseFilename]) {
        this.filesByNameAndType[baseFilename] = {};
      }
      const type = file.isPdf ? "pdf" : "dwg";
      this.filesByNameAndType[baseFilename][type] = file.url;
    });

    this.filenames = Object.keys(this.filesByNameAndType);
  }

  #addFileOptionsToSelect() {
    this.filenames.forEach((filename) => {
      const option = `<option value="${filename}">${filename}</option>`;
      this.attachmentSelectTarget.innerHTML += option;
    });
  }

  get #isValid() {
    return this.attachmentSelectTarget.value !== "";
  }

  #downloadFile(fileUrl) {
    track({ action: "Document Download", category: this.trackingCategoryValue, label: fileUrl });

    // give tracking code time to execute
    setTimeout(() => {
      window.open(fileUrl, "_blank");
    }, 250);
  }
}
