import { Fill, Stroke, Style, Circle } from "ol/style";
import { GeometryType } from "../ol-helpers";
import MultiPoint from "ol/geom/MultiPoint";

import { pointAtFeatureVertex } from "../ol-helpers";
import { addDimensionMarkersToEdges } from "./distance-markers";
import { OBSTRUCTION_DATA_TYPE, ROOF_PLANE_DATA_TYPE, ROOF_SECTION_DATA_TYPE, SETBACK_DATA_TYPE } from "../data-types";
import { selectMeasureStyle } from "./measures";
import { isSetback, selectSetbackPolygonStrokeAndFillColors } from "./setbacks";
import { selectRoofPlanePolygonStrokeAndFillColors } from "./roof-planes";
import { selectObstructionPolygonStrokeAndFillColors } from "./obstructions";
import { selectRoofSectionPolygonStrokeAndFillColors } from "./roof-sections";

export function selectStyle(feature, controller, map) {
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();

  if (geometryType === GeometryType.LINE_STRING) {
    return selectMeasureStyle(feature, controller, map);
  } else {
    return selectStylePolygon(feature, controller, map);
  }
}

export function selectStylePolygon(feature, controller, map) {
  const [strokeColor, fillColor] = selectPolygonStrokeAndFillColors(feature, controller);

  const styles = [];

  const polylineStyle = new Style({
    stroke: new Stroke({ color: strokeColor, width: 3 }),
    fill: new Fill({ color: fillColor }),
    zIndex: 1,
  });
  styles.push(polylineStyle);

  addDimensionMarkersToEdges(feature, controller, styles, strokeColor);

  const selectedVertexOuterCircleStyle = new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({ color: "rgba(255, 0, 0, 1.0)" }),
      snapToPixel: true,
    }),
    geometry: individuallySelectedVertices(feature),
  });
  styles.push(selectedVertexOuterCircleStyle);

  const selectedVertexInnerCircleStyle = new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }),
      snapToPixel: true,
    }),
    geometry: individuallySelectedVertices(feature),
    zIndex: 2,
  });
  styles.push(selectedVertexInnerCircleStyle);

  const vertexCirclesMultiPoint = unselectedVerticesOfSelectedPolygon(
    feature,
    isSetback(feature) ? { withoutFirstRing: true } : {},
  );

  const vertexOuterCircleStyle = new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({ color: strokeColor }),
      snapToPixel: true,
    }),
    geometry: vertexCirclesMultiPoint,
  });
  styles.push(vertexOuterCircleStyle);

  const vertexInnerCircleStyle = new Style({
    image: new Circle({
      radius: 4,
      fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }),
      snapToPixel: true,
    }),
    geometry: vertexCirclesMultiPoint,
    zIndex: 2,
  });
  styles.push(vertexInnerCircleStyle);

  return styles;
}

function selectPolygonStrokeAndFillColors(feature, controller) {
  if (feature.get("illegalShape")) return ["rgba(255, 0, 0, 1.0)", "rgba(255, 0, 0, 0.4)"];

  switch (feature.get("dataType")) {
    case ROOF_PLANE_DATA_TYPE:
      return selectRoofPlanePolygonStrokeAndFillColors(feature, controller);
    case OBSTRUCTION_DATA_TYPE:
      return selectObstructionPolygonStrokeAndFillColors(feature, controller);
    case ROOF_SECTION_DATA_TYPE:
      return selectRoofSectionPolygonStrokeAndFillColors(feature);
    case SETBACK_DATA_TYPE:
      return selectSetbackPolygonStrokeAndFillColors();
    default:
      debugger;
  }
}

function individuallySelectedVertices(feature) {
  const selectedVertexCoordinates = feature.get("selectedVertexCoordinates");
  if (selectedVertexCoordinates && pointAtFeatureVertex(feature, selectedVertexCoordinates)) {
    return new MultiPoint([selectedVertexCoordinates]);
  }

  return new MultiPoint([]);
}

function unselectedVerticesOfSelectedPolygon(feature, argumentOptions = {}) {
  const defaultOptions = { withoutFirstRing: false };
  const options = { ...defaultOptions, ...argumentOptions };

  const geometry = feature.getGeometry();

  let geometryCoordinates = geometry.getCoordinates();
  if (options.withoutFirstRing) {
    geometryCoordinates = geometryCoordinates.slice(1, geometryCoordinates.length);
  }

  // Combine all ring coordinates. For every ring, the first coordinate is repeated at the end, so remove it.
  const coordinates = geometryCoordinates.flatMap((ringCoordinates) => ringCoordinates.slice(0, -1));
  const selectedVertexCoordinates = feature.get("selectedVertexCoordinates");

  if (selectedVertexCoordinates) {
    const unselectedVertexCoordinates = coordinates.filter((coordinate) => {
      return coordinate[0] !== selectedVertexCoordinates[0] && coordinate[1] !== selectedVertexCoordinates[1];
    });
    return new MultiPoint(unselectedVertexCoordinates);
  } else {
    return new MultiPoint(coordinates);
  }
}
