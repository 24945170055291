import { Fill, Stroke, Style, Text } from "ol/style";

import { ZONE_1, ZONE_2, ZONE_2E, ZONE_2N, ZONE_2R, ZONE_3, ZONE_3E, ZONE_3R } from "../../models/panel-model";

const SELECT_TYPE_REGULAR = "regular";
const SELECT_TYPE_SELECTED = "selected";

const WEIGHT_BORDER = 4;

const COLOR_ZONE_1_NORMAL_BORDER = "rgba(0, 96, 217, 1.0)";
const COLOR_ZONE_1_NORMAL_FILL = "rgba(0, 96, 217, 0.6)";

const COLOR_ZONE_1_SELECTED_BORDER = "rgba(255, 255, 255, 1.0)";
const COLOR_ZONE_1_SELECTED_FILL = "rgba(102, 170, 255, 0.6)";

const COLOR_ZONE_2_NORMAL_BORDER = "rgba(201, 148, 7, 1.0)";
const COLOR_ZONE_2_NORMAL_FILL = "rgba(231, 172, 44, 0.6)";

const COLOR_ZONE_2_SELECTED_BORDER = "rgba(255, 255, 255, 1.0)";
const COLOR_ZONE_2_SELECTED_FILL = "rgba(255, 207, 102, 0.6)";

const COLOR_ZONE_3_NORMAL_BORDER = "rgba(216, 95, 19, 1.0)";
const COLOR_ZONE_3_NORMAL_FILL = "rgba(216, 95, 19, 0.6)";

const COLOR_ZONE_3_SELECTED_BORDER = "rgba(255, 255, 255, 1.0)";
const COLOR_ZONE_3_SELECTED_FILL = "rgba(255, 135, 102, 0.6)";

const SELECTED_ILLEGAL_PANEL_BORDER_COLOR = "rgba(255, 255, 255, 1.0)";
const SELECTED_ILLEGAL_PANEL_FILL_COLOR = "rgba(255, 107, 107, 0.7)";

const REGULAR_ILLEGAL_PANEL_BORDER_COLOR = "rgba(193, 39, 1, 1.0)";
const REGULAR_ILLEGAL_PANEL_FILL_COLOR = "rgba(255, 0, 0, 0.6)";

const SILHOUETTE_STROKE_COLOR = "rgba(255, 255, 255, 0.8)";
const SILHOUETTE_STROKE_WIDTH = 1;
const SILHOUETTE_FILL_COLOR = "rgba(255, 255, 255, 0.4)";

const HIGHLIGHTED_STROKE_COLOR = "rgba(225, 0, 165, 1.0)";
const HIGHLIGHTED_FILL_COLOR = "rgba(225, 0, 165, 0.7)";

const STYLE_ZONE_1_NORMAL = new Style({
  stroke: new Stroke({ color: COLOR_ZONE_1_NORMAL_BORDER, width: WEIGHT_BORDER }),
  fill: new Fill({ color: COLOR_ZONE_1_NORMAL_FILL }),
  zIndex: 1,
});

const STYLE_ZONE_1_SELECTED = new Style({
  stroke: new Stroke({ color: COLOR_ZONE_1_SELECTED_BORDER, width: WEIGHT_BORDER }),
  fill: new Fill({ color: COLOR_ZONE_1_SELECTED_FILL }),
  zIndex: 2,
});

const STYLE_ZONE_2_NORMAL = new Style({
  stroke: new Stroke({ color: COLOR_ZONE_2_NORMAL_BORDER, width: WEIGHT_BORDER }),
  fill: new Fill({ color: COLOR_ZONE_2_NORMAL_FILL }),
  zIndex: 1,
});

const STYLE_ZONE_2_SELECTED = new Style({
  stroke: new Stroke({ color: COLOR_ZONE_2_SELECTED_BORDER, width: WEIGHT_BORDER }),
  fill: new Fill({ color: COLOR_ZONE_2_SELECTED_FILL }),
  zIndex: 2,
});

const STYLE_ZONE_3_NORMAL = new Style({
  stroke: new Stroke({ color: COLOR_ZONE_3_NORMAL_BORDER, width: WEIGHT_BORDER }),
  fill: new Fill({ color: COLOR_ZONE_3_NORMAL_FILL }),
  zIndex: 1,
});

const STYLE_ZONE_3_SELECTED = new Style({
  stroke: new Stroke({ color: COLOR_ZONE_3_SELECTED_BORDER, width: WEIGHT_BORDER }),
  fill: new Fill({ color: COLOR_ZONE_3_SELECTED_FILL }),
  zIndex: 2,
});

const ZONE_STYLES_NORMAL = {
  [ZONE_1]: STYLE_ZONE_1_NORMAL,
  [ZONE_2]: STYLE_ZONE_2_NORMAL,
  [ZONE_3]: STYLE_ZONE_3_NORMAL,
};

const ZONE_STYLES_SELECTED = {
  [ZONE_1]: STYLE_ZONE_1_SELECTED,
  [ZONE_2]: STYLE_ZONE_2_SELECTED,
  [ZONE_3]: STYLE_ZONE_3_SELECTED,
};

const REGULAR_ILLEGAL_PANEL_STYLE = new Style({
  stroke: new Stroke({ color: REGULAR_ILLEGAL_PANEL_BORDER_COLOR, width: WEIGHT_BORDER }),
  fill: new Fill({ color: REGULAR_ILLEGAL_PANEL_FILL_COLOR }),
});

const SELECTED_ILLEGAL_PANEL_STYLE = new Style({
  stroke: new Stroke({ color: SELECTED_ILLEGAL_PANEL_BORDER_COLOR, width: WEIGHT_BORDER }),
  fill: new Fill({ color: SELECTED_ILLEGAL_PANEL_FILL_COLOR }),
  zIndex: 10,
});

const SILHOUETTE_PANEL_STYLE = new Style({
  stroke: new Stroke({ color: SILHOUETTE_STROKE_COLOR, width: SILHOUETTE_STROKE_WIDTH }),
  fill: new Fill({ color: SILHOUETTE_FILL_COLOR }),
});

const HIGHLIGHTED_PANEL_STYLE = new Style({
  stroke: new Stroke({ color: HIGHLIGHTED_STROKE_COLOR, width: WEIGHT_BORDER }),
  fill: new Fill({ color: HIGHLIGHTED_FILL_COLOR }),
});

export const silhouettePanelsStyle = () => SILHOUETTE_PANEL_STYLE;

function panelLabelStyle(panel, map, is716) {
  const zoom = map.getView().getZoom();

  if (zoom < 21.5) return;

  const minZoomSize = 21;
  const maxZoomSize = 24;
  const minScale = 0;
  const maxScale = 2.3;

  function zoomScale(number, inMin, inMax, outMin, outMax) {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  const scale = zoomScale(zoom, minZoomSize, maxZoomSize, minScale, maxScale);
  let labelText = panel.zoneLabel;
  if (is716) labelText += `\n${panel.modulePositionLabel}`;

  return new Style({
    text: new Text({
      text: labelText,
      font: "bold 12px Arial",
      fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }),
      overflow: true,
      scale,
    }),
    zIndex: 15,
  });
}

export function panelsStyle(feature, map, is716) {
  if (feature.get("highlighted")) {
    return HIGHLIGHTED_PANEL_STYLE;
  } else if (feature.get("illegalShape")) {
    return normalOrSelectedErrorPanelStyle({ feature, map, is716, selectType: SELECT_TYPE_REGULAR });
  } else {
    return normalOrSelectedPanelStyle({ feature, map, is716, selectType: SELECT_TYPE_REGULAR });
  }
}

function normalOrSelectedErrorPanelStyle({ feature, map, is716, selectType }) {
  const styles = [];

  if (selectType === SELECT_TYPE_REGULAR) {
    styles.push(REGULAR_ILLEGAL_PANEL_STYLE);
  } else {
    styles.push(SELECTED_ILLEGAL_PANEL_STYLE);
  }

  const panel = feature.get("model");
  const panelLabel = panelLabelStyle(panel, map, is716);
  if (panelLabel) styles.push(panelLabel);

  return styles;
}

export function selectedPanelsStyle(feature, map, is716) {
  if (feature.get("highlighted")) {
    return HIGHLIGHTED_PANEL_STYLE;
  } else if (feature.get("illegalShape")) {
    return normalOrSelectedErrorPanelStyle({ feature, map, is716, selectType: SELECT_TYPE_SELECTED });
  } else {
    return normalOrSelectedPanelStyle({ feature, map, is716, selectType: SELECT_TYPE_SELECTED });
  }
}

function normalOrSelectedPanelStyle({ feature, map, is716, selectType }) {
  const panel = feature.get("model");

  const styles = [];

  const zoneStyles = selectType === SELECT_TYPE_REGULAR ? ZONE_STYLES_NORMAL : ZONE_STYLES_SELECTED;

  switch (panel.zone) {
    case ZONE_1:
      styles.push(zoneStyles[ZONE_1]);
      break;
    case ZONE_2:
      styles.push(zoneStyles[ZONE_2]);
      break;
    case ZONE_2E:
      styles.push(zoneStyles[ZONE_2]);
      break;
    case ZONE_2N:
      styles.push(zoneStyles[ZONE_2]);
      break;
    case ZONE_2R:
      styles.push(zoneStyles[ZONE_2]);
      break;
    case ZONE_3:
      styles.push(zoneStyles[ZONE_3]);
      break;
    case ZONE_3E:
      styles.push(zoneStyles[ZONE_3]);
      break;
    case ZONE_3R:
      styles.push(zoneStyles[ZONE_3]);
      break;
  }

  if (feature.get("displayingInProjectReport")) return styles;

  const panelLabel = panelLabelStyle(panel, map, is716);
  if (panelLabel) styles.push(panelLabel);

  return styles;
}
