// cSpell:ignore rpls,Lngs,resequence

import { clone, destroy } from "mobx-state-tree";
import { toLonLat } from "ol/proj";

import RoofPlaneLegalityChecker from "../../../da/map/legality-checkers/roof-plane";
import RoofSectionLegalityChecker from "../../../da/map/legality-checkers/roof-section";
import ObstructionsProximityDetector from "../obstructions/proximity-detector";

import { linkFeatureToModel } from "../../../da/map/modification-helpers/base";
import {
  removeFeaturesForRoofSection,
  deleteRoofSectionAndRemoveFromVectorSource,
  syncRoofSectionCartesianPoints,
  syncRoofSectionBoundingBoxCartesianPoints,
} from "./roof-section";

import { findTopLeftLonLat, setCartesianPointsForModelRelativeToOrigin } from "../../../da/map/ol-geometry";
import { removeFeaturesForRoofPlane } from "../ol-helpers";
import { logger } from "../../../helpers/app";
import SimpleLatLng from "../../../da/map/models/simple-lat-lng";

export function createRoofPlane(rpFeature, project, mapModelSynchronizer, mapManager) {
  const roofPlane = project.addRoofPlane({});
  linkFeatureToModel(rpFeature, roofPlane);

  mapModelSynchronizer.updateRoofPlaneLatLngFromFeature(rpFeature);
  syncRoofPlaneCartesianPoints(roofPlane);
  new RoofPlaneLegalityChecker(mapManager.roofPlanesFeatures, mapModelSynchronizer).markIllegalPolygons(rpFeature);

  new ObstructionsProximityDetector(project).findNearbyObstructionsForRoofPlane(roofPlane);
}

export function modifyRoofPlane(rpFeature, mapModelSynchronizer, mapManager) {
  const roofPlane = mapModelSynchronizer.getRoofPlaneForFeature(
    rpFeature,
    "BxRoofPlaneModificationHelper.modifyRoofPlane",
  );

  updateRoofPlaneGuideLines(rpFeature, roofPlane);
  repositionRoofPlane(rpFeature, mapModelSynchronizer, mapManager);
  deleteAndRebuildDefaultRoofSectionsForRoofPlane(roofPlane, mapModelSynchronizer, mapManager);

  new RoofSectionLegalityChecker(
    rpFeature,
    mapManager.roofSectionsFeatures,
    mapModelSynchronizer,
  ).markIllegalPolygons();
  mapManager.forceRedrawRoofPlanes();
}

function updateRoofPlaneGuideLines(rpFeature, roofPlane) {
  if (!roofPlane.guideLines || roofPlane.guideLines.length === 0) return;

  const lineSegmentsFromPoints = (points) => {
    const segments = [];
    for (let i = 0; i < points.length - 1; i++) {
      segments.push([points[i], points[i + 1]]);
    }
    return segments;
  };

  const featureLatLngs = rpFeature
    .getGeometry()
    .getLinearRing(0)
    .getCoordinates()
    .map((c) => SimpleLatLng.fromLonLat(toLonLat(c)));
  const featureLineSegments = lineSegmentsFromPoints(featureLatLngs);

  const roofPlaneLatLngs = roofPlane.latLngPoints.map((ll) => ll.toJSON());
  const roofPlaneLineSegments = lineSegmentsFromPoints(roofPlaneLatLngs);

  const defaultGuideLineDistance = Math.round(roofPlane.setback * 12);

  const newGuideLines = [];
  for (let i = 0; i < featureLineSegments.length; i++) {
    const lineSegment = featureLineSegments[i];
    const existingSegmentLineIndex = roofPlaneLineSegments.findIndex((rpls) => {
      return (
        rpls[0].lat === lineSegment[0].lat &&
        rpls[0].lng === lineSegment[0].lng &&
        rpls[1].lat === lineSegment[1].lat &&
        rpls[1].lng === lineSegment[1].lng
      );
    });

    const defaultGuideLine = { segmentIndex: i, visible: true, distance: defaultGuideLineDistance };

    if (existingSegmentLineIndex !== -1) {
      const existingGuideLine = roofPlane.guideLineForSegmentIndex(existingSegmentLineIndex);
      if (existingGuideLine) {
        const newGuideLine = { ...existingGuideLine.toJSON(), segmentIndex: i };
        newGuideLines.push(newGuideLine);
      } else {
        newGuideLines.push(defaultGuideLine);
      }
    } else {
      newGuideLines.push(defaultGuideLine);
    }
  }

  roofPlane.setGuideLines(newGuideLines);
}

export function repositionRoofPlane(rpFeature, mapModelSynchronizer, mapManager) {
  const roofPlane = mapModelSynchronizer.getRoofPlaneForFeature(
    rpFeature,
    "BxRoofPlaneModificationHelper.repositionRoofPlane",
  );

  mapModelSynchronizer.updateRoofPlaneLatLngFromFeature(rpFeature);
  syncRoofPlaneCartesianPoints(roofPlane);
  new RoofPlaneLegalityChecker(mapManager.roofPlanesFeatures, mapModelSynchronizer).markIllegalPolygons();
  new ObstructionsProximityDetector(mapManager.project).findNearbyObstructionsForRoofPlane(roofPlane);
}

export function deleteRoofPlane(rpFeature, project, mapModelSynchronizer, mapManager) {
  const roofPlane = mapModelSynchronizer.getRoofPlaneForFeature(
    rpFeature,
    "BxRoofPlaneModificationHelper.deleteRoofPlane",
  );

  if (!roofPlane) {
    console.log("Deleted feature not a polygon. Skipping.");
    return;
  }

  removeRoofSectionsFromVectorSourceForRoofPlane(roofPlane, mapModelSynchronizer, mapManager);
  removeFeaturesForRoofPlane(roofPlane, mapManager);
  new ObstructionsProximityDetector(project).deleteRoofPlane(roofPlane);

  if (roofPlane.id) {
    // this also flags its roof sections for deletion
    roofPlane.flagDeleted();
  } else {
    // currently an unsaved roof plane can't have any roof sections
    // but this method will remove them automatically if future code
    // allows them to be created (e.g. with a default setback)
    project.destroyRoofPlane(roofPlane);
  }

  // This is less efficient if multiple roof planes are being deleted, but it's cleaner
  // because we can be confident that each call to deleteRoofPlane will leave things in
  // a valid final state.
  new RoofPlaneLegalityChecker(mapManager.roofPlanesFeatures, mapModelSynchronizer).markIllegalPolygons();
  project.resequenceRoofPlanesIdentifiers();
  mapModelSynchronizer.syncIdentifiersOnFeatures();
}

export function deleteAndRebuildDefaultRoofSectionsForRoofPlane(roofPlane, mapModelSynchronizer, mapManager) {
  if (!roofPlane.hasDefaultRoofSections) return;

  let priorLayout;
  // You might have 2 if the shape of the roof plane and the setback combine to create
  // multiple separate roof sections (e.g. a vertex that comes really close to another edge)
  // or you might have a default roof section with no layout because there is a custom roof section
  if (roofPlane.defaultRoofSections.length === 1 && roofPlane.defaultRoofSections[0].layout) {
    priorLayout = clone(roofPlane.defaultRoofSections[0].layout);
  }
  roofPlane.defaultRoofSections.forEach((roofSection) => {
    deleteRoofSectionAndRemoveFromVectorSource(roofSection, mapModelSynchronizer, mapManager);
  });
  mapManager.buildNewDefaultRoofSectionsAndSetbacks({ uuid: roofPlane.uuid, priorLayout });
  if (priorLayout) {
    destroy(priorLayout);
  }

  new ObstructionsProximityDetector(mapManager.project).findNearbyObstructionsForRoofPlane(roofPlane);
}

function removeRoofSectionsFromVectorSourceForRoofPlane(roofPlane, mapModelSynchronizer, mapManager) {
  roofPlane.roofSections.forEach((roofSection) => {
    removeFeaturesForRoofSection(roofSection, mapModelSynchronizer, mapManager);
  });
}

function syncRoofPlaneCartesianPoints(roofPlane) {
  const topLeftLonLat = findTopLeftLonLat(roofPlane.latLngPoints);

  setCartesianPointsForModelRelativeToOrigin(topLeftLonLat, roofPlane);
  logger(`RoofPlane ${roofPlane.identifier}: Set cartesian Points`, roofPlane.cartesianPointsToArray);

  roofPlane.roofSections.forEach((rs) => {
    if (!rs.deleted) {
      syncRoofSectionCartesianPoints(topLeftLonLat, rs);
      syncRoofSectionBoundingBoxCartesianPoints(topLeftLonLat, rs);
    }
  });
}
