import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  selectFullReport() {
    this.toggleCheckbox("input[name='report_email[full_report]'", true);
    this.toggleCheckbox("input[name='report_email[bom]'", false);
  }

  selectBOM() {
    this.toggleCheckbox("input[name='report_email[bom]'", true);
    this.toggleCheckbox("input[name='report_email[full_report]'", false);
  }

  toggleCheckbox(inputSelector, check) {
    const inputs = document.querySelectorAll(inputSelector);
    Array.from(inputs).forEach((input) => {
      if (input.checked === check) {
        return;
      }
      input.click();
    });
  }
}
