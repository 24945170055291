import React from "react";
import PropTypes from "prop-types";
import GridCell from "./GridCell";
import { GRID_CELL_ACTIVE, GRID_CELL_INACTIVE } from "./helpers/grid-helper";
import { getCritterGuardDirections } from "../../helpers/critter-guard";

function GridCells({
  cellPositions,
  cellHeight,
  cellWidth,
  cellHovers,
  handleClick,
  gridArray,
  contourGridArray,
  showSelectionMarquee,
  isRailOrientationNs,
  showRails,
  thirdRailUsed,
  arrayEditingAllowed,
  contourEditingAllowed,
  editorMode,
  hiddenEndClamp,
  projectType,
  useCritterGuard,
}) {
  return gridArray.map((row, i) => {
    const isGb4 = projectType === "GB4";

    return (
      <div key={`row-${i}`} className="graphical-tool__grid-row">
        {row.map((cell, j) => {
          // TODO: Calculate the Critter Gard grid setting
          const { x, y } = cellPositions[i][j];
          const marqueeHover = showSelectionMarquee && cellHovers && cellHovers[i] && cellHovers[i][j];
          const contourGridSetting = contourGridArray[i][j];

          let showRailOverhangLeft = true;
          let showRailOverhangRight = true;
          let showRailOverhangTop = true;
          let showRailOverhangBottom = true;

          if (hiddenEndClamp) {
            if (cell === GRID_CELL_INACTIVE && projectType !== "GB4") {
              showRailOverhangLeft = false;
              showRailOverhangRight = false;
              showRailOverhangTop = false;
              showRailOverhangBottom = false;
            } else {
              if (row[j - 1] === undefined || (row[j - 1] === GRID_CELL_INACTIVE && !isGb4))
                showRailOverhangLeft = false;
              if (row[j + 1] === undefined || (row[j + 1] === GRID_CELL_INACTIVE && !isGb4))
                showRailOverhangRight = false;
              if (gridArray[i - 1] === undefined || (gridArray[i - 1][j] === GRID_CELL_INACTIVE && !isGb4))
                showRailOverhangTop = false;
              if (gridArray[i + 1] === undefined || (gridArray[i + 1][j] === GRID_CELL_INACTIVE && !isGb4))
                showRailOverhangBottom = false;
            }
          }

          let useCritterGuardNorth = false;
          let useCritterGuardEast = false;
          let useCritterGuardSouth = false;
          let useCritterGuardWest = false;

          if (useCritterGuard) {
            const directions = getCritterGuardDirections(gridArray, i, j);
            useCritterGuardNorth = directions[0] === "1";
            useCritterGuardEast = directions[1] === "1";
            useCritterGuardSouth = directions[2] === "1";
            useCritterGuardWest = directions[3] === "1";
          }

          return (
            <GridCell
              key={`cell-${i}-${j}`}
              selected={cell === GRID_CELL_ACTIVE}
              marqueeHover={marqueeHover}
              height={cellHeight}
              width={cellWidth}
              row={i}
              column={j}
              x={x}
              y={y}
              handleClick={handleClick}
              isRailOrientationNs={isRailOrientationNs}
              showRails={showRails}
              showRailOverhangLeft={showRailOverhangLeft}
              showRailOverhangRight={showRailOverhangRight}
              showRailOverhangTop={showRailOverhangTop}
              showRailOverhangBottom={showRailOverhangBottom}
              thirdRailUsed={thirdRailUsed}
              contourGridSetting={contourGridSetting}
              arrayEditingAllowed={arrayEditingAllowed}
              contourEditingAllowed={contourEditingAllowed}
              editorMode={editorMode}
              projectType={projectType}
              useCritterGuardNorth={useCritterGuardNorth}
              useCritterGuardEast={useCritterGuardEast}
              useCritterGuardSouth={useCritterGuardSouth}
              useCritterGuardWest={useCritterGuardWest}
            />
          );
        })}
      </div>
    );
  });
}

GridCells.propTypes = {
  cellWidth: PropTypes.number.isRequired,
  cellHeight: PropTypes.number.isRequired,
  cellPositions: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
      }).isRequired,
    ),
  ).isRequired,
  handleClick: PropTypes.func.isRequired,
  showSelectionMarquee: PropTypes.bool,
  gridArray: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOf([GRID_CELL_ACTIVE, GRID_CELL_INACTIVE]))),
  cellHovers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)),
  isRailOrientationNs: PropTypes.bool,
  showRails: PropTypes.bool,
  thirdRailUsed: PropTypes.bool,
  arrayEditingAllowed: PropTypes.bool,
  contourEditingAllowed: PropTypes.bool,
  hiddenEndClamp: PropTypes.bool,
  projectType: PropTypes.string,
};

GridCells.defaultProps = {
  isRailOrientationNs: false,
  showRails: false,
  thirdRailUsed: false,
  arrayEditingAllowed: false,
  contourEditingAllowed: false,
  hiddenEndClamp: false,
};

export default React.memo(GridCells, areEqual);

function areEqual(prevProps, nextProps) {
  if (prevProps.cellWidth !== nextProps.cellWidth) return false;
  if (prevProps.cellHeight !== nextProps.cellHeight) return false;
  if (prevProps.handleClick !== nextProps.handleClick) return false;
  if (prevProps.showSelectionMarquee !== nextProps.showSelectionMarquee) return false;
  if (JSON.stringify(prevProps.cellHovers) !== JSON.stringify(nextProps.cellHovers)) {
    return false;
  }
  if (JSON.stringify(prevProps.gridArray) !== JSON.stringify(nextProps.gridArray)) {
    return false;
  }
  if (JSON.stringify(prevProps.cellPositions) !== JSON.stringify(nextProps.cellPositions)) {
    return false;
  }
  return true;
}
