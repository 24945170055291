import Location from "./location";

// Creates a location object from a Google Place object
export default class extends Location {
  constructor(place) {
    super();
    this.place = place;
    this.placeData = this.placeDataFromPlace();
  }

  get street() {
    if (this.__street !== undefined) return this.__street;

    let result = [this.placeData.street_number.long, this.placeData.route.long]
      .filter((si) => si !== undefined)
      .join(" ");
    if (result === "") result = this.city;
    this.__street = result;
    return result;
  }

  get city() {
    return this.placeData.locality.long || this.placeData.neighborhood.long || "";
  }

  get state() {
    if (this.isAmericanProtectorate) return this.placeData.country.short;

    return this.placeData.administrative_area_level_1.short || this.placeData.locality.long || "";
  }

  get isAmericanProtectorate() {
    return ["PR", "GU"].includes(this.placeData.country.short);
  }

  get zip() {
    return this.placeData.postal_code.long || "";
  }

  get country() {
    if (this.isAmericanProtectorate) {
      return "US";
    } else {
      return this.placeData.country.short;
    }
  }

  get lat() {
    return this.place.geometry.location.lat();
  }

  get lng() {
    return this.place.geometry.location.lng();
  }

  placeDataFromPlace() {
    const data = {
      street_number: {},
      route: {}, // street name
      neighborhood: {},
      locality: {}, // city
      postal_code: {},
      administrative_area_level_1: {}, // state
      country: {},
    };
    this.place.address_components.forEach((ac) => {
      const key = ac.types[0];
      data[key] = { long: ac.long_name, short: ac.short_name };
    });
    return data;
  }
}
