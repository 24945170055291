import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";
import Polygon from "ol/geom/Polygon";

import { linkFeatureToModel } from "../../da/map/modification-helpers/base";
import BoundingBoxBuilder, { buildBoundingBoxFeatureFromGeometry } from "./panel-grid/bounding-box-builder";
import LayoutBuilder from "./panel-grid/layout-builder";
import ArrayBuilder from "./panel-grid/array-builder";
import DaMapModelSynchronizer from "../../da/map/map-model-synchronizer";
import { findTopLeftLonLat } from "../../da/map/ol-geometry";
import { syncRoofSectionCartesianPoints } from "./modification-helpers/roof-section";
import { logger } from "../../helpers/app";
import ObstructionsProximityDetector from "./obstructions/proximity-detector";

export default class MapModelSynchronizer extends DaMapModelSynchronizer {
  featuresForDisplayableRoofPlanes() {
    const features = super.featuresForDisplayableRoofPlanes();

    // update old projects that were created before the global cartesian offset was added
    this.project.roofPlanes.forEach((roofPlane) => {
      if (roofPlane.globalCartesianOffset) return;

      const feature = features.find((feature) => feature.get("uuid") === roofPlane.uuid);

      if (feature) {
        const latLngPoints = this.latLngPointsFromFeature(feature);
        this.setGlobalCartesianOffset(latLngPoints, roofPlane);
      } else {
        debugger;
      }
    });

    return features;
  }

  featuresForObstructions() {
    const opd = new ObstructionsProximityDetector(this.project);

    return this.project.notDeletedObstructions.map((obstruction) => {
      const gco = obstruction.globalCartesianOffset;
      if (gco === undefined || (gco.x === 0 && gco.y === 0)) {
        // update the global offset for obstructions that don't have them
        this.setGlobalCartesianOffset(obstruction.latLngPoints, obstruction);
      }

      if (obstruction.nearbyRoofPlanes.length === 0 || obstruction.nearbyRoofSections.length === 0) {
        // TODO: update old obstructions that were saved before proximity detection existed
        opd.findNearbyRoofPlanesAndSectionsForObstruction(obstruction);
      }
      return this.createFeatureForModel(obstruction);
    });
  }

  featuresForObstructionBuffers() {
    const obstructionBuffersFeatures = [];
    this.project.notDeletedObstructions.forEach((obstruction) => {
      const obstructionBuffer = obstruction.buffer;
      if (obstructionBuffer) {
        const gco = obstructionBuffer.globalCartesianOffset;
        if (gco === undefined || (gco.x === 0 && gco.y === 0)) {
          // update the global offset for obstruction buffers that don't have them
          this.setGlobalCartesianOffset(obstructionBuffer.latLngPoints, obstructionBuffer);
        }
        obstructionBuffersFeatures.push(this.createFeatureForModel(obstructionBuffer));
      }
    });

    return obstructionBuffersFeatures;
  }

  featuresForArrays(limitTo, focusedRoofSection) {
    if (limitTo === "none") return [];

    return this.project.displayableRoofPlanes.flatMap((roofPlane) => {
      return roofPlane.displayableRoofSections.flatMap((roofSection) => {
        if (limitTo === "roofSection" && roofSection.id !== focusedRoofSection) return [];

        // this ensures the bounding box exists
        const boundingBoxFeature = this.roofSectionBoundingBoxFeature(roofSection);

        if (this.controller.showBoundingBoxes) {
          // only display bounding box for power users
          return [boundingBoxFeature].concat(this.roofSectionArrayFeatures(roofSection));
        } else {
          return this.roofSectionArrayFeatures(roofSection);
        }
      });
    });
  }

  roofSectionBoundingBoxFeature(roofSection) {
    if (
      !roofSection.boundingBox ||
      !roofSection.boundingBox.length ||
      !roofSection.boundingBoxCartesianPoints ||
      !roofSection.boundingBoxCartesianPoints.length
    ) {
      // This is to catch any old projects that were saved without bounding boxes
      console.log(`creating missing BB for ${roofSection.displayIdentifier} (ID: ${roofSection.id})`);
      const rsFeature = this.getFeatureForRoofSection(roofSection);
      const boundingBoxBuilder = new BoundingBoxBuilder(this.project, roofSection, rsFeature);
      boundingBoxBuilder.build();
      roofSection.markDirty();
      this.controller.markDirty();
      return boundingBoxBuilder.boundingBoxFeature;
    } else {
      // TODO: BXNR: Switch to createFeatureForModel once they use .lat and .lng
      logger(`using saved BB for ${roofSection.displayIdentifier} (ID: ${roofSection.id})`);
      const coordinates = roofSection.boundingBox.map((p) => fromLonLat([p.lng, p.lat]));
      const geometry = new Polygon([coordinates]);
      return buildBoundingBoxFeatureFromGeometry(roofSection, geometry);
    }
  }

  roofSectionArrayFeatures(roofSection) {
    if (!roofSection.layout) {
      console.log(`building missing layout for ${roofSection.displayIdentifier} (ID: ${roofSection.id})`);
      new LayoutBuilder(this.project, roofSection).build();
    }

    const arrayBuilder = new ArrayBuilder(this.project, roofSection);
    arrayBuilder.build();
    return arrayBuilder.features;
  }

  createFeatureForModel(model) {
    const coordinates = model.latLngPoints.map((p) => fromLonLat(p.toLonLat));
    const geometry = new Polygon([coordinates]);
    const feature = new Feature({ geometry });
    linkFeatureToModel(feature, model);

    return feature;
  }

  syncIdentifiersOnFeatures() {
    super.syncIdentifiersOnFeatures();

    // NOTE: This method is also used to resequence obstructions. In that case,
    //       this section is unnecessary because roof planes have the same identifiers,
    //       so roof sections will have the same displayIdentifiers.
    const mapManager = this.controller.mapManager;
    mapManager.roofSectionsFeatures.forEach((feature) => {
      const roofSection = this.getRoofSectionForFeature(feature);
      feature.set("identifier", roofSection.displayIdentifier);
    });
  }

  shouldSetEditorSettings() {
    return this.controller.isRoofPlanesPage || this.controller.isRoofSectionsPage;
  }

  updateRoofSectionLatLngFromFeature(feature) {
    super.updateRoofSectionLatLngFromFeature(feature);

    const roofSection = this.getRoofSectionForFeature(feature);
    const roofPlane = roofSection.roofPlane;
    syncRoofSectionCartesianPoints(findTopLeftLonLat(roofPlane.latLngPoints), roofSection);
  }

  loadGuideLines() {
    const guideLineFeatures = this.project.displayableRoofPlanes.flatMap((roofPlane) => {
      return this.buildRoofPlaneGuideLineStringFeatures(roofPlane, { guideLinesKey: "guideLines" });
    });

    return guideLineFeatures;
  }

  toggleRoofPlaneGuideLineVisibility(roofPlaneIdentifier, segmentIndex) {
    const guideLine = this.guideLineForSegmentIndex(roofPlaneIdentifier, segmentIndex);
    guideLine.toggleVisibility();

    this.controller.mapManager.reloadGuideLineFeatures();

    return guideLine.visible;
  }

  setGuideLineDistance(roofPlaneIdentifier, segmentIndex, distance) {
    this.guideLineForSegmentIndex(roofPlaneIdentifier, segmentIndex).setDistance(distance);
    this.controller.mapManager.reloadGuideLineFeatures();

    this.controller.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: "BxMapModelSynchronizer#setGuideLineDistance",
    });
  }

  guideLineForSegmentIndex(roofPlaneIdentifier, segmentIndex) {
    const roofPlane = this.project.getRoofPlaneForIdentifier(roofPlaneIdentifier);
    const guideLine = roofPlane.guideLineForSegmentIndex(segmentIndex);
    return guideLine;
  }

  showAllRoofPlaneGuideLines(roofPlaneIdentifier) {
    this.setAllRoofPlaneGuideLinesVisibility(roofPlaneIdentifier, true);
  }

  hideAllGuideLines() {
    this.project.displayableRoofPlanes.forEach((roofPlane) => {
      roofPlane.guideLines.forEach((g) => g.setVisibility(false));
    });
  }

  hideAllRoofPlaneGuideLines(roofPlaneIdentifier) {
    this.setAllRoofPlaneGuideLinesVisibility(roofPlaneIdentifier, false);
  }

  setAllRoofPlaneGuideLinesVisibility(roofPlaneIdentifier, visible) {
    const roofPlane = this.project.getRoofPlaneForIdentifier(roofPlaneIdentifier);
    roofPlane.guideLines.forEach((g) => g.setVisibility(visible));
    this.controller.mapManager.reloadGuideLineFeatures();
  }

  showOnlyRoofPlaneGuideLines(roofPlaneIdentifier) {
    this.project.displayableRoofPlanes.forEach((roofPlane) => {
      const visible = roofPlaneIdentifier === roofPlane.identifier;
      roofPlane.guideLines.forEach((g) => g.setVisibility(visible));
    });
    this.controller.mapManager.reloadGuideLineFeatures();
  }
}
