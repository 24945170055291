import React from "react";
import PropTypes from "prop-types";
import CompressedIcon from "./CompressedIcon";

function Marker({ x, y, label, includesCompressedSpace }) {
  return (
    <div
      className="dimension-crosshairs__line__marker"
      style={{
        left: `${x}px`,
        top: `${y}px`,
      }}
      data-testid="marker"
    >
      <div className="dimension-crosshairs__line__marker__text">≈{label}</div>
      {includesCompressedSpace && (
        <div className="dimension-crosshairs__line__marker__compressed-icon">
          <CompressedIcon />
        </div>
      )}
    </div>
  );
}

Marker.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  includesCompressedSpace: PropTypes.bool.isRequired,
};

export default React.memo(Marker);
