import { Controller } from "@hotwired/stimulus";
import { Form } from "../helpers/form";

export default class extends Controller {
  static values = {
    url: String,
    method: { type: String, default: "post" },
    remote: { type: Boolean, default: true },
  };

  submitted = false;

  submit(event) {
    event.preventDefault();
    if (this.submitted) return;

    this.submitted = true;

    const link = event.target;
    link.disabled = true;

    const data = {};
    for (let [name, value] of Object.entries(event.params)) {
      const snakeCaseName = name.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
      data[snakeCaseName] = value;
    }

    Form[this.methodValue](this.urlValue, data, { remote: this.remoteValue });
  }
}
