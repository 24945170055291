import { types } from "mobx-state-tree";

import LatLngModel from "./lat-lng-model";

const WithLatLngPoints = types
  .model({
    latLngPoints: types.array(LatLngModel),
    // Any model that uses WithLatLngPoints must respond to markDirty()
  })
  .views((self) => ({
    get latLngPointsToArray() {
      return self.latLngPoints.map((point) => point.toLatLng);
    },
    get latLngPointsToString() {
      return JSON.stringify(self.latLngPointsToArray);
    },
    get lineSegmentsLength() {
      return self.latLngPoints.length - 1;
    },
  }))
  .actions((self) => ({
    shiftLatLngPointsByDelta(latDelta, lngDelta) {
      self.markDirty();
      shiftPointsDelta(self.latLngPoints, latDelta, lngDelta);
    },
    setLatLngPoints(newPoints) {
      const oldLatLngPointsString = JSON.stringify(self.latLngPoints);
      const newLatLngPointsString = JSON.stringify(newPoints);
      if (oldLatLngPointsString === newLatLngPointsString) return;

      self.markDirty();
      self.latLngPoints = newPoints;
    },
  }));

function shiftPointsDelta(points, latDelta, lngDelta) {
  points.forEach((point) => {
    point.lat += latDelta;
    point.lng += lngDelta;
  });
}

export default WithLatLngPoints;
