import React from "react";
import PropTypes from "prop-types";

import { inchesToFeetAndInches } from "../../../helpers/app";
import Marker from "./Marker";
import Line from "./Line";

function Horizontal({
  x,
  y,
  width,
  intersectionX,
  realWidth,
  cursorOffset,
  lineOffset,
  compressedLeft,
  compressedRight,
  compressedSpaceXQuadrant,
}) {
  const offset = cursorOffset / 2;
  const middleY = y + offset;
  const middleX = intersectionX + offset;

  let rightCompressedAmount = 0;
  let leftCompressedAmount = 0;
  if (compressedSpaceXQuadrant === "bothOnRight") {
    rightCompressedAmount += compressedLeft + compressedRight;
  } else if (compressedSpaceXQuadrant === "bothOnLeft") {
    leftCompressedAmount += compressedLeft + compressedRight;
  } else {
    leftCompressedAmount += compressedLeft;
    rightCompressedAmount += compressedRight;
  }

  const leftWidth = middleX - x;
  const leftX = x + (middleX - x) / 2;
  const leftRealWidthInches = (leftWidth / width) * realWidth + leftCompressedAmount;

  const rightWidth = width - leftWidth;
  const rightX = middleX + (x + width - middleX) / 2;
  const rightRealWidthInches = (rightWidth / width) * realWidth + rightCompressedAmount;

  const leftMarker = {
    x: leftX,
    y: middleY,
    label: inchesToFeetAndInches(leftRealWidthInches),
    includesCompressedSpace: leftCompressedAmount > 0,
  };

  const rightMarker = {
    x: rightX,
    y: middleY,
    label: inchesToFeetAndInches(rightRealWidthInches),
    includesCompressedSpace: rightCompressedAmount > 0,
  };

  return (
    <div data-testid="horizontal">
      <Line x={x} y={y + lineOffset} length={width} type="horizontal" />
      <Marker {...leftMarker} />
      <Marker {...rightMarker} />
    </div>
  );
}

Horizontal.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  intersectionX: PropTypes.number.isRequired,
  realWidth: PropTypes.number.isRequired,
  cursorOffset: PropTypes.number.isRequired,
  lineOffset: PropTypes.number.isRequired,
  compressedLeft: PropTypes.number.isRequired,
  compressedRight: PropTypes.number.isRequired,
  compressedSpaceXQuadrant: PropTypes.oneOf(["split", "bothOnRight", "bothOnLeft"]).isRequired,
};

export default React.memo(Horizontal);
