import DaBase from "../../../../da/map/interaction-managers/select/base";
import { MEASURE_DATA_TYPE } from "../../../../da/map/data-types";

export default class EditArrays extends DaBase {
  filterSelects = (_feature, layer) => {
    const layerDataType = layer && layer.get("dataType");
    return layerDataType === MEASURE_DATA_TYPE;
  };

  selectClickResetInteractionManagers() {
    this.controller.translateInteractionManager.add();
    this.controller.modifyInteractionManager.add();
  }

  removeSelectedShapes = () => {
    this.baseRemoveSelectedShapes();
  };
}
