import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class BuildingHeightController extends Controller {
  static targets = ["buildingHeightInput", "smallestHorizontalDimensionContainer", "smallestHorizontalDimension"];

  heightChanged(_event) {
    const buildingHeight = Number.parseFloat(this.buildingHeightInputTarget.value);

    if (isNaN(buildingHeight) || buildingHeight <= 60.0) {
      this.smallestHorizontalDimensionContainerTarget.classList.add("d-none");
      this.smallestHorizontalDimensionTarget.value = "";
    } else {
      animate.show(this.smallestHorizontalDimensionContainerTarget);
    }
  }
}
