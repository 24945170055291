import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = [
    "saveAllSubmitBtnContainer",
    "subArraysPayloadField",
    "saveAllSubmitBtn",
    "subArrayForm",
    "saveAllForm",
  ];

  connect() {
    this.projectMaxSpans = JSON.parse(this.element.dataset.projectMaxSpans);
  }

  saveAll(event) {
    this.subArraysPayloadFieldTarget.value = JSON.stringify(this.saveAllPayload);
  }

  get saveAllPayload() {
    const payload = {};
    this.subArrayFormTargets.forEach((form) => {
      const id = form.dataset.subArrayId;
      payload[id] = this.subArrayParamsFromForm(form);
    });
    return payload;
  }

  subArrayParamsFromForm(form) {
    const formData = new FormData(form);
    const subArrayParams = {};
    for (const [name, value] of formData.entries()) {
      const attribute = this.attributeFromSubArrayFormName(name);
      if (attribute !== null) subArrayParams[attribute] = value;
    }
    return subArrayParams;
  }

  attributeFromSubArrayFormName(name) {
    const match = name.match(/^gb_sub_array\[(.*)\]$/);
    return match === null ? null : match[1];
  }

  dirtied() {
    const el = this.saveAllSubmitBtnContainerTarget;
    if (el.style.display === "none") animate.show(el);
  }
}
