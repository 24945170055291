import { Controller } from "@hotwired/stimulus";
import { createLinkAndClick } from "../../helpers/app";
import { confirmDialog } from "../components/ir_dialog/helper";

export default class extends Controller {
  static values = {
    projectType: String,
    projectImageBased: Boolean,
    projectTotalWatts: Number,
  };

  minWattsForShowingDialog = 75000;
  maxWattsForGenericMessage = 500000;
  secondsPerMegaWatt = 6.0;

  click(event) {
    event.preventDefault();

    const href = event.currentTarget.href;
    const target = event.metaKey ? "_blank" : "_self";

    if (!this.shouldShowDialog) {
      createLinkAndClick(href, target);
      return;
    }

    const callback = (confirmed) => {
      if (!confirmed) return;
      createLinkAndClick(href, target);
    };

    const clickPosition = { x: event.pageX, y: event.pageY };
    const options = {
      showNearClickPosition: true,
      clickPosition,
      headerColor: "orange",
      width: 350,
      title: "Large Project Notice",
      autoCloseIfConfirmed: false,
    };
    confirmDialog(this.message, callback, options);
  }

  get shouldShowDialog() {
    return (
      this.projectTypeValue === "bx" &&
      this.projectImageBasedValue &&
      this.projectTotalWattsValue > this.minWattsForShowingDialog
    );
  }

  get message() {
    if (this.projectTotalWattsValue <= this.maxWattsForGenericMessage) {
      return "The project you are navigating to is larger than 75 kW.  It may take a moment to load.";
    } else {
      const estimatedLoadTime = Math.ceil((this.projectTotalWattsValue / 1000000) * this.secondsPerMegaWatt);
      const roundedLoadTime = Math.ceil(estimatedLoadTime / 5.0) * 5;

      return `The project you are navigating to is larger than 75 kW.  It may take up to ${roundedLoadTime} seconds to load.`;
    }
  }
}
