import { fromLonLat } from "ol/proj";

import { pointInPolygon } from "../../../../helpers/point-in-polygon";
import { reRenderSegment } from "../segments/helpers";

/*
After initial segment or panel nudging, this can be called to move any thermal expansions
that are overlapping segments.  This will need to be called recursively until the class
reports no nudges occurred because the process of nudging can result in a chain reaction
of thermal expansion and segment movements.
*/
export default class ThermalExpansionsHorizontalNudger {
  constructor({ controller, railGroup, nudgeDirection }) {
    this.controller = controller;
    this.project = controller.project;
    this.railGroup = railGroup;
    this.nudgeDirection = nudgeDirection;

    this.mapModelSynchronizer = this.controller.mapModelSynchronizer;
    this.nudgesOccurred = false;
  }

  nudge() {
    const railedSegment = this.railGroup.railedParentSegment;
    if (!railedSegment.hasThermalExpansions) return;

    this.#checkOverlapAndNudgeThermalExpansions();

    // After having moved thermal expansions, if they have been moved into a position
    // where they overlap a segment we want to move the segment by that overlap amount
    // in the nudge direction.
    this.#checkOverlapAndNudgeSegments();

    this.railGroup.updateDistancesFromRailedParentStart();
    this.mapModelSynchronizer.reRenderThermalExpansions();

    return this.nudgesOccurred;
  }

  #checkOverlapAndNudgeThermalExpansions() {
    const { segments, railedParentSegment } = this.railGroup;
    const thermalExpansions = railedParentSegment.thermalExpansions;

    for (let j = 0; j < thermalExpansions.length; j++) {
      const thermalExpansion = thermalExpansions[j];
      for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        if (this.#isOverlapping(segment, thermalExpansion, i, false)) {
          this.#nudgeThermalExpansionToSegmentEdge(segment, thermalExpansion);
          this.nudgesOccurred = true;
          // You can only really overlap one segment at a time so we can break out here
          break;
        }
      }
    }
  }

  #checkOverlapAndNudgeSegments() {
    const { segments, railedParentSegment } = this.railGroup;
    const thermalExpansions = railedParentSegment.thermalExpansions;

    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];

      for (let j = 0; j < thermalExpansions.length; j++) {
        const thermalExpansion = thermalExpansions[j];
        const addVisualMarkers = j === 0 && i === 2;
        if (this.#isOverlapping(segment, thermalExpansion, i, addVisualMarkers)) {
          this.#nudgeSegmentToThermalExpansionEdge(segment, thermalExpansion);
          this.nudgesOccurred = true;
          // You can only really overlap one thermal expansion at a time so we can break out here
          break;
        }
      }
    }
  }

  #isOverlapping(segment, thermalExpansion, index, addVisualMarkers) {
    // Top left and 10" down (moving the point down by 10 inches to make sure it is inside the segment)
    const thermalExpansionCheckLatLng1 = thermalExpansion.startLatLng.plus(segment.unitRowVectorLatLng.times(10));
    const thermalExpansionCheckCoordinate1 = fromLonLat(thermalExpansionCheckLatLng1.toLonLat);
    const segmentCoordinates = [segment.latLngPoints.map((llp) => fromLonLat(llp.toLonLat))];
    if (pointInPolygon(thermalExpansionCheckCoordinate1, segmentCoordinates)) return true;

    // Top right and 10" down
    const thermalExpansionCheckLatLng2 = thermalExpansionCheckLatLng1.plus(
      segment.unitColumnVectorLatLng.times(this.project.thermalExpansionLength),
    );
    const thermalExpansionCheckCoordinate2 = fromLonLat(thermalExpansionCheckLatLng2.toLonLat);
    return pointInPolygon(thermalExpansionCheckCoordinate2, segmentCoordinates);
  }

  #nudgeThermalExpansionToSegmentEdge(segment, thermalExpansion) {
    let newStartLatLng;
    if (this.#isNudgingLeft) {
      const nudgeVector = segment.unitColumnVectorLatLng.times(this.project.thermalExpansionLength);
      newStartLatLng = segment.startLatLng.minus(nudgeVector);
    } else {
      const nudgeVector = segment.unitColumnVectorLatLng.times(segment.width);
      newStartLatLng = segment.startLatLng.plus(nudgeVector);
    }
    this.mapModelSynchronizer.setThermalExpansionStartLatLngAndCartesianPoint(thermalExpansion, newStartLatLng);
  }

  #nudgeSegmentToThermalExpansionEdge(segment, thermalExpansion) {
    let newStartLatLng;
    if (this.#isNudgingLeft) {
      const nudgeVector = segment.unitColumnVectorLatLng.times(segment.width);
      newStartLatLng = thermalExpansion.startLatLng.minus(nudgeVector);
    } else {
      const nudgeVector = segment.unitColumnVectorLatLng.times(this.project.thermalExpansionLength);
      newStartLatLng = thermalExpansion.startLatLng.plus(nudgeVector);
    }
    this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(segment, newStartLatLng);

    if (!segment.railed) {
      const distanceFromRailedParentStart = this.mapModelSynchronizer.getDistanceInInches(
        segment.railedParent.startLatLng,
        newStartLatLng,
      );
      segment.setDistanceFromRailedParentStart(distanceFromRailedParentStart);
    }

    reRenderSegment(this.controller, segment);
  }

  get #isNudgingLeft() {
    return this.nudgeDirection === "left";
  }
}
