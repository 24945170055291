import { Feature } from "ol";
import LineString from "ol/geom/LineString";
import { toLonLat } from "ol/proj";

import SimpleLatLng from "../../../../../da/map/models/simple-lat-lng";
import { cartesianPointRelativeTo } from "../../../../../da/map/ol-geometry";

export default class BaseRenderer {
  constructor(controller, identifier) {
    this.controller = controller;
    this.mapManager = controller.mapManager;
    this.project = controller.project;
    this.map = this.mapManager.map;

    this.adjoinments = identifier.segmentProximities;
    this.segmentFeatureSides = identifier.segmentFeatureSides;
    this.segmentFeaturesByUuid = identifier.segmentFeaturesByUuid;

    this.segmentsByUuid = {};
    Object.keys(this.segmentFeaturesByUuid).forEach((segmentUuid) => {
      this.segmentsByUuid[segmentUuid] = this.segmentFeaturesByUuid[segmentUuid].get("model");
    });
  }

  render() {
    this.clearAdjoinmentsOnSegments();

    Object.keys(this.adjoinments).forEach((segmentUuid) => {
      const adjoinments = this.adjoinments[segmentUuid];

      Object.keys(adjoinments).forEach((adjoinmentSide) => {
        if (adjoinments[adjoinmentSide].length === 0) return;

        this.#renderAdjoinmentForSegmentSide(segmentUuid, adjoinmentSide, adjoinments[adjoinmentSide]);
      });
    });
  }

  #renderAdjoinmentForSegmentSide(segmentUuid, adjoinmentSide, adjoinmentsOnSide) {
    const segmentFeatureSide = this.segmentFeatureSides[segmentUuid][adjoinmentSide];

    const sidePixels = segmentFeatureSide.pixels;
    adjoinmentsOnSide.forEach((adjoiningSegmentUuid) => {
      if (adjoinmentSide === "right") {
        const adjoiningPixels = this.segmentFeatureSides[adjoiningSegmentUuid].left.pixels;
        const topPixelY = sidePixels[0][1] < adjoiningPixels[1][1] ? adjoiningPixels[1][1] : sidePixels[0][1];
        const bottomPixelY = sidePixels[1][1] > adjoiningPixels[0][1] ? adjoiningPixels[0][1] : sidePixels[1][1];
        this.#renderFeatureFromPixelCoordinates(
          segmentUuid,
          adjoiningSegmentUuid,
          [sidePixels[0][0], topPixelY],
          [sidePixels[1][0], bottomPixelY],
          adjoinmentSide,
        );
      } else if (adjoinmentSide === "left") {
        const adjoiningPixels = this.segmentFeatureSides[adjoiningSegmentUuid].right.pixels;
        const topPixelY = sidePixels[1][1] < adjoiningPixels[0][1] ? adjoiningPixels[0][1] : sidePixels[1][1];
        const bottomPixelY = sidePixels[0][1] > adjoiningPixels[1][1] ? adjoiningPixels[1][1] : sidePixels[0][1];
        this.#renderFeatureFromPixelCoordinates(
          segmentUuid,
          adjoiningSegmentUuid,
          [sidePixels[1][0], topPixelY],
          [sidePixels[0][0], bottomPixelY],
          adjoinmentSide,
        );
      } else if (adjoinmentSide === "top") {
        const adjoiningPixels = this.segmentFeatureSides[adjoiningSegmentUuid].bottom.pixels;
        const leftPixelX = sidePixels[0][0] < adjoiningPixels[1][0] ? adjoiningPixels[1][0] : sidePixels[0][0];
        const rightPixelX = sidePixels[1][0] > adjoiningPixels[0][0] ? adjoiningPixels[0][0] : sidePixels[1][0];
        this.#renderFeatureFromPixelCoordinates(
          segmentUuid,
          adjoiningSegmentUuid,
          [leftPixelX, sidePixels[0][1]],
          [rightPixelX, sidePixels[1][1]],
          adjoinmentSide,
        );
      } else if (adjoinmentSide === "bottom") {
        const adjoiningPixels = this.segmentFeatureSides[adjoiningSegmentUuid].top.pixels;
        const leftPixelX = sidePixels[1][0] < adjoiningPixels[0][0] ? adjoiningPixels[0][0] : sidePixels[1][0];
        const rightPixelX = sidePixels[0][0] > adjoiningPixels[1][0] ? adjoiningPixels[1][0] : sidePixels[0][0];
        this.#renderFeatureFromPixelCoordinates(
          segmentUuid,
          adjoiningSegmentUuid,
          [leftPixelX, sidePixels[1][1]],
          [rightPixelX, sidePixels[0][1]],
          adjoinmentSide,
        );
      }
    });
  }

  #renderFeatureFromPixelCoordinates(segmentUuid, adjoiningSegmentUuid, pixelOne, pixelTwo, adjoinmentSide) {
    const coordinateOne = this.map.getCoordinateFromPixel(pixelOne);
    const coordinateTwo = this.map.getCoordinateFromPixel(pixelTwo);
    const geometry = new LineString([coordinateOne, coordinateTwo]);
    const feature = new Feature({ geometry });
    feature.set("segmentUuid", segmentUuid);

    this.addAdjoinmentFeature(feature);

    this.#storeAdjoinmentOnSegmentModel(
      segmentUuid,
      adjoiningSegmentUuid,
      coordinateOne,
      coordinateTwo,
      adjoinmentSide,
    );
  }

  #storeAdjoinmentOnSegmentModel(segmentUuid, adjoiningSegmentUuid, coordinateOne, coordinateTwo, adjoinmentSide) {
    const latLngOne = SimpleLatLng.fromLonLat(toLonLat(coordinateOne));
    const latLngTwo = SimpleLatLng.fromLonLat(toLonLat(coordinateTwo));

    const segment = this.segmentsByUuid[segmentUuid];

    const origin = this.project.detail.originLatLng.toLonLat;
    const cartesianPointOne = cartesianPointRelativeTo(latLngOne.toLonLat, origin);
    const cartesianPointTwo = cartesianPointRelativeTo(latLngTwo.toLonLat, origin);

    const adjoinmentData = {
      segmentUuid,
      adjoiningSegmentUuid,
      adjoinmentSide,
      latLngPoints: [latLngOne.toSimpleObject, latLngTwo.toSimpleObject],
      cartesianPoints: [cartesianPointOne, cartesianPointTwo],
    };

    this.addAdjoinment(segment, adjoinmentData);
  }
}
