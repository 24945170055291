import { toJS } from "mobx";
import { types } from "mobx-state-tree";

import LayoutModel, { layoutPersistenceData } from "./layout-model";
import LatLngModel from "../../da/models/lat-lng-model";
import CartesianModel from "../../da/models/cartesian-model";

import { logger } from "../../helpers/app";

import DaRoofSectionModel from "../../da/models/roof-section-model";
import WithCartesianPoints from "../../da/models/with-cartesian-points";

const RoofSectionBase = types
  .model("RoofSectionModel", {
    azimuth: types.number, // in degrees
    custom: types.boolean,
    active: types.boolean,
    layout: types.maybe(LayoutModel),
    boundingBox: types.array(LatLngModel),
    boundingBoxCartesianPoints: types.array(CartesianModel),
    offset: LatLngModel,
    offsetCartesianPoint: CartesianModel,
    fresh: types.optional(types.boolean, true),
    cellsWithErrors: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get displayIdentifier() {
      let prefix = "";
      if (!self.active) prefix = "Inactive-";
      if (self.deleted) prefix = "Deleted-";

      return `${prefix}${self.roofPlane.identifier}${self.identifier}`;
    },
    get isDefault() {
      return !self.custom;
    },
    get isStale() {
      return !self.fresh;
    },
    get displayable() {
      return !self.deleted && self.active && self.display;
    },
    get boundingBoxToString() {
      if (!self.boundingBox) return "[]";

      const boundingBoxArray = self.boundingBox.map((point) => point.toLatLng);
      return JSON.stringify(boundingBoxArray);
    },
    get boundingBoxCartesianPointsToArray() {
      if (!self.boundingBoxCartesianPoints) return [];

      return self.boundingBoxCartesianPoints.map((point) => point.toArray);
    },
    get boundingBoxCartesianPointsToString() {
      if (!self.boundingBoxCartesianPoints) return "[]";

      return JSON.stringify(self.boundingBoxCartesianPointsToArray);
    },
    get needsSave() {
      return self.dirty || !self.id || (self.layout && self.layout.needsSave);
    },
    get panelsPresent() {
      if (self.deleted || !self.active || !self.layout) return 0;
      return self.layout.panelsPresent;
    },
    get wattage() {
      // don't need to worry about checking deleted, active, or not layout since panelsPresent will be 0 if any are
      return self.panelsPresent * self.roofPlane.panelWattage;
    },
    get interRowSpacing() {
      return self.roofPlane.interRowSpacing;
    },
    get interColumnSpacing() {
      return self.roofPlane.interColumnSpacing;
    },
    get isDeficientLayout() {
      return self.active && (self.layout === undefined || self.layout.deleted || self.layout.isDeficient);
    },
  }))
  .actions((self) => ({
    flagDeleted() {
      if (self.deleted && !self.active) return;

      self.markDirty();
      self.deleted = true;
      self.active = false;
      self.deleteLayout();
    },
    deactivate() {
      if (!self.active) return;

      self.markDirty();
      self.active = false;
      self.illegalShape = false;
      self.deleteLayout();
    },
    deleteLayout() {
      if (!self.layout) return;

      if (self.layout.isPersisted) {
        self.layout.flagDeleted();
      } else {
        self.markDirty();
        self.layout = undefined;
      }
    },
    activate() {
      if (self.active) return;

      self.markDirty();
      self.active = true;
    },
    setAzimuth(newAzimuth) {
      if (self.azimuth === newAzimuth) return;

      self.markDirty();
      self.azimuth = newAzimuth;
    },
    setBoundingBox(newBoundingBox) {
      const oldBoundingBoxString = JSON.stringify(self.boundingBox);
      const newBoundingBoxString = JSON.stringify(newBoundingBox);
      if (oldBoundingBoxString === newBoundingBoxString) return;

      self.markDirty();
      self.boundingBox = newBoundingBox;
    },
    setBoundingBoxCartesianPoints(newBoundingBoxCartesianPoints) {
      const oldBoundingBoxCartesianPointsString = JSON.stringify(self.boundingBoxCartesianPoints);
      const newBoundingBoxCartesianPointsString = JSON.stringify(newBoundingBoxCartesianPoints);
      if (oldBoundingBoxCartesianPointsString === newBoundingBoxCartesianPointsString) return;

      self.markDirty();
      self.boundingBoxCartesianPoints = newBoundingBoxCartesianPoints;
    },
    shiftBoundingBoxByDelta(deltaLat, deltaLng) {
      self.markDirty();
      self.boundingBox.forEach((point) => {
        point.lat += deltaLat;
        point.lng += deltaLng;
      });
    },
    addLayout(rows, columns, design, fresh, cellErrorPositions) {
      self.markDirty();

      const cloneCellErrorPositions = toJS(cellErrorPositions);

      self.layout = LayoutModel.create({
        rows,
        columns,
        design,
        fresh,
        cellErrorPositions: cloneCellErrorPositions,
      });
      self.layout.setupCells();
    },
    copyLayoutFrom(otherRoofSection) {
      const otherLayout = otherRoofSection.layout;

      if (otherLayout) {
        self.addLayout(
          otherLayout.rows,
          otherLayout.columns,
          otherLayout.design,
          otherLayout.fresh,
          otherLayout.cellErrorPositions,
        );
      }
    },
    adjustOffset(offsetDelta, offsetCartesianDelta) {
      self.markDirty();
      self.offset.lat += offsetDelta.lat;
      self.offset.lng += offsetDelta.lng;
      self.offsetCartesianPoint.x += offsetCartesianDelta.x;
      self.offsetCartesianPoint.y += offsetCartesianDelta.y;
    },
    resetOffset() {
      if (self.offset.lat === 0 && self.offset.lng === 0) return;

      self.markDirty();
      self.offset.lat = 0;
      self.offset.lng = 0;
      self.offsetCartesianPoint.x = 0;
      self.offsetCartesianPoint.y = 0;
    },
    applyUpdatesReturnedBySave(json) {
      if (json) {
        self.cellsWithErrors = json["cellsWithErrors"];
      }

      if (self.layout) {
        self.layout.setupCells();
      }
    },
    freshen() {
      if (self.fresh) return;

      self.markDirty();
      self.fresh = true;
    },
  }));

export function roofSectionPersistenceData(roofSection) {
  logger(`Persisting Roof Section ${roofSection.displayIdentifier}`);

  if (!roofSection.needsSave) {
    logger("--!needsSave. Skipping.");
    return undefined;
  }

  const [latLngPoints, boundingBox, boundingBoxCartesianPoints, cartesianPoints] = pointsForSave(roofSection);

  let layout_attributes;
  let clearLayout;

  if (roofSection.layout) {
    layout_attributes = layoutPersistenceData(roofSection.layout);
  } else {
    clearLayout = true;
  }

  return {
    bx_roof_plane_id: roofSection.roofPlane.id,
    uuid: roofSection.uuid,
    id: roofSection.id,
    azimuth: roofSection.azimuth,
    identifier: roofSection.identifier,
    custom: roofSection.custom,
    active: roofSection.active,
    illegal_shape: roofSection.illegalShape,
    encroaching: roofSection.encroaching,
    lat_lng_points: latLngPoints,
    cartesian_points: cartesianPoints,
    bounding_box: boundingBox,
    bounding_box_cartesian_points: boundingBoxCartesianPoints,
    offset_lat: roofSection.offset.lat,
    offset_lng: roofSection.offset.lng,
    offset_y: roofSection.offsetCartesianPoint.y,
    offset_x: roofSection.offsetCartesianPoint.x,
    fresh: roofSection.fresh,
    _destroy: roofSection.deleted,
    // only 1 of these two will be something other than undefined
    clear_layout: clearLayout,
    layout_attributes,
  };
}

function pointsForSave(roofSection) {
  let latLngPoints;
  let boundingBox;
  let boundingBoxCartesianPoints;
  let cartesianPoints;

  if (roofSection.deleted) {
    logger("--Deleted. Blanking latLng & boundingBox");
    latLngPoints = "[]";
    boundingBox = "[]";
    boundingBoxCartesianPoints = "[]";
    cartesianPoints = "[]";
  } else if (roofSection.dirty) {
    latLngPoints = roofSection.latLngPointsToString;
    if (roofSection.active) {
      boundingBox = roofSection.boundingBoxToString;
      boundingBoxCartesianPoints = roofSection.boundingBoxCartesianPointsToString;
    } else {
      logger("--Not active. Blanking boundingBox");
      boundingBox = "[]";
      boundingBoxCartesianPoints = "[]";
    }
    cartesianPoints = roofSection.cartesianPointsToString;
  } else {
    logger("--Not dirty. Omitting latLng & boundingBox");
    latLngPoints = undefined;
    boundingBox = undefined;
    boundingBoxCartesianPoints = undefined;
    cartesianPoints = undefined;
  }

  return [latLngPoints, boundingBox, boundingBoxCartesianPoints, cartesianPoints];
}

const RoofSectionModel = types.compose(DaRoofSectionModel, WithCartesianPoints, RoofSectionBase);
export default RoofSectionModel;
