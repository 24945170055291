import { toLonLat } from "ol/proj";
import DaBase from "../../../../da/map/interaction-managers/translate/base";
import { repositionRoofPlane } from "../../modification-helpers/roof-plane";
import { moveRoofSectionByDelta } from "../../modification-helpers/roof-section";

export default class RoofPlanes extends DaBase {
  translateEndForFeature(rpFeature) {
    this.moveRoofSectionsOfRoofPlane(rpFeature);
    repositionRoofPlane(rpFeature, this.mapModelSynchronizer, this.mapManager);
  }

  moveRoofSectionsOfRoofPlane(rpFeature) {
    const roofPlane = this.mapModelSynchronizer.getRoofPlaneForFeature(
      rpFeature,
      "BxRoofPlanesTranslateInteractionManager#moveRoofSectionsOfRoofPlane",
    );
    const delta = this.deltaInLatLng(rpFeature, roofPlane);

    roofPlane.roofSections.forEach((rs) => moveRoofSectionByDelta(rs, delta[0], delta[1]));
  }

  deltaInLatLng(rpFeature, roofPlane) {
    const geometry = rpFeature.getGeometry();
    const rpFeatureCoordinates = geometry.getLinearRing(0).getCoordinates(); // get outermost ring
    const firstPointFromFeature = rpFeatureCoordinates[0];
    const firstPointFromFeatureInLonLat = toLonLat(firstPointFromFeature);
    const firstPointFromFeatureInLatLng = [firstPointFromFeatureInLonLat[1], firstPointFromFeatureInLonLat[0]];

    const roofPlaneCoordinates = roofPlane.latLngPoints;
    const firstPointFromRoofPlane = roofPlaneCoordinates[0];
    const firstPointFromRoofPlaneInLatLng = firstPointFromRoofPlane.toLatLng;

    return [
      firstPointFromFeatureInLatLng[0] - firstPointFromRoofPlaneInLatLng[0],
      firstPointFromFeatureInLatLng[1] - firstPointFromRoofPlaneInLatLng[1],
    ];
  }
}
