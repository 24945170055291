import React from "react";
import PropTypes from "prop-types";
import ArrowLineEnd from "./ArrowLineEnd";
import CompressedIcon from "./CompressedIcon";

function DimensionMarker({ orientation, dimension, x, y, width, height, compressedBeginning, compressedEnd }) {
  const styles = { left: `${x}px`, top: `${y}px` };
  if (orientation === "vertical") styles.height = `${height}px`;
  if (orientation === "horizontal") styles.width = `${width}px`;

  const classNames = ["graphical-tool__dimension-marker", `graphical-tool__dimension-marker--${orientation}`];
  if (compressedBeginning) {
    classNames.push("graphical-tool__dimension-marker--compressed-beginning");
  }
  if (compressedEnd) {
    classNames.push("graphical-tool__dimension-marker--compressed-end");
  }

  return (
    <div className={classNames.join(" ")} style={styles} data-testid="dimension-marker">
      {compressedBeginning && (
        <>
          <div
            className={[
              "graphical-tool__dimension-marker__compressed-marker",
              `graphical-tool__dimension-marker__compressed-marker--beginning-${orientation}`,
            ].join(" ")}
          >
            <CompressedIcon />
          </div>
          <div
            className={[
              "graphical-tool__dimension-marker__arrow",
              `graphical-tool__dimension-marker__arrow--beginning-${orientation}`,
            ].join(" ")}
          >
            <ArrowLineEnd />
          </div>
        </>
      )}
      {compressedEnd && (
        <>
          <div
            className={[
              "graphical-tool__dimension-marker__compressed-marker",
              `graphical-tool__dimension-marker__compressed-marker--end-${orientation}`,
            ].join(" ")}
          >
            <CompressedIcon />
          </div>
          <div
            className={[
              "graphical-tool__dimension-marker__arrow",
              `graphical-tool__dimension-marker__arrow--end-${orientation}`,
            ].join(" ")}
          >
            <ArrowLineEnd />
          </div>
        </>
      )}
      <div
        className={[
          "graphical-tool__dimension-marker__bg-line",
          `graphical-tool__dimension-marker__bg-line--${orientation}`,
        ].join(" ")}
      />
      <div
        className={[
          "graphical-tool__dimension-marker__marker",
          `graphical-tool__dimension-marker__marker--${orientation}`,
        ].join(" ")}
      >
        {dimension}
      </div>
    </div>
  );
}

DimensionMarker.propTypes = {
  orientation: PropTypes.oneOf(["horizontal", "vertical"]).isRequired,
  dimension: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  compressedBeginning: PropTypes.bool.isRequired,
  compressedEnd: PropTypes.bool.isRequired,
};

DimensionMarker.defaultProps = {
  width: 0,
  height: 0,
};

export default React.memo(DimensionMarker);
