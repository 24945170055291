import { Controller } from "@hotwired/stimulus";
import { hideDialog } from "../../components/ir_dialog/helper";
import * as animate from "../../../helpers/animate";

export default class extends Controller {
  static targets = ["lengthCheckbox", "customLengthField", "validationErrorContainer"];
  static outlets = ["da--custom-rail-lengths--display"];
  static values = { allowedValues: Array };

  updateCheckboxForLength(length, checked) {
    const checkbox = this.checkBoxForLength(length);
    checkbox.checked = checked;
  }

  checkBoxForLength(length) {
    return this.lengthCheckboxTargets.find((checkbox) => checkbox.dataset.length === length);
  }

  updateSelections(_event) {
    this.lengthCheckboxTargets.forEach((checkbox) => {
      const length = checkbox.dataset.length;
      this.daCustomRailLengthsDisplayOutlet.updateCheckboxForLength(length, checkbox.checked);
    });
  }

  fieldInput(event) {
    const field = event.currentTarget;
    let value = field.value.replace(/[^0-9]/g, "");
    field.value = value;
  }

  fieldEnterKeyup(event) {
    if (event.key === "Enter") {
      this.addCustomLength(event);
    }
  }

  addCustomLength(_event) {
    const length = Number.parseInt(this.customLengthFieldTarget.value);
    if (this.allowedValuesValue.includes(length)) {
      this.daCustomRailLengthsDisplayOutlet.updateCheckboxForLength(length, true);
      this.customLengthFieldTarget.value = "";
      animate.hide(this.validationErrorContainerTarget);
      hideDialog(this.element.parentElement);
    } else {
      animate.show(this.validationErrorContainerTarget);
    }
  }
}
