import { Text, Circle, Fill, Stroke, Style } from "ol/style";

export function visualMarkerStyle(feature) {
  const fillColor = feature.get("fillColor") || "#520077";
  const radius = feature.get("radius") || 8;
  const strokeColor = feature.get("strokeColor") || "#fff";
  const strokeWidth = feature.get("strokeWidth") || 1;

  const image = new Circle({
    fill: new Fill({ color: fillColor }),
    stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
    radius,
  });

  let text;
  if (feature.get("text")) {
    text = new Text({
      text: feature.get("text"),
      font: "bold 12px sans-serif",
      fill: new Fill({ color: "white" }),
    });
  }
  return new Style({ image, text });
}
