import VectorSource from "ol/source/Vector";
import { defaults as interactionDefaults } from "ol/interaction";

import BaseMapManager from "./base";
import { transparentOrRegularSegmentStyle } from "../styles/segments";
import { panelsStyle } from "../styles/zone-panels";

export default class ViewOnlyMapManager extends BaseMapManager {
  add() {
    this.buildRoofPlanesVectorSource();
    this.buildRoofSectionsVectorSource();
    this.buildSetbacksVectorSource();
    this.buildPanelsVectorSource();
    this.buildSegmentsVectorSource();
    this.buildRailsVectorSource();
    this.buildThermalExpansionsVectorSource();
    this.buildAdjoinmentsVectorSource();
    this.buildContourLegalityAdjoinmentsVectorSource();
    this.buildContoursVectorSource();

    this.map = this.buildMap({
      layers: [
        this.tileLayer,
        this.roofPlanesVectorLayer,
        this.roofSectionsVectorLayer,
        this.setbacksVectorLayer,
        this.panelsVectorLayer,
        this.segmentsVectorLayer,
        this.railsVectorLayer,
        this.thermalExpansionsVectorLayer,
        this.contoursVectorLayer,
        this.contourLegalityAdjoinmentsVectorLayer,
        this.adjoinmentsVectorLayer,
      ],
      interactions: interactionDefaults({ doubleClickZoom: false, mouseWheelZoom: false }),
    });

    if (this.project.segments.length > 0) {
      // We need to have the map in place first
      this.buildRafterVectorSourcesLayersAndFeatures();
    }

    this.renderContourFeatures();

    this.addMouseWheelZoomWhenCtrlKeyPressedInteraction();

    this.segmentsVectorLayer.setZIndex(60);
    this.panelsVectorLayer.setZIndex(40);

    this.map.on("moveend", this.onMoveEnd);
  }

  onMoveEndHandler(event) {
    const continueProcessing = super.onMoveEndHandler(event);
    if (!continueProcessing) return;

    this.controller.mapMoveUpdateOtherMaps();
  }

  buildPanelsVectorSource() {
    this.panelsVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.featuresForAllPanels(),
    });
  }

  panelsStyle = (feature) => {
    return panelsStyle(feature, this.map, this.controller.project.is716Or722);
  };

  segmentsStyle = (feature) => {
    return transparentOrRegularSegmentStyle(feature);
  };
}
