import { Controller } from "@hotwired/stimulus";

import { defaultHeaders } from "../../helpers/api";
import { alertDialog, hideDialog } from "../components/ir_dialog/helper";

export default class extends Controller {
  static values = {
    path: String,
    dialogId: String,
    userSignedIn: Boolean,
    timeEstimateMilliseconds: { type: Number, default: 0 },
  };

  download() {
    if (!this.userSignedInValue) return;

    if (this.timeEstimateMillisecondsValue > 10_000) {
      const rangeFrom = this.rounded(Number.parseInt(this.timeEstimateMillisecondsValue / 1000));
      const rangeTo = this.rounded(
        Number.parseInt((this.timeEstimateMillisecondsValue + this.timeEstimateMillisecondsValue * 0.3) / 1000),
      );
      const message = `We estimate this download could take ${rangeFrom} to ${rangeTo} seconds`;

      this.dialog.querySelector("[data-identifier='timeEstimateWarning']").innerHTML = message;
      this.dialog.querySelector("[data-identifier='timeEstimateWarningContainer']").classList.remove("d-none");
    }

    setTimeout(() => {
      fetch(this.pathValue, {
        headers: { ...defaultHeaders(), Accept: "text/javascript,*/*" },
      }).then((response) => this.#downloadFile(response));
    }, 200);
  }

  rounded(value) {
    return Number.parseInt(5 * Math.round(value / 5.0));
  }

  get dialog() {
    return document.querySelector(`[data-dialog-identifier="${this.dialogIdValue}"]`);
  }

  #downloadFile(response) {
    if (!response.ok) {
      if (response.status === 302) {
        response.json().then((json) => {
          window.location = json.redirect_path;
        });
        return;
      }
      const title = `Server Error (${response.status})`;
      const message =
        "<p>We encountered a server error while trying to process your request. An admin has been notified of the problem.  We apologize for the inconvenience.</p>";

      alertDialog(message, () => {}, {
        title,
        headerColor: "red",
        confirmBtnColor: "red",
      });
      return;
    }

    const contentDisposition = response.headers.get("Content-Disposition");
    const filename = contentDisposition.match(/filename="(.*)";/)[1];
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      hideDialog(this.dialog);
    });
  }
}
