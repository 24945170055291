// A caching mixin for your object
const WithCaching = {
  cached(key, valueMethod) {
    if (this.__cachedValues === undefined) this.__cachedValues = {};

    const cachedValue = this.__cachedValues[key];
    if (cachedValue) return cachedValue;

    const value = valueMethod();
    this.__cachedValues[key] = value;
    return value;
  },
};

export default WithCaching;
