import React from "react";

const NoMatchingRecords = (_) => (
  <div className="pp__no-matching-records">
    <i className="fas fa-exclamation-triangle" />
    No matching records were found
  </div>
);

export default NoMatchingRecords;
