import WithCaching from "../../../helpers/with-caching";

export default class GridReal {
  spaceAroundCellsCap = 60;

  constructor({
    columns,
    cellWidth,
    cellHeight,
    columnSpacing,
    extensionTop,
    extensionRight,
    extensionBottom,
    extensionLeft,
    rows,
    rowSpacing,
    setback,
    spaceAroundCellsTop,
    spaceAroundCellsRight,
    spaceAroundCellsBottom,
    spaceAroundCellsLeft,
    compressSpaceAroundCells = true,
  }) {
    Object.assign(this, WithCaching);

    this.cellHeight = cellHeight;
    this.cellWidth = cellWidth;
    this.columns = columns;
    this.columnSpacing = columnSpacing;
    this.extensionTop = extensionTop;
    this.extensionRight = extensionRight;
    this.extensionBottom = extensionBottom;
    this.extensionLeft = extensionLeft;
    this.rows = rows;
    this.rowSpacing = rowSpacing;
    this.setbackHeight = setback;
    this.setbackWidth = setback;

    this.compressSpaceAroundCells = compressSpaceAroundCells;
    this.setCompressedSpaceAroundCells(
      spaceAroundCellsTop,
      spaceAroundCellsRight,
      spaceAroundCellsBottom,
      spaceAroundCellsLeft,
    );
  }

  setCompressedSpaceAroundCells(top, right, bottom, left) {
    this.spaceAroundCellsTop = this.compressedSpace(top);
    this.spaceAroundCellsTopIsCompressed = top > this.spaceAroundCellsCap;
    this.spaceAroundCellsTopBeforeCapping = top;
    this.spaceAroundCellsTopCompressedSpace = top - this.spaceAroundCellsTop;

    this.spaceAroundCellsRight = this.compressedSpace(right);
    this.spaceAroundCellsRightIsCompressed = right > this.spaceAroundCellsCap;
    this.spaceAroundCellsRightBeforeCapping = right;
    this.spaceAroundCellsRightCompressedSpace = right - this.spaceAroundCellsRight;

    this.spaceAroundCellsBottom = this.compressedSpace(bottom);
    this.spaceAroundCellsBottomIsCompressed = bottom > this.spaceAroundCellsCap;
    this.spaceAroundCellsBottomBeforeCapping = bottom;
    this.spaceAroundCellsBottomCompressedSpace = bottom - this.spaceAroundCellsBottom;

    this.spaceAroundCellsLeft = this.compressedSpace(left);
    this.spaceAroundCellsLeftIsCompressed = left > this.spaceAroundCellsCap;
    this.spaceAroundCellsLeftBeforeCapping = left;
    this.spaceAroundCellsLeftCompressedSpace = left - this.spaceAroundCellsLeft;
  }

  compressedSpace(value) {
    return this.compressSpaceAroundCells && value > this.spaceAroundCellsCap ? this.spaceAroundCellsCap : value;
  }

  get setbacksWidth() {
    return this.cached("setbacksWidth", () => this.setbackWidth * 2);
  }

  get hasHorizontalSetback() {
    return this.setbackWidth > 0;
  }

  get setbacksHeight() {
    return this.cached("setbacksHeight", () => this.setbackHeight * 2);
  }

  get hasVerticalSetback() {
    return this.setbackHeight > 0;
  }

  get cellsWidth() {
    return this.cached("cellsWidth", () => this.cellWidth * this.columns);
  }

  get cellsHeight() {
    return this.cached("cellsHeight", () => this.cellHeight * this.rows);
  }

  get columnSpacingsWidth() {
    return this.cached("columnSpacingsWidth", () => this.columnSpacing * this.columnSpacesCount);
  }

  get rowSpacingsHeight() {
    return this.cached("rowSpacingsHeight", () => this.rowSpacing * this.rowSpacesCount);
  }

  get rowSpacesCount() {
    return this.rows - 1;
  }

  get columnSpacesCount() {
    return this.columns - 1;
  }

  get width() {
    return this.cached("width", () => {
      return this.setbacksWidth + this.spaceAroundCellsLeft + this.arrayHardwareWidth + this.spaceAroundCellsRight;
    });
  }

  get uncompressedWidth() {
    return this.cached("uncompressedWidth", () => {
      return (
        this.setbacksWidth +
        this.spaceAroundCellsLeftBeforeCapping +
        this.arrayHardwareWidth +
        this.spaceAroundCellsRightBeforeCapping
      );
    });
  }

  get arrayHardwareWidth() {
    return this.cached("arrayHardwareWidth", () => {
      return this.extensionLeft + this.cellsWidth + this.columnSpacingsWidth + this.extensionRight;
    });
  }

  get height() {
    return this.cached("height", () => {
      return this.setbacksHeight + this.spaceAroundCellsTop + this.arrayHardwareHeight + this.spaceAroundCellsBottom;
    });
  }

  get uncompressedHeight() {
    return this.cached("uncompressedHeight", () => {
      return (
        this.setbacksHeight +
        this.spaceAroundCellsTopBeforeCapping +
        this.arrayHardwareHeight +
        this.spaceAroundCellsBottomBeforeCapping
      );
    });
  }

  get arrayHardwareHeight() {
    return this.cached(
      "arrayHardwareHeight",
      () => this.extensionTop + this.cellsHeight + this.rowSpacingsHeight + this.extensionBottom,
    );
  }

  get isLongSideWidth() {
    return this.longSide === "width";
  }
}
