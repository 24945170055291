import { Controller } from "@hotwired/stimulus";
import {
  defaultEditorMode,
  GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR,
  GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT,
  storeEditorMode,
} from "../../helpers/graphical-tool-helpers";

export default class extends Controller {
  static values = {
    roofSectionId: Number,
    arrayEditingAllowed: Boolean,
    contourEditingAllowed: Boolean,
  };

  static targets = ["layoutOptionBtn", "contourOptionBtn"];

  activeClass = "graphical-tool__toolbar__button--active";

  connect() {
    const editorMode = defaultEditorMode({
      roofSectionId: this.roofSectionIdValue,
      contourEditingAllowed: this.contourEditingAllowedValue,
      arrayEditingAllowed: this.arrayEditingAllowedValue,
    });

    if (editorMode === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR) {
      this.layoutOptionBtnTarget.classList.remove(this.activeClass);
      this.contourOptionBtnTarget.classList.add(this.activeClass);
      storeEditorMode({ roofSectionId: this.roofSectionIdValue, editorMode });
    }
  }

  changeModeToLayout(event) {
    event.preventDefault();
    this.#setActivityOfOptionBtns(event);
    this.#graphicalToolController.changeModeToLayout();
    storeEditorMode({ roofSectionId: this.roofSectionIdValue, editorMode: GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT });
  }

  changeModeToContour(event) {
    event.preventDefault();

    const element = event.currentTarget;
    const elementIsDisabled = element.disabled || element.className.match(/--disabled/);
    if (elementIsDisabled) return;

    this.#setActivityOfOptionBtns(event);
    this.#graphicalToolController.changeModeToContour();
    storeEditorMode({ roofSectionId: this.roofSectionIdValue, editorMode: GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR });
  }

  #setActivityOfOptionBtns(event) {
    [this.layoutOptionBtnTarget, this.contourOptionBtnTarget].forEach((target) => {
      if (target.classList.contains(this.activeClass)) target.classList.remove(this.activeClass);
    });
    event.currentTarget.classList.add(this.activeClass);
  }

  get #graphicalToolController() {
    const controllerElement = document.querySelector(
      `[data-identifier='graphicalLayoutTool-${this.roofSectionIdValue}']`,
    );

    return controllerElement.controller;
  }
}
