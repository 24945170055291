/*
Used to help display the correct dimensions for the dimension crosshairs,
depending on where the event position is relative to the compressed space thresholds.
*/
export default class GridCompressedSpaceThresholds {
  static xQuadrant(thresholds, x) {
    const { leftX, rightX } = thresholds;

    if (x <= leftX) return "bothOnRight";
    if (x >= rightX) return "bothOnLeft";
    return "split";
  }

  static yQuadrant(thresholds, y) {
    const { topY, bottomY } = thresholds;

    if (y <= topY) return "bothOnBottom";
    if (y >= bottomY) return "bothOnTop";
    return "split";
  }

  static calculate(displayGrid) {
    return new this(displayGrid).calculate();
  }

  constructor(displayGrid) {
    this.markers = displayGrid.compressedSpaceMarkers;
    this.roofOutline = displayGrid.roofOutline;
  }

  calculate() {
    return {
      leftX: this.valueFromMarker("left", "x") || this.roofOutline.x,
      rightX: this.valueFromMarker("right", "x") || this.roofOutline.x + this.roofOutline.width,
      topY: this.valueFromMarker("top", "y") || this.roofOutline.y,
      bottomY: this.valueFromMarker("bottom", "y") || this.roofOutline.y + this.roofOutline.height,
    };
  }

  valueFromMarker(position, attribute) {
    const marker = this.markers.find((m) => m.key === position);
    if (!marker) return undefined;
    return marker[attribute];
  }
}
