export function applyDomUpdatesFromResponse(json) {
  if (!json.domUpdates) return;

  json.domUpdates.forEach(({ selector, html }) => {
    const elements = document.querySelectorAll(selector);
    Array.from(elements).forEach((element) => {
      element.innerHTML = html;
    });
  });
}
