import BaseMapModelSynchronizer from "./base";
import RaftersBuilder from "../modification-helpers/rafters-builder";

export default class PanelPropertiesMapModelSynchronizer extends BaseMapModelSynchronizer {
  loadSetbacks() {
    return [this.buildSetbackForRoofPlane(this.controller.focusedRoofPlane)];
  }

  rafterFeatures() {
    return new RaftersBuilder({ controller: this.controller, roofPlane: this.controller.focusedRoofPlane }).build();
  }

  reRenderRafters() {
    const raftersVectorSource = this.controller.mapManager.raftersVectorSource;
    raftersVectorSource.clear();
    raftersVectorSource.addFeatures(this.rafterFeatures());
  }
}
