export const EDITOR_MODE_DRAW = "draw";
export const EDITOR_MODE_PAINT = "paint";
export const EDITOR_MODE_PAINT_ZONES = "paintZones";
export const EDITOR_MODE_PAINT_MODULE_POSITIONS = "paintModulePositions";
export const EDITOR_MODE_PAINT_CONTOUR = "paintContour";
export const EDITOR_MODE_SELECT = "select";
export const EDITOR_MODE_MEASURE = "measure";
export const EDITOR_MODE_PAN_MAP = "panMap";

export const PANEL_ORIENTATION_PORTRAIT = "portrait";
export const PANEL_ORIENTATION_LANDSCAPE = "landscape";

export const PANEL_MODE_ADD = "add";
export const PANEL_MODE_REMOVE = "remove";
export const PANEL_MODE_TOGGLE = "toggle";

export const SELECT_TYPE_PANELS = "panels";
export const SELECT_TYPE_SEGMENTS = "segments";
export const SELECT_TYPE_MEASURES = "measures";
export const SELECT_TYPE_SETBACK = "setback";
export const SELECT_TYPE_THERMAL_EXPANSIONS = "thermalExpansions";
