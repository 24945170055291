import { Controller } from "@hotwired/stimulus";
import { alertDialog } from "../components/ir_dialog/helper";

export default class extends Controller {
  static values = {
    usingQBox: false,
    isQBoxAvailable: false,
  };

  static targets = ["qBoxCheckbox", "jayBoxRoofMountCheckbox", "jayBoxRailMountCheckbox"];

  jayBoxClick(event) {
    const checked = event.target.checked;

    if (checked && this.hasQBoxCheckboxTarget) {
      if (this.qBoxCheckboxTarget.checked && !this.isQBoxAvailableValue) {
        alertDialog(
          `Selecting JayBox will remove QBox from your project.  After you save, QBox
           will no longer be available as an option for this project.`,
        );
      }

      this.qBoxCheckboxTarget.checked = false;
    }
  }

  qBoxClick(event) {
    const checked = event.target.checked;
    if (checked && this.hasJayBoxRoofMountCheckboxTarget) {
      this.jayBoxRoofMountCheckboxTarget.checked = false;
      this.jayBoxRailMountCheckboxTarget.checked = false;
    }
  }
}
