import React from "react";
import PropTypes from "prop-types";

function ExtensionsOutline({ x, y, height, width }) {
  const styles = {
    height: `${height}px`,
    width: `${width}px`,
    left: `${x}px`,
    top: `${y}px`,
  };

  return <div style={styles} className="graphical-tool__extensions-outline" data-testid="extensions-outline" />;
}

ExtensionsOutline.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export default React.memo(ExtensionsOutline);
