import { types } from "mobx-state-tree";

import CartesianModel from "./cartesian-model";

const WithCartesianPoints = types
  .model({
    cartesianPoints: types.array(CartesianModel),
    // Any model that uses WithCartesianPoints must respond to markDirty()
  })
  .views((self) => ({
    get cartesianPointsToArray() {
      return self.cartesianPoints.map((point) => point.toArray);
    },
    get cartesianPointsToString() {
      return JSON.stringify(self.cartesianPointsToArray);
    },
  }))
  .actions((self) => ({
    setCartesianPoints(newPoints) {
      const oldCartesianPointsString = JSON.stringify(self.cartesianPoints);
      const newCartesianPointsString = JSON.stringify(newPoints);
      if (oldCartesianPointsString === newCartesianPointsString) return;

      self.markDirty();
      self.cartesianPoints = newPoints;
    },
  }));

export default WithCartesianPoints;
