import Base from "./base";
import { selectedPanelsStyle } from "../../styles/zone-panels";
import { selectMeasureStyle } from "../../../../da/map/styles/measures";
import { alertDialog } from "../../../../controllers/components/ir_dialog/helper";

import { MEASURE_DATA_TYPE, PANEL_DATA_TYPE } from "../../../../da/map/data-types";

export default class PanelProperties extends Base {
  selectStyle = (feature) => {
    const isPanel = feature.get("dataType") === PANEL_DATA_TYPE;
    if (isPanel) {
      return selectedPanelsStyle(feature, this.map, this.controller.project.is716Or722);
    } else {
      return selectMeasureStyle(feature, this.controller, this.map);
    }
  };

  filterSelects = (feature, _layer) => {
    if (this.isMeasureSelectionType) {
      return feature.get("dataType") === MEASURE_DATA_TYPE;
    } else {
      return feature.get("dataType") === PANEL_DATA_TYPE;
    }
  };

  add(selectionType) {
    this.selectionType = selectionType;

    if (this.currentSelectInteraction) {
      if (!this.currentDragBoxInteraction) this.addDragBoxInteraction();
    }

    super.add();

    if (this.isMeasureSelectionType) return;

    this.addDragBoxInteraction();

    this.selectionCollection.on(["add"], (collectionEvent) => {
      const { element: panelFeature } = collectionEvent;
      const panel = panelFeature.get("model");

      this.#setPanelProperties(panel);

      this.mapModelSynchronizer.throttledReRenderContourFeatures();

      if (!this.dragBoxMarqueePresent) {
        this.mapManager.dispatchAfterMapFeaturesRendering({
          calledFrom: `PanelPropertiesSelectInteractionManager[selectionCollection add event]`,
        });
      }
    });

    this.selectionCollection.on(["add", "remove"], (_collectionEvent) => {
      this.controller.statusItemSelectionsTarget.innerText = this.selectionCollection.getLength();
      if (this.controller.focusedRoofPlane.needsSave) this.controller.markDirty();
    });

    this.selectionCollection.on(["remove"], (collectionEvent) => {
      if (!this.draggingSelectionMarquee) return;

      const { element: panelFeature } = collectionEvent;
      const panel = panelFeature.get("model");
      panel.setZone(panelFeature.get("zoneBeforeSelection"));
      panel.setModulePosition(panelFeature.get("modulePositionBeforeSelection"));
      panel.setContourCode(panelFeature.get("contourCodeBeforeSelection"));
    });
  }

  setZone(zone) {
    this.selectionCollection.forEach((panelFeature) => {
      const panel = panelFeature.get("model");
      panel.setZone(zone);
      panelFeature.dispatchEvent("change"); // Cause a re-render to pick up the new zone style
    });
  }

  get isPanelSelectionType() {
    return this.selectionType === PANEL_DATA_TYPE;
  }

  get isMeasureSelectionType() {
    return this.selectionType === MEASURE_DATA_TYPE;
  }

  selectClickResetInteractionManagers() {
    if (this.isMeasureSelectionType) {
      // The translate interaction has to be before the modify, or modify doesn't work
      this.controller.translateInteractionManager.add();
      this.controller.modifyInteractionManager.add();
    }
  }

  onDraggingSelectionStart(event) {
    // Go ahead and apply the selected zone or module position to the panel being clicked. This
    // allows the user to click an already selected panel and change its zone or module position.
    const pixel = this.map.getPixelFromCoordinate(event.coordinate);
    this.map.getFeaturesAtPixel(pixel).forEach((feature) => {
      if (feature.get("dataType") !== PANEL_DATA_TYPE) return;

      const panel = feature.get("model");
      this.#setPanelProperties(panel);
    });

    this.mapModelSynchronizer.reRenderContourFeatures();

    this.mapManager.panelsVectorSource.getFeatures().forEach((panelFeature) => {
      const panel = panelFeature.get("model");
      panelFeature.set("zoneBeforeSelection", panel.zone);
      panelFeature.set("modulePositionBeforeSelection", panel.modulePosition);
      panelFeature.set("contourCodeBeforeSelection", panel.contourCode);
    });
  }

  onDraggingSelection() {
    this.dragBoxMarqueePresent = true;
  }

  onDraggingSelectionEnd() {
    this.dragBoxMarqueePresent = false;

    this.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: `PanelPropertiesSelectInteractionManager#onDraggingSelectionEnd`,
    });
  }

  get selectionVectorSource() {
    return this.mapManager.panelsVectorSource;
  }

  setModulePosition(position) {
    this.selectionCollection.forEach((panelFeature) => {
      const panel = panelFeature.get("model");
      panel.setModulePosition(position);
      panelFeature.dispatchEvent("change"); // Cause a re-render to pick up the new zone style
    });
  }

  removeSelectedShapes = () => {
    if (!this.isMeasureSelectionType) {
      const msg = "Sorry you can't delete panels on this screen.  To remove panels use the arrays page.";
      alertDialog(msg, () => {}, {
        title: "Can not delete",
        headerColor: "red",
        confirmBtnColor: "red",
      });
      return;
    }

    this.baseRemoveSelectedShapes();
  };

  #setPanelProperties(panel) {
    if (this.controller.isEditorModePaintZones) {
      const selectedZone = this.controller.paintZonesSelection;
      panel.setZone(selectedZone);
    } else if (this.controller.isEditorModePaintContour) {
      panel.setContourCodeFromDirections(this.controller.contourPaintDirections);
    } else {
      const selectedPosition = this.controller.paintModulePositionsSelection;
      panel.setModulePosition(selectedPosition);
    }
  }
}
