import { ARRAY_CELL_DATA_TYPE } from "../../da/map/data-types";

export function applyOpenLayersUpdatesFromModels(project, mapModelSynchronizer, mapManager) {
  const layouts = layoutsIndexedByRoofSectionUuid(project);

  updateRoofSections(project, mapModelSynchronizer);
  updateLayouts(layouts, mapManager);
}

function layoutsIndexedByRoofSectionUuid(project) {
  const layouts = {};

  project.roofPlanes.forEach((roofPlane) => {
    roofPlane.activeRoofSections.forEach((roofSection) => {
      layouts[roofSection.uuid] = roofSection.layout;
    });
  });

  return layouts;
}

function updateRoofSections(project, mapModelSynchronizer) {
  project.roofPlanes.forEach((roofPlane) => {
    roofPlane.activeRoofSections.forEach((roofSection) => {
      const roofSectionFeature = mapModelSynchronizer.getFeatureForRoofSection(roofSection);
      roofSectionFeature.set("cellsWithErrors", roofSection.cellsWithErrors);
    });
  });
}

function updateLayouts(layouts, mapManager) {
  const arrayFeatures = mapManager.arraysVectorSource.getFeatures();
  arrayFeatures.forEach((feature) => {
    if (feature.get("dataType") == ARRAY_CELL_DATA_TYPE) {
      const row = feature.get("row");
      const column = feature.get("column");
      const layout = layouts[feature.get("roofSectionUuid")];
      feature.set("cellDrawStyle", layout.cellDrawStyle(row, column));
    }
  });
}
