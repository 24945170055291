import MapBaseController from "./map_base_controller";
import * as toolbarBtn from "../../../da/layout-editor/helpers/toolbar-btns";

export default class extends MapBaseController {
  static targets = ["layoutEditorBtn"];

  connect() {
    this.viewOnlyMap = true;
    this.showBoundingBoxes = this.element.dataset.showBoundingBoxes === "true";

    super.connect();

    this.mapManager.buildSetbacksVectorSource();
    this.mapManager.addRoofSectionsAndSetbacksVectorLayers();

    this.mapManager.addArraysVectorLayer();
  }

  // Called by both Stimulus event handler and saveAndThenRedirect triggered by auto save controller
  save(_event, onSuccess = () => {}) {
    toolbarBtn.showSpinner(this.saveBtnTarget);
    this.project.projectSite.save({
      path: this.savePath,
      onSuccess: (_json) => {
        this.markClean();
        onSuccess();
        setTimeout(() => {
          toolbarBtn.hideSpinner(this.saveBtnTarget);
        }, 500);
      },
      onValidationError: this.onValidationError,
      onServerError: this.onServerError,
    });
  }

  clickLayoutEditorLink(event) {
    if (!this.dirty) return;

    event.preventDefault();

    const url = event.currentTarget.href;
    this.saveAndThenRedirect(url);
  }

  populateStatusbar() {
    // Don't do anything. We don't have a status bar on this map.
  }
}
