import { fromLonLat } from "ol/proj";
import { objectsOverlap } from "../overlap-checker";

export default class ObstructionsProximityDetector {
  constructor(project) {
    this.project = project;
  }

  // TODO: Think about the cases where changing a roof plane causes
  // roof sections to be deleted or inactivated (e.g. modifying a default)
  // without the user explicitly doing a deleteRoofSection. Make sure those
  // come out of the obstruction's list (also, custom: false, active: false)

  findNearbyObstructionsForRoofPlane(roofPlane) {
    this.#obstructions.forEach((obstruction) => {
      this.#manageObstructionAndRoofPlane(obstruction, roofPlane);
    });
  }

  deleteRoofPlane(roofPlane) {
    this.#obstructions.forEach((obstruction) => {
      obstruction.removeRoofPlane(roofPlane);
      this.#removeRoofSectionsFromObstructionForRoofPlane(obstruction, roofPlane);
    });
  }

  findNearbyObstructionsForRoofSection(roofSection) {
    this.#obstructions.forEach((obstruction) => {
      this.#manageObstructionAndRoofSection(obstruction, roofSection);
      this.#discardOrphanRoofSections(obstruction);
    });
  }

  deleteRoofSection(roofSection) {
    this.#obstructions.forEach((obstruction) => {
      obstruction.removeRoofSection(roofSection);
    });
  }

  findNearbyRoofPlanesAndSectionsForObstruction(obstruction) {
    this.project.roofPlanes.forEach((roofPlane) => {
      this.#manageObstructionAndRoofPlane(obstruction, roofPlane);
    });
  }

  get #obstructions() {
    return this.project.obstructions;
  }

  #removeRoofSectionsFromObstructionForRoofPlane(obstruction, roofPlane) {
    roofPlane.roofSections.forEach((roofSection) => {
      obstruction.removeRoofSection(roofSection);
    });
  }

  #manageObstructionAndRoofPlane(obstruction, roofPlane) {
    if (obstruction.deleted) return;

    if (!roofPlane.deleted && this.#objectOverlapsObstructionBuffer(roofPlane, obstruction)) {
      obstruction.addRoofPlane(roofPlane);
      roofPlane.roofSections.forEach((roofSection) => {
        this.#manageObstructionAndRoofSection(obstruction, roofSection);
      });
    } else {
      obstruction.removeRoofPlane(roofPlane);
      this.#removeRoofSectionsFromObstructionForRoofPlane(obstruction, roofPlane);
    }

    this.#discardOrphanRoofSections(obstruction);
  }

  #discardOrphanRoofSections(obstruction) {
    obstruction.nearbyRoofSections.forEach((roofSectionUuid) => {
      if (this.#noRoofPlaneContainsRoofSectionWithId(roofSectionUuid)) {
        obstruction.clearRoofSectionUuid(roofSectionUuid);
      }
    });
  }

  #noRoofPlaneContainsRoofSectionWithId(roofSectionUuid) {
    return !this.project.roofPlanes.some((roofPlane) => {
      return roofPlane.roofSections.some((roofSection) => {
        return roofSection.uuid === roofSectionUuid && roofSection.active;
      });
    });
  }

  #manageObstructionAndRoofSection(obstruction, roofSection) {
    if (obstruction.deleted) return;

    if (!roofSection.deleted && roofSection.active && this.#objectOverlapsObstructionBuffer(roofSection, obstruction)) {
      obstruction.addRoofSection(roofSection);
    } else {
      obstruction.removeRoofSection(roofSection);
    }
  }

  #objectOverlapsObstructionBuffer(object, obstruction) {
    const buffer = obstruction.buffer;
    if (buffer === null || buffer === undefined) return;

    const objectInOpenLayersCoordinates = object.latLngPointsToArray.map((p) => fromLonLat([p[1], p[0]]));
    const objectAsPoints = objectInOpenLayersCoordinates.map((c) => [c[0], c[1]]);

    const bufferInOpenLayersCoordinates = buffer.latLngPointsToArray.map((p) => fromLonLat([p[1], p[0]]));
    const bufferAsPoints = bufferInOpenLayersCoordinates.map((c) => [c[0], c[1]]);

    return objectsOverlap(objectInOpenLayersCoordinates, objectAsPoints, bufferInOpenLayersCoordinates, bufferAsPoints);
  }
}
