import { Controller } from "@hotwired/stimulus";

const IN_TO_MM_CONVERSION_FACTOR = 25.4;
const KG_TO_LB_CONVERSION_FACTOR = 2.205;

export default class extends Controller {
  static targets = ["display"];

  conversions = {
    millimetersToInches: {
      converter: (v) => (v / IN_TO_MM_CONVERSION_FACTOR).toFixed(2),
      toMeasurementAbbr: '"',
      toMeasurementSystem: "imperial",
    },
    kilogramsToPounds: {
      converter: (v) => (v * KG_TO_LB_CONVERSION_FACTOR).toFixed(2),
      toMeasurementAbbr: " lbs",
      toMeasurementSystem: "imperial",
    },
  };

  convert(event) {
    const field = event.currentTarget;
    const value = field.value;
    const conversionType = field.dataset.conversionType;
    const allowedConversions = Object.keys(this.conversions);
    if (!allowedConversions.includes(conversionType)) {
      throw `The conversion type \"${conversionType}\" was not recognized.
        The available types are: ${allowedConversions.join(",")}`;
    }

    this.displayTarget.innerHTML = this.convertedDisplayValue(value, conversionType);
  }

  convertedDisplayValue(value, conversionType) {
    const { converter, toMeasurementAbbr, toMeasurementSystem } = this.conversions[conversionType];

    if (!isFinite(value)) return `${toMeasurementSystem}: ?`;

    return `${toMeasurementSystem}: ${converter(value)}${toMeasurementAbbr}`;
  }
}
