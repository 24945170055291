import { Fill, Stroke, Style, Text } from "ol/style";
import { numberVertices } from "./helpers";

const LEGAL_ROOF_SECTION_STROKE_COLOR = "rgba(16, 183, 0, 0.6)";
const LEGAL_ROOF_SECTION_FILL_COLOR = "rgba(16, 183, 0, 0.1)";
const LEGAL_ROOF_SECTION_STROKE = new Stroke({ color: LEGAL_ROOF_SECTION_STROKE_COLOR, width: 3 });
const LEGAL_ROOF_SECTION_FILL = new Fill({ color: LEGAL_ROOF_SECTION_FILL_COLOR });

const ILLEGAL_ROOF_SECTION_STROKE = new Stroke({ color: "rgba(255,0, 0, 1.0)", width: 3 });
const ILLEGAL_ROOF_SECTION_FILL = new Fill({ color: "rgba(255,0, 0, 0.3)" });

const ENCROACHING_ROOF_SECTION_STROKE_COLOR = "rgba(255, 109, 0, 1.0)";
const ENCROACHING_ROOF_SECTION_FILL_COLOR = "rgba(255, 109, 0, 0.2)";
const ENCROACHING_ROOF_SECTION_STROKE = new Stroke({ color: ENCROACHING_ROOF_SECTION_STROKE_COLOR, width: 3 });
const ENCROACHING_ROOF_SECTION_FILL = new Fill({ color: ENCROACHING_ROOF_SECTION_FILL_COLOR });

export function roofSectionsStyle(feature, controller, project) {
  const roofSection = project.getRoofSection(feature.get("uuid"));
  const roofPlane = roofSection.roofPlane;
  const label = roofSection ? roofSection.displayIdentifier : "x";
  const labelText = new Text({ text: label, scale: 2, fill: new Fill({ color: "rgba(255, 255, 255, 1.0)" }) });

  let style;
  const showAsIllegal =
    feature.get("illegalShape") || feature.get("cellsWithErrors") || (project.isBX && roofSection.isDeficientLayout);
  if (showAsIllegal) {
    style = new Style({
      stroke: ILLEGAL_ROOF_SECTION_STROKE,
      fill: ILLEGAL_ROOF_SECTION_FILL,
      text: labelText,
    });
  } else if (roofPlane.stale || feature.get("encroaching") || feature.get("fresh") === false) {
    // have to explicitly check if fresh is false rather than undefined because PR doesn't
    // have that attribute
    style = new Style({
      stroke: ENCROACHING_ROOF_SECTION_STROKE,
      fill: ENCROACHING_ROOF_SECTION_FILL,
      text: labelText,
    });
  } else {
    // legal style
    style = new Style({
      stroke: LEGAL_ROOF_SECTION_STROKE,
      fill: LEGAL_ROOF_SECTION_FILL,
      text: labelText,
    });
  }
  const styles = [style];

  if (controller.debugMode === "YES") numberVertices(feature, styles);

  return styles;
}

export function selectRoofSectionPolygonStrokeAndFillColors(feature) {
  if (feature.get("encroaching")) {
    return [ENCROACHING_ROOF_SECTION_STROKE_COLOR, "rgba(255, 109, 0, 0.3)"];
  } else {
    return [LEGAL_ROOF_SECTION_STROKE_COLOR, "rgba(16, 183, 0, 0.2)"];
  }
}
