import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

import { addOptionsToSelect } from "../../helpers/form";

export default class extends Controller {
  static targets = [
    "conditionalContainer",
    "foundationTypeSelect",
    "pipeDiameterField",
    "screwLengthSelect",
    "groundScrewManufacturerSelect",
    "hexHeadSetScrewsHiddenField",
    "hexHeadSetScrewsValueDisplay",
  ];

  static values = {
    screwLengthOptions: Object,
    requiresGroundScrewManufacturers: Boolean,
  };

  foundationChanged(_event) {
    const foundation = this.foundationTypeSelectTarget.value;
    this.conditionalContainerTargets.forEach((t) => {
      const foundationTypes = t.dataset.foundationTypes;
      if (foundationTypes.includes(foundation)) {
        animate.show(t);
      } else {
        animate.hide(t, { fadeOut: false });
      }
    });
  }

  updateScrewLengthOptions(_event) {
    const pipeDiameter = this.pipeDiameterFieldTarget.value;
    const currentScrewLengthValue = Number.parseInt(this.screwLengthSelectTarget.value);

    let options = [];
    if (this.requiresGroundScrewManufacturersValue) {
      const manufacturer = this.groundScrewManufacturerSelectTarget.value;
      if (manufacturer !== "") {
        options = this.screwLengthOptionsValue[manufacturer][Number.parseInt(pipeDiameter)];
      } else {
        options = [["* requires a manufacturer", ""]];
      }
    } else {
      options = this.screwLengthOptionsValue[pipeDiameter];
    }

    const fullySpecifiedOptions = options.map((option) => {
      const selected = option[1] === currentScrewLengthValue;
      return [option[0], option[1], false, selected];
    });

    addOptionsToSelect(fullySpecifiedOptions, this.screwLengthSelectTarget);
  }

  groundScrewManufacturerChanged(event) {
    const manufacturer = event.target.value;
    if (manufacturer === "") {
      this.hexHeadSetScrewsHiddenFieldTarget.value = "";
      this.hexHeadSetScrewsValueDisplayTarget.innerHTML = "";
    } else {
      const hexHeadCount = manufacturer === "american" ? 3 : 4;
      this.hexHeadSetScrewsHiddenFieldTarget.value = hexHeadCount;
      this.hexHeadSetScrewsValueDisplayTarget.innerHTML = hexHeadCount;
    }
    this.updateScrewLengthOptions(event);
  }
}
