import SimpleLatLng from "../../../../../da/map/models/simple-lat-lng";
import { cartesianPointRelativeTo } from "../../../../../da/map/ol-geometry";
import SegmentsRailGroup from "../../../map-model-synchronizers/segments-rail-group";
import { createNewSegment, renderNewSegment } from "../../segments/helpers";

export default class PanelsNudgerBase {
  constructor({ controller, selectionCollection, nudgeDirection, nudgeDistance }) {
    this.controller = controller;
    this.selectionCollection = selectionCollection;
    this.nudgeDirection = nudgeDirection;
    this.nudgeDistance = nudgeDistance;

    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.mapManager = controller.mapManager;
    this.roofPlane = controller.focusedRoofPlane;

    this.railGroups = SegmentsRailGroup.uniqueGroupsFromPanelFeatures({
      controller,
      features: selectionCollection.getArray(),
    });

    this.nudgeVector = this.getNudgeVector();
  }

  generateNewRailGroupsFromNudgeSplits() {
    const newRailGroups = this.railGroups.flatMap((railGroup) => {
      const simplifiedRailGroupGrids = this.simplifiedRailGroupGridsAfterNudgeSplit(railGroup);
      const newRailGroups = this.buildAndRenderNewRailGroupsFromSimplifiedRailGroups(
        railGroup,
        simplifiedRailGroupGrids,
      );
      railGroup.deleteSegments();
      return newRailGroups;
    });
    return newRailGroups;
  }

  buildAndRenderNewRailGroupsFromSimplifiedRailGroups(railGroup, simplifiedRailGroupGrids) {
    const newRailGroups = simplifiedRailGroupGrids.map((simplifiedRailGroupGrid) => {
      const {
        gridRows,
        consolidatedPanelStartLatLngRows,
        consolidatedZonesAndModulePositionsGrid,
        consolidatedContourCodesGrid,
      } = simplifiedRailGroupGrid;

      let consolidatedColumnIndexOffset = 0;
      const firstGridRow = gridRows[0];
      const firstConsolidatedLatLngsRow = consolidatedPanelStartLatLngRows[0];
      const rows = gridRows.length;

      let parent;
      const segments = firstGridRow.map((segmentRow, i) => {
        const columns = segmentRow.length;
        const startLatLng = firstConsolidatedLatLngsRow[i + consolidatedColumnIndexOffset];
        const zonesAndModulePositions = SegmentsRailGroup.stringifiedZonesAndModulePositionsFromConsolidatedGrid(
          consolidatedZonesAndModulePositionsGrid,
          columns,
          i + consolidatedColumnIndexOffset,
        );
        const contourCodes = SegmentsRailGroup.stringifiedContourCodesFromConsolidatedGrid(
          consolidatedContourCodesGrid,
          columns,
          i + consolidatedColumnIndexOffset,
        );
        const attrs = {
          startLatLng,
          rows,
          columns,
          zonesAndModulePositions,
          contourCodes,
          orientation: railGroup.panelOrientation,
        };
        if (i === 0) {
          attrs.railed = true;
        } else {
          attrs.railedParentUuid = parent.uuid;
          attrs.railed = false;
          attrs.distanceFromRailedParentStart = this.mapModelSynchronizer.getDistanceInInches(
            parent.startLatLng,
            new SimpleLatLng(attrs.startLatLng.lat, attrs.startLatLng.lng),
          );
        }
        const newSegment = createNewSegment(this.controller, railGroup.roofSection, attrs);

        if (newSegment.railed) {
          this.#createNewThermalExpansionsFromOldRailedParent(newSegment, railGroup.railedParentSegment);
        }

        if (i === 0) parent = newSegment;
        consolidatedColumnIndexOffset += segmentRow.length - 1;

        return newSegment;
      });
      segments.forEach((segment) => renderNewSegment(this.controller, segment));
      const newRailGroup = SegmentsRailGroup.fromSegmentUuid({ controller: this.controller, uuid: segments[0].uuid });
      newRailGroup.selectPanelsFromSimplifiedGrid(gridRows, this.selectionCollection);
      return newRailGroup;
    });

    return newRailGroups;
  }

  #createNewThermalExpansionsFromOldRailedParent(newRailedParentSegment, oldRailedParentSegment) {
    if (!oldRailedParentSegment.hasThermalExpansions) return;

    const originLonLat = oldRailedParentSegment.roofPlane.project.detail.originLatLng.toLonLat;

    const oldStartToNewStartLatLngVect = newRailedParentSegment.startLatLng.minus(oldRailedParentSegment.startLatLng);

    oldRailedParentSegment.thermalExpansions.forEach((oldThermalExpansion) => {
      const startLatLng = oldThermalExpansion.startLatLng.plus(oldStartToNewStartLatLngVect);
      const startCartesianPoint = cartesianPointRelativeTo(startLatLng.toLonLat, originLonLat);

      newRailedParentSegment.addThermalExpansion({ startLatLng, startCartesianPoint });
    });
  }
}
