import tippy from "tippy.js";
import { Controller } from "@hotwired/stimulus";

export default class Tooltip extends Controller {
  static values = {
    content: String,
    hideOnClick: { type: Boolean, default: false },
    showMessageIfDisabled: { type: Boolean, default: false },
    disabledMessage: { type: String, default: "disabled" },
  };

  connect() {
    if (this.contentValue === "") return;

    this.tippy = tippy(this.element, {
      content: this.content,
      theme: "ir",
      zIndex: 101001 /* one more than the IR dialog container */,
      animation: "shift-away-subtle",
      allowHTML: true,
      hideOnClick: this.hideOnClickValue,
      onShow: this.onShow,
    });
  }

  get content() {
    let result = `<div class="text-center">${this.contentValue}</div>`;
    const showDisabledMessage = this.showMessageIfDisabledValue;
    const elementIsDisabled = this.element.disabled || this.element.className.match(/--disabled/);

    if (showDisabledMessage && elementIsDisabled) {
      result += `<div class='ir-tooltip__disabled-msg'>(${this.disabledMessageValue})</div>`;
    }
    return result;
  }

  onShow = (tippy) => {
    tippy.setContent(this.content);
  };

  disconnect() {
    if (!this.tippy) return;

    this.tippy.disable();
  }
}
