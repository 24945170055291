import { Text, Icon, Fill, Stroke, Style } from "ol/style";
import { GeoJSON } from "ol/format";
import { featureCollection } from "@turf/helpers";
import buffer from "@turf/buffer";

import { pill } from "../../../da/map/styles/helpers";
import Polygon from "ol/geom/Polygon";

const WEIGHT_BORDER = 4;

const ANCHOR_BORDER_COLOR = "rgba(255, 230, 0, 1.0)";

const COLOR_SEGMENT_BORDER = "rgba(255, 255, 255, 0.1)";
const COLOR_SEGMENT_FILL = "rgba(255, 255, 255, 0.0)";

const SELECTED_REGULAR_SEGMENT_STROKE_COLOR = "rgba(255, 255, 255, 1.0)";
const SELECTED_REGULAR_SEGMENT_STROKE_WIDTH = 4;
const SELECTED_REGULAR_SEGMENT_FILL_COLOR = "rgba(255, 255, 255, 0.4)";

const REGULAR_SEGMENT_INNER_BORDER_COLOR = "rgba(0, 0, 0, 0.4)";
const ILLEGAL_SEGMENT_INNER_BORDER_COLOR = "rgba(0, 0, 0, 1.0)";
const SEGMENT_INNER_BORDER_WIDTH = 8;

const SELECTED_ILLEGAL_SEGMENT_STROKE_COLOR = "rgba(255, 255, 255, 1.0)";
const SELECTED_ILLEGAL_SEGMENT_STROKE_WIDTH = 4;
const SELECTED_ILLEGAL_SEGMENT_FILL_COLOR = "rgba(255, 153, 153, 0.5)";

const HIGHLIGHTED_SEGMENT_STROKE_COLOR = "rgba(225, 0, 165, 1.0)";
const HIGHLIGHTED_SEGMENT_STROKE_WIDTH = 4;
const HIGHLIGHTED_SEGMENT_FILL_COLOR = "rgba(225, 0, 165, 0.7)";

const ROWS_COLUMNS_MARKER_TEXT_COLOR = "#fff";

const RAIL_FILL_COLOR = "rgba(255, 255, 255, 0.6)";
const SELECTED_RAIL_FILL_COLOR = "rgba(255, 255, 255, 1)";

const STYLE_SEGMENT = new Style({
  stroke: new Stroke({ color: COLOR_SEGMENT_BORDER, width: WEIGHT_BORDER }),
  fill: new Fill({ color: COLOR_SEGMENT_FILL }),
});

export function rowsColumnsMarkerStyle(feature) {
  const width = 60;
  const height = 20;
  const style = new Style({
    image: new Icon({
      img: pill({ width, height, strokeWidth: 1 }),
      imgSize: [width, height],
    }),
    text: new Text({
      text: feature.get("text"),
      font: "bold 12px sans-serif",
      fill: new Fill({ color: ROWS_COLUMNS_MARKER_TEXT_COLOR }),
    }),
  });
  return style;
}

export const transparentOrRegularSegmentStyle = (feature) => {
  const segment = feature.get("model");
  if (segment.zonesAndModulePositionsPersisted) {
    return transparentSegmentsStyle(feature);
  } else {
    return segmentsStyle(feature);
  }
};

export const transparentSegmentsStyle = (feature) => {
  return segmentsStyle(feature);
};

export const segmentsStyle = (feature) => {
  const styles = [];
  if (feature.get("highlighted")) {
    styles.push(HIGHLIGHTED_SEGMENT_STYLE);
  } else {
    styles.push(STYLE_SEGMENT);
  }

  return styles;
};

function segmentInnerBorderStyle(feature, type) {
  let strokeColor;
  if (type === "regular") {
    strokeColor = REGULAR_SEGMENT_INNER_BORDER_COLOR;
  } else if (type === "illegal") {
    strokeColor = ILLEGAL_SEGMENT_INNER_BORDER_COLOR;
  }

  const bufferedFeature = bufferFeature(feature, 0.1);
  const coordinates = bufferedFeature.getGeometry().getCoordinates();
  return new Style({
    geometry: new Polygon(coordinates),
    stroke: new Stroke({
      color: strokeColor,
      width: SEGMENT_INNER_BORDER_WIDTH,
      lineJoin: "miter",
    }),
  });
}

function bufferFeature(feature, insetDistance) {
  const format = new GeoJSON();
  const projections = { dataProjection: "EPSG:4326", featureProjection: "EPSG:3857" };
  const turfFeature = format.writeFeatureObject(feature, projections);
  const turfFeatureAsCollection = featureCollection([turfFeature]);
  const inset = -1 * insetDistance;
  const bufferedTurfFeatures = buffer(turfFeatureAsCollection, inset, { units: "feet", steps: 2 });
  const bufferedOlFeatures = format.readFeatures(bufferedTurfFeatures, projections);
  return bufferedOlFeatures[0];
}

const SELECTED_REGULAR_SEGMENT_STYLE = new Style({
  stroke: new Stroke({ color: SELECTED_REGULAR_SEGMENT_STROKE_COLOR, width: SELECTED_REGULAR_SEGMENT_STROKE_WIDTH }),
  fill: new Fill({ color: SELECTED_REGULAR_SEGMENT_FILL_COLOR }),
  zIndex: 10,
});

const SELECTED_REGULAR_SEGMENT_ANCHOR_STYLE = new Style({
  stroke: new Stroke({ color: ANCHOR_BORDER_COLOR, width: SELECTED_REGULAR_SEGMENT_STROKE_WIDTH }),
  fill: new Fill({ color: SELECTED_REGULAR_SEGMENT_FILL_COLOR }),
  zIndex: 10,
});

const SELECTED_ILLEGAL_SEGMENT_STYLE = new Style({
  stroke: new Stroke({ color: SELECTED_ILLEGAL_SEGMENT_STROKE_COLOR, width: SELECTED_ILLEGAL_SEGMENT_STROKE_WIDTH }),
  fill: new Fill({ color: SELECTED_ILLEGAL_SEGMENT_FILL_COLOR }),
  zIndex: 10,
});

const SELECTED_ILLEGAL_SEGMENT_ANCHOR_STYLE = new Style({
  stroke: new Stroke({ color: ANCHOR_BORDER_COLOR, width: SELECTED_ILLEGAL_SEGMENT_STROKE_WIDTH }),
  fill: new Fill({ color: SELECTED_ILLEGAL_SEGMENT_FILL_COLOR }),
  zIndex: 10,
});

const HIGHLIGHTED_SEGMENT_STYLE = new Style({
  stroke: new Stroke({
    color: HIGHLIGHTED_SEGMENT_STROKE_COLOR,
    width: HIGHLIGHTED_SEGMENT_STROKE_WIDTH,
  }),
  fill: new Fill({ color: HIGHLIGHTED_SEGMENT_FILL_COLOR }),
  zIndex: 10,
});

export const selectedSegmentsStyle = (feature) => {
  const styles = [];
  if (feature.get("highlighted")) {
    styles.push(HIGHLIGHTED_SEGMENT_STYLE);
  } else if (feature.get("illegalShape")) {
    if (feature.get("anchorSelection")) {
      styles.push(SELECTED_ILLEGAL_SEGMENT_ANCHOR_STYLE);
    } else {
      styles.push(SELECTED_ILLEGAL_SEGMENT_STYLE);
    }
    styles.push(segmentInnerBorderStyle(feature, "illegal"));
  } else {
    if (feature.get("anchorSelection")) {
      styles.push(SELECTED_REGULAR_SEGMENT_ANCHOR_STYLE);
    } else {
      styles.push(SELECTED_REGULAR_SEGMENT_STYLE);
    }
    styles.push(segmentInnerBorderStyle(feature, "regular"));
  }

  return styles;
};

const RAIL_STYLE = new Style({
  fill: new Fill({ color: RAIL_FILL_COLOR }),
});

const SELECTED_RAIL_STYLE = new Style({
  fill: new Fill({ color: SELECTED_RAIL_FILL_COLOR }),
});

export function railsStyle(feature) {
  if (feature.get("selected")) {
    return SELECTED_RAIL_STYLE;
  } else {
    return RAIL_STYLE;
  }
}
