import React from "react";
import PropTypes from "prop-types";

function Line({ x, y, type, length }) {
  const styles = {
    left: `${x}px`,
    top: `${y}px`,
  };
  if (type === "vertical") {
    styles.height = `${length}px`;
  } else {
    styles.width = `${length}px`;
  }

  return (
    <div className={`dimension-crosshairs__line dimension-crosshairs__line--${type}`} style={styles} data-testid="line">
      <div className={`dimension-crosshairs__line--${type}__inner`}></div>
    </div>
  );
}

Line.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["vertical", "horizontal"]).isRequired,
  length: PropTypes.number.isRequired,
};

export default React.memo(Line);
