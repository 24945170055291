import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["priceDisplay"];

  initialize() {
    this.applyPriceChange = debounce(this.applyPriceChange, 300);
  }

  priceChange(event) {
    this.priceDisplayTargets.forEach((t) => (t.innerHTML = "Calculating..."));
    this.applyPriceChange(event);
  }

  async applyPriceChange(event) {
    let maxRedemptions = Number.parseInt(event.target.value);
    if (isNaN(maxRedemptions) || maxRedemptions < 1) maxRedemptions = "";

    const response = await get(`/bulk_permit_packs/price_calculator?max_redemptions=${maxRedemptions}`, {
      responseKind: "turbo-stream",
    });
  }
}
