export default class GridCompressedSpaceMarkers {
  static calculate(displayGrid) {
    return new this(displayGrid).calculate();
  }

  constructor(displayGrid) {
    this.displayGrid = displayGrid;
    this.realGrid = displayGrid.realGrid;
    this.innerSetbackOutline = displayGrid.setbackOutlines.inner;
    this.extensionsOutline = displayGrid.extensionsOutline;

    this.markers = [];
  }

  calculate() {
    this.addTopMarker();
    this.addRightMarker();
    this.addBottomMarker();
    this.addLeftMarker();
    return this.markers;
  }

  addTopMarker() {
    if (!this.realGrid.spaceAroundCellsTopIsCompressed) return;

    const eo = this.extensionsOutline;
    const iso = this.innerSetbackOutline;

    this.markers.push({
      key: "top",
      orientation: "horizontal",
      x: eo.x + Math.floor(eo.width / 2),
      y: iso.y + Math.floor((eo.y - iso.y) / 2),
    });
  }

  addRightMarker() {
    if (!this.realGrid.spaceAroundCellsRightIsCompressed) return;

    const eo = this.extensionsOutline;
    const iso = this.innerSetbackOutline;
    eo.right = eo.x + eo.width;
    iso.right = iso.x + iso.width;

    this.markers.push({
      key: "right",
      orientation: "vertical",
      x: eo.right + Math.floor((iso.right - eo.right) / 2),
      y: eo.y + Math.floor(eo.height / 2),
    });
  }

  addBottomMarker() {
    if (!this.realGrid.spaceAroundCellsBottomIsCompressed) return;

    const eo = this.extensionsOutline;
    const iso = this.innerSetbackOutline;
    eo.bottom = eo.y + eo.height;
    iso.bottom = iso.y + iso.height;

    this.markers.push({
      key: "bottom",
      orientation: "horizontal",
      x: eo.x + Math.floor(eo.width / 2),
      y: eo.bottom + Math.floor((iso.bottom - eo.bottom) / 2),
    });
  }

  addLeftMarker() {
    if (!this.realGrid.spaceAroundCellsLeftIsCompressed) return;

    const eo = this.extensionsOutline;
    const iso = this.innerSetbackOutline;

    this.markers.push({
      key: "left",
      orientation: "vertical",
      x: iso.x + Math.floor((eo.x - iso.x) / 2),
      y: eo.y + Math.floor(eo.height / 2),
    });
  }
}
