import ProjectModel from "../../../../pr/models/project-model";
import DaMapBaseController from "../../../da/layout_editor/map_base_controller";
import { applyDomUpdatesFromResponse } from "../../../../helpers/api-dom-helpers";
import * as toolbarBtn from "../../../../da/layout-editor/helpers/toolbar-btns";
import * as animate from "../../../../helpers/animate";
import { setCartesianPointsForProjectIfMissing } from "../../../../pr/map/modification-helpers/project";

export default class MapBaseController extends DaMapBaseController {
  static targets = [
    "map",
    "compass",
    "drawBtn",
    "selectBtn",
    "deleteBtn",
    "measureBtn",
    "saveBtn",
    "statusItemZoom",
    "statusItemRotation",
  ];

  connect() {
    super.connect();

    this.project = ProjectModel.create(this.projectData);
    setCartesianPointsForProjectIfMissing(this.project);

    window.IR_GLOBALS.projectModel = this.project;
  }

  get isDirty() {
    return this.dirty;
  }

  modelSave({ model, onSuccess = () => {}, includeProjectSite }) {
    toolbarBtn.showSpinner(this.saveBtnTarget);
    model.save({
      path: this.savePath,
      onSuccess: (json) => {
        if (json) applyDomUpdatesFromResponse(json);
        this.markClean();
        onSuccess();
        setTimeout(() => {
          toolbarBtn.hideSpinner(this.saveBtnTarget);
        }, 500);
      },
      onValidationError: this.onValidationError,
      onServerError: this.onServerError,
      includeProjectSite,
    });
  }

  // Called by both Stimulus event handler and saveAndThenRedirect triggered by auto save controller
  save(_event, onSuccess = () => {}) {
    this.modelSave({ model: this.project, onSuccess, includeProjectSite: true });
  }

  showEncroachingWarningMessage(shouldShow) {
    const messageContainer = document.querySelector("[data-identifier='arraysEncroachingMessageContainer']");
    if (!messageContainer) return;

    if (shouldShow) {
      animate.show(messageContainer);
    } else {
      animate.hide(messageContainer, { fadeOut: false });
    }
  }

  showNeedsThermalExpansionWarningMessage(shouldShow) {
    const messageContainer = document.querySelector("[data-identifier='arraysNeedThermalExpansionMessageContainer']");
    if (!messageContainer) return;

    if (shouldShow) {
      animate.show(messageContainer);
    } else {
      animate.hide(messageContainer, { fadeOut: false });
    }
  }
}
