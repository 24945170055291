import { Controller } from "@hotwired/stimulus";
import { track } from "../../helpers/app";

// Used on the documents and resources pages to download cut sheets
export default class extends Controller {
  static targets = ["selectList"];

  download(event) {
    event.preventDefault();

    const fileUrl = this.selectListTarget.value;

    if (fileUrl === "select") {
      alert("Please select a cut sheet to download.");
      return;
    }

    track({ action: "Document Download", category: this.category, label: fileUrl });
    setTimeout(() => {
      window.open(fileUrl, "_blank");
    }, 250);
  }

  get category() {
    return this.element.dataset.category;
  }
}
