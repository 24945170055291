import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = [
    "mountKitCount",
    "mountKitNa",
    "powerElectronicsAttachmentNa",
    "powerElectronicsAttachmentContainer",
    "powerElectronicsAttachment",
    "mlpeWarning",
  ];

  connect() {
    this.mountKitCompatibleElectronics = JSON.parse(this.data.get("mountKitCompatible"));
    this.powerElectronicsAttachmentCompatibleElectronics = JSON.parse(
      this.data.get("powerElectronicsAttachmentCompatible"),
    );
  }

  changedElectronics(event) {
    const powerElectronicsControl = event.target;
    const powerElectronics = powerElectronicsControl.value;

    const mountKitCompatible = this.mountKitCompatibleElectronics.indexOf(powerElectronics) > -1;
    const powerElectronicsAttachmentCompatible =
      this.powerElectronicsAttachmentCompatibleElectronics.indexOf(powerElectronics) > -1;

    this.toggleMountKits(mountKitCompatible);
    this.togglePowerElectronicsAttachment(powerElectronicsAttachmentCompatible);
  }

  toggleMountKits(compatible) {
    if (compatible) {
      if (this.currentlyHidden(this.mountKitCountTarget)) {
        animate.show(this.mountKitCountTarget);
        this.mountKitNaTarget.classList.add("d-none");
      }
    } else {
      this.mountKitCountTarget.value = "0";
      this.mountKitCountTarget.classList.add("d-none");
      animate.show(this.mountKitNaTarget);
    }
  }

  togglePowerElectronicsAttachment(compatible) {
    if (compatible) {
      if (this.currentlyHidden(this.powerElectronicsAttachmentContainerTarget)) {
        animate.show(this.powerElectronicsAttachmentContainerTarget);
        if (this.isUsingMlpe) {
          animate.show(this.mlpeWarningTarget);
        } else {
          animate.hide(this.mlpeWarningTarget);
        }
        this.powerElectronicsAttachmentNaTarget.classList.add("d-none");
      }
    } else {
      this.powerElectronicsAttachmentTarget.value = "none";
      this.powerElectronicsAttachmentContainerTarget.classList.add("d-none");
      this.mlpeWarningTarget.classList.add("d-none");
      animate.show(this.powerElectronicsAttachmentNaTarget);
    }
  }

  changedPowerElectronicsAttachment(_event) {
    if (this.isUsingMlpe) {
      animate.show(this.mlpeWarningTarget);
    } else {
      this.mlpeWarningTarget.classList.add("d-none");
    }
  }

  currentlyHidden(target) {
    return target.style.display === "none" || target.classList.contains("d-none");
  }

  get isUsingMlpe() {
    return this.powerElectronicsAttachmentTarget.value === "mlpe";
  }
}
