import { types, getParent } from "mobx-state-tree";

import WithLatLngPoints from "./with-lat-lng-points";
import WithDirtyTracking from "./with-dirty-tracking";

import { ROOF_PLANE_DATA_TYPE } from "../map/data-types";

import { logger } from "../../helpers/app";

const DaRoofPlaneModelBase = types
  .model("RoofPlaneModel", {
    uuid: types.identifier,
    id: types.maybe(types.integer),
    deleted: types.optional(types.boolean, false),
    identifier: types.optional(types.string, ""),
    display: types.optional(types.boolean, true),
    illegalShape: types.optional(types.boolean, false),
    setback: types.maybe(types.number),
    deletedAt: types.maybeNull(types.Date),
  })
  .views((self) => ({
    get baseNeedsSave() {
      const anyRoofSectionsNeedsSave = self.roofSections.some((rs) => rs.needsSave);
      return self.dirty || !self.id || anyRoofSectionsNeedsSave;
    },
    get defaultRoofSections() {
      return self.roofSections.filter((roofSection) => !roofSection.custom && !roofSection.deleted);
    },
    get notDeletedRoofSections() {
      return self.roofSections.filter((roofSection) => !roofSection.deleted);
    },
    get hasDefaultRoofSections() {
      return self.defaultRoofSections.length > 0;
    },
    get activeRoofSections() {
      return self.roofSections.filter((rs) => rs.active);
    },
    get hasActiveRoofSections() {
      return self.activeRoofSections.length > 0;
    },
    get project() {
      return getParent(self, 2);
    },
    getRoofSection(uuid) {
      return self.roofSections.find((roofSection) => roofSection.uuid === uuid);
    },
    get dataType() {
      return ROOF_PLANE_DATA_TYPE;
    },
    get displayIdentifier() {
      return self.identifier;
    },
    get displayable() {
      return !self.deleted && self.display;
    },
    get notDeleted() {
      return !self.deleted;
    },
    getNextSequentialRoofSectionIdentifier() {
      const numActiveRoofSections = self.roofSections.filter((rs) => rs.active).length;
      return numActiveRoofSections + 1;
    },
    get hasIllegalRoofSections() {
      return self.roofSections.some((rs) => rs.illegalShape);
    },
    get displayableRoofSections() {
      return self.roofSections.filter((rs) => rs.displayable);
    },
  }))
  .actions((self) => ({
    setIllegalShape(legality) {
      if (self.illegalShape === legality) return;

      self.markDirty();
      self.illegalShape = legality;
    },
    setIdentifier(newIdentifier) {
      if (self.identifier === newIdentifier) return;

      self.markDirty();
      self.identifier = newIdentifier;
    },
    destroyRoofSections() {
      self.roofSections.forEach((rs) => self.destroyRoofSection(rs));
    },
    destroyRoofSection(roofSection) {
      self.roofSections.remove(roofSection);
    },
    resequenceRoofSectionsIdentifiers() {
      const notDeletedRoofSections = self.roofSections
        .slice()
        .sort((rs) => rs.identifier)
        .filter((rs) => !rs.deleted && rs.active);
      notDeletedRoofSections.forEach((rs, i) => {
        rs.setIdentifier(i + 1);
      });
    },
    setDeletedAt() {
      self.deletedAt = new Date();
    },
  }));

const DaRoofPlaneModel = types.compose(DaRoofPlaneModelBase, WithLatLngPoints, WithDirtyTracking);
export default DaRoofPlaneModel;
