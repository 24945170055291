import React from "react";
import PropTypes from "prop-types";

import GridTools from "./GridTools";
import GridRowsAndColumns from "../GridRowsAndColumns";
import ToggleSwitchWithLabel from "../../ToggleSwitchWithLabel";
import { GRID_CELL_ACTIVE, GRID_CELL_INACTIVE } from "../helpers/grid-helper";
import InfoPopupLink from "../../InfoPopupLink";

function Toolbar({
  askToSaveWithCropOrNot,
  closeSaveOptionsPopup,
  columns,
  needsSaving,
  onChangeActiveToolbarItem,
  onClearGrid,
  onColumnsChange,
  onColumnsFieldValueChange,
  onCropGrid,
  onRowsChange,
  onRowsFieldValueChange,
  onSave,
  onShiftPanelsOnGrid,
  rows,
  saving,
  saveWithOption,
  selectedToolbarItem,
  showDimensionCrosshairs,
  onToggleDimensionCrosshairs,
  onToggleSnapDimensionCrosshairs,
  snapDimensionCrosshairs,
  expandable,
  onToggleExpand,
  isExpanded,
  gridArray,
  editorMode,
  contourPaintMode,
  handleContourPaintModeChange,
  isRailOrientationNs,
  railPlatform,
  hiddenEndClamp,
  editableGrid,
  arrayEditingAllowed,
  contourEditingAllowed,
  critterGuardDisplayAllowed,
  projectType,
  isOpenSolar,
}) {
  const gridHasLoaded = gridArray[0].length > 0;
  let hasAnySelectedCells = false;
  let moduleCount = 0;
  gridArray.forEach((row) => {
    row.forEach((column) => {
      moduleCount += column === GRID_CELL_ACTIVE ? 1 : 0;
      if (column === GRID_CELL_ACTIVE) hasAnySelectedCells = true;
    });
  });

  const transposedGrid = gridArray[0].map((_, i) => gridArray.map((row) => row[i]));
  const hasAllZeroColumn = transposedGrid.some((column) => column.every((element) => element === "0"));

  let isValidGrid = true;

  if ((projectType === "GB4" && hasAllZeroColumn) || !hasAnySelectedCells) {
    isValidGrid = false;
  }

  return (
    <div className="graphical-tool__toolbar" data-testid="toolbar">
      {isOpenSolar && !arrayEditingAllowed && contourEditingAllowed && (
        <div className="ir-alert__alerts-group mb-4">
          <div className="ir-alert ir-alert--warning">
            Note that if you add Contour to this array and then change the array in OpenSolar the Contour you've added
            will be removed and will need to be added again.
          </div>
        </div>
      )}
      <div className="graphical-tool__toolbar__row">
        {(arrayEditingAllowed || contourEditingAllowed || critterGuardDisplayAllowed) && (
          <>
            <GridTools
              selectedToolbarItem={selectedToolbarItem}
              onChangeActiveToolbarItem={onChangeActiveToolbarItem}
              onClearGrid={onClearGrid}
              saving={saving}
              saveWithOption={saveWithOption}
              onSave={onSave}
              onShiftPanelsOnGrid={onShiftPanelsOnGrid}
              needsSaving={needsSaving}
              askToSaveWithCropOrNot={askToSaveWithCropOrNot}
              closeSaveOptionsPopup={closeSaveOptionsPopup}
              editorMode={editorMode}
              contourPaintMode={contourPaintMode}
              handleContourPaintModeChange={handleContourPaintModeChange}
              isRailOrientationNs={isRailOrientationNs}
              railPlatform={railPlatform}
              hiddenEndClamp={hiddenEndClamp}
              editableGrid={editableGrid}
              isValidGrid={isValidGrid}
            />
            <GridRowsAndColumns
              columns={columns}
              rows={rows}
              onRowsChange={onRowsChange}
              onColumnsChange={onColumnsChange}
              onRowsFieldValueChange={onRowsFieldValueChange}
              onColumnsFieldValueChange={onColumnsFieldValueChange}
              onCropGrid={onCropGrid}
              editableGrid={editableGrid}
              expandable={expandable}
              onToggleExpand={onToggleExpand}
              isExpanded={isExpanded}
              projectType={projectType}
            />
          </>
        )}
      </div>
      <div
        className={`graphical-tool__toolbar__row ${
          arrayEditingAllowed ? "" : "graphical-tool__toolbar__row--centered"
        }`}
      >
        <div className="pe-3">
          <ToggleSwitchWithLabel
            on={showDimensionCrosshairs}
            onClick={onToggleDimensionCrosshairs}
            label="Dimension crosshairs"
            infoPopupIdentifier="graphical-editor-dimension-crosshairs"
          />
        </div>
        {showDimensionCrosshairs && (
          <ToggleSwitchWithLabel
            on={snapDimensionCrosshairs}
            onClick={onToggleSnapDimensionCrosshairs}
            label="Snap crosshairs"
          />
        )}
        {gridHasLoaded && !hasAnySelectedCells && (
          <div className="graphical-tool__toolbar__use-draw-tools-message">
            <i className="fas fa-arrow-up" />
            Use the grid tools to add modules to the grid
            <InfoPopupLink dialogIdentifier="use-grid-tools-howto-dialog-pr" />
          </div>
        )}
        {projectType === "GB4" && hasAnySelectedCells && hasAllZeroColumn && (
          <div className="graphical-tool__toolbar__use-draw-tools-message">
            <i className="fas fa-exclamation-triangle me-3" />
            You need to add at least one module to each column
          </div>
        )}
        <div className="graphical-tool__toolbar__module-count">Module count: {moduleCount}</div>
      </div>
    </div>
  );
}

Toolbar.propTypes = {
  askToSaveWithCropOrNot: PropTypes.bool.isRequired,
  closeSaveOptionsPopup: PropTypes.func.isRequired,
  columns: PropTypes.string.isRequired,
  needsSaving: PropTypes.bool.isRequired,
  onChangeActiveToolbarItem: PropTypes.func.isRequired,
  onClearGrid: PropTypes.func.isRequired,
  onColumnsChange: PropTypes.func.isRequired,
  onColumnsFieldValueChange: PropTypes.func.isRequired,
  onCropGrid: PropTypes.func.isRequired,
  onRowsChange: PropTypes.func.isRequired,
  onRowsFieldValueChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onShiftPanelsOnGrid: PropTypes.func.isRequired,
  onToggleDimensionCrosshairs: PropTypes.func.isRequired,
  onToggleSnapDimensionCrosshairs: PropTypes.func.isRequired,
  rows: PropTypes.string.isRequired,
  saveWithOption: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  selectedToolbarItem: PropTypes.oneOf(["toggle", "add", "remove"]),
  showDimensionCrosshairs: PropTypes.bool.isRequired,
  snapDimensionCrosshairs: PropTypes.bool.isRequired,
  expandable: PropTypes.bool,
  onToggleExpand: PropTypes.func,
  isExpanded: PropTypes.bool,
  gridArray: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOf([GRID_CELL_ACTIVE, GRID_CELL_INACTIVE]))),
  editableGrid: PropTypes.bool.isRequired,
  arrayEditingAllowed: PropTypes.bool,
  contourEditingAllowed: PropTypes.bool,
  railPlatform: PropTypes.string.isRequired,
  projectType: PropTypes.string.isRequired,
  isOpenSolar: PropTypes.bool,
};

Toolbar.defaultProps = {
  selectedToolbarItem: "add",
  arrayEditingAllowed: false,
  contourEditingAllowed: false,
};

export default React.memo(Toolbar);
