import { Controller } from "@hotwired/stimulus";

import * as animate from "../../../../helpers/animate";

export default class RafterOffsetFormController extends Controller {
  static values = {
    rafterSpacing: Number,
    mapIdentifier: String,
    label: String,
  };

  static targets = ["saveForUpdatedAttachmentPlanMessageContainer", "inputField"];

  connect() {
    this.element.controller = this;
  }

  setInputField(value) {
    this.inputFieldTarget.value = value;
  }

  updateOffsetValue(event) {
    const field = event.currentTarget;
    let value = Number.parseInt(field.value);
    if (isNaN(value)) value = 0;
    if (value > this.rafterSpacingValue) {
      value = this.rafterSpacingValue;
      alert(
        `The ${this.labelValue} offset can not be greater than ${this.rafterSpacingValue} inches (the roof plane's ${this.labelValue} spacing)`,
      );
    }
    field.value = value;

    const mapElement = document.querySelector(`[data-controller="${this.mapIdentifierValue}"]`);
    const mapController = mapElement[this.mapIdentifierValue];
    mapController.setRafterOffset(value);

    animate.show(this.saveForUpdatedAttachmentPlanMessageContainerTarget);
  }

  hideSaveForUpdatedAttachmentPlanMessage() {
    animate.hide(this.saveForUpdatedAttachmentPlanMessageContainerTarget);
  }

  preventSubmit(event) {
    event.preventDefault();
  }
}
