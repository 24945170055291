import { Controller } from "@hotwired/stimulus";
import { showDialog } from "../../components/ir_dialog/helper";
import * as animate from "../../../helpers/animate";

export default class extends Controller {
  static targets = ["lengthCheckbox", "dialogLengthCheckbox"];
  static outlets = ["da--custom-rail-lengths--dialog"];

  openDialog(event) {
    const clickPosition = { x: event.pageX, y: event.pageY };
    showDialog("custom-rail-lengths", () => {}, { showNearClickPosition: true, clickPosition });
    this.daCustomRailLengthsDialogOutlet.customLengthFieldTarget.focus();
  }

  updateCheckboxForLength(length, checked) {
    const checkBox = this.checkBoxForLength(length);
    checkBox.checked = checked;

    const changeEvent = new CustomEvent("change", { bubbles: true });
    checkBox.dispatchEvent(changeEvent);

    const checkBoxContainer = checkBox.closest("[data-identifier='checkBoxContainer']");
    animate.toggle(checkBoxContainer, checked);
  }

  checkBoxForLength(length) {
    return this.lengthCheckboxTargets.find((checkbox) => checkbox.dataset.length === length + "");
  }
}
