import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["signUp", "useExisting", "registrationFormContainer", "sessionFormContainer"];

  connect() {
    this.showForm();
  }

  showForm() {
    if (this.hasSignUpTarget) {
      if (this.signUpTarget.checked) {
        this.registrationFormContainerTarget.classList.remove("d-none");
        this.sessionFormContainerTarget.classList.add("d-none");
      } else {
        this.registrationFormContainerTarget.classList.add("d-none");
        this.sessionFormContainerTarget.classList.remove("d-none");
      }
    }
  }
}
