import Base from "../../../../da/map/interaction-managers/select/base";
import { modifyObstruction, deleteObstruction } from "../../modification-helpers/obstruction";
import { MEASURE_DATA_TYPE, OBSTRUCTION_DATA_TYPE } from "../../../../da/map/data-types";

export default class Obstructions extends Base {
  constructor(controller) {
    super(controller);

    this.modifyObstruction = modifyObstruction;
    this.deleteObstruction = deleteObstruction;
  }

  filterSelects = (_feature, layer) => {
    const layerDataType = layer && layer.get("dataType");
    const selectedBubbleAtVertex = layer === null;

    return selectedBubbleAtVertex || layerDataType === OBSTRUCTION_DATA_TYPE || layerDataType === MEASURE_DATA_TYPE;
  };

  removeSelectedVertices() {
    this.removeSelectedVerticesFromObstruction();
    this.removeSelectedVertexFromSelectInteractionFeatures();
  }

  removeSelectedVerticesFromObstruction() {
    this.selectionCollection.forEach((oFeature) => {
      const selectedVertexCoordinates = oFeature.get("selectedVertexCoordinates");

      const coordinates = this.getCoordinatesIfVertexDeletionAllowed(oFeature);
      if (!coordinates) return;

      const index = this.indexOfMatchingCoordinate(selectedVertexCoordinates, coordinates);
      if (index === -1) return;

      const newCoordinates = this.coordinatesWithoutVertexAtIndex(coordinates, index);
      this.updateFeatureToNewCoordinates(oFeature, newCoordinates);
      oFeature.unset("selectedVertexCoordinates");

      this.modifyObstruction(oFeature, this.mapModelSynchronizer, this.mapManager);
    });
  }

  removeSelectedShapes = () => {
    this.removeSelectedObstructions();
    this.baseRemoveSelectedShapes();
  };

  removeSelectedObstructions = () => {
    this.selectionCollection.forEach((feature) => {
      if (feature.get("dataType") === OBSTRUCTION_DATA_TYPE) {
        this.deleteObstruction(feature, this.project, this.mapModelSynchronizer, this.mapManager);
        this.controller.bxLayoutEditorObstructionHeightsFormOutlet.remove(
          feature.get("uuid"),
          this.project.notDeletedObstructions,
        );
      }
    });
  };

  selectClickResetInteractionManagers() {
    // The translate interaction has to be before the modify, or modify doesn't work
    this.controller.translateInteractionManager.add();
    this.controller.modifyInteractionManager.add();
  }
}
