import { types, getParent } from "mobx-state-tree";

import CartesianModel from "../../da/models/cartesian-model";

import WithLatLngPoints from "../../da/models/with-lat-lng-points";
import WithDirtyTracking from "../../da/models/with-dirty-tracking";

const ContourModelBase = types
  .model("ContourModel", {
    segmentUuid: types.identifier,
    side: types.string,
    cartesianPoints: types.array(CartesianModel),
  })
  .views((self) => ({
    get segment() {
      return getParent(self, 2);
    },
  }))
  .actions((self) => ({
    setCartesianPoints(cartesianPoints) {
      self.markDirty();
      self.cartesianPoints = cartesianPoints;
    },
    clearCartesianPoints() {
      self.markDirty();
      self.cartesianPoints = [];
    },
  }));

const ContourModel = types.compose(ContourModelBase, WithLatLngPoints, WithDirtyTracking);
export default ContourModel;
