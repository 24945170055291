import React, { useMemo } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

const ToolbarBtn = ({
  icon,
  text,
  selectedToolbarItem,
  onClick,
  showSpinner,
  iconOnly,
  preventDoubleClick,
  showTooltip,
  disabled,
  disabledTooltipMessage,
}) => {
  const cssName = text.toLowerCase().replace(/\s/, "-");
  const bemBase = "graphical-tool__toolbar__button";
  const classNames = [bemBase, `${bemBase}--${cssName}`];
  if (disabled) classNames.push(`${bemBase}--disabled`);
  if (text.toLowerCase() === selectedToolbarItem) classNames.push(`${bemBase}--active`);
  if (showSpinner) classNames.push(`${bemBase}--spinning`);
  if (iconOnly) classNames.push(`${bemBase}--icon-only`);

  const eventArgument = text.toLowerCase().replace(" ", "-");

  const debouncedOnBtnClick = useMemo(() => {
    return debounce(
      () => {
        onClick(eventArgument);
      },
      preventDoubleClick ? 500 : 0,
      { leading: true, trailing: false },
    );
  }, [preventDoubleClick, onClick, eventArgument]);

  const onBtnClick = () => {
    if (!disabled && !showSpinner) debouncedOnBtnClick();
  };

  return (
    <button
      className={classNames.join(" ")}
      onClick={onBtnClick}
      type="button"
      data-controller={showTooltip ? "components--ir-tooltip" : undefined}
      data-components--ir-tooltip-content-value={!iconOnly ? "" : text}
      data-components--ir-tooltip-show-message-if-disabled-value={disabled || undefined}
      data-components--ir-tooltip-disabled-message-value={disabledTooltipMessage}
    >
      {showSpinner && <i className="spinner" />}
      {!showSpinner && <i className={`fas fa-${icon}`} />}
      {!iconOnly && text}
    </button>
  );
};

ToolbarBtn.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string,
  selectedToolbarItem: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool,
  iconOnly: PropTypes.bool,
  preventDoubleClick: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledTooltipMessage: PropTypes.string,
  showTooltip: PropTypes.bool,
};

ToolbarBtn.defaultProps = {
  showSpinner: false,
  selectedToolbarItem: "NONE",
  iconOnly: false,
  text: "",
  preventDoubleClick: false,
  showTooltip: false,
  disabled: false,
  disabledTooltipMessage: "disabled",
};

export default React.memo(ToolbarBtn);
