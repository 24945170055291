import { Controller } from "@hotwired/stimulus";
import { elementInfo, pointOverlapsElement } from "../helpers/app";
import * as animate from "../helpers/animate";

export default class extends Controller {
  static targets = [
    "navLink",
    "homeMenu",
    "adminMenu",
    "projectsMenu",
    "ordersMenu",
    "resourcesMenu",
    "accountMenu",
    "menu",
  ];

  connect() {
    document.body.addEventListener("click", this.bodyClickHideMenus.bind(this));
    window.addEventListener("resize", this.bodyClickHideMenus.bind(this));
  }

  disconnect() {
    document.body.removeEventListener("click", this.bodyClickHideMenus.bind(this));
    window.removeEventListener("resize", this.bodyClickHideMenus.bind(this));
  }

  bodyClickHideMenus(event) {
    if (!this.currentMenu) return;

    for (let i = 0; i < this.menuTargets.length; i++) {
      const overlapping = pointOverlapsElement({ x: event.clientX, y: event.clientY, element: this.menuTargets[i] });
      if (overlapping) return;
    }

    for (let i = 0; i < this.navLinkTargets.length; i++) {
      const overlapping = pointOverlapsElement({ x: event.clientX, y: event.clientY, element: this.navLinkTargets[i] });
      if (overlapping) return;
    }

    this.hideMenus();

    this.currentMenu = undefined;
  }

  showMenu(event) {
    event.preventDefault();

    const menu = this[`${event.params.menu}Target`];
    const link = event.currentTarget;
    const linkInfo = elementInfo(link);

    if (this.currentMenu === event.params.menu) {
      link.classList.remove("header__mainnav__link--active");
      this.currentMenu = undefined;
      animate.hide(menu, { fadeOut: false });
      return;
    } else {
      this.currentMenu = event.params.menu;
    }

    this.hideMenus(link, menu);

    link.classList.add("header__mainnav__link--active");

    animate.show(menu, { duration: 150 });

    setTimeout(() => {
      const menuInfo = elementInfo(menu);
      menu.style.top = `${linkInfo.bottom + 2}px`;

      const isLeftAligned = menu.classList.contains("header__menu--left-aligned");
      const xPos = isLeftAligned ? linkInfo.left : linkInfo.right - menuInfo.width;
      menu.style.left = `${xPos}px`;

      menu.style.zIndex = 1000;
    }, 10);
  }

  hideMenus(openingLink, openingMenu) {
    this.navLinkTargets.forEach((link) => {
      if (openingLink && link === openingLink) return;

      link.classList.remove("header__mainnav__link--active");
    });

    this.menuTargets.forEach((menu) => {
      if (openingMenu && menu === openingMenu) return;
      if (menu.offsetParent === null) return;

      menu.style.zIndex = 999;
      animate.hide(menu, { fadeOut: false });
    });
  }
}
