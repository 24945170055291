import * as animate from "../../helpers/animate";
import { isVisible } from "../../helpers/app";

export default class MarqueeMarker {
  constructor() {
    this.#setMarker();

    this.showAnimating = false;
  }

  show() {
    if (isVisible(this.marker) || this.showAnimating) return;

    this.showAnimating = true;

    animate.show(this.marker, {
      completedCallback: this.afterShowAnimationComplete,
    });
  }

  hide() {
    if (this.showAnimating) {
      this.hideAfterShowAnimationComplete = true;
      return;
    }

    animate.hide(this.marker, { fadeOut: false });
  }

  updateContent(newContent) {
    this.marker.innerHTML = newContent;
  }

  position(top, left) {
    this.marker.style.top = `${top}px`;
    this.marker.style.left = `${left}px`;
  }

  addTo(container) {
    container.appendChild(this.marker);
  }

  afterShowAnimationComplete = () => {
    this.showAnimating = false;

    if (this.hideAfterShowAnimationComplete) {
      this.hideAfterShowAnimationComplete = false;
      this.hide();
    }
  };

  #setMarker() {
    this.marker = document.createElement("div");
    this.marker.classList.add("ol-map__marquee-marker");
    this.marker.classList.add("d-none");
    this.updateContent("-");
  }
}
