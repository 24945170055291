import { Controller } from "@hotwired/stimulus";

// When navigating to a project edit/configure page with an anchor ref in the URL,
// the location you end up on the page can be under the floating header.  This
// allows you to adjust the location by roughly 100 pixels so the anchor appears to
// take you to the correct place.
export default class extends Controller {
  connect() {
    if (!this.isLinkingToAnchor) return;

    setTimeout(() => {
      window.scrollBy(0, -100);
    }, 100);
  }

  get isLinkingToAnchor() {
    return window.location.href.includes(this.anchor);
  }

  get anchor() {
    return this.element.id;
  }
}
