import { types } from "mobx-state-tree";

const WithDirtyTracking = types
  .model({
    dirty: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    markDirty() {
      self.dirty = true;
    },
  }));

export default WithDirtyTracking;
