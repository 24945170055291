import React from "react";
import PropTypes from "prop-types";

const Column = ({ children, style }) => (
  <div className="col-auto graphical-tool__grid-rows-and-columns__form-group" style={style}>
    {children}
  </div>
);

Column.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

Column.defaultProps = {
  style: {},
};

export default React.memo(Column);
