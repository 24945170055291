import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scrollField"];

  static values = {
    max: { type: Number, default: 10000000000 },
    min: { type: Number, default: -10000000000 },
    unsigned: { type: Boolean, default: false },
  };

  connect() {
    const value = this.scrollFieldTarget.value;
    if (value !== "" && isNaN(Number.parseInt(value))) {
      this.scrollFieldTarget.value = 0;
    }
  }

  increase(event) {
    this.scrollBy(event, 1);
  }

  decrease(event) {
    this.scrollBy(event, -1);
  }

  fieldInput(event) {
    const value = event.currentTarget.value;
    if (value === "") return;

    const regex = this.unsignedValue ? /[^\d]/ : /[^\d-]/;
    let newValue = value.replace(regex, "");
    if (newValue === "") {
      this.scrollFieldTarget.value = "";
      return;
    }

    newValue = Number.parseInt(newValue);

    this.#setValue(newValue);
  }

  scrollBy(event, amount) {
    event.preventDefault();

    if (event.shiftKey) amount *= 10;

    const scrollField = this.scrollFieldTarget;
    const fieldVal = scrollField.value;
    let number = Number.parseInt(fieldVal);
    if (isNaN(number)) number = 0;

    const newValue = number + amount;

    this.#setValue(newValue);
  }

  #setValue(newValue) {
    let value = newValue;

    if (this.unsignedValue && value < 0) value = 0;
    if (value < this.minValue) value = this.minValue;
    if (value > this.maxValue) value = this.maxValue;

    const scrollField = this.scrollFieldTarget;
    scrollField.value = value;
    // We need to manually trigger the change event in order for other
    // Stimulus actions tied to the change event to get called
    const changeEvent = new CustomEvent("change", { bubbles: true });
    scrollField.dispatchEvent(changeEvent);
  }
}
