import React, { Component } from "react";
import PropTypes from "prop-types";

class Button extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    type: PropTypes.oneOf(["button", "submit"]),
    actionStyle: PropTypes.oneOf(["green", "blue", "gray"]),
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(["prepend", "append"]),
    disabled: PropTypes.bool,
    showSpinner: PropTypes.bool,
  };

  static defaultProps = {
    iconPosition: "prepend",
    actionStyle: "gray",
    type: "button",
    disabled: false,
    showSpinner: false,
  };

  onButtonClick = (event) => {
    if (this.props.showSpinner || this.props.disabled) {
      event.preventDefault();
    } else {
      this.props.onButtonClick(event);
    }
  };

  renderIconAndSpinnerDiv() {
    const { icon } = this.props;

    const spinnerDiv = (
      <div className="spinner">
        <div className="spinner-inner" />
      </div>
    );

    if (!icon) {
      return spinnerDiv;
    }

    return (
      <span className="ir-btn__icon">
        {spinnerDiv}
        <i className={`fa fa-${icon}`} data-testid="btn-icon" />
      </span>
    );
  }

  render() {
    const { text, type, icon, iconPosition, actionStyle, disabled, showSpinner, onButtonClick } = this.props;

    const classNames = ["ir-btn", `ir-btn--${actionStyle}`];
    if (icon) {
      classNames.push(`ir-btn--icon-${iconPosition}`);
    }
    if (showSpinner) {
      classNames.push("ir-btn--spinner-shown");
    }

    return (
      <button className={classNames.join(" ")} type={type} disabled={disabled} onClick={onButtonClick}>
        {(!icon || iconPosition === "prepend") && this.renderIconAndSpinnerDiv()}
        <span className="ir-btn__text">{text}</span>
        {icon && iconPosition === "append" && this.renderIconAndSpinnerDiv()}
      </button>
    );
  }
}

export default Button;
