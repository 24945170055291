import { DragBoxEvent } from "ol/interaction/DragBox";
import Point from "ol/geom/Point";
import { Feature } from "ol";
import throttle from "lodash/throttle";

import { distanceForPixels } from "../../../../da/map/ol-helpers";
import IrDragBox, { DRAG_BOX_CLASSNAME } from "../../../../da/map/ol-extensions/interaction/DragBox";
import { ROOF_SECTION_DATA_TYPE } from "../../../../da/map/data-types";
import { logger } from "../../../../helpers/app";
import SegmentBuilder from "../../modification-helpers/segments/builder";
import SegmentRenderer from "../../modification-helpers/segments/renderer";
import { clone } from "mobx-state-tree";

export default class DrawInteractionManager {
  constructor(controller) {
    this.controller = controller;

    this.mapManager = controller.mapManager;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.map = this.mapManager.map;

    this.roofPlane = this.controller.focusedRoofPlane;

    this.drawBtnTarget = controller.drawBtnTarget;
    this.throttledBoxDrag = throttle((e) => this.boxDrag(e), 50, { trailing: false });
    this.throttledBoxMove = throttle((e) => this.boxMove(e), 50, { trailing: false });
    this.selectionStartCoordinate = undefined;
  }

  add() {
    this.clearCurrentInteraction();

    this.currentDragBoxInteraction = new IrDragBox({ className: DRAG_BOX_CLASSNAME });
    this.map.addInteraction(this.currentDragBoxInteraction);
    this.toggleBoxEvents(true);
  }

  get isActive() {
    return this.currentDragBoxInteraction !== undefined;
  }

  toggleBoxEvents(set) {
    const setState = set ? "on" : "un";
    this.currentDragBoxInteraction[setState]("boxstart", this.boxStart);
    this.currentDragBoxInteraction[setState]("boxdrag", this.throttledBoxDrag);
    this.currentDragBoxInteraction[setState]("boxend", this.boxEnd);
    this.currentDragBoxInteraction[setState]("boxcancel", this.boxCancel);
    this.currentDragBoxInteraction[setState]("boxmove", this.throttledBoxMove);
  }

  remove() {
    this.clearCurrentInteraction();
  }

  clearCurrentInteraction() {
    if (!this.currentDragBoxInteraction) return;

    this.map.removeInteraction(this.currentDragBoxInteraction);
    this.toggleBoxEvents(false);
    this.currentDragBoxInteraction = undefined;
  }

  boxStart = (event) => {
    this.showDragBox(true);

    this.cancelledInteraction = false;

    this.selectionStartCoordinate = event.coordinate;
    this.selectionStartPixel = event.mapBrowserEvent.pixel;
    this.selectionEndPixel = undefined;
    this.selectionDirection = undefined;
  };

  boxCancel = (_event) => {
    this.clearRowsAndColumnsMarker();
    this.showDragBox(false);
    this.mapManager.silhouettePanelsVectorSource.clear();
    this.cancelledInteraction = true;
  };

  cancelDragInteraction() {
    this.currentDragBoxInteraction.dispatchEvent(new DragBoxEvent("boxcancel"));
  }

  boxMove = (event) => {
    // We're throttling the drag handler, and we want to make sure we wait until
    // the last drag event completes before we fire this off.  I found that 100
    // seemed to work better than 50 for this even though we're throttling at 50.
    setTimeout(() => {
      this.selectionStartPixel = event.mapBrowserEvent.startPixel;
      this.selectionEndPixel = event.mapBrowserEvent.endPixel;

      this.selectionStartCoordinate = this.map.getCoordinateFromPixel(this.selectionStartPixel);
      this.selectionEndCoordinate = this.map.getCoordinateFromPixel(this.selectionEndPixel);

      this.displaySilhouettePanelsAndRowColumnsMarker();
    }, 100);
  };

  boxDrag = (event) => {
    if (this.cancelledInteraction) return;

    this.selectionEndCoordinate = event.coordinate;
    this.selectionEndPixel = event.mapBrowserEvent.pixel;

    this.displaySilhouettePanelsAndRowColumnsMarker();
  };

  boxEnd = (_event) => {
    // Make sure the final box drag event has finished firing
    setTimeout(() => {
      if (this.cancelledInteraction) return;

      if (this.temporarySegment.isEmpty) {
        this.temporarySegment.roofSection.deleteSegment(this.temporarySegment);
        return;
      }

      this.mapManager.dispatchBeforeMapFeaturesRendering({ calledFrom: "DrawInteractionManager#boxEnd" });
      this.mapManager.silhouettePanelsVectorSource.clear();

      const roofSection = this.roofSectionForNewArray;

      // We arbitrarily assigned the segment to the roof plane's first roof section.  Now that
      // the drag is complete we have a better idea of what roof section this belongs to.
      if (roofSection !== this.temporarySegment.roofSection) {
        const segment = clone(this.temporarySegment);
        this.temporarySegment.roofSection.deleteSegment(this.temporarySegment);
        this.temporarySegment = segment;
        roofSection.addSegment(segment);
      }

      this.temporarySegment.setTemporary(false);

      const sr = new SegmentRenderer({
        roofPlane: this.roofPlane,
        segment: this.temporarySegment,
        controller: this.controller,
        panelsVectorSource: this.mapManager.panelsVectorSource,
        segmentsVectorSource: this.mapManager.segmentsVectorSource,
        railsVectorSource: this.mapManager.railsVectorSource,
      });
      sr.render();

      this.clearRowsAndColumnsMarker();

      this.mapManager.dispatchAfterMapFeaturesRendering({ calledFrom: "DrawInteractionManager#boxEnd" });

      this.controller.markDirty();
    }, 50);
  };

  showDragBox(shouldShow) {
    const dragBox = document.querySelector(`.${DRAG_BOX_CLASSNAME}`);
    if (!dragBox) return;

    dragBox.style.display = shouldShow ? "block" : "none";
  }

  displaySilhouettePanelsAndRowColumnsMarker() {
    this.clearRowsAndColumnsMarker();

    const { selectionStartCoordinate, selectionEndCoordinate, selectionStartPixel, selectionEndPixel } = this;
    const roofPlane = this.roofPlane;
    const boxCoordinates = this.currentDragBoxInteraction.getGeometry().getLinearRing(0).getCoordinates();
    const orientation = this.controller.panelOrientation;

    this.segmentBuilder = new SegmentBuilder({
      roofPlane,
      orientation,
      boxCoordinates,
      selectionStartCoordinate,
      selectionEndCoordinate,
      selectionStartPixel,
      selectionEndPixel,
    });
    this.temporarySegment = this.segmentBuilder.segment;

    this.mapManager.silhouettePanelsVectorSource.clear();
    const sr = new SegmentRenderer({
      roofPlane,
      segment: this.temporarySegment,
      controller: this.controller,
      panelsVectorSource: this.mapManager.silhouettePanelsVectorSource,
    });
    sr.render();

    this.addRowsColumnsMarker();
  }

  clearRowsAndColumnsMarker() {
    // As we drag out a grid of panels, we're creating a slew of temporary segments for each
    // render.  We only want to keep the final one we generate when the drag ends, so we flag
    // all built segments as temporary and flip the flag on the last one to false.  Then on
    // each render, we remove the existing temporary segments (and panels).
    this.roofPlane.deleteTemporarySegments();

    this.mapManager.rowsColumnsMarkerVectorSource.clear();
  }

  addRowsColumnsMarker() {
    const { selectionDirection, marqueeStart, rows, columns } = this.segmentBuilder;
    if (rows === 0 || columns === 0 || selectionDirection === undefined) return;

    const map = this.mapManager.map;
    const widthOffset = distanceForPixels(map, 30);
    const heightOffset = distanceForPixels(map, 18);

    const marqueeStartLon = marqueeStart[0];
    const marqueeStartLat = marqueeStart[1];

    let adjustedLon;
    let adjustedLat;
    if (selectionDirection === "NW") {
      adjustedLon = marqueeStartLon + widthOffset;
      adjustedLat = marqueeStartLat + heightOffset;
    } else if (selectionDirection === "NE") {
      adjustedLon = marqueeStartLon - widthOffset;
      adjustedLat = marqueeStartLat + heightOffset;
    } else if (selectionDirection === "SW") {
      adjustedLon = marqueeStartLon + widthOffset;
      adjustedLat = marqueeStartLat - heightOffset;
    } else if (selectionDirection === "SE") {
      adjustedLon = marqueeStartLon - widthOffset;
      adjustedLat = marqueeStartLat - heightOffset;
    }

    const rotationRads = this.roofPlane.eaveRotationAngleRadians;
    const posLon =
      Math.cos(rotationRads) * (adjustedLon - marqueeStartLon) -
      Math.sin(rotationRads) * (adjustedLat - marqueeStartLat) +
      marqueeStartLon;
    const posLat =
      Math.sin(rotationRads) * (adjustedLon - marqueeStartLon) +
      Math.cos(rotationRads) * (adjustedLat - marqueeStartLat) +
      marqueeStartLat;

    const geometry = new Point([posLon, posLat]);
    const feature = new Feature({ geometry });

    feature.set("text", `${rows} x ${columns}`);
    this.mapManager.rowsColumnsMarkerVectorSource.addFeature(feature);
  }

  get silhouetteFeatures() {
    return this.mapManager.silhouettePanelsVectorSource.getFeatures();
  }

  get roofSectionForNewArray() {
    // Get the roof section at the marquee start
    const boxCoordinates = this.currentDragBoxInteraction.getGeometry().getLinearRing(0).getCoordinates();
    const roofSectionAtMarqueeStart = this.roofSectionAtCoordinate(boxCoordinates[0]);
    if (roofSectionAtMarqueeStart) {
      logger("Found roof section at marquee start", roofSectionAtMarqueeStart.toJSON());
      return roofSectionAtMarqueeStart;
    }

    // Get the first roof section that's under a silhouette panel
    const silhouettePanelFeatures = this.mapManager.silhouettePanelsVectorSource.getFeatures();
    let roofSectionForPanels = undefined;
    for (let i = 0; i < silhouettePanelFeatures.length; i++) {
      const feature = silhouettePanelFeatures[i];
      const coordinate = feature.getGeometry().getLinearRing(0).getCoordinates()[0];
      roofSectionForPanels = this.roofSectionAtCoordinate(coordinate);
    }
    if (roofSectionForPanels) {
      logger("Found roof section at panel top left coordinate", roofSectionForPanels.toJSON());
      return roofSectionForPanels;
    }

    // If we didn't find a roof section at this point, just return the first one for the roof plane
    const roofSection = this.controller.focusedRoofPlane.roofSections[0];
    logger("Didn't find a roof section from marquee or panels, using first one", roofSection.toJSON());
    return roofSection;
  }

  roofSectionAtCoordinate(coordinate) {
    const pixelCoordinate = this.map.getPixelFromCoordinate(coordinate);
    const featuresAtPixel = this.map.getFeaturesAtPixel(pixelCoordinate);
    if (!featuresAtPixel) return undefined;

    const roofSectionFeature = featuresAtPixel.find((feature) => feature.get("dataType") === ROOF_SECTION_DATA_TYPE);
    if (!roofSectionFeature) return undefined;

    return this.mapModelSynchronizer.getRoofSectionForFeature(roofSectionFeature);
  }
}
