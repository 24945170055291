import { types } from "mobx-state-tree";

import LatLngModel from "../../da/models/lat-lng-model";
import WithDirtyTracking from "../../da/models/with-dirty-tracking";

const DetailModelBase = types
  .model("DetailModel", {
    id: types.maybe(types.integer),
    originLatLng: types.maybeNull(LatLngModel),
    roofShape: types.string,
    roofAttachment: types.string,
    staggeredAttachments: types.boolean,
    thirdRail: types.boolean,
  })
  .views((self) => ({
    get needsSave() {
      return self.dirty;
    },
    get isDeckAttached() {
      return self.roofAttachment === "fra" || self.roofAttachment === "qm_hug_d";
    },
  }))
  .actions((self) => ({
    setOriginLatLng(newOriginLatLng) {
      if (self.originLatLng !== newOriginLatLng) self.markDirty();
      self.originLatLng = newOriginLatLng;
    },
    setStaggeredAttachments(newStaggeredAttachments) {
      if (self.staggeredAttachments !== newStaggeredAttachments) self.markDirty();
      self.staggeredAttachments = newStaggeredAttachments;
    },
  }));

export function detailPersistenceData(detail) {
  return {
    id: detail.id,
    origin_lat_lng: detail.originLatLng?.toLatLngString || null,
    staggered_attachments: detail.staggeredAttachments,
  };
}

const DetailModel = types.compose(DetailModelBase, WithDirtyTracking);
export default DetailModel;
