import { types, getParent } from "mobx-state-tree";
import WithLatLngPoints from "../../da/models/with-lat-lng-points";
import WithDirtyTracking from "../../da/models/with-dirty-tracking";
import {
  ZONE_1,
  ZONES,
  MODULE_POSITION_N,
  MODULE_POSITIONS,
  ZONES_MODULE_POSITIONS,
} from "../helpers/zones-and-module-positions";
import {
  isContourEast,
  isContourSouth,
  isContourWest,
  contourCodeToDirections,
  contourDirectionsToCode,
  CONTOUR_CODE_NONE,
} from "../../helpers/contour";

export * from "../helpers/zones-and-module-positions";

const PanelModelBase = types
  .model("PanelModel", {
    uuid: types.identifier,
    row: types.integer,
    column: types.integer,
    zone: types.optional(types.string, ZONE_1),
    modulePosition: types.optional(types.string, MODULE_POSITION_N),
    contourCode: types.optional(types.string, "0"),
  })
  .views((self) => ({
    get segment() {
      return getParent(self, 2);
    },
    get startLatLng() {
      return self.latLngPoints[0];
    },
    get zoneLabel() {
      return `Z${self.zone}`;
    },
    get modulePositionLabel() {
      return `${self.modulePosition}`;
    },
    get zoneModulePosition() {
      return ZONES_MODULE_POSITIONS.findIndex((zonePos) => zonePos === `${self.zone}:${self.modulePosition}`);
    },
    get legalContourDirections() {
      const results = [];
      if (self.segment.rows === self.row) results.push("south");

      if (!self.segment.project.hiddenEndClamp) return results;

      if (self.segment.railed && self.column === 1) results.push("west");
      if (self.segment.isLastInRailGroup && self.segment.columns === self.column) results.push("east");
      return results;
    },
    get contourDirections() {
      return contourCodeToDirections(self.contourCode);
    },
    get isContourWest() {
      return isContourWest(self.contourCode);
    },
    get isContourEast() {
      return isContourEast(self.contourCode);
    },
    get isContourSouth() {
      return isContourSouth(self.contourCode);
    },
    get sideLatLngsTop() {
      return [this.latLngPoints[0], this.latLngPoints[1]];
    },
    get sideLatLngsRight() {
      return [this.latLngPoints[1], this.latLngPoints[2]];
    },
    get sideLatLngsBottom() {
      return [this.latLngPoints[3], this.latLngPoints[2]];
    },
    get sideLatLngsLeft() {
      return [this.latLngPoints[0], this.latLngPoints[3]];
    },
    get hasContour() {
      return self.contourCode !== CONTOUR_CODE_NONE;
    },
    get isLastColumn() {
      return self.column === self.segment.columns;
    },
    get isFirstColumn() {
      return self.column === 1;
    },
    get isFirstRow() {
      return self.row === 1;
    },
    get isLastRow() {
      return self.row === self.segment.rows;
    },
  }))
  .actions((self) => ({
    setZone(newZone) {
      const zone = newZone.replace("Z", "");
      if (!ZONES.includes(zone)) throw `Trying to set zone to an invalid value: ${zone}`;
      if (self.zone !== zone) self.markDirty();

      self.zone = zone;
    },
    setModulePosition(newModulePosition) {
      if (!MODULE_POSITIONS.includes(newModulePosition))
        throw `Trying to set position to an invalid value: ${newModulePosition}`;
      if (self.modulePosition !== newModulePosition) self.markDirty();

      self.modulePosition = newModulePosition;
    },
    setContourCodeFromDirections(directions) {
      const legalDirections = self.legalContourDirections;
      const directionsToApply = directions.filter((direction) => legalDirections.includes(direction));
      const contourCode = contourDirectionsToCode(directionsToApply);
      self.setContourCode(contourCode);
    },
    setContourCode(newContourCode) {
      if (self.contourCode !== newContourCode) self.markDirty();

      self.contourCode = newContourCode;
      self.segment.refreshContourCodes();
    },
  }));

const PanelModel = types.compose(PanelModelBase, WithLatLngPoints, WithDirtyTracking);
export default PanelModel;
