import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";

import throttle from "lodash/throttle";

import DaMapModelSynchronizer from "../../../da/map/map-model-synchronizer";
import { linkFeatureToModel } from "../modification-helpers/base";
import SegmentRenderer from "../modification-helpers/segments/renderer";
import { cartesianPointRelativeTo } from "../../../da/map/ol-geometry";
import RaftersBuilder from "../modification-helpers/rafters-builder";
import SegmentContourBuilder from "../modification-helpers/segments/contour/builder";

import { ADJOINMENT_DATA_TYPE, CONTOUR_DATA_TYPE } from "../../../da/map/data-types";
import SegmentsContourShortRunsRemover from "../modification-helpers/segments/contour/short-runs-remover";

export default class BaseMapModelSynchronizer extends DaMapModelSynchronizer {
  constructor(controller) {
    super(controller);

    this.mapManager = controller.mapManager;

    this.segmentFeatures = [];
    this.railFeatures = [];
    this.thermalExpansionFeatures = [];

    this.throttledReRenderContourFeatures = throttle(this.reRenderContourFeatures, 100, { leading: true });
  }

  createFeatureForModel(model) {
    const coordinates = model.latLngPoints.map((p) => fromLonLat(p.toLonLat));
    const geometry = new Polygon([coordinates]);
    const feature = new Feature({ geometry });
    linkFeatureToModel(feature, model);

    return feature;
  }

  featuresForAllPanels() {
    return this.controller.project.roofPlanes.flatMap((roofPlane) => {
      return this.featuresForRoofPlanePanels(roofPlane);
    });
  }

  clearSegmentAndRailFeatures() {
    this.segmentFeatures = [];
    this.railFeatures = [];
    this.thermalExpansionFeatures = [];
  }

  featuresForRoofPlanePanels(roofPlane) {
    const roofSections = roofPlane.roofSections;

    return roofSections.flatMap((roofSection) => {
      return roofSection.segments.flatMap((segment) => {
        const sr = new SegmentRenderer({ roofPlane, segment, controller: this.controller });
        sr.render();

        this.segmentFeatures.push(sr.segmentFeature);
        this.railFeatures = [...this.railFeatures, ...sr.railFeatures];
        this.thermalExpansionFeatures = [...this.thermalExpansionFeatures, ...sr.thermalExpansionFeatures];

        return sr.panelFeatures;
      });
    });
  }

  setSegmentStartLatLngAndCartesianPoint(segment, newStartLatLng) {
    this.#setStartLatLngAndCartesianPoint(segment, newStartLatLng);
  }

  setThermalExpansionStartLatLngAndCartesianPoint(thermalExpansion, newStartLatLng) {
    this.#setStartLatLngAndCartesianPoint(thermalExpansion, newStartLatLng);
  }

  rafterFeatures(roofPlane) {
    return new RaftersBuilder({ controller: this.controller, roofPlane }).build();
  }

  reRenderRafters() {
    const vectorSources = this.controller.mapManager.rafterVectorSources;
    Object.keys(vectorSources).forEach((roofPlaneIdentifier) => {
      const rafterVectorSource = vectorSources[roofPlaneIdentifier];
      const roofPlane = this.project.roofPlanes.find((rp) => rp.identifier === roofPlaneIdentifier);
      rafterVectorSource.clear();
      rafterVectorSource.addFeatures(this.rafterFeatures(roofPlane));
    });
  }

  reRenderThermalExpansions() {
    const vectorSource = this.controller.mapManager.thermalExpansionsVectorSource;
    vectorSource.clear();
    this.controller.pageRoofPlanes.forEach((roofPlane) => {
      roofPlane.roofSections.forEach((roofSection) => {
        roofSection.activeRailedParentSegments.forEach((railedParentSegment) => {
          railedParentSegment.thermalExpansions.forEach((thermalExpansion) => {
            const coordinatesLatLng = thermalExpansion.latLngPoints;
            const coordinates = coordinatesLatLng.map((latLng) => fromLonLat(latLng.toLonLat));
            const geometry = new Polygon([coordinates]);
            const feature = new Feature({ geometry });
            feature.set("model", thermalExpansion);
            feature.set("segmentUuid", railedParentSegment.uuid);
            vectorSource.addFeature(feature);
          });
        });
      });
    });
  }

  isThermalExpansionBetweenSegments(thermalExpansion, segment1, segment2) {
    const railedParent = segment1.railed ? segment1 : segment1.railedParent;

    const gapStart = segment1.distanceFromRailedParentStart + segment1.width - this.project.interColumnSpacing;
    const gapEnd = segment2.distanceFromRailedParentStart;

    const teStartDistance = this.getDistanceInInches(railedParent.startLatLng, thermalExpansion.startLatLng);
    return teStartDistance >= gapStart && teStartDistance <= gapEnd;
  }

  #setStartLatLngAndCartesianPoint(model, newStartLatLng) {
    model.setStartLatLng(newStartLatLng);
    const newStartCartesianPoint = cartesianPointRelativeTo(
      newStartLatLng.toLonLat || [newStartLatLng.lng, newStartLatLng.lat],
      this.project.detail.originLatLng.toLonLat,
    );
    model.setStartCartesianPoint(newStartCartesianPoint);
  }

  get adjoinmentsFeatures() {
    const features = [];
    this.controller.pageRoofPlanes.forEach((roofPlane) => {
      roofPlane.roofSections.forEach((roofSection) => {
        roofSection.segments.forEach((segment) => {
          segment.adjoinments.forEach((adjoinment) => {
            features.push(this.adjoinmentFeatureFromModel(segment, adjoinment));
          });
        });
      });
    });
    return features;
  }

  adjoinmentFeatureFromModel(segment, adjoinment) {
    const coordinates = adjoinment.latLngPoints.map((latLng) => fromLonLat(latLng.toLonLat));
    const geometry = new LineString(coordinates);
    const feature = new Feature({ geometry });
    feature.set("segmentUuid", segment.uuid);
    feature.set("dataType", ADJOINMENT_DATA_TYPE);
    return feature;
  }

  get contourLegalityAdjoinmentsFeatures() {
    const features = [];
    this.controller.pageRoofPlanes.forEach((roofPlane) => {
      roofPlane.roofSections.forEach((roofSection) => {
        roofSection.segments.forEach((segment) => {
          segment.contourLegalityAdjoinments.forEach((adjoinment) => {
            features.push(this.adjoinmentFeatureFromModel(segment, adjoinment));
          });
        });
      });
    });
    return features;
  }

  getPanelFeatureFromPanel(panel) {
    return this.controller.mapManager.panelsVectorSource.getFeatures().find((pf) => pf.get("uuid") === panel.uuid);
  }

  get contourFeatures() {
    const results = [];

    this.controller.pageRoofPlanes.forEach((roofPlane) => {
      roofPlane.roofSections.forEach((roofSection) => {
        roofSection.segments.forEach((segment) => {
          if (segment.deleted) return;

          segment.contours.forEach((contour) => {
            const coordinates = contour.latLngPoints.map((ll) => fromLonLat(ll.toLonLat));
            const geometry = new LineString(coordinates);
            const feature = new Feature({ geometry });
            feature.set("segmentUuid", contour.segmentUuid);
            feature.set("model", contour);
            feature.set("dataType", CONTOUR_DATA_TYPE);
            results.push(feature);
          });
        });
      });
    });

    return results;
  }

  reRenderContourFeatures() {
    const vectorSource = this.controller.mapManager.contoursVectorSource;

    vectorSource.clear();

    const allRunFeatures = this.#buildSegmentsContourRunFeatures();

    const shortRunsRemover = new SegmentsContourShortRunsRemover({ controller: this.controller, allRunFeatures });
    shortRunsRemover.remove();
    const legalRunFeatures = shortRunsRemover.legalFeatures;

    legalRunFeatures.forEach((feature) => {
      vectorSource.addFeature(feature);
    });
  }

  reRenderAdjoinmentsAndContourForPageRoofPlanes() {
    const contoursVectorSource = this.controller.mapManager.contoursVectorSource;
    contoursVectorSource.clear();
    this.contourFeatures.forEach((f) => contoursVectorSource.addFeature(f));

    const adjoinmentsVectorSource = this.controller.mapManager.adjoinmentsVectorSource;
    adjoinmentsVectorSource.clear();
    this.adjoinmentsFeatures.forEach((f) => adjoinmentsVectorSource.addFeature(f));

    const contourLegalityAdjoinmentsVectorSource = this.controller.mapManager.contourLegalityAdjoinmentsVectorSource;
    contourLegalityAdjoinmentsVectorSource.clear();
    this.contourLegalityAdjoinmentsFeatures.forEach((f) => contourLegalityAdjoinmentsVectorSource.addFeature(f));
  }

  #buildSegmentsContourRunFeatures() {
    const result = [];

    this.controller.pageRoofPlanes.forEach((roofPlane) => {
      roofPlane.roofSections.forEach((roofSection) => {
        roofSection.segments.forEach((segment) => {
          if (segment.deleted) return;

          const segmentContourBuilder = new SegmentContourBuilder({ controller: this.controller, segment });
          segmentContourBuilder.build();

          segmentContourBuilder.features.forEach((feature) => {
            result.push(feature);
          });
        });
      });
    });

    return result;
  }
}
