export function activate(...buttons) {
  buttons.forEach((button) => {
    button.classList.add("ol-map__btn--active");
  });
}

export function deactivate(...buttons) {
  buttons.forEach((button) => {
    button.classList.remove("ol-map__btn--active");
  });
}

export function enable(...buttons) {
  buttons.forEach((button) => {
    button.classList.remove("ol-map__btn--disabled");
  });
}

export function disable(...buttons) {
  buttons.forEach((button) => {
    button.classList.add("ol-map__btn--disabled");
  });
}

export function showSpinner(button) {
  button.classList.add("ol-map__btn--spinner-shown");
}

export function hideSpinner(button) {
  button.classList.remove("ol-map__btn--spinner-shown");
}

export function setIcon(button, icon) {
  button.querySelector(".fas").className = `fas fa-${icon}`;
}

export function setSpanContent(button, newContent) {
  button.querySelector("span").innerHTML = newContent;
}

export function isToolbarBtn(button) {
  if (!button) return false;
  return button.classList.contains("ol-map__btn");
}

export function isDisabled(button) {
  if (!isToolbarBtn(button)) return false;

  return button.classList.contains("ol-map__btn--disabled");
}

export function isActive(button) {
  if (!isToolbarBtn(button)) return false;

  return button.classList.contains("ol-map__btn--active");
}

export function showEnabledAndHideDisabledPinnedFlyoutMenus() {
  const flyoutMenuContainers = document.querySelectorAll(".ol-map__btn__flyout__container");
  Array.from(flyoutMenuContainers).forEach((el) => {
    if (!el.flyoutMenuController) return;

    el.flyoutMenuController.showEnabledAndHideDisabledPinnedMenu();
  });
}
