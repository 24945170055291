import { Collection } from "ol";
import { toLonLat } from "ol/proj";
import { getDistance } from "ol/sphere";

import { degreesToRadians } from "../../../../helpers/geometry";
import SegmentsRailGroup from "../../map-model-synchronizers/segments-rail-group";
import SegmentsNudger from "./nudger";

import { METERS_TO_INCHES } from "../../../../da/map/ol-geometry";
import {
  ADJOINMENT_HORIZONTAL_THRESHOLD,
  CONTOUR_LEGALITY_ADJOINMENT_HORIZONTAL_THRESHOLD,
  CONTOUR_LEGALITY_ADJOINMENT_VERTICAL_THRESHOLD,
} from "../../../models/adjoinment-model";

export const ADJOINMENT_TYPE_REGULAR = "regular";
export const ADJOINMENT_TYPE_CONTOUR_LEGALITY = "contour legality";

export default class SegmentsAdjoiningAligner {
  constructor({ controller, selectionCollection, direction, adjoinmentType = ADJOINMENT_TYPE_REGULAR }) {
    this.controller = controller;
    this.project = controller.project;
    this.selectionCollection = selectionCollection;

    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;

    if (adjoinmentType === ADJOINMENT_TYPE_REGULAR) {
      this.horizontalDistance = ADJOINMENT_HORIZONTAL_THRESHOLD;
      this.verticalDistance = this.project.interRowSpacing;
    } else {
      this.horizontalDistance = CONTOUR_LEGALITY_ADJOINMENT_HORIZONTAL_THRESHOLD;
      this.verticalDistance = CONTOUR_LEGALITY_ADJOINMENT_VERTICAL_THRESHOLD;
    }

    const [anchorSegmentFeature, moverSegmentFeature] = selectionCollection.getArray();

    this.anchorSegmentFeature = anchorSegmentFeature;
    this.moverSegmentFeature = moverSegmentFeature;

    this.segmentPixels = {
      [anchorSegmentFeature.get("uuid")]: this.#segmentFeatureCoordinates(anchorSegmentFeature).map((coordinate) =>
        this.map.getPixelFromCoordinate(coordinate),
      ),
      [moverSegmentFeature.get("uuid")]: this.#segmentFeatureCoordinates(moverSegmentFeature).map((coordinate) =>
        this.map.getPixelFromCoordinate(coordinate),
      ),
    };

    this.direction = direction;
    this.isHorizontal = direction === "horizontal";

    this.#setNudgeDirectionAndPositionSegments();
    this.#setNudgeDistance();

    this.moverRailGroup = SegmentsRailGroup.fromSegmentUuid({ controller, uuid: moverSegmentFeature.get("uuid") });
    this.moverAndAnchorInSameRailGroup = this.moverRailGroup.segmentUuids.includes(anchorSegmentFeature.get("uuid"));
  }

  areSegmentsAlignable() {
    if (this.moverAndAnchorInSameRailGroup) {
      this.notAlignableMessage = "You can not adjoin align segments in the same rail group.";
      return false;
    }

    if (this.segmentsAreOverlapping) {
      this.notAlignableMessage =
        "The segments must have some space between them in the alignment direction in order be able to carry out an adjoining alignment";
      return false;
    }

    return true;
  }

  align() {
    const nudgeSegmentsCollection = this.#moverSegmentsCollection;
    const nudgeSegmentsArray = nudgeSegmentsCollection.getArray();
    const nudger = new SegmentsNudger({
      controller: this.controller,
      selectionCollection: nudgeSegmentsCollection,
      nudgeDirection: this.nudgeDirection,
      nudgeDistance: this.nudgeDistance,
      slopeAdjusted: false,
    });
    nudger.nudge();

    this.controller.selectInteractionManager.removeFromSelection(this.moverSegmentFeature);

    this.#reselectMoverNewMoverSegmentAfterNudge(nudgeSegmentsArray);
  }

  get #moverSegmentsCollection() {
    const railGroup = this.moverRailGroup;

    let nudgeSegments;
    if (this.isHorizontal) {
      if (this.nudgeDirection === "left") {
        nudgeSegments = railGroup.segmentFeaturesUpToAndIncluding(railGroup.maxSelectedSegmentFeatureIndex);
      } else {
        nudgeSegments = railGroup.segmentFeaturesAfterAndIncluding(railGroup.minSelectedSegmentFeatureIndex);
      }
    } else {
      nudgeSegments = [this.moverSegmentFeature];
    }
    nudgeSegments.forEach((sf) => this.controller.selectInteractionManager.addToSelection(sf));

    const collection = new Collection(nudgeSegments);
    return collection;
  }

  #reselectMoverNewMoverSegmentAfterNudge(nudgeSegments) {
    const findByUuid = (uuid) => {
      return this.mapManager.segmentsVectorSource.getFeatures().find((sf) => sf.get("uuid") === uuid);
    };

    nudgeSegments.forEach((sf) => {
      const newSegmentFeatureAfterNudge = findByUuid(sf.get("uuid"));
      this.controller.selectInteractionManager.addToSelection(newSegmentFeatureAfterNudge);
    });
  }

  #setNudgeDirectionAndPositionSegments() {
    const anchorSegmentPixels = this.segmentPixels[this.anchorSegmentFeature.get("uuid")];
    const moverSegmentPixels = this.segmentPixels[this.moverSegmentFeature.get("uuid")];

    const anchorTopLeftPixel = anchorSegmentPixels[0];
    const moverTopLeftPixel = moverSegmentPixels[0];

    if (this.isHorizontal) {
      if (anchorTopLeftPixel[0] < moverTopLeftPixel[0]) {
        this.nudgeDirection = "left";
        this.leftSegmentFeature = this.anchorSegmentFeature;
        this.rightSegmentFeature = this.moverSegmentFeature;
        this.segmentsAreOverlapping = anchorSegmentPixels[1][0] >= moverSegmentPixels[0][0];
      } else {
        this.nudgeDirection = "right";
        this.leftSegmentFeature = this.moverSegmentFeature;
        this.rightSegmentFeature = this.anchorSegmentFeature;
        this.segmentsAreOverlapping = moverSegmentPixels[1][0] >= anchorSegmentPixels[0][0];
      }
    } else {
      if (anchorTopLeftPixel[1] < moverTopLeftPixel[1]) {
        this.nudgeDirection = "up";
        this.topSegmentFeature = this.anchorSegmentFeature;
        this.bottomSegmentFeature = this.moverSegmentFeature;
        this.segmentsAreOverlapping = anchorSegmentPixels[3][1] >= moverSegmentPixels[0][1];
      } else {
        this.nudgeDirection = "down";
        this.topSegmentFeature = this.moverSegmentFeature;
        this.bottomSegmentFeature = this.anchorSegmentFeature;
        this.segmentsAreOverlapping = moverSegmentPixels[3][1] >= anchorSegmentPixels[0][1];
      }
    }
  }

  #setNudgeDistance() {
    if (this.isHorizontal) {
      const leftSegmentLonLats = this.#segmentFeatureCoordinates(this.leftSegmentFeature).map((coordinate) =>
        toLonLat(coordinate),
      );
      const leftSegmentPixels = this.segmentPixels[this.leftSegmentFeature.get("uuid")];
      const rightSegmentPixels = this.segmentPixels[this.rightSegmentFeature.get("uuid")];

      const lonLatOne = leftSegmentLonLats[1]; // top right
      const pixelTwo = [rightSegmentPixels[0][0], leftSegmentPixels[1][1]];
      const lonLatTwo = toLonLat(this.map.getCoordinateFromPixel(pixelTwo));
      this.nudgeDistance = this.#distanceBetween(lonLatOne, lonLatTwo) - this.horizontalDistance;
    } else {
      const topSegmentLonLats = this.#segmentFeatureCoordinates(this.topSegmentFeature).map((coordinate) =>
        toLonLat(coordinate),
      );
      const topSegmentPixels = this.segmentPixels[this.topSegmentFeature.get("uuid")];
      const bottomSegmentPixels = this.segmentPixels[this.bottomSegmentFeature.get("uuid")];
      const lonLatOne = topSegmentLonLats[3]; // bottom left
      const pixelTwo = [topSegmentPixels[3][0], bottomSegmentPixels[0][1]];
      const lonLatTwo = toLonLat(this.map.getCoordinateFromPixel(pixelTwo));

      const roofSlopeRadians = degreesToRadians(this.controller.focusedRoofPlane.roofSlope);
      const slopeAdjustedInterRowSpacing = this.verticalDistance * Math.cos(roofSlopeRadians);
      this.nudgeDistance = this.#distanceBetween(lonLatOne, lonLatTwo) - slopeAdjustedInterRowSpacing;
    }
  }

  #segmentFeatureCoordinates(segmentFeature) {
    return segmentFeature.getGeometry().getLinearRing(0).getCoordinates();
  }

  #distanceBetween(lonLatOne, lonLatTwo) {
    return getDistance(lonLatOne, lonLatTwo) * METERS_TO_INCHES;
  }
}
