import React from "react";
import PropTypes from "prop-types";

export const TAB_IR = "ironridge-supported";
export const TAB_CP = "custom-panels";

function TabNav({ activeTab, onTabClick }) {
  return (
    <div className="ir-content-box__tab-nav__bar">
      <ul className="ir-content-box__tab-nav">
        <Tab name="IronRidge Supported" value={TAB_IR} activeTab={activeTab} onTabClick={onTabClick} />
        <Tab name="Custom Panels" value={TAB_CP} activeTab={activeTab} onTabClick={onTabClick} />
      </ul>
    </div>
  );
}

function Tab({ name, value, activeTab, onTabClick }) {
  const classes = ["ir-content-box__tab-nav__item"];
  if (activeTab === value) classes.push("ir-content-box__tab-nav__item--active");

  return (
    <li>
      <button type="button" className={classes.join(" ")} onClick={() => onTabClick(value)}>
        {name}
      </button>
    </li>
  );
}

TabNav.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

export default React.memo(TabNav);
