import { types, getParent } from "mobx-state-tree";

import CartesianModel from "../../da/models/cartesian-model";

import WithLatLngPoints from "../../da/models/with-lat-lng-points";
import WithDirtyTracking from "../../da/models/with-dirty-tracking";

export const ADJOINMENT_HORIZONTAL_THRESHOLD = 2; // inches
export const ADJOINMENT_VERTICAL_THRESHOLD = 2; // inches

export const CONTOUR_LEGALITY_ADJOINMENT_HORIZONTAL_THRESHOLD = 12; // inches
export const CONTOUR_LEGALITY_ADJOINMENT_VERTICAL_THRESHOLD = 6; // inches

const AdjoinmentModelBase = types
  .model("AdjoinmentModel", {
    segmentUuid: types.identifier,
    adjoiningSegmentUuid: types.string,
    adjoinmentSide: types.string,
    cartesianPoints: types.array(CartesianModel),
  })
  .views((self) => ({
    get segment() {
      return getParent(self, 2);
    },
  }))
  .actions((self) => ({
    setCartesianPoints(cartesianPoints) {
      self.markDirty();
      self.cartesianPoints = cartesianPoints;
    },
    clearCartesianPoints() {
      self.markDirty();
      self.cartesianPoints = [];
    },
  }));

const AdjoinmentModel = types.compose(AdjoinmentModelBase, WithLatLngPoints, WithDirtyTracking);
export default AdjoinmentModel;
