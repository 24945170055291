import React from "react";
import PropTypes from "prop-types";

function ToggleSwitch({ on, onClick }) {
  const handleChange = (event) => onClick(event.target.checked);

  const classNames = ["toggle-field", `toggle-field--${on ? "on" : "off"}`];

  return (
    <label className={classNames.join(" ")} data-testid="toggleSwitch">
      <input className="toggle-field__input" type="checkbox" checked={on} onChange={handleChange} />
      <span className="toggle-field__slider" />
    </label>
  );
}

ToggleSwitch.propTypes = {
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ToggleSwitch;
