import { Controller } from "@hotwired/stimulus";
import React from "react";
import ReactDOM from "react-dom";

export default class extends Controller {
  static targets = ["searchField", "searchResultsContainer", "selectionsContainer"];

  static values = {
    allOptions: Array,
    fieldName: String,
    selections: Array,
  };

  connect() {
    this.#renderResults();
  }

  onSelect(e) {
    const selection = e.target;
    const value = selection.dataset.value;
    const label = selection.textContent;

    if (!this.selectionsValue.includes(value)) {
      selection.classList.add("bg-anakiwa");

      this.selectionsValue = [...this.selectionsValue, value];

      this.#renderResults();

      // The search field blur should get called first
      setTimeout(() => {
        this.searchFieldTarget.value = "";
        this.searchFieldTarget.focus();
      }, 450);
    }
  }

  onSearchFieldBlur(event) {
    // Need to allow for a slight delay so that if the user is clicking one of the
    // options, the menu is still visible when the event fires.  Otherwise, Stimulus
    // appears to ignore the click event.
    setTimeout(() => {
      this.searchResultsContainerTarget.classList.add("d-none");
    }, 300);
  }

  onSearchFieldKeypress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  onRemoveClick(event) {
    event.preventDefault();

    const link = event.target;
    const hiddenField = link.closest("div.ir-multi-select__result").querySelector("input");
    const value = hiddenField.value;

    this.selectionsValue = this.selectionsValue.filter((sv) => `${sv}` !== value);

    this.#renderResults();
  }

  search(e) {
    const searchFieldValue = e.target.value.toLowerCase();

    if (searchFieldValue.length < 3) {
      this.searchResultsContainerTarget.classList.add("d-none");
      return;
    }
    this.searchResultsContainerTarget.classList.remove("d-none");

    // try not to block the keyboard since search can take a long time
    return Promise.resolve().then(() => {
      ReactDOM.unmountComponentAtNode(this.searchResultsContainerTarget);

      this.#addOptionTags();
    });
  }

  #addOptionTags() {
    ReactDOM.render(
      <div>
        {this.#filteredOptions.map((option) => {
          const [label, value] = option;
          return (
            <div
              key={value}
              className="ir-multi-select__option"
              data-value={value}
              data-action="click->components--ir-multi-select#onSelect"
            >
              {label}
            </div>
          );
        })}
      </div>,
      this.searchResultsContainerTarget,
    );
  }

  get #filteredOptions() {
    return this.allOptionsValue.filter((option) => {
      if (!this.selectionsValue.includes(`${option[1]}`)) {
        const searchFieldValue = this.searchFieldTarget.value.toLowerCase();
        return option[0].toLowerCase().includes(searchFieldValue);
      } else {
        return false;
      }
    });
  }

  #renderResults() {
    ReactDOM.unmountComponentAtNode(this.selectionsContainerTarget);

    ReactDOM.render(
      <div className="ir-multi-select__result__container">
        {this.selectionsValue.map((selection) => {
          const label = this.allOptionsValue.find((o) => `${o[1]}` === `${selection}`)[0];
          return (
            <div className="ir-multi-select__result d-flex" key={label}>
              <input type="hidden" name={this.fieldNameValue} value={selection} />
              <div>{label}</div>
              <div>
                <small>
                  <a href="#" data-action="components--ir-multi-select#onRemoveClick" className="text-red-600 ms-3">
                    <i className="fas fa-times pe-2"></i>
                    remove
                  </a>
                </small>
              </div>
            </div>
          );
        })}
      </div>,
      this.selectionsContainerTarget,
    );
  }
}
