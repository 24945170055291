import { Controller } from "@hotwired/stimulus";
import { isVisible } from "../../../helpers/app";
import * as animate from "../../../helpers/animate";
import * as store from "../../../helpers/local-store";

export default class MapLegendController extends Controller {
  static targets = ["list", "toggleIcon"];

  storeKey = "pr-map-legend-collapsed";

  connect() {
    if (store.has(this.storeKey)) {
      if (store.get(this.storeKey)) {
        this.hide();
      } else {
        this.show();
      }
    } else {
      this.show();
    }
  }

  toggle(event) {
    event.preventDefault();

    if (isVisible(this.listTarget)) {
      this.hide();
      store.set(this.storeKey, true);
    } else {
      store.set(this.storeKey, false);
      this.show();
    }
  }

  hide() {
    animate.hide(this.listTarget, { fadeOut: false });
    this.toggleIconTarget.classList.add("fa-circle-chevron-up");
    this.toggleIconTarget.classList.remove("fa-circle-chevron-down");
  }

  show() {
    animate.show(this.listTarget);
    this.toggleIconTarget.classList.add("fa-circle-chevron-down");
    this.toggleIconTarget.classList.remove("fa-circle-chevron-up");
  }
}
