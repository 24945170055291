import { Controller } from "@hotwired/stimulus";
import * as animate from "../../../helpers/animate";
import { elementInfo, getZIndex } from "../../../helpers/app";

export default class extends Controller {
  static targets = ["link", "menu"];

  static values = {
    activeLinkClass: String,
    hoverLinkClass: String,
    rightAlignedMenuClass: String,
    pinnedMenuClass: String,
  };

  connect() {
    document.addEventListener("click", this.hide.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.hide.bind(this));
  }

  show(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.#isMenuPinned || !this.hasMenuTarget) return;

    if (this.linkTarget.classList.contains(this.activeLinkClassValue)) {
      this.hide(event);
    } else {
      this.#updateMenuZIndex(1);
      animate.show(this.menuTarget, { duration: 250 });
      this.#setLinkStyleActive(true);
      this.#alignMenuRightIfOffscreen();
    }
  }

  hide(_event) {
    if (this.#isMenuPinned || !this.hasMenuTarget) return;

    this.#updateMenuZIndex(-1);

    animate.hide(this.menuTarget, { duration: 150 });
    this.#setLinkStyleActive(false);
  }

  #updateMenuZIndex(amount) {
    const currentZIndex = getZIndex(this.menuTarget);
    this.menuTarget.style.zIndex = currentZIndex + amount;
  }

  #setLinkStyleActive(active) {
    // use the hover style, but only if the link doesn't have the active style
    if (this.linkTarget.classList.contains(this.activeLinkClassValue)) return;

    const meth = active ? "add" : "remove";
    this.linkTarget.classList[meth](this.hoverLinkClassValue);
  }

  #alignMenuRightIfOffscreen() {
    this.menuTarget.classList.remove(this.rightAlignedMenuClassValue);

    const windowRight = window.innerWidth;
    const menuInfo = elementInfo(this.menuTarget);
    const { right: menuRight } = menuInfo;

    if (menuRight > windowRight) {
      this.menuTarget.classList.add(this.rightAlignedMenuClassValue);
    }
  }

  // Pinning is used for debugging and inspecting menus
  get #isMenuPinned() {
    return this.menuTarget.classList.contains(this.pinnedMenuClassValue);
  }
}
