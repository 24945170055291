export default class GridNumbers {
  rowNumberWidth = 25;

  columnNumberHeight = 25;

  static calculate(displayGrid) {
    return new this(displayGrid).calculate();
  }

  constructor(displayGrid) {
    this.displayGrid = displayGrid;
  }

  calculate() {
    return [this.rowNumbers, this.columnNumbers];
  }

  get rowNumbers() {
    const { cellHeight, cellPositions, extensionLeft, setbackWidth, spaceAroundCellsLeft } = this.displayGrid;

    const cellsFirstColumn = cellPositions.map((pp) => pp[0]);
    const rowNumbers = cellsFirstColumn.map((pfc, i) => {
      return {
        type: "row",
        number: i + 1,
        x: pfc.x - this.rowNumberWidth - setbackWidth - spaceAroundCellsLeft - extensionLeft,
        y: pfc.y,
        width: this.rowNumberWidth,
        height: cellHeight,
      };
    });
    return this.numbersLimitedToIncrements(rowNumbers);
  }

  numbersLimitedToIncrements(numbers) {
    const minDimension = 15;
    const smallSizeNumberIncrement = 5;
    const { height: numHeight, width: numWidth } = numbers[0];

    const numberLargerThanCutoff = numHeight >= minDimension && numWidth >= minDimension;
    if (numberLargerThanCutoff) return numbers;

    return numbers.filter((n) => n.number === 1 || n.number % smallSizeNumberIncrement === 0);
  }

  get columnNumbers() {
    const { cellPositions, cellWidth, extensionTop, setbackHeight, spaceAroundCellsTop } = this.displayGrid;

    const cellsFirstRow = cellPositions[0];
    const columnNumbers = cellsFirstRow.map((pfr, i) => {
      return {
        type: "column",
        number: i + 1,
        x: pfr.x,
        y: pfr.y - this.columnNumberHeight - setbackHeight - spaceAroundCellsTop - extensionTop,
        width: cellWidth,
        height: this.columnNumberHeight,
      };
    });
    return this.numbersLimitedToIncrements(columnNumbers);
  }
}
