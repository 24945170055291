import { polygonsAsPointListsIntersect } from "../../../da/map/legality-checkers/polygon-helpers";

export default class SetbackLegalityChecker {
  constructor(setbackFeature, mapModelSynchronizer) {
    this.setbackFeature = setbackFeature;
    const setbackCoordinates = setbackFeature.getGeometry().getCoordinates();

    this.roofPlaneCoordinates = setbackCoordinates[0];
    // The inner ring of of the setback is the outer boundary of the roofSection
    this.roofSectionsCoordinates = setbackCoordinates.slice(1, setbackCoordinates.length);

    this.mapModelSynchronizer = mapModelSynchronizer;
  }

  check() {
    this.clearIllegal();

    this.roofSectionsCoordinates.forEach((roofSectionCoordinates) => {
      if (this.roofSectionOutsideOfRoofPlane(roofSectionCoordinates)) this.markIllegal();
      if (this.roofSectionIntersectsItself(roofSectionCoordinates)) this.markIllegal();
      if (this.roofSectionIntersectsSibling(roofSectionCoordinates)) this.markIllegal();
    });

    this.saveIllegalityIntoModels();
  }

  clearIllegal() {
    this.setbackFeature.set("illegalShape", false);
  }

  roofSectionOutsideOfRoofPlane(roofSectionCoordinates) {
    return polygonsAsPointListsIntersect(roofSectionCoordinates, this.roofPlaneCoordinates);
  }

  roofSectionIntersectsItself(roofSectionCoordinates) {
    return polygonsAsPointListsIntersect(roofSectionCoordinates, roofSectionCoordinates);
  }

  roofSectionIntersectsSibling(roofSectionCoordinates) {
    for (let i = 0; i < this.roofSectionsCoordinates.length; i++) {
      const siblingCoordinates = this.roofSectionsCoordinates[i];

      const isSameRoofSection = JSON.stringify(roofSectionCoordinates) === JSON.stringify(siblingCoordinates);
      if (isSameRoofSection) continue;

      if (polygonsAsPointListsIntersect(roofSectionCoordinates, siblingCoordinates)) return true;
    }
    return false;
  }

  markIllegal() {
    this.setbackFeature.set("illegalShape", true);
  }

  saveIllegalityIntoModels() {
    if (!this.mapModelSynchronizer.setRoofPlaneRoofSectionsLegality) return;

    this.mapModelSynchronizer.setRoofPlaneRoofSectionsLegality(
      this.setbackFeature.get("roofPlaneUuid"),
      this.setbackFeature.get("illegalShape"),
    );
  }
}
