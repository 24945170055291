import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

// Standard behavior for showing and hiding an element
export default class extends Controller {
  static targets = ["content"];
  animating = false;

  connect() {
    const el = this.contentTarget;
    // if d-none Bootstrap class is applied to element initially to
    // hide it, remove it and set the element style display to none
    if (el.classList.contains("d-none")) {
      el.classList.remove("d-none");
      el.style.display = "none";
    }
  }

  toggle(event) {
    event.preventDefault();
    if (this.animating) {
      return;
    }
    this.animating = true;

    const link = event.currentTarget;
    const el = this.contentTarget;

    const displayProp = window.getComputedStyle(this.contentTarget).display;
    const contentHidden = displayProp === "none";

    this[contentHidden ? "showContent" : "hideContent"](el);
    this.setLinkIcon(link, contentHidden ? "up" : "down");
  }

  setLinkIcon(link, iconDir) {
    const i = link.querySelector("i");
    // in case the link isn't using a disclosure chevron
    if (i === null) return;

    if (iconDir == "down") {
      i.classList.replace("fa-angle-up", "fa-angle-down");
    } else {
      i.classList.replace("fa-angle-down", "fa-angle-up");
    }
  }

  showContent(el) {
    animate.show(el, {
      completedCallback: () => {
        this.animating = false;
      },
    });
  }

  hideContent(el) {
    animate.hide(el, {
      completedCallback: () => {
        this.animating = false;
      },
    });
  }
}
