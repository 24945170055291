import { degreesToRadians } from "../../../../../helpers/geometry";
import PanelsNudgerBase from "./base";

export default class PanelsNudgerVertical extends PanelsNudgerBase {
  getNudgeVector() {
    const { eavePerpendicularEdgeVectorLatLng } = this.roofPlane;
    const distance = this.nudgeDistance;
    const roofSlopeRadians = degreesToRadians(this.roofPlane.roofSlope);
    const slopeAdjustedDistance = distance * Math.cos(roofSlopeRadians);
    const directionMultiplier = this.nudgeDirection === "up" ? -1 : 1;
    return eavePerpendicularEdgeVectorLatLng.times(directionMultiplier * slopeAdjustedDistance);
  }

  nudge() {
    this.railGroups = this.generateNewRailGroupsFromNudgeSplits();
    this.#nudgeRailGroupsWithSelections();
  }

  simplifiedRailGroupGridsAfterNudgeSplit(railGroup) {
    const simplifiedGrid = railGroup.simplifiedGrid;
    const consolidatedPanelsGridStartLatLngs = railGroup.consolidatedPanelsGridStartLatLngs;
    const consolidatedZonesAndModulePositionsGrid = railGroup.consolidatedSegmentZonesAndModulePositionsGrid;
    const consolidatedContourCodesGrid = railGroup.consolidatedContourCodesGrid;

    const controlRowIndex = this.#controlRowIndex(railGroup);
    const newRailGroupSimplifiedGrids = [];
    let currentRailGroupGrid;

    simplifiedGrid.forEach((simplifiedGridRow, i) => {
      if (
        i === 0 ||
        (this.nudgeDirection === "up" && i === controlRowIndex + 1) ||
        (this.nudgeDirection === "down" && i === controlRowIndex)
      ) {
        if (currentRailGroupGrid) newRailGroupSimplifiedGrids.push(currentRailGroupGrid);
        currentRailGroupGrid = {
          gridRows: [simplifiedGridRow],
          consolidatedPanelStartLatLngRows: [consolidatedPanelsGridStartLatLngs[i]],
          consolidatedZonesAndModulePositionsGrid: [consolidatedZonesAndModulePositionsGrid[i]],
          consolidatedContourCodesGrid: [consolidatedContourCodesGrid[i]],
        };
      } else {
        currentRailGroupGrid.gridRows.push(simplifiedGridRow);
        currentRailGroupGrid.consolidatedPanelStartLatLngRows.push(consolidatedPanelsGridStartLatLngs[i]);
        currentRailGroupGrid.consolidatedZonesAndModulePositionsGrid.push(consolidatedZonesAndModulePositionsGrid[i]);
        currentRailGroupGrid.consolidatedContourCodesGrid.push(consolidatedContourCodesGrid[i]);
      }
    });
    newRailGroupSimplifiedGrids.push(currentRailGroupGrid);

    return newRailGroupSimplifiedGrids;
  }

  // Find the greatest or least row index with a selection which defines the row that
  // we will split the rail group on.
  #controlRowIndex(railGroup) {
    const consolidatedSimplifiedGrid = railGroup.consolidatedSimplifiedGrid;
    const rowIndexesWithSelections = [];
    consolidatedSimplifiedGrid.map((gridRow, i) => {
      if (gridRow.includes("X")) rowIndexesWithSelections.push(i);
    });
    const minMax = this.nudgeDirection === "up" ? "max" : "min";
    const result = Math[minMax](...rowIndexesWithSelections);
    return result;
  }

  #nudgeRailGroupsWithSelections() {
    this.railGroups.forEach((railGroup) => {
      if (!railGroup.hasAnyPanelSelections) return;

      const simplifiedGrid = railGroup.simplifiedGrid;

      railGroup.segments.forEach((segment) => {
        const nudgedStartLatLng = segment.startLatLng.plus(this.nudgeVector);
        this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(segment, nudgedStartLatLng);

        if (segment.railed && segment.hasThermalExpansions) {
          segment.thermalExpansions.forEach((thermalExpansion) => {
            const nudgedStartLatLng = thermalExpansion.startLatLng.plus(this.nudgeVector);
            this.mapModelSynchronizer.setThermalExpansionStartLatLngAndCartesianPoint(
              thermalExpansion,
              nudgedStartLatLng,
            );
          });
        }
      });

      railGroup.clearPanelSelections();
      railGroup.reRenderSegments();
      railGroup.selectPanelsFromSimplifiedGrid(simplifiedGrid, this.selectionCollection);
    });
  }
}
