import { Circle, Fill, Stroke, Style } from "ol/style";
import { scaleMap } from "../../../helpers/app";

const REGULAR_FILL = new Fill({ color: "#54C107" });
const ILLEGAL_FILL = new Fill({ color: "#ED0A0A" });

export function attachmentsStyle(feature, map) {
  const zoom = map.getView().getZoom();

  let radius;
  if (zoom < 20) {
    radius = 1;
  } else if (zoom > 25) {
    radius = 10;
  } else {
    radius = scaleMap(zoom, 20, 25, 1, 10);
  }

  const fill = feature.get("illegalShape") ? ILLEGAL_FILL : REGULAR_FILL;

  const image = new Circle({
    stroke: new Stroke({ color: "#fff", width: 2 }),
    fill,
    radius,
  });

  return new Style({ image });
}
