import { Controller } from "@hotwired/stimulus";

import * as animate from "../../helpers/animate";
import * as store from "../../helpers/local-store";

export default class extends Controller {
  static targets = [
    "toggleLink",
    "onIsVisible", // elements to make visible when the state is on
    "onIsHidden", // elements to make hidden when the state is on
  ];

  static values = {
    offLinkText: { type: String, default: "" }, // when the state is off (e.g. "show stuff")
    onLinkText: { type: String, default: "" }, // when the state is on (e.g. "hide stuff")
    rememberKey: { type: String, default: "" },
  };

  stateOff = "off";
  stateOn = "on";
  toggleState = this.stateOff; // initial state unless pulling from local storage

  connect() {
    if (this.rememberKeyValue === "") return;

    this.#toggleTargets({ fadeIn: false });
  }

  toggle(event) {
    event.preventDefault();

    const newState = this.toggleState === this.stateOn ? this.stateOff : this.stateOn;
    this.#setState(newState);
    this.#toggleTargets({ fadeIn: true });
  }

  #toggleTargets(options) {
    const fadeIn = options.fadeIn;
    const state = this.#state;
    const isStateOn = state === this.stateOn;

    const show = (elem) => animate.show(elem, { fadeIn });
    const hide = (elem) => animate.hide(elem, { fadeOut: false });

    if (this.hasOnIsVisibleTarget) {
      this.onIsVisibleTargets.forEach((elem) => {
        isStateOn ? show(elem) : hide(elem);
      });
    }

    if (this.hasOnIsHiddenTarget) {
      this.onIsHiddenTargets.forEach((elem) => {
        isStateOn ? hide(elem) : show(elem);
      });
    }

    if (this.hasToggleLinkTarget) {
      this.toggleLinkTarget.innerHTML = isStateOn ? this.onLinkTextValue : this.offLinkTextValue;
    }
  }

  get #state() {
    if (this.rememberKeyValue === "") {
      return this.toggleState;
    }

    let state = store.get(`ir-toggle-${this.rememberKeyValue}`);
    if (!state) {
      state = this.toggleState;
      this.#setState(state);
    } else {
      this.toggleState = state;
    }

    return state;
  }

  #setState(state) {
    this.toggleState = state;

    if (this.rememberKeyValue === "") return;

    store.set(`ir-toggle-${this.rememberKeyValue}`, state);
  }
}
