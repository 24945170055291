import Polygon from "ol/geom/Polygon";
import { Feature } from "ol";
import { fromLonLat } from "ol/proj";

import { degreesToRadians } from "../../../../helpers/geometry";
import { PANEL_DATA_TYPE, SEGMENT_DATA_TYPE } from "../../../../da/map/data-types";
import { DEFAULT_ZONE_MODULE_POSITION } from "../../../models/panel-model";
import { createThermalExpansionFeature } from "../thermal-expansions/helpers";
import { RAIL_OVERHANG_LENGTH } from "../../map-model-synchronizers/segments-rail-group";
import { CONTOUR_CODE_NONE } from "../../../../helpers/contour";

export default class SegmentRenderer {
  constructor({
    roofPlane,
    segment,
    controller,
    panelsVectorSource,
    segmentsVectorSource,
    railsVectorSource,
    thermalExpansionsVectorSource,
  }) {
    this.roofPlane = roofPlane;
    this.project = roofPlane.project;
    this.segment = segment;

    this.controller = controller;
    this.mapManager = controller.mapManager;

    this.panelsVectorSource = panelsVectorSource;
    this.segmentsVectorSource = segmentsVectorSource;
    this.railsVectorSource = railsVectorSource;
    this.thermalExpansionsVectorSource = thermalExpansionsVectorSource;

    this.panelFeatures = [];
    this.segmentFeature = undefined;
    this.railFeatures = [];
    this.thermalExpansionFeatures = [];
  }

  reRender() {
    this.clearSegmentFeatures();
    this.render();
  }

  clearSegmentFeatures() {
    this.railsVectorSource.getFeatures().forEach((railFeature) => {
      if (railFeature.get("segmentUuid") !== this.segment.uuid) return;
      this.railsVectorSource.removeFeature(railFeature);
    });
    this.panelsVectorSource.getFeatures().forEach((panelFeature) => {
      if (panelFeature.get("segmentUuid") !== this.segment.uuid) return;
      this.panelsVectorSource.removeFeature(panelFeature);
    });
    this.segmentsVectorSource.getFeatures().forEach((segmentFeature) => {
      if (segmentFeature.get("uuid") !== this.segment.uuid) return;
      this.segmentsVectorSource.removeFeature(segmentFeature);
    });
    this.thermalExpansionsVectorSource.getFeatures().forEach((thermalExpansionFeature) => {
      if (thermalExpansionFeature.get("segmentUuid") !== this.segment.uuid) return;
      this.thermalExpansionsVectorSource.removeFeature(thermalExpansionFeature);
    });
  }

  render() {
    if (!this.segment.displayable) return;

    this.buildPanels();
    this.segment.setDefaultZonesAndModulePositionsIfNoneExist();
    this.addPanelsToVectorSource();

    this.buildSegment();
    this.addSegmentToVectorSource();

    this.buildRails();
    this.addRailsToVectorSource();

    this.buildThermalExpansions();
    this.addThermalExpansionsToVectorSource();
  }

  get segmentCoordinates() {
    return this.segment.latLngPoints.map((latLng) => fromLonLat(latLng.toLonLat));
  }

  buildPanels() {
    const zonesAndModulePositionsGrid = this.segment.zonesAndModulePositionsGrid;
    const contourCodesGrid = this.segment.contourCodesGrid;
    if (this.segment.panels.length > 0) this.segment.clearPanels();

    const { rows, columns } = this.segment;

    for (let row = 1; row <= rows; row++) {
      for (let column = 1; column <= columns; column++) {
        let zonesModulePosition = DEFAULT_ZONE_MODULE_POSITION;
        if (zonesAndModulePositionsGrid[row - 1] && zonesAndModulePositionsGrid[row - 1][column - 1]) {
          zonesModulePosition = zonesAndModulePositionsGrid[row - 1][column - 1];
        }
        const [zone, modulePosition] = zonesModulePosition.split(":");

        let contourCode = CONTOUR_CODE_NONE;
        if (contourCodesGrid[row - 1] && contourCodesGrid[row - 1][column - 1]) {
          contourCode = contourCodesGrid[row - 1][column - 1];
        }

        const panelFeature = this.buildPanel(row, column, zone, modulePosition, contourCode);
        this.panelFeatures.push(panelFeature);
      }
    }
  }

  addPanelsToVectorSource() {
    if (!this.panelsVectorSource) return;

    this.panelFeatures.forEach((panelFeature) => this.panelsVectorSource.addFeature(panelFeature));
  }

  buildPanel(row, column, zone, modulePosition, contourCode) {
    const panel = this.segment.addPanel({ row, column, zone, modulePosition, contourCode });

    const coordinates = panel.latLngPoints.map((latLng) => fromLonLat(latLng.toLonLat));
    const panelFeature = this.rectangleFeature(coordinates);

    panelFeature.set("illegalShape", this.segment.illegalShape);
    panelFeature.set("model", panel);
    panelFeature.set("dataType", PANEL_DATA_TYPE);
    panelFeature.set("uuid", panel.uuid);
    panelFeature.set("segmentUuid", this.segment.uuid);

    return panelFeature;
  }

  rectangleFeature(coordinates) {
    const geometry = new Polygon([coordinates]);
    const feature = new Feature({ geometry });
    return feature;
  }

  buildSegment() {
    const coordinates = this.segment.latLngPoints.map((latLng) => fromLonLat(latLng.toLonLat));
    const feature = this.rectangleFeature(coordinates);
    feature.set("model", this.segment);
    feature.set("dataType", SEGMENT_DATA_TYPE);
    feature.set("uuid", this.segment.uuid);
    this.segmentFeature = feature;
  }

  addSegmentToVectorSource() {
    if (this.segmentsVectorSource === undefined) return;

    this.segmentsVectorSource.addFeature(this.segmentFeature);
  }

  buildRails() {
    if (!this.segment.railed) return;

    const useThirdRail = this.controller.project.detail.thirdRail;

    const roofSlopeRadians = degreesToRadians(this.roofPlane.roofSlope);
    const railWidth = 5.0;
    const railInsetPercentage = 0.2;
    const adjustedRailWidth = railWidth * Math.cos(roofSlopeRadians);

    const railOffsets = (row, percentageOfPanelInset) => {
      const top = this.segment.unitRowVectorLatLng.times(
        row * this.segment.slopeAdjustedSpacedPanelSizeInRowDirection +
          this.segment.slopeAdjustedSpacedPanelSizeInRowDirection * -1 * percentageOfPanelInset +
          0.5 * adjustedRailWidth,
      );
      const bottom = this.segment.unitRowVectorLatLng.times(adjustedRailWidth);
      const middle = this.segment.unitRowVectorLatLng.times(adjustedRailWidth / 2);
      return { top, bottom, middle };
    };

    for (let row = 1; row < this.segment.rows + 1; row++) {
      // Top rail
      let rail = 1;
      const railTwoOffsetPanelWidthPercent = 1.0 - railInsetPercentage;
      const railTwoOffsets = railOffsets(row, railTwoOffsetPanelWidthPercent);
      const railTwoFeature = this.buildRail(
        row,
        rail,
        railTwoOffsets.top,
        railTwoOffsets.bottom,
        railTwoOffsets.middle,
      );
      this.railFeatures.push(railTwoFeature);

      // Bottom rail
      rail = 2;
      const railOneOffsets = railOffsets(row, railInsetPercentage);
      const railOneFeature = this.buildRail(
        row,
        rail,
        railOneOffsets.top,
        railOneOffsets.bottom,
        railOneOffsets.middle,
      );
      this.railFeatures.push(railOneFeature);

      // Middle rail
      if (useThirdRail) {
        rail = 3;
        const offsetPanelWidthPercent = 0.5;
        const offsets = railOffsets(row, offsetPanelWidthPercent);
        const feature = this.buildRail(row, rail, offsets.top, offsets.bottom, offsets.middle);
        this.railFeatures.push(feature);
      }
    }
  }

  buildRail(row, rail, railOffsetTop, railOffsetBottom, railOffsetMiddle) {
    const { startLatLng, hasUnrailedChildren, unrailedChildren } = this.segment;

    let columnOffset;
    if (hasUnrailedChildren) {
      const farthestAwayChild = unrailedChildren[unrailedChildren.length - 1];
      const { distanceFromRailedParentStart } = farthestAwayChild;
      columnOffset = this.segment.unitColumnVectorLatLng.times(farthestAwayChild.width + distanceFromRailedParentStart);
    } else {
      columnOffset = this.segment.unitColumnVectorLatLng.times(this.segment.width);
    }

    const railOverhangLength = this.project.hiddenEndClamp ? 0 : RAIL_OVERHANG_LENGTH;
    const railOverhangOffset = this.segment.unitColumnVectorLatLng.times(railOverhangLength);

    const railStart = startLatLng.plus(railOffsetTop).minus(railOverhangOffset);
    const p2 = railStart.plus(columnOffset).plus(railOverhangOffset.times(2));
    const p3 = p2.minus(railOffsetBottom);
    const p4 = railStart.minus(railOffsetBottom);

    const coordinatesLatLng = [railStart, p2, p3, p4, railStart];
    const coordinates = coordinatesLatLng.map((latLng) => fromLonLat(latLng.toLonLat));

    const midLinePointOne = railStart.minus(railOffsetMiddle);
    const midLinePointTwo = p2.minus(railOffsetMiddle);
    const midLineCoordinates = [midLinePointOne, midLinePointTwo].map((latLng) => fromLonLat(latLng.toLonLat));

    const feature = this.rectangleFeature(coordinates);
    feature.set("segmentUuid", this.segment.uuid);
    feature.set("row", row);
    feature.set("rail", rail);
    feature.set("midLineCoordinates", midLineCoordinates);

    return feature;
  }

  addRailsToVectorSource() {
    if (this.railsVectorSource === undefined) return;

    this.railFeatures.forEach((feature) => this.railsVectorSource.addFeature(feature));
  }

  buildThermalExpansions() {
    this.segment.thermalExpansions.forEach((thermalExpansion) => {
      if (thermalExpansion.latLngPoints.length === 0) {
        thermalExpansion.generateAndSetLatLngPoints(thermalExpansion.startLatLng);
      }
      const feature = createThermalExpansionFeature(thermalExpansion);
      this.thermalExpansionFeatures.push(feature);
    });
  }

  addThermalExpansionsToVectorSource() {
    if (this.thermalExpansionsVectorSource === undefined) return;

    this.thermalExpansionFeatures.forEach((feature) => {
      this.thermalExpansionsVectorSource.addFeature(feature);
    });
  }
}
