import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["heightContainer", "template"];
  static outlets = ["bx--layout-editor--map-obstructions"];

  add(obstruction) {
    const template = this.templateTarget.innerHTML
      .replaceAll("[identifier]", obstruction.identifier)
      .replaceAll("[uuid]", obstruction.uuid);

    this.element.insertAdjacentHTML("beforeend", template);
  }

  remove(uuid, obstructions) {
    const container = this.#getHeightContainerForUUID(uuid);
    container.remove();

    obstructions.forEach((obstruction) => {
      const container = this.#getHeightContainerForUUID(obstruction.uuid);
      if (container) {
        container.querySelector("label").innerHTML = `Obstruction #${obstruction.identifier}`;
      }
    });
  }

  updateHeight(event) {
    const field = event.currentTarget;
    const uuid = event.params.uuid;

    let height = this.#extractValidFloat(field.value);

    field.value = height;
    const container = field.closest("[data-identifier='fieldWrapper']");
    if (field.value === "") {
      container.classList.add("field_with_errors");
    } else {
      container.classList.remove("field_with_errors");
    }
    this.bxLayoutEditorMapObstructionsOutlet.setObstructionHeight(uuid, height);
  }

  iconRemove(event) {
    const obstructionUuid = event.params.uuid;
    this.bxLayoutEditorMapObstructionsOutlet.removeObstruction(obstructionUuid);
    this.remove(obstructionUuid, this.bxLayoutEditorMapObstructionsOutlet.project.obstructions);
  }

  #getHeightContainerForUUID(uuid) {
    return this.heightContainerTargets.find((container) => container.dataset.uuid === uuid);
  }

  #extractValidFloat(value) {
    let result = value.replace(/[^\d\.]/g, "");

    // get rid of a second .
    if (result.match(/\.\d*\./)) {
      result = Number.parseFloat(result);
    }

    return result;
  }
}
