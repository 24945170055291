import DaBase from "../../../../da/map/interaction-managers/modify/base";
import {
  updateRoofSectionsFromModifiedSetbackCoordinates,
  ensureSetbackOuterRingRemainsUnmodified,
} from "../../modification-helpers/roof-section";

export default class Setbacks extends DaBase {
  modifyEndForFeature(setbackFeature) {
    this.mapManager.dispatchBeforeMapFeaturesRendering({
      calledFrom: "SetbacksModifyInteractionManager#modifyEndForFeature",
    });

    ensureSetbackOuterRingRemainsUnmodified(this.mapManager, setbackFeature);
    updateRoofSectionsFromModifiedSetbackCoordinates(this.controller.project, setbackFeature);

    this.mapManager.buildRoofSectionsVectorSource();
    this.mapManager.forceRedrawRoofPlanes();

    this.mapManager.dispatchAfterMapFeaturesRendering({
      calledFrom: "SetbacksModifyInteractionManager#modifyEndForFeature",
    });

    this.controller.markDirty();
  }

  get polygonModifyInteractionParams() {
    return {
      features: this.selectedPolygonFeatures,
      insertVertexCondition: () => false, // disallow adding vertexes
    };
  }
}
