import Location from "./location";

export default class extends Location {
  constructor({ street, city, state, zip, lat, lng }) {
    super();
    this.street = street;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.lat = lat;
    this.lng = lng;
  }
}
