import { Controller } from "@hotwired/stimulus";
import React from "react";
import ReactDOMServer from "react-dom/server";

import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = [
    "allDistributorLocations",
    "nearestDistributorLocations",
    "successMessage",
    "failureMessage",
    "selectedDistributorName",
    "selectedDistributorCityState",
    "selectedDistributorId",
    "selectedDistributorContainer",
    "selectADistributorContainer",
    "changeButton",
    "chooserContainer",
    "setByNameButton",
    "setNearProjectButton",
    "byNameViewContainer",
    "nameSearch",
    "nameMatches",
    "nearestChoices",
    "nearProjectViewContainer",
    "submitQuoteRequestContainer",
    "currentUserForm",
    "submitButton",
  ];

  connect() {
    this.allLocations = JSON.parse(this.allDistributorLocationsTarget.dataset.locations);
    this.nearestDistributorLocations = JSON.parse(this.nearestDistributorLocationsTarget.dataset.locations);

    this.populateNearest();
  }

  populateNearest() {
    const choices = this.nearestDistributorLocations.map((location) => this.selectableLocation(location));
    this.nearestChoicesTarget.innerHTML = choices.join("\n");
  }

  showChoosers() {
    animate.show(this.chooserContainerTarget);
  }

  setCurrentView(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const currentView = button.dataset.viewName;

    this.toggleButtonActive(button, true);

    if (currentView === "nearProject") {
      this.byNameViewContainerTarget.style.display = "none";
      animate.show(this.nearProjectViewContainerTarget);
      this.toggleButtonActive(this.setByNameButtonTarget, false);
    } else {
      this.nearProjectViewContainerTarget.style.display = "none";
      animate.show(this.byNameViewContainerTarget);
      this.toggleButtonActive(this.setNearProjectButtonTarget, false);
    }
  }

  toggleButtonActive(button, bool) {
    button.closest("li").classList[bool ? "add" : "remove"]("active");
  }

  search() {
    const pattern = this.nameSearchTarget.value.toLowerCase();

    const matches = this.allLocations
      .filter((location) => {
        const { name } = location;
        return name.toLowerCase().includes(pattern);
      })
      .map((location) => this.selectableLocation(location));
    if (matches.length > 0) {
      animate.show(this.nameMatchesTarget);
    } else {
      animate.hide(this.nameMatchesTarget);
    }
    this.nameMatchesTarget.innerHTML = matches.join("\n");
  }

  selectableLocation(location) {
    const { name, cityState, locationId, distance } = location;

    return ReactDOMServer.renderToString(
      <div className="row da-quote-request__location-select__row">
        <div className="col-5">
          <div className="da-quote-request__location-select__location-name">{name}</div>
          <div className="da-quote-request__location-select__location-city-state">{cityState}</div>
        </div>
        <div className="col-3">{distance !== "?" && `${distance.toLocaleString()} mls`}</div>
        <div className="col-4 d-flex align-items-center justify-content-end">
          <button
            className="ir-btn ir-btn--small ir-btn--blue ir-btn--icon-prepend float-right"
            data-action="click->da--request-quote#selectLocation"
            data-location-id={locationId}
            type="button"
          >
            <span className="ir-btn__icon">
              <i className="fas fa-check" />
            </span>
            <span className="ir-btn__text">Select</span>
          </button>
        </div>
      </div>,
    );
  }

  selectLocation(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const locationId = parseInt(button.dataset.locationId);

    const location = this.allLocations.find((location) => location.locationId === locationId);

    if (location) {
      const { name, cityState } = location;

      this.selectedDistributorNameTarget.innerHTML = name;
      this.selectedDistributorCityStateTarget.innerHTML = cityState;
      this.selectedDistributorIdTarget.value = locationId;

      animate.show(this.changeButtonTarget, { display: "flex" });
      animate.hide(this.chooserContainerTarget);
      animate.show(this.submitQuoteRequestContainerTarget);
      animate.show(this.selectedDistributorContainerTarget);
      this.selectADistributorContainerTarget.style.display = "none";
    }
  }

  close(_event) {
    this.changeButtonTarget.style.display = "flex";
    this.successMessageTarget.style.display = "none";
    this.failureMessageTarget.style.display = "none";
    this.currentUserFormTarget.style.display = "block";
    this.submitButtonTarget.classList.remove("ir-btn--spinner-shown");
  }
}
