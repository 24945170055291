import React from "react";
import PropTypes from "prop-types";

function Filter({ checked, onChange, message }) {
  return (
    <div className="pp__filter">
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} /> {message}
      </label>
    </div>
  );
}

Filter.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default React.memo(Filter);
