import { Controller } from "@hotwired/stimulus";
import { showDialogWithIdentifier } from "../components/ir_dialog/helper";

export default class extends Controller {
  request(event) {
    event.preventDefault();
    const link = event.currentTarget;
    const { distributorName, distributorEmail, distributorCity, distributorState } = link.dataset;

    this.populateHiddenFieldWithValue("distributorName", distributorName);
    this.populateHiddenFieldWithValue("distributorEmail", distributorEmail);
    this.populateHiddenFieldWithValue("distributorCity", distributorCity);
    this.populateHiddenFieldWithValue("distributorState", distributorState);

    this.setDisplay(distributorName, distributorCity, distributorState);

    const clickPosition = { x: event.pageX, y: event.pageY };

    showDialogWithIdentifier("distributors-request-quote-dialog", () => {}, {
      showNearClickPosition: true,
      clickPosition,
    });
  }

  populateHiddenFieldWithValue(keyName, value) {
    document.querySelector(`[data-distributors--quote-form-target="${keyName}HiddenField"]`).value = value;
  }

  setDisplay(distributorName, distributorCity, distributorState) {
    document.querySelector(
      `[data-distributors--quote-form-target="distributorDisplay"]`,
    ).innerText = `${distributorName}, ${distributorCity}, ${distributorState}`;
  }
}
