import { pixelsFromLatLng, truncatePixelCoordinateDecimal } from "../../../../../da/map/ol-helpers";
import { contourCodeGenerator, isContourEast, isContourSouth, isContourWest } from "../../../../../helpers/contour";

// If panels are no longer covered by Contour on a side, remove the Contour code
export default class SegmentsContourCodesClearer {
  constructor({ controller }) {
    this.controller = controller;
    this.map = controller.mapManager.map;

    this.segments = controller.focusedRoofPlane.roofSections.flatMap((rs) => rs.notDeletedSegments);
    this.contours = this.segments.flatMap((s) => s.contours);
  }

  clear() {
    this.segments.forEach((segment) => {
      const verticalPanels = [...segment.panelsOnSideLeft, ...segment.panelsOnSideRight];
      this.#unsetVerticalPanelContourIfNoLongerCoveredByContourRun(verticalPanels, "left");
      this.#unsetVerticalPanelContourIfNoLongerCoveredByContourRun(verticalPanels, "right");
      this.#unsetHorizontalPanelContourIfNoLongerCoveredByContourRun(segment.panelsOnSideBottom);
    });
  }

  #unsetVerticalPanelContourIfNoLongerCoveredByContourRun(panels, side) {
    panels.forEach((panel) => {
      if (panel.contourCode === "0") return;

      const hasContourOnSide = side === "left" ? panel.isContourWest : panel.isContourEast;
      if (!hasContourOnSide) return;

      let panelContourPx;
      if (side === "left") {
        panelContourPx = panel.sideLatLngsLeft.map((ll) => pixelsFromLatLng(this.map, ll.toLatLng));
      } else {
        panelContourPx = panel.sideLatLngsRight.map((ll) => pixelsFromLatLng(this.map, ll.toLatLng));
      }

      let hasOverlappingContourRun = false;
      this.contours.forEach((contour) => {
        if (contour.side === "bottom") return;

        const contourPx = contour.latLngPoints.map((ll) => pixelsFromLatLng(this.map, ll.toLatLng));
        if (this.#hasVerticalOverlap(contourPx, panelContourPx)) {
          hasOverlappingContourRun = true;
        }
      });

      if (!hasOverlappingContourRun) {
        let isWest = side === "left" ? false : isContourWest(panel.contourCode);
        let isEast = side === "left" ? isContourEast(panel.contourCode) : false;
        let isSouth = isContourSouth(panel.contourCode);
        const newCode = contourCodeGenerator(isWest, isEast, isSouth);
        panel.setContourCode(newCode);
      }
    });
  }

  #unsetHorizontalPanelContourIfNoLongerCoveredByContourRun(panels) {
    panels.forEach((panel) => {
      if (panel.contourCode === "0") return;

      if (!panel.isContourSouth) return;

      const panelContourPx = panel.sideLatLngsBottom.map((ll) => pixelsFromLatLng(this.map, ll.toLatLng));

      let hasOverlappingContourRun = false;
      this.contours.forEach((contour) => {
        if (contour.side !== "bottom") return;

        const contourPx = contour.latLngPoints.map((ll) => pixelsFromLatLng(this.map, ll.toLatLng));
        if (this.#hasHorizontalOverlap(contourPx, panelContourPx)) {
          hasOverlappingContourRun = true;
        }
      });

      if (!hasOverlappingContourRun) {
        let isWest = isContourWest(panel.contourCode);
        let isEast = isContourEast(panel.contourCode);
        let isSouth = false;
        const newCode = contourCodeGenerator(isWest, isEast, isSouth);
        panel.setContourCode(newCode);
      }
    });
  }

  #hasVerticalOverlap(contourPx, panelContourPx) {
    const sXCompare = truncatePixelCoordinateDecimal(contourPx[0][0]);
    const pXCompare = truncatePixelCoordinateDecimal(panelContourPx[0][0]);

    if (sXCompare !== pXCompare) return false;

    const panelYs = panelContourPx.map((a) => a[1]).sort((a, b) => a - b);
    const [pY1, pY2] = panelYs;

    const pY1Compare = truncatePixelCoordinateDecimal(pY1);
    const pY2Compare = truncatePixelCoordinateDecimal(pY2);

    const contourYs = contourPx.map((c) => c[1]).sort((a, b) => a - b);
    const [cY1, cY2] = contourYs;

    const cY1Compare = truncatePixelCoordinateDecimal(cY1);
    const cY2Compare = truncatePixelCoordinateDecimal(cY2);

    if (pY1Compare === cY1Compare && pY2Compare === cY2Compare) return true;

    const notOverlapping = pY2Compare < cY1Compare || pY1Compare > cY2Compare;
    return !notOverlapping;
  }

  #hasHorizontalOverlap(contourPx, panelContourPx) {
    const sYCompare = truncatePixelCoordinateDecimal(contourPx[0][1]);
    const pYCompare = truncatePixelCoordinateDecimal(panelContourPx[0][1]);

    if (sYCompare !== pYCompare) return false;

    const panelXs = panelContourPx.map((a) => a[0]).sort((a, b) => a - b);
    const [pX1, pX2] = panelXs;

    const pX1Compare = truncatePixelCoordinateDecimal(pX1);
    const pX2Compare = truncatePixelCoordinateDecimal(pX2);

    const contourXs = contourPx.map((c) => c[0]).sort((a, b) => a - b);
    const [cX1, cX2] = contourXs;

    const cX1Compare = truncatePixelCoordinateDecimal(cX1);
    const cX2Compare = truncatePixelCoordinateDecimal(cX2);

    if (pX1Compare === cX1Compare && pX2Compare === cX2Compare) return true;

    const notOverlapping = pX2Compare < cX1Compare || pX1Compare > cX2Compare;
    return !notOverlapping;
  }
}
