import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import { defaultHeaders } from "../../helpers/api";
import { alertDialog } from "../components/ir_dialog/helper";

export default class extends Controller {
  static targets = ["projectFolderIds", "discount"];

  get projectFolderIds() {
    return this.projectFolderIdsTargets.map((t) => t.value);
  }

  download(e) {
    const actionKey = e.currentTarget.dataset.actionKey;
    e.currentTarget.classList.add("ir-btn--spinner-shown");
    setTimeout(() => {
      fetch(this.#consolidatedBomUrl(actionKey), {
        headers: defaultHeaders(),
      }).then((response) => this.#downloadFileOrRenderValidationError(response));
    }, 200);
  }

  #consolidatedBomUrl(action) {
    const discountParam = `consolidated_bom[discount]=${this.discountTarget.value}`;
    const projectFolderIdParams = this.projectFolderIds
      .map((id) => `consolidated_bom[project_folder_ids][]=${id}`)
      .join("&");
    return `/consolidated_boms/${action}?${projectFolderIdParams}&${discountParam}`;
  }

  #downloadFileOrRenderValidationError(response) {
    if (!response.ok) {
      const title = `Server Error (${response.status})`;
      const message =
        "<p>We encountered a server error while trying to process your request. An admin has been notified of the problem.  We apologize for the inconvenience.</p>";

      alertDialog(message, () => {}, {
        title,
        headerColor: "red",
        confirmBtnColor: "red",
      });
      return;
    }

    /*
     * When successful, the response, which the Rails controller generates with `send_data` can
     * be converted to a blob in JS, which is a file-like object.  Check out https://developer.mozilla.org/en-US/docs/Web/API/Blob for more info.
     * Blobs can be sent in to window.URL.createObjectURL() to create a URL to download the file (for example, blob://abcd-randomly-generated-uuid-1234).
     * Once we have this URL, we can then add an invisible anchor tag to the DOM, set it's `href` attribute to the URL,
     * and then simulate a click on the anchor tag to initiate the file download.
     *
     * If there's a validation error, then the controllers render javascript (from new.js.erb), which we run by `eval`ing the response.
     */
    const contentDisposition = response.headers.get("Content-Disposition");
    if (contentDisposition && contentDisposition.includes("attachment")) {
      const filename = contentDisposition.match(/filename="(.*)";/)[1];
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        /* A call to /consolidated_boms/new does two things:
         * 1. It resets the spinner on the download button
         * 2. If a validation error exists from a previous submit and the user performs a valid download, it removes the error messages.
         */
        Rails.ajax({ type: "GET", url: this.#consolidatedBomUrl("new") });
      });
    } else {
      response.text().then((jsErb) => window.eval(jsErb));
    }
  }
}
