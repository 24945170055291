import { Style, Icon, Stroke } from "ol/style";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";

import { lineTooShortToShowOrnamentation } from "../../../da/map/ol-helpers";
import { calculateUnitPerpendicularEdgeVectorLatLng, METERS_TO_INCHES } from "../../../da/map/ol-geometry";
import { lineNumberMarkerStyle } from "./helpers";

const BLUE_LINE = "rgba(77, 151, 246, 1.0)";
const YELLOW_LINE = "rgba(216, 209, 10, 1.0)";
const ORANGE_FILL = "#D16000";

export function defaultEaveSelectionLineStringStyle(feature, map) {
  if (feature.get("lockedSelected")) {
    return selectedEaveSelectionLineStringStyle(feature, map);
  }

  let lineColor;
  let circleColor;
  if (feature.get("mouseover")) {
    lineColor = YELLOW_LINE;
    circleColor = ORANGE_FILL;
  } else {
    lineColor = BLUE_LINE;
    circleColor = "#000";
  }
  const lineWidth = 3;
  const zIndex = 1;
  return eaveSelectionLineStringStyles(feature, map, lineColor, lineWidth, circleColor, zIndex);
}

export function selectedEaveSelectionLineStringStyle(feature, map) {
  const lineColor = YELLOW_LINE;
  const circleColor = ORANGE_FILL;
  const lineWidth = 5;
  const zIndex = 2;
  const styles = eaveSelectionLineStringStyles(feature, map, lineColor, lineWidth, circleColor, zIndex);

  const arrow = arrowStyle(feature, map);
  styles.push(arrow);
  return styles;
}

function arrowStyle(feature, map) {
  const [startPoint, endPoint] = feature.getGeometry().getCoordinates();

  const [midPointLatLng, unitPerpendicularEdgeVectorLatLng, _unitEdgeVectorLatLng] =
    calculateUnitPerpendicularEdgeVectorLatLng(startPoint, endPoint); // [coordinate, inches]

  const resolution = map.getView().getResolution(); // meters per pixel
  const arrowDistancePixels = 50;
  const arrowDistanceInches = arrowDistancePixels * resolution * METERS_TO_INCHES;

  const arrowHeadLatLon = midPointLatLng.plus(unitPerpendicularEdgeVectorLatLng.times(arrowDistanceInches));
  const arrowHeadCoordinates = fromLonLat(arrowHeadLatLon.toLonLat);
  const heightPixels = 50;
  const widthPixels = 50;

  const dx = endPoint[0] - startPoint[0];
  const dy = endPoint[1] - startPoint[1];
  const rotation = -1 * (Math.atan2(dy, dx) + Math.PI);

  return new Style({
    geometry: new Point(arrowHeadCoordinates),
    image: new Icon({
      img: arrow(heightPixels, widthPixels),
      imgSize: [heightPixels, widthPixels],
      rotateWithView: true,
      rotation,
    }),
    zIndex: 10,
  });
}

function arrow(width, height) {
  const canvas = document.createElement("canvas");
  canvas.height = height;
  canvas.width = width;

  const context = canvas.getContext("2d");

  context.beginPath();
  const arrowPoints = [
    [25, 5],
    [42, 23],
    [38, 27],
    [28, 16],
    [28, 44],
    [22, 44],
    [22, 16],
    [12, 27],
    [8, 23],
  ];
  arrowPoints.forEach((ap, i) => {
    const m = i === 0 ? "moveTo" : "lineTo";
    context[m](...ap);
  });
  context.closePath();

  context.strokeStyle = "#fff";
  context.fillStyle = YELLOW_LINE;
  context.lineCap = "round";

  context.fill();

  return canvas;
}

function eaveSelectionLineStringStyles(feature, map, lineColor, lineWidth, circleColor, zIndex) {
  const styles = [];
  styles.push(
    new Style({
      stroke: new Stroke({ color: lineColor, width: lineWidth }),
      zIndex,
    }),
  );
  const [startPoint, endPoint] = feature.getGeometry().getCoordinates();
  if (!lineTooShortToShowOrnamentation(map, startPoint, endPoint, 40)) {
    const lineNumber = feature.get("lineNumber");
    styles.push(
      lineNumberMarkerStyle({
        startPoint,
        endPoint,
        lineNumber,
        lineColor,
        lineWidth,
        circleColor,
        zIndex: zIndex + 1,
      }),
    );
  }
  return styles;
}
