import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["changeWhenFixed"];

  fixedCssClass = "project-header--fixed";

  connect() {
    window.addEventListener("scroll", this.scrollToggle);
    this.headerHeight = this.element.offsetHeight;
    this.#toggleFixedHeaderNav();
  }

  disconnect() {
    window.removeEventListener("scroll", this.scrollToggle);
  }

  scrollToggle = (_event) => {
    this.#toggleFixedHeaderNav();
  };

  #toggleFixedHeaderNav() {
    if (this.#isScrolledPastMainNavBar) {
      this.#addFixedHeaderNavClass();
    } else {
      this.#removeFixedHeaderNavClass();
    }
  }

  #addFixedHeaderNavClass() {
    this.element.classList.add(this.fixedCssClass);
    this.#bodyContainer.style.paddingTop = `${this.headerHeight}px`;
    this.#changeWhenFixed("add");
  }

  #removeFixedHeaderNavClass() {
    this.element.classList.remove(this.fixedCssClass);
    this.#changeWhenFixed("remove");
    this.#bodyContainer.style.paddingTop = 0;
  }

  get #isScrolledPastMainNavBar() {
    return window.scrollY > this.#mainNavBar.offsetHeight;
  }

  get #mainNavBar() {
    return document.querySelector("[data-identifier='main-navbar']");
  }

  get #bodyContainer() {
    return document.querySelector(".ir-body-container");
  }

  #changeWhenFixed(addRemove) {
    this.changeWhenFixedTargets.forEach((element) => {
      element.classList[addRemove](element.dataset.fixedCssClass);
    });
  }
}
