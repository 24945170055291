// cSpell:ignore obstructedness, unobstruct

import { fromLonLat } from "ol/proj";

import { emptyDesign, presentDesign } from "../../models/layout-model";

import CellPositioner from "./cell-positioner";
import { pointOutsidePolygon } from "../../../helpers/point-in-polygon";
import { polygonsAsPointListsIntersect } from "../../../da/map/legality-checkers/polygon-helpers";
import { benchmark, logger } from "../../../helpers/app";
import { objectsOverlap, polygonHasVertexInsideOtherPolygon } from "../overlap-checker";

export default class LayoutBuilder {
  constructor(project, roofSection) {
    this.project = project;
    this.roofSection = roofSection;
  }

  build(priorLayout) {
    const fromOld = priorLayout ? ` (from old ${priorLayout.rows}r x ${priorLayout.columns}c)` : "";
    console.log(`Building Layout for ${this.roofSection.displayIdentifier}${fromOld}`);

    this.#buildCellPositioner();
    if (priorLayout) {
      this.#buildAllEmptyLayout();
      this.overlayPresentPanels(priorLayout);
    } else {
      this.#buildAllPresentLayout();
    }
    this.#disableOutOfBoundsCells();
  }

  reprocessExistingLayout() {
    this.#buildCellPositioner();
    this.#updateOutOfBoundsCells();
  }

  overlayPresentPanels(priorLayout) {
    // new shape might be too small to get a layout
    const newLayout = this.roofSection.layout;
    if (!newLayout) return;

    newLayout.overlayPresentPanels(priorLayout);
  }

  #buildCellPositioner() {
    this.cellPositioner = new CellPositioner(this.project, this.roofSection);
    this.rows = this.cellPositioner.rows;
    this.columns = this.cellPositioner.columns;
  }

  #buildAllPresentLayout() {
    this.#buildFilledLayout(presentDesign);
  }

  #buildAllEmptyLayout() {
    this.#buildFilledLayout(emptyDesign);
  }

  #buildFilledLayout(designBuilder) {
    if (this.rows < 1 || this.columns < 1) {
      // delete the existing layout (if one exists)
      this.roofSection.deleteLayout();
    } else {
      const design = designBuilder(this.rows, this.columns);

      this.roofSection.addLayout(this.rows, this.columns, design, true, []);
    }
  }

  #disableOutOfBoundsCells() {
    const cellUpdater = (layout, row, column, cellOutOfBounds, cellObstructed) => {
      if (cellOutOfBounds) {
        layout.outOfBoundsPanel(row, column);
      }
      this.#updateObstructedness(layout, row, column, cellObstructed);
    };
    this.#processCells(cellUpdater);
  }

  #updateOutOfBoundsCells() {
    const cellUpdater = (layout, row, column, cellOutOfBounds, cellObstructed) => {
      if (cellOutOfBounds) {
        layout.outOfBoundsPanel(row, column);
      } else {
        if (layout.isOutOfBounds(row, column)) {
          layout.addPanel(row, column);
        }
      }
      this.#updateObstructedness(layout, row, column, cellObstructed);
    };
    this.#processCells(cellUpdater);
  }

  #updateObstructedness(layout, row, column, cellObstructed) {
    if (cellObstructed) {
      layout.obstructPanel(row, column);
    } else {
      layout.unobstructPanel(row, column);
    }
  }

  #processCells(cellUpdater) {
    benchmark(`Layout: processCells for roofSection ${this.roofSection.displayIdentifier}`, () => {
      const layout = this.roofSection.layout;
      if (!layout) return;

      const roofSectionInOpenLayersCoordinates = this.roofSection.latLngPointsToArray.map((p) =>
        fromLonLat([p[1], p[0]]),
      );
      const roofSectionAsPoints = roofSectionInOpenLayersCoordinates.map((c) => [c[0], c[1]]);
      this.#convertObstructionsAndBuffers(roofSectionInOpenLayersCoordinates, roofSectionAsPoints);

      for (let row = 1; row <= this.rows; row++) {
        for (let column = 1; column <= this.columns; column++) {
          const cellAt = this.cellPositioner.cellAt(row, column);
          const cellAtInOpenLayersCoordinates = cellAt.map((latLng) => fromLonLat(latLng.toLonLat));
          const cellAtAsPoints = cellAtInOpenLayersCoordinates.map((p) => [p[0], p[1]]);

          const extendedCellAt = this.cellPositioner.extendedCellAt(row, column);
          const extendedCellAtInOpenLayersCoordinates = extendedCellAt.map((latLng) => fromLonLat(latLng.toLonLat));
          const firstVertexOfExtendedCellInOpenLayersCoordinates = extendedCellAtInOpenLayersCoordinates[0];
          const extendedCellAtAsPoints = extendedCellAtInOpenLayersCoordinates.map((p) => [p[0], p[1]]);

          const cellOutOfBounds =
            this.#outsideOfRoofSection(firstVertexOfExtendedCellInOpenLayersCoordinates, [
              roofSectionInOpenLayersCoordinates,
            ]) ||
            this.#anyEdgesIntersect(extendedCellAtAsPoints, roofSectionAsPoints) ||
            this.#overlapsObstruction(extendedCellAtInOpenLayersCoordinates, extendedCellAtAsPoints);

          const cellObstructed = this.#overlapsObstructionBuffer(cellAtInOpenLayersCoordinates, cellAtAsPoints);

          cellUpdater(layout, row, column, cellOutOfBounds, cellObstructed);
        }
      }

      // make sure the design matches the updated status in each cells. Keeping things in sync is good,
      // but its especially important for newly built arrays which are created with an all present layout
      layout.transferCellsToDesign();
    });
  }

  #outsideOfRoofSection(vertex, roofSectionInOpenLayersCoordinates) {
    return pointOutsidePolygon(vertex, roofSectionInOpenLayersCoordinates);
  }

  #anyEdgesIntersect(cellAtAsPoints, roofSectionAsPoints) {
    return polygonsAsPointListsIntersect(cellAtAsPoints, roofSectionAsPoints);
  }

  #convertObstructionsAndBuffers(roofSectionInOpenLayersCoordinates, roofSectionAsPoints) {
    this.obstructionsInOpenLayersCoordinates = [];
    this.obstructionsAsPoints = [];

    this.obstructionBuffersInOpenLayersCoordinates = [];
    this.obstructionBuffersAsPoints = [];

    this.project.notDeletedObstructions.forEach((obstruction) => {
      this.#convertObject(
        obstruction,
        roofSectionInOpenLayersCoordinates,
        roofSectionAsPoints,
        this.obstructionsInOpenLayersCoordinates,
        this.obstructionsAsPoints,
      );
      this.#convertObject(
        obstruction.buffer,
        roofSectionInOpenLayersCoordinates,
        roofSectionAsPoints,
        this.obstructionBuffersInOpenLayersCoordinates,
        this.obstructionBuffersAsPoints,
      );
    });
  }

  #convertObject(
    object,
    roofSectionInOpenLayersCoordinates,
    roofSectionAsPoints,
    objectsInCoordinates,
    objectsAsPoints,
  ) {
    if (object === null || object === undefined) return;

    const objectInOpenLayersCoordinates = object.latLngPointsToArray.map((p) => fromLonLat([p[1], p[0]]));
    const objectAsPoints = objectInOpenLayersCoordinates.map((c) => [c[0], c[1]]);

    if (
      objectsOverlap(
        objectInOpenLayersCoordinates,
        objectAsPoints,
        roofSectionInOpenLayersCoordinates,
        roofSectionAsPoints,
      )
    ) {
      logger(`Considering object #${object.identifier}`);
      objectsInCoordinates.push(objectInOpenLayersCoordinates);
      objectsAsPoints.push(objectAsPoints);
    } else {
      logger(`Skipping object #${object.identifier}`);
    }
  }

  #overlapsObstruction(cellAtInOpenLayersCoordinates, cellAtAsPoints) {
    return (
      this.#cellVertexInsideObstructionOrBuffer(
        cellAtInOpenLayersCoordinates,
        this.obstructionsInOpenLayersCoordinates,
      ) ||
      this.#cellEdgeIntersectsObstructionOrBufferEdge(cellAtAsPoints, this.obstructionsAsPoints) ||
      this.#obstructionOrBufferVertexInsideCell(cellAtInOpenLayersCoordinates, this.obstructionsInOpenLayersCoordinates)
    );
  }

  #overlapsObstructionBuffer(cellAtInOpenLayersCoordinates, cellAtAsPoints) {
    return (
      this.#cellVertexInsideObstructionOrBuffer(
        cellAtInOpenLayersCoordinates,
        this.obstructionBuffersInOpenLayersCoordinates,
      ) ||
      this.#cellEdgeIntersectsObstructionOrBufferEdge(cellAtAsPoints, this.obstructionBuffersAsPoints) ||
      this.#obstructionOrBufferVertexInsideCell(
        cellAtInOpenLayersCoordinates,
        this.obstructionBuffersInOpenLayersCoordinates,
      )
    );
  }

  #cellVertexInsideObstructionOrBuffer(cellAtInOpenLayersCoordinates, obstructionsOrBuffers) {
    return obstructionsOrBuffers.some((obstructionInOpenLayersCoordinates) => {
      return polygonHasVertexInsideOtherPolygon(cellAtInOpenLayersCoordinates, obstructionInOpenLayersCoordinates);
    });
  }

  #cellEdgeIntersectsObstructionOrBufferEdge(cellAtAsPoints, obstructionsOrBuffers) {
    return obstructionsOrBuffers.some((obstructionAsPoints) => {
      return polygonsAsPointListsIntersect(cellAtAsPoints, obstructionAsPoints);
    });
  }

  #obstructionOrBufferVertexInsideCell(cellAtInOpenLayersCoordinates, obstructionsOrBuffers) {
    return obstructionsOrBuffers.some((obstructionInOpenLayersCoordinates) => {
      return polygonHasVertexInsideOtherPolygon(obstructionInOpenLayersCoordinates, cellAtInOpenLayersCoordinates);
    });
  }
}
