import { PROXIMITY_CUTOFF } from "./geometry";

// true if the point is inside the polygon or on an edge
export function pointInOrOnPolygon(p, polygon) {
  const testResult = pointRelativeToPolygon(p, polygon);

  return testResult === true || testResult === 0;
}

// true if the point is inside the polygon and NOT on an edge
export function pointInPolygon(p, polygon) {
  const testResult = pointRelativeToPolygon(p, polygon);

  return testResult === true;
}

// true if the point is outside the polygon and NOT on an edge
export function pointOutsidePolygon(p, polygon) {
  const testResult = pointRelativeToPolygon(p, polygon);

  return testResult === false;
}

// https://github.com/rowanwins/point-in-polygon-hao
/*
  Returns
    true - point is in Polygon
    false - point is not inside Polygon
    0 - point is on an edge
*/
export function pointRelativeToPolygon(p, polygon) {
  let i = 0; // which ring of the polygon you are testing
  let ii = 0; // which point on the ring you are testing
  let k = 0;
  let determinant = 0;
  let determinantNearZero = false;
  let x1 = 0; // x coordinate of start of current line segment
  let y1 = 0; // y coordinate of start of current line segment
  let x2 = 0; // x coordinate of end of current line segment
  let y2 = 0; // y coordinate of end of current line segment
  let currentPoint = null;
  let nextPoint = null;

  const x = p[0]; // x coordinate of point being tested
  const y = p[1]; // y coordinate of point being tested

  const numContours = polygon.length;
  for (i; i < numContours; i++) {
    ii = 0;
    const contourLen = polygon[i].length - 1;
    const contour = polygon[i];

    currentPoint = contour[0];
    x1 = currentPoint[0] - x;
    y1 = currentPoint[1] - y;

    for (ii; ii < contourLen; ii++) {
      nextPoint = contour[ii + 1];

      y2 = nextPoint[1] - y;

      if ((y1 < 0 && y2 < 0) || (y1 > 0 && y2 > 0)) {
        // Line is either entirely below or entirely above point
        currentPoint = nextPoint;
        y1 = y2;
        x1 = currentPoint[0] - x;
        continue;
      }

      x2 = nextPoint[0] - x;
      determinant = x1 * y2 - x2 * y1;
      // NOTE: when you have an almost horizontal edge where one end is exactly
      // at the some Y value as the point, the determinant collapses to just one
      // of the two terms (since either y1 or y2 is 0). In that case, the other y
      // can be very small and the determinant can become smaller than the cutoff.
      // Making the cutoff smaller is problematic because it increases the tolerances
      // for trying to create manual roof sections that are inside a roof plane.
      // We left this "as is" and started the R1C1 cell offset 0.1" down from the
      // bounding box (in addition to the 0.1" over it was already using)
      determinantNearZero = Math.abs(determinant) < PROXIMITY_CUTOFF;

      if (y2 > 0 && y1 <= 0) {
        // lineEnd above point & lineStart equal or below point
        if (determinantNearZero) return 0;
        if (determinant > 0) k = k + 1;
      } else if (y1 > 0 && y2 <= 0) {
        // lineStart above point & lineEnd equal or below point
        if (determinantNearZero) return 0;
        if (determinant < 0) k = k + 1;
      } else if (y2 === 0 && y1 < 0) {
        // lineEnd same height as point & lineStart below
        if (determinantNearZero) return 0;
      } else if (y1 === 0 && y2 < 0) {
        // lineStart same height as point & lineEnd below
        if (determinantNearZero) return 0;
      } else if (y1 === 0 && y2 === 0) {
        // lineStart and lineEnd at the same height as point (e.g. horizontal line)
        if (x2 <= 0 && x1 >= 0) {
          // lineEnd is left of point and lineStart is at or right of point
          return 0;
        } else if (x1 <= 0 && x2 >= 0) {
          // lineStart is left of point and lineEnd is at or right of point
          return 0;
        }
      }

      currentPoint = nextPoint;
      y1 = y2;
      x1 = x2;
    }
  }

  if (k % 2 === 0) return false;
  return true;
}
