import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "../../TextField";

class SearchField extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    searchTerm: PropTypes.string,
    searchTermChange: PropTypes.func.isRequired,
    currentSelectionIndex: PropTypes.number,
    setCurrentSelectionIndex: PropTypes.func.isRequired,
    selectCollectionItemFromCurrentSelectionIndex: PropTypes.func.isRequired,
    isValidSelectionIndex: PropTypes.func,
    allSelectionsInvalid: PropTypes.bool,
    collectionCount: PropTypes.number,
  };

  static defaultProps = {
    isValidSelectionIndex: () => true,
    allSelectionsInvalid: false,
    searchTerm: "",
  };

  textFieldKeyDown = (event) => {
    const { selectCollectionItemFromCurrentSelectionIndex, setCurrentSelectionIndex } = this.props;

    if (event.key === "Enter") {
      event.preventDefault();
      selectCollectionItemFromCurrentSelectionIndex();
    } else if (event.ctrlKey) {
      if (event.key === "k") {
        event.preventDefault();
        this.selectMenuItem(-1);
      } else if (event.key === "j") {
        event.preventDefault();
        this.selectMenuItem(1);
      }
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      this.selectMenuItem(1);
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      this.selectMenuItem(-1);
    } else {
      // we are not trying to navigate the menu
      setCurrentSelectionIndex(undefined);
    }
  };

  selectMenuItem(increment) {
    const { collectionCount, allSelectionsInvalid } = this.props;
    const { currentSelectionIndex, isValidSelectionIndex, setCurrentSelectionIndex } = this.props;
    if (allSelectionsInvalid || collectionCount === 0) return;

    let startingIndexPosition = currentSelectionIndex;
    if (startingIndexPosition === undefined) {
      startingIndexPosition = increment > 0 ? -1 : collectionCount;
    }

    let newSelectionIndex;
    if (increment < 0) {
      newSelectionIndex = (startingIndexPosition + increment - collectionCount * increment) % collectionCount;
    } else {
      newSelectionIndex = (startingIndexPosition + increment) % collectionCount;
    }

    if (!isValidSelectionIndex(newSelectionIndex)) {
      const newIncrement = increment < 0 ? increment - 1 : increment + 1;
      this.selectMenuItem(newIncrement);
    } else {
      setCurrentSelectionIndex(newSelectionIndex);
    }
  }

  render() {
    const { id, searchTerm, searchTermChange } = this.props;

    return (
      <TextField
        id={id}
        label="Search"
        value={searchTerm}
        onFieldChange={searchTermChange}
        onKeyDown={this.textFieldKeyDown}
        autoFocus
      />
    );
  }
}

export default SearchField;
