import { Controller } from "@hotwired/stimulus";
import Siema from "siema";
import { v4 as uuid } from "uuid";

export default class extends Controller {
  static targets = ["carousel", "pageLink"];

  connect() {
    if (this.isAlreadyConnected) return;

    this.addIdentifierToCarousel();

    this.initialized = false;
    this.element.controller = this;

    // We use this inside of introduction dialogs.  We need to give the dialog some
    // time to load, and then get moved to a new place in the DOM before initializing
    // otherwise Siema seems to intermittently come to the conclusion the width of the
    // content area is 0.
    setTimeout(() => {
      // If the element isn't visible, then we need to defer initializing Siema.  If we
      // don't Siema will compute the necessary width it needs to zero.
      const isVisible = this.element.offsetParent !== null;
      if (isVisible) this.initSiema();
    }, 300);
  }

  get isAlreadyConnected() {
    return this.carouselTarget.dataset.identifier !== undefined;
  }

  addIdentifierToCarousel() {
    this.uuid = uuid();
    this.carouselTarget.dataset.identifier = this.uuid;
  }

  initSiema() {
    if (this.initialized) return;

    this.siema = new Siema({
      selector: `[data-identifier='${this.uuid}']`,
      onChange: this.onChange.bind(this),
      loop: true,
    });

    this.initialized = true;
  }

  onChange = () => {
    const currentPage = this.siema.currentSlide + 1;
    this.pageLinkTargets.forEach((pageLink) => {
      const li = pageLink.closest("li");
      const linkPage = Number.parseInt(pageLink.dataset.page);
      const meth = linkPage === currentPage ? "add" : "remove";
      li.classList[meth]("active");
    });
  };

  goTo(event) {
    event.preventDefault();
    const link = event.currentTarget;
    const index = Number.parseInt(link.dataset.page) - 1;
    this.siema.goTo(index);
  }

  next(event) {
    event.preventDefault();
    this.siema.next();
  }

  prev(event) {
    event.preventDefault();
    this.siema.prev();
  }
}
