import { StreamActions } from "@hotwired/turbo";

import { errorAlertDialog, hideDialog } from "../controllers/components/ir_dialog/helper";
import { logger, showFlashMessage } from "../helpers/app";
import { flashlight } from "../helpers/animate";

StreamActions.hideDialog = function () {
  const dialogId = this.getAttribute("target");
  const dialog = document.querySelector(`[data-dialog-identifier="${dialogId}"]`);
  if (!dialog) {
    logger(`Dialog not found: ${dialogId}`);
    return;
  }

  hideDialog(dialog);
};

StreamActions.showErrorDialog = function () {
  const message = this.getAttribute("message");
  const title = this.getAttribute("title") || "Error";
  errorAlertDialog(message, () => {}, { title });
};

StreamActions.showFlash = function () {
  const message = this.getAttribute("message");
  const type = this.getAttribute("type") || "success";
  showFlashMessage({ message, flashType: type });
};

StreamActions.flashlight = function () {
  const selector = this.getAttribute("selector");
  flashlight(selector);
};

StreamActions.redirect = function () {
  const url = this.getAttribute("url");
  const timeout = this.getAttribute("timeout") || 0;
  setTimeout(() => {
    location.href = url;
  }, timeout);
};
