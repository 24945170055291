import React from "react";
import PropTypes from "prop-types";
import Editable from "./Editable";
import ViewOnly from "./ViewOnly";

function GridRowsAndColumns({
  onCropGrid,
  rows,
  columns,
  onRowsChange,
  onRowsFieldValueChange,
  onColumnsChange,
  onColumnsFieldValueChange,
  expandable,
  onToggleExpand,
  isExpanded,
  editableGrid,
  projectType,
}) {
  if (projectType === "GB4") return <></>;

  return (
    <div className="ir-form graphical-tool__grid-rows-and-columns ms-auto">
      <div className="row align-items-end">
        {editableGrid ? (
          <Editable
            onCropGrid={onCropGrid}
            rows={rows}
            columns={columns}
            onRowsChange={onRowsChange}
            onRowsFieldValueChange={onRowsFieldValueChange}
            onColumnsChange={onColumnsChange}
            onColumnsFieldValueChange={onColumnsFieldValueChange}
            expandable={expandable}
            onToggleExpand={onToggleExpand}
            isExpanded={isExpanded}
          />
        ) : (
          <ViewOnly rows={rows} columns={columns} />
        )}
      </div>
    </div>
  );
}

GridRowsAndColumns.propTypes = {
  rows: PropTypes.string.isRequired,
  columns: PropTypes.string.isRequired,
  onRowsChange: PropTypes.func.isRequired,
  onColumnsChange: PropTypes.func.isRequired,
  onRowsFieldValueChange: PropTypes.func.isRequired,
  onColumnsFieldValueChange: PropTypes.func.isRequired,
  onCropGrid: PropTypes.func.isRequired,
  expandable: PropTypes.bool,
  onToggleExpand: PropTypes.func,
  isExpanded: PropTypes.bool,
  editableGrid: PropTypes.bool.isRequired,
  projectType: PropTypes.string.isRequired,
};

export default React.memo(GridRowsAndColumns);
