import { Controller } from "@hotwired/stimulus";
import { flashlight } from "../../../helpers/animate";

export default class extends Controller {
  static targets = [
    "buildingHeightField",
    "roofSlopeSelect",
    "fireSetbackField",
    "parapetHeightInField",
    "parapetThicknessInField",
    "hasRoofingInformationRadioYes",
    "hasRoofingInformationRadioNo",
    "roofMaterialSelect",
    "roofManufacturerSelect",
    "colorSelect",
    "thicknessSelect",
    "projectSeismicDesignField",
    "projectSetbackField",
    "setbackField",
    "seismicDesignField",
    "previousValueMessage",
    "projectSupplementalChassisField",
    "supplementalChassisRadioYes",
    "supplementalChassisRadioNo",
  ];

  connect() {
    this.defaultData = JSON.parse(this.element.dataset.projectDetail);
  }

  applyDefaults(event) {
    event.preventDefault();

    this.buildingHeightFieldTarget.value = this.defaultData.buildingHeight;
    this.roofSlopeSelectTarget.value = this.defaultData.roofSlope;
    this.fireSetbackFieldTarget.value = this.defaultData.fireSetback;
    this.parapetHeightInFieldTarget.value = this.defaultData.parapetHeightIn;
    this.parapetThicknessInFieldTarget.value = this.defaultData.parapetThicknessIn;
    if (this.defaultData.hasRoofingInformation) {
      this.hasRoofingInformationRadioYesTarget.click();
    } else {
      this.hasRoofingInformationRadioNoTarget.click();
    }
    this.roofMaterialSelectTarget.value = this.defaultData.roofMaterial;

    const changeEvent = new CustomEvent("change", { bubbles: true });
    this.roofMaterialSelectTarget.dispatchEvent(changeEvent);

    this.roofManufacturerSelectTarget.value = this.defaultData.roofManufacturer;
    this.colorSelectTarget.value = this.defaultData.color;
    this.thicknessSelectTarget.value = this.defaultData.thickness;

    this.projectSeismicDesignFieldTarget.value = this.defaultData.seismicDesign;
    this.projectSetbackFieldTarget.value = this.defaultData.setback;
    this.projectSupplementalChassisFieldTarget.value = this.defaultData.supplementalChassis;

    if (this.hasSetbackFieldTarget) {
      this.setbackFieldTarget.value = this.defaultData.setback;
      flashlight(this.setbackFieldTarget);
    }

    if (this.hasSeismicDesignFieldTarget) {
      this.seismicDesignFieldTarget.value = this.defaultData.seismicDesign;
      flashlight(this.seismicDesignFieldTarget);
    }
    if (this.hasSupplementalChassisRadioYesTarget) {
      if (this.defaultData.supplementalChassis) {
        this.supplementalChassisRadioYesTarget.click();
      } else {
        this.supplementalChassisRadioNoTarget.click();
      }
    }
  }

  setbackInvalidated() {
    this.projectSetbackFieldTarget.value = "";
    if (this.hasSetbackFieldTarget) {
      const originalValue = this.setbackFieldTarget.dataset.originalValue;
      const currentValue = this.setbackFieldTarget.value;

      console.log({ originalValue, currentValue });

      if (originalValue !== currentValue) {
        this.setbackFieldTarget.value = originalValue;
        flashlight(this.setbackFieldTarget);
        this.previousValueMessageTarget.classList.remove("d-none");
      }
    }
  }
}
