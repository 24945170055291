import { dump } from "./grid-serializer";
import { linesAreIntersecting } from "../../../helpers/geometry";
import { calculateSelectionMarqueeSizeAndPosition } from "./marquee-size-and-position-calculator";
import { GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR } from "../../../helpers/graphical-tool-helpers";
import {
  contourCodeToDirections,
  contourCodeGenerator,
  CONTOUR_DIRECTION_EAST,
  CONTOUR_DIRECTION_SOUTH,
  CONTOUR_DIRECTION_WEST,
  isContourEast,
  isContourSouth,
  isContourWest,
} from "../../../helpers/contour";

export const GRID_CELL_ACTIVE = "1";
export const GRID_CELL_INACTIVE = "0";

export const generateEmptyGridString = ({ rows, columns }) => {
  const gridArray = generateEmptyGridArray({ rows, columns });
  return dump(gridArray);
};

export const generateEmptyGridArray = ({ rows, columns }) => {
  return new Array(rows).fill(new Array(columns).fill("0"));
};

export const gridCellInMarqueeSelection = ({
  itemX,
  itemY,
  itemWidth,
  itemHeight,
  marqueeX,
  marqueeY,
  marqueeWidth,
  marqueeHeight,
}) => {
  const itemBottomX = itemX + itemWidth;
  const itemBottomY = itemY + itemHeight;
  const marqueeBottomX = marqueeX + marqueeWidth;
  const marqueeBottomY = marqueeY + marqueeHeight;

  const marqueeLines = [
    {
      p1: { x: marqueeX, y: marqueeY },
      p2: { x: marqueeBottomX, y: marqueeY },
    },
    {
      p1: { x: marqueeBottomX, y: marqueeY },
      p2: { x: marqueeBottomX, y: marqueeBottomY },
    },
    {
      p1: { x: marqueeBottomX, y: marqueeBottomY },
      p2: { x: marqueeX, y: marqueeBottomY },
    },
    {
      p1: { x: marqueeX, y: marqueeBottomY },
      p2: { x: marqueeX, y: marqueeY },
    },
  ];

  const itemLines = [
    {
      p1: { x: itemX, y: itemY },
      p2: { x: itemBottomX, y: itemY },
    },
    {
      p1: { x: itemBottomX, y: itemY },
      p2: { x: itemBottomX, y: itemBottomY },
    },
    {
      p1: { x: itemBottomX, y: itemBottomY },
      p2: { x: itemX, y: itemBottomY },
    },
    {
      p1: { x: itemX, y: itemBottomY },
      p2: { x: itemX, y: itemY },
    },
  ];

  // does the marquee overlap the item at all
  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < 4; j++) {
      if (linesAreIntersecting(marqueeLines[i].p1, marqueeLines[i].p2, itemLines[j].p1, itemLines[j].p2)) {
        return true;
      }
    }
  }

  // does the marquee completely wraps the item
  if (itemX >= marqueeX && itemY >= marqueeY && itemBottomX <= marqueeBottomX && itemBottomY <= marqueeBottomY) {
    return true;
  }

  return false;
};

export const updateGridArraySelectionFromDragAndDrop = (
  dragAndDrop,
  gridPositions,
  gridArray,
  contourGridArray,
  cellWidth,
  cellHeight,
  selectedToolbarItem,
  editorMode,
  contourPaintMode,
) => {
  const selectionMarquee = calculateSelectionMarqueeSizeAndPosition(dragAndDrop, window);

  const newGridArray = gridArray.map((row) => [...row]);
  const newContourGridArray = contourGridArray.map((row) => [...row]);
  const contourPaintDirections = contourCodeToDirections(contourPaintMode);

  gridPositions.forEach((row, i) => {
    row.forEach((item, j) => {
      if (
        gridCellInMarqueeSelection({
          itemX: item.x,
          itemY: item.y,
          itemWidth: cellWidth,
          itemHeight: cellHeight,
          marqueeX: selectionMarquee.x,
          marqueeY: selectionMarquee.y,
          marqueeWidth: selectionMarquee.width,
          marqueeHeight: selectionMarquee.height,
        })
      ) {
        if (editorMode === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR) {
          contourPaintDirections.forEach((paintDirection) => {
            if (
              newGridArray[i][j] === GRID_CELL_ACTIVE &&
              validContourModification(gridArray, newContourGridArray, i, j, selectedToolbarItem, paintDirection)
            ) {
              newContourGridArray[i][j] = updateContourGridCellValue(
                newContourGridArray[i][j],
                selectedToolbarItem,
                paintDirection,
              );
            }
          });
        } else {
          newGridArray[i][j] = updateGridCellValue(newGridArray[i][j], selectedToolbarItem);
          if (newGridArray[i][j] === GRID_CELL_INACTIVE) {
            newContourGridArray[i][j] = contourCodeGenerator(false, false, false);
          } else {
            clearIllegalContourInAdjacentCells(newContourGridArray, i, j);
          }
        }
      }
    });
  });

  return [newGridArray, newContourGridArray];
};

export const updateGridCellValue = (currentValue, selectedToolbarItem) => {
  switch (selectedToolbarItem) {
    case "toggle":
      return currentValue === "1" ? GRID_CELL_INACTIVE : GRID_CELL_ACTIVE;
    case "add":
      return GRID_CELL_ACTIVE;
    case "remove":
      return GRID_CELL_INACTIVE;
    default:
      return currentValue;
  }
};

export const validContourModification = (
  gridArray,
  contourGridArray,
  row,
  column,
  selectedToolbarItem,
  paintDirection,
) => {
  const currentContourValue = contourGridArray[row][column];

  switch (selectedToolbarItem) {
    case "toggle":
      if (cellHasContourInDirection(currentContourValue, paintDirection)) {
        return cellHasContourInDirection(currentContourValue, paintDirection);
      } else {
        return isContourModificationValid(gridArray, row, column, paintDirection);
      }
    case "add":
      // already has contour on that side
      if (cellHasContourInDirection(currentContourValue, paintDirection)) return false;

      return isContourModificationValid(gridArray, row, column, paintDirection);
    case "remove":
      return cellHasContourInDirection(currentContourValue, paintDirection);
  }
};

const cellHasContourInDirection = (currentValue, paintDirection) => {
  let isWest = isContourWest(currentValue);
  let isEast = isContourEast(currentValue);
  let isSouth = isContourSouth(currentValue);

  return (
    (paintDirection === CONTOUR_DIRECTION_WEST && isWest) ||
    (paintDirection === CONTOUR_DIRECTION_SOUTH && isSouth) ||
    (paintDirection === CONTOUR_DIRECTION_EAST && isEast)
  );
};

const isContourModificationValid = (gridArray, row, column, paintDirection) => {
  let neighborRow = row;
  let neighborColumn = column;

  switch (paintDirection) {
    case CONTOUR_DIRECTION_WEST:
      neighborColumn = column - 1;
      break;
    case CONTOUR_DIRECTION_SOUTH:
      neighborRow = row + 1;
      break;
    case CONTOUR_DIRECTION_EAST:
      neighborColumn = column + 1;
      break;
  }

  if (isBeyondEdge(gridArray, neighborRow, neighborColumn)) return true;

  const neighbor = gridArray[neighborRow][neighborColumn];
  return neighbor === GRID_CELL_INACTIVE;
};

const isBeyondEdge = (gridArray, neighborRow, neighborColumn) => {
  if (neighborRow < 0 || neighborColumn < 0) return true;

  // off the bottom of the rows
  if (neighborRow >= gridArray.length) return true;

  // all rows have the same number of columns, so just check against the first
  return neighborColumn >= gridArray[0].length;
};

export const updateContourGridCellValue = (currentValue, selectedToolbarItem, paintDirection) => {
  let isWest = isContourWest(currentValue);
  let isEast = isContourEast(currentValue);
  let isSouth = isContourSouth(currentValue);

  switch (selectedToolbarItem) {
    case "toggle":
      isWest = paintDirection === CONTOUR_DIRECTION_WEST ? !isWest : isWest;
      isEast = paintDirection === CONTOUR_DIRECTION_EAST ? !isEast : isEast;
      isSouth = paintDirection === CONTOUR_DIRECTION_SOUTH ? !isSouth : isSouth;
      break;
    case "add":
      isWest = paintDirection === CONTOUR_DIRECTION_WEST ? true : isWest;
      isEast = paintDirection === CONTOUR_DIRECTION_EAST ? true : isEast;
      isSouth = paintDirection === CONTOUR_DIRECTION_SOUTH ? true : isSouth;
      break;
    case "remove":
      isWest = paintDirection === CONTOUR_DIRECTION_WEST ? false : isWest;
      isEast = paintDirection === CONTOUR_DIRECTION_EAST ? false : isEast;
      isSouth = paintDirection === CONTOUR_DIRECTION_SOUTH ? false : isSouth;
      break;
  }
  return contourCodeGenerator(isWest, isEast, isSouth);
};

export const gridIsCropable = (gridArray) => {
  const rowLength = gridArray[0].length - 1;
  const columnLength = gridArray.length - 1;

  const borderSets = {
    topRow: gridArray[0],
    bottomRow: gridArray[columnLength],
    leftColumn: gridArray.map((r) => r[0]),
    rightColumn: gridArray.map((r) => r[rowLength]),
  };

  return Object.values(borderSets).some((borderSet) => borderSet.every((c) => c === EMPTY_CHAR));
};

export const clearIllegalContourInAdjacentCells = (contourGridArray, row, column) => {
  if (!isBeyondEdge(contourGridArray, row - 1, column)) {
    clearContour(contourGridArray, row - 1, column, CONTOUR_DIRECTION_SOUTH);
  }

  if (!isBeyondEdge(contourGridArray, row, column - 1)) {
    clearContour(contourGridArray, row, column - 1, CONTOUR_DIRECTION_EAST);
  }

  if (!isBeyondEdge(contourGridArray, row, column + 1)) {
    clearContour(contourGridArray, row, column + 1, CONTOUR_DIRECTION_WEST);
  }
};

const clearContour = (contourGridArray, row, column, paintDirection) => {
  const currentValue = contourGridArray[row][column];
  contourGridArray[row][column] = updateContourGridCellValue(currentValue, "remove", paintDirection);
};

export const calculateCrop = (gridArray) => {
  const [columnStartIndex, columnStopIndex] = cropColumnsStartAndStopIndexes(gridArray);
  const [rowStartIndex, rowStopIndex] = firstAndLastIndexesForGridString(convertRowsWithPanelsToGridString(gridArray));
  return { rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex };
};

export const cropGrid = (gridArray, cropRowsAndColumns) => {
  const croppedGridArray = cropRows(cropColumns(gridArray, cropRowsAndColumns), cropRowsAndColumns);
  return croppedGridArray.length === 0 ? gridArray : croppedGridArray;
};

const cropColumns = (gridArray, cropRowsAndColumns) => {
  const { columnStartIndex, columnStopIndex } = cropRowsAndColumns;
  return gridArray.map((row) => row.slice(columnStartIndex, columnStopIndex + 1));
};

const cropColumnsStartAndStopIndexes = (gridArray) => {
  const firstIndexes = [];
  const lastIndexes = [];

  gridArray.forEach((row) => {
    const [firstIndex, lastIndex] = firstAndLastIndexesForGridString(row.join(""));
    if (firstIndex !== -1) firstIndexes.push(firstIndex);
    if (lastIndex !== -1) lastIndexes.push(lastIndex);
  });

  return [Math.min(...firstIndexes), Math.max(...lastIndexes)];
};

const EMPTY_CHAR = "0";
const NON_EMPTY_CHAR = "1";

const firstAndLastIndexesForGridString = (gridString) => {
  const firstIndex = gridString.indexOf(NON_EMPTY_CHAR);
  const lastIndex = gridString.lastIndexOf(NON_EMPTY_CHAR);
  return [firstIndex, lastIndex];
};

const cropRows = (gridArray, cropRowsAndColumns) => {
  const { rowStartIndex, rowStopIndex } = cropRowsAndColumns;
  return gridArray.slice(rowStartIndex, rowStopIndex + 1);
};

const convertRowsWithPanelsToGridString = (gridArray) => {
  return gridArray.map((row) => (row.some((ri) => ri === GRID_CELL_ACTIVE) ? NON_EMPTY_CHAR : EMPTY_CHAR)).join("");
};

export const clearGrid = (gridArray) => {
  return gridArray.map((row) => row.map((_) => GRID_CELL_INACTIVE));
};

export const shiftPanelsOnGrid = (gridArray, direction) => {
  if (direction === "left") {
    return gridArray.map((row) => [...row, "0"].slice(1));
  }

  if (direction === "right") {
    return gridArray.map((row) => ["0", ...row].slice(0, -1));
  }

  const rowLength = gridArray[0].length;
  const blankRow = Array(rowLength).fill("0");

  if (direction === "up") {
    return [...gridArray, blankRow].slice(1);
  }

  if (direction === "down") {
    return [blankRow, ...gridArray].slice(0, -1);
  }

  return gridArray;
};

export const invalidContourDesign = (gridArray, contourGridArray) => {
  for (let row = 0; row < gridArray.length; row++) {
    for (let column = 0; column < gridArray[row].length; column++) {
      if (gridArray[row][column] === GRID_CELL_INACTIVE && contourGridArray[row][column] !== GRID_CELL_INACTIVE) {
        return true;
      }
    }
  }
  return false;
};
