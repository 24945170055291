import { Controller } from "@hotwired/stimulus";

// Our dialogs are are written to the page and then moved with JavaScript so they
// are direct children of the body tag.  This is useful for consistent CSS positioning.
// However, it results in Stimulus connecting twice, which we don't want.
//
// Usage:
// Instead of extending the Controller from Stimulus, Import and extend from this. Then
// in your connect method, add the following guard clause before your connection logic:
// if (!this.isOkayToConnect) return;
export default class extends Controller {
  get isOkayToConnect() {
    // If this is not in a dialog then connection the first time is okay
    // (it's only going to happen once)
    if (this.isInDialog) this.element.dataset.allowConnect = true;

    // We're in a dialog and don't want to connect the first time
    if (this.element.dataset.allowConnect === undefined) {
      this.element.dataset.allowConnect = true;
      return false;
    }

    return true;
  }

  get isInDialog() {
    return this.element.closest(".ir-dialog__container") === null;
  }
}
