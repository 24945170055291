import { polygonsAsPointListsIntersect } from "../../da/map/legality-checkers/polygon-helpers";
import { pointInPolygon } from "../../helpers/point-in-polygon";

export function objectsOverlap(
  object1InOpenLayersCoordinates,
  object1AsPoints,
  object2InOpenLayersCoordinates,
  object2AsPoints,
) {
  return (
    objectHasAVertexInsideOtherObject(object1InOpenLayersCoordinates, object2InOpenLayersCoordinates) ||
    objectHasAVertexInsideOtherObject(object2InOpenLayersCoordinates, object1InOpenLayersCoordinates) ||
    edgesIntersect(object1AsPoints, object2AsPoints)
  );
}

export function polygonHasVertexInsideOtherPolygon(
  polygonInOpenLayersCoordinates,
  possibleContainerInOpenLayersCoordinates,
) {
  // wrap the possible container in [] because pointInPolygon expects rings
  const possibleContainerWrappedAsRing = [possibleContainerInOpenLayersCoordinates];

  return polygonInOpenLayersCoordinates.some((vertex) => {
    return pointInPolygon(vertex, possibleContainerWrappedAsRing);
  });
}

// Internal methods
function objectHasAVertexInsideOtherObject(object1InOpenLayersCoordinates, object2InOpenLayersCoordinates) {
  return polygonHasVertexInsideOtherPolygon(object1InOpenLayersCoordinates, object2InOpenLayersCoordinates);
}

function edgesIntersect(object1AsPoints, object2AsPoints) {
  return polygonsAsPointListsIntersect(object1AsPoints, object2AsPoints);
}
