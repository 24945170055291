import { getParent, types } from "mobx-state-tree";

import WithLatLngPoints from "../../da/models/with-lat-lng-points";
import WithCartesianPoints from "../../da/models/with-cartesian-points";
import WithDirtyTracking from "../../da/models/with-dirty-tracking";
import CartesianModel from "../../da/models/cartesian-model";

import { OBSTRUCTION_BUFFER_DATA_TYPE } from "../../da/map/data-types";

const ObstructionBufferModelBase = types
  .model("ObstructionBufferModel", {
    globalCartesianOffset: types.maybe(CartesianModel),
  })
  .views((self) => ({
    get dataType() {
      return OBSTRUCTION_BUFFER_DATA_TYPE;
    },
    get obstruction() {
      return getParent(self, 1);
    },
    get uuid() {
      return this.obstruction.uuid;
    },
    get identifier() {
      return `Buffer_${self.obstruction.identifier}`;
    },
  }))
  .actions((self) => ({
    setGlobalCartesianOffset(delta) {
      self.globalCartesianOffset = delta;
    },
  }));

export function obstructionBufferPersistenceData(obstructionBuffer) {
  if (obstructionBuffer) {
    return {
      lat_lng_points: obstructionBuffer.latLngPointsToString,
      cartesian_points: obstructionBuffer.cartesianPointsToString,
      global_cartesian_offset: obstructionBuffer.globalCartesianOffset?.toArray || [0, 0],
    };
  } else {
    return {
      lat_lng_points: "[]",
      cartesian_points: "[]",
      global_cartesian_offset: [0, 0],
    };
  }
}

const ObstructionBufferModel = types.compose(
  ObstructionBufferModelBase,
  WithLatLngPoints,
  WithCartesianPoints,
  WithDirtyTracking,
);
export default ObstructionBufferModel;
