import Feature from "ol/Feature";
import LineString from "ol/geom/LineString";
import Polygon from "ol/geom/Polygon";

export default class Base {
  constructor(controller) {
    this.controller = controller;
    this.mapManager = controller.mapManager;
  }

  add(feature) {
    this.feature = feature;
    this.mapManager.onClick(this.onClick);
  }

  remove() {
    this.mapManager.unClick(this.onClick);
    this.mapManager.rulersVectorSource.clear();
    this.feature = undefined;
    this.firstVertex = undefined;
    this.lastVertex = undefined;
  }

  onClick = (_event) => {
    this.rememberActiveVertices();
    this.drawRulersAndMarkers();
  };

  // rotate, zoom, or drag
  onMapChange = () => {
    this.drawRulersAndMarkers();
  };

  rememberActiveVertices() {
    const coordinates = this.feature.getGeometry().getLinearRing(0).getCoordinates();
    this.firstVertex = coordinates[0];

    if (coordinates.length >= 4) {
      // last coordinate = first coordinate repeated
      // penultimate coordinate = mouse position which might be slightly different from snapped drop
      // then array is 0 indexed
      this.lastVertex = coordinates[coordinates.length - 2 - 1];
    }
  }

  drawRulersAndMarkers() {
    this.mapManager.rulersVectorSource.clear();
    this.addRectangleAtVertices();
    this.addRulerAtVertex(this.firstVertex);
    this.addRulerAtVertex(this.lastVertex);
  }

  addRulerAtVertex(vertex) {
    if (!vertex) return;

    const [topBottomLine, leftRightLine] = this.rulerCoordinatesForVertex(vertex);

    let geometry = new LineString(topBottomLine);
    let feature = new Feature({ geometry, name: "ruleTopBottom" });
    this.mapManager.rulersVectorSource.addFeature(feature);

    geometry = new LineString(leftRightLine);
    feature = new Feature({ geometry, name: "ruleTopBottom" });
    this.mapManager.rulersVectorSource.addFeature(feature);
  }

  rulerCoordinatesForVertex(vertex) {
    const map = this.mapManager.map;
    const [sizeX, sizeY] = map.getSize();

    const vertexPixels = map.getPixelFromCoordinate(vertex);
    const [vertexX, vertexY] = vertexPixels;

    const topPixels = [vertexX, 0];
    const bottomPixels = [vertexX, sizeY];

    const leftPixels = [0, vertexY];
    const rightPixels = [sizeX, vertexY];

    const topCoordinates = map.getCoordinateFromPixel(topPixels);
    const bottomCoordinates = map.getCoordinateFromPixel(bottomPixels);
    const leftCoordinates = map.getCoordinateFromPixel(leftPixels);
    const rightCoordinates = map.getCoordinateFromPixel(rightPixels);

    return [
      [topCoordinates, bottomCoordinates],
      [leftCoordinates, rightCoordinates],
    ];
  }

  // In order to be able to snap to the corners where two rulers intersect, we needed
  // a polygon to be present. Otherwise Snap followed one edge or the other but ignored corners.
  // The raw OL coordinates ignore rotation. By switching to pixels and then back
  // the rectangle picks up the rotation present in the View
  addRectangleAtVertices() {
    if (!this.firstVertex || !this.lastVertex) return;

    const map = this.mapManager.map;

    const firstVertexPixels = map.getPixelFromCoordinate(this.firstVertex);
    const lastVertexPixels = map.getPixelFromCoordinate(this.lastVertex);

    const corner1Pixels = firstVertexPixels;
    const corner2Pixels = [firstVertexPixels[0], lastVertexPixels[1]];
    const corner3Pixels = lastVertexPixels;
    const corner4Pixels = [lastVertexPixels[0], firstVertexPixels[1]];

    const corner1 = map.getCoordinateFromPixel(corner1Pixels);
    const corner2 = map.getCoordinateFromPixel(corner2Pixels);
    const corner3 = map.getCoordinateFromPixel(corner3Pixels);
    const corner4 = map.getCoordinateFromPixel(corner4Pixels);

    const outerRing = [corner1, corner2, corner3, corner4, corner1];

    const geometry = new Polygon([outerRing]);
    const feature = new Feature({ geometry, name: "intersectionMarker" });
    this.mapManager.rulersVectorSource.addFeature(feature);
  }
}
