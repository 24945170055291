import { toLonLat } from "ol/proj";
import { setCartesianPointsForModelRelativeToOrigin } from "../../../da/map/ol-geometry";

export function ensureSetbackOuterRingRemainsUnmodified(mapManager, setbackFeature) {
  const roofPlaneUuid = setbackFeature.get("roofPlaneUuid");
  const roofPlaneFeature = mapManager.roofPlanesVectorSource
    .getFeatures()
    .find((rpf) => rpf.get("uuid") === roofPlaneUuid);
  const roofPlaneOuterRingCoordinates = roofPlaneFeature.getGeometry().getLinearRing(0).getCoordinates();

  const setbackFeatureGeometry = setbackFeature.getGeometry();
  const setbackFeatureCoordinates = setbackFeatureGeometry.getCoordinates();
  setbackFeatureCoordinates[0] = roofPlaneOuterRingCoordinates;
  setbackFeatureGeometry.setCoordinates(setbackFeatureCoordinates);
}

export function updateRoofSectionsFromModifiedSetbackCoordinates(project, setbackFeature) {
  const setbackCoordinates = setbackFeature.getGeometry().getCoordinates();
  const innerRings = setbackCoordinates.slice(1, setbackCoordinates.length);
  const roofPlane = project.getRoofPlane(setbackFeature.get("roofPlaneUuid"));
  const originLonLat = project.detail.originLatLng.toLonLat;

  roofPlane.notDeletedRoofSections.forEach((roofSection, i) => {
    const newCoordinates = innerRings[i].map((c) => {
      const lonLat = toLonLat(c);
      return { lat: lonLat[1], lng: lonLat[0] };
    });
    roofSection.setLatLngPoints(newCoordinates);
    setCartesianPointsForModelRelativeToOrigin(originLonLat, roofSection);
  });
}
