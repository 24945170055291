import { Controller } from "@hotwired/stimulus";
import { track } from "../../helpers/app";

// Used on the documents and resources pages to download cut sheets
export default class extends Controller {
  static targets = ["selectList"];

  static values = {
    trackingCategory: String,
    files: Array,
  };
  /*
  The array of files are generated with: app/views/s3_files/_s3_file.json.jbuilder
  Each file is a hash of S3 data from the backend.  The keys used here are:
  ["url", "name", "isVisible"]
  */

  connect() {
    this.files = this.filesValue.filter((f) => f.isVisible);
    this.#addFileOptionsToSelect();
  }

  download(event) {
    event.preventDefault();

    const fileUrl = this.selectListTarget.value;

    if (fileUrl === "") {
      alert("Please select a cut sheet to download.");
      return;
    }

    track({ action: "Document Download", category: this.trackingCategoryValue, label: fileUrl });

    setTimeout(() => {
      window.open(fileUrl, "_blank");
    }, 250);
  }

  #addFileOptionsToSelect() {
    this.files.forEach((file) => {
      const option = `<option value="${file.url}">${file.name}</option>`;
      this.selectListTarget.innerHTML += option;
    });
  }
}
