import Select from "ol/interaction/Select";
import { click } from "ol/events/condition";

import applyPanelModeToArrayFeature from "./apply-panel-mode-to-array-feature";

export default class PaintSelectInteractionManager {
  constructor(controller) {
    this.controller = controller;

    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;

    this.roofSection = controller.roofSection;
  }

  add() {
    this.clearCurrentInteraction();

    this.currentPaintSelectInteraction = new Select({ condition: click, layers: [this.mapManager.arraysVectorLayer] });
    this.map.addInteraction(this.currentPaintSelectInteraction);
    this.currentPaintSelectInteraction.on("select", this.selectClick);
  }

  remove() {
    this.clearCurrentInteraction();
  }

  clearCurrentInteraction() {
    if (!this.currentPaintSelectInteraction) return;

    this.map.removeInteraction(this.currentPaintSelectInteraction);
    this.currentPaintSelectInteraction.un("select", this.selectClick);
    delete this.currentPaintSelectInteraction;
    this.currentPaintSelectInteraction = undefined;
  }

  selectClick = (event) => {
    const layout = this.roofSection.layout;

    event.selected.forEach((feature) => {
      applyPanelModeToArrayFeature(this.controller, layout, feature);
    });

    this.currentPaintSelectInteraction.getFeatures().clear();
    this.controller.markDirty();

    this.mapManager.dispatchAfterMapFeaturesRendering({ calledFrom: "PaintSelectInteractionManager#selectClick" });
  };
}
