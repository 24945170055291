import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";
import { addOptionsToSelect } from "../../helpers/form";

export default class extends Controller {
  static values = {
    ssProfilesToGaugeThickness: Object,
  };

  static targets = [
    "roofInfoContainer",
    "roofAttachmentSelect",
    "roofMaterialSelect",
    "standingSeamDetailContainer",
    "standingSeamGaugeThicknessSelect",
    "standingSeamHeight",
    "standingSeamProfileSelect",
    "standingSeamWidth",
    "defaultRafterSpacingLabel",
  ];

  get roofMaterial() {
    return this.roofMaterialSelectTarget.value;
  }

  get roofAttachment() {
    return this.roofAttachmentSelectTarget.value;
  }

  get standingSeamProfile() {
    return this.standingSeamProfileSelectTarget.value;
  }

  get isStandingSeamProfileEmpty() {
    return this.standingSeamProfile === "";
  }

  changeRoofMaterialFamily() {
    this.changeRoofMaterial();
  }

  changeRoofMaterial() {
    this.changeRoofAttachment();
  }

  changeRoofAttachment() {
    this.#toggleLynxFields();
    this.#changeSpacingLabel();
  }

  changeStandingSeamProfile() {
    const gaugeThicknesses = this.isStandingSeamProfileEmpty
      ? []
      : this.ssProfilesToGaugeThicknessValue[this.standingSeamProfile];
    addOptionsToSelect(gaugeThicknesses, this.standingSeamGaugeThicknessSelectTarget, true);
    this.standingSeamGaugeThicknessSelectTarget.disabled = this.isStandingSeamProfileEmpty;
  }

  #toggleLynxFields() {
    const show = this.roofAttachment === "lynx_ssrc";
    if (this.hasStandingSeamDetailContainerTarget) {
      this.standingSeamDetailContainerTargets.forEach((target) => animate.toggle(target, show));

      this.standingSeamGaugeThicknessSelectTarget.disabled = this.isStandingSeamProfileEmpty;

      if (!show) {
        this.standingSeamProfileSelectTarget.value = "";
        this.standingSeamGaugeThicknessSelectTarget.value = "";
        this.standingSeamHeightTarget.value = "";
        this.standingSeamWidthTarget.value = "";
      }
    }
  }

  #changeSpacingLabel() {
    const label = this.defaultRafterSpacingLabelTarget.innerHTML.toLowerCase();
    if (this.roofMaterial === "metal_standing_seam") {
      const newLabel = label.replace("rafter", "standing seam");
      this.defaultRafterSpacingLabelTarget.innerHTML = newLabel[0].toUpperCase() + newLabel.slice(1);
    } else {
      const newLabel = label.replace("standing seam", "rafter");
      this.defaultRafterSpacingLabelTarget.innerHTML = newLabel[0].toUpperCase() + newLabel.slice(1);
    }
  }
}
