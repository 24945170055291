import React, { Component } from "react";
import PropTypes from "prop-types";
import * as timePieces from "../../helpers/time-pieces";
import moment from "moment";

const CountdownSection = ({ value, label }) => {
  return (
    <div className="countdown-timer__part">
      <div className="countdown-timer__part__value" data-testid={`${value}-${label}`}>
        {value}
      </div>
      <div className="countdown-timer__part__label">{label}</div>
    </div>
  );
};

class CountdownTimer extends Component {
  static propTypes = {
    untilDateString: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      dateUntil: moment(props.untilDateString),
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.setTimeParts();
    setInterval(() => {
      this.setTimeParts();
    }, 1000);
  }

  setTimeParts() {
    const duration = timePieces.until(this.state.dateUntil);
    this.setState({
      years: duration.years,
      months: duration.months,
      days: duration.days,
      hours: duration.hours,
      minutes: duration.minutes,
      seconds: duration.seconds,
    });
  }

  render() {
    const { years, months, days, hours, minutes, seconds } = this.state;

    return (
      <div className="countdown-timer__container">
        {years > 0 && <CountdownSection label="years" value={years} />}
        {(years > 0 || months > 0) && <CountdownSection label="months" value={months} />}
        <CountdownSection label="days" value={days} />
        <CountdownSection label="hours" value={hours} />
        <CountdownSection label="minutes" value={minutes} />
        <CountdownSection label="seconds" value={seconds} />
      </div>
    );
  }
}

export default CountdownTimer;
