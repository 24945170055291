import { Controller } from "@hotwired/stimulus";
import anime from "animejs";
import { elementInfo } from "../../helpers/app";
import { scrollToRoofSection } from "../../helpers/da-globals";
import * as store from "../../helpers/local-store";

export default class extends Controller {
  static targets = ["menu"];

  static values = { projectType: String };

  hidingMenu = false;

  connect() {
    // Give the fixed header a chance to animate in before kicking things off
    window.setTimeout(() => {
      this.initializeScrollEventHandler();
    }, 500);

    this.element.controller = this;
  }

  disconnect() {
    window.removeEventListener("scroll", this.toggleFixedRoofSectionMenu);
  }

  initializeScrollEventHandler() {
    window.addEventListener("scroll", this.toggleFixedRoofSectionMenu);
    this.toggleFixedRoofSectionMenu();

    if (!this.isFirstStrip && this.floatingMenuDismissed && this.atLeastOneRoofSectionExists) {
      this.element.style.display = "block";
    }
  }

  get atLeastOneRoofSectionExists() {
    return document.querySelector("[data-identifier='da-roof-sections-list']").innerHTML.trim() !== "";
  }

  get isFirstStrip() {
    return this.element.dataset.hasOwnProperty("firstStrip");
  }

  get floatingMenuDismissed() {
    return store.get("da-hide-floating-roof-section-menu");
  }

  get headerHeight() {
    let height = elementInfo(this.headerElem).height;
    return height;
  }

  get headerElem() {
    return document.querySelector("[data-identifier='project-header-wrapper']");
  }

  toggleFixedRoofSectionMenu = (_) => {
    const roofSectionsContainer = document.querySelector("[data-identifier='da-roof-sections']");
    if (!roofSectionsContainer) return;
    if (!this.headerElem) {
      setTimeout(() => {
        this.toggleFixedRoofSectionMenu();
      }, 500);
      return;
    }

    const roofSectionContainerTop = roofSectionsContainer.getBoundingClientRect().top;
    const offsetHeight = roofSectionContainerTop + window.scrollY - this.headerHeight - 20;
    if (window.scrollY > offsetHeight) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
  };

  showMenu() {
    if (this.floatingMenuDismissed) return;
    if (!this.isFirstStrip) return;
    if (this.menuHasFixedClass) return;

    this.menuTarget.classList.add("da-roof-sections-menu--fixed");
    this.menuTarget.style.opacity = 0;

    const paddingBelowHeader = 15;
    const stop = this.headerHeight + paddingBelowHeader;
    const animationDistance = 40;
    const start = stop - animationDistance;

    anime({
      targets: this.menuTarget,
      opacity: 1,
      top: [`${start}px`, `${stop}px`],
      easing: "easeOutQuad",
      duration: 300,
    });
  }

  get menuHasFixedClass() {
    return this.menuTarget.classList.contains("da-roof-sections-menu--fixed");
  }

  hideMenu() {
    if (!this.menuHasFixedClass && !this.hidingMenu) return;
    this.hidingMenu = true;

    anime({
      targets: this.menuTarget,
      opacity: 0,
      easing: "easeOutQuad",
      duration: 200,
      complete: this.afterHideMenu,
    });
  }

  afterHideMenu = () => {
    this.menuTarget.style.opacity = 1;
    this.hidingMenu = false;
    this.menuTarget.classList.remove("da-roof-sections-menu--fixed");
  };

  linkJumpToRoofSection = (event) => {
    event.preventDefault();
    scrollToRoofSection(event.currentTarget.dataset.roofSectionId, this.projectTypeValue);
  };

  selectMenuJumpToRoofSection = (event) => {
    scrollToRoofSection(event.currentTarget.value, this.projectTypeValue);
  };

  close = (event) => {
    if (event) event.preventDefault();

    this.hideMenu();
    this.hideFloatingMenu(true);

    const roofSectionMenus = document.querySelectorAll("[data-controller='da--roof-section-menu']");

    Array.from(roofSectionMenus).forEach((menu) => {
      if (!menu.dataset.hasOwnProperty("firstStrip")) {
        menu.style.display = "block";
      }
    });
  };

  hideFloatingMenu(bool) {
    store.set("da-hide-floating-roof-section-menu", bool);
  }

  reOpenFloatingMenu = (event) => {
    event.preventDefault();
    this.hideFloatingMenu(false);
    this.element.style.display = "none";
    // scroll by a pixel to trigger the floating menu to come up for the
    // other roof section menu
    window.scrollTo({ top: window.pageYOffset - 1, behavior: "smooth" });
  };
}
