import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

import * as animate from "../../../helpers/animate";

export default class extends Controller {
  static targets = ["roofPlaneSelect", "distanceTextField", "toggleVisibilityIcon", "roofPlaneGuideLinesContainer"];

  connect() {
    this.element.controller = this;
    this.changeDistance = debounce(this.changeDistance, 300);

    // Need to give the map controller a chance to load
    setTimeout(() => {
      this.#showOnlySelectedRoofPlaneGuideLines();
    }, 50);
  }

  selectRoofPlane(event) {
    const identifier = event.currentTarget.value;

    this.roofPlaneGuideLinesContainerTargets.forEach((container) => {
      if (container.dataset.identifier === identifier) {
        animate.show(container, { display: "flex" });
      } else {
        animate.hide(container, { fadeOut: false });
      }
    });

    this.#showOnlySelectedRoofPlaneGuideLines();
  }

  changeDistance(event) {
    const field = event.target;
    const fieldValue = field.value.replace(/[^0-9.]/, "");
    if (field.value !== fieldValue) field.value = fieldValue;
    if (field.value === "") return;

    const segmentIndex = Number.parseInt(field.dataset.segmentIndex);
    const roofPlaneIdentifier = field.dataset.roofPlaneIdentifier;
    const distance = Number.parseFloat(fieldValue);
    this.#mapController.mapModelSynchronizer.setGuideLineDistance(roofPlaneIdentifier, segmentIndex, distance);
    this.#mapController.markDirty();
  }

  toggleVisibility(event) {
    const button = event.currentTarget;
    const segmentIndex = Number.parseInt(button.dataset.segmentIndex);

    const roofPlaneIdentifier = this.roofPlaneSelectTarget.value;
    const visible = this.#mapController.mapModelSynchronizer.toggleRoofPlaneGuideLineVisibility(
      roofPlaneIdentifier,
      segmentIndex,
    );

    const icon = button.querySelector("i");
    this.#toggleVisibilityIcon(icon, visible);

    this.#mapController.markDirty();
  }

  hideAll(_event) {
    const roofPlaneIdentifier = this.roofPlaneSelectTarget.value;
    this.#mapController.mapModelSynchronizer.hideAllRoofPlaneGuideLines(roofPlaneIdentifier);
    this.#toggleIconsForRoofPlane(roofPlaneIdentifier, false);
    this.#mapController.markDirty();
  }

  showAll(_event) {
    const roofPlaneIdentifier = this.roofPlaneSelectTarget.value;
    this.#mapController.mapModelSynchronizer.showAllRoofPlaneGuideLines(roofPlaneIdentifier);
    this.#toggleIconsForRoofPlane(roofPlaneIdentifier, true);
    this.#mapController.markDirty();
  }

  updateDistanceValues(project) {
    project.roofPlanes.forEach((rp) => {
      this.#distanceFieldsForRoofPlane(rp.identifier).forEach((field) => {
        const segmentIndex = Number.parseInt(field.dataset.segmentIndex);
        const guideLine = rp.guideLineForSegmentIndex(segmentIndex);
        field.value = guideLine.distance;
      });
    });
  }

  #distanceFieldsForRoofPlane(roofPlaneIdentifier) {
    return this.distanceTextFieldTargets.filter((dtf) => dtf.dataset.roofPlaneIdentifier === roofPlaneIdentifier);
  }

  #toggleVisibilityIcon(icon, visible) {
    if (visible) {
      icon.classList.remove("fa-eye-slash");
      icon.classList.add("fa-eye");
    } else {
      icon.classList.remove("fa-eye");
      icon.classList.add("fa-eye-slash");
    }
  }

  get #mapController() {
    return document.querySelector("[data-controller='bx--layout-editor--map-roof-sections']")[
      "bx--layout-editor--map-roof-sections"
    ];
  }

  #toggleIconsForRoofPlane(roofPlaneIdentifier, visible) {
    this.toggleVisibilityIconTargets.forEach((icon) => {
      if (icon.dataset.roofPlaneIdentifier === roofPlaneIdentifier) {
        this.#toggleVisibilityIcon(icon, visible);
      }
    });
  }

  #showOnlySelectedRoofPlaneGuideLines() {
    const roofPlaneIdentifier = this.roofPlaneSelectTarget.value;
    this.#mapController.mapModelSynchronizer.showOnlyRoofPlaneGuideLines(roofPlaneIdentifier);
    this.toggleVisibilityIconTargets.forEach((icon) => {
      const visible = icon.dataset.roofPlaneIdentifier !== roofPlaneIdentifier;
      this.#toggleIconsForRoofPlane(roofPlaneIdentifier, visible);
    });
  }
}
