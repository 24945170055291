import { OBSTRUCTION_DATA_TYPE } from "../../../../da/map/data-types";
import DaBase from "../../../../da/map/interaction-managers/translate/base";
import { removeObstructionBuffer, repositionObstruction } from "../../modification-helpers/obstruction";

export default class Obstructions extends DaBase {
  translateStartForFeature(oFeature) {
    if (oFeature.get("dataType") !== OBSTRUCTION_DATA_TYPE) return;

    removeObstructionBuffer(oFeature, this.mapModelSynchronizer, this.mapManager);
  }

  translateEndForFeature(oFeature) {
    if (oFeature.get("dataType") !== OBSTRUCTION_DATA_TYPE) return;

    repositionObstruction(oFeature, this.mapModelSynchronizer, this.mapManager);
  }
}
