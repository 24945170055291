import WithCaching from "../../../helpers/with-caching";
import GridCompressedSpaceThresholds from "./grid-compressed-space-thresholds";
import GridCellPositions from "./grid-cell-positions";
import GridDisplayScaleFactor from "./grid-display-scale-factor";
import GridNumbers from "./grid-numbers";
import GridDimensionMarkers, { GRID_DIMENSION_MARKERS_SPACING } from "./grid-dimension-markers";
import GridCompressedSpaceMarkers from "./grid-compressed-space-markers";
import GridSetbackOutlines from "./grid-setback-outlines";
import GridExtensionsOutline from "./grid-extensions-outline";
import GridPositionSnaps from "./grid-position-snaps";

export default class GridDisplay {
  constructor({
    realGrid,
    maxWidth,
    maxHeight,
    floorValues = true, // flooring alleviates sub-pixel display funkiness
  }) {
    Object.assign(this, WithCaching);

    this.realGrid = realGrid;
    this.maxWidth = maxWidth;
    this.maxHeight = maxHeight;

    this.floorValues = floorValues;
    this.rows = realGrid.rows;
    this.columns = realGrid.columns;
  }

  get containerPadding() {
    return this.cached("containerPadding", () => {
      const { hasHorizontalSetback, hasVerticalSetback } = this.realGrid;
      const defaultPadding = 20;
      const top = defaultPadding;
      const right = hasHorizontalSetback > 0 ? defaultPadding + GRID_DIMENSION_MARKERS_SPACING : defaultPadding;
      const bottom = hasVerticalSetback > 0 ? defaultPadding + GRID_DIMENSION_MARKERS_SPACING : defaultPadding;
      const left = defaultPadding;
      const horizontal = left + right;
      const vertical = top + bottom;
      return { top, right, bottom, left, horizontal, vertical };
    });
  }

  get extensionTop() {
    return this.scaledDisplayValue("extensionTop");
  }

  scaledDisplayValue(realGridAttribute) {
    return this.cached(realGridAttribute, () => {
      const value = this.realGrid[realGridAttribute] * this.scaleFactor;
      return this.displayValue(value);
    });
  }

  get extensionRight() {
    return this.scaledDisplayValue("extensionRight");
  }

  get extensionBottom() {
    return this.scaledDisplayValue("extensionBottom");
  }

  get extensionLeft() {
    return this.scaledDisplayValue("extensionLeft");
  }

  get setbackWidth() {
    return this.scaledDisplayValue("setbackWidth");
  }

  get scaleFactor() {
    return this.cached("scaleFactor", () => {
      return GridDisplayScaleFactor.calculate(this);
    });
  }

  displayValue(value) {
    if (this.floorValues) return Math.floor(value);
    return value;
  }

  get setbackHeight() {
    return this.scaledDisplayValue("setbackHeight");
  }

  get cellWidth() {
    return this.scaledDisplayValue("cellWidth");
  }

  get cellHeight() {
    return this.scaledDisplayValue("cellHeight");
  }

  get rowSpacing() {
    return this.scaledDisplayValue("rowSpacing");
  }

  get columnSpacing() {
    return this.scaledDisplayValue("columnSpacing");
  }

  get spaceAroundCellsTop() {
    return this.scaledDisplayValue("spaceAroundCellsTop");
  }

  get spaceAroundCellsRight() {
    return this.scaledDisplayValue("spaceAroundCellsRight");
  }

  get spaceAroundCellsBottom() {
    return this.scaledDisplayValue("spaceAroundCellsBottom");
  }

  get spaceAroundCellsLeft() {
    return this.scaledDisplayValue("spaceAroundCellsLeft");
  }

  get spaceAroundCellsHorizontal() {
    return this.cached("spaceAroundCellsHorizontal", () => {
      return this.spaceAroundCellsLeft + this.spaceAroundCellsRight;
    });
  }

  get spaceAroundCellsVertical() {
    return this.cached("spaceAroundCellsVertical", () => {
      return this.spaceAroundCellsTop + this.spaceAroundCellsBottom;
    });
  }

  get height() {
    return this.cached("height", () => {
      return (
        this.setbackHeight * 2 +
        this.rows * this.cellHeight +
        this.rowSpacing * (this.rows - 1) +
        this.containerPadding.vertical +
        this.spaceAroundCellsVertical +
        this.extensionTop +
        this.extensionBottom
      );
    });
  }

  get width() {
    return this.cached("width", () => {
      return (
        this.setbackWidth * 2 +
        this.columns * this.cellWidth +
        this.columnSpacing * (this.columns - 1) +
        this.containerPadding.horizontal +
        this.spaceAroundCellsHorizontal +
        this.extensionLeft +
        this.extensionRight
      );
    });
  }

  get arrayHardwareWidth() {
    return this.scaledDisplayValue("arrayHardwareWidth");
  }

  get arrayHardwareHeight() {
    return this.scaledDisplayValue("arrayHardwareHeight");
  }

  get cellPositions() {
    return this.cached("cellPositions", () => {
      return GridCellPositions.calculate(this);
    });
  }

  get horizontalCenteringOffset() {
    return this.cached("horizontalCenteringOffset", () => {
      const value = (this.maxWidth - this.width) / 2 - this.containerPadding.left / 2;
      return this.displayValue(value);
    });
  }

  get numbers() {
    return GridNumbers.calculate(this);
  }

  get dimensionMarkers() {
    return GridDimensionMarkers.calculate(this);
  }

  get setbackOutlines() {
    return this.cached("setbackOutlines", () => GridSetbackOutlines.calculate(this));
  }

  get extensionsOutline() {
    return this.cached("extensionsOutline", () => GridExtensionsOutline.calculate(this));
  }

  get roofOutline() {
    return this.cached("roofOutline", () => {
      if (this.setbackWidth > 0) return this.setbackOutlines.outer;
      if (this.extensionsOutline) return this.extensionsOutline;

      const firstCell = this.cellPositions[0][0];
      return {
        x: firstCell.x,
        y: firstCell.y,
        width: this.width - this.containerPadding.horizontal - 1,
        height: this.height - this.containerPadding.vertical - 1,
      };
    });
  }

  get positionSnaps() {
    return GridPositionSnaps.calculate(this);
  }

  get compressedSpaceMarkers() {
    return this.cached("compressedSpaceMarkers", () => GridCompressedSpaceMarkers.calculate(this));
  }

  get compressedSpaceThresholds() {
    return GridCompressedSpaceThresholds.calculate(this);
  }
}
