import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = ["setAttachedButton", "setBallastedButton", "attachedViewContainer", "ballastedViewContainer"];

  setCurrentView(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const currentView = button.dataset.viewName;

    this.toggleButtonActive(button, true);

    if (currentView === "attached") {
      this.ballastedViewContainerTarget.style.display = "none";
      animate.show(this.attachedViewContainerTarget);
      this.toggleButtonActive(this.setBallastedButtonTarget, false);
    } else {
      this.attachedViewContainerTarget.style.display = "none";
      animate.show(this.ballastedViewContainerTarget);
      this.toggleButtonActive(this.setAttachedButtonTarget, false);
    }
  }

  toggleButtonActive(button, bool) {
    button.closest("li").classList[bool ? "add" : "remove"]("active");
  }
}
