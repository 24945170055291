import { Controller } from "@hotwired/stimulus";
import { pointOverlapsElement } from "../helpers/app";

export default class extends Controller {
  static targets = ["button", "menu"];

  connect() {
    this.element.buttonMenuController = this;
    document.body.addEventListener("click", this.bodyClickClose.bind(this));
  }

  disconnect() {
    document.body.removeEventListener("click", this.bodyClickClose.bind(this));
  }

  open(event) {
    event.preventDefault();
    event.stopPropagation();

    this.hideOtherMenusOnPage();

    if (!this.menuTarget.classList.contains("ir-btn-menu--visible")) {
      this.menuTarget.classList.add("ir-btn-menu--visible");
    } else {
      this.close(event);
    }
  }

  bodyClickClose(event) {
    if (!this.menuTarget.classList.contains("ir-btn-menu--visible")) return;

    const overlapping = pointOverlapsElement({ x: event.clientX, y: event.clientY, element: this.menuTarget });
    if (overlapping) return;

    this.close(event);
  }

  close(_event) {
    this.menuTarget.classList.remove("ir-btn-menu--visible");
  }

  hideOtherMenusOnPage() {
    const menuContainers = document.querySelectorAll("[data-controller='components--ir-btn-menu']");
    menuContainers.forEach((menuContainer) => {
      const otherMenuController = menuContainer.buttonMenuController;
      if (otherMenuController !== this) otherMenuController.close();
    });
  }
}
