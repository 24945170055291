// This takes in a simplified rail group grid and shifts the selections by the shift amount.
// (For an explanation of the simplified grid see: app/javascript/pr/map/map-model-synchronizers/segments-rail-group.js#simplifiedGrid)
// For example if you have a grid like:
//
// "X" = Selected
// "0" = Not selected
// "| 0 |" = Panel
// "=" = Rail
//
// | X | 0 | = | 0 | 0 |
// | X | 0 | = | 0 | 0 |
//
// ... and you shift it by 2, you end up with:
//
// | 0 | 0 | = | X | 0 |
// | 0 | 0 | = | X | 0 |
export function shiftSimplifiedRailGroupSelection(simplifiedRailGroupGrid, shiftAmount) {
  if (shiftAmount === 0) return simplifiedRailGroupGrid;

  const consolidatedGroupRowLength = simplifiedRailGroupGrid[0].flatMap((a) => a).length;
  if (shiftAmount > consolidatedGroupRowLength) {
    shiftAmount = shiftAmount % consolidatedGroupRowLength;
  }

  if (shiftAmount < 0) {
    shiftAmount = consolidatedGroupRowLength + shiftAmount;
  }

  return simplifiedRailGroupGrid.map((railGroupRow) => {
    const consolidatedGroupRowArray = railGroupRow.flatMap((a) => a);

    const head = consolidatedGroupRowArray.slice(
      consolidatedGroupRowArray.length - shiftAmount,
      consolidatedGroupRowArray.length,
    );
    const tail = consolidatedGroupRowArray.slice(0, consolidatedGroupRowArray.length - shiftAmount);
    const shiftedUnifiedGroupArray = [...head, ...tail];

    const newRailGroupRow = [];
    let offsetIndex = 0;
    railGroupRow.forEach((segmentRow) => {
      const newShiftedSegmentRow = shiftedUnifiedGroupArray.slice(offsetIndex, offsetIndex + segmentRow.length);
      newRailGroupRow.push(newShiftedSegmentRow);
      offsetIndex += segmentRow.length;
    });

    return newRailGroupRow;
  });
}

// Thermal expansions can not exist outside of a rail group and there can not be more than one thermal expansion per railed gap.
export function clearOutAnyIllegalThermalExpansions(railedParentSegment, mapModelSynchronizer) {
  const railGroupSegments = railedParentSegment.railedGroup;
  const thermalExpansions = railedParentSegment.thermalExpansions;
  if (thermalExpansions.length === 0) return;

  const keepers = [];

  for (let i = 0; i < railGroupSegments.length - 1; i++) {
    const s1 = railGroupSegments[i];
    const s2 = railGroupSegments[i + 1];

    thermalExpansions
      .filter((te) => mapModelSynchronizer.isThermalExpansionBetweenSegments(te, s1, s2))
      .forEach((te, i) => {
        if (i === 0) keepers.push(te);
      });
  }

  thermalExpansions.forEach((te) => {
    if (keepers.includes(te)) return;

    railedParentSegment.deleteThermalExpansion(te);
  });
}
