import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleSelect(event) {
    const checkbox = event.currentTarget;
    const tr = checkbox.closest("tr");
    this.toggleTrStyle(checkbox, tr);
  }

  toggleTrStyle(checkbox, tr) {
    const meth = checkbox.checked ? "add" : "remove";
    tr.classList[meth]("selected");
  }

  toggleSelectAll(event) {
    const checkbox = event.currentTarget;
    const table = checkbox.closest("table");
    const trs = table.querySelectorAll("tbody tr");
    trs.forEach((tr) => {
      const trCheckbox = tr.querySelector("input[type=checkbox]");
      trCheckbox.checked = checkbox.checked;
      this.toggleTrStyle(trCheckbox, tr);
    });
  }
}
