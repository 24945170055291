import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = [
    "buildingNorthSouth",
    "enclosingNorthSouth",
    "buildingEastWest",
    "enclosingEastWest",
    "rectangularDisclosure",
  ];

  changedShape(event) {
    const buildingShapeSelect = event.target;
    const buildingShape = buildingShapeSelect.value;

    if (buildingShape === "complex") {
      animate.show(this.enclosingNorthSouthTarget);
      animate.show(this.enclosingEastWestTarget);
      this.buildingNorthSouthTarget.classList.add("d-none");
      this.buildingEastWestTarget.classList.add("d-none");
      this.rectangularDisclosureTarget.classList.add("d-none");
    } else {
      animate.show(this.buildingNorthSouthTarget);
      animate.show(this.buildingEastWestTarget);
      animate.show(this.rectangularDisclosureTarget);
      this.enclosingNorthSouthTarget.classList.add("d-none");
      this.enclosingEastWestTarget.classList.add("d-none");
    }
  }
}
