import { Controller } from "@hotwired/stimulus";
import * as animate from "../helpers/animate";

export default class extends Controller {
  static targets = ["countrySelect", "stateZipContainer"];

  connect() {
    this.showHideStateZip();
  }

  changedCountry(_event) {
    this.showHideStateZip();
  }

  showHideStateZip() {
    animate.toggle(this.stateZipContainerTarget, this.isCountryUsa, { display: "flex" });
  }

  get isCountryUsa() {
    const country =
      this.countrySelectTarget.tagName === "SELECT"
        ? this.countrySelectTarget.value
        : this.countrySelectTarget.textContent;
    return country === "US";
  }
}
