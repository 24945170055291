import Select from "ol/interaction/Select";
import { click } from "ol/events/condition";

import { selectedEaveSelectionLineStringStyle } from "../../styles/eave-selection";
import DaBaseSelectInteractionManager from "../../../../da/map/interaction-managers/select/base";
import { setRoofPlaneEaveValues } from "../../modification-helpers/roof-plane";
import { buildLengthCorrectedEdgeList } from "../../../../da/map/edge-list";

export default class SelectInteractionManager extends DaBaseSelectInteractionManager {
  get buildSelectInteraction() {
    return new Select({
      style: (feature) => selectedEaveSelectionLineStringStyle(feature, this.map),
      hitTolerance: 3,
      layers: [this.mapManager.roofPlaneLineStringsVectorLayer],
      // this improves responsiveness because select doesn't need to wait to
      // determine if the user single vs double clicked
      condition: click,
    });
  }

  selectClick = (event) => {
    if (event.selected.length === 0) return;

    const lineStringFeature = event.selected[0];
    const roofPlane = this.project.getRoofPlane(lineStringFeature.get("roofPlaneUuid"));
    const eaveEdgeIndex = lineStringFeature.get("lineNumber") - 1;
    setRoofPlaneEaveValues(roofPlane, eaveEdgeIndex);

    const roofPlaneFeature = this.mapModelSynchronizer.getFeatureForRoofPlane(roofPlane);
    const edgeList = buildLengthCorrectedEdgeList(roofPlaneFeature, this.controller);
    const eaveRotationAngleRadians = Math.PI - edgeList[0].rotationAngle;
    roofPlane.setEaveRotationAngleRadians(eaveRotationAngleRadians);

    this.controller.markDirty();
  };
}
