import { Controller } from "@hotwired/stimulus";
import axios from "axios";

import { showDialog, zIndexForDialog, isDialogVisible } from "./helper";
import * as store from "../../../helpers/local-store";
import * as api from "../../../helpers/api";
import { elementInfo } from "../../../helpers/app";

// Handles the behavior of opening an ir-dialog
export default class extends Controller {
  static values = {
    closeDialogIdentifier: String,
    introductionToMarkAsSeen: String,
    isCurrentUserPresent: Boolean, // Used for tracking introduction dismissals
    ignore: Boolean,
    ignoreIfOpenerValueEquals: String, // When the opener is a form element
    centerDialogOverOpener: { type: Boolean, default: true },
  };

  connect() {
    this.valueOnLastEnter = undefined;
  }

  shouldBlockOpen(openerElement) {
    if (this.hasIgnoreValue && this.ignoreValue) return true;
    if (this.hasIgnoreIfOpenerValueEqualsValue) return this.ignoreIfOpenerValueEqualsValue === openerElement.value;
    return false;
  }

  open(event) {
    event.preventDefault();
    event.stopPropagation();

    const dialog = this.dialogToOpen(event);
    if (!dialog) return;
    if (isDialogVisible(dialog)) return;

    let clickPosition = false;
    if (this.centerDialogOverOpenerValue) {
      if (event.type === "change") {
        const elemInfo = elementInfo(event.currentTarget);
        clickPosition = elemInfo.center;
      } else if (event.pageX !== undefined) {
        clickPosition = { x: event.pageX, y: event.pageY };
      }
    }

    dialog.focusOnClose = event.currentTarget;
    if (this.hasCloseDialogIdentifierValue) {
      const dialogToClose = document.querySelector(`[data-dialog-identifier="${this.closeDialogIdentifierValue}"]`);
      dialogToClose.style.display = "none";
    }

    const currentValue = event.currentTarget.value;
    this.valueOnLastEnter = currentValue;

    this.openDialog(dialog, clickPosition);

    if (this.hasIntroductionToMarkAsSeenValue) {
      this.markIntroductionAsSeen();
    }
  }

  openOnEnter(event) {
    const currentValue = event.currentTarget.value;

    if (event.key === "Enter" && currentValue !== this.valueOnLastEnter) {
      this.valueOnLastEnter = currentValue;
      this.open(event);
    }
  }

  dialogToOpen(event) {
    if (this.shouldBlockOpen(event.currentTarget)) return;
    if (event.currentTarget.classList.contains("disabled")) return;

    const dialogIdentifier = event.currentTarget.dataset.dialog;
    const dialog = document.querySelector(`[data-dialog-identifier="${dialogIdentifier}"]`);
    if (!dialog) throw `Dialog not found: ${dialogIdentifier}`;

    return dialog;
  }

  markIntroductionAsSeen() {
    store.set(this.introductionToMarkAsSeenValue, true);

    if (this.hasIsCurrentUserPresentValue && this.isCurrentUserPresentValue) {
      axios({
        method: "POST",
        headers: api.defaultHeaders(),
        url: "/introduction_dismissals",
        data: { introduction_name: this.introductionToMarkAsSeenValue },
      }).catch(api.errorHandler);
    }
  }

  openDialog = (dialog, clickPosition) => {
    // Restore default positioning in case the dialog has been dragged to a new position
    dialog.style.left = "50%";
    dialog.style.top = "20%";
    dialog.style.transform = "translateX(-50%)";

    dialog.style.zIndex = zIndexForDialog(dialog);

    let showDialogOpts = {};
    if (clickPosition) {
      showDialogOpts.showNearClickPosition = true;
      showDialogOpts.clickPosition = clickPosition;
    }

    showDialog(
      dialog,
      () => {
        if (dialog.dataset.autoFocusElementSelector !== undefined) this.focusFirstElement(dialog);
        const carouselContainer = dialog.querySelector(".ir-carousel");
        if (carouselContainer) {
          // If the dialog has a carousel, we may need to initialize it because
          // we can't initialize it while the dialog is hidden.
          carouselContainer.controller.initSiema();
        }
      },
      showDialogOpts,
    );
  };

  focusFirstElement(dialog) {
    const selector = dialog.dataset.autoFocusElementSelector;
    const element = dialog.querySelector(selector);
    if (!element) return;
    element.focus();
  }
}
