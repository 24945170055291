import { Controller } from "@hotwired/stimulus";
import throttle from "lodash/throttle";
import debounce from "lodash/debounce";

import { elementInfo } from "../../../helpers/app";
import { radiansToDegrees } from "../../../helpers/geometry";
import { valueInSteps } from "../../../helpers/app";

export default class extends Controller {
  static targets = [
    "degreesField",
    "degreesPointerImage",
    "degreesPointerArrowContainer",
    "degreesPointerArrow",
    "onlyThis",
    "allRsInRp",
    "allRs",
    "newProjectDefault",
  ];

  static outlets = ["bx--layout-editor--map-edit-array"];

  connect() {
    this.mapIdentifier = this.element.dataset.mapIdentifier;
    this.bxMapDomElement = document.querySelector(`[data-controller='${this.mapIdentifier}']`);

    this.throttledNotifyMapOfAzimuthUpdate = throttle(this.notifyMapOfAzimuthUpdate, 750, { leading: true });
    this.debouncedNotifyMapOfAzimuthUpdate = debounce(this.notifyMapOfAzimuthUpdate, 500);

    this.updateDegreePointerDisplay();

    // Add animation CSS after the page loads and we set the initial value
    setTimeout(() => {
      this.degreesPointerArrowContainerTarget.classList.add(
        "bx__layout-editor__azimuth-management__image__arrow--animated",
      );
    }, 500);

    this.dragging = false;
    this.fieldValue = this.degreesFieldTarget.value;
    this.element.controller = this;
  }

  disconnect() {
    this.dragRemoveDocumentEvents();
  }

  // the azimuth input is in a form to leverage existing styles, but we don't want it to submit
  // if they hit enter
  preventSubmit(event) {
    event.preventDefault();
  }

  onOnlyThis() {
    // Need the method because form creates an action pointing to it, but it
    // doesn't need to do anything since the base roof section was already updated
  }

  onAllRsInRp() {
    this.bxMapController.azimuthUpdated(this.currentRotationDegrees, this.allRsInRpTarget.checked, false, false);
  }

  onAllRs() {
    this.bxMapController.azimuthUpdated(this.currentRotationDegrees, false, this.allRsTarget.checked, false);
  }

  onNewProjectDefault() {
    this.bxMapController.azimuthUpdated(
      this.currentRotationDegrees,
      false,
      false,
      this.newProjectDefaultTarget.checked,
    );
  }

  updateDegreePointerDisplay() {
    this.degreesPointerArrowContainerTarget.setAttribute("transform", `rotate(${this.currentRotationDegrees} 34 34)`);
  }

  focusedField(_event) {
    if (this.hasBxLayoutEditorMapEditArrayOutlet) {
      this.bxLayoutEditorMapEditArrayOutlet.allowNudgingWithArrowKeys = false;
    }
  }

  blurredField(_event) {
    if (this.hasBxLayoutEditorMapEditArrayOutlet) {
      this.bxLayoutEditorMapEditArrayOutlet.allowNudgingWithArrowKeys = true;
    }
  }

  setFromRotation(event) {
    event.preventDefault();

    const mapRotation = radiansToDegrees(this.bxMapController.mapManager.rotation);
    const newDegrees = 180 - mapRotation;

    this.degreesFieldTarget.value = valueInSteps(newDegrees, 2, 0.01);
    this.updateDegreePointerDisplay();
    this.notifyMapOfAzimuthUpdate();
  }

  get currentRotationDegrees() {
    const degreesValue = this.degreesFieldTarget.value;
    const degrees = Number.parseFloat(degreesValue);

    if (Number.isNaN(degrees)) return 0;
    if (degrees >= 0 && degrees < 360) return degrees;

    // force value into range of 0 to 360
    const quotient = Math.floor(degrees / 360);
    const adjustment = -1 * quotient * 360;
    const final = degrees + adjustment;

    // hoop jumping to try to avoid nasty decimals due to float representations
    return Number.parseFloat(final.toFixed(2));
  }

  fieldValueChange(event) {
    const fieldValue = event.currentTarget.value;
    if (fieldValue === this.fieldValue) return;
    this.fieldValue = fieldValue;
    this.updateDegreePointerDisplay();
    this.debouncedNotifyMapOfAzimuthUpdate();
  }

  setAzimuthValue(newValue) {
    this.degreesFieldTarget.value = newValue;
    this.updateDegreePointerDisplay();
  }

  checkValue() {
    if (this.degreesFieldTarget.value === String(this.currentRotationDegrees)) return;

    this.degreesFieldTarget.value = this.currentRotationDegrees;
  }

  dragMouseDown(event) {
    event.preventDefault();
    this.dragging = true;
    this.dragAddDocumentEvents();
    this.degreesPointerArrowTarget.classList.add("bx__layout-editor__azimuth-management__image__arrow--active");
    this.degreesPointerArrowContainerTarget.classList.remove(
      "bx__layout-editor__azimuth-management__image__arrow--animated",
    );
  }

  imageCenterToEventPointAngle(ex, ey) {
    const {
      center: { x: cx, y: cy },
    } = elementInfo(this.degreesPointerImageTarget);

    const dy = ey - cy;
    const dx = ex - cx;
    let theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]

    // Translate to solar style map rotation degrees
    theta += 90;
    if (theta < 0) theta += 360;

    return theta;
  }

  dragAddDocumentEvents() {
    document.addEventListener("mousemove", this.dragMouseMove);
    document.addEventListener("mouseup", this.dragMouseUp);
  }

  dragMouseMove = (event) => {
    const newDegrees = this.imageCenterToEventPointAngle(event.clientX, event.clientY);
    this.degreesFieldTarget.value = valueInSteps(newDegrees, 1, 0.5);
    this.updateDegreePointerDisplay();
    this.throttledNotifyMapOfAzimuthUpdate();
  };

  dragMouseUp = (_event) => {
    this.dragging = false;
    this.throttledNotifyMapOfAzimuthUpdate();
    this.dragRemoveDocumentEvents();
    // Sometimes arrow was out of sync with value. We think mouse might have come up while transform was
    // still running. So make sure to sync arrow with rotation value at the end when mouse comes up.
    this.updateDegreePointerDisplay();
    this.degreesPointerArrowTarget.classList.remove("bx__layout-editor__azimuth-management__image__arrow--active");
    this.degreesPointerArrowContainerTarget.classList.add(
      "bx__layout-editor__azimuth-management__image__arrow--animated",
    );
  };

  dragRemoveDocumentEvents() {
    document.removeEventListener("mousemove", this.dragMouseMove);
    document.removeEventListener("mouseup", this.dragMouseUp);
  }

  notifyMapOfAzimuthUpdate = () => {
    let allRsInRp = true;
    let allRs = true;
    let newProjectDefault = true;

    if (this.hasAllRsInRpTarget && !this.allRsInRpTarget.checked) {
      allRsInRp = false;
    }
    if (this.hasAllRsTarget && !this.allRsTarget.checked) {
      allRs = false;
    }
    if (this.hasNewProjectDefaultTarget && !this.newProjectDefaultTarget.checked) {
      newProjectDefault = false;
    }

    this.bxMapController.azimuthUpdated(
      this.currentRotationDegrees,
      allRsInRp,
      allRs,
      newProjectDefault,
      this.dragging,
    );
  };

  get bxMapController() {
    return this.bxMapDomElement[this.mapIdentifier];
  }
}
