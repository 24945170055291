import Base from "../../../../da/map/interaction-managers/snap/base";

export default class Arrays extends Base {
  get mainTargetSource() {
    return this.mapManager.roofPlanesVectorSource;
  }

  get secondarySource() {
    return this.mapManager.setbacksVectorSource;
  }

  get tertiarySource() {
    return this.mapManager.panelsVectorSource;
  }

  get quaternarySource() {
    return this.mapManager.railsVectorSource;
  }
}
