import React from "react";
import PropTypes from "prop-types";

function CountMarker({ rows, columns, x, y }) {
  const styles = { left: `${x}px`, top: `${y}px` };

  return (
    <div className="graphical-tool__selection-marquee__row-col-marker" style={styles} data-testid="count-marker">
      {rows} x {columns}
    </div>
  );
}

CountMarker.propTypes = {
  rows: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export default CountMarker;
