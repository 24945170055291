import anime from "animejs";

// this allows us to call the function from a Rails JS "sprinkle" response
if (!("IR_GLOBALS" in window)) window.IR_GLOBALS = {};

export const show = (element, options = {}) => {
  if (getComputedStyle(element).display !== "none") return;

  const defaultOpts = {
    completedCallback: () => {},
    showOpacity: 1,
    display: "block",
    fadeIn: true,
    duration: 500,
  };
  const opts = { ...defaultOpts, ...options };

  element.classList.remove("d-none");
  if (!opts.fadeIn) return;

  element.style.display = opts.display;
  element.style.opacity = 0;

  anime({
    targets: element,
    opacity: opts.showOpacity,
    easing: "easeOutQuad",
    duration: opts.duration,
    complete: () => {
      opts.completedCallback();
    },
  });
};

export const hide = (element, options = {}) => {
  const defaultOpts = {
    completedCallback: () => {},
    fadeOut: true,
    duration: 250,
  };
  const opts = { ...defaultOpts, ...options };

  if (!opts.fadeOut) {
    element.classList.add("d-none");
    return;
  }

  anime({
    targets: element,
    opacity: 0,
    easing: "easeInQuad",
    duration: opts.duration,
    complete: () => {
      element.style.display = "none";
      opts.completedCallback();
    },
  });
};

export const toggle = (element, display, options = {}) => {
  display ? show(element, options) : hide(element, options);
};

export const flashlight = (selectorOrElementRef) => {
  let elements;
  if (typeof selectorOrElementRef === "string") {
    elements = Array.from(document.querySelectorAll(selectorOrElementRef));
  } else {
    elements = [selectorOrElementRef];
  }

  elements.forEach((element) => {
    if (element.classList.contains("flashlight")) {
      element.classList.remove("flashlight");
    }
    // if we're removing the class first, we need a slight pause in order
    // for the browser to run the animation again
    setTimeout(() => {
      element.classList.add("flashlight");
    }, 200);
  });
};

window.IR_GLOBALS.flashlight = flashlight;
