import { Feature } from "ol";
import { LineString } from "ol/geom";

import { CONTOUR_DATA_TYPE } from "../../../../../da/map/data-types";
import { isContourEast, isContourSouth, isContourWest } from "../../../../../helpers/contour";
import { cartesianPointsRelativeToOrigin } from "../../../../../da/map/ol-geometry";
import SimpleLatLng from "../../../../../da/map/models/simple-lat-lng";
import {
  latLngFromPixelCoordinate,
  latLngToCoordinate,
  pixelsFromLatLng,
  truncatePixelCoordinateDecimal,
} from "../../../../../da/map/ol-helpers";

export default class SegmentContourBuilder {
  constructor({ controller, segment }) {
    this.controller = controller;
    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;
    this.segment = segment;
    this.features = [];

    this.panelsOnSide = {
      left: this.segment.panelsOnSideLeft,
      right: this.segment.panelsOnSideRight,
      bottom: this.segment.panelsOnSideBottom,
    };
  }

  build() {
    this.segment.clearContours();

    const contourRuns = this.#contourRunsToRender;

    const features = contourRuns.map((contourRun) => {
      const origin = this.controller.project.detail.originLatLng.toLonLat;
      const latLngPoints = contourRun.latLngs.map((ll) => new SimpleLatLng(ll[0], ll[1]));
      const cartesianPoints = cartesianPointsRelativeToOrigin(origin, latLngPoints);
      const contourData = {
        segmentUuid: this.segment.uuid,
        side: contourRun.side,
        latLngPoints: latLngPoints.map((ll) => ll.toSimpleObject),
        cartesianPoints: cartesianPoints.map((cp) => cp.toSimpleObject),
      };
      const contour = this.segment.addContour(contourData);

      const coordinates = contourRun.latLngs.map((ll) => latLngToCoordinate(ll));
      const geometry = new LineString(coordinates);
      const feature = new Feature({ geometry });
      feature.set("segmentUuid", this.segment.uui);
      feature.set("dataType", CONTOUR_DATA_TYPE);
      feature.set("model", contour);
      return feature;
    });

    this.features = features;
  }

  get #contourRunsToRender() {
    let results = [];

    ["left", "right", "bottom"].forEach((side) => {
      let contourRuns = this.#getContiguousFeatureLatLngsOnSide(side);
      if (contourRuns.length === 0) return;

      const contourRunsChoppedResults = this.#removeAdjoinmentOverlapsOnSide(contourRuns, side);

      contourRunsChoppedResults.contourRunsLatLng.forEach((ll, i) => {
        results.push({ latLngs: ll, px: contourRunsChoppedResults.contourRunsPx[i], side });
      });
    });

    return results;
  }

  #removeAdjoinmentOverlapsOnSide(contourRuns, side) {
    const contourRunsLatLngs = contourRuns.map((cs) => cs.map((ll) => ll.toLatLng));
    const contourRunsPx = contourRunsLatLngs.map((cs) => cs.map((ll) => pixelsFromLatLng(this.map, ll)));
    const adjoinments = this.segment.contourLegalityAdjoinmentsOn(side);
    const adjoinmentsLatLng = adjoinments.map((a) => a.latLngPoints);
    if (adjoinments.length === 0) {
      return { contourRunsLatLng: contourRunsLatLngs, contourRunsPx: contourRunsPx };
    }

    const adjoinmentsPx = adjoinmentsLatLng.map((all) => all.map((ll) => pixelsFromLatLng(this.map, ll.toLatLng)));
    const results = this.#recursiveChops(contourRunsLatLngs, contourRunsPx, adjoinmentsLatLng, adjoinmentsPx, side);
    return results;
  }

  #recursiveChops(contourRunsLatLngs, contourRunsPx, adjoinmentsLatLng, adjoinmentsPx, side, recursionCount = 0) {
    const resultsLatLng = [];
    const resultsPx = [];
    let chopsOccurred = false;

    contourRunsLatLngs.forEach((contourRunLatLng, contoursIndex) => {
      if (chopsOccurred) {
        resultsLatLng.push(contourRunLatLng);
        resultsPx.push(contourRunsPx[contoursIndex]);
        return;
      }

      adjoinmentsPx.forEach((adjoinmentPx, adjoinmentsIndex) => {
        if (chopsOccurred) return;

        let choppingResult;
        if (["left", "right"].includes(side)) {
          choppingResult = this.#leftRightContourRunsCutFromAdjoinment(
            contourRunLatLng,
            contourRunsPx[contoursIndex],
            adjoinmentPx,
          );
        } else {
          choppingResult = this.#bottomContourRunsCutFromAdjoinment(
            contourRunLatLng,
            contourRunsPx[contoursIndex],
            adjoinmentPx,
          );
        }

        if (choppingResult.chopsOccurred) {
          chopsOccurred = true;
          choppingResult.segmentsLatLng.forEach((s) => resultsLatLng.push(s));
          choppingResult.segmentsPx.forEach((s) => resultsPx.push(s));
        } else if (adjoinmentsIndex === adjoinmentsPx.length - 1) {
          choppingResult.segmentsLatLng.forEach((s) => resultsLatLng.push(s));
          choppingResult.segmentsPx.forEach((s) => resultsPx.push(s));
        }
      });
    });

    if (recursionCount > 49) {
      console.log("runaway recursion");
      return result;
    }

    if (chopsOccurred) {
      return this.#recursiveChops(resultsLatLng, resultsPx, adjoinmentsLatLng, adjoinmentsPx, side, recursionCount + 1);
    } else {
      return { contourRunsLatLng: resultsLatLng, contourRunsPx: resultsPx };
    }
  }

  #leftRightContourRunsCutFromAdjoinment(contourRunLatLng, contourRunPx, adjoinmentPx) {
    const contourX = contourRunPx[0][0];

    const adjoinmentYs = adjoinmentPx.map((a) => a[1]).sort((a, b) => a - b);
    const [aY1, aY2] = adjoinmentYs;

    const aY1Compare = truncatePixelCoordinateDecimal(aY1);
    const aY2Compare = truncatePixelCoordinateDecimal(aY2);

    const contourYs = contourRunPx.map((c) => c[1]).sort((a, b) => a - b);
    const [cY1, cY2] = contourYs;

    const cY1Compare = truncatePixelCoordinateDecimal(cY1);
    const cY2Compare = truncatePixelCoordinateDecimal(cY2);

    const notOverlapping = cY2Compare <= aY1Compare || cY1Compare >= aY2Compare;
    if (notOverlapping) {
      return { segmentsLatLng: [contourRunLatLng], segmentsPx: [contourRunPx], chopsOccurred: false };
    }

    const contourCompletelyCoveredByAdjoinment = cY1Compare >= aY1Compare && cY2Compare <= aY2Compare;
    if (contourCompletelyCoveredByAdjoinment) return { segmentsLatLng: [], segmentsPx: [], chopsOccurred: true };

    const adjoinmentInMiddleOfContour = cY1Compare < aY1Compare && cY2Compare > aY2Compare;
    if (adjoinmentInMiddleOfContour) {
      // The original piece of Contour needs to be cut into two pieces
      const contour1Px = [
        [contourX, cY1],
        [contourX, aY1],
      ];
      const contour1LatLng = contour1Px.map((px) => latLngFromPixelCoordinate(this.map, px));
      const contour2Px = [
        [contourX, aY2],
        [contourX, cY2],
      ];
      const contour2LatLng = contour2Px.map((px) => latLngFromPixelCoordinate(this.map, px));
      return {
        segmentsLatLng: [contour1LatLng, contour2LatLng],
        segmentsPx: [contour1Px, contour2Px],
        chopsOccurred: true,
      };
    } else {
      // The original piece of Contour needs to be cut, removing the section overlapping with adjoinment
      let contourPx;
      let contourLatLng;
      if (cY1Compare === aY1Compare) {
        contourPx = [
          [contourX, aY2],
          [contourX, cY2],
        ];
        contourLatLng = contourPx.map((px) => latLngFromPixelCoordinate(this.map, px));
      } else if (cY1Compare < aY1Compare) {
        contourPx = [
          [contourX, cY1],
          [contourX, aY1],
        ];
        contourLatLng = contourPx.map((px) => latLngFromPixelCoordinate(this.map, px));
      } else {
        contourPx = [
          [contourX, aY2],
          [contourX, cY2],
        ];
        contourLatLng = contourPx.map((px) => latLngFromPixelCoordinate(this.map, px));
      }
      return { segmentsLatLng: [contourLatLng], segmentsPx: [contourPx], chopsOccurred: true };
    }
  }

  #bottomContourRunsCutFromAdjoinment(contourRunLatLng, contourRunPx, adjoinmentPx) {
    const contourY = contourRunPx[0][1];

    const adjoinmentXs = adjoinmentPx.map((a) => a[0]).sort((a, b) => a - b);
    const [aX1, aX2] = adjoinmentXs;

    const aX1Compare = truncatePixelCoordinateDecimal(aX1);
    const aX2Compare = truncatePixelCoordinateDecimal(aX2);

    const contourXs = contourRunPx.map((c) => c[0]).sort((a, b) => a - b);
    const [cX1, cX2] = contourXs;

    const cX1Compare = truncatePixelCoordinateDecimal(cX1);
    const cX2Compare = truncatePixelCoordinateDecimal(cX2);

    const notOverlapping = cX2Compare <= aX1Compare || cX1Compare >= aX2Compare;
    if (notOverlapping) return { segmentsLatLng: [contourRunLatLng], segmentsPx: [contourRunPx], chopsOccurred: false };

    const contourCompletelyCoveredByAdjoinment = cX1Compare >= aX1Compare && cX2Compare <= aX2Compare;
    if (contourCompletelyCoveredByAdjoinment) return { segmentsLatLng: [], segmentsPx: [], chopsOccurred: true };

    const adjoinmentInMiddleOfContour = cX1Compare < aX1Compare && cX2Compare > aX2Compare;
    if (adjoinmentInMiddleOfContour) {
      // The original piece of Contour needs to be cut into two pieces
      const contour1Px = [
        [cX1, contourY],
        [aX1, contourY],
      ];
      const contour1LatLng = contour1Px.map((px) => latLngFromPixelCoordinate(this.map, px));
      const contour2Px = [
        [cX2, contourY],
        [aX2, contourY],
      ];
      const contour2LatLng = contour2Px.map((px) => latLngFromPixelCoordinate(this.map, px));
      return {
        segmentsLatLng: [contour1LatLng, contour2LatLng],
        segmentsPx: [contour1Px, contour2Px],
        chopsOccurred: true,
      };
    } else {
      // The original piece of Contour needs to be cut, removing the section overlapping with adjoinment
      let contourPx;
      let contourLatLng;
      if (cX1Compare === aX1Compare) {
        contourPx = [
          [aX2, contourY],
          [cX2, contourY],
        ];
        contourLatLng = contourPx.map((px) => latLngFromPixelCoordinate(this.map, px));
      } else if (cX1Compare < aX1Compare) {
        contourPx = [
          [cX1, contourY],
          [aX1, contourY],
        ];
        contourLatLng = contourPx.map((px) => latLngFromPixelCoordinate(this.map, px));
      } else {
        contourPx = [
          [aX2, contourY],
          [cX2, contourY],
        ];
        contourLatLng = contourPx.map((px) => latLngFromPixelCoordinate(this.map, px));
      }
      return { segmentsLatLng: [contourLatLng], segmentsPx: [contourPx], chopsOccurred: true };
    }
  }

  #getContiguousFeatureLatLngsOnSide(side) {
    const contiguousSegments = [];

    let currentSegment = undefined;
    const panels = this.panelsOnSide[side];
    panels.forEach((panel) => {
      const hasContourOnSide = this.#panelHasContourOnSide(panel, side);

      if (hasContourOnSide) {
        const panelSideLatLngs = this.#panelLatLngsForSide(panel, side);
        if (currentSegment) {
          currentSegment[1] = panelSideLatLngs[1];
        } else {
          currentSegment = panelSideLatLngs;
        }
      } else {
        if (currentSegment) {
          contiguousSegments.push(currentSegment);
          currentSegment = undefined;
        }
      }
    });

    if (currentSegment) {
      contiguousSegments.push(currentSegment);
    }

    return contiguousSegments;
  }

  #panelHasContourOnSide(panel, side) {
    if (side === "left") return isContourWest(panel.contourCode);
    if (side === "right") return isContourEast(panel.contourCode);
    if (side === "bottom") return isContourSouth(panel.contourCode);
  }

  #panelLatLngsForSide(panel, side) {
    if (side === "left") return panel.sideLatLngsLeft;
    if (side === "right") return panel.sideLatLngsRight;
    if (side === "bottom") return panel.sideLatLngsBottom;
  }
}
