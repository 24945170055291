import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    allowAttachments: Boolean,
    allowLinks: Boolean,
  };

  editorConnectionsCount = 0;

  connect() {
    this.connectEditor();
  }

  connectEditor() {
    if (this.editorConnectionsCount > 30) return;

    setTimeout(() => {
      this.editorConnectionsCount += 1;

      const trixToolbarId = this.element.getAttribute("toolbar");
      const trixToolbar = document.querySelector(`#${trixToolbarId}`);

      if (!trixToolbar) {
        this.connectEditor();
        return;
      }

      if (!this.allowAttachmentsValue) {
        trixToolbar.querySelector(".trix-button-group--file-tools").hidden = true;
        this.element.addEventListener("trix-file-accept", (e) => e.preventDefault());
      }

      if (!this.allowLinksValue) {
        trixToolbar.querySelector(".trix-button--icon-link").hidden = true;
      }
    }, 100);
  }
}
