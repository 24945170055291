import { Controller } from "@hotwired/stimulus";
import { dynamicDialog, showDialog, zIndexForDialog } from "./helper";

export default class extends Controller {
  clickToOpen(event) {
    event.preventDefault();
    event.stopPropagation();

    const clickPosition = { x: event.pageX, y: event.pageY };
    this.showDetailsDialog(event.params, clickPosition);
  }

  showDetailsDialog(eventParams, clickPosition) {
    const { dialogHeader, dialogIdentifier, dialogWidth, dialogBodyMinHeight, turboFrameSrc, turboFrameId } =
      eventParams;

    const dialog = document.querySelector(`[data-dialog-identifier='${dialogIdentifier}']`);

    if (dialog) {
      dialog.style.zIndex = zIndexForDialog(dialog);
      showDialog(dialog, () => {}, { showNearClickPosition: true, clickPosition });
    } else {
      dynamicDialog({
        header: dialogHeader,
        content: this.detailsTurboFrame(dialogBodyMinHeight, turboFrameId, turboFrameSrc),
        width: dialogWidth || 600,
        showNearClickPosition: true,
        dialogIdentifier,
        clickPosition,
      });
    }
  }

  detailsTurboFrame(dialogBodyMinHeight, turboFrameId, turboFrameSrc) {
    return `
      <div style="min-height: ${dialogBodyMinHeight || 450}px;">
        <turbo-frame id="${turboFrameId}" src="${turboFrameSrc}">Loading...</turbo-frame>
      </div>
      `;
  }
}
