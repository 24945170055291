import { Controller } from "@hotwired/stimulus";
import anime from "animejs";
import * as store from "../../helpers/local-store";

// Handles the behavior of showing and hiding ir-alert messages.  We use
// this mostly for displaying validation errors on the page.
export default class extends Controller {
  static targets = ["container"];

  connect() {
    if (store.get(this.storeIdentifier)) {
      this.containerTarget.remove();
      return;
    }

    anime({
      targets: this.containerTarget,
      opacity: 1,
      easing: "easeInOutQuad",
      duration: 500,
    });
  }

  close(event) {
    event.preventDefault();

    if (this.rememberClose) store.set(this.storeIdentifier, true);

    anime({
      targets: this.containerTarget,
      opacity: 0,
      height: 0,
      easing: "easeInOutQuad",
      duration: 300,
      complete: () => {
        this.containerTarget.style.display = "none";
      },
    });
  }

  get rememberClose() {
    return this.containerTarget.dataset.rememberClose !== undefined;
  }

  get storeIdentifier() {
    return `ir-alert-closed-${this.containerTarget.dataset.identifier}`;
  }
}
