import { Controller } from "@hotwired/stimulus";
import { elementInfo } from "../../../helpers/app";

export default class extends Controller {
  connect() {
    const headerInfo = elementInfo(document.querySelector(".project-header"));

    // Having this static value for the header height isn't ideal, but for some reason when doing an
    // AJAX request to the backend and using a .js.erb to populate parts of the page, a Stimulus
    // controller in the the loaded content that tries to dynamically figure out the height of the
    // header seems to return 0 even though the height is around 115px.  I've tried using timeouts
    // and running until a height is greater than 0, but this doesn't seem to help.  The header
    // height is generally 115px or close to it, so using a fallback if the height is calculated to
    // be 0 seems reasonable.
    const projectHeaderHeightDefault = 115;

    const heightOffset = headerInfo.height > 0 ? headerInfo.height : projectHeaderHeightDefault;
    this.element.style.top = `-${heightOffset}px`;
  }
}
