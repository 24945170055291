export const OFF_SCREEN_POSITION = -10000;
export const DRAGGING_TOLERANCE = 5;

export const calculateSelectionMarqueeSizeAndPosition = (dragAndDrop, window) => {
  const { startX, startY, endX, endY } = dragAndDrop;
  const { pageXOffset, pageYOffset } = window;

  let width;
  let height;
  let x;
  let y;

  width = Math.abs(endX - startX);
  height = Math.abs(endY - startY);

  if (startX < endX) {
    x = startX + pageXOffset;
  } else if (startX > endX) {
    x = startX - width + pageXOffset;
  } else if (startX === endX && height >= DRAGGING_TOLERANCE) {
    x = startX + pageXOffset;
  } else {
    x = OFF_SCREEN_POSITION;
  }

  if (startY < endY) {
    y = startY + pageYOffset;
  } else if (startY > endY) {
    y = startY - height + pageYOffset;
  } else if (startY === endY && width >= DRAGGING_TOLERANCE) {
    y = startY + pageYOffset;
  } else {
    y = OFF_SCREEN_POSITION;
  }

  if (height < 1) height = 1;
  if (width < 1) width = 1;

  return { width, height, x, y };
};
