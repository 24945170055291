import sha256 from "crypto-js/sha256";

export default class UndoQueue {
  constructor({ maxUndo, debug } = { debug: false, maxUndo: 50 }) {
    this.undoStack = [];
    this.redoStack = [];
    this.maxUndo = maxUndo;
    this.debug = debug;
  }

  push(newValue) {
    if (this.debug) console.log("undoQueue: push");

    if (!newValue) return;

    const newItem = this.#hashedItem(newValue);

    if (this.currentItem) {
      if (newItem.hash === this.currentItem.hash) return;

      this.undoStack.push(this.currentItem);
    } else if (this.hasRedoSteps) {
      this.undoStack.push(this.redoStack.pop());
    }

    this.currentItem = newItem;
    if (this.undoStack.length > this.maxUndo) this.undoStack.shift();

    this.redoStack = [];
    this.lastItem = undefined;

    if (this.debug) this.debugLog();
  }

  undo() {
    if (this.debug) console.log("undoQueue: undo");

    if (this.hasZeroUndoSteps) return undefined;
    if (this.hasZeroRedoSteps && this.currentItem) {
      this.redoStack.push(this.currentItem);
      this.currentItem = undefined;
    }

    const pushPop = () => {
      const item = this.undoStack.pop();
      this.redoStack.push(item);
      return item;
    };

    let item = pushPop();
    if (!item) return undefined;

    if (this.#itemEqualsLastItem(item)) item = pushPop();

    this.lastItem = item;

    if (this.debug) this.debugLog();

    return item.value;
  }

  redo() {
    if (this.debug) console.log("undoQueue: redo");

    if (this.hasZeroRedoSteps) return undefined;

    const pushPop = () => {
      const item = this.redoStack.pop();
      this.undoStack.push(item);
      return item;
    };

    let item = pushPop();
    if (!item) return undefined;

    if (this.#itemEqualsLastItem(item)) item = pushPop();

    this.lastItem = item;

    if (this.debug) this.debugLog();

    return item.value;
  }

  get undoSteps() {
    return this.undoStack.length;
  }

  get hasUndoSteps() {
    return this.undoSteps > 0;
  }

  get hasZeroUndoSteps() {
    return this.undoSteps === 0;
  }

  get redoSteps() {
    return this.redoStack.length;
  }

  get hasRedoSteps() {
    return this.redoSteps > 0;
  }

  get hasZeroRedoSteps() {
    return this.redoSteps === 0;
  }

  clear() {
    if (this.debug) console.log("undoQueue: clear");

    this.undoStack = [];
    this.redoStack = [];
    this.currentItem = undefined;
    this.lastItem = undefined;
  }

  #hashedItem(value) {
    return { hash: sha256(JSON.stringify(value)).toString(), value };
  }

  #itemEqualsLastItem(item) {
    if (!this.lastItem) return false;

    return this.lastItem.hash === item.hash;
  }

  debugLog() {
    console.group(`--[ Undo Queue Debug Logging ]---------------- ${new Date()}`);
    console.group("undoStack:");
    if (this.hasUndoSteps) {
      this.undoStack.forEach((item, i) => {
        console.log(`${i + 1}. ${item.hash}`);
      });
    }
    console.groupEnd();
    console.group("redoStack:");
    if (this.hasRedoSteps) {
      this.redoStack.forEach((item, i) => {
        console.log(`${i + 1}. ${item.hash}`);
      });
    }
    console.groupEnd();
    console.log("currentItem:", this.currentItem && this.currentItem.hash);
    console.log("lastItem:", this.lastItem && this.lastItem.hash);
    console.groupEnd();
  }
}
