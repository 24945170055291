import { Controller } from "@hotwired/stimulus";
import throttle from "lodash/throttle";

import { elementInfo, pointOverlapsElement } from "../../../helpers/app";
import * as animate from "../../../helpers/animate";

export default class extends Controller {
  static targets = ["itemsContainer"];

  connect() {
    document.body.addEventListener("click", this.bodyClickHideMenu.bind(this));

    this.sideMenuHorizontalAlignmentContainer = document.querySelector(
      '[data-identifier="side-menu-horizontal-alignment-container"]',
    );

    this.alignHorizontally = throttle(this.alignHorizontally, 300);

    window.addEventListener("resize", this.alignHorizontally);

    this.alignHorizontally();
    animate.show(this.element);
  }

  disconnect() {
    document.body.removeEventListener("click", this.bodyClickHideMenu.bind(this));

    window.removeEventListener("resize", this.alignHorizontally);
  }

  alignHorizontally = (_event) => {
    const containerInfo = elementInfo(this.sideMenuHorizontalAlignmentContainer);
    const containerLeft = containerInfo.left;
    let elementLeft = containerLeft - 60;
    if (elementLeft < 0) {
      elementLeft = 0;
      this.element.classList.add("side-menu--anchored");
      this.itemsContainerTarget.classList.add("side-menu__items-container--anchored");
    } else {
      this.element.classList.remove("side-menu--anchored");
      this.itemsContainerTarget.classList.remove("side-menu__items-container--anchored");
    }

    this.element.style.left = `${elementLeft}px`;
  };

  show = (_event) => {
    animate.show(this.itemsContainerTarget, { duration: 250 });
  };

  bodyClickHideMenu(event) {
    const overlapping = pointOverlapsElement({
      x: event.clientX,
      y: event.clientY,
      element: this.itemsContainerTarget,
    });
    if (overlapping) return;

    this.hide(event);
  }

  hide(_event) {
    animate.hide(this.itemsContainerTarget, { duration: 150 });
  }

  roofSectionItemClicked(_event) {
    const roofSectionMenuContainer = document.querySelector("[data-identifier='roof-section-menu-container']");
    if (!roofSectionMenuContainer) return;

    roofSectionMenuContainer.controller.close();
  }
}
