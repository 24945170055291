import { Controller } from "@hotwired/stimulus";

import { showDialog } from "../components/ir_dialog/helper";
import { isInStore, setInStore } from "./helpers";

export default class extends Controller {
  connect() {
    const { introductionName, introductionShow } = this.element.dataset;

    if (introductionShow === undefined) return;
    if (isInStore(introductionName)) return;

    showDialog(`${introductionName}-dialog`);
    setInStore(introductionName);
  }
}
