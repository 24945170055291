import React from "react";
import PropTypes from "prop-types";

function GridNumber({ height, width, x, y, number }) {
  const styles = {
    height: `${height}px`,
    width: `${width}px`,
    left: `${x}px`,
    top: `${y}px`,
  };

  return (
    <div className="graphical-tool__grid-cell-number no-select" style={styles}>
      <div>{number}</div>
    </div>
  );
}

GridNumber.propTypes = {
  number: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export default React.memo(GridNumber);
