export const MIN_ROW_COUNT = 1;
export const MAX_ROW_COUNT = 30;
export const MIN_COLUMN_COUNT = 1;
export const MAX_COLUMN_COUNT = 60;

export default class GridModifier {
  constructor(gridArray, clampHardwareDimensions, maxHardwareHeight, maxHardwareWidth) {
    this.gridArray = gridArray.map((row) => [...row]); // make a copy of the grid array
    this.gridRows = this.gridArray.length;
    this.gridColumns = this.gridArray[0].length;
    this.clampHardwareDimensions = clampHardwareDimensions;
    this.maxHardwareHeight = maxHardwareHeight;
    this.maxHardwareWidth = maxHardwareWidth;
  }

  changeRowNumber(newRowCount, newArrayHardwareHeight) {
    const rowCount = this.clampRowNumber(newRowCount, newArrayHardwareHeight);

    if (rowCount === this.gridRows) return this.gridArray;

    const meth = rowCount > this.gridRows ? "addRows" : "removeRows";
    this[meth](rowCount);

    return this.gridArray;
  }

  addRows(newRowCount) {
    const numberOfNewRows = newRowCount - this.gridRows;
    for (let i = 0; i < numberOfNewRows; i++) {
      this.gridArray.push(new Array(this.gridColumns).fill("0"));
    }
  }

  removeRows(newRowCount) {
    this.gridArray = this.gridArray.slice(0, newRowCount);
  }

  clampRowNumber(numberValue, newArrayHardwareHeight) {
    const maxRowCount = this.maxRowCount(numberValue, newArrayHardwareHeight);
    return this.clamp(numberValue, MIN_ROW_COUNT, maxRowCount);
  }

  maxRowCount(newRowCount, newArrayHardwareHeight) {
    newRowCount = Number.parseInt(newRowCount) || 0;

    if (this.clampHardwareDimensions) {
      return newArrayHardwareHeight > this.maxHardwareHeight ? this.gridRows : Math.max(newRowCount, MAX_ROW_COUNT);
    } else {
      return MAX_ROW_COUNT;
    }
  }

  clamp(value, min, max) {
    const number = Number.parseInt(value) || min; // handles the NaN possibility
    return Math.min(Math.max(number, min), max);
  }

  changeColumnNumber(newColumnCount, newArrayHardwareWidth) {
    newColumnCount = Number.parseInt(newColumnCount) || 0;

    const columnCount = this.clampColumnNumber(newColumnCount, newArrayHardwareWidth);

    if (columnCount === this.gridColumns) return this.gridArray;

    const meth = columnCount > this.gridColumns ? "addColumns" : "removeColumns";
    this[meth](columnCount);

    return this.gridArray;
  }

  addColumns(newColumnCount) {
    const numberOfNewColumns = newColumnCount - this.gridColumns;
    this.gridArray = this.gridArray.map((row) => [...row, ...new Array(numberOfNewColumns).fill("0")]);
  }

  removeColumns(newColumnCount) {
    this.gridArray = this.gridArray.map((row) => row.slice(0, newColumnCount));
  }

  clampColumnNumber(numberValue, newArrayHardwareWidth) {
    const maxColumnCount = this.maxColumnCount(numberValue, newArrayHardwareWidth);
    return this.clamp(numberValue, MIN_COLUMN_COUNT, maxColumnCount);
  }

  maxColumnCount(newColumnCount, newArrayHardwareWidth) {
    if (this.clampHardwareDimensions) {
      return newArrayHardwareWidth > this.maxHardwareWidth
        ? this.gridColumns
        : Math.max(newColumnCount, MAX_COLUMN_COUNT);
    } else {
      return MAX_COLUMN_COUNT;
    }
  }
}
