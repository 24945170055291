import { types, getParent } from "mobx-state-tree";
import LatLngModel from "../../da/models/lat-lng-model";
import WithLatLngPoints from "../../da/models/with-lat-lng-points";
import CartesianModel from "../../da/models/cartesian-model";
import WithDirtyTracking from "../../da/models/with-dirty-tracking";

export const THERMAL_EXPANSION_FLAP_WIDTH = 4;
export const THERMAL_EXPANSION_FLAP_INSET = 6;

const ThermalExpansionModelBase = types
  .model("ThermalExpansionModel", {
    uuid: types.identifier,
    startLatLng: LatLngModel,
    startCartesianPoint: types.maybeNull(CartesianModel),
    legalityCheckLatLngPoints: types.array(LatLngModel),
  })
  .views((self) => ({
    get segment() {
      return getParent(self, 2);
    },
    get thermalExpansionLength() {
      return self.segment.project.thermalExpansionLength;
    },
    thermalExpansionLatLngPoints(startLatLng) {
      const { unitColumnVectorLatLng, unitRowVectorLatLng } = self.segment;
      const totalHeight = self.segment.height;
      const flapLength = totalHeight - THERMAL_EXPANSION_FLAP_INSET * 2;

      const p2 = startLatLng.plus(unitColumnVectorLatLng.times(self.thermalExpansionLength));
      const p3 = p2.plus(unitRowVectorLatLng.times(THERMAL_EXPANSION_FLAP_INSET));
      const p4 = p3.plus(unitColumnVectorLatLng.times(THERMAL_EXPANSION_FLAP_WIDTH));
      const p5 = p4.plus(unitRowVectorLatLng.times(flapLength));
      const p6 = p5.minus(unitColumnVectorLatLng.times(THERMAL_EXPANSION_FLAP_WIDTH));
      const p7 = p6.plus(unitRowVectorLatLng.times(THERMAL_EXPANSION_FLAP_INSET));
      const p8 = p7.minus(unitColumnVectorLatLng.times(self.thermalExpansionLength));
      const p9 = p8.minus(unitRowVectorLatLng.times(THERMAL_EXPANSION_FLAP_INSET));
      const p10 = p9.minus(unitColumnVectorLatLng.times(THERMAL_EXPANSION_FLAP_WIDTH));
      const p11 = p10.minus(unitRowVectorLatLng.times(flapLength));
      const p12 = p11.plus(unitColumnVectorLatLng.times(THERMAL_EXPANSION_FLAP_WIDTH));
      const latLngPoints = [startLatLng, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, startLatLng].map(
        (ll) => ll.toObjectLiteral,
      );
      return latLngPoints;
    },
    thermalExpansionLegalityCheckLatLngPoints(startLatLng) {
      const thermalExpansionDrawingParallelToEave = self.segment.unitColumnVectorLatLng.times(
        self.thermalExpansionLength,
      );
      const thermalExpansionDrawingPerpendicularToEave = self.segment.unitRowVectorLatLng.times(self.segment.height);
      const p2 = startLatLng.plus(thermalExpansionDrawingParallelToEave);
      const p3 = p2.plus(thermalExpansionDrawingPerpendicularToEave);
      const p4 = startLatLng.plus(thermalExpansionDrawingPerpendicularToEave);
      const latLngPoints = [startLatLng, p2, p3, p4, startLatLng].map((ll) => ll.toObjectLiteral);
      return latLngPoints;
    },
  }))
  .actions((self) => ({
    setStartLatLng(newStartLatLng) {
      if (self.startLatLng.lat === newStartLatLng.lat && self.startLatLng.lng === newStartLatLng.lng) {
        return;
      }
      self.startLatLng = newStartLatLng;
      self.generateAndSetLatLngPoints(newStartLatLng);
      self.markDirty();
    },
    setStartCartesianPoint(newStartCartesianPoint) {
      if (
        self.startCartesianPoint &&
        self.startCartesianPoint.x === newStartCartesianPoint.x &&
        self.startCartesianPoint.y === newStartCartesianPoint.y
      ) {
        return;
      }
      self.startCartesianPoint = newStartCartesianPoint;
      self.markDirty();
    },
    setLegalityCheckLatLngPoints(newPoints) {
      const oldLatLngPointsString = JSON.stringify(self.legalityCheckLatLngPoints);
      const newLatLngPointsString = JSON.stringify(newPoints);
      if (oldLatLngPointsString === newLatLngPointsString) return;

      self.legalityCheckLatLngPoints = newPoints;
    },
    generateAndSetLatLngPoints(startLatLng) {
      self.setLatLngPoints(self.thermalExpansionLatLngPoints(startLatLng));
      self.setLegalityCheckLatLngPoints(self.thermalExpansionLegalityCheckLatLngPoints(startLatLng));
    },
  }));

const ThermalExpansionModel = types.compose(ThermalExpansionModelBase, WithLatLngPoints, WithDirtyTracking);
export default ThermalExpansionModel;
