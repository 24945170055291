import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "addBtn"];

  add = (_) => {
    this.addBtnTarget.classList.add("ir-btn--spinner-shown");
    this.menuTarget.style.display = "none";
    setTimeout(() => {
      this.menuTarget.style.display = "block";
    }, 1000);
  };
}
