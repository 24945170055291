import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import debounce from "lodash/debounce";

import { errorAlertDialog } from "./components/ir_dialog/helper";

export default class AutoCompleteCheckboxController extends Controller {
  static targets = ["searchField", "searchResults", "checkbox", "checkboxesContainer"];

  static values = {
    formParamsKey: String,
    basePath: String,
    modelName: String,
  };

  connect() {
    this.search = debounce(this.search, 300);
  }

  search(event) {
    const searchTerm = event.target.value;

    get(`${this.basePathValue}?search=${searchTerm}&form_params_key=${this.formParamsKeyValue}`, {
      responseKind: "turbo-stream",
    });
  }

  add(event) {
    event.preventDefault();

    const id = event.params.id;

    if (this.#idsAlreadyIncludeId(id)) {
      errorAlertDialog(`This ${this.modelNameValue}, ${event.params.name}, is already added.`);
      return;
    }

    if (this.hasSearchResultsTarget) {
      this.searchResultsTarget.remove();
    }

    const checkbox = event.params.checkbox;
    this.checkboxesContainerTarget.insertAdjacentHTML("beforeend", checkbox);

    this.searchFieldTarget.value = "";
    this.searchFieldTarget.focus();
  }

  #idsAlreadyIncludeId(id) {
    const ids = this.checkboxTargets.map((cb) => parseInt(cb.value));
    return ids.includes(parseInt(id));
  }
}
