import { gridCellInMarqueeSelection } from "../helpers/grid-helper";

export default class GridDragSelection {
  constructor({ cellHeight, cellWidth, cellPositions, selectionMarquee }) {
    this.cellHeight = cellHeight;
    this.cellWidth = cellWidth;
    this.cellPositions = cellPositions;
    this.selectionMarquee = selectionMarquee;

    this.marqueeColumns = 0;
    this.marqueeRows = 0;
    this.cellHovers = [[]];

    this.calculateHovers();
  }

  calculateHovers() {
    for (let i = 0; i < this.cellPositions.length; i++) {
      let rowHasHoveredGridCell = false;
      this.cellHovers[i] = [];
      let marqueeColumnsForRow = 0;

      for (let j = 0; j < this.cellPositions[i].length; j++) {
        const marqueeHover = this.marqueeHoverForGridCell(i, j);
        this.cellHovers[i][j] = marqueeHover;
        if (marqueeHover) {
          rowHasHoveredGridCell = true;
          marqueeColumnsForRow++;
        }
      }

      if (rowHasHoveredGridCell) this.marqueeRows++;
      if (marqueeColumnsForRow > this.marqueeColumns) {
        this.marqueeColumns = marqueeColumnsForRow;
      }
    }
  }

  marqueeHoverForGridCell(row, column) {
    return gridCellInMarqueeSelection({
      itemX: this.cellPositions[row][column].x,
      itemY: this.cellPositions[row][column].y,
      itemWidth: this.cellWidth,
      itemHeight: this.cellHeight,
      marqueeX: this.selectionMarquee.x,
      marqueeY: this.selectionMarquee.y,
      marqueeWidth: this.selectionMarquee.width,
      marqueeHeight: this.selectionMarquee.height,
    });
  }
}
