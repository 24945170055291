import { toLonLat } from "ol/proj";
import { getDistance } from "ol/sphere";

import { degreesToRadians } from "../../../../../helpers/geometry";
import { rectangleFeatureSideCoordinates } from "../../../../../da/map/ol-helpers";

import { METERS_TO_INCHES } from "../../../../../da/map/ol-geometry";
const RANGE_DISTANCE_THRESHOLD = 0.01; // inches

export default class SegmentsProximityAlignmentsIdentifier {
  constructor({ controller, horizontalRange, verticalRange }) {
    this.controller = controller;

    this.mapManager = controller.mapManager;
    this.project = controller.project;
    this.map = this.mapManager.map;

    this.horizontalRange = this.#setHorizontalRange(horizontalRange);
    this.verticalRange = this.#setVerticalRange(verticalRange);
    this.pixelsProximityRange = this.#getPixelProximityRange();

    this.segmentFeatures = this.mapManager.segmentsVectorSource.getFeatures();
    this.segmentFeaturesByUuid = {};

    this.#setSegmentFeaturesSideCoordinates();
    this.#setDefaultSegmentProximities();
  }

  identify() {
    this.segmentFeatures.forEach((segmentFeature) => {
      const segmentUuid = segmentFeature.get("uuid");
      this.segmentFeaturesByUuid[segmentUuid] = segmentFeature;
      const segment = segmentFeature.get("model");
      const railGroupSegmentUuids = segment.railGroupSegmentUuids;

      this.segmentFeatures.forEach((otherSegmentFeature) => {
        if (segmentFeature === otherSegmentFeature) return;
        const otherSegmentUuid = otherSegmentFeature.get("uuid");

        if (railGroupSegmentUuids.includes(otherSegmentUuid)) return;

        this.#identifySegmentProximities(segmentUuid, otherSegmentUuid);
      });
    });
  }

  #setHorizontalRange(horizontalRange) {
    return horizontalRange || this.project.interColumnSpacing;
  }

  #setVerticalRange(verticalRange) {
    const roofSlopeRadians = degreesToRadians(this.controller.focusedRoofPlane.roofSlope);
    const slopeAdjustmentMultiplier = Math.cos(roofSlopeRadians);

    if (verticalRange) return verticalRange * slopeAdjustmentMultiplier;

    return this.project.interRowSpacing * slopeAdjustmentMultiplier;
  }

  #getPixelProximityRange() {
    const maxRangeDistance = Math.max(this.horizontalRange, this.verticalRange); // inches
    const proximityDistance = maxRangeDistance + 10; // Adding inches to widen the ballpark
    const inchesPerPixel = this.map.getView().getResolution() * METERS_TO_INCHES;
    return proximityDistance / inchesPerPixel;
  }

  // Since proximities come in pairs [right, left] and [bottom, top], we only need to check right and bottom
  #identifySegmentProximities(segmentUuid, otherSegmentUuid) {
    if (this.#segmentFeatureIsInProximityOnRightByOtherSegmentFeature(segmentUuid, otherSegmentUuid)) {
      this.segmentProximities[segmentUuid].right.push(otherSegmentUuid);
      this.segmentProximities[otherSegmentUuid].left.push(segmentUuid);
    }

    if (this.#segmentFeatureIsInProximityOnBottomByOtherSegmentFeature(segmentUuid, otherSegmentUuid)) {
      this.segmentProximities[segmentUuid].bottom.push(otherSegmentUuid);
      this.segmentProximities[otherSegmentUuid].top.push(segmentUuid);
    }
  }

  #segmentFeatureIsInProximityOnRightByOtherSegmentFeature(segmentUuid, otherSegmentUuid) {
    const segmentFeatureSides = this.segmentFeatureSides[segmentUuid];
    const otherSegmentFeatureSides = this.segmentFeatureSides[otherSegmentUuid];

    const segmentTopRightPixel = segmentFeatureSides.right.pixels[0];
    const segmentBottomRightPixel = segmentFeatureSides.right.pixels[1];
    const otherSegmentTopLeftPixel = otherSegmentFeatureSides.left.pixels[1];
    const otherSegmentBottomLeftPixel = otherSegmentFeatureSides.left.pixels[0];
    if (segmentTopRightPixel[0] >= otherSegmentTopLeftPixel[0]) return false;
    if (otherSegmentTopLeftPixel[0] - segmentTopRightPixel[0] > this.pixelsProximityRange) return false;

    const segmentsHaveSomeVerticalOverlap =
      (segmentTopRightPixel[1] >= otherSegmentTopLeftPixel[1] &&
        segmentTopRightPixel[1] < otherSegmentBottomLeftPixel[1]) ||
      (otherSegmentTopLeftPixel[1] >= segmentTopRightPixel[1] &&
        otherSegmentTopLeftPixel[1] < segmentBottomRightPixel[1]);
    if (!segmentsHaveSomeVerticalOverlap) return false;

    const distanceLonLat1 = toLonLat(this.map.getCoordinateFromPixel(segmentTopRightPixel));
    const distanceLonLat2 = toLonLat(
      this.map.getCoordinateFromPixel([otherSegmentTopLeftPixel[0], segmentTopRightPixel[1]]),
    );
    const distance = getDistance(distanceLonLat1, distanceLonLat2) * METERS_TO_INCHES;
    return distance <= RANGE_DISTANCE_THRESHOLD + this.horizontalRange;
  }

  #segmentFeatureIsInProximityOnBottomByOtherSegmentFeature(segmentUuid, otherSegmentUuid) {
    const segmentFeatureSides = this.segmentFeatureSides[segmentUuid];
    const otherSegmentFeatureSides = this.segmentFeatureSides[otherSegmentUuid];

    const segmentBottomLeftPixel = segmentFeatureSides.bottom.pixels[1];
    const segmentBottomRightPixel = segmentFeatureSides.bottom.pixels[0];
    const otherSegmentTopLeftPixel = otherSegmentFeatureSides.top.pixels[0];
    const otherSegmentTopRightPixel = otherSegmentFeatureSides.top.pixels[1];

    if (otherSegmentTopLeftPixel[1] <= segmentBottomLeftPixel[1]) return false;
    if (otherSegmentTopLeftPixel[1] - segmentBottomLeftPixel[1] > this.pixelsProximityRange) return false;

    const segmentsHaveSomeHorizontalOverlap =
      (segmentBottomLeftPixel[0] >= otherSegmentTopLeftPixel[0] &&
        segmentBottomLeftPixel[0] < otherSegmentTopRightPixel[0]) ||
      (otherSegmentTopLeftPixel[0] >= segmentBottomLeftPixel[0] &&
        otherSegmentTopLeftPixel[0] < segmentBottomRightPixel[0]);
    if (!segmentsHaveSomeHorizontalOverlap) return false;

    const distanceLonLat1 = toLonLat(this.map.getCoordinateFromPixel(segmentBottomLeftPixel));
    const distanceLonLat2 = toLonLat(
      this.map.getCoordinateFromPixel([segmentBottomLeftPixel[0], otherSegmentTopLeftPixel[1]]),
    );
    const distance = getDistance(distanceLonLat1, distanceLonLat2) * METERS_TO_INCHES;

    return distance <= RANGE_DISTANCE_THRESHOLD + this.verticalRange;
  }

  #setSegmentFeaturesSideCoordinates() {
    this.segmentFeatureSides = {};
    this.segmentFeatures.map((segmentFeature) => {
      const uuid = segmentFeature.get("uuid");
      this.segmentFeatureSides[uuid] = rectangleFeatureSideCoordinates(segmentFeature, this.mapManager.map);
    });
  }

  #setDefaultSegmentProximities() {
    this.segmentProximities = {};
    this.segmentFeatures.forEach((segmentFeature) => {
      const segmentUuid = segmentFeature.get("uuid");
      this.segmentProximities[segmentUuid] = { top: [], right: [], bottom: [], left: [] };
    });
  }
}
