import { Controller } from "@hotwired/stimulus";
import { flashlight } from "../../helpers/animate";

export default class extends Controller {
  static targets = ["blockWeight"];

  changed(event) {
    const blockSizeControl = event.target;
    const blockSize = blockSizeControl.value;

    const defaultWeights = JSON.parse(this.blockWeightTarget.dataset.defaultWeights);
    const defaultWeight = defaultWeights[blockSize];

    this.blockWeightTarget.value = defaultWeight;
    flashlight(this.blockWeightTarget);
  }
}
