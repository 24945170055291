import { Controller } from "@hotwired/stimulus";

export default class SetbackGuidesForm extends Controller {
  static targets = ["toggleVisibilityIcon"];

  connect() {
    this.element.controller = this;
  }

  changeDistance(event) {
    const field = event.currentTarget;
    const segmentIndex = Number.parseInt(field.dataset.segmentIndex);
    if (field.value === "") return;

    const distance = Number.parseFloat(field.value);
    this.mapController.mapModelSynchronizer.setGuideLineDistance(segmentIndex, distance);
    this.mapController.markDirty();
  }

  toggleVisibility(event) {
    const button = event.currentTarget;
    const segmentIndex = Number.parseInt(button.dataset.segmentIndex);
    const icon = button.querySelector("i");

    // There is only 1 displayable roof plane
    const setbackGuide = this.mapController.project.displayableRoofPlanes[0].guideLineForSegmentIndex(segmentIndex);
    this.toggleVisibilityIcon(icon, !setbackGuide.visible);

    this.mapController.mapModelSynchronizer.toggleGuideLineVisibility(segmentIndex);
    this.mapController.markDirty();
  }

  toggleVisibilityIcon(icon, visible) {
    if (visible) {
      icon.classList.remove("fa-eye-slash");
      icon.classList.add("fa-eye");
    } else {
      icon.classList.remove("fa-eye");
      icon.classList.add("fa-eye-slash");
    }
  }

  get mapController() {
    return document.querySelector("[data-controller='pr--image-based--layout-editor--map-setbacks']")[
      "pr--image-based--layout-editor--map-setbacks"
    ];
  }

  hideAll(_event) {
    this.mapController.mapModelSynchronizer.hideAllGuideLines();
    this.toggleVisibilityIconTargets.forEach((icon) => this.toggleVisibilityIcon(icon, false));
    this.mapController.markDirty();
  }

  showAll(_event) {
    this.mapController.mapModelSynchronizer.showAllGuideLines();
    this.toggleVisibilityIconTargets.forEach((icon) => this.toggleVisibilityIcon(icon, true));
    this.mapController.markDirty();
  }
}
