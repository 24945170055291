import { Circle, Text, Fill, Stroke, Style } from "ol/style";
import Point from "ol/geom/Point";

export function lineNumberMarkerStyle({
  startPoint,
  endPoint,
  lineNumber,
  lineColor,
  lineWidth,
  circleColor,
  zIndex = 1,
  radius = 20,
  fontSize = 16,
}) {
  const lineNumberMarkerStyle = new Style({
    geometry: new Point(midPoint(startPoint, endPoint)),
    image: new Circle({
      radius,
      fill: new Fill({ color: circleColor }),
      stroke: new Stroke({ color: lineColor, width: lineWidth }),
      snapToPixel: true,
    }),
    text: new Text({
      text: `${lineNumber}`,
      font: `bold ${fontSize}px sans-serif`,
      fill: new Fill({ color: "#fff" }),
    }),
    zIndex,
  });
  return lineNumberMarkerStyle;
}

export function midPoint(startPoint, endPoint) {
  return [(startPoint[0] + endPoint[0]) / 2.0, (startPoint[1] + endPoint[1]) / 2.0];
}
