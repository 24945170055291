import MapBaseController from "./layout_editor/map_base_controller";

export default class extends MapBaseController {
  connect() {
    this.viewOnlyMap = true;
    this.showBoundingBoxes = false;
    this.hideEmptyCells = true;
    this.isProjectReport = true;

    super.connect();

    this.mapManager.roofPlanesVectorSource.clear();

    this.mapManager.obstructionsVectorSource.clear();
    this.mapManager.obstructionBuffersVectorSource.clear();

    this.mapManager.addArraysVectorLayer();
  }
}
