import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

const FLORIDA_716_APPROVAL_SMALL_PANEL_THRESHOLD = 67.5;

export default class extends Controller {
  static targets = ["floridaDeclineTrue", "railsPerRowContainer"];
  static values = {
    allowed: Boolean,
    asce: String,
    panelLength: Number,
    railType: String,
  };

  engineeringChanged(_event) {
    if (!this.allowedValue) return;

    this.#showHideRailsPerRow();
  }

  #showHideRailsPerRow() {
    const panelLength = this.panelLengthValue;
    const floridaDeclined = this.floridaDeclineTrueTarget.checked;
    const showRailsPerRow =
      floridaDeclined || this.railTypeValue === "XR10" || panelLength > FLORIDA_716_APPROVAL_SMALL_PANEL_THRESHOLD;

    if (showRailsPerRow) {
      animate.show(this.railsPerRowContainerTarget);
    } else {
      animate.hide(this.railsPerRowContainerTarget);
    }
  }
}
