export default class GridPositionSnaps {
  static calculate(displayGrid) {
    return new this(displayGrid).calculate();
  }

  constructor(displayGrid) {
    this.setbackWidth = displayGrid.setbackWidth;
    this.setbackOutlines = displayGrid.setbackOutlines;
    this.extensionsOutline = displayGrid.extensionsOutline;
    this.cellWidth = displayGrid.cellWidth;
    this.cellHeight = displayGrid.cellHeight;
    this.cellPositions = displayGrid.cellPositions;

    this.cellPadding = 1;

    this.xAxis = new Set();
    this.yAxis = new Set();
  }

  calculate() {
    this.addCellRowYs();
    this.addCellColumnXs();

    const xAxis = [...this.xAxis];
    const yAxis = [...this.yAxis];
    return { xAxis, yAxis };
  }

  addCellRowYs() {
    const firstColumnCells = this.cellPositions.map((row) => row[0]);
    firstColumnCells.forEach((cell) => {
      this.yAxis.add(cell.y);
      this.yAxis.add(cell.y + this.cellHeight - this.cellPadding);
    });
  }

  addCellColumnXs() {
    const firstRowCells = this.cellPositions[0];
    firstRowCells.forEach((cell) => {
      this.xAxis.add(cell.x);
      this.xAxis.add(cell.x + this.cellWidth - this.cellPadding);
    });
  }
}
