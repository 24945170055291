import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="bulk-permit-packs"
export default class extends Controller {
  static targets = ["stripePaymentForm"];

  hideStripePaymentForm() {
    if (this.hasStripePaymentFormTarget) {
      this.stripePaymentFormTarget.classList.add("d-none");
    }
  }
}
