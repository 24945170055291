import { Circle, Text, Fill, Stroke, Style } from "ol/style";
import Point from "ol/geom/Point";

import { lineNumberMarkerStyle, midPoint } from "./helpers";
import { distanceForPixels } from "../../../da/map/ol-helpers";

const GUIDE_LINE_COLOR = "rgba(148, 0, 255, 1)";

export function guideLinesStyle(feature, map, roofPlanes) {
  const styles = [];
  styles.push(
    new Style({
      stroke: new Stroke({ color: GUIDE_LINE_COLOR, width: 1 }),
    }),
  );
  const [startPoint, endPoint] = feature.getGeometry().getCoordinates();
  const lineNumber = feature.get("lineNumber");
  styles.push(
    lineNumberMarkerStyle({
      startPoint,
      endPoint,
      lineNumber,
      lineColor: GUIDE_LINE_COLOR,
      lineWidth: 3,
      circleColor: GUIDE_LINE_COLOR,
      radius: 12,
      fontSize: 15,
    }),
  );

  const roofPlane = roofPlanes.find((rp) => rp.uuid === feature.get("roofPlaneUuid"));
  styles.push(guideLineDistanceMarker(feature, roofPlane, map, startPoint, endPoint));
  return styles;
}

function guideLineDistanceMarker(feature, roofPlane, map, startPoint, endPoint) {
  const guideLine = roofPlane.guideLineForSegmentIndex(feature.get("segmentIndex"));
  if (!guideLine) debugger;

  const distance = distanceForPixels(map, 35);
  const midPointCoordinate = midPoint(startPoint, endPoint);
  let vectorX = midPointCoordinate[0] - endPoint[0];
  let vectorY = midPointCoordinate[1] - endPoint[1];
  const factor = distance / Math.sqrt(vectorX * vectorX + vectorY * vectorY);
  vectorX *= factor;
  vectorY *= factor;
  const position = [midPointCoordinate[0] + vectorX, midPointCoordinate[1] + vectorY];

  return new Style({
    geometry: new Point(position),
    text: new Text({
      text: `${guideLine.distance}"`,
      font: "bold 15px sans-serif",
      fill: new Fill({ color: "#fff" }),
      stroke: new Stroke({ color: GUIDE_LINE_COLOR, width: 3 }),
    }),
    zIndex: 25,
  });
}

export function guideLinesIntersectionCirclesStyle(_feature, _map) {
  return new Style({
    image: new Circle({
      fill: new Fill({ color: GUIDE_LINE_COLOR }),
      radius: 3,
    }),
  });
}
