import { Fill, Stroke, Style, Text } from "ol/style";
import Point from "ol/geom/Point";

export function numberVertices(feature, styles) {
  const geometry = feature.getGeometry();
  const coordinates = geometry.getLinearRing(0).getCoordinates();
  coordinates.forEach((coordinate, index) => {
    const vertexLabel = new Style({
      text: new Text({
        text: `P${index + 1}`,
        scale: 2,
        stroke: new Stroke({ color: "rgba(255, 255, 255, 1.0)", width: 3 }),
        fill: new Fill({ color: "rgba(170, 51, 0, 1.0)" }),
      }),
      geometry: new Point(coordinate),
    });
    if (index !== coordinates.length - 1) styles.push(vertexLabel);
  });
}

export function pill({ width, height, strokeColor = "#000", fillColor = "#000", strokeWidth = 2 }) {
  const canvas = document.createElement("canvas");
  canvas.height = height;
  canvas.width = width;
  const context = canvas.getContext("2d");

  const radius = height / 2;

  const top = strokeWidth;
  const left = strokeWidth;
  const bottom = height - strokeWidth;
  const right = width - strokeWidth;

  context.beginPath();
  context.moveTo(width - radius, top);
  context.arcTo(right, top, right, radius, radius);
  context.arcTo(right, bottom, right - radius, bottom, radius);
  context.arcTo(left, bottom, left, radius, radius);
  context.arcTo(left, top, radius, top, radius);
  context.closePath();

  context.strokeStyle = strokeColor;
  context.fillStyle = fillColor;
  context.lineWidth = strokeWidth;

  context.fill();
  context.stroke();

  return canvas;
}
