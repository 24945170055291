import { DragBox } from "ol/interaction";
import { always } from "ol/events/condition";

import applyPanelModeToArrayFeature from "./apply-panel-mode-to-array-feature";
import { ARRAY_CELL_DATA_TYPE } from "../../../da/map/data-types";

export default class DragBoxInteractionManager {
  constructor(controller) {
    this.controller = controller;

    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;

    this.roofSection = controller.roofSection;

    this.dragging = false;
  }

  add() {
    this.clearCurrentInteraction();

    this.currentDragBoxInteraction = new DragBox({ className: "ol-map__drag-box", condition: always });
    this.map.addInteraction(this.currentDragBoxInteraction);
    this.currentDragBoxInteraction.on("boxdrag", this.boxDrag);
    this.currentDragBoxInteraction.on("boxend", this.boxEnd);
  }

  remove() {
    this.clearCurrentInteraction();
  }

  clearCurrentInteraction() {
    if (!this.currentDragBoxInteraction) return;

    this.map.removeInteraction(this.currentDragBoxInteraction);
    this.currentDragBoxInteraction.un("boxdrag", this.boxDrag);
    this.currentDragBoxInteraction.un("boxend", this.boxEnd);
    delete this.currentDragBoxInteraction;
    this.currentDragBoxInteraction = undefined;
  }

  boxDrag = (_event) => {
    this.dragging = true;

    const features = this.#collectFeaturesUnderBoundingBox();

    const marqueeMarker = this.controller.marqueeMarker;

    if (features.length === 0) {
      marqueeMarker.hide();
      return;
    }

    const dragBoxPx = this.#dragBoxTopLeftPx;

    const markerTopOffset = 27;
    marqueeMarker.position(dragBoxPx[1] - markerTopOffset, dragBoxPx[0]);
    marqueeMarker.updateContent(features.length);
    marqueeMarker.show();
  };

  boxEnd = (_event) => {
    this.dragging = false;

    const features = this.#collectFeaturesUnderBoundingBox();
    const layout = this.roofSection.layout;

    features.forEach((feature) => {
      applyPanelModeToArrayFeature(this.controller, layout, feature);
    });

    this.controller.marqueeMarker.hide();

    this.controller.markDirty();

    this.mapManager.dispatchAfterMapFeaturesRendering({ calledFrom: "DragBoxInteractionManager#boxEnd" });
  };

  #collectFeaturesUnderBoundingBox() {
    let features = [];
    const extent = this.currentDragBoxInteraction.getGeometry().getExtent();

    this.mapManager.arraysVectorSource.forEachFeatureIntersectingExtent(extent, (feature) => {
      features.push(feature);
    });

    features = this.#selectCandidatesAfterConsideringRotation(features);

    return features;
  }

  #selectCandidatesAfterConsideringRotation(features) {
    const rotation = this.map.getView().getRotation();
    const anchor = [0, 0];
    const rotatedGeometry = this.currentDragBoxInteraction.getGeometry().clone();
    rotatedGeometry.rotate(-rotation, anchor);
    const rotatedExtent = rotatedGeometry.getExtent();

    return features.filter((feature) => {
      const rotatedFeatureGeometry = feature.getGeometry().clone();
      rotatedFeatureGeometry.rotate(-rotation, anchor);
      return rotatedFeatureGeometry.intersectsExtent(rotatedExtent) && feature.get("dataType") === ARRAY_CELL_DATA_TYPE;
    });
  }

  get #dragBoxTopLeftPx() {
    const dragBox = this.currentDragBoxInteraction;
    const dragBoxCoordinates = dragBox.getGeometry().getCoordinates()[0];
    const dragBoxPixelCoordinates = dragBoxCoordinates.map((coordinate) => this.map.getPixelFromCoordinate(coordinate));
    let minX;
    let minY;

    dragBoxPixelCoordinates.forEach((dragBoxPixelCoordinate) => {
      if (!minX || dragBoxPixelCoordinate[0] < minX) minX = dragBoxPixelCoordinate[0];
      if (!minY || dragBoxPixelCoordinate[1] < minY) minY = dragBoxPixelCoordinate[1];
    });

    return [minX, minY];
  }
}
