import { pointInOrOnPolygon } from "../../../../helpers/point-in-polygon";
import { buildLengthCorrectedMeasure } from "../../edge-list";

export function calculateMeasureSlopeAdjustedDistance(measureFeature, controller) {
  if (!controller.slopeAdjustedMeasures) return;

  const containingRoofPlaneFeature = getContainingRoofPlaneFeature(measureFeature, controller);
  if (!containingRoofPlaneFeature) return undefined;

  const edgeList = buildLengthCorrectedMeasure(containingRoofPlaneFeature, controller, measureFeature);
  return edgeList[0].distance;
}

function getContainingRoofPlaneFeature(measureFeature, controller) {
  const [coordinateOne, coordinateTwo] = measureFeature.getGeometry().getCoordinates();
  if (coordinateOne === undefined || coordinateTwo === undefined) return;

  const roofPlaneFeatures = controller.mapManager.roofPlanesVectorSource.getFeatures();
  const containingRoofPlaneFeature = roofPlaneFeatures.find((rpFeature) => {
    const coordinateOneIsInside = pointInOrOnPolygon(coordinateOne, rpFeature.getGeometry().getCoordinates());
    const coordinateTwoIsInside = pointInOrOnPolygon(coordinateTwo, rpFeature.getGeometry().getCoordinates());
    return coordinateOneIsInside && coordinateTwoIsInside;
  });

  return containingRoofPlaneFeature;
}
