import { Controller } from "@hotwired/stimulus";
import { showFlashMessage } from "../../helpers/app";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = ["copyIcon", "checkIcon"];

  static values = {
    // This is optional. If you don't supply it, just the clipboard value will be
    // copied. If supplied, it should be a string with %s at the location you'd like
    // the copied value to be placed. For example:
    // mapController.mapManager.highlightSegment(“%s”)
    formatString: String,
  };

  copy(event) {
    event.preventDefault();

    let clipboardValue = event.params.value;

    if (this.#hasCheckAndCopyIcons) {
      animate.hide(this.copyIconTarget, { fadeOut: false });
      animate.show(this.checkIconTarget, { display: "inline-block" });

      setTimeout(() => {
        animate.show(this.copyIconTarget, { display: "inline-block" });
        animate.hide(this.checkIconTarget, { fadeOut: false });
      }, 500);
    }

    if (this.hasFormatStringValue) {
      clipboardValue = this.formatStringValue.replace("%s", clipboardValue);
    }

    navigator.clipboard.writeText(clipboardValue);

    showFlashMessage({ message: "Copied to clipboard", flashType: "success" });
  }

  get #hasCheckAndCopyIcons() {
    return this.hasCopyIconTarget && this.hasCheckIconTarget;
  }
}
