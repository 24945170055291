import { CONTOUR_CODE_NONE } from "../../../../helpers/contour";
import { ZONES_MODULE_POSITIONS } from "../../../helpers/zones-and-module-positions";
import SegmentsRailGroup from "../../map-model-synchronizers/segments-rail-group";
import RailGroupConsolidator from "../rail-group-consolidator";
import ThermalExpansionsHorizontalNudger from "../thermal-expansions/horizontal-nudger";

export default class SegmentsRowsAndColumnsAdder {
  constructor({ controller, selectionCollection, side }) {
    this.controller = controller;
    this.selectionCollection = selectionCollection;
    this.side = side;

    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.mapManager = controller.mapManager;
    this.roofPlane = controller.focusedRoofPlane;

    this.railGroups = SegmentsRailGroup.uniqueGroupsFromSegmentFeatures({
      controller,
      features: selectionCollection.getArray(),
    });
  }

  add() {
    this.railGroups.forEach((railGroup) => {
      if (["left", "right"].includes(this.side)) this.#horizontalAdd(railGroup);
      if (["top", "bottom"].includes(this.side)) this.#verticalAdd(railGroup);
      railGroup.removeIllegalPanelContour();
    });
  }

  #horizontalAdd(railGroup) {
    const { eaveEdgeVectorLatLng } = this.roofPlane;

    railGroup.selectedSegments.forEach((segment) => {
      const zonesAndModulePositionsGrid = segment.zonesAndModulePositionsGrid;
      const contourCodesGrid = segment.contourCodesGrid;

      if (this.side === "left") {
        const moveVector = eaveEdgeVectorLatLng.times(segment.spacedPanelSizeInColumnDirection);
        const newStartLatLng = segment.startLatLng.plus(moveVector);
        this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(segment, newStartLatLng);
        zonesAndModulePositionsGrid.forEach((gridRow) => gridRow.unshift(ZONES_MODULE_POSITIONS[0]));
        contourCodesGrid.forEach((gridRow) => gridRow.unshift(CONTOUR_CODE_NONE));
      } else {
        zonesAndModulePositionsGrid.forEach((gridRow) => gridRow.push(ZONES_MODULE_POSITIONS[0]));
        contourCodesGrid.forEach((gridRow) => gridRow.push(CONTOUR_CODE_NONE));
      }

      const zonesAndModulePositionsString =
        SegmentsRailGroup.stringifyZonesAndModulePositionsFromGrid(zonesAndModulePositionsGrid);
      segment.setZonesAndModulePositionsString(zonesAndModulePositionsString);

      const contourCodesString = SegmentsRailGroup.stringifyContourCodesFromGrid(contourCodesGrid);
      segment.setContourCodes(contourCodesString);

      segment.addColumn();
    });

    railGroup.updateDistancesFromRailedParentStart();
    this.#thermalExpansionNudging(railGroup);
    this.#consolidateOverlappingRailGroupSegments(railGroup);

    railGroup.reRenderSegmentsWithSegmentSelections(this.selectionCollection);
  }

  #thermalExpansionNudging(railGroup) {
    this.thermalExpansionNudgeCalls++;
    if (this.thermalExpansionNudgeCalls > 25) {
      console.error("Called the thermal expansion nudger more than 25 times");
      return;
    }

    const { controller, side: nudgeDirection } = this;

    const nudgesOccurred = new ThermalExpansionsHorizontalNudger({
      controller,
      railGroup,
      nudgeDirection,
    }).nudge();

    if (nudgesOccurred) this.#thermalExpansionNudging(railGroup);
  }

  #consolidateOverlappingRailGroupSegments(railGroup) {
    const consolidationsOccurred = new RailGroupConsolidator({
      controller: this.controller,
      railGroup,
      nudgeDirection: this.side,
    }).consolidate();

    if (consolidationsOccurred) this.#consolidateOverlappingRailGroupSegments(railGroup);
  }

  #verticalAdd(railGroup) {
    railGroup.segments.forEach((segment) => {
      const zonesAndModulePositionsGrid = segment.zonesAndModulePositionsGrid;
      const contourCodesGrid = segment.contourCodesGrid;
      const newZonesAndModulePositionsRow = Array(segment.columns).fill(ZONES_MODULE_POSITIONS[0]);
      const newContourCodesRow = Array(segment.columns).fill(CONTOUR_CODE_NONE);

      let moveVector;
      if (this.side === "top") {
        const { slopeAdjustedSpacedPanelSizeInRowDirection, startLatLng } = segment;
        moveVector = this.roofPlane.eavePerpendicularEdgeVectorLatLng.times(
          -1 * slopeAdjustedSpacedPanelSizeInRowDirection,
        );
        const newStartLatLng = startLatLng.plus(moveVector);
        this.mapModelSynchronizer.setSegmentStartLatLngAndCartesianPoint(segment, newStartLatLng);

        zonesAndModulePositionsGrid.unshift(newZonesAndModulePositionsRow);
        contourCodesGrid.unshift(newContourCodesRow);
      } else {
        zonesAndModulePositionsGrid.push(newZonesAndModulePositionsRow);
        contourCodesGrid.push(newContourCodesRow);
      }

      const zonesAndModulePositionsString =
        SegmentsRailGroup.stringifyZonesAndModulePositionsFromGrid(zonesAndModulePositionsGrid);
      segment.setZonesAndModulePositionsString(zonesAndModulePositionsString);

      const contourCodesString = SegmentsRailGroup.stringifyContourCodesFromGrid(contourCodesGrid);
      segment.setContourCodes(contourCodesString);

      segment.addRow();

      if (segment.railed && segment.hasThermalExpansions) {
        segment.thermalExpansions.forEach((thermalExpansion) => {
          if (this.side === "top") {
            const newStartLatLng = thermalExpansion.startLatLng.plus(moveVector);
            this.mapModelSynchronizer.setThermalExpansionStartLatLngAndCartesianPoint(thermalExpansion, newStartLatLng);
          } else {
            thermalExpansion.generateAndSetLatLngPoints(thermalExpansion.startLatLng.clone);
          }
        });
      }
    });

    railGroup.reRenderSegmentsWithSegmentSelections(this.selectionCollection);
    this.mapModelSynchronizer.reRenderThermalExpansions();
  }
}
