// cSpell:ignore unobstruct

import { types, getParent, clone } from "mobx-state-tree";

import { reportError } from "../../helpers/api-mobx";
import CellPositionModel from "./cell-position-model";
import WithDirtyTracking from "../../da/models/with-dirty-tracking";

import { logger } from "../../helpers/app";

const CELL_STATUS_EMPTY = 0;
const CELL_STATUS_PRESENT = 1;
const CELL_STATUS_OUT_OF_BOUNDS = 2;
const CELL_STATUS_PRESENT_WITH_ERROR = 3;

// Used on save to update the design
const CELL_STATUS_EMPTY_STRING = "0";
const CELL_STATUS_PRESENT_STRING = "1";
const CELL_STATUS_OUT_OF_BOUNDS_STRING = "2";

const CELL_STATUS_OBSTRUCTED_DELTA = 5;
const CELL_STATUS_OBSTRUCTED_EMPTY = CELL_STATUS_OBSTRUCTED_DELTA + CELL_STATUS_EMPTY;
const CELL_STATUS_OBSTRUCTED_PRESENT = CELL_STATUS_OBSTRUCTED_DELTA + CELL_STATUS_PRESENT;
const CELL_STATUS_OBSTRUCTED_OUT_OF_BOUNDS = CELL_STATUS_OBSTRUCTED_DELTA + CELL_STATUS_OUT_OF_BOUNDS;
const CELL_STATUS_OBSTRUCTED_PRESENT_WITH_ERROR = CELL_STATUS_OBSTRUCTED_DELTA + CELL_STATUS_PRESENT_WITH_ERROR;

const CELL_STATUS_OBSTRUCTED_EMPTY_STRING = "5";
const CELL_STATUS_OBSTRUCTED_PRESENT_STRING = "6";
const CELL_STATUS_OBSTRUCTED_OUT_OF_BOUNDS_STRING = "7";

// use the cell status (numeric) as an index into the array to get the string translation
// present elements with or without errors are saved as present
const CELL_STATUS_SAVE_TRANSLATION = [
  CELL_STATUS_EMPTY_STRING,
  CELL_STATUS_PRESENT_STRING,
  CELL_STATUS_OUT_OF_BOUNDS_STRING,
  CELL_STATUS_PRESENT_STRING,
  undefined,
  CELL_STATUS_OBSTRUCTED_EMPTY_STRING,
  CELL_STATUS_OBSTRUCTED_PRESENT_STRING,
  CELL_STATUS_OBSTRUCTED_OUT_OF_BOUNDS_STRING,
  CELL_STATUS_OBSTRUCTED_PRESENT_STRING,
];

export const CELL_DRAW_STYLE_EMPTY = CELL_STATUS_EMPTY;
export const CELL_DRAW_STYLE_PRESENT = CELL_STATUS_PRESENT;
export const CELL_DRAW_STYLE_OUT_OF_BOUNDS = CELL_STATUS_OUT_OF_BOUNDS;
export const CELL_DRAW_STYLE_PRESENT_WITH_ERROR = CELL_STATUS_PRESENT_WITH_ERROR;

export const CELL_DRAW_STYLE_OBSTRUCTED_EMPTY = CELL_STATUS_OBSTRUCTED_EMPTY;
export const CELL_DRAW_STYLE_OBSTRUCTED_PRESENT = CELL_STATUS_OBSTRUCTED_PRESENT;
export const CELL_DRAW_STYLE_OBSTRUCTED_OUT_OF_BOUNDS = CELL_STATUS_OBSTRUCTED_OUT_OF_BOUNDS;
export const CELL_DRAW_STYLE_OBSTRUCTED_PRESENT_WITH_ERROR = CELL_STATUS_OBSTRUCTED_PRESENT_WITH_ERROR;

// use the cell status (numeric) as an index into the array to get the string translation
const CELL_STATUS_DRAW_STYLE_TRANSLATION = [
  CELL_DRAW_STYLE_EMPTY,
  CELL_DRAW_STYLE_PRESENT,
  CELL_DRAW_STYLE_OUT_OF_BOUNDS,
  CELL_DRAW_STYLE_PRESENT_WITH_ERROR,
  undefined,
  CELL_DRAW_STYLE_OBSTRUCTED_EMPTY,
  CELL_DRAW_STYLE_OBSTRUCTED_PRESENT,
  CELL_DRAW_STYLE_OBSTRUCTED_OUT_OF_BOUNDS,
  CELL_DRAW_STYLE_OBSTRUCTED_PRESENT_WITH_ERROR,
];

const LayoutModelBase = types
  .model("LayoutModel", {
    id: types.maybe(types.integer),
    deleted: types.optional(types.boolean, false),
    rows: types.integer,
    columns: types.integer,
    design: types.string,
    cells: types.array(types.array(types.integer)),
    activePanelsCount: types.optional(types.integer, 0),
    fresh: types.optional(types.boolean, true),
    cellErrorPositions: types.array(CellPositionModel),
  })
  .views((self) => ({
    get roofSection() {
      return getParent(self);
    },
    illegalRowColumn(row, column) {
      if (row > self.rows || row < 1 || column > self.columns || column < 1) {
        reportError(`Out of bounds access to Grid: ${row}, ${column}`);
        return true;
      }
      return false;
    },

    isEmpty(row, column) {
      const status = self.cellStatus(row, column);
      return self.isEmptyStatus(status);
    },
    isEmptyStatus(status) {
      return status === CELL_STATUS_EMPTY || status === CELL_STATUS_OBSTRUCTED_EMPTY;
    },
    isPresent(row, column) {
      const status = self.cellStatus(row, column);
      return self.isPresentStatus(status);
    },
    isPresentStatus(status) {
      return status === CELL_STATUS_PRESENT || status === CELL_STATUS_OBSTRUCTED_PRESENT;
    },
    isOutOfBounds(row, column) {
      const status = self.cellStatus(row, column);
      return self.isOutOfBoundsStatus(status);
    },
    isOutOfBoundsStatus(status) {
      return status === CELL_STATUS_OUT_OF_BOUNDS || status === CELL_STATUS_OBSTRUCTED_OUT_OF_BOUNDS;
    },
    isPresentWithError(row, column) {
      const status = self.cellStatus(row, column);
      return self.isPresentWithErrorStatus(status);
    },
    isPresentWithErrorStatus(status) {
      return status === CELL_STATUS_PRESENT_WITH_ERROR || status === CELL_STATUS_OBSTRUCTED_PRESENT_WITH_ERROR;
    },
    isObstructed(row, column) {
      const status = self.cellStatus(row, column);
      return self.isObstructedStatus(status);
    },
    isObstructedStatus(status) {
      return status >= CELL_STATUS_OBSTRUCTED_EMPTY;
    },
    // This should be considered a private method
    cellStatus(row, column) {
      if (self.illegalRowColumn(row, column)) return undefined;

      return self.cells[row - 1][column - 1];
    },

    get panelsPresent() {
      if (self.deleted) return 0;
      return self.activePanelsCount;
    },
    get needsSave() {
      return self.dirty || !self.id;
    },
    get isPersisted() {
      return self.id !== undefined;
    },
    get interRowSpacing() {
      return self.roofSection.interRowSpacing;
    },
    get interColumnSpacing() {
      return self.roofSection.interColumnSpacing;
    },
    get isDeficient() {
      return self.rows < 1 || self.columns < 1;
    },
    cellDrawStyle(row, column) {
      const status = self.cellStatus(row, column);

      return CELL_STATUS_DRAW_STYLE_TRANSLATION[status];
    },
    valueWithErrorAdded(currentValue) {
      if (currentValue >= CELL_STATUS_OBSTRUCTED_EMPTY) {
        return CELL_STATUS_OBSTRUCTED_PRESENT_WITH_ERROR;
      } else {
        return CELL_STATUS_PRESENT_WITH_ERROR;
      }
    },
  }))
  .actions((self) => ({
    setupCells() {
      const cells = self.initializeCellsAsPresent();
      self.transferDesignToCells(cells);
      self.markCellsWithErrors(cells);

      self.cells = cells;
    },
    initializeCellsAsPresent() {
      const defaultRow = new Array(self.columns);
      for (let i = 0; i < self.columns; i++) {
        defaultRow[i] = CELL_STATUS_PRESENT;
      }

      const cells = new Array(self.rows);
      for (let i = 0; i < self.rows; i++) {
        cells[i] = defaultRow.slice();
      }

      self.activePanelsCount = self.rows * self.columns;

      return cells;
    },
    transferDesignToCells(cells) {
      for (let row = 0; row < self.rows; row++) {
        for (let column = 0; column < self.columns; column++) {
          const position = row * self.columns + column;
          const valueAtPosition = self.design[position];
          if (valueAtPosition === CELL_STATUS_OBSTRUCTED_PRESENT_STRING) {
            cells[row][column] = CELL_STATUS_OBSTRUCTED_PRESENT;
          } else if (valueAtPosition !== CELL_STATUS_PRESENT_STRING) {
            cells[row][column] = Number.parseInt(valueAtPosition);
            self.activePanelsCount--;
          }
        }
      }
    },
    markCellsWithErrors(cells) {
      self.cellErrorPositions.forEach((cellPosition) => {
        const { row, column } = cellPosition;
        const currentValue = cells[row - 1][column - 1];
        cells[row - 1][column - 1] = self.valueWithErrorAdded(currentValue);
      });
    },
    transferCellsToDesign() {
      let result = "";

      for (let row = 0; row < self.rows; row++) {
        for (let column = 0; column < self.columns; column++) {
          const status = self.cells[row][column];
          result += CELL_STATUS_SAVE_TRANSLATION[status];
        }
      }

      self.design = result;
    },
    removePanel(row, column) {
      if (self.illegalRowColumn(row, column)) return;

      const currentStatus = self.cells[row - 1][column - 1];
      if (self.isEmptyStatus(currentStatus)) return;

      self.markDirty();
      if (self.isPresentStatus(currentStatus) || self.isPresentWithErrorStatus(currentStatus)) {
        self.activePanelsCount--;
        self.clearErrorAt(row, column);
      }
      self.setCellEmpty(row, column, currentStatus);
    },
    addPanel(row, column) {
      if (self.illegalRowColumn(row, column)) return;

      const currentStatus = self.cells[row - 1][column - 1];
      if (self.isPresentStatus(currentStatus) || self.isPresentWithErrorStatus(currentStatus)) return;

      self.markDirty();
      self.activePanelsCount++;
      self.setCellPresent(row, column, currentStatus);
    },
    togglePanel(row, column) {
      if (self.illegalRowColumn(row, column)) return;

      const currentStatus = self.cells[row - 1][column - 1];
      if (self.isOutOfBoundsStatus(currentStatus)) return;

      self.markDirty();
      if (self.isEmptyStatus(currentStatus)) {
        self.activePanelsCount++;
        self.setCellPresent(row, column, currentStatus);
      } else {
        // PRESENT OR PRESENT_WITH_ERRORS
        self.activePanelsCount--;
        self.clearErrorAt(row, column);
        self.setCellEmpty(row, column, currentStatus);
      }
    },
    outOfBoundsPanel(row, column) {
      if (self.illegalRowColumn(row, column)) return;

      const currentStatus = self.cells[row - 1][column - 1];
      if (self.isOutOfBoundsStatus(currentStatus)) return;

      self.markDirty();
      if (self.isPresentStatus(currentStatus)) {
        self.activePanelsCount--;
      }
      self.setCellOutOfBounds(row, column, currentStatus);
    },
    obstructPanel(row, column) {
      if (self.illegalRowColumn(row, column)) return;

      const currentStatus = self.cells[row - 1][column - 1];
      if (self.isObstructedStatus(currentStatus)) return;

      self.markDirty();
      self.setCellObstructed(row, column, currentStatus);
    },
    unobstructPanel(row, column) {
      if (self.illegalRowColumn(row, column)) return;

      const currentStatus = self.cells[row - 1][column - 1];
      if (!self.isObstructedStatus(currentStatus)) return;

      self.markDirty();
      self.setCellUnobstructed(row, column, currentStatus);
    },
    flagDeleted() {
      if (self.deleted) return;

      self.markDirty();
      self.deleted = true;
    },
    overlayPresentPanels(priorLayout) {
      const commonRows = Math.min(self.rows, priorLayout.rows);
      const commonColumns = Math.min(self.columns, priorLayout.columns);

      for (let row = 1; row <= commonRows; row++) {
        for (let column = 1; column <= commonColumns; column++) {
          if (priorLayout.isPresent(row, column) || priorLayout.isPresentWithError(row, column)) {
            self.addPanel(row, column);
          }
        }
      }

      priorLayout.cellErrorPositions.forEach((cellError) => {
        if (cellError.row <= commonRows && cellError.column <= commonColumns) {
          self.cellErrorPositions.push(clone(cellError));
        }
      });
      self.markCellsWithErrors(self.cells);
      self.markDirty();
    },
    // from the row & column, you could get the currentStatus, but in the context
    // where these are used, we already have it, so we're saving extra array lookups
    setCellEmpty(row, column, currentStatus) {
      if (self.isObstructedStatus(currentStatus)) {
        self.cells[row - 1][column - 1] = CELL_STATUS_OBSTRUCTED_EMPTY;
      } else {
        self.cells[row - 1][column - 1] = CELL_STATUS_EMPTY;
      }
    },
    setCellPresent(row, column, currentStatus) {
      if (self.isObstructedStatus(currentStatus)) {
        self.cells[row - 1][column - 1] = CELL_STATUS_OBSTRUCTED_PRESENT;
      } else {
        self.cells[row - 1][column - 1] = CELL_STATUS_PRESENT;
      }
    },
    setCellOutOfBounds(row, column, currentStatus) {
      if (self.isObstructedStatus(currentStatus)) {
        self.cells[row - 1][column - 1] = CELL_STATUS_OBSTRUCTED_OUT_OF_BOUNDS;
      } else {
        self.cells[row - 1][column - 1] = CELL_STATUS_OUT_OF_BOUNDS;
      }
    },
    setCellObstructed(row, column, currentStatus) {
      if (self.isObstructedStatus(currentStatus)) return;

      self.cells[row - 1][column - 1] = currentStatus + CELL_STATUS_OBSTRUCTED_DELTA;
    },
    setCellUnobstructed(row, column, currentStatus) {
      if (!self.isObstructedStatus(currentStatus)) return;

      self.cells[row - 1][column - 1] = currentStatus - CELL_STATUS_OBSTRUCTED_DELTA;
    },
    clearErrorAt(row, column) {
      const updatedCellErrorPositions = self.cellErrorPositions.filter((cellPosition) => {
        return cellPosition.row != row || cellPosition.column != column;
      });

      self.cellErrorPositions = updatedCellErrorPositions;
    },
  }));

export function layoutPersistenceData(layout) {
  saveDescription(layout);

  if (layout === undefined || !layout.needsSave) {
    logger("--Undefined or !needsSave. Skipping");
    return undefined;
  }

  let design;
  if (layout.deleted) {
    design = "";
    logger("--Deleted. Blanking design.");
  } else {
    layout.transferCellsToDesign();
    design = layout.design;
  }

  return {
    id: layout.id,
    rows: layout.rows,
    columns: layout.columns,
    inter_row_spacing: layout.interRowSpacing,
    inter_column_spacing: layout.interColumnSpacing,
    design,
    fresh: layout.fresh,
    _destroy: layout.deleted,
  };
}

function saveDescription(layout) {
  if (layout) {
    if (layout.id) {
      logger(`Persisting Layout #${layout.id}`);
    } else {
      logger("Persisting Layout -- new & unsaved");
    }
  } else {
    logger("Persisting Layout -- undefined");
  }
}

export function emptyDesign(rows, columns) {
  const initialStatus = CELL_STATUS_EMPTY_STRING;
  const row = initialStatus.repeat(columns);
  return row.repeat(rows);
}

export function presentDesign(rows, columns) {
  const initialStatus = CELL_STATUS_PRESENT_STRING;
  const row = initialStatus.repeat(columns);
  return row.repeat(rows);
}

const LayoutModel = types.compose(LayoutModelBase, WithDirtyTracking);
export default LayoutModel;
