import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["uploadButton", "fileInput"];

  click(event) {
    event.preventDefault();
    this.fileInputTarget.click();
  }

  submit(event) {
    event.preventDefault();
    this.uploadButtonTarget.classList.add("ir-btn--spinner-shown");
    this.uploadButtonTarget.disabled = true;

    setTimeout(() => {
      this.element.requestSubmit();
    }, 500);
  }
}
