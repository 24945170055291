import { Controller } from "@hotwired/stimulus";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import zip from "lodash/zip";
import chunk from "lodash/chunk";

import GraphicalLayoutTool from "../../components/GraphicalLayoutTool";
import SentryErrorHandler from "../../components/SentryErrorHandler";
import * as api from "../../helpers/api";
import { defaultEditorMode } from "../../helpers/graphical-tool-helpers";

export default class extends Controller {
  connect() {
    this.cleanupExistingDimensionCrosshairs();

    const data = this.element.dataset;
    this.cellHeight = Number.parseFloat(data.cellHeight);
    this.cellWidth = Number.parseFloat(data.cellWidth);
    this.columns = Number.parseInt(data.columns);
    this.extensionTop = this.floatOrZero(data.extensionTop);
    this.extensionRight = this.floatOrZero(data.extensionRight);
    this.extensionBottom = this.floatOrZero(data.extensionBottom);
    this.extensionLeft = this.floatOrZero(data.extensionLeft);
    this.gridString = data.gridString;
    this.interColumnSpacing = Number.parseFloat(data.interColumnSpacing);
    this.interRowSpacing = Number.parseFloat(data.interRowSpacing);
    this.maxEditorHeight = Number.parseInt(data.maxEditorHeight);
    this.offerCropOnSave = data.offerCropOnSave !== undefined;
    this.projectType = data.projectType;
    this.roofSectionId = data.roofSectionId || data.identifier;
    this.rows = Number.parseInt(data.rows);
    this.setback = this.floatOrZero(data.setback);
    this.spaceAroundCellsTop = this.floatOrZero(data.spaceAroundCellsTop);
    this.spaceAroundCellsRight = this.floatOrZero(data.spaceAroundCellsRight);
    this.spaceAroundCellsBottom = this.floatOrZero(data.spaceAroundCellsBottom);
    this.spaceAroundCellsLeft = this.floatOrZero(data.spaceAroundCellsLeft);
    this.updatePath = data.updatePath;
    this.isRailOrientationNs = data.isRailOrientationNs === "true";
    this.showRails = data.showRails !== undefined;
    this.railPlatform = data.railPlatform;
    this.thirdRailUsed = data.thirdRailUsed === "true";
    this.clampHardwareDimensions = data.clampHardwareDimensions === "true";
    this.maxHardwareHeight = this.floatOrZero(data.maxHardwareHeight);
    this.maxHardwareWidth = this.floatOrZero(data.maxHardwareWidth);
    this.expandable = data.expandable === "true";
    this.contourGridString = data.contourGridString;
    this.hiddenEndClamp = data.hiddenEndClamp !== undefined;
    this.arrayEditingAllowed = data.arrayEditingAllowed !== undefined;
    this.contourEditingAllowed = data.contourEditingAllowed !== undefined;
    this.critterGuardDisplayAllowed = data.critterGuardDisplayAllowed !== undefined;
    this.useCritterGuard = data.useCritterGuard !== undefined;
    this.editableGrid = data.editableGrid !== undefined;
    this.editorMode = defaultEditorMode({
      roofSectionId: this.roofSectionId,
      contourEditingAllowed: this.contourEditingAllowed,
      arrayEditingAllowed: this.arrayEditingAllowed,
    });
    this.railLengthFtIn = data.railLengthFtIn;
    this.minRows = data.minRows;
    this.maxRows = data.maxRows;
    this.minColumns = data.minColumns;
    this.maxColumns = data.maxColumns;
    this.isOpenSolar = data.isOpenSolar !== undefined;

    this.element.controller = this;

    this.instantiateGraphicalEditor();
  }

  changeModeToLayout() {
    const changeEvent = new CustomEvent("changeModeToLayout", { bubbles: true });
    this.element.dispatchEvent(changeEvent);
  }

  changeModeToContour() {
    const changeEvent = new CustomEvent("changeModeToContour", { bubbles: true });
    this.element.dispatchEvent(changeEvent);
  }

  cleanupExistingDimensionCrosshairs() {
    const dimensionCrosshairsElem = document.querySelector("#graphical-tool__dimension-crosshairs__container");
    if (dimensionCrosshairsElem) dimensionCrosshairsElem.remove();
  }

  floatOrZero(value) {
    return value === undefined ? 0 : Number.parseFloat(value);
  }

  convertToRailOrientationEw = (rows, columns, gridString) => ({
    rows: columns,
    columns: rows,
    convertedGridString: zip(...chunk(gridString.split(""), columns).reverse())
      .flat()
      .join(""),
  });

  persist = ({ rows, columns, gridString, contourGridString, completed }) => {
    const originalRows = rows;
    const originalColumns = columns;

    if (this.isRailOrientationNs && this.projectType !== "GB4") {
      ({
        rows,
        columns,
        convertedGridString: gridString,
      } = this.convertToRailOrientationEw(originalRows, originalColumns, gridString));
      ({ convertedGridString: contourGridString } = this.convertToRailOrientationEw(
        originalRows,
        originalColumns,
        contourGridString,
      ));
    }

    const data = {
      da_layout: {
        rows,
        columns,
        design: gridString,
      },
    };
    if (this.projectType === "PR4" || this.projectType === "AURORA") {
      data.da_layout.contour_design = contourGridString;
    }

    axios({
      method: "PATCH",
      url: this.updatePath,
      headers: api.defaultHeaders(),
      data,
    })
      .then((response) => {
        this.persistenceResponsePageUpdates(response);
        completed();
      })
      .catch(api.errorHandler);
  };

  persistenceResponsePageUpdates(response) {
    if (response.data.updates === undefined) return;

    response.data.updates.forEach((update) => {
      const elem = document.querySelector(update.selector);
      if (!elem) {
        console.warn(`Element not found when trying to populate from JSON response: ${update.selector}`);
      } else {
        elem.innerHTML = update.html;
      }
    });
  }

  instantiateGraphicalEditor() {
    this.graphicalTool = (
      <GraphicalLayoutTool
        cellHeight={this.cellHeight}
        cellWidth={this.cellWidth}
        columns={this.columns}
        editableGrid={this.editableGrid}
        extensionTop={this.extensionTop}
        extensionRight={this.extensionRight}
        extensionBottom={this.extensionBottom}
        extensionLeft={this.extensionLeft}
        gridString={this.gridString}
        identifier={this.roofSectionId}
        interColumnSpacing={this.interColumnSpacing}
        interRowSpacing={this.interRowSpacing}
        maxEditorHeight={this.maxEditorHeight}
        offerCropOnSave={this.offerCropOnSave}
        persistenceCallback={this.persist}
        projectType={this.projectType}
        rows={this.rows}
        setback={this.setback}
        spaceAroundCellsTop={this.spaceAroundCellsTop}
        spaceAroundCellsRight={this.spaceAroundCellsRight}
        spaceAroundCellsBottom={this.spaceAroundCellsBottom}
        spaceAroundCellsLeft={this.spaceAroundCellsLeft}
        isRailOrientationNs={this.isRailOrientationNs}
        showRails={this.showRails}
        railPlatform={this.railPlatform}
        thirdRailUsed={this.thirdRailUsed}
        clampHardwareDimensions={this.clampHardwareDimensions}
        maxHardwareHeight={this.maxHardwareHeight}
        maxHardwareWidth={this.maxHardwareWidth}
        expandable={this.expandable}
        editorMode={this.editorMode}
        graphicalToolControllerElement={this.element}
        contourGridString={this.contourGridString}
        hiddenEndClamp={this.hiddenEndClamp}
        arrayEditingAllowed={this.arrayEditingAllowed}
        contourEditingAllowed={this.contourEditingAllowed}
        critterGuardDisplayAllowed={this.critterGuardDisplayAllowed}
        useCritterGuard={this.useCritterGuard}
        railLengthFtIn={this.railLengthFtIn}
        minRows={this.minRows}
        maxRows={this.maxRows}
        minColumns={this.minColumns}
        maxColumns={this.maxColumns}
        isOpenSolar={this.isOpenSolar}
      />
    );

    ReactDOM.render(<SentryErrorHandler>{this.graphicalTool}</SentryErrorHandler>, this.element);
  }
}
