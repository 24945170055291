import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["role"];

  submit(event) {
    event.preventDefault();
    if (event.target.querySelector("[name='company_join_request[role]']")) {
      event.target.querySelector("[name='company_join_request[role]']").value = this.roleTarget.value;
    }
    event.target.submit();
  }
}
