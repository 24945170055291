import React from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "../ToggleSwitch";
import InfoPopupLink from "../InfoPopupLink";

function ToggleSwitchWithLabel({ on, onClick, label, infoPopupIdentifier }) {
  const classNames = ["toggle-field__container"];
  if (on) classNames.push("toggle-field__container--on");

  return (
    <div className={classNames.join(" ")}>
      <label>
        <div className="d-flex">
          <ToggleSwitch on={on} onClick={onClick} />
          <span className="toggle-field__container__label-text">{label}</span>
        </div>
      </label>
      {infoPopupIdentifier && (
        <div className="toggle-field__info-popup-container">
          <InfoPopupLink dialogIdentifier={infoPopupIdentifier} />
        </div>
      )}
    </div>
  );
}

ToggleSwitchWithLabel.propTypes = {
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  infoPopupIdentifier: PropTypes.string,
};

ToggleSwitchWithLabel.defaultProps = {
  infoPopupIdentifier: undefined,
};

export default React.memo(ToggleSwitchWithLabel);
