import DaDefaultRoofSectionsBuilder from "../../da/map/default-roof-sections-builder";
import { addLayoutAndArrayForRoofSection, syncRoofSectionCartesianPoints } from "./modification-helpers/roof-section";
import { findTopLeftLonLat } from "../../da/map/ol-geometry";
import ObstructionsProximityDetector from "./obstructions/proximity-detector";

export default class DefaultRoofSectionsBuilder extends DaDefaultRoofSectionsBuilder {
  addRoofSectionPolygonFeatureToMap(polygonFeature, roofSection) {
    super.addRoofSectionPolygonFeatureToMap(polygonFeature, roofSection);

    const roofPlane = roofSection.roofPlane;
    let priorLayout;
    if (this.roofPlaneWithPriorLayout && this.roofPlaneWithPriorLayout.uuid === roofPlane.uuid) {
      priorLayout = this.roofPlaneWithPriorLayout.priorLayout;
    }
    addLayoutAndArrayForRoofSection(polygonFeature, roofSection, this.project, this.controller.mapManager, priorLayout);
  }

  postBuildDaSpecificActions(roofPlane) {
    const topLeftLonLat = findTopLeftLonLat(roofPlane.latLngPoints);
    roofPlane.defaultRoofSections.forEach((rs) => syncRoofSectionCartesianPoints(topLeftLonLat, rs));

    new ObstructionsProximityDetector(this.project).findNearbyObstructionsForRoofPlane(roofPlane);
  }
}
