import { MEASURE_DATA_TYPE } from "../../../../da/map/data-types";
import Base from "./base";

export default class Attachments extends Base {
  filterSelects = (feature, _layer) => {
    return feature.get("dataType") === MEASURE_DATA_TYPE;
  };

  selectClickResetInteractionManagers() {
    // The translate interaction has to be before the modify, or modify doesn't work
    this.controller.translateInteractionManager.add();
    this.controller.modifyInteractionManager.add();
  }

  removeSelectedShapes = () => {
    this.baseRemoveSelectedShapes();
  };
}
