import React, { Component } from "react";
import PropTypes from "prop-types";
import SearchField from "../SearchField";
import { manufacturerPropTypeShape } from "../helper";
import NoMatchingRecords from "../NoMatchingRecords";

class ManufacturerSelect extends Component {
  static propTypes = {
    projectType: PropTypes.string.isRequired,
    selectManufacturer: PropTypes.func.isRequired,
    manufacturers: PropTypes.arrayOf(manufacturerPropTypeShape),
    selectedManufacturerId: PropTypes.number,
    onlyShowUlListedAndCompatible: PropTypes.bool.isRequired,
    onlyShowBxCompatible: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
      currentSelectionIndex: undefined,
      filteredManufacturers: [],
    };
  }

  componentDidMount() {
    this.setFilteredManufacturers();
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    const { onlyShowUlListedAndCompatible, onlyShowBxCompatible } = this.props;

    if (
      prevProps.onlyShowUlListedAndCompatible !== onlyShowUlListedAndCompatible ||
      prevProps.onlyShowBxCompatible !== onlyShowBxCompatible
    ) {
      this.setFilteredManufacturers();
    }
  }

  get selectedManufacturer() {
    const { manufacturers, selectedManufacturerId } = this.props;
    return manufacturers.find((m) => m.id === selectedManufacturerId);
  }

  setFilteredManufacturers() {
    const { projectType, manufacturers, onlyShowUlListedAndCompatible, onlyShowBxCompatible } = this.props;
    const { searchTerm } = this.state;

    const onlyShowCheckbox = projectType === "BX" ? onlyShowBxCompatible : onlyShowUlListedAndCompatible;

    let filteredManufacturers;
    if (searchTerm.length === 0 && !onlyShowCheckbox) {
      filteredManufacturers = manufacturers;
    } else {
      filteredManufacturers = manufacturers.filter(this.shouldIncludeManufacturerInFilteredList);
    }

    this.setState({ filteredManufacturers });
  }

  searchTermChange = (searchTerm) => {
    this.setState({ searchTerm }, () => this.setFilteredManufacturers());
  };

  selectCollectionItemFromCurrentSelectionIndex = () => {
    const { currentSelectionIndex, filteredManufacturers } = this.state;
    const index = currentSelectionIndex || 0;

    const manufacturer = filteredManufacturers[index];
    if (manufacturer === undefined) return;

    this.selectManufacturer(manufacturer.id);
  };

  shouldIncludeManufacturerInFilteredList = (manufacturer) => {
    const { projectType, onlyShowUlListedAndCompatible, onlyShowBxCompatible } = this.props;
    const { searchTerm } = this.state;

    if (searchTerm.length > 0) {
      const includesSearchTerm = manufacturer.name.toLowerCase().includes(searchTerm.toLowerCase());
      if (!includesSearchTerm) return false;
    }

    if (projectType === "BX" && onlyShowBxCompatible) {
      return manufacturer.active_bx_compatible_and_ul_listed_panels_count > 0;
    }
    if (projectType !== "BX" && onlyShowUlListedAndCompatible) {
      return manufacturer.active_ul_approved_panels_count > 0;
    }

    return true;
  };

  editManufacturer = (event) => {
    event.preventDefault();
    this.selectManufacturer(undefined);
  };

  setCurrentSelectionIndex = (currentSelectionIndex) => {
    this.setState({ currentSelectionIndex });
  };

  selectManufacturer(id) {
    const { selectManufacturer } = this.props;
    selectManufacturer(id);
    this.setState(
      {
        searchTerm: "",
        currentSelectionIndex: undefined,
      },
      () => this.setFilteredManufacturers(),
    );
  }

  renderManufacturer(manufacturer, index) {
    const { currentSelectionIndex } = this.state;
    const { projectType } = this.props;
    const classNames = ["pp__manufacturers__manufacturer"];
    if (currentSelectionIndex === index) {
      classNames.push("pp__manufacturers__manufacturer--selected");
    }

    let markerCount;
    let markerMessage;
    if (projectType === "BX") {
      markerCount = manufacturer.active_bx_compatible_and_ul_listed_panels_count;
      markerMessage = "BX comp & UL listed";
    } else {
      markerCount = manufacturer.active_ul_approved_panels_count;
      markerMessage = "UL listed";
    }

    return (
      <div
        className={classNames.join(" ")}
        key={manufacturer.id}
        onClick={() => this.selectManufacturer(manufacturer.id)}
      >
        <div className="pp__manufacturer__name">{manufacturer.name}</div>
        <div className="pp__manufacturer__marker">
          <div className="pp__manufacturer__marker__count">
            {markerCount}/{manufacturer.active_panels_count}
          </div>
          {markerMessage}
        </div>
      </div>
    );
  }

  renderManufacturers() {
    const { filteredManufacturers } = this.state;

    if (filteredManufacturers.length === 0) {
      return <NoMatchingRecords />;
    }

    return (
      <div className="pp__manufacturers__container">
        {filteredManufacturers.map((m, i) => this.renderManufacturer(m, i))}
      </div>
    );
  }

  renderEditManufacturer() {
    const { selectedManufacturer } = this;

    if (!selectedManufacturer) return "";

    return (
      <div style={{ paddingBottom: "1rem" }}>
        <span>Manufacturer:</span>{" "}
        <a href="#" onClick={this.editManufacturer} className="pp__manufacturer-edit-link">
          <b>{selectedManufacturer.name}</b> <i className="fas fa-pencil-alt" />
        </a>
      </div>
    );
  }

  render() {
    const { selectedManufacturerId } = this.props;
    const { searchTerm, currentSelectionIndex, filteredManufacturers } = this.state;

    if (selectedManufacturerId) return this.renderEditManufacturer();

    return (
      <div className="ir-form">
        <h4 className="pp__select-title">Select a manufacturer</h4>
        <SearchField
          id="manufacturer-search"
          searchTerm={searchTerm}
          searchTermChange={this.searchTermChange}
          currentSelectionIndex={currentSelectionIndex}
          setCurrentSelectionIndex={this.setCurrentSelectionIndex}
          collection={filteredManufacturers}
          selectCollectionItemFromCurrentSelectionIndex={this.selectCollectionItemFromCurrentSelectionIndex}
          collectionCount={filteredManufacturers.length}
        />
        {this.renderManufacturers()}
      </div>
    );
  }
}

export default ManufacturerSelect;
