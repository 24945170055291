import { Controller } from "@hotwired/stimulus";
import * as animate from "../../../../helpers/animate";

export default class RailFormController extends Controller {
  static targets = ["railTypeChangeMessage"];

  changeRailType(_event) {
    animate.show(this.railTypeChangeMessageTarget);
  }
}
