import DaBase from "../../../../da/map/interaction-managers/modify/base";
import { modifyObstruction, removeObstructionBuffer } from "../../modification-helpers/obstruction";

export default class Obstructions extends DaBase {
  modifyStartForFeature(oFeature) {
    removeObstructionBuffer(oFeature, this.mapModelSynchronizer, this.mapManager);
  }

  modifyEndForFeature(oFeature) {
    modifyObstruction(oFeature, this.mapModelSynchronizer, this.mapManager);
  }
}
