import DaRoofPlanes from "../../../../da/map/interaction-managers/select/roof-planes";
import { modifyRoofPlane, deleteRoofPlane } from "../../modification-helpers/roof-plane";
import { MEASURE_DATA_TYPE, ROOF_PLANE_DATA_TYPE } from "../../../../da/map/data-types";

export default class RoofPlanes extends DaRoofPlanes {
  constructor(controller) {
    super(controller);

    this.modifyRoofPlane = modifyRoofPlane;
    this.deleteRoofPlane = deleteRoofPlane;
  }

  filterSelects = (_feature, layer) => {
    const layerDataType = layer && layer.get("dataType");
    const selectedBubbleAtVertex = layer === null;

    return selectedBubbleAtVertex || layerDataType === ROOF_PLANE_DATA_TYPE || layerDataType === MEASURE_DATA_TYPE;
  };
}
