import { Fill, Style, Text, Icon, Stroke, Circle } from "ol/style";
import { toLonLat } from "ol/proj";
import Point from "ol/geom/Point";
import { getDistance } from "ol/sphere";
import { GeometryType } from "../ol-helpers";
import { debounce } from "lodash";

import { inchesToFeetAndInches, logger } from "../../../helpers/app";
import { lineTooShortToShowOrnamentation } from "../ol-helpers";
import { buildSimpleEdgeList, buildLengthCorrectedEdgeList } from "../edge-list";
import { pill } from "./helpers";

export function lineWithDistancePills(feature, map, activeLineRGB, inactiveRGB, lineStringStrokeWidth, showEndDots) {
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();

  const activeLineColor = `rgba(${activeLineRGB})`;
  const inactiveColorOpaque = `rgba(${inactiveRGB}, 1.0)`;
  const inactiveColorTransparent = `rgba(${inactiveRGB}, 0.5)`;

  const lineStringStroke = new Stroke({ color: activeLineColor, width: lineStringStrokeWidth });
  const polygonStroke = new Stroke({ color: inactiveColorOpaque, width: 3 });
  const stroke = geometryType === GeometryType.POLYGON ? polygonStroke : lineStringStroke;

  let image;
  if (showEndDots) {
    image = new Circle({
      radius: 5,
      fill: new Fill({ color: inactiveColorOpaque }),
      snapToPixel: true,
    });
  }
  const styles = [
    new Style({
      stroke,
      fill: new Fill({ color: inactiveColorTransparent }),
      image,
    }),
  ];

  if (geometryType === GeometryType.LINE_STRING) {
    const [startPoint, endPoint] = lastSegment(geometry);
    const distance = feature.get("slopeAdjustedDistance") || distanceInInchesBetweenCoordinates(startPoint, endPoint);
    if (!lineTooShortToShowOrnamentation(map, startPoint, endPoint, 70)) {
      styles.push(buildDimensionStyle(startPoint, endPoint, activeLineColor, distance, map));
    }
    printDimensionMarkerDistance(distance);
  }

  return styles;
}

const printDimensionMarkerDistance = debounce((distance) => {
  logger(`dimension marker distance: ${inchesToFeetAndInches(distance)} (${distance}")`);
}, 200);

function lastSegment(lineString) {
  let priorPoint;
  let currentPoint;

  lineString.forEachSegment((start, end) => {
    priorPoint = start.slice();
    currentPoint = end.slice();
  });

  return [priorPoint, currentPoint];
}

function buildDimensionStyle(startPoint, endPoint, strokeColor, distanceInInches, map) {
  const dx = endPoint[0] - startPoint[0];
  const dy = endPoint[1] - startPoint[1];
  const rotationStartToEnd = Math.atan2(dy, dx);

  // OpenLayers has positive rotation to the SE
  const pillRotation = -rotationStartToEnd;

  const mapRotation = map.getView().getRotation();
  let netRotation = mapRotation + pillRotation;

  // keep netRotation between -PI and +PI
  if (netRotation < -Math.PI) {
    netRotation += Math.PI;
  } else if (netRotation > Math.PI) {
    netRotation -= Math.PI;
  }

  // flip text over if it in quadrant 2 or 3
  let textRotation = netRotation;
  if (netRotation > Math.PI / 2.0) {
    textRotation -= Math.PI;
  } else if (netRotation < -Math.PI / 2.0) {
    textRotation += Math.PI;
  }

  const midPoint = [(startPoint[0] + endPoint[0]) / 2.0, (startPoint[1] + endPoint[1]) / 2.0];
  const label = inchesToFeetAndInches(distanceInInches);

  const width = IR_GLOBALS.dimensionLabelsPrecision ? 60 + 5 * IR_GLOBALS.dimensionLabelsPrecision : 60;

  const dimensionStyle = new Style({
    geometry: new Point(midPoint),
    image: new Icon({
      img: pill({ width, height: 25, strokeColor }),
      imgSize: [width, 25],
      rotateWithView: true,
      rotation: netRotation - mapRotation,
    }),
    text: new Text({
      text: label,
      font: "bold 12px sans-serif",
      fill: new Fill({ color: "white" }),
      rotateWithView: true,
      rotation: textRotation - mapRotation,
    }),
    zIndex: 5,
  });

  return dimensionStyle;
}

export function distanceInInchesBetweenCoordinates(startPoint, endPoint) {
  const startPointLonLat = toLonLat(startPoint);
  const endPointLonLat = toLonLat(endPoint);
  return getDistance(startPointLonLat, endPointLonLat) * 3.28084 * 12.0;
}

export function addDimensionMarkersToEdges(feature, controller, styles, lineColor) {
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();
  if (geometryType === GeometryType.POLYGON) {
    let edgeList;
    if (controller.project.shouldLengthCorrectEdges) {
      edgeList = buildLengthCorrectedEdgeList(feature, controller);
    } else {
      const coordinates = geometry.getLinearRing(0).getCoordinates();
      edgeList = buildSimpleEdgeList(coordinates);
    }
    const { map } = controller.mapManager;
    addDimensionMarkersToSegmentsBetweenCoordinates(styles, map, lineColor, edgeList);
  }
}

export function addDimensionMarkersToSegmentsBetweenCoordinates(styles, map, lineColor, edgeList) {
  edgeList.forEach((edge) => {
    const [startPoint, endPoint] = edge.segment;
    const { distance } = edge;

    if (!lineTooShortToShowOrnamentation(map, startPoint, endPoint, 70)) {
      styles.push(buildDimensionStyle(startPoint, endPoint, lineColor, distance, map));
    }
  });
}
