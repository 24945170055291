import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import throttle from "lodash/throttle";

function DragAndDrop({ onDragStart, onDragMove, onDragEnd, children }) {
  const containerRef = useRef();

  const [mouseDown, setMouseDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const [endX, setEndX] = useState(null);
  const [endY, setEndY] = useState(null);
  const [spacebar, setSpacebar] = useState(false);
  const [startScrollX, setStartScrollX] = useState(null);
  const [startScrollY, setStartScrollY] = useState(null);

  const onMouseDown = (event) => {
    if (!containerRef) return;

    const eventPos = eventPosition(event, containerRef.current);
    const newStartX = eventPos.x;
    const newStartY = eventPos.y;
    const newEndX = eventPos.x;
    const newEndY = eventPos.y;

    setMouseDown(true);
    setStartX(newStartX);
    setStartY(newStartY);
    setEndX(newEndX);
    setEndY(newEndY);
    setStartScrollX(window.pageXOffset);
    setStartScrollY(window.pageYOffset);

    onDragStart({ startX: newStartX, startY: newStartY, endX: newEndX, endY: newEndY, spacebar });
  };

  const eventPosition = (event, offsetTarget) => {
    const target = offsetTarget || event.target;
    const offset = target.getBoundingClientRect();
    const x = event.clientX - offset.left - window.pageXOffset;
    const y = event.clientY - offset.top - window.pageYOffset;
    return { x, y };
  };

  useEffect(() => {
    const calculateNewStart = (eventPos) => {
      let newStartX = startX;
      let newStartY = startY;

      if (spacebar) {
        const deltaX = eventPos.x - endX;
        const deltaY = eventPos.y - endY;

        newStartX += deltaX;
        newStartY += deltaY;
      }

      const deltaScrollX = window.pageXOffset - startScrollX;
      const deltaScrollY = window.pageYOffset - startScrollY;

      if (Math.abs(deltaScrollX) > 0) {
        newStartX -= deltaScrollX;
        setStartScrollX(window.pageXOffset);
      }
      if (Math.abs(deltaScrollY) > 0) {
        newStartY -= deltaScrollY;
        setStartScrollY(window.pageYOffset);
      }

      setStartX(newStartX);
      setStartY(newStartY);

      return { newStartX, newStartY };
    };

    const onMouseMove = (event) => {
      if (!mouseDown || !containerRef) return;

      const eventPos = eventPosition(event, containerRef.current);

      const { newStartX, newStartY } = calculateNewStart(eventPos);
      const newEndX = eventPos.x;
      const newEndY = eventPos.y;

      setEndX(newEndX);
      setEndY(newEndY);

      onDragMove({ startX: newStartX, startY: newStartY, endX: newEndX, endY: newEndY, spacebar });
    };

    const throttledOnMouseMove = throttle(onMouseMove, 100, { leading: true });

    const onMouseUp = (_event) => {
      throttledOnMouseMove.cancel();

      const didSomeDragging = Math.abs(endX - startX) > 2 || Math.abs(endY - startY) > 2;
      if (didSomeDragging) onDragEnd({ startX, startY, endX, endY, spacebar: false });

      setMouseDown(false);
      setSpacebar(false);
      setStartX(0);
      setStartY(0);
      setEndX(0);
      setEndY(0);
      setStartScrollX(0);
      setStartScrollY(0);
    };

    const keyToggleSpacebar = (event, down) => {
      // spacebar
      if (event.keyCode !== 32 || !mouseDown) return;

      event.preventDefault();
      event.stopPropagation();
      setSpacebar(down);
    };

    const onKeydown = (event) => keyToggleSpacebar(event, true);

    const onKeyup = (event) => keyToggleSpacebar(event, false);

    window.addEventListener("mousemove", throttledOnMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("keydown", onKeydown);
    window.addEventListener("keyup", onKeyup);

    return () => {
      window.removeEventListener("mousemove", throttledOnMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("keydown", onKeydown);
      window.removeEventListener("keyup", onKeyup);
    };
  }, [
    mouseDown,
    containerRef,
    endX,
    endY,
    spacebar,
    startX,
    startY,
    onDragMove,
    onDragEnd,
    startScrollX,
    startScrollY,
  ]);

  return (
    <div onMouseDown={onMouseDown} className="drag-and-drop" ref={containerRef}>
      {children}
    </div>
  );
}

DragAndDrop.propTypes = {
  onDragStart: PropTypes.func,
  onDragMove: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  children: PropTypes.node,
};

DragAndDrop.defaultProps = {
  onDragStart: () => {},
  children: undefined,
};

export default DragAndDrop;
