import * as store from "./local-store";

export const GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT = "layout";
export const GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR = "contour";

const LOCAL_STORE_KEY_PREFIX = "gtem"; // Graphical tool editor mode

export function defaultEditorMode({ roofSectionId, arrayEditingAllowed, contourEditingAllowed }) {
  const localStoreKey = `${LOCAL_STORE_KEY_PREFIX}-${roofSectionId}`;
  if (store.get(localStoreKey) === GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR && contourEditingAllowed)
    return GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR;
  if (store.get(localStoreKey) === GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT && arrayEditingAllowed)
    return GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT;

  return !arrayEditingAllowed && contourEditingAllowed
    ? GRAPHICAL_TOOL_EDITOR_MODE_CONTOUR
    : GRAPHICAL_TOOL_EDITOR_MODE_LAYOUT;
}

export function storeEditorMode({ roofSectionId, editorMode }) {
  const localStoreKey = `${LOCAL_STORE_KEY_PREFIX}-${roofSectionId}`;
  store.set(localStoreKey, editorMode);
}
