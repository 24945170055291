import React, { Component } from "react";
import { sentryException } from "../../config/sentry";

class SentryErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      error: props.error || null,
      hasError: false,
      firstChild: props.children[0],
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    sentryException(error, errorInfo, (eventId) => {
      this.setState({ eventId });
    });
  }

  get errorMessage() {
    const { error } = this.state;
    if (error === null) return null;
    if (typeof error === "string") return error;
    if (error.message !== undefined) return error.message;
    return error;
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="raven-error-container">
          <h4>Sorry, we encountered an error</h4>
          <p>We've been notified about the problem</p>
          <div className="nitty-gritty">
            <b>Error message:</b>
            <br />
            {this.errorMessage}
            <br />
            Event ID: {this.state.eventId}
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default SentryErrorHandler;
