import { Controller } from "@hotwired/stimulus";
import * as animate from "../../../helpers/animate";

export default class extends Controller {
  static targets = ["allBtn", "identifierBtn"];

  connect() {
    this.element[this.identifier] = this;
  }

  btnClick(event) {
    const identifier = event.currentTarget.dataset.identifier;
    this.mapController.restoreRoofPlaneDefaultRoofSectionGivenIdentifier(identifier);
  }

  get mapController() {
    return document.querySelector("[data-controller='bx--layout-editor--map-roof-sections']")[
      "bx--layout-editor--map-roof-sections"
    ];
  }

  updateUI(roofPlanes) {
    const roofPlanesWithInactiveDefaults = roofPlanes.filter((rp) => rp.hasInactiveDefaultRoofSections);

    animate.toggle(this.element, roofPlanesWithInactiveDefaults.length > 0);
    this.allBtnTarget.disabled = roofPlanesWithInactiveDefaults.length === 1;

    const roofPlanesWithInactiveDefaultsIdentifiers = roofPlanesWithInactiveDefaults.map((rp) => rp.identifier);
    this.identifierBtnTargets.forEach((btnTarget) => {
      const identifier = btnTarget.dataset.identifier;
      btnTarget.disabled = !roofPlanesWithInactiveDefaultsIdentifiers.includes(identifier);
      btnTarget.classList.remove("ir-btn--disabled");
    });
  }
}
