import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import * as animate from "../../../../helpers/animate";
import { Form } from "../../../../helpers/form";

export default class PrSpansEditorSpanOverridesController extends Controller {
  static targets = [
    "form",
    "submitBtn",
    "submitBtnCheckIcon",
    "submitBtnSpinner",
    "addBtnContainer",
    "fieldContainer",
    "overrideDisplayContainer",
    "resetForm",
  ];

  static values = {
    isOverridePersisted: Boolean,
    hasValidationError: Boolean,
    overrideSpan: Number,
    resetUrl: String,
  };

  submit(event) {
    event.preventDefault();

    this.submitBtnTarget.disabled = true;
    this.submitBtnCheckIconTarget.classList.add("d-none");
    this.submitBtnSpinnerTarget.classList.remove("d-none");

    setTimeout(() => {
      Rails.fire(this.formTarget, "submit");
    }, 200);
  }

  addOverrideClick(_event) {
    this.addBtnContainerTarget.classList.remove("d-flex");
    animate.hide(this.addBtnContainerTarget, { fadeOut: false });
    animate.show(this.fieldContainerTarget, { display: "flex" });
    this.firstField.focus();
  }

  editOverrideClick(_event) {
    this.overrideDisplayContainerTarget.classList.remove("d-flex");
    animate.hide(this.overrideDisplayContainerTarget, { fadeOut: false });
    animate.show(this.fieldContainerTarget, { display: "flex" });
    this.firstField.focus();
  }

  get firstField() {
    const selectField = this.formTarget.querySelector("select");
    const textField = this.formTarget.querySelector("input[type='text']");
    return selectField || textField;
  }

  cancel(_event) {
    if (this.hasValidationErrorValue) {
      if (this.isOverridePersistedValue) {
        Form.getRemote(this.resetUrlValue);
        return;
      } else {
        this.#clearValidationErrorsAndResetForm();
      }
    }

    if (this.isOverridePersistedValue) {
      animate.show(this.overrideDisplayContainerTarget, { display: "flex" });
    } else {
      animate.show(this.addBtnContainerTarget, { display: "flex" });
    }
    this.fieldContainerTarget.classList.remove("d-flex");
    animate.hide(this.fieldContainerTarget, { fadeOut: false });
  }

  #clearValidationErrorsAndResetForm() {
    if (!this.hasValidationErrorValue) return;

    const fieldContainer = this.fieldContainerTarget;

    const fieldWithErrorsWrapper = fieldContainer.querySelector(".field_with_errors");
    if (fieldWithErrorsWrapper) fieldWithErrorsWrapper.classList.remove("field_with_errors");

    const validationError = fieldContainer.querySelector(".ir-text-message--error");
    if (validationError) validationError.remove();

    const selectField = this.formTarget.querySelector("select");
    if (selectField) {
      selectField.value = "";
    } else {
      fieldContainer.querySelector("input[name='pr_roof_plane_span_override[span]']").value = "";
      fieldContainer.querySelector("input[name='feet_pr_roof_plane_span_override_span']").value = "";
      fieldContainer.querySelector("input[name='inches_pr_roof_plane_span_override_span']").value = "";
    }
  }
}
