import { BOUNDING_BOX_DATA_TYPE } from "../../../da/map/data-types";

export default function applyPanelModeToArrayFeature(controller, layout, feature) {
  if (feature.get("dataType") === BOUNDING_BOX_DATA_TYPE) return;

  const row = Number.parseInt(feature.get("row"));
  const column = Number.parseInt(feature.get("column"));

  if (layout.isOutOfBounds(row, column)) return;

  if (controller.isPanelModeAdd) {
    layout.addPanel(row, column);
  } else if (controller.isPanelModeRemove) {
    layout.removePanel(row, column);
  } else {
    layout.togglePanel(row, column);
  }
  const finalCellDrawStyle = layout.cellDrawStyle(row, column);
  feature.set("cellDrawStyle", finalCellDrawStyle);
}
