import React from "react";
import PropTypes from "prop-types";

function SelectionMarquee({ x, y, width, height, color }) {
  const classNames = ["graphical-tool__selection-marquee", `graphical-tool__selection-marquee--${color}`];

  const cursorOffset = 1;

  return (
    <svg
      height={height + cursorOffset}
      width={width + cursorOffset}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
      className={classNames.join(" ")}
      style={{ left: `${x}px`, top: `${y}px`, width: `${width}px`, height: `${height}px` }}
      data-testid="base-selection-marquee"
    >
      <rect width={width} height={height} />
    </svg>
  );
}

SelectionMarquee.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string,
};

SelectionMarquee.defaultProps = {
  color: "blue",
};

export default SelectionMarquee;
