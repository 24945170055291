export function linkFeatureToModel(feature, model) {
  feature.set("uuid", model.uuid);
  feature.set("dataType", model.dataType);
  feature.set("illegalShape", model.illegalShape);
  if (model.dataType === "RoofSection") {
    feature.set("identifier", model.displayIdentifier);
    feature.set("roofPlaneUuid", model.roofPlane.uuid);
    feature.set("custom", model.custom);
    feature.set("encroaching", model.encroaching);
    feature.set("fresh", model.fresh);
    feature.set("cellsWithErrors", model.cellsWithErrors);
  } else {
    feature.set("identifier", model.identifier);
  }
}
