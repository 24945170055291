import React from "react";
import PropTypes from "prop-types";

import Marker from "./Marker";

function CompressedSpaceMarkers({ markers, rows, columns }) {
  return markers.map(({ x, y, orientation }) => (
    <Marker key={`${x}${y}${orientation}`} x={x} y={y} orientation={orientation} rows={rows} columns={columns} />
  ));
}

CompressedSpaceMarkers.propTypes = {
  columns: PropTypes.number.isRequired,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
  ),
  rows: PropTypes.number.isRequired,
};

CompressedSpaceMarkers.defaultProps = {
  markers: [],
};

export default React.memo(CompressedSpaceMarkers);
