import React, { Component } from "react";
import PropTypes from "prop-types";
import InfoPopupLink from "../../InfoPopupLink";

import { panelPropTypeShape, customPanelPropTypeShape, manufacturerPropTypeShape, detailsLinkParams } from "../helper";

class ChosenPanelDisplay extends Component {
  static propTypes = {
    openPicker: PropTypes.func.isRequired,
    projectType: PropTypes.string.isRequired,
    panels: PropTypes.arrayOf(panelPropTypeShape),
    customPanels: PropTypes.arrayOf(customPanelPropTypeShape),
    manufacturers: PropTypes.arrayOf(manufacturerPropTypeShape),
    selectedManufacturerId: PropTypes.number,
    selectedPanelId: PropTypes.number,
    disabled: PropTypes.bool,
    efoAvailable: PropTypes.bool.isRequired,
    stopperAvailable: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      manufacturers: props.manufacturers,
      selectedManufacturerId: props.selectedManufacturerId,
      panels: props.panels,
      customPanels: props.customPanels,
      selectedPanelId: props.selectedPanelId,
      disabled: props.disabled,
      railPlatform: props.railPlatform,
    };
  }

  get selectedManufacturer() {
    const { selectedManufacturerId: id, manufacturers } = this.state;
    if (!id) return undefined;

    return manufacturers.find((m) => m.id === id);
  }

  get selectedPanel() {
    const { selectedPanelId: id } = this.state;
    if (!id) return undefined;

    return this.allPanels.find((p) => p.id === id);
  }

  get allPanels() {
    const { panels, customPanels } = this.state;
    if (!panels) {
      return customPanels;
    } else if (!customPanels) {
      return panels;
    } else {
      return panels.concat(customPanels);
    }
  }

  renderChooseOne() {
    if (this.selectedPanel) return "";

    return <div className="pp__chosen-panel__choose-panel">- choose one -</div>;
  }

  renderChosenPanel() {
    if (!this.selectedPanel) return "";

    const { currentUser, projectPanelUser } = this.props;
    const { name: panelName, user_id: panelUserId, retired } = this.selectedPanel;

    const isPanelCustomAndDoesNotBelongToCurrentUser =
      this.selectedPanel.user_id && currentUser && projectPanelUser && panelUserId !== currentUser.id;

    let manName;
    if (panelUserId) {
      manName = "Custom Panels";
    } else {
      manName = this.selectedManufacturer.name;
    }

    return (
      <>
        <div className="pp__chosen-panel__manufacturer">{manName}</div>
        <div className="pp__chosen-panel__panel">
          {panelName}
          {retired && <span className="pp__chosen-panel__panel__warning">(Retired)</span>}
          {isPanelCustomAndDoesNotBelongToCurrentUser && (
            <span className="pp__chosen-panel__panel__warning">
              (owned by {projectPanelUser.first_name[0]}. {projectPanelUser.last_name})
            </span>
          )}
        </div>
      </>
    );
  }

  renderChosenPanelDimensions() {
    if (!this.selectedPanel) return "";

    const hasFlangeInfo =
      this.selectedPanel.flange_dimensions !== "" && this.selectedPanel.flange_dimensions !== undefined;

    return (
      <>
        <div className="pp__chosen-panel__dimensions" data-identifier="dimensions">
          {this.selectedPanel.dimensions}
        </div>
        {hasFlangeInfo && (
          <div className="pp__chosen-panel__dimensions pp__chosen-panel__flange-dimensions">
            <span className="d-inline-block pe-2">{this.selectedPanel.flange_dimensions}</span>
            <InfoPopupLink dialogIdentifier="info-popup-panel-picker-flange-dimensions" />
          </div>
        )}
      </>
    );
  }

  renderChosenPanelNotUlListedStatus() {
    const panel = this.selectedPanel;
    if (!panel || panel.user_id) return "";

    const ulStatus = panel.ul_listed_status;
    if (ulStatus === "approved" || ulStatus === "in process") return "";

    let message;
    if (ulStatus === "pending") {
      message = "IronRidge UL Compatibility Pending";
    } else {
      message = "Not IronRidge UL Compatible";
    }

    return (
      <div className="pp__chosen-panel__ul-pending">
        <i className="fas fa-exclamation-triangle" />
        {message} <InfoPopupLink dialogIdentifier="info-popup-panel-not-ul-listed" />
      </div>
    );
  }

  renderChosenPanelWeight() {
    const panel = this.selectedPanel;
    if (!panel) return "";

    return <div className="pp__chosen-panel__weights">{panel.weights}</div>;
  }

  renderDetailsLink() {
    if (!this.selectedPanel) return "";

    const panel = this.selectedPanel;
    const { projectType, projectId, efoAvailable, stopperAvailable } = this.props;
    const { railPlatform } = this.state;

    return (
      <a
        href="#"
        data-controller="components--ir-dialog--turbo-frame-details"
        {...detailsLinkParams(panel, projectType, projectId, railPlatform, efoAvailable, stopperAvailable)}
      >
        <small className="text-nowrap">
          <i className="fas fa-magnifying-glass me-2" />
          Details
        </small>
      </a>
    );
  }

  render() {
    const { openPicker, projectType } = this.props;
    const { disabled } = this.state;
    const classes = ["pp__chosen-panel"];
    if (disabled) {
      classes.push("pp__chosen-panel--disabled");
    }

    return (
      <>
        <div className="d-flex">
          <label className="d-block">Panel</label>
          <div className="ms-auto">{this.renderDetailsLink()}</div>
        </div>
        <div>
          <button type="button" onClick={openPicker} className={classes.join(" ")}>
            <div className="pp__chosen-panel__edit-icon">
              <i className="fas fa-pen-square" />
            </div>
            {this.renderChosenPanel()}
            {this.renderChooseOne()}
          </button>
          {this.renderChosenPanelDimensions()}
          <div className="d-flex">
            {projectType === "BX" && this.renderChosenPanelWeight()}
            {this.renderChosenPanelNotUlListedStatus()}
          </div>
        </div>
      </>
    );
  }
}

export default ChosenPanelDisplay;
