import { types, getParent } from "mobx-state-tree";

import WithLatLngPoints from "./with-lat-lng-points";
import WithDirtyTracking from "./with-dirty-tracking";

import { ROOF_SECTION_DATA_TYPE } from "../map/data-types";

const RoofSectionBase = types
  .model("RoofSectionModel", {
    uuid: types.identifier,
    id: types.maybe(types.integer),
    deleted: types.optional(types.boolean, false),
    identifier: types.optional(types.integer, 1),
    illegalShape: types.optional(types.boolean, false),
    encroaching: types.optional(types.boolean, false),
    display: types.optional(types.boolean, true),
  })
  .views((self) => ({
    get roofPlane() {
      return getParent(self, 2);
    },
    get dataType() {
      return ROOF_SECTION_DATA_TYPE;
    },
    get notDeleted() {
      return !self.deleted;
    },
  }))
  .actions((self) => ({
    setIllegalShape(legality) {
      if (self.illegalShape === legality) return;

      self.markDirty();
      self.illegalShape = legality;
    },
    setIdentifier(newIdentifier) {
      if (self.identifier === newIdentifier) return;

      self.markDirty();
      self.identifier = newIdentifier;
    },
    setEncroaching(status) {
      if (self.encroaching === status) return;

      self.markDirty();
      self.encroaching = status;
    },
  }));

const DaRoofSectionModel = types.compose(RoofSectionBase, WithLatLngPoints, WithDirtyTracking);
export default DaRoofSectionModel;
