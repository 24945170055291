import { types } from "mobx-state-tree";

import WithDirtyTracking from "../../models/with-dirty-tracking";

const GuideLineModelBase = types
  .model("GuideLineModel", {
    segmentIndex: types.number,
    visible: types.boolean,
    distance: types.number,
    considerVisibilityWhenDeterminingDirtiness: types.optional(types.boolean, true),
  })
  .views((self) => ({
    get needsSave() {
      return self.dirty;
    },
  }))
  .actions((self) => ({
    setVisibility(visible) {
      if (self.visible === visible) return;

      if (self.considerVisibilityWhenDeterminingDirtiness) {
        self.markDirty();
      }

      self.visible = visible;
    },
    toggleVisibility() {
      self.setVisibility(!self.visible);
    },
    setDistance(distance) {
      if (self.distance === distance) return;

      self.markDirty();
      self.distance = distance;
    },
  }));

export function guideLinePersistenceData(guideLine) {
  return {
    segment_index: guideLine.segmentIndex,
    visible: guideLine.visible,
    distance: guideLine.distance,
  };
}

const GuideLineModel = types.compose(GuideLineModelBase, WithDirtyTracking);
export default GuideLineModel;
