import SegmentsRailGroup from "../../map-model-synchronizers/segments-rail-group";

export default class SegmentsDeleter {
  constructor({ controller, selectionCollection }) {
    this.controller = controller;
    this.selectionCollection = selectionCollection;

    this.railGroups = SegmentsRailGroup.uniqueGroupsFromSegmentFeatures({
      controller,
      features: selectionCollection.getArray(),
    });
  }

  delete() {
    this.railGroups.forEach((railGroup) => {
      railGroup.selectedSegments.forEach((segment) => {
        railGroup.deleteSegment(segment);
      });
      railGroup.reRenderSegments();
    });
  }
}
