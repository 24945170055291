import { Controller } from "@hotwired/stimulus";

// Behavior added to the ir-submit-btn which prevent double click
// and adds a spinner animation
export default class extends Controller {
  lastButton = null;

  handleSubmit = (event) => {
    const button = event.currentTarget;
    event.preventDefault();
    if (!button.classList.contains("ir-btn--spinner-shown")) {
      const clickEvent = new MouseEvent("click", { bubbles: true });
      button.classList.add("ir-btn--spinner-shown");
      this.lastButton = button;
      // this is to give the browser time to show the animation
      setTimeout(() => button.dispatchEvent(clickEvent), 200);
    }
  };

  handleResetSubmit = (_event) => {
    if (this.lastButton.classList.contains("ir-btn--spinner-shown")) {
      this.lastButton.classList.remove("ir-btn--spinner-shown");
    }
  };
}
