import BufferBuilder from "../../../da/map/buffer-builder";
import { linkFeatureToModel } from "../../../da/map/modification-helpers/base";
import { logger } from "../../../helpers/app";
import { syncObstructionCartesianPoints } from "../modification-helpers/obstruction";

export default class ObstructionsBufferBuilder extends BufferBuilder {
  constructor(controller, map, obstructionFeatures, obstructionBufferFeatures) {
    super();

    this.controller = controller;
    this.map = map;
    this.obstructionFeatures = obstructionFeatures;
    this.obstructionBufferFeatures = obstructionBufferFeatures;

    this.project = controller.project;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;

    this.bufferObstructions();
  }

  bufferObstructions() {
    logger("Buffering obstructions");
    this.project.notDeletedObstructions.forEach((obstruction) => {
      logger(`Buffering obstruction: ${obstruction.identifier}`);
      if (isNaN(obstruction.height) || obstruction.height < 0.0) return;

      if (obstruction.dirty || !obstruction.hasBuffer) {
        this.buildBufferForObstruction(obstruction);
        this.controller.markDirty();
      }
    });
  }

  buildBufferForObstruction(obstruction) {
    const matchingObstructionFeature = this.obstructionFeatures.find((feature) => {
      return feature.get("uuid") === obstruction.uuid;
    });
    this.#removeOldObstructionBuffer(obstruction);

    // this prevents building a new buffer for illegal shapes
    if (obstruction.illegalShape) return;

    if (!matchingObstructionFeature) {
      debugger;
    }

    logger("  Building new buffer");
    const outset = obstruction.height;

    const translatedFeaturesCollection = this.translateOpenLayersFeaturesToTurf(matchingObstructionFeature);
    const bufferedFeatures = this.buildBufferFeatures(translatedFeaturesCollection, outset);
    this.translateTurfFeaturesToModels(bufferedFeatures);
  }

  buildModelsFromTurfFeatures(featuresFromBuffering) {
    return featuresFromBuffering.flatMap((feature) => {
      const obstructionUuid = feature.get("uuid");
      const obstruction = this.project.getObstruction(obstructionUuid);

      const polygonFeatures = this.mapMultiPolygon(feature);

      polygonFeatures.forEach((polygonFeature) => {
        // only use the outer ring because thats all we save and restore
        const polygonGeometry = polygonFeature.getGeometry();
        const outerRingCoordinates = polygonGeometry.getLinearRing(0).getCoordinates();
        polygonGeometry.setCoordinates([outerRingCoordinates]);

        const obstructionBuffer = obstruction.addBuffer();

        const latLngPoints = this.mapModelSynchronizer.latLngPointsFromFeature(polygonFeature);
        obstructionBuffer.setLatLngPoints(latLngPoints);
        syncObstructionCartesianPoints(obstructionBuffer);

        this.controller.mapModelSynchronizer.setGlobalCartesianOffset(latLngPoints, obstructionBuffer);

        this.addObstructionBufferPolygonFeatureToMap(polygonFeature, obstructionBuffer);
      });
    });
  }

  addObstructionBufferPolygonFeatureToMap(polygonFeature, obstructionBuffer) {
    linkFeatureToModel(polygonFeature, obstructionBuffer);
    this.controller.mapManager.obstructionBuffersVectorSource.addFeature(polygonFeature);
  }

  #removeOldObstructionBuffer(obstruction) {
    const matchingObstructionBufferFeature = this.obstructionBufferFeatures.find((feature) => {
      return feature.get("uuid") === obstruction.uuid;
    });

    logger("  Removing old buffer");
    obstruction.removeBuffer();
    this.controller.mapManager.obstructionBuffersVectorSource.removeFeature(matchingObstructionBufferFeature);
  }
}
