import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tagsContainer"];

  static values = {
    formObjectName: String,
    tags: Array,
  };

  connect() {
    this.tags = this.tagsValue;
  }

  removeTag(event) {
    event.preventDefault();
    const tag = event.params.tag;
    this.tags = this.tags.filter((t) => t !== tag);
    event.currentTarget.remove();
  }

  textFieldInput(event) {
    if (event.type === "blur" || event.key === "Enter") {
      if (event.type !== "blur") event.preventDefault();

      const textField = event.currentTarget;
      this.#addTagsFromTextFieldText(textField.value);
      textField.value = "";
    }
  }

  #addTagsFromTextFieldText(text) {
    if (text === "") return;
    const tags = this.#tagsFromText(text);
    tags.forEach((tag) => this.#addTag(tag));
  }

  #tagsFromText(text) {
    if (text.trim() === "") return [];

    let tags = text
      .split(",")
      .map((t) => {
        return t
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "_")
          .replace(/-+/g, "_")
          .replace(/[^a-zA-Z0-9_]+/, "");
      })
      .filter((t) => t !== "" && !this.tags.includes(t));

    tags = [...new Set(tags)];

    tags.forEach((t) => this.tags.push(t));

    return tags;
  }

  #addTag(tagName) {
    if (tagName.trim() === "") return;

    const html = `
      <a class="tags-manager__tag-link"
        data-action="click->components--ir-tags-manager#removeTag"
        data-components--ir-tags-manager-tag-param="${tagName}"
        data-controller="components--ir-tooltip"
        data-components--ir-tooltip-content-value="remove" href="#">
        <input value="${tagName}" multiple="multiple" autocomplete="off" type="hidden" name="s3_file[tags][]">
        <small class="ir-tag badge rounded-pill bg-yellow-100 text-yellow-800 text-nowrap"><i class="fas fa-tag me-2" aria-hidden="true"></i>${tagName}</small>
      </a>
    `;

    this.tagsContainerTarget.innerHTML += html;
  }
}
