import throttle from "lodash/throttle";

import DaBase from "../../../../da/map/interaction-managers/select/base";
import IrDragBox, { DRAG_BOX_CLASSNAME } from "../../../../da/map/ol-extensions/interaction/DragBox";

export default class Base extends DaBase {
  addDragBoxInteraction() {
    this.#clearCurrentDragBoxInteraction();
    this.currentDragBoxInteraction = new IrDragBox({ className: DRAG_BOX_CLASSNAME });
    this.map.addInteraction(this.currentDragBoxInteraction);
    this.#toggleBoxEvents(true);
  }

  clearDragBoxInteraction() {
    this.#clearCurrentDragBoxInteraction();
  }

  clearCurrentInteraction() {
    super.clearCurrentInteraction();
    this.#clearCurrentDragBoxInteraction();
  }

  #toggleBoxEvents(set) {
    const setState = set ? "on" : "un";
    this.currentDragBoxInteraction[setState]("boxstart", this.#boxStart);
    this.currentDragBoxInteraction[setState]("boxdrag", this.#throttledBoxDrag);
    this.currentDragBoxInteraction[setState]("boxend", this.#boxEnd);
  }

  #boxStart = (event) => {
    const originalEvent = event.mapBrowserEvent.originalEvent;
    this.draggingWithAltKey = originalEvent.altKey;
    this.draggingWithShiftKey = originalEvent.shiftKey;

    if (!this.draggingWithAltKey && this.draggingWithShiftKey) {
      this.selectionCollection.forEach((feature) => feature.set("previouslySelected", true));
    }
    if (this.onDraggingSelectionStart) this.onDraggingSelectionStart(event);
  };

  #boxDrag = (_event) => {
    this.draggingSelectionMarquee = true;
    if (!this.draggingWithAltKey) {
      if (this.draggingWithShiftKey) {
        // While dragging with shift key we want to refresh the dragged selection
        // but only for selections added during this drag interaction.  We want to
        // keep the old selections around so we can add to them.
        const removals = [];
        this.selectionCollection.forEach((feature) => {
          if (!feature.get("previouslySelected")) removals.push(feature);
        });
        removals.forEach((feature) => this.selectionCollection.remove(feature));
      } else {
        this.deselectSelected();
      }
    }

    const selectAction = (feature) => {
      if (this.draggingWithAltKey) {
        this.selectionCollection.remove(feature);
      } else {
        this.selectionCollection.push(feature);
      }
    };

    // https://openlayers.org/en/latest/examples/box-selection.html
    // Features that intersect the box geometry are added to the collection of selected features
    const rotation = this.map.getView().getRotation();
    const dragBox = this.currentDragBoxInteraction;
    const extent = dragBox.getGeometry().getExtent();

    const vectorSource = this.selectionVectorSource;

    const oblique = rotation % (Math.PI / 2) !== 0;
    if (oblique) {
      // When the view is obliquely rotated the box extent will exceed its geometry so both the
      // box and the candidate feature geometries are rotated around a common anchor to confirm
      // that, with the box geometry aligned with its extent, the geometries intersect
      const anchor = [0, 0];
      const geometry = dragBox.getGeometry().clone();
      geometry.rotate(-rotation, anchor);
      const extent$1 = geometry.getExtent();
      vectorSource.getFeatures().forEach((feature) => {
        const geometry = feature.getGeometry().clone();
        geometry.rotate(-rotation, anchor);
        if (geometry.intersectsExtent(extent$1)) selectAction(feature);
      });
    } else {
      // If the view is not obliquely rotated the box geometry and its extent are equivalent so
      // intersecting features can be added directly to the collection
      vectorSource.forEachFeatureIntersectingExtent(extent, (feature) => selectAction(feature));
    }

    if (this.onDraggingSelection) this.onDraggingSelection();
  };

  get selectionVectorSource() {
    return this.mapManager.panelsVectorSource;
  }

  #throttledBoxDrag = throttle((e) => this.#boxDrag(e), 50, { trailing: false });

  #boxEnd = (event) => {
    this.draggingWithShiftKey = undefined;
    this.draggingWithAltKey = undefined;
    this.draggingSelectionMarquee = false;
    this.selectionCollection.forEach((feature) => feature.set("previouslySelected", undefined));

    if (this.onDraggingSelectionEnd) this.onDraggingSelectionEnd(event);
  };

  #clearCurrentDragBoxInteraction() {
    if (!this.currentDragBoxInteraction) return;

    this.map.removeInteraction(this.currentDragBoxInteraction);
    this.#toggleBoxEvents(false);
    this.currentDragBoxInteraction = undefined;
  }
}
