import MapRoofPlanesAndSectionsController from "./map_roof_planes_and_sections_controller";
import DrawInteractionManager from "../../../bx/map/interaction-managers/draw/roof-planes";
import SelectInteractionManager from "../../../bx/map/interaction-managers/select/roof-planes";
import SnapInteractionManager from "../../../bx/map/interaction-managers/snap/roof-planes";
import TranslateInteractionManager from "../../../bx/map/interaction-managers/translate/roof-planes";
import ModifyInteractionManager from "../../../bx/map/interaction-managers/modify/roof-planes";
import MeasureInteractionManager from "../../../da/map/interaction-managers/measure/base";
import RulerInteractionManager from "../../../da/map/interaction-managers/ruler/base";
import { EDITOR_MODE_DRAW } from "../../../da/layout-editor/helpers/toolbar-constants";
import * as toolbarSelectGroup from "../../../da/layout-editor/helpers/toolbar-select-group";

export default class MapRoofPlanesController extends MapRoofPlanesAndSectionsController {
  mapSpecificConnect() {
    this.editorMode = toolbarSelectGroup.getState("bxRoofPlaneEditorMode", EDITOR_MODE_DRAW);
  }

  setupMapInteractionManagers() {
    this.drawInteractionManager = new DrawInteractionManager(this);

    this.selectInteractionManager = new SelectInteractionManager(this);

    this.snapInteractionManager = new SnapInteractionManager(this);
    this.snapInteractionManager.add();

    this.translateInteractionManager = new TranslateInteractionManager(this);

    this.modifyInteractionManager = new ModifyInteractionManager(this);

    this.measureInteractionManager = new MeasureInteractionManager(this);

    this.rulerInteractionManager = new RulerInteractionManager(this);
  }
}
