import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";
import axios from "axios";
import * as api from "../../helpers/api";

export default class extends Controller {
  static targets = [
    "orientationAngleAndSpacingField",
    "setOrientationUrl",
    "setPortraitButton",
    "setLandscapeButton",
    "landscapeViewContainer",
    "portraitViewContainer",
    "bondingBoltContainer",
  ];

  setCurrentView(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const currentView = button.dataset.viewName;
    this.orientationAngleAndSpacingFieldTarget.value = currentView;

    this.toggleButtonActive(button, true);

    this.updateOrientation(currentView);

    if (currentView === "landscape") {
      if (this.hasPortraitViewContainerTarget) {
        this.portraitViewContainerTarget.style.display = "none";
      }

      animate.show(this.landscapeViewContainerTarget);

      if (this.hasSetPortraitButtonTarget) {
        this.toggleButtonActive(this.setPortraitButtonTarget, false);
      }
    } else {
      if (this.hasLandscapeViewContainerTarget) {
        this.landscapeViewContainerTarget.style.display = "none";
      }

      animate.show(this.portraitViewContainerTarget);

      if (this.hasSetLandscapeButtonTarget) {
        this.toggleButtonActive(this.setLandscapeButtonTarget, false);
      }
    }
  }

  updateOrientation(orientation) {
    axios({
      method: "PATCH",
      url: this.setOrientationUrlTarget.dataset.value,
      data: { orientation },
      headers: api.defaultHeaders(),
    });
  }

  toggleButtonActive(button, bool) {
    button.closest("li").classList[bool ? "add" : "remove"]("active");
  }

  setTiltAngle(event) {
    const angle = event.currentTarget.value;

    if (angle === "0") {
      animate.show(this.bondingBoltContainerTarget);
    } else {
      this.bondingBoltContainerTarget.style.display = "none";
    }
  }
}
