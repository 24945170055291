import * as yup from "yup";
import InDialogController from "../controller_in_dialog";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  company: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().required(),
  companyType: yup.string().required(),
  companySize: yup.string().required(),
});

const validationFields = Object.keys(schema.fields);

export default class extends InDialogController {
  submitting = false;

  static targets = [
    "firstNameField",
    "firstNameFieldContainer",
    "lastNameField",
    "lastNameFieldContainer",
    "emailField",
    "emailFieldContainer",
    "phoneField",
    "phoneFieldContainer",
    "cityField",
    "cityFieldContainer",
    "stateField",
    "stateFieldContainer",
    "zipField",
    "zipFieldContainer",
    "companyField",
    "companyFieldContainer",
    "companyTypeField",
    "companyTypeFieldContainer",
    "companySizeField",
    "companySizeFieldContainer",
    "installationsPerMonthContainer",
    "installationsPerMonthField",
    "recaptchaContainer",
    "submitBtn",
  ];

  connect() {
    if (!this.isOkayToConnect) return;

    this.initRecaptcha();
  }

  disconnect() {
    clearInterval(this.recaptchaInterval);
  }

  get formValues() {
    return {
      firstName: this.firstNameFieldTarget.value,
      lastName: this.lastNameFieldTarget.value,
      email: this.emailFieldTarget.value,
      phone: this.phoneFieldTarget.value,
      company: this.companyFieldTarget.value,
      city: this.cityFieldTarget.value,
      state: this.stateFieldTarget.value,
      zip: this.zipFieldTarget.value,
      companyType: this.companyTypeFieldTarget.value,
      companySize: this.companySizeFieldTarget.value,
      installationsPerMonth: this.installationsPerMonthFieldTarget.value,
    };
  }

  submit(event) {
    if (this.submitting) return;

    event.preventDefault();
    this.removePreviousValidationErrors();
    schema
      .validate(this.formValues, { abortEarly: false })
      .then((_valid) => {
        this.submitting = true;
        this.submitBtnTarget.classList.add("ir-btn--spinner-shown");
        this.element.submit();
      })
      .catch((err) => this.handleValidationErrors(err));
  }

  removePreviousValidationErrors() {
    validationFields.forEach((validationField) => {
      const container = this[`${validationField}FieldContainerTarget`];
      container.classList.remove("field_with_errors");
    });
  }

  handleValidationErrors(err) {
    err.inner.forEach((validationError) => {
      this.handleFieldError(validationError.path, validationError.errors);
    });
    window.scrollTo({ top: "0", behavior: "smooth" });
  }

  handleFieldError(validationField, errors) {
    const container = this[`${validationField}FieldContainerTarget`];
    container.classList.add("field_with_errors");
  }

  initRecaptcha() {
    const timestamp = () => {
      const response = document.getElementById("g-recaptcha-response");
      if (response == null || response.value.trim() == "") {
        const elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);
        elems["ts"] = JSON.stringify(new Date().getTime());
        document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems);
      }
    };
    this.recaptchaInterval = setInterval(timestamp, 500);
  }
}
