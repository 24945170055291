import { clone, destroy } from "mobx-state-tree";

import { ROOF_SECTION_DATA_TYPE } from "../../../da/map/data-types";

import RoofSectionLegalityChecker from "../../../da/map/legality-checkers/roof-section";
import { pointInOrOnPolygon } from "../../../helpers/point-in-polygon";
import BoundingBoxBuilder from "../panel-grid/bounding-box-builder";
import LayoutBuilder from "../panel-grid/layout-builder";
import ArrayBuilder from "../panel-grid/array-builder";
import ObstructionsProximityDetector from "../obstructions/proximity-detector";

import { removeArrayCellFeaturesDescendedFromRoofSectionUuid } from "../ol-helpers";
import { removeFeaturesMatchingUuid, removeFeaturesDescendedFromRoofSectionUuid } from "../../../da/map/ol-helpers";
import {
  setCartesianPointsForModelRelativeToOrigin,
  cartesianPointsRelativeToOrigin,
} from "../../../da/map/ol-geometry";
import { linkFeatureToModel } from "../../../da/map/modification-helpers/base";
import { logger } from "../../../helpers/app";

export function createRoofSection(rsFeature, project, mapModelSynchronizer, mapManager) {
  const rpFeature = findRoofPlaneFeatureForRoofSectionFeature(rsFeature, mapManager);

  if (rpFeature) {
    const roofPlane = mapModelSynchronizer.getRoofPlaneForFeature(
      rpFeature,
      "BxRoofSectionModificationHelper.createRoofSection",
    );
    const roofSection = roofPlane.addRoofSection({ custom: true });
    linkFeatureToModel(rsFeature, roofSection);
    mapModelSynchronizer.updateRoofSectionLatLngFromFeature(rsFeature);

    addLayoutAndArrayForRoofSection(rsFeature, roofSection, project, mapManager);

    new RoofSectionLegalityChecker(
      rpFeature,
      mapManager.roofSectionsFeatures,
      mapModelSynchronizer,
    ).markIllegalPolygons(rsFeature);
    new ObstructionsProximityDetector(project).findNearbyObstructionsForRoofSection(roofSection);
    mapManager.forceRedrawRoofPlanes();
  } else {
    rsFeature.set("flaggedForAutoDeleteByVectorSource", true);
    rsFeature.set("dataType", ROOF_SECTION_DATA_TYPE);
    alert("Roof sections must be inside a roof plane");
  }
}

export function translateRoofSection(rsFeature, project, mapModelSynchronizer, mapManager, controller) {
  const roofPlaneFeature = mapModelSynchronizer.getRoofPlaneFeatureByUuid(rsFeature.get("roofPlaneUuid"));

  if (roofPlaneFeature) {
    let roofSection = mapModelSynchronizer.getRoofSectionForFeature(rsFeature);
    if (roofSection.isDefault) {
      // this preserves the setback against the roof plane
      removeExistingBoundingBoxAndArrayFeatures(roofSection, mapManager);
      roofSection = swapInNewCustomRoofSection(rsFeature, roofSection, true);
      controller.updateRestoreDefaultRoofSectionsUI();
    }
    repositionRoofSection(rsFeature, roofSection, roofPlaneFeature, project, mapModelSynchronizer, mapManager);
    updateBoundingBoxAndReAddLayoutForRoofSection(rsFeature, roofSection, project, mapManager);
  } else {
    // should never happen
    console.error("Could not find roof plane for roof section feature", rsFeature);
    debugger;
  }
}

export function modifyRoofSection(rsFeature, project, mapModelSynchronizer, mapManager, controller) {
  const roofPlaneFeature = mapModelSynchronizer.getRoofPlaneFeatureByUuid(rsFeature.get("roofPlaneUuid"));

  if (roofPlaneFeature) {
    let roofSection = mapModelSynchronizer.getRoofSectionForFeature(rsFeature);
    const currentLayout = roofSection.layout && clone(roofSection.layout);
    if (roofSection.isDefault) {
      // this preserves the setback against the roof plane
      removeExistingBoundingBoxAndArrayFeatures(roofSection, mapManager);
      roofSection = swapInNewCustomRoofSection(rsFeature, roofSection, false);
      controller.updateRestoreDefaultRoofSectionsUI();
    }
    repositionRoofSection(rsFeature, roofSection, roofPlaneFeature, project, mapModelSynchronizer, mapManager);
    addLayoutAndArrayForRoofSection(rsFeature, roofSection, project, mapManager, currentLayout);
    if (currentLayout) destroy(currentLayout);
  } else {
    // should never happen
    console.error("Could not find roof plane for roof section feature", rsFeature);
    debugger;
  }
}

export function deleteRoofSection(rsFeature, project, mapModelSynchronizer, mapManager, controller) {
  const uuid = rsFeature.get("uuid");

  if (uuid) {
    const roofSection = project.getRoofSection(uuid);
    const roofPlane = roofSection.roofPlane;
    const rpFeature = mapModelSynchronizer.getRoofPlaneFeatureByUuid(roofPlane.uuid);

    removeExistingBoundingBoxAndArrayFeatures(roofSection, mapManager);
    removeFeaturesForRoofSection(roofSection, mapModelSynchronizer, mapManager);

    if (roofSection.isDefault) {
      roofSection.deactivate();
      controller.updateRestoreDefaultRoofSectionsUI();
    } else if (roofSection.id) {
      roofSection.flagDeleted();
    } else {
      roofPlane.destroyRoofSection(roofSection);
    }

    new RoofSectionLegalityChecker(
      rpFeature,
      mapManager.roofSectionsFeatures,
      mapModelSynchronizer,
    ).markIllegalPolygons();

    roofPlane.resequenceRoofSectionsIdentifiers();
    mapModelSynchronizer.syncIdentifiersOnFeatures();
    new ObstructionsProximityDetector(project).deleteRoofSection(roofSection);

    mapManager.forceRedrawRoofPlanes();
  } else {
    console.log("No UUID. Skipping", rsFeature);
  }
}

export function deleteRoofSectionAndRemoveFromVectorSource(roofSection, mapModelSynchronizer, mapManager) {
  removeFeaturesForRoofSection(roofSection, mapModelSynchronizer, mapManager);

  new ObstructionsProximityDetector(mapManager.project).deleteRoofSection(roofSection);

  if (roofSection.id) {
    roofSection.flagDeleted();
  } else {
    roofSection.roofPlane.destroyRoofSection(roofSection);
  }
}

export function removeFeaturesForRoofSection(roofSection, mapModelSynchronizer, mapManager) {
  removeExistingBoundingBoxAndArrayFeatures(roofSection, mapManager);
  removeFeaturesMatchingUuid(mapManager.roofSectionsVectorSource, roofSection.uuid);
}

export function reactivateRoofSection(roofSection, project, mapModelSynchronizer, mapManager) {
  roofSection.activate();
  const rsFeature = mapModelSynchronizer.createFeatureForModel(roofSection);
  mapManager.roofSectionsVectorSource.addFeature(rsFeature);
  addLayoutAndArrayForRoofSection(rsFeature, roofSection, project, mapManager);
  new ObstructionsProximityDetector(mapManager.project).findNearbyObstructionsForRoofSection(roofSection);
}

function repositionRoofSection(rsFeature, roofSection, rpFeature, project, mapModelSynchronizer, mapManager) {
  mapModelSynchronizer.updateRoofSectionLatLngFromFeature(rsFeature);

  new RoofSectionLegalityChecker(
    rpFeature,
    mapManager.roofSectionsFeatures,
    mapModelSynchronizer,
  ).markIllegalPolygons();

  new ObstructionsProximityDetector(project).findNearbyObstructionsForRoofSection(roofSection);
}

// NOTE: This only moves the underlying model. At this time, there is no point in moving the features
// because this is only used when translating a roof plane. Which happens on a page where roof sections
// are not shown. When the user switches to the roof section page, new features are generated on load.
export function moveRoofSectionByDelta(roofSection, deltaLat, deltaLng) {
  roofSection.shiftLatLngPointsByDelta(deltaLat, deltaLng);
  roofSection.shiftBoundingBoxByDelta(deltaLat, deltaLng);
  // NOTE: The bounding box cartesian coordinates are relative to the roof plane. Since this method
  //       is only used to move a roofSection in sync with the of its containing roofPlane, the
  //       position of the roofSection and its boundingBox relative to the roofPlane don't change.
  //       So there is no change to the cartesian points of the roof section or the cartesian points
  //       of the bounding box.
}

function swapInNewCustomRoofSection(rsFeature, roofSection, copyLayout) {
  const roofPlane = roofSection.roofPlane;
  const replacement = roofPlane.addNewCustomRoofSectionBasedOnADefaultBeingDeactivated(roofSection, copyLayout);
  linkFeatureToModel(rsFeature, replacement);

  return replacement;
}

function findRoofPlaneFeatureForRoofSectionFeature(feature, mapManager) {
  const coordinates = feature.getGeometry().getLinearRing(0).getCoordinates();
  const roofPlaneFeatures = mapManager.roofPlanesFeatures;

  for (let i = 0; i < coordinates.length; i++) {
    for (let j = 0; j < roofPlaneFeatures.length; j++) {
      const isInRoofPlane = pointInOrOnPolygon(coordinates[i], roofPlaneFeatures[j].getGeometry().getCoordinates());
      if (isInRoofPlane) return roofPlaneFeatures[j];
    }
  }
  return undefined;
}

export function addLayoutAndArrayForRoofSection(rsFeature, roofSection, project, mapManager, priorLayout) {
  removeExistingBoundingBoxAndArrayFeatures(roofSection, mapManager);
  addBoundingBox(rsFeature, roofSection, project, mapManager, true);
  new LayoutBuilder(project, roofSection).build(priorLayout);
  buildArrayAndAddFeatures(roofSection, project, mapManager);
}

export function addLayoutForRoofSection(rsFeature, roofSection, project, mapManager, priorLayout) {
  removeExistingBoundingBoxAndArrayFeatures(roofSection, mapManager);
  addBoundingBox(rsFeature, roofSection, project, mapManager, false);
  new LayoutBuilder(project, roofSection).build(priorLayout);
}

export function updateBoundingBoxAndReAddLayoutForRoofSection(rsFeature, roofSection, project, mapManager) {
  removeExistingBoundingBoxAndArrayFeatures(roofSection, mapManager);
  addBoundingBox(rsFeature, roofSection, project, mapManager, true);
  new LayoutBuilder(project, roofSection).reprocessExistingLayout();
  buildArrayAndAddFeatures(roofSection, project, mapManager);
}

function addBoundingBox(rsFeature, roofSection, project, mapManager, addFeatureForPowerUsers) {
  const boundingBoxBuilder = new BoundingBoxBuilder(project, roofSection, rsFeature);
  boundingBoxBuilder.build();
  if (project.powerUser && addFeatureForPowerUsers && mapManager.controller.showBoundingBoxes) {
    mapManager.arraysVectorSource.addFeature(boundingBoxBuilder.boundingBoxFeature);
  }
}

export function adjustArrayAfterNudge(roofSection, project, mapManager) {
  const layoutBuilder = new LayoutBuilder(project, roofSection);
  layoutBuilder.reprocessExistingLayout();
  removeArrayCellFeaturesDescendedFromRoofSectionUuid(mapManager.arraysVectorSource, roofSection.uuid);
  buildArrayAndAddFeatures(roofSection, project, mapManager);
}

function buildArrayAndAddFeatures(roofSection, project, mapManager) {
  const arrayBuilder = new ArrayBuilder(project, roofSection);
  arrayBuilder.build();
  arrayBuilder.features.forEach((feature) => mapManager.arraysVectorSource.addFeature(feature));
}

function removeExistingBoundingBoxAndArrayFeatures(roofSection, mapManager) {
  removeFeaturesDescendedFromRoofSectionUuid(mapManager.arraysVectorSource, roofSection.uuid);
}

export function syncRoofSectionCartesianPoints(topLeftLonLat, roofSection) {
  setCartesianPointsForModelRelativeToOrigin(topLeftLonLat, roofSection);
  logger(`RoofSection ${roofSection.displayIdentifier}: Set cartesian Points`, roofSection.cartesianPointsToArray);
}

export function syncRoofSectionBoundingBoxCartesianPoints(topLeftLonLat, roofSection) {
  const newPoints = cartesianPointsRelativeToOrigin(topLeftLonLat, roofSection.boundingBox);
  roofSection.setBoundingBoxCartesianPoints(newPoints);

  logger(
    `RoofSection ${roofSection.displayIdentifier}: Updated bounding box cartesian points`,
    roofSection.boundingBoxCartesianPointsToArray,
  );
}
