import React from "react";
import PropTypes from "prop-types";
import Outline from "./Outline";

function Outlines({ outer, inner }) {
  return (
    <>
      <Outline type="outer" {...outer} />
      <Outline type="inner" {...inner} />
    </>
  );
}

Outlines.propTypes = {
  outer: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  inner: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
};

export default React.memo(Outlines);
