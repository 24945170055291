import { Controller } from "@hotwired/stimulus";

import * as toolbarBtn from "../../../da/layout-editor/helpers/toolbar-btns";
import * as store from "../../../helpers/local-store";

export default class BtnMultiselectGroup extends Controller {
  static values = {
    localStorageKeyPrefix: String,
    defaultSelectedOptions: Array,
    disallowDeselectionOfLastOption: { type: Boolean, default: false },
  };

  static targets = ["optionBtn"];

  connect() {
    this.element.multiSelectGroupController = this;

    const defaultOptions = this.defaultSelectedOptionsValue;
    this.optionBtnTargets.forEach((btn) => {
      const btnOption = this.#btnOption(btn);
      const localStoreKey = this.optionStoreKey(btnOption);
      const hasLocalStoreValue = store.has(localStoreKey);
      const localStoreValue = store.get(localStoreKey);

      const isDefaultOption = defaultOptions.includes(btnOption);
      const noLocalStoreOptionAndIsDefaultOption = !hasLocalStoreValue && isDefaultOption;
      const isLocalStoreValueTrue = localStoreValue === true;

      if (noLocalStoreOptionAndIsDefaultOption || isLocalStoreValueTrue) {
        toolbarBtn.activate(btn);
      }
    });
  }

  optionStoreKey(option) {
    return `${this.localStorageKeyPrefixValue}-${option}`;
  }

  updateOptionSelect(event) {
    const btn = event.currentTarget;
    if (toolbarBtn.isDisabled(btn)) return;

    const btnOption = event.params.option;
    const localStoreKey = this.optionStoreKey(btnOption);

    if (toolbarBtn.isActive(btn)) {
      const activeBtns = this.optionBtnTargets.filter((btn) => toolbarBtn.isActive(btn));
      if (this.disallowDeselectionOfLastOptionValue && activeBtns.length === 1) return;

      store.set(localStoreKey, false);
      toolbarBtn.deactivate(btn);
    } else {
      store.set(localStoreKey, true);
      toolbarBtn.activate(btn);
    }
  }

  get selectedOptions() {
    const results = [];
    this.optionBtnTargets.forEach((btn) => {
      if (toolbarBtn.isActive(btn)) {
        const btnOption = this.#btnOption(btn);
        results.push(btnOption);
      }
    });
    return results;
  }

  #btnOption(btn) {
    return btn.dataset["da-LayoutEditor-BtnMultiselectGroupOptionParam"];
  }
}
