import { Controller } from "@hotwired/stimulus";
import { showBtnSpinner } from "../../../../helpers/app";
import { confirmDialog } from "../../../components/ir_dialog/helper";

export default class extends Controller {
  static targets = ["submitBtn", "roofPlaneCheckbox"];

  confirm(event) {
    if (this.noRoofPlanesSelected) {
      alert("You need to select at least one roof plane to unlock");
      return;
    }
    event.preventDefault();
    const clickPosition = { x: event.pageX, y: event.pageY };
    confirmDialog(this.confirmMessage, this.confirmationResponse, {
      confirmBtnColor: "red",
      headerColor: "red",
      showNearClickPosition: true,
      clickPosition,
    });
  }

  get noRoofPlanesSelected() {
    if (this.roofPlaneCheckboxTargets.length === 0) return false;

    return this.roofPlaneCheckboxTargets.every((checkbox) => !checkbox.checked);
  }

  get confirmMessage() {
    return this.element.dataset.confirmMessage;
  }

  confirmationResponse = (confirmed) => {
    if (!confirmed) return;

    showBtnSpinner(this.submitBtnTarget);
    // Giving the button spinner a chance to show before the form is submitted.
    // Without this pause the submit happens before the spinner shows.
    setTimeout(() => {
      this.element.submit();
    }, 200);
  };
}
