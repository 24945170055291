import LineString from "ol/geom/LineString";
import { Feature } from "ol";
import { fromLonLat, toLonLat } from "ol/proj";
import * as olPolygon from "ol/geom/Polygon";
import { getDistance } from "ol/sphere";
import { radiansToDegrees } from "@turf/helpers";
import { cartesianPointsRelativeToOrigin, METERS_TO_INCHES } from "../../../da/map/ol-geometry";

import SimpleLatLng from "../../../da/map/models/simple-lat-lng";

export default class RaftersBuilder {
  constructor({ controller, roofPlane }) {
    this.controller = controller;
    this.roofPlane = roofPlane;

    this.mapManager = controller.mapManager;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
  }

  build() {
    if (this.roofPlane.roofSections.length === 0) return;

    this.rafterSpacing = this.roofPlane.rafterSpacing;
    this.rafterOffset = this.roofPlane.rafterOffset;
    this.calculateRafterVectorsAndStartCoordinates();
    this.buildFeatures();
    return this.features;
  }

  buildFraRafters() {
    if (this.roofPlane.roofSections.length === 0) return;

    this.rafterSpacing = 1;
    this.rafterOffset = 0;
    this.calculateRafterVectorsAndStartCoordinates();
    this.buildFeatures();
    return this.features;
  }

  calculateRafterVectorsAndStartCoordinates() {
    const roofPlaneFeature = this.mapModelSynchronizer.getFeatureForRoofPlane(this.roofPlane);
    const boundingBoxFeature = this.rotatedPolygonFeatureBoundingBox(roofPlaneFeature);

    const boundingBoxPolygon = boundingBoxFeature.getGeometry();
    const boundingBoxCoordinates = boundingBoxPolygon.getLinearRing(0).getCoordinates();
    const boundingBoxLatLngs = boundingBoxCoordinates.map((c) => SimpleLatLng.fromLonLat(toLonLat(c)));
    const boundingBoxLatLngsObjects = boundingBoxLatLngs.map((ll) => ll.toSimpleObject);
    this.roofPlane.setBoundingBox(boundingBoxLatLngsObjects);

    const originLonLat = this.roofPlane.project.detail.originLatLng.toLonLat;
    const boundingBoxCartesianPoints = cartesianPointsRelativeToOrigin(originLonLat, boundingBoxLatLngs);
    this.roofPlane.setBoundingBoxCartesianPoints(boundingBoxCartesianPoints);

    const [bottomLeftIndex, topLeftIndex, bottomRightIndex] = this.getBoundingBoxIndexes();
    const boundingBoxBottomLeftLatLng = boundingBoxLatLngs[bottomLeftIndex];
    const boundingBoxTopLeftLatLng = boundingBoxLatLngs[topLeftIndex];

    this.raftersBottomEndLatLng = boundingBoxLatLngs[bottomRightIndex];

    const boundingBoxBottomDistance =
      getDistance(boundingBoxBottomLeftLatLng.toLonLat, this.raftersBottomEndLatLng.toLonLat) * METERS_TO_INCHES;
    const boundingBoxBottomEdgeVector = this.raftersBottomEndLatLng.minus(boundingBoxBottomLeftLatLng);
    this.boundingBoxEdgeUnitVector = boundingBoxBottomEdgeVector.dividedBy(boundingBoxBottomDistance);

    this.raftersBottomStartLatLng =
      this.rafterOffset > 0
        ? boundingBoxBottomLeftLatLng.plus(this.boundingBoxEdgeUnitVector.times(this.rafterOffset))
        : boundingBoxBottomLeftLatLng;

    this.raftersTopStartLatLng =
      this.rafterOffset > 0
        ? boundingBoxTopLeftLatLng.plus(this.boundingBoxEdgeUnitVector.times(this.rafterOffset))
        : boundingBoxTopLeftLatLng;

    const raftersDistanceInches =
      getDistance(this.raftersBottomStartLatLng.toLonLat, this.raftersBottomEndLatLng.toLonLat) * METERS_TO_INCHES;

    this.rafterNum = Math.floor(raftersDistanceInches / this.rafterSpacing) + 1;
    this.boundingBoxLeftEdgeVector = this.raftersTopStartLatLng.minus(this.raftersBottomStartLatLng);
  }

  getBoundingBoxIndexes() {
    const rotationDegrees = radiansToDegrees(this.roofPlane.eaveRotationAngleRadians);

    let bottomLeftIndex = 0;
    let topLeftIndex = 1;
    let bottomRightIndex = 3;

    if (rotationDegrees === 90) {
      bottomLeftIndex = 3;
      topLeftIndex = 0;
      bottomRightIndex = 2;
    } else if (rotationDegrees === 180) {
      bottomLeftIndex = 2;
      topLeftIndex = 3;
      bottomRightIndex = 1;
    } else if (rotationDegrees === 270) {
      bottomLeftIndex = 1;
      topLeftIndex = 2;
      bottomRightIndex = 0;
    }

    return [bottomLeftIndex, topLeftIndex, bottomRightIndex];
  }

  buildFeatures() {
    const features = [];

    Array.from({ length: this.rafterNum }).forEach((_, i) => {
      const distance = this.rafterSpacing * i;

      const rafterOffset = this.boundingBoxEdgeUnitVector.times(distance);

      const rafterBottomLatLng = this.raftersBottomStartLatLng.plus(rafterOffset);
      const rafterBottomCoordinate = fromLonLat(rafterBottomLatLng.toLonLat);

      const rafterTopLatLng = rafterBottomLatLng.plus(this.boundingBoxLeftEdgeVector);
      const rafterTopCoordinate = fromLonLat(rafterTopLatLng.toLonLat);

      const geometry = new LineString([rafterBottomCoordinate, rafterTopCoordinate]);
      const feature = new Feature({ geometry });
      feature.set("rafterNumber", i);

      features.push(feature);
    });

    this.features = features;
  }

  rotatedPolygonFeatureBoundingBox(feature) {
    // To get the extent (bounding box) of the rotated feature, we need to un-rotate a copy of the
    // feature around the map center, get the extent in that position, and then rotate that extent
    // geometry back around the center to display it.
    let extentGeometry = feature.getGeometry();
    const rotation = this.roofPlane.eaveRotationAngleRadians;
    const oblique = rotation % (Math.PI / 2) !== 0;
    const mapCenter = this.mapManager.map.getView().getCenter();
    if (oblique) {
      const geometry = extentGeometry.clone();
      geometry.rotate(-rotation, mapCenter);
      extentGeometry = geometry;
    }

    let rotatedFeatureExtent = extentGeometry.getExtent();
    let boundingBoxPolygon = olPolygon.fromExtent(rotatedFeatureExtent);
    if (oblique) {
      const geometry = boundingBoxPolygon.clone();
      geometry.rotate(rotation, mapCenter);
      boundingBoxPolygon = geometry;
    }

    const boundingBoxFeature = new Feature({ geometry: boundingBoxPolygon });
    return boundingBoxFeature;
  }
}
