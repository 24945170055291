import * as store from "../../helpers/local-store";

export function isInStore(name) {
  // After this was rolled out it occurred to me that it would be a good idea
  // to prefix the local storage key with "intro" so it would be clearer where
  // these values were coming from.  So, I'm checking for the old no-prefix
  // value and the new prefixed value here.
  const oldValueInStore = store.get(name);
  if (store.get(name)) {
    store.set(`intro-${name}`, oldValueInStore);
    store.del(name);
    return true;
  } else if (store.get(`intro-${name}`)) {
    return true;
  } else {
    return false;
  }
}

export function setInStore(name) {
  store.set(`intro-${name}`, true);
}
